import React, { useEffect, useState, useContext } from 'react';

import cx from 'classnames';
import { useFormik } from 'formik';
import { Scrollbars } from 'react-custom-scrollbars';
import Dropzone from 'react-dropzone';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';

import DashboardApi from 'api/DashboardApi';
import { AuthContext } from 'auth/AuthContext';
import IconButton from 'components/buttons/IconButton/IconButton';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import Radio from 'components/forms/components/Radio';
import TextInput from 'components/forms/components/TextInput';
import PHRFile from 'components/PHRUI/File/PHRFile/PHRFile';
import SpinnerRing from 'components/Spinner/SpinnerRing';
import SuccessCheckmark from 'components/SuccessCheckmark/SuccessCheckmark';
import Tooltip from 'components/tooltip/Tooltip';
import { ATTACHMENTS_SIZE } from 'constants/attachmentsSize';
import PHRFileDTO from 'services/File/PHRFile';
import useResolveHandler from 'shared/useResolveHandler';
import formatBytes from 'utility/formatBytes';
import formatKey from 'utility/formatKey';
import t from 'utility/setTranslation';

import styles from './AddAnnouncement.module.scss';

const dropzoneStyle = {
  width: '100%',
  height: 'auto',
  borderWidth: 2,
  borderColor: 'rgb(102, 102, 102)',
  borderStyle: 'dashed',
  borderRadius: 5,
};

const AddAnnouncement = ({ onRequestClose, selected, setIsFormDirty, update, discardChanges }) => {
  const { authState } = useContext(AuthContext);
  const { responseStatus, setStatus } = useResolveHandler();
  const [loading, setLoading] = useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [intervalId, setIntervalId] = useState(null);
  const [validationErrorMsg, setValidationErrorMsg] = useState('');
  const navigate = useNavigate();

  const hasPermission = authState.user.roles.includes('ROLE_ADMIN') || authState.user.roles.includes('ROLE_HR');

  const validate = (values) => {
    const errors = {};
    if (!values.title.trim()) {
      errors.title = t(`GENERAL.FIELD.CANT_BE_EMPTY`, {
        field: t('GENERAL.TITLE'),
      });
    }

    if (!values.content) {
      errors.content = t(`GENERAL.FIELD.CANT_BE_EMPTY`, {
        field: t('GENERAL.CONTENT'),
      });
    }

    if (values.type === 'img') {
      if (!selected && !values.file) {
        errors.file = t(`GENERAL.FIELD.CANT_BE_EMPTY`, {
          field: t('GENERAL.FILE'),
        });
      }

      if (values.file && values.file.size > 2097152) {
        errors.file = t(`GENERAL.DOCUMENTS_EXCEED_MAX_UPLOADED_SIZE`, {
          field: `${formatBytes(values.file.size)}`,
          max: `${ATTACHMENTS_SIZE}MB`,
        });
      }
    }

    return errors;
  };

  const getIframe = () => {
    const iframe = document.querySelector('.scrollbarsWrapper iframe');

    if (iframe) {
      setLoadingSkeleton(false);
    }
  };

  useEffect(() => {
    const id = setInterval(() => getIframe(), 1000);
    setIntervalId(id);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    if (!loadingSkeleton) {
      clearInterval(intervalId);
    }
  }, [loadingSkeleton]);

  const formik = useFormik({
    initialValues: {
      title: selected ? selected.title : '',
      titleArabic: selected?.titleArabic || '',
      content: selected ? selected.content : '',
      contentArabic: selected?.contentArabic || '',
      type: selected?.file ? 'img' : 'text',
      file: selected?.file || null,
    },
    validate,
    onSubmit: (values) => {
      setValidationErrorMsg('');
      setLoading(true);
      const sentData = new FormData();
      sentData.append('title', values.title);
      sentData.append('content', values.content);
      sentData.append('titleArabic', values.titleArabic);
      sentData.append('contentArabic', values.contentArabic);

      let params = '';
      if (values.type === 'img' && values.file && typeof values.file?.name === 'string') {
        sentData.append('file', values.file instanceof File ? values.file : values.file?.id);
      } else {
        if (!values.file) {
          if (values.type !== 'text') {
            params = '?deleteFile=true';
          }
        } else {
          if (values.type === 'text') {
            params = '?deleteFile=true';
          }
        }
      }

      if (selected) {
        DashboardApi.editAnnouncement(selected.id, sentData, params)
          .then(() => {
            setLoading(false);
            update();
            setStatus(true, () => {
              discardChanges(true);
            });
          })
          .catch((err) => {
            if (err?.response?.status === 400) {
              setValidationErrorMsg(t('GENERAL.ERROR_YOU_HAVE_TO_USE_THESE_HTML_BLOCKS'));
            }
            setLoading(false);
            setStatus(false, () => {}, err?.response?.data?.message);
          });
      } else {
        DashboardApi.addAnnouncement(sentData)
          .then(() => {
            setLoading(false);
            update();
            setStatus(true, () => {
              discardChanges(true);
            });
          })
          .catch((err) => {
            if (err?.response?.status === 400) {
              setValidationErrorMsg(t('GENERAL.ERROR_YOU_HAVE_TO_USE_THESE_HTML_BLOCKS'));
            }
            setLoading(false);
            setStatus(false, () => {}, err?.response?.data?.message);
          });
      }
    },
  });

  const getFilePreview = () => {
    const formFile = formik.values.file;
    if (!formFile) return null;
    return formFile instanceof File ? PHRFileDTO.createFromFile(formFile) : PHRFileDTO.create(formFile);
  };

  const [filePreview, setFilePreview] = useState(getFilePreview());

  useEffect(() => {
    setIsFormDirty(formik.dirty);

    setValidationErrorMsg('');
  }, [formik.values]);

  useEffect(() => {
    setFilePreview(getFilePreview());
  }, [formik.values.file]);

  const onAttachmentDelete = () => {
    formik.setFieldValue('file', null);
  };

  const dropHandler = (acceptedFiles) => {
    // do nothing if no files || max 1 one file
    if (acceptedFiles.length === 0) {
      formik.setFieldError('file', t('GENERAL.IMAGE_INVALID_FORMAT'));
      return;
    }

    if (acceptedFiles.length > 1) {
      formik.setFieldError('file', t('HIRIRNG.MODAL.MULTIPLE_FILES_ERROR'));
      return;
    }

    formik.setFieldValue('file', acceptedFiles[0]);

    setIsFormDirty(formik.dirty); // send is form dirty to parent component
  };

  const toolbarOptions = [
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ['link'],
    ['code-block'],
    ['emoji'],
  ];

  const modules = {
    toolbar: toolbarOptions,
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <div>
      {responseStatus.resolved && <SuccessCheckmark text={responseStatus.msg} isFailed={responseStatus.isFailed} />}
      {loading && <SpinnerRing />}
      <div>
        <div className='d-flex justify-content-between border-btm pb-4 mb-5 mx-5 mt-4'>
          <h5 className='fw-bold m-0'>{selected ? t('GENERAL.EDIT_POST') : t('GENERAL.CREATE_POST')}</h5>
          <div className='d-flex justify-content-between align-items-center'>
            {hasPermission && (
              <div className='me-1'>
                <Tooltip label={t('GENERAL.OPEN_POSTS_SETTINGS_TOOLTIP')} position='bottom'>
                  <IconButton
                    icon='icon-settings'
                    size='lg'
                    color='gray'
                    onClick={() => {
                      navigate('/company-settings/team/posts/announcements');
                    }}
                  />
                </Tooltip>
              </div>
            )}
            <IconButton icon='icon-cross-x' onClick={onRequestClose} size='md' color='gray' />
          </div>
        </div>

        <div className='position-relative'>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Scrollbars className='scrollbarsWrapper no-margin' autoHeight autoHeightMin={200} autoHeightMax='60vh'>
              <div className='d-flex flex-column pb-8 mx-3'>
                <div className=' d-flex gap-4 px-2a mb-3'>
                  <TextInput
                    name='title'
                    label='title'
                    sufix='en'
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && formik.errors.title}
                    isRequired
                    maxLength='255'
                  />

                  <TextInput
                    name='titleArabic'
                    label='title'
                    sufix='ar'
                    value={formik.values.titleArabic}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.titleArabic && formik.errors.titleArabic}
                    maxLength='255'
                    direction='rtl'
                  />
                </div>

                <section className='d-flex gap-3'>
                  <section className='flex-1'>
                    <section>
                      <div className='d-flex justify-content-between'>
                        <label className='label'>{t('GENERAL.CONTENT')}</label>

                        <span className='label text-end mb-1'>{`(${formatKey('GENERAL', 'en')})`}</span>
                      </div>
                      <section style={{ height: '171px' }}>
                        <ReactQuill
                          style={{ height: 'calc(100% - 42px)' }}
                          theme='snow'
                          className={styles.customToolbar}
                          value={formik.values.content}
                          onChange={(content) => formik.setFieldValue('content', content)}
                          modules={modules}
                        />
                      </section>
                    </section>

                    <div className='error'>{formik.errors.content}</div>
                  </section>

                  <div className={cx('flex-1 px-2a mb-4')}>
                    <div className='d-flex justify-content-between'>
                      <label className='label'>{t('GENERAL.CONTENT')}</label>

                      <span className='label text-end mb-1'>{`(${formatKey('GENERAL', 'ar')})`}</span>
                    </div>

                    <ReactQuill
                      className='quillEditor'
                      theme='snow'
                      style={{ height: '129px' }}
                      value={formik.values.contentArabic}
                      onChange={(content) => formik.setFieldValue('contentArabic', content)}
                      modules={modules}
                    />
                  </div>
                </section>

                <div className=' px-2a mb-6 mt-3'>
                  <div className='d-flex justify-content-start'>
                    <Radio
                      className='me-5'
                      name='type'
                      value='text'
                      label='Text Only'
                      checked={formik.values.type === 'text'}
                      onChange={formik.handleChange}
                    />
                    <Radio
                      className='me-5'
                      name='type'
                      value='img'
                      label='Media'
                      checked={formik.values.type === 'img'}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>

                <div style={{ minHeight: '200px' }} className='col-12'>
                  {formik.values.type === 'img' && (
                    <>
                      {filePreview ? (
                        <div className='mb-3 w-40' style={{ minHeight: '98px' }}>
                          <PHRFile
                            file={filePreview}
                            isDownloadable={false}
                            isThumbnail={false}
                            onDelete={onAttachmentDelete}
                          />
                          <div className='error mt-2'>{formik.errors.file}</div>
                        </div>
                      ) : (
                        <div className='mb-3'>
                          <p className='label mb-2'>{t('GENERAL.MEDIA')}</p>
                          <Dropzone
                            accept='image/jpeg, image/jpg, image/png, application/pdf'
                            style={dropzoneStyle}
                            onDrop={dropHandler}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <>
                                <div {...getRootProps()} className={styles.dropDownWrapper}>
                                  <input {...getInputProps()} />

                                  <i className={cx(styles.dropDownIcon, ' icon-cloud-upload me-2')} />
                                  <p className={styles.dropDownPlaceholder}>{t('GENERAL.DRAG_DROP_PLACEHOLDER')}</p>
                                </div>
                                <div className='error mt-2'>{formik.errors.file}</div>
                              </>
                            )}
                          </Dropzone>
                        </div>
                      )}

                      <div className='col-3 p-0'>
                        <p className='text-gray'>
                          <small>
                            {t('DASHBOARD.ANNOUNCEMENT.MAX_SIZE')}.{t('DASHBOARD.ANNOUNCEMENT.FORMATS')}
                          </small>
                        </p>
                      </div>

                      <div className='col-9'>
                        {validationErrorMsg && (
                          <p className='text-center text-red'>
                            <span>{t('GENERAL.ERROR')}!</span>
                            <br />
                            <small>{validationErrorMsg}</small>
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Scrollbars>

            <div className='mt-3 d-flex align-items-center border-t justify-content-end py-4 btn-modal-box'>
              <div className='me-5'>
                <RoundedButton type='submit' text='post' btnStyle='contained' color='primary' size='md' />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddAnnouncement;
