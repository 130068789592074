import React from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import trial from 'assets/img/trial_expired.png';
import t from 'utility/setTranslation';

import styles from './TrialInfoBox.module.scss';

const TrialInfoBox = ({ date, type }) => (
  <div className={cx(styles.wrapper, 'pt-3 px-4 pb-4')}>
    <img src={trial} alt='trial' className={styles.img} />

    <div className={cx(styles.text, 'my-3')}>
      {type === 'trial' ? <span>{t('TRIAL_COUNTER.DESC1')}</span> : <span>{t('DEMO_COUNTER.DESC1')}</span>}

      <span className='fw-bold ms-1'>{date ? `${moment(date).format('MMM DD, YYYY')}. ` : '- -'}</span>

      {type === 'trial' ? <span>{t('TRIAL_COUNTER.DESC2')}</span> : null}
    </div>
  </div>
);

TrialInfoBox.propTypes = {
  date: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default TrialInfoBox;
