import { createContext } from 'react';

import { observable, action, makeObservable } from 'mobx';

class ECStore {
  currentView = '';

  showContent = false;

  ecClass = 'minimized';

  selectedRequestID = undefined;

  hasNoScrollClass = true;

  newRequestCreated = 1;

  /* ****Loan */
  newLoanRequest = undefined;

  idUser = undefined;

  /* ***** TASKS **** */
  selectedTaskId = undefined;

  selectedEditTask = null;

  newTaskCreated = 1;

  constructor() {
    makeObservable(this, {
      currentView: observable,
      showContent: observable,
      selectedRequestID: observable,
      ecClass: observable,
      setCurrentView: action,
      setEcState: action,
      selectRequest: action,
      hasNoScrollClass: observable,
      setHasNoScrollClass: action,
      setNewRequestCreated: action,
      newRequestCreated: observable,

      selectedTaskId: observable,
      selectTask: action,
      newTaskCreated: observable,
      setNewTaskCreated: action,
      selectedEditTask: observable,
      selectEditTask: action,
      setRequestView: action,
      newLoanRequest: observable,
    });
  }

  // Loan
  setRequestView(view, id) {
    this.newLoanRequest = view;
    this.idUser = id;
  }

  setCurrentView(view) {
    this.currentView = view;
  }

  setEcState(seeContent, ecClassName) {
    this.showContent = seeContent;
    this.ecClass = ecClassName;
  }

  selectRequest(id) {
    this.selectedRequestID = id;
  }

  setHasNoScrollClass(hasClass) {
    this.hasNoScrollClass = hasClass;
  }

  setNewRequestCreated() {
    this.newRequestCreated += 1;
  }

  /* ***** TASKS **** */
  selectTask(id) {
    this.selectedTaskId = id;
  }

  setNewTaskCreated() {
    this.newTaskCreated += 1;
  }

  selectEditTask(data) {
    this.selectedEditTask = data;
  }
}

export default createContext(new ECStore());
