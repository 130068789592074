import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import CircularLoader from 'components/PHRUI/Loader/Circular/CircularLoader';
import Tooltip from 'components/tooltip/Tooltip';

import styles from './IconButton.module.scss';

const IconButton = ({
  icon,
  onClick,
  isDisabled,
  color,
  size,
  className,
  style,
  showNotification,
  active,
  loading,
  showBorder,
  tooltipText,
  tooltipPosition,
  ariaLabel,
  text,
  activeTextBtn,
}) => {
  const clickHandler = (e) => {
    e.stopPropagation();
    onClick(e);
  };

  const btnEl = (
    <button
      data-testid={tooltipText ? tooltipText + icon : icon}
      className={cx(
        styles.iconButton,
        styles[color],
        styles[size],
        showBorder && styles.showBorder,
        loading && styles.loading,
        active && styles.active,
        activeTextBtn && styles.activeTextBtn,
        className,
        'btn'
      )}
      style={style}
      type='button'
      aria-label={ariaLabel || icon}
      onClick={clickHandler}
      disabled={isDisabled}
    >
      <i className={cx(icon, styles.i)} />
      {showNotification && <div className={styles.notification} />}
      {text && <span className={styles.text}>{text}</span>}
      {loading && (
        <div style={{ position: 'absolute' }}>
          <CircularLoader color={color} size={size} />
        </div>
      )}
    </button>
  );

  if (tooltipText) {
    return (
      <Tooltip position={tooltipPosition} label={tooltipText}>
        {btnEl}
      </Tooltip>
    );
  }

  return btnEl;
};

IconButton.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  tooltipPosition: PropTypes.string,
  showNotification: PropTypes.bool,
  loading: PropTypes.bool,
  active: PropTypes.bool,
  showBorder: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  activeTextBtn: PropTypes.bool,
};

IconButton.defaultProps = {
  icon: '',
  onClick: undefined,
  isDisabled: false,
  color: 'gray',
  size: 'sm',
  className: '',
  ariaLabel: '',
  showNotification: false,
  loading: false,
  active: false,
  showBorder: false,
  text: '',
  tooltipPosition: 'top',
  activeTextBtn: false,
};

export default IconButton;
