import React, { useState } from 'react';

import moment from 'moment';

import IconButton from 'components/buttons/IconButton/IconButton';
import Card from 'components/Card/Card';
import t from 'utility/setTranslation';

import CandidateApplicationsChart from '../CandidateApplicationsChart/CandidateApplicationsChart';

const CandidateApplicationsChartCard = ({ jobPostId, jobPostPublishDate, jobPostArchiveDate, isLoading }) => {
  const currentYear = jobPostArchiveDate ? moment(jobPostArchiveDate).year() : new Date().getFullYear();
  const jobPostPublishYear = moment(jobPostPublishDate).year();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const handleDecrementYears = () => {
    setSelectedYear((prevState) => prevState - 1);
  };
  const handleIncrementYears = () => {
    setSelectedYear((prevState) => prevState + 1);
  };

  return (
    <Card className='d-flex flex-column px-4 pt-5 pb-3'>
      <section className='d-flex justify-content-between mb-auto'>
        <h2 className='text-main font-size-24 font-weight-bold'>{t('GENERAL.DAILY_APPLICANTS')}</h2>
        <section className='d-flex gap-3'>
          <IconButton
            isDisabled={jobPostPublishYear === currentYear}
            icon='icon-chevron-left font-size-20'
            color='primary'
            onClick={handleDecrementYears}
          />
          <h4 className='font-size-20 text-primary font-weight-bold'>{selectedYear}</h4>
          <IconButton
            isDisabled={selectedYear === currentYear}
            icon='icon-chevron-right font-size-20'
            color='primary'
            onClick={handleIncrementYears}
          />
        </section>
      </section>
      <CandidateApplicationsChart
        jobPostId={jobPostId}
        isLoading={isLoading}
        selectedYear={selectedYear}
        jobPostPublishDate={jobPostPublishDate}
        jobPostArchiveDate={jobPostArchiveDate}
      />
      <section className='d-flex justify-content-center align-items-center'>
        <i className='icon-bullet-point text-primary font-size-24' />
        <h5 className='font-size-11 text-gray-400 mb-0 uppercase font-weight-bold'>{t('GENERAL.DAILY_APPLICANTS')}</h5>
      </section>
    </Card>
  );
};

export default CandidateApplicationsChartCard;
