import React, { useContext } from 'react';

import cx from 'classnames';

import { AuthContext } from 'auth/AuthContext';
import Personas from 'components/Personas';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

import styles from './Dashboard.module.scss';

const Header = () => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;

  return (
    <div className={cx(styles.formWrapper, 'row position-relative')}>
      <div className='col-12 position-relative'>
        <div className='d-flex justify-content-start align-items-center ms-4'>
          <div className={cx('image-wrapper', 'position-relative')}>
            <Personas user={user} size={88} customClass={styles.profileImage} />
          </div>

          <div className={cx(styles.userName, 'ms-5')}>
            {t('GENERAL.DAHSBOARD_NAME', {
              name: readName(authState.user.language, user?.firstName, user?.firstNameArabic),
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
