import React, { useState, useEffect, useContext } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import Scrollbars from 'react-custom-scrollbars';

import DashboardApi from 'api/DashboardApi';
import { AuthContext } from 'auth/AuthContext';
import ConfirmationBox from 'components/ConfirmationBox';
import Menu from 'components/menu/Menu';
import createSelectOptions from 'components/menu/utility/createSelectOptions';
import Modal from 'components/Modal';
import DashboardContext from 'modules/Dashboard/DashboardContext';
import AddEventModal from 'modules/Dashboard/Events/AddEventModal';
import useResolveHandler from 'shared/useResolveHandler';
import GeneralStore from 'stores/GeneralStore';
import t from 'utility/setTranslation';
import updateDataList from 'utility/updateDataList';

import AddAnnouncement from './AddAnnouncement';
import styles from './Announcement.module.scss';
import AnnouncementCard from './AnnouncementCard';
import AnnouncementDetails from './AnnouncementDetails';
import SkeletonAnnouncement from '../components/SkeletonAnnouncement';

const Announcement = () => {
  const [loading, setLoading] = useState(true);
  const [isInit, setIsInit] = useState(true);
  const [announcements, setAnnouncements] = useState({
    items: [],
    pagination: {},
  });
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const { responseStatus, setStatus } = useResolveHandler();

  const [openAddEventModal, setIsAddEventModalOpened] = useState(false);
  const [customEvents, setEvents] = useState([]);

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [detailsModalData, setDetailsModalData] = useState(null);
  const [isAnnouncer, setIsAnnouncer] = useState(false);
  const generalStore = useContext(GeneralStore);
  const { setShouldUpdateEvents } = useContext(DashboardContext);

  const { authState } = useContext(AuthContext);
  const hasPermission = authState.user.roles.includes('ROLE_ADMIN') || authState.user.roles.includes('ROLE_HR');

  const actions = [];
  if (isAnnouncer) {
    actions.push('announcement');
  }
  if (hasPermission) {
    actions.push('event');
  }

  const getAnnouncementsHandler = (isScroll = false) => {
    if (!isScroll) {
      setLoading(true);
    }

    const params = `perPage=20`;
    let sentFilter = '?filters=';
    const state = { ...announcements };

    if (isScroll) {
      const nextPage = state.pagination.currentPage + 1;

      if (nextPage <= state.pagination.pages) {
        sentFilter = `?currentPage=${nextPage}&${params}`;
      } else {
        return;
      }
    } else {
      sentFilter = `?${params}`;
    }

    setLoading(true);
    DashboardApi.getAnnouncements(sentFilter)
      .then((res) => {
        if (isScroll) {
          state.items = _.concat(state.items, res.data.items);
          state.pagination = res.data.pagination;

          setAnnouncements(state);
        } else {
          setAnnouncements(res.data);
        }

        setLoading(false);
        setIsInit(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const isEmployeeAnnouncer = () => {
    DashboardApi.getIsEmployeeAnnouncer()
      .then((res) => {
        setIsAnnouncer(res.data.isAnnouncer);
      })
      .catch(() => {});
  };

  const onReaction = (announcementId, reaction) => {
    DashboardApi.addAnnouncementReaction(announcementId, reaction, {}).then(() => {
      getAnnouncementsHandler(false);
    });
  };

  const openConfirmModal = (data) => {
    setIsConfirmModalOpened(true);
    setSelectedAnnouncement(data);
  };

  const onDeleteAnnouncement = () => {
    setDeleting(true);
    DashboardApi.deleteAnnouncement(selectedAnnouncement.id).then(() => {
      setDeleting(false);
      getAnnouncementsHandler(false);

      setStatus(true, () => {
        setIsConfirmModalOpened(false);
      });
    });
  };

  const handleScroll = (event) => {
    if (event.top >= 0.999) {
      getAnnouncementsHandler(true);
    }
  };

  const onModalClose = () => {
    setIsModalOpened(false);
    setIsAddEventModalOpened(false);
  };

  const actionHandler = (action) => {
    switch (action) {
      case 'announcement':
        setIsModalOpened(true);
        setModalData(null);
        break;
      case 'event':
        setIsAddEventModalOpened(true);
        break;
      default:
        break;
    }
  };

  const addCustomEvent = (data) => {
    setLoading(true);
    DashboardApi.addCustomEvent(data)
      .then((res) => {
        const state = updateDataList(res.data, 'add', customEvents);
        setEvents(state);
        setShouldUpdateEvents(true);
        setLoading(false);
        setStatus(true, () => {
          setIsAddEventModalOpened(false);
        });
      })
      .catch((error) => {
        setLoading(false);
        setStatus(false, () => {}, error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getAnnouncementsHandler(false);
    isEmployeeAnnouncer();
  }, []);

  useEffect(() => {
    const intervalAnnouncements = setInterval(() => {
      getAnnouncementsHandler(false);
    }, 180000);

    if (!generalStore.intervalChange) {
      clearInterval(intervalAnnouncements);
    }

    return () => {
      clearInterval(intervalAnnouncements);
      setAnnouncements({
        items: [],
        pagination: {},
      });
    };
  }, [generalStore.intervalChange]);

  return (
    <>
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        className='scrollbarsWrapper'
        hideTracksWhenNotNeeded
        style={{ height: '100%' }}
        onScrollFrame={(e) => handleScroll(e)}
      >
        <div className='pt-2 px-2a pb-3'>
          {(isAnnouncer || hasPermission) && (
            <div className={cx(styles.cardWrapper, 'd-flex justify-content-between align-items-center mb-3 ')}>
              <p className={styles.sortedText}>{t('GENERAL.LATEST_POSTS')}</p>
              <div className='position-relative'>
                <Menu
                  data={createSelectOptions(actions)}
                  onAction={actionHandler}
                  style={{ width: '240px' }}
                  icon='icon-plus'
                  iconSize='md'
                />
              </div>
            </div>
          )}
          {loading && isInit ? (
            <SkeletonAnnouncement />
          ) : (
            announcements.items.map((el) => (
              <AnnouncementCard
                key={el.id}
                isAnnouncer={isAnnouncer}
                className='mb-3'
                data={el}
                onReaction={onReaction}
                onEdit={(data) => {
                  setModalData(data);
                  setIsModalOpened(true);
                }}
                onDelete={openConfirmModal}
                onOpen={(data) => {
                  setDetailsModalData(data);
                  setIsDetailsModalOpen(true);
                }}
              />
            ))
          )}
        </div>
      </Scrollbars>

      <Modal
        showDirtyForm
        showHeader={false}
        isOpen={isModalOpened}
        styles={{ width: '90vw', maxWidth: '1560px', padding: '0' }}
        onRequestClose={onModalClose}
      >
        <AddAnnouncement selected={modalData} update={getAnnouncementsHandler} />
      </Modal>

      <Modal
        showDirtyForm
        title={t('GENERAL.NEW_EVENT')}
        isOpen={openAddEventModal}
        styles={{ width: '640px', padding: '0' }}
        onRequestClose={() => {
          onModalClose(false);
        }}
      >
        <AddEventModal
          onClose={onModalClose}
          onSave={addCustomEvent}
          saving={loading}
          responseStatus={responseStatus}
        />
      </Modal>

      <Modal
        showHeader={false}
        isOpen={isConfirmModalOpened}
        styles={{ width: '640px' }}
        onRequestClose={() => {
          setIsConfirmModalOpened(false);
        }}
      >
        <ConfirmationBox
          close={() => {
            setIsConfirmModalOpened(false);
          }}
          action={() => {
            onDeleteAnnouncement();
          }}
          text='DAHBOARD.DELETE_ANNOUNCEMENT'
          title='GENERAL.DELETE'
          sending={deleting}
          type='danger'
          responseStatus={responseStatus}
        />
      </Modal>

      <Modal
        showHeader={false}
        isOpen={isDetailsModalOpen}
        styles={{
          width: '90vw',
          maxWidth: detailsModalData?.image || detailsModalData?.isDefault ? '1600px' : '1200px',
          height: '90vh',
          padding: '0',
        }}
        onRequestClose={() => {
          setIsDetailsModalOpen(false);
        }}
      >
        <AnnouncementDetails
          selected={detailsModalData}
          close={() => {
            setIsDetailsModalOpen(false);
          }}
          update={() => {
            getAnnouncementsHandler(false);
          }}
          onReaction={onReaction}
        />
      </Modal>
    </>
  );
};

export default Announcement;
