import axios from 'axios';

import http from './Api';
import returnTranslationUrl from '../utility/returnTranslationUrl';

class TranslationsApi {
  static getTranslations(lang) {
    const url = returnTranslationUrl(lang);
    const config = url.includes('.json')
      ? {}
      : {
          headers: {
            tenant: new URL(window.location.href).hostname.split('.')[0],
          },
        };

    return axios.get(`${process.env.REACT_APP_BASE_URL}${url}`, config);
  }

  static setLanguage(data) {
    return http.put('me/language', data);
  }
}

export default TranslationsApi;
