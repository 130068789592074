// reports
const REPORT_PAYROLL_HISTORY = 'payroll_history';
const REPORT_TOTAL_EOS_ACCRUAL = 'total_eos_accrual';
const REPORT_ATTENDANCE = 'attendance';
const REPORT_CUSTOM_ATTENDANCE = 'custom_attendance';
const REPORT_MONTHLY_EOS_ACCRUAL = 'monthly_eos_accrual';
const REPORT_VACATION_ACCRUAL = 'vacation_accrual';
const REPORT_LOAN = 'loan';
const REPORT_HEADCOUNT = 'headcount';
const REPORT_TEAM_DETAILS = 'team_details';
const REPORT_ORGANIZATION = 'organization';
const REPORT_FLUCTUATION = 'fluctuation';
const REPORT_DEMOGRAPHICS = 'demographics';
const REPORT_CONTRACT_DETAIL = 'contract_detail';
const REPORT_LENGTH_OF_SERVICE = 'length_of_service';
const REPORT_PROBATION_PERIODS = 'probation_periods';
const REPORT_DEPENDANTS = 'dependants';
const REPORT_OBJECTIVES = 'objectives';
const REPORT_WORK_SCHEDULES = 'work_schedules';
const REPORT_VACATION_POLICY = 'vacation_policy';
const REPORT_VACATION_BALANCE = 'vacation_balance';
const REPORT_LEAVE = 'leave';

// column names, shared among reports
const COLUMN_NAME_ID = 'id';
const COLUMN_NAME_EMPLOYEE_NAME = 'employee_name';
const COLUMN_NAME_MONTH_OF_SERVICE = 'months_of_service';
const COLUMN_NAME_PACKAGE_SALARY = 'package_salary';
const COLUMN_NAME_TOTAL_ACCRUAL = 'total_accrual';
const COLUMN_NAME_ACCRUAL_LIABILITY = 'accrual_liability';
const COLUMN_NAME_EMPLOYMENT_STATUS = 'employment_status';
const COLUMN_NAME_EMPLOYMENT_TYPE = 'employment_type';
const COLUMN_NAME_PAYMENT_METHODE = 'payment_methode';
const COLUMN_NAME_BANK_NAME = 'bank_name';
const COLUMN_NAME_IBAN = 'iban';
const COLUMN_NAME_SWIFT = 'swift';
const COLUMN_NAME_BIC = 'bic';
const COLUMN_NAME_BASIC_SALARY = 'basic_salary';
const COLUMN_NAME_HOUSING = 'housing';
const COLUMN_NAME_TRANSPORTATION = 'transportation';
const COLUMN_NAME_OTHER = 'other';
const COLUMN_NAME_OVERTIME_ADDITION = 'overtime_addition';
const COLUMN_NAME_GROSS_PAY = 'gross_pay';
const COLUMN_NAME_NET_PAY = 'net_pay';
const COLUMN_NAME_TAXES_AND_CONTRIBUTIONS = 'taxes_and_contributions';
const COLUMN_NAME_EXPENSE_CLAIM_PAYMENT = 'expense_claim_payment';
const COLUMN_NAME_LOAN_PAYMENT = 'loan_payment';
const COLUMN_NAME_SALARY_ADVANCE_PAYMENT = 'salary_advance_payment';
const COLUMN_NAME_VACATION_ADVANCE_PAYMENT = 'vacation_advance_payment';
const COLUMN_NAME_END_OF_SERVICE_BENEFITS = 'end_of_service_benefits';
const COLUMN_NAME_OVERTIME = 'overtime';
const COLUMN_NAME_UNDERTIME = 'undertime';
const COLUMN_NAME_DAY_AVERAGE = 'day_average';
const COLUMN_NAME_WEEK_AVERAGE = 'week_average';
const COLUMN_NAME_PERIOD = 'period';
const COLUMN_NAME_TEAM = 'team';
const COLUMN_NAME_TOTAL_WORK_HOURS = 'total_work_hours';
const COLUMN_NAME_LATE_CHECK_INS = 'late_check_ins';
const COLUMN_NAME_EARLY_CHECK_OUTS = 'early_check_outs';
const COLUMN_NAME_TOTAL_UNDERTIME = 'total_undertime';
const COLUMN_NAME_TOTAL_OVERTIME = 'total_overtime';
const COLUMN_NAME_APPROVED_OVERTIME = 'approved_overtime';
const COLUMN_NAME_PRESENCE_DAYS = 'presence_days';
const COLUMN_NAME_STAMP_MISSING_DAYS = 'stamp_missing_days';
const COLUMN_NAME_VACATION_DAYS = 'vacation_days';
const COLUMN_NAME_SICK_LEAVE_DAYS = 'sick_leave_days';
const COLUMN_NAME_SPECIAL_LEAVE_DAYS = 'special_leave_days';
const COLUMN_NAME_UNPAID_LEAVE_DAYS = 'unpaid_leave_days';
const COLUMN_NAME_BUSINESS_TRIP_DAYS = 'business_trip_days';
const COLUMN_NAME_REMOTE_WORK_DAYS = 'remote_work_days';
const COLUMN_NAME_LEAVE_DAYS = 'leave_days';
const COLUMN_NAME_LEAVE_NON_WORKING_DAYS = 'leave_non_working_days';
const COLUMN_NAME_NON_WORKING_DAYS = 'non_working_days';
const COLUMN_NAME_WORKING_DAYS = 'working_days';
const COLUMN_NAME_ABSENCE_DAYS = 'absence_days';
const COLUMN_NAME_DEPARTMENT = 'department';
const COLUMN_NAME_MONTHLY_ACCRUAL = 'monthly_accrual';
const COLUMN_NAME_VACATION_POLICY = 'vacation_policy';
const COLUMN_NAME_BALANCE = 'balance';
const COLUMN_NAME_VACATION_ACCRUAL = 'vacation_accrual';
const COLUMN_NAME_UNDERTIME_DEDUCTION = 'undertime_deduction';
const COLUMN_NAME_UNPAID_LEAVE_DEDUCTION = 'unpaid_leave_deduction';
const COLUMN_NAME_SICK_LEAVE_DEDUCTION = 'sick_leave_deduction';
const COLUMN_NAME_LOAN_REPAYMENT = 'loan_repayment';
const COLUMN_NAME_TRAFFIC_VIOLATION = 'traffic_violation';
const COLUMN_NAME_TOTAL_ADDITIONS = 'total_additions';
const COLUMN_NAME_SALARY_ADVANCE_REPAYMENT = 'salary_advance_repayment';
const COLUMN_NAME_VACATION_ADVANCE_REPAYMENT = 'vacation_advance_repayment';
const COLUMN_NAME_TOTAL_DEDUCTION = 'total_deduction';
const COLUMN_NAME_END_DATE = 'end_date';
const COLUMN_NAME_INSTALLMENT = 'installment';
const COLUMN_NAME_LOAN_AMOUNT = 'loan_amount';
const COLUMN_NAME_PENDING = 'pending';
const COLUMN_NAME_START_DATE = 'start_date';
const COLUMN_NAME_PENDING_ITEMS = 'pending_items';
const COLUMN_NAME_FINAL_SALARY = 'final_salary';
const COLUMN_NAME_POTENTIAL_LIABILITY = 'potential_liability';
const COLUMN_NAME_HIRE_DATE = 'hire_date';
const COLUMN_NAME_OFF_BOARD_DATE = 'off_board_date';
const COLUMN_NAME_NATIONAL_ID = 'national_id';
const COLUMN_NAME_PASSPORT_NUMBER = 'passport_number';
const COLUMN_NAME_LOCATION = 'location';
const COLUMN_NAME_LINE_MANAGER = 'line_manager';
const COLUMN_NAME_BIRTH_DATE = 'birth_date';
const COLUMN_NAME_GENDER = 'gender';
const COLUMN_NAME_RELIGION = 'religion';
const COLUMN_NAME_NATIONALITY = 'nationality';
const COLUMN_NAME_WORK_EMAIL = 'work_email';
const COLUMN_NAME_PRIVATE_EMAIL = 'private_email';
const COLUMN_NAME_WORK_PHONE_NUMBER = 'work_phone_number';
const COLUMN_NAME_PRIVATE_PHONE_NUMBER = 'private_phone_number';
const COLUMN_NAME_CURRENT_CONTRACT_START_DATE = 'current_contract_start_date';
const COLUMN_NAME_CURRENT_CONTRACT_END_DATE = 'current_contract_end_date';
const COLUMN_NAME_PROBATION_PERIOD = 'probation_period';
const COLUMN_NAME_WORK_SCHEDULE = 'work_schedule';
const COLUMN_NAME_EMERGENCY_CONTACT = 'emergency_contact';
const COLUMN_NAME_EDUCATION = 'education';
const COLUMN_NAME_YEARS_OF_SERVICE = 'years_of_service';
const COLUMN_NAME_MARITAL_STATUS = 'marital_status';
const COLUMN_NAME_AGE = 'age';
const COLUMN_NAME_CURRENCY = 'currency';
const COLUMN_NAME_REMAINING_PERIOD = 'remaining_period';
const COLUMN_NAME_NOTICE_PERIOD = 'notice_period';
const COLUMN_NAME_DAYS_LEFT = 'days_left';
const COLUMN_NAME_DAYS = 'days';
const COLUMN_NAME_RELATIONSHIP = 'relationship';
const COLUMN_NAME_DEPENDANT = 'dependant';
const COLUMN_NAME_ID_NUMBER = 'id_number';
const COLUMN_NAME_OBJECTIVE = 'objective';
const COLUMN_NAME_DESCRIPTION = 'description';
const COLUMN_NAME_PROGRESS = 'progress';
const COLUMN_NAME_WEIGHT = 'weight';
const COLUMN_NAME_DUE_DATE = 'due_date';
const COLUMN_NAME_OPENING_BALANCE = 'opening_balance';
const COLUMN_NAME_ACCRUED = 'accrued';
const COLUMN_NAME_CONSUMED = 'consumed';
const COLUMN_NAME_ADJUSTMENTS = 'adjustments';
const COLUMN_NAME_LEAVE_TYPE = 'leave_type';
const COLUMN_NAME_ABSENCE_PERIOD = 'absence_period';
const COLUMN_NAME_RETURN_DATE = 'return_date';
const COLUMN_NAME_JOB_TITLE = 'job_title';
const COLUMN_NAME_TOTAL_EXCUSE_TIME = 'total_excuse_time';
const COLUMN_NAME_DURATION = 'duration';
const COLUMN_JOB_CUSTOM_DETAILS = 'job_custom_details';
const COLUMN_MANUAL_ADDITIONS = 'manual_additions';
const COLUMN_MANUAL_DEDUCTIONS = 'manual_deductions';
const COLUMN_NAME_LEGAL_ENTITY = 'legal_entity';

// eslint-disable-next-line import/prefer-default-export
export const reportConstants = {
  // reports
  REPORT_PAYROLL_HISTORY,
  REPORT_TOTAL_EOS_ACCRUAL,
  REPORT_ATTENDANCE,
  REPORT_CUSTOM_ATTENDANCE,
  REPORT_MONTHLY_EOS_ACCRUAL,
  REPORT_VACATION_ACCRUAL,
  REPORT_LOAN,
  REPORT_HEADCOUNT,
  REPORT_TEAM_DETAILS,
  REPORT_ORGANIZATION,
  REPORT_FLUCTUATION,
  REPORT_DEMOGRAPHICS,
  REPORT_CONTRACT_DETAIL,
  REPORT_LENGTH_OF_SERVICE,
  REPORT_PROBATION_PERIODS,
  REPORT_DEPENDANTS,
  REPORT_OBJECTIVES,
  REPORT_WORK_SCHEDULES,
  REPORT_VACATION_POLICY,
  REPORT_VACATION_BALANCE,
  REPORT_LEAVE,
  REPORTS: [
    REPORT_PAYROLL_HISTORY,
    REPORT_TOTAL_EOS_ACCRUAL,
    REPORT_ATTENDANCE,
    REPORT_CUSTOM_ATTENDANCE,
    REPORT_MONTHLY_EOS_ACCRUAL,
    REPORT_LOAN,
    REPORT_VACATION_ACCRUAL,
    REPORT_HEADCOUNT,
    REPORT_TEAM_DETAILS,
    REPORT_ORGANIZATION,
    REPORT_FLUCTUATION,
    REPORT_DEMOGRAPHICS,
    REPORT_LENGTH_OF_SERVICE,
    REPORT_CONTRACT_DETAIL,
    REPORT_PROBATION_PERIODS,
    REPORT_DEPENDANTS,
    REPORT_OBJECTIVES,
    REPORT_WORK_SCHEDULES,
    REPORT_VACATION_POLICY,
    REPORT_VACATION_BALANCE,
    REPORT_LEAVE,
  ],

  // columns
  COLUMN_NAME_ID,
  COLUMN_NAME_EMPLOYEE_NAME,
  COLUMN_NAME_MONTH_OF_SERVICE,
  COLUMN_NAME_PACKAGE_SALARY,
  COLUMN_NAME_TOTAL_ACCRUAL,
  COLUMN_NAME_ACCRUAL_LIABILITY,
  COLUMN_NAME_EMPLOYMENT_STATUS,
  COLUMN_NAME_EMPLOYMENT_TYPE,
  COLUMN_NAME_PAYMENT_METHODE,
  COLUMN_NAME_BANK_NAME,
  COLUMN_NAME_IBAN,
  COLUMN_NAME_SWIFT,
  COLUMN_NAME_BIC,
  COLUMN_NAME_BASIC_SALARY,
  COLUMN_NAME_HOUSING,
  COLUMN_NAME_TRANSPORTATION,
  COLUMN_NAME_OTHER,
  COLUMN_NAME_OVERTIME_ADDITION,
  COLUMN_NAME_GROSS_PAY,
  COLUMN_NAME_TAXES_AND_CONTRIBUTIONS,
  COLUMN_NAME_EXPENSE_CLAIM_PAYMENT,
  COLUMN_NAME_LOAN_PAYMENT,
  COLUMN_NAME_SALARY_ADVANCE_PAYMENT,
  COLUMN_NAME_VACATION_ADVANCE_PAYMENT,
  COLUMN_NAME_END_OF_SERVICE_BENEFITS,
  COLUMN_NAME_OVERTIME,
  COLUMN_NAME_UNDERTIME,
  COLUMN_NAME_DAY_AVERAGE,
  COLUMN_NAME_WEEK_AVERAGE,
  COLUMN_NAME_PERIOD,
  COLUMN_NAME_TEAM,
  COLUMN_NAME_TOTAL_WORK_HOURS,
  COLUMN_NAME_LATE_CHECK_INS,
  COLUMN_NAME_EARLY_CHECK_OUTS,
  COLUMN_NAME_TOTAL_UNDERTIME,
  COLUMN_NAME_TOTAL_OVERTIME,
  COLUMN_NAME_APPROVED_OVERTIME,
  COLUMN_NAME_PRESENCE_DAYS,
  COLUMN_NAME_STAMP_MISSING_DAYS,
  COLUMN_NAME_VACATION_DAYS,
  COLUMN_NAME_SICK_LEAVE_DAYS,
  COLUMN_NAME_SPECIAL_LEAVE_DAYS,
  COLUMN_NAME_UNPAID_LEAVE_DAYS,
  COLUMN_NAME_BUSINESS_TRIP_DAYS,
  COLUMN_NAME_REMOTE_WORK_DAYS,
  COLUMN_NAME_LEAVE_DAYS,
  COLUMN_NAME_LEAVE_NON_WORKING_DAYS,
  COLUMN_NAME_NON_WORKING_DAYS,
  COLUMN_NAME_WORKING_DAYS,
  COLUMN_NAME_ABSENCE_DAYS,
  COLUMN_NAME_DEPARTMENT,
  COLUMN_NAME_MONTHLY_ACCRUAL,
  COLUMN_NAME_END_DATE,
  COLUMN_NAME_INSTALLMENT,
  COLUMN_NAME_LOAN_AMOUNT,
  COLUMN_NAME_PENDING,
  COLUMN_NAME_START_DATE,
  COLUMN_NAME_VACATION_POLICY,
  COLUMN_NAME_BALANCE,
  COLUMN_NAME_VACATION_ACCRUAL,
  COLUMN_NAME_NET_PAY,
  COLUMN_NAME_UNDERTIME_DEDUCTION,
  COLUMN_NAME_UNPAID_LEAVE_DEDUCTION,
  COLUMN_NAME_SICK_LEAVE_DEDUCTION,
  COLUMN_NAME_LOAN_REPAYMENT,
  COLUMN_NAME_TRAFFIC_VIOLATION,
  COLUMN_NAME_TOTAL_ADDITIONS,
  COLUMN_NAME_SALARY_ADVANCE_REPAYMENT,
  COLUMN_NAME_VACATION_ADVANCE_REPAYMENT,
  COLUMN_NAME_TOTAL_DEDUCTION,
  COLUMN_NAME_PENDING_ITEMS,
  COLUMN_NAME_FINAL_SALARY,
  COLUMN_NAME_POTENTIAL_LIABILITY,
  COLUMN_NAME_HIRE_DATE,
  COLUMN_NAME_OFF_BOARD_DATE,
  COLUMN_NAME_NATIONAL_ID,
  COLUMN_NAME_PASSPORT_NUMBER,
  COLUMN_NAME_LOCATION,
  COLUMN_NAME_LINE_MANAGER,
  COLUMN_NAME_BIRTH_DATE,
  COLUMN_NAME_GENDER,
  COLUMN_NAME_RELIGION,
  COLUMN_NAME_NATIONALITY,
  COLUMN_NAME_WORK_EMAIL,
  COLUMN_NAME_PRIVATE_EMAIL,
  COLUMN_NAME_WORK_PHONE_NUMBER,
  COLUMN_NAME_PRIVATE_PHONE_NUMBER,
  COLUMN_NAME_CURRENT_CONTRACT_START_DATE,
  COLUMN_NAME_CURRENT_CONTRACT_END_DATE,
  COLUMN_NAME_PROBATION_PERIOD,
  COLUMN_NAME_WORK_SCHEDULE,
  COLUMN_NAME_EMERGENCY_CONTACT,
  COLUMN_NAME_EDUCATION,
  COLUMN_NAME_YEARS_OF_SERVICE,
  COLUMN_NAME_MARITAL_STATUS,
  COLUMN_NAME_AGE,
  COLUMN_NAME_CURRENCY,
  COLUMN_NAME_REMAINING_PERIOD,
  COLUMN_NAME_NOTICE_PERIOD,
  COLUMN_NAME_DAYS_LEFT,
  COLUMN_NAME_DAYS,
  COLUMN_NAME_RELATIONSHIP,
  COLUMN_NAME_DEPENDANT,
  COLUMN_NAME_ID_NUMBER,
  COLUMN_NAME_OBJECTIVE,
  COLUMN_NAME_DESCRIPTION,
  COLUMN_NAME_PROGRESS,
  COLUMN_NAME_WEIGHT,
  COLUMN_NAME_DUE_DATE,
  COLUMN_NAME_OPENING_BALANCE,
  COLUMN_NAME_ACCRUED,
  COLUMN_NAME_CONSUMED,
  COLUMN_NAME_ADJUSTMENTS,
  COLUMN_NAME_LEAVE_TYPE,
  COLUMN_NAME_ABSENCE_PERIOD,
  COLUMN_NAME_RETURN_DATE,
  COLUMN_NAME_JOB_TITLE,
  COLUMN_NAME_TOTAL_EXCUSE_TIME,
  COLUMN_NAME_DURATION,
  COLUMN_JOB_CUSTOM_DETAILS,
  COLUMN_MANUAL_ADDITIONS,
  COLUMN_MANUAL_DEDUCTIONS,
  COLUMN_NAME_LEGAL_ENTITY,
  COLUMNS: [
    COLUMN_NAME_DURATION,
    COLUMN_NAME_ID,
    COLUMN_NAME_EMPLOYEE_NAME,
    COLUMN_NAME_MONTH_OF_SERVICE,
    COLUMN_NAME_PACKAGE_SALARY,
    COLUMN_NAME_TOTAL_ACCRUAL,
    COLUMN_NAME_ACCRUAL_LIABILITY,
    COLUMN_NAME_EMPLOYMENT_STATUS,
    COLUMN_NAME_EMPLOYMENT_TYPE,
    COLUMN_NAME_PAYMENT_METHODE,
    COLUMN_NAME_BANK_NAME,
    COLUMN_NAME_IBAN,
    COLUMN_NAME_SWIFT,
    COLUMN_NAME_BIC,
    COLUMN_NAME_BASIC_SALARY,
    COLUMN_NAME_HOUSING,
    COLUMN_NAME_TRANSPORTATION,
    COLUMN_NAME_OTHER,
    COLUMN_NAME_OVERTIME_ADDITION,
    COLUMN_NAME_NET_PAY,
    COLUMN_NAME_TAXES_AND_CONTRIBUTIONS,
    COLUMN_NAME_EXPENSE_CLAIM_PAYMENT,
    COLUMN_NAME_LOAN_PAYMENT,
    COLUMN_NAME_SALARY_ADVANCE_PAYMENT,
    COLUMN_NAME_VACATION_ADVANCE_PAYMENT,
    COLUMN_NAME_END_OF_SERVICE_BENEFITS,
    COLUMN_NAME_OVERTIME,
    COLUMN_NAME_UNDERTIME,
    COLUMN_NAME_DAY_AVERAGE,
    COLUMN_NAME_WEEK_AVERAGE,
    COLUMN_NAME_PERIOD,
    COLUMN_NAME_TEAM,
    COLUMN_NAME_TOTAL_WORK_HOURS,
    COLUMN_NAME_LATE_CHECK_INS,
    COLUMN_NAME_EARLY_CHECK_OUTS,
    COLUMN_NAME_TOTAL_UNDERTIME,
    COLUMN_NAME_TOTAL_OVERTIME,
    COLUMN_NAME_APPROVED_OVERTIME,
    COLUMN_NAME_PRESENCE_DAYS,
    COLUMN_NAME_STAMP_MISSING_DAYS,
    COLUMN_NAME_VACATION_DAYS,
    COLUMN_NAME_SICK_LEAVE_DAYS,
    COLUMN_NAME_SPECIAL_LEAVE_DAYS,
    COLUMN_NAME_UNPAID_LEAVE_DAYS,
    COLUMN_NAME_BUSINESS_TRIP_DAYS,
    COLUMN_NAME_REMOTE_WORK_DAYS,
    COLUMN_NAME_LEAVE_DAYS,
    COLUMN_NAME_LEAVE_NON_WORKING_DAYS,
    COLUMN_NAME_NON_WORKING_DAYS,
    COLUMN_NAME_WORKING_DAYS,
    COLUMN_NAME_ABSENCE_DAYS,
    COLUMN_NAME_DEPARTMENT,
    COLUMN_NAME_MONTHLY_ACCRUAL,
    COLUMN_NAME_END_DATE,
    COLUMN_NAME_INSTALLMENT,
    COLUMN_NAME_LOAN_AMOUNT,
    COLUMN_NAME_PENDING,
    COLUMN_NAME_START_DATE,
    COLUMN_NAME_VACATION_POLICY,
    COLUMN_NAME_BALANCE,
    COLUMN_NAME_VACATION_ACCRUAL,
    COLUMN_NAME_UNDERTIME_DEDUCTION,
    COLUMN_NAME_UNPAID_LEAVE_DEDUCTION,
    COLUMN_NAME_SICK_LEAVE_DEDUCTION,
    COLUMN_NAME_LOAN_REPAYMENT,
    COLUMN_NAME_TRAFFIC_VIOLATION,
    COLUMN_NAME_TOTAL_ADDITIONS,
    COLUMN_NAME_SALARY_ADVANCE_REPAYMENT,
    COLUMN_NAME_VACATION_ADVANCE_REPAYMENT,
    COLUMN_NAME_TOTAL_DEDUCTION,
    COLUMN_NAME_PENDING_ITEMS,
    COLUMN_NAME_FINAL_SALARY,
    COLUMN_NAME_POTENTIAL_LIABILITY,
    COLUMN_NAME_GROSS_PAY,
    COLUMN_NAME_HIRE_DATE,
    COLUMN_NAME_OFF_BOARD_DATE,
    COLUMN_NAME_PASSPORT_NUMBER,
    COLUMN_NAME_NATIONAL_ID,
    COLUMN_NAME_HIRE_DATE,
    COLUMN_NAME_LOCATION,
    COLUMN_NAME_LINE_MANAGER,
    COLUMN_NAME_BIRTH_DATE,
    COLUMN_NAME_GENDER,
    COLUMN_NAME_RELIGION,
    COLUMN_NAME_NATIONALITY,
    COLUMN_NAME_WORK_EMAIL,
    COLUMN_NAME_PRIVATE_EMAIL,
    COLUMN_NAME_WORK_PHONE_NUMBER,
    COLUMN_NAME_PRIVATE_PHONE_NUMBER,
    COLUMN_NAME_CURRENT_CONTRACT_START_DATE,
    COLUMN_NAME_CURRENT_CONTRACT_END_DATE,
    COLUMN_NAME_PROBATION_PERIOD,
    COLUMN_NAME_WORK_SCHEDULE,
    COLUMN_NAME_EMERGENCY_CONTACT,
    COLUMN_NAME_EDUCATION,
    COLUMN_NAME_YEARS_OF_SERVICE,
    COLUMN_NAME_MARITAL_STATUS,
    COLUMN_NAME_AGE,
    COLUMN_NAME_CURRENCY,
    COLUMN_NAME_REMAINING_PERIOD,
    COLUMN_NAME_NOTICE_PERIOD,
    COLUMN_NAME_DAYS_LEFT,
    COLUMN_NAME_DAYS,
    COLUMN_NAME_DEPENDANT,
    COLUMN_NAME_ID_NUMBER,
    COLUMN_NAME_OBJECTIVE,
    COLUMN_NAME_DESCRIPTION,
    COLUMN_NAME_PROGRESS,
    COLUMN_NAME_WEIGHT,
    COLUMN_NAME_DUE_DATE,
    COLUMN_NAME_OPENING_BALANCE,
    COLUMN_NAME_ACCRUED,
    COLUMN_NAME_CONSUMED,
    COLUMN_NAME_ADJUSTMENTS,
    COLUMN_NAME_LEAVE_TYPE,
    COLUMN_NAME_ABSENCE_PERIOD,
    COLUMN_NAME_RETURN_DATE,
    COLUMN_NAME_JOB_TITLE,
    COLUMN_NAME_TOTAL_EXCUSE_TIME,
    COLUMN_JOB_CUSTOM_DETAILS,
    COLUMN_MANUAL_ADDITIONS,
    COLUMN_MANUAL_DEDUCTIONS,
  ],
};
