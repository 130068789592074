import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

const Circle = ({ style, gradient, className, size, removeMarginBottom }) => (
  <div
    className={cx(gradient, 'rounded-circle', removeMarginBottom ? '' : 'mb-2', className)}
    style={{ ...style, width: size, height: size }}
  />
);

Circle.propTypes = {
  gradient: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.number,
  removeMarginBottom: PropTypes.bool,
};

Circle.defaultProps = {
  className: '',
  size: 32,
  style: {
    opacity: 0.7,
  },
  removeMarginBottom: false,
};

export default React.memo(Circle);
