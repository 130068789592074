import React, { useContext } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { AuthContext } from 'auth/AuthContext';
import readName from 'utility/readName';

import styles from './Personas.module.scss';
import resourcePath from '../shared/resourcePath';

const Personas = ({
  user,
  size,
  customClass,
  showDetails,
  iconStatus,
  customText,
  isCustomAvatarUrl,
  isHideAvatar,
}) => {
  const { authState } = useContext(AuthContext);

  const getInitals = (nam) => {
    const matches = nam.match(/\b(\w)/g);
    const acronym = matches ? matches.join('').slice(0, 2) : 'NN';
    return acronym;
  };

  const getJobTitle = (emp) => {
    const jobObj = {};
    if (emp?.jobTitle) {
      jobObj.name = emp.jobTitle;
      jobObj.nameArabic = emp.jobTitleArabic;
    } else if (user?.employment?.jobTitle) {
      jobObj.name = emp?.employment?.jobTitle?.name;
      jobObj.nameArabic = emp?.employment?.jobTitle?.nameArabic;
    } else if (emp?.job) {
      jobObj.name = emp?.job?.name;
      jobObj.nameArabic = emp?.job?.nameArabic;
    } else if (emp?.activeJob) {
      jobObj.name = emp?.activeJob?.name;
      jobObj.nameArabic = emp?.jactiveJob?.nameArabic;
    }
    return readName(authState.user.language, jobObj?.name, jobObj?.nameArabic);
  };

  const employeeName = readName(authState.user.language, user?.fullName, user?.fullNameArabic);

  const avatar = isHideAvatar
    ? null
    : isCustomAvatarUrl || user?.avatarThumb?.includes('static')
    ? user.avatarThumb
    : resourcePath(user?.avatarThumb);

  return (
    <div className={cx(customClass, 'd-flex align-items-center position-relative')}>
      <div
        className={cx(
          styles.profileImage,
          {
            [styles.primary]: !avatar,
          },
          'image-profle'
        )}
        style={{ width: `${size}px`, height: `${size}px`, minWidth: `${size}px`, minHeight: `${size}px` }}
      >
        {avatar ? (
          <img className={styles.img} src={avatar} alt={employeeName} />
        ) : (
          <span className={cx(styles.initials, 'text-white')} style={{ fontSize: `${Math.round(size * 0.4)}px` }}>
            {user && user?.fullName
              ? `${getInitals(user?.fullName)}`
              : user?.firstName && user?.lastName
              ? `${user?.firstName[0].toUpperCase()}${user?.lastName[0].toUpperCase()}`
              : 'NN'}
          </span>
        )}
      </div>
      {showDetails && (
        <div className={cx(size > 32 ? 'ms-3' : 'ms-2a')} style={{ width: `calc(100% - 0.5rem - ${size}px)` }}>
          <div className='d-flex justify-content-start'>
            <p className={cx(styles.name, 'text-truncate mb-0')}>{employeeName || '- -'}</p>
            {iconStatus || ''}
          </div>
          {size >= 32 && <p className={styles.job}>{getJobTitle(user) || '- -'}</p>}
        </div>
      )}

      {customText && (
        <div className={cx(size > 32 ? 'ms-3' : 'ms-2a')} style={{ width: `calc(100% - 0.5rem - ${size}px)` }}>
          <div className='d-flex justify-content-start'>
            <p className={cx(styles.name, 'text-truncate mb-0')}>{employeeName || '- -'}</p>
            {iconStatus || ''}
          </div>
          <p className={styles.job}>{customText}</p>
        </div>
      )}
    </div> //
  );
};

Personas.propTypes = {
  user: PropTypes.object.isRequired,
  size: PropTypes.number,
  showDetails: PropTypes.bool,
  isCustomAvatarUrl: PropTypes.bool,
  customClass: PropTypes.string,
  iconStatus: PropTypes.element,
  customText: PropTypes.string,
};

Personas.defaultProps = {
  size: 32,
  showDetails: false,
  isCustomAvatarUrl: false,
  customClass: '',
  iconStatus: undefined,
  customText: '',
};

export default Personas;
