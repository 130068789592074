import React, { useContext, useState } from 'react';

import cx from 'classnames';

import { useDepartmentFilters } from 'api/Filters/FiltersAPIUse';
import { AuthContext } from 'auth/AuthContext';
import { defaultProps, propTypes, formatDisplay } from 'components/Filters/Department/utility';
import MultipleSelectField from 'components/forms/components/MultipleSelectField';
import DepartmentCollection from 'services/Department/DepartmentCollection';

const DepartmentFilter = ({
  value,
  name,
  label,
  className,
  onChange,
  isDisabled,
  isRequired,
  showLabel,
  placeholder,
  isSearchDesign,
}) => {
  const { authState } = useContext(AuthContext);
  const [selectedDepartments, setSelectedDepartments] = useState(value);
  const { isFetching, data: responseData } = useDepartmentFilters({ perPage: 1000 });
  const departments = DepartmentCollection.create(responseData?.data?.items ?? []);

  const onValueChange = (newValue) => {
    setSelectedDepartments(newValue);
    if (onChange) onChange(newValue);
  };

  return (
    <MultipleSelectField
      className={cx('min-h-unset', className)}
      label={formatDisplay(showLabel ? label : '')}
      name={formatDisplay(name)}
      placeholder={formatDisplay(placeholder)}
      options={departments.getItemsAsOptions(authState)}
      value={selectedDepartments}
      onChange={(fieldName, departmentData) => onValueChange(departmentData)}
      isDisabled={isFetching || isDisabled}
      isRequired={isRequired}
      isFormik={false}
      isSearchDesign={isSearchDesign}
    />
  );
};

DepartmentFilter.propTypes = propTypes;
DepartmentFilter.defaultProps = defaultProps;

export default DepartmentFilter;
