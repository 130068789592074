import React, { useState, useEffect, useContext, Suspense, lazy } from 'react';

import cx from 'classnames';
import { Navigate, Route, Routes } from 'react-router-dom';

import CompanySettingsApi from 'api/CompanySettingsApi';
import { AuthContext } from 'auth/AuthContext';
import BackgroundCropper from 'components/Croppers/BackgroundCropper';
import Modal from 'components/Modal';
import AreaLoader from 'components/Spinner/AreaLoader';
import { MODULES_CONFIGS } from 'constants/moduleConfigurations';
import { useModulePermissions } from 'shared/useModulePermissions';
import CustomRoute from 'topNavigation/Router/CustomRoute';

import styles from './CompanySettings.module.scss';
import Header from './Header/Header';
import resourcePath from '../../shared/resourcePath';

const Assets = lazy(() => import('./Assets/Assets'));
const Access = lazy(() => import('./Access/Access'));
const Integrations = lazy(() => import('./Integrations/Integrations'));
const Documents = lazy(() => import('./Documents/Documents'));
const Organization = lazy(() => import('./Organization/Organization'));
const Permissions = lazy(() => import('./Permissions/Permissions'));
const Requests = lazy(() => import('./Requests/Requests'));
const Subscription = lazy(() => import('./Subscription/Subscription'));
const Tasks = lazy(() => import('./Tasks/Tasks'));
const Team = lazy(() => import('./Team/Team'));
const Time = lazy(() => import('./Time/Time'));
const Pay = lazy(() => import('./Pay/Pay'));
const Recruitment = React.lazy(() => import('./Recruitment/Recruitment'));

const CompanySettings = () => {
  const { authState, dispatch } = useContext(AuthContext);
  const [imageFile, setImageFile] = useState(null);
  const [isCropperOpened, setIsCropperOpened] = useState(false);
  const [sendingImage, setSendingImage] = useState(false);
  const { isModuleEnabled: isRecruitmentModuleEnabled } = useModulePermissions(MODULES_CONFIGS.RECRUITMENT.NAME);
  const { isModuleEnabled: isBillingModuleEnabled } = useModulePermissions(MODULES_CONFIGS.BILLING_SECTION.NAME);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    const body = document.querySelector('body');
    body.classList.add('no-scroll');
    return () => {
      body.classList.remove('no-scroll');
    };
  }, []);

  const onFileChange = (e) => {
    e.persist();
    setImageFile(e.target.files[0]);
    setIsCropperOpened(true);
  };

  const closeCropper = () => {
    setIsCropperOpened(false);
    setImageFile(null);
  };

  const onSaveBackground = (data) => {
    setSendingImage(true);
    CompanySettingsApi.uploadBackground(data)
      .then((res) => {
        const url = res.data.companyBackground;
        const state = { ...authState };
        state.companyBackground = resourcePath(url);
        dispatch({ type: 'set', payload: state });
        setSendingImage(false);
        setIsCropperOpened(false);
      })
      .catch(() => {
        setSendingImage(false);
      });
  };

  const isHR = authState.user.roles.includes('ROLE_HR');
  const isAdmin = authState.user.roles.includes('ROLE_ADMIN');

  return (
    <>
      <div className={cx('pageContainer page-top-positioning position-relative')}>
        <Header
          selectImage={onFileChange}
          isBillingModuleEnabled={isBillingModuleEnabled}
          isRecruitmentModuleEnabled={isRecruitmentModuleEnabled}
        />
        <div className={cx(styles.content, 'd-flex flex-1 flex-column')}>
          <Routes>
            <Route
              path='organization/*'
              element={
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path='/company-settings/organization' component={Organization} />
                </Suspense>
              }
            />
            <Route
              path='team/*'
              element={
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path='/company-settings/team' component={Team} />
                </Suspense>
              }
            />
            <Route
              path='time/*'
              element={
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path='/company-settings/time' component={Time} />
                </Suspense>
              }
            />
            <Route
              path='pay/*'
              element={
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path='/company-settings/pay' component={Pay} />
                </Suspense>
              }
            />
            <Route
              path='requests/*'
              element={
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path='/company-settings/requests' component={Requests} />
                </Suspense>
              }
            />
            <Route
              path='tasks/*'
              element={
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path='/company-settings/tasks' component={Tasks} />
                </Suspense>
              }
            />
            <Route
              path='documents/*'
              element={
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path='/company-settings/documents' component={Documents} />
                </Suspense>
              }
            />
            <Route
              path='assets/*'
              element={
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path='/company-settings/assets' component={Assets} />
                </Suspense>
              }
            />
            <Route
              path='permissions'
              element={
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path='/company-settings/permissions' component={Permissions} />
                </Suspense>
              }
            />
            <Route
              path='integrations/*'
              element={
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path='/company-settings/integrations' component={Integrations} />
                </Suspense>
              }
            />
            <Route
              path='security'
              element={
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path='/company-settings/security' component={Access} />
                </Suspense>
              }
            />

            {isBillingModuleEnabled && (
              <Route
                path='billing'
                element={
                  <Suspense fallback={<AreaLoader />}>
                    <CustomRoute path='/company-settings/billing' component={Subscription} />
                  </Suspense>
                }
              />
            )}

            {isRecruitmentModuleEnabled && (isHR || isAdmin) && (
              <Route
                path='recruitment/*'
                element={
                  <Suspense fallback={<AreaLoader />}>
                    <CustomRoute path='/company-settings/recruitment' component={Recruitment} />
                  </Suspense>
                }
              />
            )}

            <Route path='' element={<Navigate to='organization' replace />} />
          </Routes>
        </div>
      </div>

      <Modal
        showHeader={false}
        isOpen={isCropperOpened}
        onRequestClose={() => {
          closeCropper();
        }}
      >
        <BackgroundCropper
          img={imageFile}
          onSave={onSaveBackground}
          onClose={closeCropper}
          reverse={false}
          fieldValue='companyBackground'
          sendingImage={sendingImage}
        />
      </Modal>
    </>
  );
};

export default CompanySettings;
