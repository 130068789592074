import React from 'react';

import Modal from 'components/Modal';
import t from 'utility/setTranslation';

import WhoIsInTabs from '../Tabs/WhoIsInTabs';

const WhoIsInModal = ({ isOpen, onClose }) => (
  <Modal
    isOpen={isOpen}
    styles={{ width: '880px', height: '90vh', padding: '0', borderRadius: '8px' }}
    showDirtyForm
    onRequestClose={onClose}
    showHeader
    title={t('GENERAL_WHO_IS_IN')}
  >
    <WhoIsInTabs />
  </Modal>
);

export default WhoIsInModal;
