import React, { useState } from 'react';

const RowDensityContext = React.createContext({});

const RowDensityContextProvider = (props) => {
  const rowDensitysProps = { ...props };
  const [density, setDensity] = useState(localStorage.getItem('rowDensity') || 'default');

  const onSetRowDensity = (value) => {
    setDensity(value);
    localStorage.setItem('rowDensity', value);
  };

  const value = { density, onSetRowDensity };

  return (
    <RowDensityContext.Provider value={value}>
      {rowDensitysProps ? rowDensitysProps.children : false}
    </RowDensityContext.Provider>
  );
};

export { RowDensityContext, RowDensityContextProvider };
