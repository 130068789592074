import { useMutation, useQuery } from '@tanstack/react-query';

import DashboardApi from 'api/DashboardApi';

export const queryKeys = {
  missingTimeStampsCount: ['missing timestamps count'],
  featureFlagConfigurations: ['featureFlagConfigurations'],
  sendMessageToAI: ['sendMessageToAI'],
  cancelSubscription: ['cancelSubscription'],
};

export const useMissingTimeStampsCount = () =>
  useQuery(queryKeys.missingTimeStampsCount, () => DashboardApi.getMissedStampsTotal());

export const useQueryFeatureFlagConfigurations = (options) =>
  useQuery(queryKeys.featureFlagConfigurations, DashboardApi.getFeatureFlagConfigurations, {
    retry: 0,
    staleTime: 300000,
    ...options,
  });

export const useSendMessageToAI = () => useMutation(queryKeys.sendMessageToAI, DashboardApi.sendMessageToAI);
