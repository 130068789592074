import React from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import Calendar from 'modules/EngagementCenter/shared/Calendar/Calendar';
import CalendarLegend from 'modules/EngagementCenter/shared/Calendar/CalendarLegend';
import SkeletonCalendar from 'modules/EngagementCenter/shared/loaders/SkeletonCalendar';

import styles from './Card.module.scss';

const CardCalendar = ({ className, employeeData }) => {
  const labels = ['Current Day', 'Approved Time Requests', 'Pending Time Requests', 'Holidays'];

  return (
    <div className={cx(styles.cardWrapper, className, 'deselected')}>
      {employeeData?.identification && (
        <Calendar
          className='dashboard-calendar'
          setDates={() => {}}
          minDate={employeeData?.hireDate ? new Date(moment(employeeData.hireDate).format('YYYY-MM-DD')) : null}
          employee={employeeData.identification}
          weekdayDisplayFormat='EEEEE'
          canSelectDays={false}
          months={1}
        />
      )}

      {!employeeData?.identification && (
        <div style={{ height: '325px' }}>
          <SkeletonCalendar />
        </div>
      )}

      <CalendarLegend labels={labels} className='pt-0' />
    </div>
  );
};

CardCalendar.propTypes = {
  employeeData: PropTypes.object.isRequired,
  className: PropTypes.string,
};

CardCalendar.defaultProps = {
  className: '',
};

export default CardCalendar;
