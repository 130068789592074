import React, { useContext } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { AuthContext } from 'auth/AuthContext';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

import styles from './PriorityMenu.module.scss';

const PriorityMenu = ({ className, priorities, onSelect, taskId }) => {
  const { authState } = useContext(AuthContext);
  return (
    <div className={cx(styles.wrapper, className)}>
      <ul className='no-style py-2 mb-0'>
        {priorities.map((priority) => (
          <li
            key={priority.id}
            onClick={() => {
              onSelect(priority.id);
            }}
            className={cx(
              styles.listItem,
              priority?.id === taskId && styles.active,
              'd-flex justify-content-start align-items-center'
            )}
          >
            <i className={cx(styles.i, 'icon-custom-1', 'me-2')} style={{ color: `${priority?.color}` }} />
            <span>{readName(authState.user.language, priority.name, priority.nameArabic)}</span>
          </li>
        ))}

        <li
          onClick={() => {
            onSelect('clear');
          }}
          className={cx(styles.listItem, 'd-flex justify-content-start align-items-center')}
        >
          <i className={cx(styles.i, 'icon-cross-x me-2 text-red')} />
          <span>{t('GENERAL.CLEAR')}</span>
        </li>
      </ul>
    </div>
  );
};

PriorityMenu.propTypes = {
  priorities: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
};

PriorityMenu.defaultProps = {
  className: '',
};

export default React.memo(PriorityMenu);
