import React, { useEffect } from 'react';

import helper from './helper';
import TableBody from './TableBody';
import TableHead from './TableHead';

const Table = React.forwardRef((props, ref) => {
  const { tcRef, tRef, tbRef, thRef, tfRef } = helper.prepareRefs(ref).current;
  const {
    id,
    onXScrollable,
    size,
    stickyHead,
    rowIdentifier,
    loading,
    columns,
    rows,
    onSort,
    currentTotal,
    sort,
    hoverable,
    tableWidth,
  } = props;

  useEffect(() => {
    const scrollable = tcRef.current.clientWidth < tcRef.current.scrollWidth;
    if (scrollable) {
      helper.onTxScrollable({ scrollable, id }, onXScrollable);
      return;
    }
    helper.onTxScrollable({ scrollable, id }, onXScrollable);
  });

  return (
    <table ref={tRef} id={id} style={{ width: tableWidth ?? '100%' }}>
      <TableHead
        ref={thRef}
        sort={sort}
        columns={columns}
        size={size}
        sticky={stickyHead}
        onSort={onSort}
        tableId={id}
        loading={loading}
      />

      <TableBody
        ref={tbRef}
        columns={columns}
        rows={rows}
        loading={loading}
        identifier={rowIdentifier}
        tableId={id}
        currentTotal={currentTotal}
        hoverable={hoverable}
      />

      <tfoot ref={tfRef} />
    </table>
  );
});

Table.defaultProps = {
  id: '',
  columns: [],
  rows: [],
  loading: false,
  size: 'sm',
  stickyHead: false,
  onSort: null,
  onXScrollable: null,
  currentTotal: 0,
  sort: null,
  hoverable: true,
};

export default Table;
