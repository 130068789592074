import PropTypes from 'prop-types';

import PHRFile from 'services/File/PHRFile';
import propTypesUtility from 'utility/propTypesUtility';

export const propTypes = {
  id: propTypesUtility.idPropType(),
  file: PropTypes.oneOf([PropTypes.instanceOf(PHRFile), PropTypes.object]),
  isDownloadable: propTypesUtility.boolPropType(),
  isThumbnail: propTypesUtility.boolPropType(),
  onDelete: PropTypes.func,
};

export const defaultProps = {
  id: 'phr-file-preview',
  isDownloadable: false,
  isThumbnail: true,
  onDelete: null,
};
