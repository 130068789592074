import React from 'react';

import cx from 'classnames';

import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import { clientTypes } from 'constants/clientTypes';
import { useVisibility } from 'shared/useVisibility';
import t from 'utility/setTranslation';

import BookDemoModal from './BookDemoModal/BookDemoModal';
import ClearDataModal from './ClearDataModal/ClearDataModal';
import styles from './TrialPeriodBanner.module.scss';
import GoLiveModal from './TrialPeriodGoLive/components/GoLiveModal/GoLiveModal';
import { getTrialDays } from './TrialPeriodGoLive/utility';
import { useAccountSubscriptionData } from '../../../hooks/useAccount';
import NotificationBanner from '../../NotificationBanner/NotificationBanner';

const TrialPeriodBanner = () => {
  const { isOpen: isGoLiveModalOpen, close: closeGoLiveModal, open: openGoLiveModal } = useVisibility();
  const { isOpen: isClearDataModalOpen, close: closeClearDataModal, open: openClearDataModal } = useVisibility();
  const { isOpen: isBookDemoModalOpen, close: closeBookDemoModalModal, open: openBookDemoModalModal } = useVisibility();

  const { data } = useAccountSubscriptionData();

  const account = data?.data.account;
  const accountType = account?.clientType;
  const trialExpireAt = new Date(account?.trialExpireAt);
  const trialDays = getTrialDays(trialExpireAt);

  const renderTrialPeriodActions = () => (
    <div className='d-flex align-items-end gap-2'>
      <RoundedButton
        text='Go Live'
        size='xs'
        color='white'
        onClick={openGoLiveModal}
        className={cx(styles.goLiveBtn)}
        id='go-live'
      />
      <RoundedButton
        text='Clear Data'
        size='xs'
        color='white'
        className={cx(styles.clearDataBtn)}
        onClick={openClearDataModal}
        id='clear-data'
      />
      <RoundedButton
        text='Book a demo'
        size='xs'
        color='primary'
        btnStyle='contained'
        className={cx(styles.bannerBtnContained)}
        onClick={openBookDemoModalModal}
        id='book-a-demo-popup'
      />
    </div>
  );

  return (
    <>
      <NotificationBanner
        type='primary'
        isShow={accountType === clientTypes.TRIAL || accountType === clientTypes.TEST_SITE}
        message={t('GENERAL.TRIAL_DAYS', { trialDays })}
        renderActions={renderTrialPeriodActions}
        className='py-2'
      />
      {isGoLiveModalOpen && <GoLiveModal isOpen={isGoLiveModalOpen} onClose={closeGoLiveModal} />}
      {isClearDataModalOpen && <ClearDataModal isOpen={isClearDataModalOpen} onClose={closeClearDataModal} />}
      {isBookDemoModalOpen && <BookDemoModal isOpen={isBookDemoModalOpen} onClose={closeBookDemoModalModal} />}
    </>
  );
};

export default TrialPeriodBanner;
