/* eslint-disable no-use-before-define */
import React, { useState, useContext, useEffect, useRef } from 'react';

import cx from 'classnames';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { MentionsInput, Mention } from 'react-mentions';

import TeamApi from 'api/TeamApi';
import { AuthContext } from 'auth/AuthContext';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import Personas from 'components/Personas';
import OutsideClickHandler from 'hoc/OutsideClickHandler';
import GeneralStore from 'stores/GeneralStore';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

import styles from './CommentBox.module.scss';
import MentionInputStyle from './MentionInputStyle';
import MentionStyle from './MentionStyle';

const CommentBox = ({ onSend, customClass, hideCommentBox }) => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const generalStore = useContext(GeneralStore);

  const [showCommentBox, setShowCommentBox] = useState(false);
  const [sending, setSending] = useState(false);
  const [mentionPeople, setMentionPeople] = useState([]);
  const commentWrapperRef = useRef(null);

  const openCommentBoxHandler = () => {
    setShowCommentBox(true);
  };

  const closeCommentBoxHandler = () => {
    setShowCommentBox(false);
    formik.resetForm();
  };

  const validate = (values) => {
    const errors = {};
    if (!values.message) {
      errors.message = 'Message cannot be empty';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      if (values.message) {
        setSending(true);
        onSend(values)
          .then(() => {
            closeCommentBoxHandler();
            setSending(false);
            resetForm();
          })
          .catch(() => {
            setSending(false);
          });
      }
    },
  });

  const getAllActivePeople = () => {
    const params = `?sort=${authState.user.language === 'ar' ? 'e.fullNameArabic' : 'e.fullName'}&direction=asc`;

    TeamApi.getAllActiveEmployees(params).then((res) => {
      const data = [];
      res.data.items.forEach((emp) => {
        data.push({
          ...emp,
          display: readName(user.language, emp.fullName, emp.fullNameArabic),
        });
      });

      generalStore.setAllActiveUsers(data);
      setMentionPeople(data);
    });
  };

  useEffect(() => {
    if (generalStore.allActiveUsers.length === 0) {
      getAllActivePeople();
    } else {
      setMentionPeople(generalStore.allActiveUsers);
    }

    document.addEventListener('keydown', (e) => {
      if (e.ctrlKey && e.keyCode === 13) {
        // Ctrl-Enter pressed
        formik.handleSubmit();
      }
    });

    return () => {
      document.removeEventListener('keydown', (e) => {
        if (e.ctrlKey && e.keyCode === 13) {
          // Ctrl-Enter pressed
          formik.handleSubmit();
        }
      });
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (commentWrapperRef && commentWrapperRef.current && commentWrapperRef.current.style) {
        if (showCommentBox) {
          commentWrapperRef.current.style.overflow = 'visible';
        } else {
          commentWrapperRef.current.style.overflow = 'hidden';
        }
      }
    }, 500);
  }, [showCommentBox]);

  return (
    <OutsideClickHandler clickHandler={closeCommentBoxHandler}>
      <div
        className={cx(styles.wrapper, customClass, {
          [styles.active]: showCommentBox,
          [styles.noBox]: hideCommentBox,
        })}
      >
        <form className='d-flex align-items-start position-relative' onSubmit={formik.handleSubmit}>
          <div className={styles.profileBox}>
            <Personas user={user} size={32} />
          </div>
          <div
            className={cx(styles.commentWrapper, { [styles.active]: showCommentBox }, 'flex-grow-1', 'ms-3')}
            ref={commentWrapperRef}
          >
            {showCommentBox ? (
              <div id='suggestionPortal'>
                <MentionsInput
                  className={cx(styles.textarea)}
                  autoFocus
                  style={MentionInputStyle}
                  value={formik.values.message}
                  allowSpaceInQuery
                  placeholder={t('GENERAL.WRITE_YOUR_COMMENT')}
                  suggestionsPortalHost={commentWrapperRef.current}
                  allowSuggestionsAboveCursor
                  onChange={(event) => {
                    formik.setFieldValue('message', event.target.value);
                  }}
                >
                  <Mention
                    style={MentionStyle}
                    trigger='@'
                    data={mentionPeople}
                    renderSuggestion={(entry, search, highlightedDisplay) => (
                      <div className='d-flex justify-content-start align-items-center'>
                        <Personas
                          user={mentionPeople.filter((el) => el.id === entry.id)[0]}
                          size={16}
                          customClass='me-2'
                        />

                        <div>{highlightedDisplay}</div>
                      </div>
                    )}
                    markup='@mention(__id__)[__display__]'
                    appendSpaceOnAdd
                    displayTransform={(id, display) => `@${display}`}
                  />
                </MentionsInput>

                <div className='d-flex justify-content-end px-3 pb-3'>
                  <RoundedButton
                    btnStyle='contained'
                    color='primary'
                    size='sm'
                    isDisabled={!!formik.errors.message}
                    type='submit'
                    text='Comment'
                    loading={sending}
                  />
                </div>
              </div>
            ) : (
              <div onClick={openCommentBoxHandler} className={cx(styles.inputPlaceholder, 'flex-grow-1')}>
                {t('EC.TASKS.COMMENT')}
              </div>
            )}
          </div>
        </form>
      </div>
    </OutsideClickHandler>
  );
};

CommentBox.propTypes = {
  onSend: PropTypes.func.isRequired,
  customClass: PropTypes.string,
  hideCommentBox: PropTypes.bool,
};

CommentBox.defaultProps = {
  customClass: '',
  hideCommentBox: false,
};

export default CommentBox;
