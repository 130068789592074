import * as yup from 'yup';

import t from '../../../../../../utility/setTranslation';
import { PAYMENT_TYPE_VALUES } from '../constants';

export const goLivePaymentSettingsValidationSchema = () =>
  yup.object().shape({
    billingInfo: yup.object().shape({
      addressLine1: yup.string().required(t('GENERAL.REQUIRED_FIELD')),
      addressLine2: yup.string().optional(),
      vatNumber: yup.string(),
      city: yup.string().required(t('GENERAL.REQUIRED_FIELD')),
      state: yup.string(),
      zip: yup
        .string()
        .matches(/^\d{5}$/, t('GENERAL.ZIP_CODE_ERROR'))
        .required(t('GENERAL.REQUIRED_FIELD')),
    }),
    payment: yup.string().oneOf(Object.values(PAYMENT_TYPE_VALUES)).required(t('GENERAL.REQUIRED_FIELD')),
    isPaymentComplete: yup
      .boolean()
      .test('isPaymentComplete', t('GENERAL.IS_PAYMENT_COMPLETE_ERROR'), (value) => value === true),
    isTermsAccepted: yup
      .boolean()
      .test('isTermsAccepted', t('GENERAL.IS_TERMS_ACCEPTED_ERROR'), (value) => value === true),
  });
