import _ from 'lodash';

import ColumnsConfiguratorApi from 'api/ColumnsConfiguratorApi';
import arrayUtil from 'utility/arrayUtil';

function excludedCols() {
  return ['id', 'employee_name'];
}

function sortedColumns(columns, direction = 'asc') {
  return arrayUtil.sortByProperty(columns, direction, 'columnOrder');
}

function save(reportId, params, callbacks) {
  if (callbacks.before && typeof callbacks.before === 'function') {
    callbacks.before();
  }

  ColumnsConfiguratorApi.saveUserReportColumns(params, reportId).then(
    () => {
      if (callbacks.success && typeof callbacks.success === 'function') {
        callbacks.success();
      }
    },
    (error) => {
      if (callbacks.fail && typeof callbacks.fail === 'function') {
        callbacks.fail(error);
      }
    }
  );
}

function minOrder(columns) {
  const excludedColumns = sortedColumns(
    _.filter(columns, (col) => excludedCols().includes(col.name)),
    'desc'
  );
  const lastExcludedCol = excludedColumns.shift();

  return lastExcludedCol ? lastExcludedCol.columnOrder + 1 : 0;
}

function maxOrder(columns) {
  const excludedColumns = sortedColumns(columns);
  const lastCol = excludedColumns.pop();
  return lastCol ? lastCol.columnOrder : 0;
}

function allSelected(list) {
  const count = list.reduce((acc, v) => {
    if (Boolean(v.visible) === true) {
      // eslint-disable-next-line no-param-reassign
      acc += 1;
    }
    return acc;
  }, 0);
  return count === list.length;
}

function enableSave(initCols, newCols) {
  return !_.isEqual(sortedColumns(initCols), sortedColumns(newCols));
}

function optionChecked(currentColumns, n, checked = false) {
  const state = [...currentColumns];

  const optionIndex = _.findIndex(state, ['id', +n]);
  if (!optionIndex) {
    return state;
  }

  state[optionIndex] = { ...state[optionIndex], visible: checked };

  return state;
}

function optionSorted(currentColumns, index, item, direction = 'up') {
  const state = [...currentColumns];
  const nextIndex = direction === 'up' ? index - 1 : index + 1;

  const nextItem = state[nextIndex];
  if (!nextItem) return state;

  const next = { ...nextItem, columnOrder: item.columnOrder };
  state[nextIndex] = { ...item, columnOrder: nextItem.columnOrder };
  state[index] = next;

  return state;
}

function checkedAll(currentColumns, checked = false) {
  let state = [...currentColumns];
  state = state.map((field) => {
    if (!checked && !excludedCols().includes(field.name)) {
      return { ...field, visible: false };
    }
    return { ...field, visible: true };
  });
  return state;
}

function payload(columns) {
  const newCols = [];
  columns.forEach((col) => {
    newCols.push({ column: col.id, visible: col.visible, columnOrder: col.columnOrder });
  });
  return newCols;
}

export default {
  sortedColumns,
  save,
  excludedCols,
  minOrder,
  allSelected,
  enableSave,
  optionChecked,
  optionSorted,
  checkedAll,
  payload,
  maxOrder,
};
