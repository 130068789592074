import http from 'api/Api';

export class ApprovalsAPI {
  static getApprovalsMonths(date) {
    return http.get(`/billable-hours/approvals/monthly/me`, { params: { date } });
  }

  static getEmployeesToBeReviewed(params) {
    return http.get(`/billable-hours/approvals/per-employee/me`, { params });
  }

  static getRequestsPerEmployeesToBeReviewed({ employeeId, params }) {
    return http.get(`/billable-hours/approvals/employee/${employeeId}/me`, { params });
  }

  static getRequestReport(requestId) {
    return http.get(`/billable-hours/request/${requestId}/export/csv`, {
      responseType: 'arraybuffer',
    });
  }

  static exportRequests(params) {
    return http.get(`/billable-hours/requests/export/csv`, {
      params,
      responseType: 'arraybuffer',
    });
  }

  static approveRequest(requestId) {
    return http.post(`/billable-hours/request/approve/${requestId}`);
  }

  static approveRequests({ requests }) {
    return http.post(`/billable-hours/requests/approve`, {
      requests,
    });
  }

  static rejectRequest({ requestId, payload }) {
    return http.post(`/billable-hours/request/reject/${requestId}`, payload);
  }

  static rejectRequests({ requests, message }) {
    return http.post(`/billable-hours/requests/reject`, {
      requests,
      message,
    });
  }
}
