import React, { useMemo, useState, useRef } from 'react';

import cx from 'classnames';
import { useFormikWizard } from 'formik-wizard-form';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { Scrollbars } from 'react-custom-scrollbars';

import FormContext from 'components/forms/components/FormContext';
import WizardFooter from 'components/PHRWizardWithoutSideMenu/WizardFooter';
import TipsContainer from 'components/TipsContainer/TipsContainer';
import useLanguage from 'shared/useLanguage';

const PhrWizardWithoutSideMenu = ({
  initialValues,
  renderHeaderComponent,
  onSubmit,
  generateWizardSteps,
  onNext,
  onPrevious,
  previousButtonText,
  submitButtonText,
  initialIsShowTipsState,
  className,
  tipsTitle,
  tipsWidth,
}) => {
  const [isShowTips, setIsShowTips] = useState(initialIsShowTipsState);

  const language = useLanguage();
  const [stepsValidationState, setStepsValidationState] = useState(cloneDeep(generateWizardSteps()));

  const stepsMemoized = useMemo(generateWizardSteps, [language]);

  const formik = useFormikWizard({
    initialValues,
    onSubmit,
    steps: stepsMemoized,
  });

  const { renderComponent, handlePrev, handleNext, isLastStep, isFirstStep, currentStepIndex } = formik;
  const { tipsComponent: renderTipsComponent } = stepsMemoized[currentStepIndex];

  const scrollbarsRef = useRef();

  const onNextHandler = async () => {
    const errors = await formik.validateForm();
    await formik.setTouched(
      Object.keys(errors).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {})
    );

    if (isEmpty(errors)) {
      if (onNext) {
        onNext();
      }
      await handleNext();
      setStepsValidationState((prevSideMenuSteps) => {
        prevSideMenuSteps[currentStepIndex].isValid = true;
        return [...prevSideMenuSteps];
      });

      scrollbarsRef.current.scrollToTop();
    }
  };

  const onPrevHandler = () => {
    if (stepsValidationState.length > 1) {
      scrollbarsRef.current.scrollToTop();
      return handlePrev();
    }
    return onPrevious();
  };

  const isTipsExist = stepsMemoized[currentStepIndex].tipsComponent;

  const hideTips = () => setIsShowTips(false);

  return (
    <div className={cx('d-flex flex-column', className)}>
      {renderHeaderComponent({ isTipsExist, setIsShowTips, isShowTips })}
      <section className='flex-1'>
        <Scrollbars autoHide ref={scrollbarsRef}>
          <FormContext.Provider value={{ formik }}>
            <section
              className={cx('d-flex align-items-start justify-content-between px-5 gap-8', { 'h-100': !isTipsExist })}
            >
              <section className='flex-1 h-100'>{renderComponent()}</section>

              {isTipsExist && (
                <TipsContainer
                  hideTips={hideTips}
                  isShowTips={isShowTips}
                  tipsTitle={tipsTitle}
                  tipsWidth={tipsWidth}
                  renderTipsComponent={() =>
                    renderTipsComponent({
                      formik,
                      setIsShowTips,
                    })
                  }
                />
              )}
            </section>
          </FormContext.Provider>
        </Scrollbars>
      </section>

      <WizardFooter
        className='mt-auto'
        isFirstStep={isFirstStep}
        isLastStep={isLastStep}
        handlePrev={onPrevHandler}
        currentStepIndex={currentStepIndex}
        stepsValidationState={stepsValidationState}
        handleNext={onNextHandler}
        previousButtonText={isFirstStep ? previousButtonText : 'go back'}
        nextButtonText={isLastStep ? submitButtonText || 'save' : 'next step'}
      />
    </div>
  );
};

export default PhrWizardWithoutSideMenu;
