const actionIconMap = {
  delete: 'icon-trash',
  'delete candidate': 'icon-trash',
  'reject candidate': 'icon-cross-x',
  edit: 'icon-pencil-create',
  move: 'icon-move-file',
  verify: 'icon-pencil-create',
  'view details': 'icon-eye',
  'manage locations': 'icon-pin',
  'manage banks': 'icon-bank',
  'assign people': 'icon-users',
  'add contribution': 'icon-list',
  'change approvers': 'icon-list',
  'change details': 'icon-file-text',
  'task assignees': 'icon-tasks-2',
  'assign employees': 'icon-users',
  'change request': 'icon-request-general',
  default: 'icon-circle-checked',
  active: 'icon-circle-checked',
  done: 'icon-circle-checked',
  'add section talking points': 'icon-text-align-left',
  'add section action items': 'icon-list',
  'reset configuration to default': 'icon-refresh-ccw',
  revert: 'icon-refresh-ccw',
  'reset configuration to default only': 'icon-refresh-ccw',
  'create new holiday': 'icon-plus',
  'import holidays(s)': 'icon-import',
  import: 'icon-import',
  'create new': 'icon-file-create',
  'rename policy': 'icon-pencil-create',
  'edit policy': 'icon-pencil-create',
  'edit type': 'icon-pencil-create',
  'cancel removal': 'icon-refresh-ccw',
  rename: 'icon-pencil-create',
  remove: 'icon-trash',
  announcement: 'icon-megaphone',
  event: 'icon-calendar-plus',
  'upload-document': 'icon-file-upload',
  'link-document': 'icon-link',
  'change effective date': 'icon-pencil-create',
  'mark incomplete': 'icon-check-mark',
  'mark as complete': 'icon-check-mark',
  'submit for approval': 'icon-file-upload',
  'add target': 'icon-target',
  'create-folder': 'icon-folder',
  'upload-file': 'icon-file-upload',
  'link-file': 'icon-link',
  download: 'icon-download',
  resolve: 'icon-pencil-create',
  employee: 'icon-user',
  contractor: 'icon-watch',
  'bulk hire': 'icon-users',
  send_back_to_previous_phase: 'icon-arrow-bold-reply',
  mark_as_unpaid: 'icon-check-marks',
  mark_as_paid: 'icon-check-marks',
  reject: 'icon-shield-no',
  move_back_to_pending: 'icon-arrow-bold-undo',
  move_to_selected_month: 'icon-arrow-right-curved',
  'allow access': 'icon-verified',
  'remove access': 'icon-not-verified',
  'enable automatic contract extension': 'icon-square-checked',
  'disable automatic contract extension': 'icon-square-indeterminate',
  'copy job url': 'icon-share',
  'copy to other job': 'icon-share',
  'view statistics': 'icon-bar-chart',
  'duplicate job': 'icon-copy',
  'duplicate type': 'icon-copy',
  'preview job': 'icon-eye',
  'create-document': 'icon-file-plus',
  'add to favorite': 'icon-heart',
  'remove from favorite': 'icon-heart_filled',
  'people.id': 'icon-chevron-sort',
  name: 'icon-chevron-sort',
};

export default actionIconMap;
