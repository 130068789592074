import React from 'react';

import { PHRSideTransitionContainer } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

import useDirection from 'shared/useDirection';

const SideTransitionContainer = ({ children, isIn, zIndex, onClose, backdropClassName }) => {
  const direction = useDirection();

  return (
    <PHRSideTransitionContainer
      direction={direction}
      isIn={isIn}
      zIndex={zIndex}
      onClose={onClose}
      backdropClassName={backdropClassName}
    >
      {children}
    </PHRSideTransitionContainer>
  );
};

SideTransitionContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isIn: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  zIndex: PropTypes.number,
};

SideTransitionContainer.defaultProps = {
  onClose: () => {},
  zIndex: 10,
};

export default SideTransitionContainer;
