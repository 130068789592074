const generateRowsOptions = (options) => {
  const result = [];
  options.forEach((item) => {
    result.push({
      id: item,
      name: item,
      option: item,
      value: item,
    });
  });
  return result;
};

export default generateRowsOptions;
