import http, { httpPalmHrAPI } from './Api';

class CompanySettingsTimesheetsApi {
  static getTimesheets() {
    return http.get(`/timesheet-settings`);
  }

  static addTimesheets(data) {
    return http.post(`/timesheet-settings`, data);
  }

  static editTimesheets(data) {
    return http.put(`/timesheet-settings`, data);
  }

  static deleteTimesheets() {
    return http.delete(`/timesheet-settings`);
  }

  static getCutoffPeriod() {
    return http.get(`/timesheet-settings/period`);
  }

  static createCutoffPeriod(data) {
    return http.post(`/timesheet-settings/period`, data);
  }

  static updateCutoffPeriod(id, data, filters = '') {
    return http.put(`/timesheet-settings/period/${id}${filters}`, data);
  }

  static getGracePeriod(filters = '') {
    return http.get(`/settings/time/timesheets/grace-periods${filters}`);
  }

  static addGracePeriod(data) {
    return http.post(`/settings/time/timesheets/grace-periods`, data);
  }

  static editGracePeriod(id, data) {
    return http.put(`/settings/time/timesheets/grace-periods/${id}`, data);
  }

  static deleteGracePeriod(id) {
    return http.delete(`/settings/time/timesheets/grace-periods/${id}`);
  }

  static getTimeTrackingEmployees(mode, filters = '') {
    return http.get(`/settings/time/time-tracking/${mode}/employees${filters}`);
  }

  static isIntegrated() {
    return http.get(`/settings/time/time-tracking/biometrics/integrated`);
  }

  static getBiometricData() {
    return http.get(`/settings/time/time-tracking/biometrics`);
  }

  static addBiometricData(data) {
    return http.post(`/settings/time/time-tracking/biometrics`, data);
  }

  static editBiometricData(data) {
    return http.put(`/settings/time/time-tracking/biometrics`, data);
  }

  static deleteBiometricData() {
    return http.delete(`/settings/time/time-tracking/biometrics`);
  }

  static testConnection(data) {
    return http.post(`/settings/time/time-tracking/biometrics/test`, data);
  }

  static timeTrackingSettings() {
    return http.get(`/settings/time/time-tracking/settings`);
  }

  static updateTimeTrackingSettings(data) {
    return http.put(`/settings/time/time-tracking/settings`, data);
  }

  static getTimeTrackingConfig() {
    return httpPalmHrAPI.get(`/api/v1/time/time-tracking/config`);
  }

  static updateTimeTrackingConfig(data) {
    return httpPalmHrAPI.put(`/api/v1/time/time-tracking/config`, data);
  }

  // Wizard
  static getAssignedPeopleStats() {
    return http.get(`/settings/time/time-tracking/stats`);
  }

  static getUnassignedPeople(filters = '') {
    return http.get(`/settings/time/time-tracking/unassigned/employees${filters}`);
  }

  static addMultipleTimeTracking(data) {
    return http.post(`settings/time/time-tracking/multiple-assign`, data);
  }
}

export default CompanySettingsTimesheetsApi;
