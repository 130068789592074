import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { PropTypes } from 'prop-types';

import IconButton from 'components/buttons/IconButton/IconButton';
import SelectField from 'components/forms/components/SelectField';
import queryParamsUtil from 'utility/queryParamsUtil';
import t from 'utility/setTranslation';

import generateRowsOptions from './functions/generateRowsOptions';
import usePagination from './functions/usePagination';
import styles from './Pagination.module.scss';

const selectStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: '9999!important',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '264px',
  }),
  control: (provided) => ({
    ...provided,
    minHeight: '32px',
  }),
};

const defaultPag = queryParamsUtil.defaultPagination();

const Pagination = ({ pagination, onPaginationAction, loading, zeroIndexing }) => {
  const { total, perPage, pages, currentPage } = pagination;
  const [selected, setSelected] = useState(perPage?.toString());

  const calculatedCurrentPage = zeroIndexing ? currentPage + 1 : currentPage;

  const minPage = zeroIndexing ? 0 : 1;
  const maxPage = zeroIndexing ? pages - 1 : pages;
  const paginationRange = usePagination({
    perPage,
    pages,
    currentPage: calculatedCurrentPage,
  });

  const itemsPerPage = () => {
    const start = (calculatedCurrentPage - 1) * perPage + 1;
    const end = Math.min(calculatedCurrentPage * perPage, total);
    return `${start || 0}-${end || 0} of ${total || 0}`;
  };

  const onPageAction = (number) => {
    onPaginationAction({ ...pagination, currentPage: number });
  };

  const onChangeRowsHandler = (name, value) => {
    setSelected(value.value);
    const pag = { ...defaultPag, perPage: value.value, currentPage: minPage };
    onPaginationAction(pag);
  };

  const renderPagination = (pageNum, key) => {
    let content = null;
    if (pageNum === '...') {
      content = (
        <div key={key} className={styles.dots}>
          <i className='icon-more-horizontal' />
        </div>
      );
    } else {
      content = (
        <IconButton
          key={key}
          text={pageNum}
          showBorder
          isDisabled={pageNum === calculatedCurrentPage}
          onClick={() => onPageAction(zeroIndexing ? pageNum - 1 : pageNum)}
          activeTextBtn={pageNum === calculatedCurrentPage}
          className={cx('mx-1', styles.paginationButton)}
          style={{ borderRadius: '8px' }}
          size='md'
        />
      );
    }

    return content;
  };

  useEffect(() => {
    const newPerPage = pagination?.perPage || defaultPag?.perPage || 10;
    setSelected(newPerPage.toString());
  }, [pagination?.perPage]);

  return (
    <div className={cx(styles.pagination, { [styles.disabled]: loading }, 'py-4')}>
      <div className='d-flex justify-content-between align-items-center position-relative'>
        <div className='d-flex justify content start align-items-start'>
          <IconButton
            icon='icon-chevron-left'
            onClick={() => onPageAction(currentPage - 1)}
            isDisabled={currentPage === minPage}
            className='me-2a'
            size='md'
            style={{ borderRadius: '4px' }}
            showBorder
          />
          {paginationRange &&
            paginationRange?.map((pageNumber, index) => renderPagination(pageNumber, pageNumber + index))}
          <IconButton
            icon='icon-chevron-right'
            onClick={() => onPageAction(currentPage + 1)}
            isDisabled={currentPage === maxPage}
            className='ms-2a'
            size='md'
            style={{ borderRadius: '4px' }}
            showBorder
          />
        </div>
        <div className={cx(styles.perPage, 'd-flex justify-content-start align-items-center')}>
          <p className='me-3'>{t('PAGINATION.ROWS_PER_PAGE')}</p>
          <div className={cx(styles.select, 'me-4')}>
            <SelectField
              size='sm'
              customClass='h-40 p-0'
              hideClear
              isFormik={false}
              name='number'
              isRequired
              options={generateRowsOptions(['1', '2', '5', '10', '25', '50', '100'])}
              onChange={(name, value) => onChangeRowsHandler(name, value)}
              value={selected}
              styles={selectStyles}
              menuPlacement='top'
            />
          </div>
          <p>{itemsPerPage()}</p>
        </div>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  onPaginationAction: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    total: PropTypes.number,
    perPage: PropTypes.number,
    currentPage: PropTypes.number,
    pages: PropTypes.number,
    loading: PropTypes.bool,
  }),
};

Pagination.defaultProps = {
  pagination: {
    total: 0,
    perPage: 10,
    currentPage: 1,
    pages: 0,
    loading: false,
  },
};
export default Pagination;
