import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import _ from 'lodash';

import { usePaginatedOptions } from 'services/UseQuery/UseQueryService';

import WhoIsInQueryApi from './WhoIsInQueryApi';

export const queryKeys = {
  getWhoIsIn: (slug) => ['getWhoIsIn', slug],
  countWhoIsIn: (slug) => ['countWhoIsIn', slug],
  getWhoNeedsToBeIn: (slug) => ['getWhoNeedsToBeIn', slug],
  countWhoNeedsToBeIn: (slug) => ['countWhoNeedsToBeIn', slug],
  getWhoIsOut: (slug) => ['getWhoIsOut', slug],
  countWhoIsOut: (slug) => ['countWhoIsOut', slug],
};

export const useGetWhoIsIn = (slug, params = {}, useOptions = {}, apiConfig = {}) =>
  useQuery(queryKeys.getWhoIsIn(slug), () => WhoIsInQueryApi.getWhoIsIn(params, apiConfig), useOptions);

export const useCountWhoIsIn = (slug, params = {}, useOptions = {}, apiConfig = {}) =>
  useQuery(queryKeys.countWhoIsIn(slug), () => WhoIsInQueryApi.countWhoIsIn(params, apiConfig), useOptions);

export const useGetWhoNeedsToBeIn = (slug, params = {}, useOptions = {}, apiConfig = {}) =>
  useQuery(queryKeys.getWhoNeedsToBeIn(slug), () => WhoIsInQueryApi.getWhoNeedsToBeIn(params, apiConfig), useOptions);

export const useCountWhoNeedsToBeIn = (slug, params = {}, useOptions = {}, apiConfig = {}) =>
  useQuery(
    queryKeys.countWhoNeedsToBeIn(slug),
    () => WhoIsInQueryApi.countWhoNeedsToBeIn(params, apiConfig),
    useOptions
  );

export const useGetWhoIsOut = (slug, params = {}, useOptions = {}, apiConfig = {}) =>
  useQuery(queryKeys.getWhoIsOut(slug), () => WhoIsInQueryApi.getWhoIsOut(params, apiConfig), useOptions);

export const useGetWhoIsOutPaginated = (params = {}, useOptions = {}, apiConfig = {}) =>
  useInfiniteQuery(
    queryKeys.getWhoIsOut(JSON.stringify(params)),
    ({ pageParam }) => WhoIsInQueryApi.getWhoIsOut({ page: pageParam, ...params }, apiConfig),
    usePaginatedOptions(useOptions, true)
  );

export const useCountWhoIsOut = (slug, params = {}, useOptions = {}, apiConfig = {}) =>
  useQuery(queryKeys.countWhoIsOut(slug), () => WhoIsInQueryApi.countWhoIsOut(params, apiConfig), useOptions);
