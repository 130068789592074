import React from 'react';

import t from 'utility/setTranslation';

import GoLiveOption from './GoLiveOption/GoLiveOption';

const OptionSelector = ({ options, selectedOption, handleOptionChange, error, onBlur }) => (
  <>
    <div className='d-flex flex-1 gap-5 justify-content-between '>
      {options.map((option) => (
        <GoLiveOption
          key={option.id}
          name={t(option.nameKey)}
          description={t(option.description)}
          value={option.id}
          isChecked={selectedOption === option.id}
          onChange={() => handleOptionChange(option.id)}
          className='radius-8'
          error={error}
          onBlur={onBlur}
        />
      ))}
    </div>
  </>
);

export default OptionSelector;
