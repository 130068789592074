import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import IconButton from 'components/buttons/IconButton/IconButton';
import OutsideClickHandler from 'hoc/OutsideClickHandler';
import { useVisibility } from 'shared/useVisibility';

import styles from './style.module.scss';
import { propTypes, defaultProps, findLabelByValue } from './utility';
import List from '../List/List';
import ListItem from '../ListItem/ListItem';

const Select = ({ id, value, label, options, className, style, onSelect }) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const [selectedLabel, setSelectedLabel] = useState(findLabelByValue(value, options));
  const { close, open, isOpen } = useVisibility();

  const onItemSelect = (newValue) => {
    setSelectedValue(newValue);
    if (onSelect) onSelect(newValue);
    close();
  };

  const onToggle = () => {
    if (isOpen) close();
    if (!isOpen) open();
  };

  useEffect(() => {
    setSelectedLabel(findLabelByValue(selectedValue, options));
  }, [selectedValue]);

  return (
    <div id={id} className={`position-relative ${className}`} style={style}>
      <div
        className={cx('d-flex justify-content-between align-items-center border-1 radius-4 p-1', styles.trigger)}
        onClick={onToggle}
      >
        {label && <span className={'me-2 text-gray'}>{label}</span>}
        {selectedValue && <span>{selectedLabel}</span>}
        <IconButton
          icon={`${isOpen ? 'icon-chevron-top' : 'icon-chevron-bottom'}`}
          size={'sm'}
          className={'ms-2'}
          onClick={onToggle}
        />
      </div>

      {isOpen && (
        <OutsideClickHandler clickHandler={() => close()}>
          <List className={cx('w-100', styles.list)} raised>
            {options.map((item) => {
              const { value: itemValue, label: itemLabel } = item;
              return (
                <ListItem key={itemValue} onClick={() => onItemSelect(itemValue)}>
                  {itemLabel}
                  {selectedValue === itemValue && <i className='ms-2 icon-check-mark text-primary' />}
                </ListItem>
              );
            })}
          </List>
        </OutsideClickHandler>
      )}
    </div>
  );
};

Select.propTypes = propTypes();
Select.defaultProps = defaultProps();

export default Select;
