/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useContext, useEffect, useRef } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';

import CommentApi from 'api/CommentApi';
import palmhrAnnouncementImg from 'assets/img/announcement-default.jpeg';
import khadamatiAnnouncementImg from 'assets/img/enterprise/khadamati/default_announcement.png';
import khadamatiLogo from 'assets/img/enterprise/khadamati/logo_primary.png';
import palmhrLogo from 'assets/img/logo.svg';
import { AuthContext } from 'auth/AuthContext';
import IconButton from 'components/buttons/IconButton/IconButton';
import ConfirmationBox from 'components/ConfirmationBox';
import Modal from 'components/Modal';
import Personas from 'components/Personas';
import ShowMoreAnnouncement from 'components/ShowMoreAnnouncement';
import CommentBox from 'modules/EngagementCenter/shared/Conversations/CommentBox';
import useResolveHandler from 'shared/useResolveHandler';
import { ThemeContext } from 'themes/ThemeContext';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

import AnnouncementCommentItem from './AnnouncementCommentItem';
import styles from './AnnouncementDetails.module.scss';
import AnnouncementFile from './AnnouncementFile/AnnouncementFile';
import ListReactions from './ListReactions/ListReactions';

const AnnouncementDetails = ({ close, selected, onReaction, update }) => {
  const { authState } = useContext(AuthContext);
  const reactionRef = useRef();
  const { themeState } = useContext(ThemeContext);
  const enterpriseName = themeState?.companyName || 'palmhr';
  const [comments, setComments] = useState(selected?.comments || []);
  const [reactions, setReactions] = useState(selected?.announcementReactions || []);
  const [activeLike, setActiveLike] = useState(false);
  const [activeHeart, setActiveHeart] = useState(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const { responseStatus, setStatus } = useResolveHandler();
  const [listPosition, setListPosition] = useState({
    top: '',
    bottom: '',
  });

  const imageMap = {
    palmhr: {
      name: t('GENERAL.PALMHR_TEAM'),
      logo: palmhrLogo,
      announcementImg: palmhrAnnouncementImg,
    },
    khadamati: {
      name: t('AHAD.TITLE'),
      logo: khadamatiLogo,
      announcementImg: khadamatiAnnouncementImg,
    },
  };

  const checkIsActive = (reactionsArray, type) => {
    if (reactionsArray && Array.isArray(reactionsArray)) {
      const array = reactionsArray;
      for (let index = 0; index < array.length; index += 1) {
        const element = array[index];

        if (element.employee?.id === authState.user.identification) {
          if (type === 'like') {
            setActiveLike(true);
          } else {
            setActiveHeart(true);
          }

          return;
        }
      }
    }

    if (type === 'like') {
      setActiveLike(false);
    } else {
      setActiveHeart(false);
    }
  };

  const onCommentAdd = (values) =>
    CommentApi.addComment(selected.commentThread.id, values).then((res) => {
      update();
      const arr = [...comments];
      arr.push(res.data);
      setComments(arr);
      return res;
    });

  const onCommentDelete = () => {
    setDeleting(true);
    CommentApi.deleteComment(selected.commentThread.id, selectedComment.id)
      .then(() => {
        const arr = comments.filter((el) => el.id !== selectedComment.id);
        setComments(arr);
        update();

        setDeleting(false);
        setStatus(true, () => {
          setIsConfirmModalOpened(false);
        });
      })
      .catch((error) => {
        setDeleting(false);
        setStatus(false, () => {}, error?.response?.data?.message);
      });
  };

  useEffect(() => {
    checkIsActive(
      selected?.announcementReactions.filter((el) => el.reaction?.id === 1),
      'like'
    );

    checkIsActive(
      selected?.announcementReactions.filter((el) => el.reaction?.id === 2),
      'heart'
    );
  }, []);

  const elementInViewport = () => {
    const bounding = reactionRef.current.getBoundingClientRect();

    if (bounding.top >= 0 && bounding.bottom + 200 <= (window.innerHeight || document.documentElement.clientHeight)) {
      setListPosition({
        top: '20px',
        bottom: '',
      });
    } else {
      setListPosition({
        top: '',
        bottom: '20px',
      });
    }
  };

  return (
    <>
      <div className='row h-100 m-0'>
        {(selected.file || selected?.isDefault) && (
          <div className={cx(styles.col8, 'col-8')}>
            <AnnouncementFile announcement={selected} enterpriseName={enterpriseName} isPreview />
          </div>
        )}

        <div
          className={cx(
            selected.file || selected?.isDefault ? 'col-4' : 'col-12',
            'd-flex justify-content-between flex-column p-0'
          )}
          id='announcementRow'
        >
          <div className='mx-5'>
            <div
              className={cx(styles.header, 'mt-5 d-flex justify-content-between alig-items-start position-relative')}
            >
              <div className={cx(styles.userDiv, 'd-flex', 'justify-content-start', 'align-items-center')}>
                {selected.isDefault ? (
                  <div className={cx(styles.logoDiv, 'me-2')}>
                    <img src={imageMap[enterpriseName].logo} alt='logo' className={styles.img} />
                  </div>
                ) : (
                  <Personas user={selected.author} size={32} customClass='me-2' />
                )}

                <div className={styles.nameDiv}>
                  <div className={styles.name}>
                    {selected.isDefault
                      ? imageMap[enterpriseName].name
                      : readName(authState.user.language, selected?.author?.fullName, selected?.author?.fullNameArabic)}
                  </div>
                  <div className={styles.date}>{moment(new Date(selected?.createdAt)).fromNow()}</div>
                </div>
              </div>

              <IconButton
                className={styles.closeModalBtn}
                icon='icon-cross-x'
                size='md'
                color='gray'
                onClick={() => {
                  close();
                }}
              />
            </div>
            <div className={styles.content} id='announcementContent'>
              <div className={styles.title}>
                {selected.isDefault
                  ? t(selected?.title)
                  : readName(authState.user.language, selected.title, selected.titleArabic)}
              </div>

              <div className={styles.description}>
                <ShowMoreAnnouncement
                  text={
                    selected.isDefault
                      ? t(selected?.content)
                      : readName(authState.user.language, selected.content, selected.contentArabic)
                  }
                />
              </div>

              <div className={cx(styles.footer, 'd-flex', 'justify-content-between', 'alig-items-center', 'px-2')}>
                <div ref={reactionRef} className='d-flex justify-content-between alig-items-center'>
                  <div
                    className='d-flex justify-content-between alig-items-center me-3 position-relative tooltip-box'
                    onMouseOver={() => elementInViewport()}
                  >
                    <i
                      className={cx(styles.reactionIcon, 'icon-thumbs-up', 'me-1', {
                        [styles.activeLike]: activeLike,
                      })}
                      onClick={(e) => {
                        e.stopPropagation();
                        onReaction(selected.id, 1);

                        if (activeLike) {
                          setReactions(
                            reactions.filter(
                              (el) => !(el.reaction?.id === 1 && el.employee?.id === authState.user.identification)
                            )
                          );
                        } else {
                          const stateReactions = [...reactions];
                          stateReactions.push({
                            createdAt: moment().format('YYYY-MM-DD'),
                            employee: {
                              ...authState.user,
                              id: authState.user.identification,
                            },
                            id: Math.floor(Math.random() * 1000000000),
                            reaction: {
                              id: 1,
                              name: 'REACTION.LIKE',
                              icon: 'icon-thumbs-up',
                            },
                          });
                          setReactions(stateReactions);
                        }

                        setActiveLike(!activeLike);
                      }}
                    />
                    <span className={styles.reactionNumber}>
                      {reactions.filter((el) => el.reaction?.id === 1).length}
                    </span>
                    <ListReactions
                      data={_.filter(reactions, ['reaction.name', 'REACTION.LIKE'])}
                      title='REACTION.LIKE'
                      position={listPosition}
                    />
                  </div>

                  <div
                    onMouseOver={() => elementInViewport()}
                    className='d-flex justify-content-between alig-items-center position-relative tooltip-box'
                  >
                    <i
                      className={cx(styles.reactionIcon, 'icon-heart', 'me-1', {
                        [styles.activeHeart]: activeHeart,
                      })}
                      onClick={(e) => {
                        e.stopPropagation();
                        onReaction(selected.id, 2);

                        if (activeHeart) {
                          setReactions(
                            reactions.filter(
                              (el) => !(el.reaction?.id === 2 && el.employee?.id === authState.user.identification)
                            )
                          );
                        } else {
                          const stateReactions = [...reactions];
                          stateReactions.push({
                            createdAt: moment().format('YYYY-MM-DD'),
                            employee: {
                              ...authState.user,
                              id: authState.user.identification,
                            },
                            id: Math.floor(Math.random() * 1000000000),
                            reaction: {
                              icon: 'icon-hart',
                              id: 2,
                              name: 'REACTION.HART',
                            },
                          });
                          setReactions(stateReactions);
                        }

                        setActiveHeart(!activeHeart);
                      }}
                    />
                    <span className={styles.reactionNumber}>
                      {reactions.filter((el) => el.reaction?.id === 2).length}
                    </span>
                    <ListReactions
                      data={_.filter(reactions, ['reaction.name', 'REACTION.HART'])}
                      title='REACTION.HEART'
                      position={listPosition}
                    />
                  </div>
                </div>

                <div className='d-flex justify-content-between alig-items-center'>
                  <i className={cx(styles.reactionIcon, 'icon-comment-text', 'me-1')} />
                  <span className={styles.reactionNumber}>{comments.length}</span>
                </div>
              </div>
            </div>
          </div>
          <div className='flex-1'>
            <Scrollbars className='scrollbarsWrapper' hideTracksWhenNotNeeded style={{ height: '100%' }}>
              <div className='px-5'>
                {comments.map((el) => (
                  <AnnouncementCommentItem
                    key={el.id}
                    data={el}
                    onDelete={(data) => {
                      setIsConfirmModalOpened(true);
                      setSelectedComment(data);
                    }}
                    onEditUpdate={(comment) => {
                      const arr = [...comments];
                      const objIndex = _.findIndex(arr, ['id', comment.id]);
                      arr[objIndex] = { ...comment };
                      setComments(arr);
                      update();
                    }}
                    commentThread={selected.commentThread}
                  />
                ))}
              </div>
            </Scrollbars>
          </div>

          <div>
            <CommentBox onSend={onCommentAdd} hideCommentBox />
          </div>
        </div>
      </div>

      <Modal
        showHeader={false}
        isOpen={isConfirmModalOpened}
        styles={{ width: '640px' }}
        onRequestClose={() => {
          setIsConfirmModalOpened(false);
        }}
      >
        <ConfirmationBox
          close={() => {
            setIsConfirmModalOpened(false);
          }}
          action={() => {
            onCommentDelete();
          }}
          text='DAHBOARD.DELETE_ANNOUNCEMENT_COMMENT'
          title='GENERAL.DELETE'
          sending={deleting}
          type='danger'
          responseStatus={responseStatus}
        />
      </Modal>
    </>
  );
};

export default AnnouncementDetails;
