import axios from 'axios';

import http from './Api';

class CompanyAccessApi {
  static getCompanyAccessConfig() {
    return http.get(`/settings/account/access`);
  }

  static getActiveAssets() {
    return http.get('assets/all/unarchived');
  }

  static getArchivedAssets() {
    return http.get('/assets/all/archived');
  }

  static getTwoFactorConfig() {
    return http.get(`settings/two-factor`);
  }

  static addTwoFactorConfig(data) {
    return http.put(`settings/two-factor`, data);
  }

  static getSSOConfig() {
    return axios.get(process.env.REACT_APP_SSO_CONFIG_URL, {
      headers: { tenant: new URL(window.location.href).hostname.split('.')[0] },
    });
  }

  static toggleAccess(methodType) {
    return http.put(`settings/sso/${methodType}/toggle`);
  }

  static getInsightsAssetsTable(params) {
    return http.get(`/assets/insights/employee`, { params });
  }

  static downloadAssetsEmployee(type, params) {
    return http.get(`/assets/insights/employee/export/${type}`, { params });
  }

  static getAssetsCategories() {
    return http.get(`/settings/assets/types`);
  }

  static getAssetsStatus() {
    return http.get(`/settings/assets/statuses`);
  }

  static getAssetsChartData() {
    return http.get('/assets/insights/grouped-by-department');
  }

  static getAssetsGraphData(year) {
    return http.get(`/assets/insights/total-assets/${year}`);
  }

  static downloadAssetsChart(type) {
    return http.get(`/assets/insights/grouped-by-department/export/${type}`);
  }
}

export default CompanyAccessApi;
