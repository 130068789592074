const XS = 'xs';
const SM = 'sm';
const MD = 'md';
const LG = 'lg';
const XL = 'xl';
const XXL = 'xxl';

// eslint-disable-next-line import/prefer-default-export
export const sizeConstants = {
  XS,
  SM,
  MD,
  LG,
  XL,
  XXL,
};
