import React from 'react';

import cx from 'classnames';

import britainFlag from 'assets/img/flags/GB.png';
import saudiFlag from 'assets/img/flags/saudi.png';

import styles from './Language.module.scss';
import 'moment/locale/ar-sa';

const Language = ({ selectedLanguage, onChangeLang }) => (
  <>
    <div className={cx(styles.wrapper, 'd-flex')}>
      <button
        className='pb-0'
        type='button'
        onClick={() => {
          if (selectedLanguage === 'en') {
            onChangeLang('ar', 'rtl');
          } else {
            onChangeLang('en', 'ltr');
          }
        }}
      >
        <img src={selectedLanguage === 'en' ? saudiFlag : britainFlag} alt='localization flag' />
        <span>{selectedLanguage === 'en' ? 'العربية' : 'English'}</span>
      </button>
    </div>
  </>
);
Language.propTypes = {};

Language.defaultProps = {};

export default Language;
