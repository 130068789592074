import React, { useContext } from 'react';

import cx from 'classnames';

import { RowDensityContext } from 'components/RowDensity/RowDensityContext';

import styles from './Table.module.scss';

const TDCell = ({ children, colSpan, style, className }) => {
  const { density } = useContext(RowDensityContext);
  return (
    <td className={cx(styles[density], className)} colSpan={colSpan} style={style}>
      {children}
    </td>
  );
};

TDCell.defaultProps = {
  children: null,
  colSpan: 1,
  style: {},
  className: '',
};

export default TDCell;
