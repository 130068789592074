import React from 'react';

import PropTypes from 'prop-types';

import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import BillingHistory from 'modules/CompanySettings/Subscription/BillingHistory/BillingHistory';

const SuspendedDetails = ({ onClose }) => (
  <div>
    <div className='px-5'>
      <BillingHistory />
    </div>
    <div className='mt-4 border-t py-4'>
      <div className='px-5 d-flex justify-content-end'>
        <RoundedButton text='Close' btnStyle='contained' color='primary' size='md' onClick={onClose} />
      </div>
    </div>
  </div>
);

SuspendedDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SuspendedDetails;
