import React, { useContext } from 'react';

import cx from 'classnames';

import { AuthContext } from 'auth/AuthContext';
import IconButton from 'components/buttons/IconButton/IconButton';
import Personas from 'components/Personas';

import styles from './ListItem.module.scss';

const ListItem = ({ employee, onClick }) => {
  const { authState } = useContext(AuthContext);

  const hasPermission =
    authState.user.roles.includes('ROLE_ADMIN') ||
    authState.user.roles.includes('ROLE_HR') ||
    authState.user.identification === employee.id;

  return (
    <li
      className={cx(styles.listItem, 'd-flex align-items-center justify-content-between  border-btm p-3')}
      key={employee.id}
    >
      <div>
        <Personas size={32} user={employee} showDetails />
      </div>
      {hasPermission && (
        <div>
          <IconButton
            icon='icon-eye'
            type='button'
            text='Preview'
            color='secondary'
            btnStyle='outlined'
            size='md'
            showBorder
            onClick={onClick}
            className={styles.viewDetails}
          />
        </div>
      )}
    </li>
  );
};

export default ListItem;
