import React from 'react';

import cx from 'classnames';

import TableSkeleton from 'components/skeletons/TableSkeleton';

import helper from './helper';
import styles from './Table.module.scss';
import TDCell from './TDCell';

const TableBody = React.forwardRef((props, ref) => {
  const { loading, columns, rows, identifier, tableId, currentTotal, hoverable } = props;

  return (
    <tbody id={helper.genId(tableId, helper.components().tbody)} ref={ref}>
      {loading && currentTotal === 0 && (
        <tr>
          <td style={{ padding: 0 }} colSpan={columns.length}>
            <TableSkeleton lineHight={50} num={5} />
          </td>
        </tr>
      )}

      {rows.map((item, i) => {
        const idCol = item[identifier] ?? {};
        const id = idCol.value || null;

        return (
          <tr key={id ?? i} className={cx({ [styles.hoverable]: hoverable })}>
            {columns.map((column) => {
              if (column.visible === false) return;

              const col = item[column.name] ?? null;
              if (!col) return;

              const conf = col.tdConfig ?? {};

              // eslint-disable-next-line consistent-return
              return (
                <TDCell key={column.name} {...conf}>
                  {col.value}
                </TDCell>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
});

TableBody.defaultProps = {
  tableId: '',
  loading: false,
  identifier: 'id',
  columns: [],
  rows: [],
  currentTotal: 0,
  hoverable: true,
};

export default TableBody;
