import React from 'react';

import cx from 'classnames';
import { saveAs } from 'file-saver';

import { useDownloadFileFromUrl } from 'api/File/FileQueryAPIUse';
import IconButton from 'components/buttons/IconButton/IconButton';
import Card from 'components/PHRUI/Card/Card';
import Icon from 'components/PHRUI/Icon/Icon';
import PHRFile from 'services/File/PHRFile';

import style from './style.module.scss';
import { propTypes, defaultProps, icon, color } from './utility';

const PHRFileThumbnail = ({ id, file, isDownloadable }) => {
  const fileDTO = PHRFile.create(file);
  const { name, sizeMBFormatted, formattedLocalCreatedAt, url } = fileDTO;
  const documentIcon = icon(fileDTO);
  const documentColor = color(fileDTO);

  const { refetch: downloadFile, isFetching } = useDownloadFileFromUrl(url);

  const onDownload = () => {
    downloadFile().then((response) => saveAs(response?.data?.data, name));
  };

  return (
    <Card
      id={id}
      border
      hoverable={false}
      raised={false}
      className={cx('d-flex flex-row justify-content-start align-items-center height-auto', style.container)}
    >
      <div className={cx('d-flex justify-content-center align-items-center border', style.iconContainer)}>
        <Icon icon={documentIcon} size={'xxl'} color={documentColor} />
      </div>

      <div className={'d-flex flex-column font-size-14 text-gray px-3'}>
        <span>{name}</span>
        <span>{sizeMBFormatted}</span>
        <span>{formattedLocalCreatedAt}</span>
      </div>

      {isDownloadable && (
        <div className={'d-flex justify-content-center align-items-center'}>
          <IconButton
            loading={isFetching}
            className={'border'}
            icon={'icon-download'}
            color={'primary'}
            onClick={onDownload}
            size={'lg'}
          />
        </div>
      )}
    </Card>
  );
};

PHRFileThumbnail.propTypes = propTypes;
PHRFileThumbnail.defaultProps = defaultProps;

export default PHRFileThumbnail;
