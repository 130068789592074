import React, { useContext } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import HeaderTitle from 'components/HeaderTitle/HeaderTitle';
import NavBar from 'components/Navigation/NavBar';
import { PerformanceContext } from 'modules/CompanySettings/Team/Performance/PerformanceContext';
import t from 'utility/setTranslation';

const Header = ({ onSelectView, url }) => {
  const { performanceState } = useContext(PerformanceContext);
  const navItems = [];

  if (performanceState.goalsEnabled && performanceState?.permissionsModule?.canViewGoals) {
    navItems.push({ title: 'goals', href: `${url}/goals` });
  }

  if (performanceState.oneOnOneEnabled && performanceState?.permissionsModule?.canViewOneOnOnes) {
    navItems.push({ title: '1to1', href: `${url}/1-on-1` });
  }

  if (
    performanceState.goalsEnabled &&
    performanceState?.permissionsModule?.canApproveTarget &&
    performanceState?.kpiApprovalFlowEnabled
  ) {
    navItems.push({ title: 'approvals', href: `${url}/approvals` });
  }

  return (
    <div className='pt-2'>
      <div className={cx('position-relative', 'pb-3')}>
        <div className='d-flex justify-content-between align-items-center pt-3'>
          <HeaderTitle className='mb-4 mt-1'>{t('GENERAL.PERFORMANCE')}</HeaderTitle>
        </div>
        <NavBar items={navItems} onSelectView={onSelectView} type='link' />
      </div>
    </div>
  );
};

Header.propTypes = {
  onSelectView: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export default Header;
