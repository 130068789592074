import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';

import Personas from 'components/Personas';
import NotificationMeta from 'services/Messaging/Notification/NotificationMeta';
import { PrivateRoutes } from 'topNavigation/Router/routes';

import styles from './InboxNotifications.module.scss';

const NotificationItem = ({ item, onSeenNotification, setShowMenu, onSelectNotification }) => {
  const [type, setType] = useState('default');
  const formatedRoute =
    item?.webRoute && item?.webRoute.includes('#')
      ? `${item.webRoute}&by-notification`
      : `${item.webRoute}#by-notification`;

  const displayIcon = (el) => {
    let custom = (
      <div className={cx(styles.customIcon, 'd-flex align-items-center justify-content-center')}>
        <i className='icon-bell' />
      </div>
    );

    if (el.notificationType === 'SYSTEM.NOTIFICATION.NOTIFICATION_CLOCK_IN_REMINDER') {
      custom = (
        <div
          className={cx(styles.customIcon, styles.customIconOrange, 'd-flex align-items-center justify-content-center')}
        >
          <i className='icon-alarm-checked' />
        </div>
      );
    }

    switch (el.pictureDisplayObject) {
      case 'SYSTEM.NOTIFICATION.PICTURE.DISPLAY.OBJECT.INITIATOR':
        return el.actionInitiator ? <Personas user={el.actionInitiator} size={52} /> : custom;

      case 'SYSTEM.NOTIFICATION.PICTURE.DISPLAY.OBJECT.TARGET.EMPLOYEE':
        return el.targetEmployee ? <Personas user={el.targetEmployee} size={52} /> : custom;
      default:
        return custom;
    }
  };

  const checkNotification = (notif) => {
    const { isClickable } = NotificationMeta.createFromNotification(notif);

    if (isClickable) return true;

    let check = false;
    if (_.isNull(notif?.data?.parent)) {
      if (notif?.data?.type === 'Request' || notif?.data?.type === 'Task') {
        check = true;
      }
    }
    if (notif?.data?.parent?.type === 'Request' || notif?.data?.parent?.type === 'Task') {
      check = true;
    }

    return check;
  };

  useEffect(() => {
    if (item.anchor && item.webRoute) {
      const url = new URL(item.anchor);

      item.anchorPath = url.pathname;

      if (url.hash) {
        item.anchorPath += url.hash;
      }

      setType('link');
    }
    return () => onSelectNotification(null);
  }, []);

  const navigate = useNavigate();

  const onNotificationOpen = () => {
    const { isClickable, navigateTo } = NotificationMeta.createFromNotification(item);

    if (isClickable) {
      navigate(navigateTo);
      setShowMenu(false);
      if (!item.isSeen) onSeenNotification(item.id);
    }

    if (item.notificationType === 'SYSTEM.NOTIFICATION.RECRUITMENT.HIRING-TEAM.ADDED') {
      navigate(`${PrivateRoutes.recruitmentJobs}/${item.data.jobPostId}`);
      setShowMenu(false);
      if (!item.isSeen) {
        onSeenNotification(item.id);
      }
    }
    if (
      item.notificationType.includes('REQUEST') ||
      item.notificationType.includes('APPROVAL') ||
      checkNotification(item)
    ) {
      onSelectNotification(item);
      window.history.pushState(null, 'notification', '#by-notification');
      if (!item.isSeen) {
        onSeenNotification(item.id);
      }
      setShowMenu(false);
    }
  };

  const innerContent = (
    <div className={cx(styles.notificationHeader, 'd-flex', 'justify-content-start')}>
      <div className={cx('me-3', 'mt-2', 'position-relative')}>
        {displayIcon(item)}

        {item.actionIconClass && (
          <i
            className={cx(
              styles.actionIcon,
              item.actionIconClass,
              item.notificationType.includes('CANCELLED') ? 'text-red' : '',
              item.notificationType.includes('SYSTEM.NOTIFICATION.MENTION') ? 'text-orange' : ''
            )}
          />
        )}
      </div>

      <div className='w-100'>
        <div className={cx(styles.notificationHeader, 'd-flex', 'align-items-center', 'justify-content-between')}>
          <div className={cx(styles.notificationTitle)}>{item.title}</div>
        </div>
        <div className={cx(styles.notificationContent)}>{item.content}</div>
        <div className={cx(styles.notificationDate)}>{moment(item.receivedAt).fromNow()}</div>
      </div>
    </div>
  );

  const displayMap = {
    link: (
      <Link
        to={formatedRoute || ''}
        className={cx(styles.notificationWrapper, styles.link, {
          [styles.unseen]: !item.isSeen,
        })}
        onClick={() => {
          onSeenNotification(item.id);
          setShowMenu(false);
        }}
      >
        {innerContent}
      </Link>
    ),
    default: (
      <div
        key={Math.random()}
        className={cx(styles.notificationWrapper, {
          [styles.unseen]: !item.isSeen,
          [styles.clickabileNotification]:
            !item.isSeen &&
            (item.notificationType.includes('REQUEST') ||
              item.notificationType.includes('APPROVAL') ||
              checkNotification(item)),
          [styles.clickabileNotificationSeen]:
            item.isSeen &&
            (item.notificationType.includes('REQUEST') ||
              item.notificationType.includes('APPROVAL') ||
              checkNotification(item)),
        })}
        onClick={onNotificationOpen}
      >
        {innerContent}
      </div>
    ),
  };

  return (
    <div className='position-relative'>
      <div className={styles.seenBtnBox}>
        {!item.isSeen && (
          <button
            className={styles.seenBtn}
            onClick={(e) => {
              e.stopPropagation();
              onSeenNotification(item.id);
            }}
            type='button'
          />
        )}
      </div>
      {displayMap[type]}
    </div>
  );
};

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
  onSeenNotification: PropTypes.func.isRequired,
  setShowMenu: PropTypes.func.isRequired,
};

export default NotificationItem;
