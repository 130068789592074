import React, { useContext, useEffect, useState } from 'react';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import AuthApi from 'api/AuthApi';
import EmployeeApi from 'api/EmployeeApi';
import { AuthContext } from 'auth/AuthContext';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import PasswordInput from 'components/forms/components/PasswordInput';
import PasswordConfig from 'components/PasswordConfig';
import SpinnerRing from 'components/Spinner/SpinnerRing';
import { specialCharacterRegex, lowercaseRegex, uppercaseRegex, hasNumberRegex } from 'utility/regexUtils';
import t from 'utility/setTranslation';

const ChangePassword = ({ setIsFormDirty, setSending, setSaved, setAlertBoxMsg }) => {
  const { authState } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [passwordConfig, setPasswordConfig] = useState(null);
  const [passwordStrength, setPasswordStrength] = useState({
    passwordMinimumLength: false,
    requireLowercase: false,
    requireNumber: false,
    requireSpecialChar: false,
    requireUppercase: false,
  });

  const validate = (values) => {
    const errors = {};
    if (!values.currentPassword) {
      errors.currentPassword = t(`GENERAL.FIELD.CANT_BE_EMPTY`, {
        field: t('GENERAL.CURRENT_PASSWORD'),
      });
    }

    if (!values.newPassword) {
      errors.newPassword = t(`GENERAL.FIELD.CANT_BE_EMPTY`, {
        field: t('GENERAL.NEW_PASSWORD'),
      });
    }

    if (!values.reNewPassword) {
      errors.reNewPassword = t(`GENERAL.FIELD.CANT_BE_EMPTY`, {
        field: t('GENERAL.CONFIRM_NEW_PASSWORD'),
      });
    }

    if (values.newPassword !== values.reNewPassword) {
      errors.newPassword = t('GENERAL.PASSWORDS_NOT_MATCH');
      errors.reNewPassword = t('GENERAL.PASSWORDS_NOT_MATCH');
    }

    /* Password strength validation */
    const customErrors = {};
    if (values.newPassword.length < passwordConfig?.passwordMinimumLength) {
      errors.newPassword = t('GENERAL.PASSWORD_DO_NOT_MATCH_CONFIG');
      customErrors.passwordMinimumLength = false;
    } else {
      customErrors.passwordMinimumLength = true;
    }

    if (passwordConfig?.requireSpecialChar && !specialCharacterRegex.test(values.newPassword)) {
      errors.newPassword = t('GENERAL.PASSWORD_DO_NOT_MATCH_CONFIG');
      customErrors.requireSpecialChar = false;
    } else {
      customErrors.requireSpecialChar = true;
    }

    if (passwordConfig?.requireLowercase && !lowercaseRegex.test(values.newPassword)) {
      errors.newPassword = t('GENERAL.PASSWORD_DO_NOT_MATCH_CONFIG');
      customErrors.requireLowercase = false;
    } else {
      customErrors.requireLowercase = true;
    }

    if (passwordConfig?.requireUppercase && !uppercaseRegex.test(values.newPassword)) {
      errors.newPassword = t('GENERAL.PASSWORD_DO_NOT_MATCH_CONFIG');
      customErrors.requireUppercase = false;
    } else {
      customErrors.requireUppercase = true;
    }

    if (passwordConfig?.requireNumber && !hasNumberRegex.test(values.newPassword)) {
      errors.newPassword = t('GENERAL.PASSWORD_DO_NOT_MATCH_CONFIG');
      customErrors.requireNumber = false;
    } else {
      customErrors.requireNumber = true;
    }

    setPasswordStrength(customErrors);
    /* END PASSWORD STRENGTH */

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      reNewPassword: '',
    },
    validate,
    onSubmit: (values) => {
      setSending(true);

      EmployeeApi.meChangePassword(values).then(
        () => {
          setSending(false);
          setSaved(true);

          setAlertBoxMsg(t('GENERAL.YOU_HAVE_TO_BE_LOGOUT'));

          setTimeout(() => {
            localStorage.removeItem('store');
            window.location.href = '/login';
            setAlertBoxMsg('');
          }, 4000);
        },
        () => {
          setSending(false);
        }
      );
    },
  });

  const getPasswordConfigHandler = (currentUsername) => {
    setLoading(true);
    AuthApi.getUserPasswordConfig(currentUsername)
      .then((res) => {
        setPasswordConfig(res.data);
        setLoading(false);
      })
      .catch(() => {
        setPasswordConfig(null);
        setLoading(false);
      });
  };

  useEffect(() => {
    setIsFormDirty(formik.dirty);
  }, [formik.dirty]);

  useEffect(() => {
    if (authState?.user?.username) getPasswordConfigHandler(authState.user.username);
  }, []);

  return (
    <div>
      {loading && <SpinnerRing />}

      <form noValidate onSubmit={formik.handleSubmit}>
        <div className='row mx-5'>
          <div className='col-12 px-2a mb-3'>
            <PasswordInput
              label={t('GENERAL.CURRENT_PASSWORD')}
              name='currentPassword'
              value={formik.values.currentPassword}
              isRequired
              error={formik.touched.currentPassword && formik.errors.currentPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className='col-12 px-2a mb-3'>
            <PasswordInput
              label={t('GENERAL.NEW_PASSWORD')}
              name='newPassword'
              value={formik.values.newPassword}
              isRequired
              error={formik.touched.newPassword && formik.errors.newPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className='col-12 px-2a mb-3'>
            <PasswordInput
              label={t('GENERAL.CONFIRM_NEW_PASSWORD')}
              name='reNewPassword'
              value={formik.values.reNewPassword}
              isRequired
              error={formik.touched.reNewPassword && formik.errors.reNewPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {passwordConfig && (
            <div className='col-12 px-2a mb-3'>
              <PasswordConfig passwordConfig={passwordConfig} passwordStrength={passwordStrength} />
            </div>
          )}
        </div>
        <div className='d-flex justify-content-end pt-4 border-t px-5'>
          <RoundedButton type='submit' text='Change Password' btnStyle='contained' color='primary' size='md' />
        </div>
      </form>
    </div>
  );
};

ChangePassword.propTypes = {
  setIsFormDirty: PropTypes.func,
  setSending: PropTypes.func.isRequired,
  setSaved: PropTypes.func.isRequired,
  setAlertBoxMsg: PropTypes.func.isRequired,
};

ChangePassword.defaultProps = {
  setIsFormDirty: undefined,
};

export default ChangePassword;
