/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useContext, useEffect, useRef } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import palmhrAnnouncementImg from 'assets/img/announcement-default.jpeg';
import khadamatiAnnouncementImg from 'assets/img/enterprise/khadamati/default_announcement.png';
import khadamatiLogo from 'assets/img/enterprise/khadamati/logo_primary.png';
import palmhrLogo from 'assets/img/logo.svg';
import { AuthContext } from 'auth/AuthContext';
import Menu from 'components/menu/Menu';
import createSelectOptions from 'components/menu/utility/createSelectOptions';
import Personas from 'components/Personas';
import ShowMoreAnnouncement from 'components/ShowMoreAnnouncement';
import { ThemeContext } from 'themes/ThemeContext';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

import styles from './Announcement.module.scss';
import AnnouncementFile from './AnnouncementFile/AnnouncementFile';
import ListReactions from './ListReactions/ListReactions';

const AnnouncementCard = ({ className, isAnnouncer, data, onReaction, onOpen, onEdit, onDelete }) => {
  const { authState } = useContext(AuthContext);
  const { themeState } = useContext(ThemeContext);
  const enterpriseName = themeState?.companyName || 'palmhr';
  const reactionRef = useRef();
  const [reactions, setReactions] = useState(data?.announcementReactions || []);
  const [activeLike, setActiveLike] = useState(false);
  const [activeHeart, setActiveHeart] = useState(false);
  const [listPosition, setListPosition] = useState({
    top: '',
    bottom: '',
  });

  const imageMap = {
    palmhr: {
      name: t('GENERAL.PALMHR_TEAM'),
      logo: palmhrLogo,
      announcementImg: palmhrAnnouncementImg,
    },
    khadamati: {
      name: t('AHAD.TITLE'),
      logo: khadamatiLogo,
      announcementImg: khadamatiAnnouncementImg,
    },
  };

  const checkIsActive = (reactionItems, type) => {
    if (reactionItems && Array.isArray(reactionItems)) {
      const array = reactionItems;
      for (let index = 0; index < array.length; index += 1) {
        const element = array[index];

        if (element.employee?.id === authState.user.identification) {
          if (type === 'like') {
            setActiveLike(true);
          } else {
            setActiveHeart(true);
          }

          return;
        }
      }
    }

    if (type === 'like') {
      setActiveLike(false);
    } else {
      setActiveHeart(false);
    }
  };

  useEffect(() => {
    if (data && data.announcementReactions) {
      checkIsActive(
        data?.announcementReactions.filter((el) => el.reaction?.id === 1),
        'like'
      );

      checkIsActive(
        data?.announcementReactions.filter((el) => el.reaction?.id === 2),
        'heart'
      );

      setReactions(data.announcementReactions);
    }
  }, [data]);

  const elementInViewport = () => {
    const bounding = reactionRef.current.getBoundingClientRect();

    if (bounding.top >= 0 && bounding.bottom + 200 <= (window.innerHeight || document.documentElement.clientHeight)) {
      setListPosition({
        top: '20px',
        bottom: '',
      });
    } else {
      setListPosition({
        top: '',
        bottom: '20px',
      });
    }
  };

  const onAction = (action) => {
    switch (action) {
      case 'Delete':
        onDelete(data);
        break;
      case 'Edit':
        onEdit(data);
        break;
      default:
        break;
    }
  };

  return (
    <div className={cx(styles.cardWrapper, className)}>
      <div className={cx(styles.header, 'd-flex justify-content-between align-items-center position-relative')}>
        <div className={cx(styles.userDiv, 'd-flex justify-content-start align-items-center')}>
          {data.isDefault ? (
            <div className={cx(styles.logoDiv, 'me-2')}>
              <img src={imageMap[enterpriseName].logo} alt='logo' className={cx(styles.img)} />
            </div>
          ) : (
            <Personas user={data.author} size={32} customClass='me-2' />
          )}

          <div className={styles.nameDiv}>
            <div className={styles.name}>
              {data.isDefault
                ? imageMap[enterpriseName].name
                : readName(authState.user.language, data?.author?.fullName, data?.author?.fullNameArabic)}
            </div>

            <div className={styles.date}>{moment(data?.createdAt).fromNow()}</div>
          </div>
        </div>

        {!data.isDefault && isAnnouncer && (
          <div className='position-relative'>
            <Menu
              data={createSelectOptions(['Edit', 'Delete'])}
              onAction={onAction}
              style={{ width: '150px' }}
              iconSize='md'
            />
          </div>
        )}
      </div>

      <div
        className={cx(styles.content, 'cursor-pointer')}
        onClick={() => {
          onOpen(data);
        }}
      >
        <div className={cx(styles.title)}>
          {data.isDefault ? t(data?.title) : readName(authState.user.language, data.title, data.titleArabic)}
        </div>

        <div className={cx(styles.description)}>
          <ShowMoreAnnouncement
            text={
              data.isDefault ? t(data?.content) : readName(authState.user.language, data.content, data.contentArabic)
            }
            showMoreButton
          />
        </div>

        <AnnouncementFile announcement={data} enterpriseName={enterpriseName} />
      </div>

      <div className={cx(styles.footer, 'd-flex justify-content-between alig-items-center px-2')}>
        <div ref={reactionRef} className='d-flex justify-content-between alig-items-center'>
          <div
            className='d-flex justify-content-between alig-items-center pe-3 tooltip-box position-relative'
            onMouseOver={() => elementInViewport()}
          >
            <i
              className={cx(styles.reactionIcon, 'icon-thumbs-up', 'me-1', {
                [styles.activeLike]: activeLike,
              })}
              onClick={(e) => {
                e.stopPropagation();
                onReaction(data.id, 1);

                if (activeLike) {
                  setReactions(
                    reactions.filter(
                      (el) => !(el.reaction?.id === 1 && el.employee?.id === authState.user.identification)
                    )
                  );
                } else {
                  const stateReactions = [...reactions];
                  stateReactions.push({
                    createdAt: moment().format('YYYY-MM-DD'),
                    employee: {
                      ...authState.user,
                      id: authState.user.identification,
                    },
                    id: Math.floor(Math.random() * 1000000000),
                    reaction: {
                      id: 1,
                      name: 'REACTION.LIKE',
                      icon: 'icon-thumbs-up',
                    },
                  });
                  setReactions(stateReactions);
                }

                setActiveLike(!activeLike);
              }}
            />
            <span className={styles.reactionNumber}>{reactions.filter((el) => el.reaction?.id === 1).length}</span>
            <ListReactions
              data={_.filter(data.announcementReactions, ['reaction.name', 'REACTION.LIKE'])}
              title='REACTION.LIKE'
              position={listPosition}
            />
          </div>

          <div
            onMouseOver={() => elementInViewport()}
            className='d-flex justify-content-between alig-items-center tooltip-box position-relative'
          >
            <i
              className={cx(styles.reactionIcon, 'icon-heart', 'me-1', {
                [styles.activeHeart]: activeHeart,
              })}
              onClick={(e) => {
                e.stopPropagation();
                onReaction(data.id, 2);

                if (activeHeart) {
                  setReactions(
                    reactions.filter(
                      (el) => !(el.reaction?.id === 2 && el.employee?.id === authState.user.identification)
                    )
                  );
                } else {
                  const stateReactions = [...reactions];
                  stateReactions.push({
                    createdAt: moment().format('YYYY-MM-DD'),
                    employee: {
                      ...authState.user,
                      id: authState.user.identification,
                    },
                    id: Math.floor(Math.random() * 1000000000),
                    reaction: {
                      icon: 'icon-hart',
                      id: 2,
                      name: 'REACTION.HART',
                    },
                  });
                  setReactions(stateReactions);
                }

                setActiveHeart(!activeHeart);
              }}
            />
            <span className={styles.reactionNumber}>{reactions.filter((el) => el.reaction?.id === 2).length}</span>
            <ListReactions
              data={_.filter(data.announcementReactions, ['reaction.name', 'REACTION.HART'])}
              title='REACTION.HEART'
              position={listPosition}
            />
          </div>
        </div>

        <div className='d-flex justify-content-between alig-items-center'>
          <i
            className={cx(styles.reactionIcon, 'icon-comment-text me-1')}
            onClick={(e) => {
              e.stopPropagation();
              onOpen(data);
            }}
          />
          <span className={cx(styles.reactionNumber)}>{data?.comments.length}</span>
        </div>
      </div>
    </div>
  );
};

AnnouncementCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  onReaction: PropTypes.func,
  onOpen: PropTypes.func,
  onDelete: PropTypes.func,
  isAnnouncer: PropTypes.bool,
};

AnnouncementCard.defaultProps = {
  className: '',
  data: {},
  onReaction: undefined,
  onOpen: undefined,
  onDelete: undefined,
  isAnnouncer: false,
};

export default AnnouncementCard;
