import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import icon from 'assets/img/empty-time.png';
import DataTable from 'components/DataTable/DataTable';
import TableHead from 'components/DataTable/TableHead';
import EmptyState from 'components/EmptyPages/EmptyState';
import Search from 'components/forms/components/Search';
import Modal from 'components/Modal';
import Select from 'components/PHRUI/Select/Select';
import ToolBar from 'components/PHRUI/ToolBar/ToolBar';
import useDebounce from 'shared/useDebounce';
import { useSlug } from 'shared/useSlug';
import t from 'utility/setTranslation';

import { api, buildPayload, TYPE_IN, TYPE_OUT, options, ALL } from './utility';
import WhoIsInTableRow from './WhoIsInTableRow';
import AttendanceDetails from '../AttendanceDetails';

const WhoIsInTable = ({ type }) => {
  const { slug, toggleSlug } = useSlug(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(100);
  const [term, setTerm] = useState('');
  const debouncedTerm = useDebounce(term, 400);
  const [selected, setSelected] = useState(null);
  const [leaveType, setLeaveType] = useState(ALL);
  const filterOptions = options(type);

  const { isFetching, data: response } = api(type)(slug, buildPayload(limit, page, debouncedTerm, leaveType, type));
  const { items, pagination } = response?.data ?? {};

  const onPageChange = ({ currentPage, perPage }) => {
    setPage(currentPage);
    setLimit(perPage);
    toggleSlug();
  };

  const onDetailsClose = () => {
    setSelected(null);
  };

  const onLeaveTypeSelect = (newLeaveType) => {
    setLeaveType(newLeaveType);
    toggleSlug();
  };

  useEffect(() => {
    toggleSlug();
  }, [debouncedTerm]);

  return (
    <>
      <ToolBar fullWidth className={'px-5 pb-3'}>
        <Search
          onChange={(e) => setTerm(e.target.value)}
          placeholder='Search'
          value={term}
          onClear={() => setTerm('')}
        />
        <Select
          id={'who-is-in-leave-type'}
          label={`${_.capitalize(t('GENERAL.LEAVE_TYPE').toLowerCase())}:`}
          value={ALL}
          options={filterOptions}
          onSelect={onLeaveTypeSelect}
        />
      </ToolBar>

      <DataTable
        data={items ?? []}
        TableRowComponent={WhoIsInTableRow}
        isLoading={isFetching}
        isPagination
        isScroll
        columns={[{ title: 'employee' }, { title: 'leave type' }]}
        TableHeader={TableHead}
        onPaginationAction={onPageChange}
        pagination={pagination}
        zeroIndexingPagination
        rowAdditionalProps={{ onClick: (id) => setSelected(id), type }}
        EmptySearchComponent={() => (
          <EmptyState icon={icon} title={t('GENERAL.EMPTY_CHECK IN_LIST')} showBtn={false} className='bg-white mt-8' />
        )}
      />

      <Modal
        isOpen={!!selected}
        styles={{ width: '880px', height: '90vh', padding: '0', borderRadius: '8px' }}
        showDirtyForm
        onRequestClose={onDetailsClose}
        showHeader={false}
      >
        {selected && <AttendanceDetails onGoBack={onDetailsClose} onClose={onDetailsClose} selected={selected} />}
        {!selected && []}
      </Modal>
    </>
  );
};

WhoIsInTable.propTypes = {
  type: PropTypes.oneOf([TYPE_IN, TYPE_OUT]),
};

WhoIsInTable.defaultProps = {
  type: TYPE_IN,
};

export default WhoIsInTable;
