import React from 'react';

import cx from 'classnames';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';

import spinner from 'assets/json/response_loaders/loop/data.json';

import styles from './SpinnerRing.module.scss';

const SpinnerRing = ({ positionFixed, className }) => (
  <div
    id='spinner-ring'
    data-testid='spinner-ring'
    className={cx(
      styles.sendingOverlay,
      'd-flex',
      'justify-content-center',
      'align-items-center',
      positionFixed && 'position-fixed',
      className
    )}
    style={{ width: '100%', height: '100%' }}
  >
    <div className={styles.content}>
      <Lottie className={styles.spinner} animationData={spinner} />
    </div>
  </div>
);

SpinnerRing.propTypes = {
  positionFixed: PropTypes.bool,
};

SpinnerRing.defaultProps = {
  positionFixed: false,
};

export default SpinnerRing;
