import React, { useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import formatKey from 'utility/formatKey';

import FieldWrapper from './FieldWrapper';
import styles from './TextInput.module.scss';

const PasswordInput = React.forwardRef((props, ref) => {
  const { customClass, name, placeholder, value, label, isRequired, onChange, onBlur, error } = props;
  const [showPassword, setShowPassword] = useState(false);

  let requiredAttr = false;

  if (isRequired) {
    requiredAttr = 'required';
  }

  const onBlurField = () => {
    if (onBlur) {
      onBlur(name);
    }
  };

  return (
    <FieldWrapper name={name} className={cx(styles.inputWrapper, customClass, 'position-relative')}>
      <div className='d-flex'>
        {label ? (
          <label className='label' htmlFor={name}>
            {formatKey('GENERAL', label)}
            {isRequired && <sup>*</sup>}
          </label>
        ) : null}
      </div>
      <input
        ref={ref}
        className={cx('form-control', error && 'is-invalid')}
        type={showPassword ? 'text' : 'password'}
        name={name}
        defaultValue={value}
        placeholder={placeholder}
        required={requiredAttr}
        onChange={onChange}
        onBlur={onBlurField}
        autoComplete='off'
      />
      <i
        className={cx(styles.passwordIcon, showPassword ? 'icon-eye-no text-gray' : 'icon-eye text-gray')}
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      />
      <div className='invalid-feedback'>{error}</div>
    </FieldWrapper>
  );
});

PasswordInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  customClass: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
};

PasswordInput.defaultProps = {
  value: '',
  placeholder: '',
  customClass: '',
  label: '',
  isRequired: false,
  onChange: undefined,
  onBlur: undefined,
  error: undefined,
};

export default PasswordInput;
