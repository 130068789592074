import React, { useState } from 'react';

import cx from 'classnames';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';

import failed from 'assets/json/response_loaders/fail/data.json';
import success from 'assets/json/response_loaders/success/data.json';
import IconButton from 'components/buttons/IconButton/IconButton';
import t from 'utility/setTranslation';

import styles from './SuccessCheckmark.module.scss';

const SuccessCheckmark = ({ positionFixed, text, isFailed, noTranslation, hideCloseButton }) => {
  const [closed, setClosed] = useState(false);

  return (
    <div
      id='checkmark-overlay'
      className={cx(
        styles.sendingOverlay,
        'd-flex',
        'justify-content-center',
        'align-items-center',
        positionFixed && 'position-fixed',
        closed && styles.displayNone
      )}
      data-testid='successCheckmark'
    >
      <div className={styles.content}>
        <Lottie
          id='lottie-success-error-animation'
          className={styles.checkmark}
          animationData={isFailed ? failed : success}
          loop={false}
        />
        <div className={cx(styles.text, 'align-self-end m-0')}>
          {isFailed && (
            <span className={cx(styles.upsText, 'mb-2')}>
              Uuuuuups.... <i className={cx(styles.icon, 'icon-emoticon-sad')} />
            </span>
          )}
          {noTranslation ? <div>{text}</div> : <span>{t(text || 'GENERAL.SUCCESS_MESSAGE')}</span>}
        </div>
        {!hideCloseButton && (
          <IconButton
            className={styles.xIcon}
            icon='icon-cross-x'
            size='md'
            color='gray'
            onClick={() => {
              setClosed(true);
            }}
          />
        )}
      </div>
    </div>
  );
};

SuccessCheckmark.propTypes = {
  positionFixed: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isFailed: PropTypes.bool,
  noTranslation: PropTypes.bool,
};

SuccessCheckmark.defaultProps = {
  positionFixed: false,
  text: 'GENERAL.SUCCESS_MESSAGE',
  isFailed: false,
  noTranslation: false,
};

export default SuccessCheckmark;
