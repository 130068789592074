import React, { useContext, useRef, useState } from 'react';

import cx from 'classnames';

import palmLogo from 'assets/img/palm-ai-text.svg';
import palmVideo from 'assets/video/palmmp4.mp4';
import { AuthContext } from 'auth/AuthContext';
import t from 'utility/setTranslation';

import styles from './PalmHrAICard.module.scss';

const PalmHrAICard = ({ onClick }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const { authState } = useContext(AuthContext);

  const handleMouseOver = () => {
    setIsPlaying(true);
    videoRef.current.play();
  };

  const handleMouseOut = () => {
    setIsPlaying(false);
  };

  const handleVideoEnd = () => {
    if (isPlaying) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  };

  const isWindowsOs = navigator.userAgent.includes('Windows');
  const isArabicLang = authState.user.language === 'ar';

  return (
    <div
      onMouseEnter={handleMouseOver}
      onMouseOut={handleMouseOut}
      onBlur={handleMouseOut}
      onClick={onClick}
      className={cx(
        styles.PalmHrAiCard,
        isWindowsOs ? 'bg-dark-blue-windows' : 'bg-dark-blue',
        `d-flex
      align-items-center radius-8 w-100 mb-3 shadow-04 position-relative overflow-hidden cursor-pointer`
      )}
    >
      <div
        className={cx(
          styles.PalmHrAiCard__beta,
          { [styles.PalmHrAiCard__beta__arabic]: isArabicLang },
          'd-flex align-items-center justify-content-center background-orange'
        )}
      >
        <p className='font-size-11 uppercase color-white'>{t('GENERAL.BETA')}</p>
      </div>
      <video
        className={styles.PalmHrAiCard__video}
        ref={videoRef}
        onEnded={handleVideoEnd}
        src={palmVideo}
        width={75}
        muted
      />
      <img className={cx(styles.PalmHrAiCard__logo, 'ms-2')} alt='palm logo' src={palmLogo} />
      <i className='icon-circle-arrow-right color-white font-size-20 ms-lg-3 ms-md-0' />
    </div>
  );
};

export default PalmHrAICard;
