import React from 'react';

import cx from 'classnames';

import Radio from 'components/forms/components/Radio';

import styles from './GoLiveOption.module.scss';
import GoLiveOptionContainer from '../../GoLiveOptionContainer/GoLiveOptionContainer';

const GoLiveOption = ({ key, name, description, className, isChecked, onChange, value, error, onBlur }) => (
  <GoLiveOptionContainer className={className} error={error}>
    <div className='d-flex justify-content-between'>
      <div className='d-flex align-items-center gap-2'>
        <h5 className='subtitle mb-0 text-center'>{name}</h5>
        {!description && <i className='icon-info font-size-20 text-gray' />}
      </div>
      <Radio
        key={key}
        name={name}
        value={value}
        checked={isChecked}
        onChange={onChange}
        className='align-self-center'
        error={error}
        onBlur={onBlur}
      />
    </div>
    {description && (
      <div className={cx('d-flex gap-2 pt-3 mt-3', styles.description)}>
        <i className='icon-info font-size-20 text-gray' />
        <p className='font-size-13'>{description}</p>
      </div>
    )}
  </GoLiveOptionContainer>
);

export default GoLiveOption;
