import { useEffect } from 'react';

import useLanguage from './useLanguage';

const useCrispStyles = () => {
  const activeLang = useLanguage();

  useEffect(() => {
    const applyStyles = (styles) => {
      const styleElement = document.createElement('style');
      styleElement.textContent = styles;
      document.head.appendChild(styleElement);
      return () => {
        document.head.removeChild(styleElement);
      };
    };

    let cleanup = null;

    const styles = {
      en: `
        .crisp-client .cc-1brb6 .cc-1yy0g .cc-1m2mf, .crisp-client .cc-1brb6 .cc-1yy0g .cc-1no03 {
          position: absolute !important;
          left: 60px !important;
        }
        .crisp-client .cc-1brb6 .cc-1yy0g .cc-1m2mf .cc-157aw .cc-d73fc .cc-1bvfm .cc-1d4mk, 
        .crisp-client .cc-1brb6 .cc-1yy0g .cc-1m2mf .cc-157aw .cc-d73fc .cc-1bvfm .cc-1d4mk .cc-1kqps {
          width: 60px !important;
          height: 60px !important;
          border-radius: 60px !important;
        }
        .cc-1bvfm {
          width: 60px !important;
          height: 60px !important;
        }
        .crisp-client .cc-1brb6 a, .crisp-client .cc-1brb6 a *, 
        .crisp-client .cc-1brb6 input[type=checkbox], .crisp-client .cc-1brb6 input[type=radio], 
        .crisp-client .cc-1brb6 input[type=submit], .crisp-client .cc-1brb6 select {
          width: 60px !important;
        }
        .crisp-client .cc-1brb6 .cc-1yy0g .cc-1m2mf .cc-6lwfw .cc-1pzwe {
        right: 24px !important;
        left: auto !important;
        }
      `,
      ar: `
        .crisp-client .cc-1brb6 .cc-1yy0g .cc-1m2mf, .crisp-client .cc-1brb6 .cc-1yy0g .cc-1no03 {
          position: absolute !important;
          right: 60px !important;
        }
        .crisp-client .cc-1brb6 .cc-1yy0g .cc-1m2mf .cc-6lwfw .cc-1pzwe {
        left: 24px !important;
        right: auto !important;
        }
      `,
    };

    if (styles[activeLang]) {
      cleanup = applyStyles(styles[activeLang]);
    }

    return cleanup;
  }, [activeLang]);
};

export default useCrispStyles;
