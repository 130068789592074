import React from 'react';

import cx from 'classnames';
import { saveAs } from 'file-saver';

import { useDownloadFileFromUrl } from 'api/File/FileQueryAPIUse';
import IconButton from 'components/buttons/IconButton/IconButton';
import ToolBar from 'components/PHRUI/ToolBar/ToolBar';
import PHRFileDTO from 'services/File/PHRFile';

import PHRFileThumbnail from './PHRFileThumbnail/PHRFileThumbnail';
import style from './style.module.scss';
import { propTypes, defaultProps } from './utility';

const PHRFile = ({ id, file, isDownloadable, isThumbnail, onDelete }) => {
  const fileDTO = PHRFileDTO.create(file);
  const { isImage, mimeType, url, name } = fileDTO;

  const { refetch: downloadFile, isFetching } = useDownloadFileFromUrl(url);

  const onDownload = () => {
    downloadFile().then((response) => saveAs(response?.data?.data, name));
  };

  if (isThumbnail) return <PHRFileThumbnail id={`${id}-thumbnail`} file={fileDTO} isDownloadable={isDownloadable} />;

  return (
    <div className={cx('h-100 w-100 position-relative')}>
      {(isDownloadable || onDelete) && (
        <ToolBar fullWidth justifyContent={'end'} className={cx(style.toolbar, 'p-2')}>
          {isDownloadable && (
            <IconButton
              loading={isFetching}
              className={'border'}
              icon={'icon-download'}
              color={'primary'}
              onClick={onDownload}
              size={'md'}
            />
          )}

          {onDelete && (
            <IconButton className={'border'} icon={'icon-trash'} color={'danger'} onClick={onDelete} size={'md'} />
          )}
        </ToolBar>
      )}

      {isImage && <img src={url} alt={'media'} className={style.img} />}

      {!isImage && <embed src={`${url}#toolbar=0`} type={mimeType} className={style.doc} />}
    </div>
  );
};

PHRFile.propTypes = propTypes;
PHRFile.defaultProps = defaultProps;

export default PHRFile;
