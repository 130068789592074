import _ from 'lodash';

import { SUBSCRIPTION_TYPE_VALUES } from './constants';

export const getPlgPlans = (items) => items.find((item) => item.isPrimary);

export const getPlgServicesSubscriptions = (items) => items.filter((item) => !item.isPrimary && item.isService);

export const getPlgProductsSubscriptions = (items) => items.filter((item) => !item.isPrimary && !item.isService);

export const getSubscriptionValues = (formik, plgPricingPlan = {}) => {
  const { identifier, currency, country, defaultUnitPrice, isSingleCharge } = plgPricingPlan;

  if (identifier) formik.setFieldValue('subscription.plan', identifier);
  if (currency) formik.setFieldValue('subscription.currency', currency);
  if (country) formik.setFieldValue('subscription.country', country);
  if (defaultUnitPrice) formik.setFieldValue('subscription.unitPrice', defaultUnitPrice);
  if (isSingleCharge) formik.setFieldValue('subscription.type', SUBSCRIPTION_TYPE_VALUES.ONE_TIME);
};

let isDomainAvailable = false;
let domainTested = false;

export const updateDomainAvailability = (availability) => {
  isDomainAvailable = availability;
};

export const getDomainAvailability = () => isDomainAvailable;

export const setDomainTested = (tested) => {
  domainTested = tested;
};

export const isDomainTested = () => domainTested;

export const getSubscriptionPricingModels = (isSingleCharge, pricingUnit, pricingModel, defaultBillingPeriod) => {
  if (!pricingUnit || !pricingModel || !defaultBillingPeriod) {
    return '';
  }

  if (!isSingleCharge) {
    if (pricingModel === 'PER_UNIT') {
      return `${pricingUnit.toLowerCase()} / ${defaultBillingPeriod.toLowerCase()}`;
    }
    if (pricingModel === 'FLAT_FEE') {
      return `per ${defaultBillingPeriod.toLowerCase()}`;
    }
  }
  if (isSingleCharge) {
    return `per ${pricingUnit.toLowerCase()}`;
  }
  return '';
};

export const adaptAddonSubscriptionsForAPI = (items, formik) =>
  items.map((item) => ({
    plan: item.identifier,
    numberOfUsers:
      item.pricingModel === 'PER_UNIT' && item.pricingUnit !== 'INTEGRATION'
        ? formik.values.subscription.numberOfUsers
        : 1,
    unitPrice: item.defaultUnitPrice,
    currency: item.currency,
    discount: 0,
    type: item.isSingleCharge ? SUBSCRIPTION_TYPE_VALUES.ONE_TIME : SUBSCRIPTION_TYPE_VALUES.SUBSCRIPTION,
    addonSubscriptions: [],
    country: item.country,
    periodUnit: formik.values.subscription.periodUnit,
    startDate: new Date().toISOString(),
  }));

export const getOrderSummaryRowsData = (invoicePreview, invoiceCurrency) =>
  invoicePreview.items.map((item, index) => ({
    id: index + 1,
    description: item.description,
    units: item.units,
    unitPrice: item.unitPrice,
    amount: item.amount,
    billingPeriod: item.defaultBillingPeriod,
    currency: invoiceCurrency,
  }));

export const formatPrice = (number, currencyCode, locale = 'en-US') => {
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return `${currencyCode} ${formatter.format(number)}`;
};

export const getTrialDays = (expireDate) => {
  const today = new Date();
  const endDate = new Date(expireDate);

  const millisecondsDiff = endDate - today;

  const daysDiff = millisecondsDiff / (1000 * 60 * 60 * 24);

  return Math.ceil(daysDiff);
};

export const adaptValuesForAPI = (values) => {
  const sanitizedValues = _.cloneDeep(values);
  delete sanitizedValues.isPaymentComplete;
  delete sanitizedValues.isTermsAccepted;
  return sanitizedValues;
};
