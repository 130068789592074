import React, { useRef, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './ExpandableWrapper.module.scss';

const ExpandableWrapper = ({
  renderChildren,
  className,
  containerClassName,
  maxHeight,
  minHeight,
  onExpand,
  onUnExpand,
}) => {
  const [isExpand, setIsExpand] = useState(false);
  const wrapperRef = useRef(null);

  const expandHandler = () => {
    wrapperRef.current.style.height = `${maxHeight}px`;
    setIsExpand(true);
    onExpand();
  };

  const unExpandHandler = () => {
    wrapperRef.current.style.height = `${minHeight}px`;
    setIsExpand(false);
    onUnExpand();
  };

  return (
    <div ref={wrapperRef} className={cx(styles.ExpandableWrapper, containerClassName)}>
      <div
        style={{ height: isExpand ? maxHeight : minHeight }}
        className={cx(styles.ExpandableWrapper__block, className)}
      >
        {renderChildren({ expandHandler, unExpandHandler })}
      </div>
    </div>
  );
};

ExpandableWrapper.propTypes = {
  renderChildren: PropTypes.func.isRequired,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  maxHeight: PropTypes.number.isRequired,
  minHeight: PropTypes.number.isRequired,
  onExpand: PropTypes.func,
  onUnExpand: PropTypes.func,
};

ExpandableWrapper.defaultProps = {
  className: '',
  containerClassName: '',
  onExpand: () => {},
  onUnExpand: () => {},
};

export default ExpandableWrapper;
