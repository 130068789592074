import { useMutation, useQuery } from '@tanstack/react-query';

import CompanySettingsApi from '../../../api/CompanySettingsApi';
import SubscriptionApi from '../../../api/SubscriptionApi';

export const queryKeys = {
  companySettingsData: ['companySettingsData'],
  subscriptionInvoices: ['subscriptionInvoices'],
  downloadSubscriptionInvoice: ['downloadSubscriptionInvoice'],
};

export const useCompanySettingsData = () =>
  useQuery(queryKeys.companySettingsData, CompanySettingsApi.getCompanySettingsData);

export const UseListSubscriptionInvoices = () =>
  useQuery(queryKeys.subscriptionInvoices, SubscriptionApi.getSubscriptionInvoices);

export const useDownloadSubscriptionInvoice = () =>
  useMutation(({ invoice, action }) => SubscriptionApi.downloadInvoice(invoice, action));
