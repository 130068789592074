import React from 'react';

import Card from 'components/Card/Card';
import Tooltip from 'components/tooltip/Tooltip';

const StatisticsDataCard = ({ isLoading, data, title, tooltipText, unit }) => (
  <Card className='d-flex flex-column position-relative pb-3' isLoading={isLoading}>
    <section className='d-flex w-100'>
      <h5 className='font-size-16 text-gray-500 font-weight-bold mb-0'>{title}</h5>
      <section className='ms-auto'>
        <Tooltip label={tooltipText} position='topLeft' size='md'>
          <i className='icon-info text-gray fs-20 ms-auto' />
        </Tooltip>
      </section>
    </section>
    <h2 className='mt-2a mb-1 font-size-40 font-weight-bold text-main lh-48p'>{data}</h2>
    <h5 className='font-size-11 text-gray-500 mb-0 mt-auto font-weight-bold uppercase'>{unit}</h5>
  </Card>
);

export default StatisticsDataCard;
