import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import Scrollbars from 'react-custom-scrollbars';

import palmHrAILogo from 'assets/img/palm-ai-icon-dark.svg';
import palmHrAIText from 'assets/img/palm-ai-text-dark.svg';
import { AuthContext } from 'auth/AuthContext';
import IconButton from 'components/buttons/IconButton/IconButton';
import Modal from 'components/Modal';
import PalmHrAIMessages from 'modules/Dashboard/components/PalmHrAIMessages/PalmHrAIMessages';
import PalmHrAIWelcomeScreen from 'modules/Dashboard/components/PalmHrAIWelcomeScreen/PalmHrAIWelcomeScreen';
import { AI, PALM_HR_AI_LOADING_ID } from 'modules/Dashboard/Constants';
import { useSendMessageToAI } from 'modules/Dashboard/hooks/useDashboard';

import styles from './PalmHrAIModal.module.scss';
import PalmHrAITextArea from '../PalmHrAITextArea/PalmHrAITextArea';

const PalmHrAIModal = ({ onRequestClose, isOpen }) => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [messages, setMessages] = useState([]);
  const [promptId, setPromptId] = useState('');
  const [showRegenerate, setShowRegenerate] = useState(false);
  const { mutate, isLoading } = useSendMessageToAI();

  const sendMessage = (message) => {
    setShowRegenerate(false);
    if (promptId) {
      message.parentPromptId = promptId;
    }

    mutate(message, {
      onSuccess: (response) => {
        setMessages((prevState) => [...prevState, { id: Date.now(), sender: AI, message: response.data.message }]);
        setPromptId(response.id);
      },
      onError: () => setShowRegenerate(true),
    });
  };

  const onSubmit = ({ message }) => {
    setMessages((prevState) => [...prevState, { id: Date.now(), sender: user, message }]);
    sendMessage({ message });
  };

  const onRegenerate = () => {
    const humanMessages = messages.filter((singleMessage) => singleMessage.sender !== AI);
    const { message } = humanMessages[humanMessages.length - 1];
    sendMessage({ message });
  };

  useEffect(() => {
    if (isLoading) {
      document
        .querySelector(`[data-id=${PALM_HR_AI_LOADING_ID}]`)
        .scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [isLoading]);

  return (
    <Modal
      showHeader={false}
      isOpen={isOpen}
      styles={{ width: '720px', padding: '0', height: '720px' }}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
    >
      <div className='d-flex flex-column h-100'>
        <div className='d-flex justify-content-between align-items-center border-btm mx-5 py-2a'>
          <div className='d-flex'>
            <img className={styles.palmHrAILogo} src={palmHrAILogo} alt='palm hr ai logo' />
            <img className={cx(styles.palmHrAIText, 'ms-2')} src={palmHrAIText} alt='palm hr ai text' />
          </div>
          <IconButton size='lg' icon='icon-cross-x' onClick={onRequestClose} />
        </div>

        <Scrollbars className='scrollbarsWrapper' style={{ flex: 1 }}>
          <div className='mt-4 mb-2'>
            {messages.length ? (
              <PalmHrAIMessages
                showRegenerate={() => setShowRegenerate(true)}
                isLoading={isLoading}
                messages={messages}
              />
            ) : (
              <PalmHrAIWelcomeScreen />
            )}
          </div>
        </Scrollbars>

        <PalmHrAITextArea
          isSendDisabled={isLoading || (!showRegenerate && Boolean(messages.length))}
          showRegenerate={Boolean(messages.length) && !isLoading && showRegenerate}
          onSubmit={onSubmit}
          onRegenerate={onRegenerate}
        />
      </div>
    </Modal>
  );
};

export default PalmHrAIModal;
