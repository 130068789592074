import http from './Api';

class SystemNotificationsApi {
  static getAllNotifications(employeeId, filters = '') {
    return http.get(`/employee/${employeeId}/notifications-inbox${filters}`);
  }

  static setSeenedNotifications(employeeId, data) {
    return http.put(`/employee/${employeeId}/notifications-inbox`, data);
  }

  static seenAllNotifications(employeeId) {
    return http.put(`/employee/${employeeId}/notifications-inbox/seen`, {});
  }
}

export default SystemNotificationsApi;
