import React, { lazy, Suspense } from 'react';

import { Navigate } from 'react-router-dom';

import AreaLoader from 'components/Spinner/AreaLoader';
import InitLoading from 'components/Spinner/InitLoading';
import Assets from 'modules/CompanyAssets/Assets';
import CompanySettings from 'modules/CompanySettings/CompanySettings';
import Dashboard from 'modules/Dashboard/Dashboard';
import Organization from 'modules/Employee/Organization/Organization';
import Files from 'modules/Files/Files';
import Pay from 'modules/Pay/Pay';
import Performance from 'modules/Performance/Performance';
import Requests from 'modules/Requests/Requests';
import Tasks from 'modules/Tasks/Tasks';
import Time from 'modules/Time/Time';
import useSharedRoutes from 'topNavigation/Router/hooks/useSharedRoutes';
import PrivateRoute from 'topNavigation/Router/PrivateRoute';
import PublicRoute from 'topNavigation/Router/PublicRoute';
import { PrivateRoutes } from 'topNavigation/Router/routes';
import useRecruitmentRoutes from 'topNavigation/Router/useRecruitmentRoutes';

const Offboarding = lazy(() => import('modules/Employee/Offboarding/Offboarding'));
const Onboarding = lazy(() => import('modules/Employee/Onboarding/Onboarding'));
const Profile = lazy(() => import('modules/Employee/Profile/Profile'));
const Page404 = lazy(() => import('errors/404/Page404'));

const usePrivateRoutes = () => {
  const sharedRoutes = useSharedRoutes();

  return [
    ...useRecruitmentRoutes(),
    {
      path: PrivateRoutes.dashboard,
      element: <PrivateRoute path='/dashboard' component={Dashboard} />,
    },
    {
      path: PrivateRoutes.companySettings,
      element: <PrivateRoute path='/company-settings' component={CompanySettings} />,
    },
    {
      path: PrivateRoutes.organization,
      element: <PrivateRoute path='/organization' component={Organization} />,
    },
    {
      path: PrivateRoutes.onboarding,
      element: (
        <Suspense fallback={<AreaLoader />}>
          <PrivateRoute path='/onboarding' component={Onboarding} />
        </Suspense>
      ),
    },
    {
      path: PrivateRoutes.offboardingId,
      element: (
        <Suspense fallback={<AreaLoader />}>
          <PrivateRoute path='/offboarding/:id' component={Offboarding} />
        </Suspense>
      ),
    },
    {
      path: PrivateRoutes.profileId,
      element: (
        <Suspense fallback={<AreaLoader />}>
          <PrivateRoute path='/profile/:id' component={Profile} />
        </Suspense>
      ),
    },
    {
      path: PrivateRoutes.time,
      element: <PrivateRoute path='/time' component={Time} />,
    },
    {
      path: PrivateRoutes.pay,
      element: <PrivateRoute path='/pay' component={Pay} />,
    },
    {
      path: PrivateRoutes.requests,
      element: <PrivateRoute path='/requests' component={Requests} />,
    },
    {
      path: PrivateRoutes.tasks,
      element: <PrivateRoute path='/tasks' component={Tasks} />,
    },
    {
      path: PrivateRoutes.files,
      element: <PrivateRoute path='/files' component={Files} />,
    },
    {
      path: PrivateRoutes.performance,
      element: <PrivateRoute path='/performance' component={Performance} />,
    },
    {
      path: PrivateRoutes.companyAssets,
      element: <PrivateRoute path='/company-assets' component={Assets} />,
    },
    {
      path: '/',
      element: <Navigate to='/dashboard' replace />,
    },
    {
      path: '/login',
      element: <Navigate to='/dashboard' replace />,
    },
    ...sharedRoutes,
    {
      path: '*',
      element: (
        <Suspense fallback={<InitLoading />}>
          <PublicRoute component={Page404} />
        </Suspense>
      ),
    },
  ];
};

export default usePrivateRoutes;
