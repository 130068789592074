import React from 'react';
import styles from './style.module.scss';
import cx from "classnames";
import {propTypes, defaultProps} from "./utility";

const Icon = ({ id, icon, size, color, className, style }) =>
{
    const classes = cx(
        className,
        icon,
        cx(styles.sizeFont, {[styles[size]]: true}),
        cx(styles.color, {[styles[color]]: true}),
    );

    return <i
        id={id}
        className={classes}
        style={style}
    />
};

Icon.propTypes = propTypes();
Icon.defaultProps = defaultProps();

export default Icon;