import http from './Api';

class TimeOffApi {
  static getVacationBalance(id, from, to, type) {
    return http.get(`/time-off/${type}/employee/${id}/vacation-balance?from=${from}&to=${to}`);
  }

  static getHistory(type, id, filters = '') {
    return http.get(`/time-off/${type}/employee/${id}/history${filters}`);
  }

  static getConsumed(id, type, year = '') {
    return http.get(`/time-off/${type}/employee/${id}/consumed${year}`);
  }

  static getTotal(id, type, year = '') {
    return http.get(`/time-off/${type}/employee/${id}/total${year}`);
  }

  static getAverage(id, type, year = '') {
    return http.get(`/time-off/${type}/employee/${id}/average${year}`);
  }

  static getChartData(type, id, year) {
    return http.get(`/time-off/${type}/employee/${id}/chart?year=${year}`);
  }

  /** *** Policies ***** */

  static getVacationPolicies(contractId) {
    return http.get(`/profile/contract/${contractId}/vacation-policies`);
  }

  static savePolicy(data) {
    return http.post('/profile/contract/vacation-policies', data);
  }

  static editPolicy(id, data) {
    return http.put(`/profile/contract/vacation-policies/${id}`, data);
  }

  static deletePolicy(id) {
    return http.delete(`/profile/contract/vacation-policies/${id}`);
  }

  static getPolicyOptions(filters = '') {
    return http.get(`/settings/time/policies${filters}`);
  }

  static getPolicyByDate(id, date = '') {
    return http.get(`/employee/${id}/vacation-policy${date}`);
  }

  /** *** Unpaid Leave Policies ***** */

  static getActiveUnpaidLeavePolicy(id, date = '') {
    return http.get(`/unpaid-leave-policy/employee/${id}/policies/active${date}`);
  }

  static getActiveSpecialLeavePolicy(id, date = '') {
    return http.get(`/special-leave-policy/employee/${id}/policies/active${date}`);
  }

  static getActiveSickLeavePolicy(id, date) {
    return http.get(`/employees/${id}/date/${date}/sick-leave/policy`);
  }

  static getActiveUnpaidLeavePolicyNoPeriod(id, from, to) {
    return http.get(`/unpaid-leave-policy/employee/${id}/policies/from/${from}/to/${to}`);
  }

  static getUnpaidLeavePolicies(id, filters = '') {
    return http.get(`/unpaid-leave-policy/employee/${id}/policies${filters}`);
  }

  static saveUnpaidLeavePolicy(id, data) {
    return http.post(`/unpaid-leave-policy/employee/${id}/policies`, data);
  }

  static getUnpaidLeavePolicyData(id, filters = '') {
    return http.get(`unpaid-leave-policy/employee/policies/${id}${filters}`);
  }

  static editUnpaidLeavePolicy(id, data) {
    return http.put(`unpaid-leave-policy/employee/policies/${id}`, data);
  }

  static removeUnpaidLeavePolicy(id) {
    return http.delete(`unpaid-leave-policy/employee/policies/${id}`);
  }

  /** *** Special Leave Policies ***** */

  static getSpecialLeavePolicies(id, filters = '') {
    return http.get(`special-leave-policy/employee/${id}/policies${filters}`);
  }

  static editSpecialLeavePolicy(id, data) {
    return http.put(`special-leave-policy/employee/policies/${id}`, data);
  }

  static deleteSpecialLeavePolicy(id) {
    return http.delete(`special-leave-policy/employee/policies/${id}`);
  }

  static getAllSpecialLeavePolicy(filters) {
    return http.get(`settings/special-leave-policies${filters}`);
  }

  static assignSpecialLeavePolicy(id, data) {
    return http.post(`special-leave-policy/employee/${id}/policies`, data);
  }
  /** *** Additional Days ***** */

  static addAdjustment(data) {
    return http.post('/time/employee/additional-days', data);
  }

  static deleteAdjustment(id) {
    return http.delete(`/time/employee/additional-days/${id}`);
  }

  static getAdjustmentData(id, filters = '') {
    return http.get(`/time/employee/${id}/additional-days${filters}`);
  }

  static getAdjustmentDetails(id) {
    return http.get(`/time/employee/additional-days/${id}`);
  }

  static downloadRequestPDF(id, action) {
    const timestamp = new Date().getTime();
    return http.get(`/pdf/requests/${id}/${action}?_=${timestamp}`, {
      responseType: 'arraybuffer',
    });
  }

  static getActiveLeaveTypesForEmployee(employee = '') {
    return http.get(`/time-off/employee/${employee}/active-leave-types`);
  }
}
export default TimeOffApi;
