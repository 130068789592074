export const AI = 'AI';
export const PALM_HR_AI_LOADING_ID = 'palm-hr-ai-loading';

export const getStartedGuidePages = {
  dashboard: '/dashboard',
  team: '/organization/people',
  time: '/time/leave/vacation',
  pay: '/pay/compensation',
};

export const quickStartGuidePages = {
  addTeam: '/organization/onboarding',
  addPolicies: '/company-settings/time/policies/vacation',
  workSchedule: '/company-settings/time/work-schedules',
  activeRequest: '/company-settings/requests/hr/accommodation',
  setupPayroll: '/pay/payroll/list',
};
