import http from './Api';

class CompanySettingsDocumentsApi {
  static getFileSettings() {
    return http.get(`settings/file-settings`);
  }

  static editFileSettings(data) {
    return http.put(`settings/file-settings`, data);
  }
}

export default CompanySettingsDocumentsApi;
