import { map, sortBy } from 'lodash';
import moment from 'moment';

import { tableUtil } from 'components/PHRTable';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

import { renderShiftType, renderLocationType, renderActions } from './tableRowesConfig';

const COLUMN_NAME = 'employee_name';
const COLUMN_SHIFT_TYPE = 'shift_type';
const COLUMN_SHIFT_LOCATION = 'shift_location';
const COLUMN_ACTIONS = 'actions';

const columnsConfig = () => [
  {
    name: COLUMN_NAME,
    visible: true,
    columnOrder: 1,
    thConfig: { title: t('GENERAL.EMPLOYEE') },
  },
  {
    name: COLUMN_SHIFT_TYPE,
    visible: true,
    columnOrder: 2,
    thConfig: { title: t('SHIFTS.SHIFT_TYPE') },
  },
  {
    name: COLUMN_SHIFT_LOCATION,
    visible: true,
    columnOrder: 3,
    thConfig: { title: t('SHIFTS.SHIFT_LOCATION') },
  },
  {
    name: COLUMN_ACTIONS,
    visible: true,
    columnOrder: 4,
    thConfig: { title: '\u00A0' },
  },
];

export const tableData = (items, authState, navigate) => {
  const rows = [];
  items.forEach((item) => {
    item.days?.forEach((day) => {
      day.shifts?.forEach((shift, index) => {
        const locationName = shift.location
          ? readName(authState.user.language, shift.location.name, shift.location.nameArabic)
          : t('GENERAL.SHIFT_NO_LOCATION');

        rows.push({
          [COLUMN_NAME]: tableUtil.employeeDOM(item.employee ?? {}, authState, true, 24),
          [COLUMN_SHIFT_TYPE]: renderShiftType(
            readName(authState.user.language, shift.name || '', shift.nameArabic || '') || t('GENERAL.CUSTOM_SHIFT'),
            shift.startTime,
            shift.endTime,
            index
          ),
          [COLUMN_SHIFT_LOCATION]:
            shift.isRemote || day.isRemoteWork
              ? renderLocationType('remote', locationName)
              : renderLocationType('on-site', locationName),
          [COLUMN_ACTIONS]: renderActions(navigate),
        });
      });
    });
  });

  return tableUtil.tableData(columnsConfig(), rows, columnsConfig());
};

export const buildQueryPayload = (term, departmentIds = [], locationIds = [], teamIds = []) => ({
  perPage: 999,
  ...(term && { name: term }),
  ...(departmentIds.length > 0 && { departments: departmentIds.join(',') }),
  ...(locationIds.length > 0 && { locations: locationIds.join(',') }),
  ...(teamIds.length > 0 && { teams: teamIds.join(',') }),
});

const formatDate = (date, language) => moment(date).locale(language).format('MMM DD, YYYY, hh:mm A');

export const processShifts = (shiftsItems, userLanguage) =>
  shiftsItems.reduce((acc, shiftData) => {
    shiftData.days.forEach((day) => {
      day.shifts.forEach((shift) => {
        const shiftStartDate = formatDate(`${day.date.split('T')[0]}T${shift.startTime}`, userLanguage);
        const shiftEndDate = formatDate(`${day.date.split('T')[0]}T${shift.endTime}`, userLanguage);

        const shiftName = readName(userLanguage, shift.name, shift.nameArabic) || t('GENERAL.CUSTOM_SHIFT');
        const shiftInfo = shift.name
          ? t('SHIFTS.SHIFT_WIDGET_INFO', {
              startDate: shiftStartDate,
              endDate: shiftEndDate,
            })
          : '';

        if (!acc[shiftName]) {
          acc[shiftName] = { info: shiftInfo, employees: [] };
        }

        acc[shiftName].employees.push(shiftData.employee);
      });
    });
    return acc;
  }, {});

export const prepareShiftItems = (groupedShifts) =>
  sortBy(
    map(groupedShifts, (value, key) => ({
      title: key,
      info: value.info,
      employees: Array.from(new Set(value.employees.map((emp) => emp.id))).map((id) =>
        value.employees.find((emp) => emp.id === id)
      ),
    })),
    [(item) => item.title !== t('GENERAL.CUSTOM_SHIFT')]
  );
