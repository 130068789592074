import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './CircleBadge.module.scss';

const CircleBadge = ({ icon, size, iconSize, className }) => (
  <div data-testid='circle-badge' className={cx(className, styles.userIcon)} style={{ width: size, height: size }}>
    <i data-testid='circle-badge-icon' className={icon} style={{ fontSize: iconSize }} />
  </div>
);

CircleBadge.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
  iconSize: PropTypes.number,
};

CircleBadge.defaultProps = {
  size: 36,
  iconSize: 20,
  className: 'me-4',
};

export default CircleBadge;
