import React from 'react';

import icon from 'assets/img/logo-title.svg';
import t from 'utility/setTranslation';

const GoLiveSubdomainAndEnvironmentTips = () => (
  <>
    <img src={icon} alt={'nn'} style={{ width: '240px' }} />
    <p style={{ width: '280px', fontSize: '13px' }}>
      <b>{t('GENERAL.TIPS')}:</b> {t('NAVBAR.ENVIRONMENT_TIPS')}
    </p>
  </>
);
export default GoLiveSubdomainAndEnvironmentTips;
