/* eslint-disable */

import { sizeConstants } from 'constants/size';

const sizes = () => {
  return Object.values(sizeConstants);
};

const xsSize = () => {
  return sizeConstants.XS;
};

const smSize = () => {
  return sizeConstants.SM;
};

const mdSize = () => {
  return sizeConstants.MD;
};

const lgSize = () => {
  return sizeConstants.LG;
};

const xlSize = () => {
  return sizeConstants.XL;
};

const xxlSize = () => {
  return sizeConstants.XXL;
};

export default {
  sizes,
  xsSize,
  smSize,
  mdSize,
  lgSize,
  xlSize,
  xxlSize,
};
