import React, { useContext } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { AuthContext } from 'auth/AuthContext';
import Personas from 'components/Personas';
import readName from 'utility/readName';

import styles from './CardMoreDetails.module.scss';

const CardMoreDetails = ({ item }) => {
  const { authState } = useContext(AuthContext);

  return (
    <li className='p-2 mb-3 w-100'>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-start'>
          <Personas user={item} size={20} />
          <div className='ms-2'>
            <p className={cx(styles.name, 'fw-bold', 'text-steal')}>
              {readName(authState.user.language, item?.fullName, item?.fullNameArabic)}
            </p>
          </div>
        </div>
      </div>
    </li>
  );
};

CardMoreDetails.propTypes = {
  item: PropTypes.object.isRequired,
};

export default CardMoreDetails;
