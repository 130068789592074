import AuthState from 'services/Auth/AuthState';

const NAV_ITEM_ORGANIZATION = 'organization';
const NAV_ITEM_TEAM = 'team';
const NAV_ITEM_TIME = 'time';
const NAV_ITEM_PAY = 'pay';
const NAV_ITEM_DOCUMENT = 'documents';
const NAV_ITEM_PERMISSION = 'permissions';
const NAV_ITEM_INTEGRATION = 'integrations';
const NAV_ITEM_RECRUITMENT = 'recruitment';
const NAV_ITEM_BILLING = 'billing';
const NAV_ITEM_REQUEST = 'requests';
const NAV_ITEM_TASK = 'tasks';
const NAV_ITEM_ASSET = 'assets';
const NAV_ITEM_SECURITY = 'security';

export const navigationItems = [
  { title: NAV_ITEM_ORGANIZATION, href: `/company-settings/organization`, type: 'link' },
  { title: NAV_ITEM_TEAM, href: `/company-settings/team`, type: 'link' },
  { title: NAV_ITEM_TIME, href: `/company-settings/time`, type: 'link' },
  { title: NAV_ITEM_PAY, href: `/company-settings/pay`, type: 'link' },
  { title: NAV_ITEM_REQUEST, href: `/company-settings/requests`, type: 'link' },
  { title: NAV_ITEM_TASK, href: `/company-settings/tasks`, type: 'link' },
  { title: NAV_ITEM_DOCUMENT, href: `/company-settings/documents`, type: 'link' },
  { title: NAV_ITEM_ASSET, href: `/company-settings/assets`, type: 'link' },
  { title: NAV_ITEM_PERMISSION, href: `/company-settings/permissions`, type: 'link' },
  { title: NAV_ITEM_INTEGRATION, href: `/company-settings/integrations`, type: 'link' },
  { title: NAV_ITEM_SECURITY, href: `/company-settings/security`, type: 'link' },
  { title: NAV_ITEM_RECRUITMENT, href: `/company-settings/recruitment`, type: 'link' },
  { title: NAV_ITEM_BILLING, href: `/company-settings/billing`, type: 'link' },
];

export const userNavigationItems = (authState, isRecruitmentModuleEnabled, isBillingModuleEnabled) => {
  const { isAdmin, isSecurityAdmin, isAdminOrHr, isEnterprise } = AuthState.create(authState);

  const navItems = [];

  navigationItems.forEach((navItem) => {
    const { title } = navItem;

    if (title === NAV_ITEM_ORGANIZATION && isAdmin) {
      navItems.push(navItem);
      return;
    }

    if (title === NAV_ITEM_RECRUITMENT && isAdminOrHr && isRecruitmentModuleEnabled) {
      navItems.push(navItem);
      return;
    }

    if (
      title === NAV_ITEM_BILLING &&
      isAdmin &&
      !authState?.companyData?.partner &&
      !isEnterprise &&
      isBillingModuleEnabled
    ) {
      navItems.push(navItem);
      return;
    }

    if (title === NAV_ITEM_SECURITY && (isAdmin || isSecurityAdmin)) {
      navItems.push(navItem);
      return;
    }

    if ([NAV_ITEM_REQUEST, NAV_ITEM_TASK, NAV_ITEM_ASSET].includes(title) && isAdmin) {
      navItems.push(navItem);
      return;
    }

    if (
      [
        NAV_ITEM_TEAM,
        NAV_ITEM_TIME,
        NAV_ITEM_PAY,
        NAV_ITEM_DOCUMENT,
        NAV_ITEM_PERMISSION,
        NAV_ITEM_INTEGRATION,
      ].includes(title) &&
      isAdminOrHr
    ) {
      navItems.push(navItem);
    }
  });

  return navItems;
};
