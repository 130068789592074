import React from 'react';

import cx from 'classnames';

import styles from './styles.module.scss';

const LinearProgress = ({ id, color, value, max }) => (
  <progress id={id} value={value} max={max} className={cx(styles.container, styles[color])} />
);

LinearProgress.defaultProps = {
  id: 'linear-progress',
  color: '',
  value: null,
  max: null,
};

export default LinearProgress;
