import React from 'react';

import Pagination from './Pagination';

const PaginationWrapper = React.forwardRef((props, ref) => {
  const { children, loading, showPagination, pagination, onPaginationAction, paginationDivCss, zeroIndexing } = props;

  return (
    <>
      {children}
      {!!showPagination && (
        <div className={paginationDivCss} ref={ref}>
          <Pagination
            pagination={pagination}
            zeroIndexing={zeroIndexing}
            onPaginationAction={onPaginationAction}
            loading={loading}
          />
        </div>
      )}
    </>
  );
});

export default PaginationWrapper;
