/* eslint-disable */

import PropTypes from "prop-types";
import sizeUtility from "utility/style/sizeUtility";
import colorUtility from "utility/style/colorUtility";
import propTypesUtility from 'utility/propTypesUtility';

export const defaultProps = () =>
{
    return {
        id: null,
        size: sizeUtility.mdSize(),
        color: colorUtility.secondaryColor(),
        style: {}
    };
}

export const propTypes = () =>
{
    return {
        id: propTypesUtility.idPropType(),
        icon: PropTypes.string.isRequired,
        size: propTypesUtility.sizePropType(),
        color: propTypesUtility.colorPropType(),
        className: PropTypes.string,
        style: propTypesUtility.stylePropType()
    };
}