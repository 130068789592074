// get
export const getStorage = (local = true) => (local ? localStorage : sessionStorage);

export const getItem = (key, storage = getStorage()) => storage.getItem(key);

export const getLocalItem = (key) => getItem(key, localStorage);

export const getSessionItem = (key) => getItem(key, sessionStorage);

// has
export const hasItem = (key, storage = getStorage()) => !!getItem(key, storage);

export const hasLocalItem = (key) => hasItem(key, localStorage);

export const hasSessionItem = (key) => hasItem(key, sessionStorage);

// set
export const setItem = (key, value, storage = getStorage()) => {
  storage.setItem(key, value);
  return value;
};

export const setLocalItem = (key, value) => setItem(key, value, localStorage);

export const setSessionItem = (key, value) => setItem(key, value, sessionStorage);

// remove
export const removeItem = (key, storage = getStorage()) => {
  const value = getItem(key, storage);
  storage.removeItem(key);
  return value;
};

export const removeLocalItem = (key) => removeItem(key, localStorage);

export const removeSessionItem = (key) => removeItem(key, sessionStorage);

// clear
export const clearItems = (storage = getStorage()) => {
  storage.clear();
};

export const clearLocalItems = () => {
  clearItems(localStorage);
};

export const clearSessionItems = () => {
  clearItems(sessionStorage);
};
