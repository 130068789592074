import React from 'react';

import cx from 'classnames';

import Tooltip from 'components/tooltip/Tooltip';
import { useVisibility } from 'shared/useVisibility';
import t from 'utility/setTranslation';

import WhoIsInModal from './WhoIsInModal';
import styles from '../../Card.module.scss';

const WhoIsInTrigger = () => {
  const { open, close, isOpen } = useVisibility();

  return (
    <>
      <div className='ms-sm-2 ms-lg-3' onClick={() => open()}>
        <Tooltip label={t('GENERAL.WHO_IS_IN')} position='top'>
          <i className={cx(styles.quicIcon, styles.time, 'icon-alarm-checked')} />
        </Tooltip>
      </div>
      <WhoIsInModal isOpen={isOpen} onClose={() => close()} />
    </>
  );
};

export default WhoIsInTrigger;
