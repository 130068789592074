const flagMap = {
  'EMPLOYEE.FLAG.BIRTHDAY': 'icon-gift text-pink',
  'EMPLOYEE.FLAG.ANNIVERSARY': 'icon-star text-warning',
  'EMPLOYEE.FLAG.CONTRACT_EXPIRING': 'icon-contract text-orange',
  'EMPLOYEE.FLAG.CONTRACT_EXPIRED': 'icon-contract text-danger',
  'EMPLOYEE.FLAG.ON_PROBATION': 'icon-grace-in text-gray',
  'EMPLOYEE.FLAG.SCHEDULED_TO_JOIN': 'icon-contract text-yellow',
};

export default flagMap;
