import React from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import styles from './CardAdvancedItem.module.scss';
import CompanyItem from './CompanyItem';
import EmployeeItem from './EmployeeItem';

const CardAdvancedItem = ({ employee, type, date, company, event, onAction, hasPermission, selectedDate }) => {
  const iconClass = () => {
    let icon;

    switch (type) {
      case 'customEvent':
        icon = company?.icon;
        break;
      case 'birthday':
        icon = 'icon-gift';
        break;
      case 'anniversary':
        icon = 'icon-custom-10';
        break;
      default:
        icon = '';
    }

    return icon;
  };

  const countYears = () => {
    let year = '';
    const now = moment(new Date());
    const end = moment(date).format('YYYY-MM-DD');
    const duration = moment.duration(now.diff(end));
    const yearsCount = Math.round(duration.asYears());
    if (yearsCount === 1) {
      year = `${yearsCount}st`;
    } else if (yearsCount === 2) {
      year = `${yearsCount}nd`;
    } else if (yearsCount === 3) {
      year = `${yearsCount}rd`;
    } else if (yearsCount === 0) {
      year = '';
    } else {
      year = `${yearsCount}th`;
    }
    return year;
  };

  return (
    <div className={cx(styles.wraper, 'w-100 mb-2')}>
      {type !== 'customEvent' ? (
        <EmployeeItem data={employee} icon={iconClass()} year={countYears()} type={type} selectedDate={selectedDate} />
      ) : (
        <CompanyItem
          data={company}
          icon={iconClass()}
          event={event}
          onAction={onAction}
          hasPermission={hasPermission}
        />
      )}
    </div>
  );
};

CardAdvancedItem.propTypes = {
  employee: PropTypes.object,
  company: PropTypes.object,
  type: PropTypes.string,
  event: PropTypes.bool,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),

  hasPermission: PropTypes.bool,
};

CardAdvancedItem.defaultProps = {
  employee: null,
  company: null,
  event: false,
  type: '',
  date: '',
  hasPermission: false,
};

export default CardAdvancedItem;
