import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import Scrollbars from 'react-custom-scrollbars';

import DashboardApi from 'api/DashboardApi';
import khadamatiImage from 'assets/img/enterprise/khadamati/who_s_in.png';
import palmhrImage from 'assets/img/who_s_in.png';
import { AuthContext } from 'auth/AuthContext';
import IconButton from 'components/buttons/IconButton/IconButton';
import CircleBadge from 'components/CircleBadge';
import Search from 'components/forms/components/Search';
import NavTab from 'components/Navigation/NavTab';
import ListSkeleton from 'modules/CompanySettings/Organization/Companies/Locations/ListSkeleton';
import useDebounce from 'shared/useDebounce';
import { ThemeContext } from 'themes/ThemeContext';
import t from 'utility/setTranslation';

import AttendanceDetails from './AttendanceDetails';
import styles from './WhoIsIn.module.scss';
import WhoIsInList from './WhoIsInList';

const WhoIsIn = ({ onClose, workType }) => {
  const { authState } = useContext(AuthContext);
  const [activeView, setActiveView] = useState('checked in');
  const [employeesCount, setEmployeesCount] = useState({});
  const [employees, setEmployees] = useState({ items: [], pagination: {} });
  const [details, setDetails] = useState(false);
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 400);
  const [disabeDirBtn, setDisabeDirBtn] = useState({
    next: false,
    prev: false,
  });
  const hasPermission = authState.user.roles.includes('ROLE_ADMIN') || authState.user.roles.includes('ROLE_HR');

  const { themeState } = useContext(ThemeContext);
  const enterpriseName = themeState?.companyName || 'palmhr';

  const imageMap = {
    palmhr: palmhrImage,
    khadamati: khadamatiImage,
  };

  const getEmployeesCount = () => {
    setLoading(true);
    const params = `?remoteWork=${workType}`;
    DashboardApi.getWhoIsIn(params)
      .then((res) => {
        setLoading(false);
        setEmployeesCount(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getAllEmployees = (isScroll = false) => {
    if (!isScroll) {
      setLoading(true);
    }
    let params = `?perPage=10&remoteWork=${workType}`;

    const filters = [];
    if (searchValue) {
      filters.push({
        field: authState.user.language === 'ar' ? 'e.fullNameArabic' : 'e.fullName',
        type: 'like',
        value: searchValue,
      });
    }

    if (filters.length > 0) {
      params += `&filters=${encodeURIComponent(JSON.stringify(filters))}`;
    }

    const state = { ...employees };
    if (isScroll) {
      const nextPage = state.pagination.currentPage + 1;
      if (nextPage <= state.pagination.pages) {
        params += `&currentPage=${nextPage}`;
      } else {
        return;
      }
    }
    let type = '';
    if (activeView === 'checked in') {
      type = 'present';
    } else {
      type = 'absent';
    }

    DashboardApi.getListEmployees(type, params)
      .then((res) => {
        if (isScroll) {
          state.items = _.concat(state.items, res.data.items);
          state.pagination = res.data.pagination;
          setEmployees(state);
        } else {
          setEmployees(res.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const viewDetails = (id) => {
    setSelected(id);
    setDetails(true);
  };

  const handleScroll = (event) => {
    if (event.top >= 0.999 && !loading) {
      getAllEmployees(true);
    }
  };

  const onGoBack = () => {
    setDetails(false);
    setSelected();
  };

  const onChangeDetails = (direction) => {
    if (selected) {
      const empIndex = _.findIndex(employees.items, ['id', selected]);
      const nexIndex = direction === 'next' ? empIndex + 1 : empIndex - 1;
      if (employees.items[nexIndex]?.id) {
        setSelected(employees.items[nexIndex].id);
      }
      if (!employees.items[nexIndex]?.id && direction === 'next') {
        setSelected();
      }
    }
  };

  useEffect(() => {
    if (selected) {
      const psIndex = _.findIndex(employees.items, ['id', selected]);
      const btnState = { ...disabeDirBtn };
      btnState.prev = psIndex <= 0;
      btnState.next = psIndex >= employees.items.length - 1;
      setDisabeDirBtn(btnState);
    }
  }, [selected]);

  useEffect(() => {
    getEmployeesCount();
  }, [details]);

  useEffect(() => {
    getAllEmployees(false);
  }, [activeView, details, debouncedSearchTerm]);

  return (
    <div className='position-relative'>
      <div className={cx(styles.content)}>
        {details ? (
          <>
            {hasPermission && activeView === 'checked in' && (
              <>
                <div className={styles.prevBtn}>
                  <IconButton
                    onClick={() => {
                      onChangeDetails('prev');
                    }}
                    icon='icon-chevron-left'
                    size='xs'
                    color='gray'
                    isDisabled={disabeDirBtn.prev}
                  />
                </div>
                <div className={styles.nextBtn}>
                  <IconButton
                    onClick={() => {
                      onChangeDetails('next');
                    }}
                    icon='icon-chevron-right'
                    size='xs'
                    color='gray'
                    isDisabled={disabeDirBtn.next}
                  />
                </div>
              </>
            )}
            <AttendanceDetails onGoBack={onGoBack} onClose={onClose} selected={selected} />
          </>
        ) : (
          <>
            <div className={cx(styles.titleHeader, 'mx-5 py-2a')}>
              <div className='d-flex align-items-center justify-content-between'>
                <h5>{t('GENERAL.ATTENDANCE_TODAY')}</h5>
                <IconButton icon='icon-cross-x' onClick={onClose} size='md' color='gray' />
              </div>
            </div>

            <div
              className={cx(styles.header, 'position-relative')}
              style={{ backgroundImage: `url(${imageMap[enterpriseName]})` }}
            >
              <div className={styles.badge}>
                <CircleBadge
                  icon={workType ? 'icon-request-remote' : 'icon-alarm-checked'}
                  size={64}
                  className={cx(styles.icon, 'bg-white')}
                  iconSize={32}
                />
              </div>
            </div>

            <div className='d-flex align-items-center justify-content-center mt-8 mb-4'>
              <NavTab
                text='checked in'
                onClick={setActiveView}
                active={activeView === 'checked in'}
                number={employeesCount?.present || 0}
                showNumber
              />
              <NavTab
                text='needs to check in'
                onClick={setActiveView}
                active={activeView === 'needs to check in'}
                number={employeesCount?.absent || 0}
                showNumber
              />
            </div>

            <div className='mt-4 mb-3 mx-5'>
              <Search
                className={styles.search}
                onChange={(e) => {
                  setSearchValue(e.target.value.replace(/^\s+/g, ''));
                }}
                placeholder='Search'
                value={searchValue}
                onClear={() => {
                  setSearchValue('');
                }}
              />
            </div>

            <div className='mx-5'>
              <div className={cx(styles.title, 'd-flex aligm-items-center justify-content-between mb-2 py-2a px-3')}>
                <div>
                  <p>{t('GENERAL.EMPLOYEE')}</p>
                </div>
                <div>
                  <p>{t('GENERAL.DETAILS')}</p>
                </div>
              </div>
            </div>

            <Scrollbars
              className='scrollbarsWrapper'
              onScrollFrame={(e) => handleScroll(e)}
              autoHeight
              autoHeightMin={230}
            >
              <div className='mx-5'>
                {loading ? (
                  <ListSkeleton numOfItems={3} gradient={styles.gradient} />
                ) : (
                  <WhoIsInList employees={employees.items} onPreview={viewDetails} />
                )}
              </div>
            </Scrollbars>

            <div className='border-t px-5 py-8' />
          </>
        )}
      </div>
    </div>
  );
};

export default WhoIsIn;
