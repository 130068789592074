/* global google */
import React, { useEffect, useState } from 'react';

const params = {
  strokeOpacity: 0,
  strokeWeight: 0,
  fillColor: '#4eb2f9',
  fillOpacity: 0.35,
};

const Circle = (options) => {
  const [circle, setCircle] = useState(null);
  useEffect(() => {
    if (!circle) {
      setCircle(new google.maps.Circle());
    }
    return () => {
      if (circle) {
        circle.setMap(null);
      }
    };
  }, [circle]);

  useEffect(() => {
    if (circle) {
      circle.setOptions({ ...options, ...params });
    }
  }, [circle, options]);

  return null;
};

export default React.memo(Circle);
