/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Line from 'modules/EngagementCenter/shared/loaders/Line';
import formatKey from 'utility/formatKey';

import FieldWrapper from './FieldWrapper';
import styles from './TextInput.module.scss';

const TextInput = ({
  name,
  placeholder,
  value,
  label,
  isRequired,
  isDisabled,
  onChange,
  onBlur,
  onKeyPress,
  autofocus,
  size,
  customClass,
  error,
  readOnly,
  prefix,
  sufix,
  direction,
  hidden,
  maxLength,
  isCustom,
  hideLabel,
  icon,
  type,
  iconPosition,
  isLoading,
  hideError,
}) => {
  let requiredAttr = false;

  if (isRequired) {
    requiredAttr = 'required';
  }

  const onBlurField = () => {
    if (onBlur) {
      onBlur(name);
    }
  };

  return (
    <FieldWrapper name={name} className={cx(styles.inputWrapper, customClass, 'position-relative w-100')}>
      {!hideLabel && (
        <div
          className={cx(styles.label, 'd-flex', direction === 'rtl' ? 'justify-content-end' : 'justify-content-start')}
        >
          {label ? (
            <label className='label' htmlFor={name}>
              {isCustom ? label : formatKey('GENERAL', label)}
              {isRequired && <sup>*</sup>}
            </label>
          ) : null}
        </div>
      )}
      {isLoading ? (
        <Line style={{ height: 34, width: '100%' }} gradient='loading-gradient' className={cx('radius-4')} />
      ) : (
        <>
          <input
            className={cx(
              `form-control form-control-${size}`,
              error ? 'is-invalid' : 'valid',
              sufix || iconPosition === 'right' ? styles.inputSufix : '',
              prefix || iconPosition === 'left' ? styles.inputPrefix : ''
            )}
            type={type}
            name={name}
            aria-label={name}
            data-testid={name}
            id={name}
            value={value || ''}
            placeholder={placeholder && formatKey('GENERAL', placeholder)}
            required={requiredAttr}
            disabled={isDisabled}
            onChange={onChange}
            onBlur={onBlurField}
            onKeyDown={onKeyPress}
            autoFocus={autofocus}
            readOnly={readOnly}
            dir={direction}
            hidden={hidden}
            autoComplete='off'
            spellCheck='false'
            maxLength={maxLength}
          />
          {sufix ? <span className={cx(styles.sufix, styles[size], 'label ps-2')}>{sufix}</span> : null}
          {prefix ? <span className={cx(styles.prefix, styles[size], 'label pe-2')}>{prefix}</span> : null}
          {icon ? <i className={cx(icon, styles.icon, styles[iconPosition], styles[size])} /> : null}
        </>
      )}
      <div className='invalid-feedback'>{error}</div>
    </FieldWrapper>
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  autofocus: PropTypes.bool,
  size: PropTypes.string,
  sufix: PropTypes.string,
  customClass: PropTypes.string,
  direction: PropTypes.string,
  error: PropTypes.string,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.string,
  onKeyPress: PropTypes.func,
  prefix: PropTypes.string,
  type: PropTypes.string,
  hidden: PropTypes.bool,
  isCustom: PropTypes.bool,
  hideLabel: PropTypes.bool,
  hideError: PropTypes.bool,
  iconPosition: PropTypes.string,
};

TextInput.defaultProps = {
  value: '',
  placeholder: '',
  label: '',
  isRequired: false,
  isDisabled: false,
  onChange: undefined,
  onBlur: undefined,
  autofocus: undefined,
  size: '',
  customClass: '',
  sufix: '',
  type: 'text',
  direction: '',
  error: undefined,
  readOnly: false,
  maxLength: '255',
  onKeyPress: undefined,
  prefix: '',
  hidden: false,
  isCustom: false,
  hideLabel: false,
  hideError: false,
  iconPosition: '',
};

export default TextInput;
