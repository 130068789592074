import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ProfileTwoFactorSettings from 'api/ProfileTwoFactorSettings';
import IconButton from 'components/buttons/IconButton/IconButton';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import SpinnerRing from 'components/Spinner/SpinnerRing';
import SuccessCheckmark from 'components/SuccessCheckmark/SuccessCheckmark';
import t from 'utility/setTranslation';

import styles from './SettingsAuth.module.scss';

const SettingsAuthGoogle = ({ close, responseStatus, sending, onVerify, setRecoveryCodes }) => {
  const [googleData, setGoogleData] = useState({});
  const [activeView, setActiveView] = useState('qr');

  useEffect(() => {
    ProfileTwoFactorSettings.initGoogle(null).then((res) => {
      setGoogleData(res.data);
      setRecoveryCodes(res?.data?.recoveryCode || []);
    });
  }, []);

  useEffect(() => {
    const focusNextInput = (e, input) => {
      if (e.which !== 8 && e.which !== 46) {
        setTimeout(() => {
          if (input.nextElementSibling) input.nextElementSibling.focus();
        }, 100);
      } else {
        setTimeout(() => {
          if (input.previousElementSibling) input.previousElementSibling.focus();
        }, 100);
      }
    };

    const inputs = document.querySelectorAll('.code-input');
    inputs.forEach((input) => {
      input.addEventListener('keydown', (event) => {
        focusNextInput(event, input);
      });
    });

    return () => {
      const inputsUnmount = document.querySelectorAll('.code-input');
      inputsUnmount.forEach((input) => {
        input.removeEventListener('keydown', (event) => {
          focusNextInput(event, input);
        });
      });
    };
  }, [activeView]);

  const onVerifyClick = () => {
    let code = '';

    const inputs = document.getElementsByClassName('code-input');
    for (let index = 0; index < inputs.length; index += 1) {
      const element = inputs[index];

      code += element.value;
    }

    onVerify({ mode: 'google_2fa', code });
  };

  return (
    <div>
      {responseStatus?.resolved && <SuccessCheckmark text={responseStatus.msg} isFailed={responseStatus.isFailed} />}
      {sending && <SpinnerRing />}

      <div className={cx(styles.header, 'border-btm')}>
        <i className='icon-google' />

        <IconButton
          className={styles.close}
          color='white'
          icon='icon-cross-x'
          onClick={() => {
            close();
          }}
          size='md'
        />
      </div>

      {activeView === 'qr' && (
        <div className={cx(styles.content)}>
          <div className='text-start mb-7'>
            <h5>{t('GENERAL.ACTIVATE_GOOGLE_AUTH')}</h5>
            <p>{t('GENERAL.SCAN_QR_CODE')}</p>
          </div>

          <div className={styles.qrDiv}>
            <img src={googleData.qrUrl} alt='QR code' />

            <p
              onClick={() => {
                setActiveView('cannot');
              }}
            >
              {t('GENERAL.CANNOT_SCAN_QR_CODE')}
            </p>
          </div>
        </div>
      )}

      {activeView === 'verify' && (
        <div className={cx(styles.content)}>
          <div className='text-start mb-7'>
            <h5>{t('GENERAL.ACTIVATE_GOOGLE_AUTH')}</h5>
            <p>{t('GENERAL.ACTIVATE_GOOGLE_AUTH_INFO')}</p>
          </div>

          <div className={styles.codeDiv}>
            <input type='text' className='code-input me-2' maxLength='1' />
            <input type='text' className='code-input me-2' maxLength='1' />
            <input type='text' className='code-input me-4' maxLength='1' />

            <input type='text' className='code-input me-2' maxLength='1' />
            <input type='text' className='code-input me-2' maxLength='1' />
            <input type='text' className='code-input me-2' maxLength='1' />
          </div>
        </div>
      )}

      {activeView === 'cannot' && (
        <div className={cx(styles.content)}>
          <div className='text-start mb-7'>
            <h5>{t('GENERAL.CANNOT_SCAN_BARCODE')}</h5>
            <p>{t('GENERAL.CANNOT_SCAN_BARCODE_INFO')}</p>
          </div>

          <div className={styles.cannotDiv}>
            <div className={styles.title}>{t('GENERAL.ACCOUNT_NAME')}</div>
            <div className={styles.text}>{googleData.account}</div>
          </div>

          <div className={styles.cannotDiv}>
            <div className={styles.title}>{t('GENERAL.SECURITY_KEY')}</div>
            <div className={styles.text}>{googleData.key}</div>
          </div>
        </div>
      )}

      <div className={cx(styles.footer, 'mt-2 mb-2 d-flex justify-content-end align-items-center px-5')}>
        <div
          className={cx(styles.buttonBack, 'me-5')}
          onClick={() => {
            if (activeView === 'verify' || activeView === 'cannot') {
              setActiveView('qr');
            } else {
              close();
            }
          }}
        >
          {activeView === 'verify' || activeView === 'cannot' ? t('GENERAL.BACK') : t('GENERAL.CANCEL')}
        </div>

        <RoundedButton
          type='button'
          text={activeView === 'verify' ? 'Verify' : 'Next'}
          btnStyle='contained'
          color='primary'
          size='md'
          onClick={() => {
            if (activeView === 'verify') {
              onVerifyClick();
            } else {
              setActiveView('verify');
            }
          }}
        />
      </div>
    </div>
  );
};

SettingsAuthGoogle.propTypes = {
  close: PropTypes.func.isRequired,
  setRecoveryCodes: PropTypes.func,
};

SettingsAuthGoogle.defaultProps = {
  setRecoveryCodes: undefined,
};

export default SettingsAuthGoogle;
