import React, { useContext } from 'react';

import { Navigate } from 'react-router-dom';

import { AuthContext } from 'auth/AuthContext';

const CustomRoute = ({ component: Component, ...rest }) => {
  const restParams = rest;
  const { authState } = useContext(AuthContext);
  const { checkPrivileges } = authState;

  const canSee = checkPrivileges(restParams.pathPattern || restParams.path);
  const shouldHidePage = rest?.hidePage === true || false;

  return canSee && !shouldHidePage ? <Component {...rest} /> : <Navigate to='/403' />;
};

export default CustomRoute;
