import React, { useState } from 'react';

import cx from 'classnames';

import emptySearchIcon from 'assets/img/empty_search_icon.png';
import EmptyState from 'components/EmptyPages/EmptyState';
import Modal from 'components/Modal';
import UserReportColumnsModal from 'components/Modal/UserReportColumns/UserReportColumnsModal';
import PaginationWrapper from 'components/Pagination/PaginationWrapper';
import RowDensityModal from 'components/RowDensity/RowDensityModal';
import FilterTabsGroup from 'components/searchFilterMenu/FilterTabsGroup';
import SearchFilterMenu from 'components/searchFilterMenu/SearchFilterMenu';
import SideTransitionContainer from 'components/searchFilterMenu/SideTransitionContainer';
import t from 'utility/setTranslation';

import helper from './helper';
import Table from './Table';
import styles from './Table.module.scss';
import TableToolbar from './TableToolbar';

const TableContainer = React.forwardRef((props, ref) => {
  const {
    id,
    size,
    title,
    searchValue,
    loading,
    description,
    searchable,
    controlColumns,
    onSearch,
    controlDensity,
    onColumnsModalOpen,
    onRowDensityModalOpen,
    stickyToolbar,
    xScroll,
    columns,
    rows,
    stickyHead,
    onSort,
    rowIdentifier,
    columnsModalOpen,
    onColumnsModalSave,
    rowDensityModalOpen,
    total,
    limit,
    pages,
    page,
    sort,
    pagination,
    controlFilters,
    filters,
    activeFilters,
    onFiltersModalOpen,
    onFiltersSelect,
    onFiltersApply,
    onFiltersClear,
    filtersModalOpen,
    hoverable,
    emptyMsg,
    reportId,
    onPageChange,
    paginationData,
    zeroIndexing,
    tableWidth,
  } = props;

  const [isXScrollable, setIsXScrollable] = useState(false);
  // const [firstPage, setFirstPage] = useState(0);

  const refs = helper.prepareRefs(ref);
  const { cRef, cfRef, tcRef } = refs.current;
  const currentTotal = rows.length;
  const { selectedFilters } = helper.formatFilters(filters);
  const filterChipsData = helper.filterChipData(filters, activeFilters);

  // some reports start with 0 or 1 as first page
  // this is tracked (on mount) in order to display appropriate no. (no zero)
  // useEffect(() => {
  //   setFirstPage(page);
  // }, []);

  return (
    <div ref={cRef} id={helper.genId(id, helper.components().container)} className={cx(styles.container, styles[size])}>
      <PaginationWrapper
        onPaginationAction={onPageChange}
        pagination={paginationData}
        showPagination={paginationData?.total > 0}
        loading={loading}
        paginationDivCss={cx('w-100', styles.footer)}
        ref={cfRef}
        zeroIndexing={zeroIndexing}
      >
        <TableToolbar
          ref={refs}
          tableId={id}
          size={size}
          title={title}
          loading={loading}
          searchValue={searchValue}
          description={description}
          searchable={searchable}
          controlColumns={controlColumns}
          controlDensity={controlDensity}
          onSearch={onSearch}
          onColumnsModalOpen={onColumnsModalOpen}
          onRowDensityModalOpen={onRowDensityModalOpen}
          sticky={stickyToolbar}
          xScroll={xScroll}
          isXScrollable={isXScrollable}
          controlFilters={controlFilters}
          onFiltersModalOpen={onFiltersModalOpen}
        />

        {filterChipsData.length > 0 && (
          <div className='mx-5'>
            <FilterTabsGroup
              id={helper.genId(id, helper.components().fchipscontainer)}
              selectedFilterTabs={filterChipsData}
              disabled={loading}
              onRemoveItemFromTabs={(data) => {
                if (!data) {
                  helper.onTFiltersApply([], onFiltersApply, onFiltersClear);
                  return;
                }

                const sFilters = [...selectedFilters];
                sFilters.forEach((sFilter, i) => {
                  sFilter.options.forEach((sOption, oi) => {
                    if (sOption.field !== data.field) return;
                    sFilters[i].options[oi].selected = sOption.id === data.id ? false : sOption.selected;
                  });
                });

                helper.onTFiltersApply(sFilters, onFiltersApply, onFiltersClear);
              }}
            />
          </div>
        )}

        {rows.length === 0 && !loading && (
          <EmptyState
            title={emptyMsg}
            subtitle={t('TEAM.PEOPLE.TRY_BEEING_LESS_SPECIFFIC_,_OR_CHECK_YOUR_SPELLING')}
            icon={emptySearchIcon}
            showBtn={false}
          />
        )}

        {(loading || (!loading && currentTotal > 0)) && (
          <div
            id={helper.genId(id, helper.components().tcontainer)}
            ref={tcRef}
            className={cx(styles.tContainer, { [styles.sticky]: stickyHead })}
          >
            <Table
              ref={refs}
              sort={sort}
              id={id}
              loading={loading}
              size={size}
              columns={columns}
              stickyHead={stickyHead}
              rows={rows}
              onSort={onSort}
              rowIdentifier={rowIdentifier}
              onXScrollable={(data) => setIsXScrollable(data.scrollable)}
              total={total}
              currentTotal={currentTotal}
              limit={limit}
              page={page}
              pages={pages}
              pagination={pagination}
              hoverable={hoverable}
              tableWidth={tableWidth}
            />
          </div>
        )}
      </PaginationWrapper>

      {controlColumns && (
        <Modal
          title={t('INSIGHTS.CUSTOMIZE_COLUMNS')}
          isOpen={columnsModalOpen}
          onRequestClose={() => helper.onTColumnsModalOpen({ open: false, id }, onColumnsModalOpen)}
          styles={{ width: '800px', padding: '0' }}
        >
          <UserReportColumnsModal
            onSave={(cols) => helper.onTColumnsSaved({ columns: cols, id }, onColumnsModalSave)}
            columns={columns}
            reportId={reportId}
          />
        </Modal>
      )}
      {controlDensity && (
        <Modal
          showDirtyForm
          title={t('GENERAL.ROW_DENSITY')}
          isOpen={rowDensityModalOpen}
          onRequestClose={() => helper.onTRowDensityModalOpen({ id, open: false }, onRowDensityModalOpen)}
          styles={{ width: '560px', paddingTop: '0px' }}
        >
          <RowDensityModal onClose={() => helper.onTRowDensityModalOpen({ id, open: false }, onRowDensityModalOpen)} />
        </Modal>
      )}
      <div>
        <SideTransitionContainer
          isIn={controlFilters && filtersModalOpen}
          onClose={() => helper.onTFiltersModalOpen({ open: false, id }, onFiltersModalOpen)}
        >
          <SearchFilterMenu
            filterMapArray={filters}
            selectedFilter={selectedFilters}
            onApplyFilter={(sFilters) => helper.onTFiltersApply(sFilters, onFiltersApply, onFiltersClear)}
            onSelectField={(selected, filter, value, label, valueLabel) =>
              helper.onTFiltersSelect({ selected, filter, value, label, valueLabel }, [...filters], onFiltersSelect)
            }
            onClose={() => helper.onTFiltersModalOpen({ open: false, id }, onFiltersModalOpen)}
          />
        </SideTransitionContainer>
      </div>
    </div>
  );
});

TableContainer.defaultProps = {
  id: 'table',
  columns: [],
  rows: [],
  title: '',
  description: '',
  rowIdentifier: 'id',
  loading: false,
  size: 'sm',
  stickyHead: false,
  stickyToolbar: false,
  sort: null,
  hoverable: true,
  emptyMsg: t('TEAM.PEOPLE.NO_ONE_MATCHES_YOUR_SEARCH'),

  // smart horizontal scroll
  xScroll: false,

  // paging
  total: 0,
  page: 0,
  limit: 25,
  pages: 0,
  pagination: false,
  onPageChange: null,

  onSort: null,

  // search
  searchable: false,
  searchValue: '',
  onSearch: null,

  // control columns
  controlColumns: false,
  columnsModalOpen: false,
  onColumnsModalOpen: null,
  onColumnsModalSave: null,

  // control density
  controlDensity: false,
  rowDensityModalOpen: false,
  onRowDensityModalOpen: null,

  // config
  reportId: null,

  // control filters
  controlFilters: false,
  filters: [],
  activeFilters: [],
  filtersModalOpen: false,
  onFiltersModalOpen: null,
  onFiltersSelect: null,
  onFiltersApply: null,
  onFiltersClear: null,
};

export default TableContainer;
