import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select';

import useLanguage from 'shared/useLanguage';
import formatKey from 'utility/formatKey';
import formatOptions from 'utility/formatOptions';
import t from 'utility/setTranslation';

import FieldWrapper from './FieldWrapper';

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '40px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#a7b5bf',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#043344',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    visibility: 'hidden',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#1A938A' : 'white',
    color: state.isSelected ? 'white' : '#043344',
    opacity: state.isDisabled ? '0.3' : '1',
  }),
  menuList: (provided) => ({
    ...provided,
    height: '150px',
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

const MultipleSelectField = ({
  className,
  name,
  label,
  options,
  isDisabled,
  isRequired,
  styles,
  onChange,
  error,
  onBlur,
  value,
  isFormik,
  onInputChange,
  scrollMore,
  isSearchDesign,
  placeholder,
}) => {
  const language = useLanguage();
  const [inputValue, setInputValue] = useState([]);
  const translatedOptions = formatOptions(options, language);
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onChangeHandler = (val) => {
    if (isFormik) {
      if (onChange) {
        onChange(name)(val);
      }
    } else {
      onChange(name, val);
    }
  };

  const clearSerachHadler = () => {
    if (onInputChange) {
      /* This is added to remove text from searchValue on select */
      onInputChange('');
    }
  };

  const onSearch = (v, e) => {
    // set search value only on input change, to prevent options api request on select value,
    // 'cause select value clears search
    if (onInputChange && e.action === 'input-change') {
      onInputChange(v);
    }
  };

  const onBlurField = () => {
    if (onBlur) {
      onBlur(name);
    }
  };

  return (
    <FieldWrapper
      name={name}
      className={cx(className, error ? 'is-invalid' : '', isSearchDesign ? 'search-select-design' : '')}
    >
      <div className='d-flex'>
        {label ? (
          <label className='label' htmlFor={name}>
            {formatKey('GENERAL', label)}
            {isRequired && <sup>*</sup>}
          </label>
        ) : null}
      </div>
      <Select
        isMulti
        hideSelectedOptions
        styles={{ ...selectStyles, ...styles }}
        name={name}
        className='styled-select'
        classNamePrefix='react-select'
        placeholder={placeholder ? t(placeholder) : isSearchDesign ? t('GENERAL.SEARCH_REQUESTS') : t('GENERAL.SELECT')}
        options={translatedOptions}
        value={inputValue}
        isDisabled={isDisabled}
        isClearable={!isRequired}
        backspaceRemoves={!isRequired}
        deleteRemoves={!isRequired}
        onChange={onChangeHandler}
        onBlur={onBlurField}
        onInputChange={onSearch}
        onMenuOpen={clearSerachHadler}
        onMenuScrollToBottom={scrollMore}
        maxMenuHeight={250}
        menuPortalTarget={document.body}
        menuShouldScrollIntoView={false}
        menuPosition='fixed'
        menuShouldBlockScroll
      />
      {!isSearchDesign && <div className='error'>{error}</div>}
    </FieldWrapper>
  );
};

MultipleSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  styles: PropTypes.object,
  isFormik: PropTypes.bool,
  onInputChange: PropTypes.func,
  isSearchDesign: PropTypes.bool,
};

MultipleSelectField.defaultProps = {
  value: [],
  label: '',
  onChange: undefined,
  onBlur: undefined,
  isDisabled: false,
  isRequired: false,
  className: '',
  styles: undefined,
  options: [],
  isFormik: true,
  onInputChange: undefined,
  isSearchDesign: false,
};

export default MultipleSelectField;
