import React, { useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import CSSTransition from 'react-transition-group/CSSTransition';

import IconButton from 'components/buttons/IconButton/IconButton';
import Tooltip from 'components/tooltip/Tooltip';
import OutsideClickHandler from 'hoc/OutsideClickHandler';
import t from 'utility/setTranslation';

import styles from './BulkActionToolbar.module.scss';
import PriorityMenu from './PriorityMenu';
import StatusMenu from './StatusMenu';

const link = cx(styles.link, 'd-flex align-items-center ms-2');

const BulkActionToolbar = ({ checkedNumber, onClose, onSelectAction, taskPriorities, taskStatuses }) => {
  const [priorityOpened, setPriorityOpened] = useState(false);
  const [statusOpened, setStatusOpened] = useState(false);

  return (
    <>
      <div
        className={cx(
          styles.toolBox,
          'position-absolute bg-white py-2a',
          'rounded-pill d-flex justify-content-between align-items-center'
        )}
      >
        <p>
          <span>{checkedNumber}</span>
          <span className='ms-2'>{t('GENERAL.SELECTED_TASKS')}</span>
        </p>

        <div className='d-flex justify-conten-end align-items-center'>
          <div>
            <div className={link}>
              <Tooltip label={t('GENERAL.STATUS')} position='left'>
                <IconButton
                  icon='icon-circle-checked'
                  color='secondary'
                  size='md'
                  onClick={() => {
                    setStatusOpened(true);
                  }}
                />
              </Tooltip>
            </div>
            <CSSTransition
              mountOnEnter
              unmountOnExit
              in={statusOpened}
              timeout={200}
              classNames={{
                enter: styles.enter,
                enterActive: styles.enterActive,
                exit: styles.exit,
                exitActive: styles.exitActive,
              }}
            >
              <OutsideClickHandler
                clickHandler={() => {
                  setStatusOpened(false);
                }}
              >
                <StatusMenu
                  className={styles.statusMenu}
                  statuses={taskStatuses.items}
                  onSelect={(type) => {
                    onSelectAction('status', type);
                    setStatusOpened(false);
                  }}
                />
              </OutsideClickHandler>
            </CSSTransition>
          </div>

          <div>
            <div className={link}>
              <Tooltip label={t('GENERAL.PRIORITY')} position='left'>
                <IconButton
                  icon='icon-custom-1'
                  color='secondary'
                  size='md'
                  onClick={() => {
                    setPriorityOpened(true);
                  }}
                />
              </Tooltip>
            </div>
            <CSSTransition
              mountOnEnter
              unmountOnExit
              in={priorityOpened}
              timeout={200}
              classNames={{
                enter: styles.enter,
                enterActive: styles.enterActive,
                exit: styles.exit,
                exitActive: styles.exitActive,
              }}
            >
              <OutsideClickHandler
                clickHandler={() => {
                  setPriorityOpened(false);
                }}
              >
                <PriorityMenu
                  className={styles.priorityMenu}
                  priorities={taskPriorities.items}
                  onSelect={(type) => {
                    if (type === 'clear') {
                      onSelectAction('priority', '');
                    } else {
                      onSelectAction('priority', type);
                    }

                    setPriorityOpened(false);
                  }}
                />
              </OutsideClickHandler>
            </CSSTransition>
          </div>

          <div className={link}>
            <Tooltip label={t('GENERAL.DELETE')} position='left'>
              <IconButton
                icon='icon-trash'
                color='secondary'
                size='md'
                onClick={() => {
                  onSelectAction('delete');
                }}
              />
            </Tooltip>
          </div>

          <div className={link}>
            <Tooltip label={t('GENERAL.CLEAR')} position='left'>
              <IconButton
                icon='icon-cross-x'
                color='secondary'
                size='md'
                onClick={() => {
                  onClose();
                }}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};

BulkActionToolbar.propTypes = {
  onClose: PropTypes.func.isRequired,
  checkedNumber: PropTypes.number.isRequired,
  onSelectAction: PropTypes.func.isRequired,
  taskPriorities: PropTypes.object,
  taskStatuses: PropTypes.object,
};

BulkActionToolbar.defaultProps = {
  taskPriorities: { items: [] },
  taskStatuses: { items: [] },
};

export default BulkActionToolbar;
