import React from 'react';

import EmptyList from 'components/EmptyPages/EmptyList';

import ListItem from './ListItem';

const WhoIsInList = ({ employees, onPreview }) => (
  <>
    {employees.length > 0 ? (
      employees.map((employee) => (
        <ListItem employee={employee} onClick={() => onPreview(employee.id)} key={employee.id} />
      ))
    ) : (
      <EmptyList icon='icon-user' text='GENERAL.LIST_IS_EMPTY' iconSize='xl' />
    )}
  </>
);

export default WhoIsInList;
