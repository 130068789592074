import React, { useContext, useState } from 'react';

import cx from 'classnames';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { AuthContext } from 'auth/AuthContext';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import ExpandableWrapper from 'components/ExpandableWrapper/ExpandableWrapper';
import Textarea from 'components/forms/components/Textarea';
import Personas from 'components/Personas';
import OutsideClickHandler from 'hoc/OutsideClickHandler';

import styles from './PalmHrAITextArea.module.scss';

const PalmHrAITextArea = ({ onSubmit, onRegenerate, showRegenerate, isSendDisabled }) => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [showSubmit, setShowSubmit] = useState(false);

  const schema = Yup.object().shape({
    message: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema: schema,
    onSubmit: (values, formikHelpers) => {
      onSubmit(values);
      formikHelpers.resetForm();
    },
  });

  const onKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey && !isSendDisabled) {
      event.preventDefault();
      formik.handleSubmit();
    }
  };

  return (
    <ExpandableWrapper
      minHeight={91}
      maxHeight={160}
      onExpand={() => setShowSubmit(true)}
      onUnExpand={() => setShowSubmit(false)}
      className='border-t px-5 py-4'
      renderChildren={({ expandHandler, unExpandHandler }) => (
        <form onSubmit={formik.handleSubmit} noValidate>
          <div className={cx('d-flex align-items-start w-100 h-100 position-relative')}>
            <Personas user={user} size={40} />
            <OutsideClickHandler className='h-100 ms-3 flex-1' clickHandler={unExpandHandler}>
              <>
                <Textarea
                  onClick={expandHandler}
                  name='message'
                  containerClassName='h-100'
                  customClass='no-resize h-100'
                  rows='1'
                  placeholder='Send a message.'
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onKeyPress={onKeyPress}
                />
                <div className={cx(styles.submitButtonContainer, 'd-flex justify-content-end')}>
                  {showRegenerate && showSubmit && (
                    <RoundedButton
                      btnStyle='outlined'
                      color='white'
                      text='Regenerate'
                      type='button'
                      className='me-2'
                      onClick={() => {
                        onRegenerate();
                        unExpandHandler();
                      }}
                    />
                  )}
                  {showSubmit && (
                    <RoundedButton
                      text='Send'
                      type='button'
                      onClick={() => {
                        formik.handleSubmit();
                        unExpandHandler();
                      }}
                      color='primary'
                      isDisabled={
                        !formik.dirty || Boolean(formik.dirty && Object.keys(formik.errors).length) || isSendDisabled
                      }
                    />
                  )}
                </div>
              </>
            </OutsideClickHandler>
          </div>
        </form>
      )}
    />
  );
};

PalmHrAITextArea.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onRegenerate: PropTypes.func.isRequired,
  showRegenerate: PropTypes.bool.isRequired,
};

export default PalmHrAITextArea;
