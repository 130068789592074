import http from './Api';

class WhoIsInApi {
  static getAttendanceRecords(employeeId) {
    return http.get(`/people/whos-in/attendances/${employeeId}/records`);
  }

  static addAttendanceRecords(employeeAttendanceId, data) {
    return http.post(`/people/whos-in/attendances/${employeeAttendanceId}/records`, data);
  }

  static updateAttendanceRecord(employeeAttendanceId, recordId, data) {
    return http.put(`people/whos-in/attendances/${employeeAttendanceId}/records/${recordId}`, data);
  }

  static deleteAttendanceRecord(employeeAttendanceId, recordId) {
    return http.delete(`/people/whos-in/attendances/${employeeAttendanceId}/records/${recordId}`);
  }
}

export default WhoIsInApi;
