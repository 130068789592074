export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'ACTIVE',
  OPT_OUT: 'OPT_OUT',
  CANCELLED: 'CANCELLED',
  NON_RENEWING: 'NON_RENEWING',
};

export const SUBSCRIPTION_ACTIONS = {
  ACTIVATE: 'ACTIVATE',
  DEACTIVATE: 'DEACTIVATE',
};
