import React, { useMemo } from 'react';

import cx from 'classnames';

import CheckBox from 'components/forms/components/CheckBox';
import useLanguage from 'shared/useLanguage';

import styles from './GoLivePaymentTerms.module.scss';
import { useAccountSubscriptionData } from '../../../../../../../hooks/useAccount';

const GoLivePaymentTerms = ({ formik }) => {
  const { data: accountSubscriptionData } = useAccountSubscriptionData();

  const activeLanguage = useLanguage();

  const country = accountSubscriptionData?.data.account.customerCountry;
  const handleChange = (checked) => {
    formik.setFieldValue('isTermsAccepted', checked);
  };

  const hasError = useMemo(
    () => formik.touched.isTermsAccepted && formik.errors.isTermsAccepted,
    [formik.touched.isTermsAccepted, formik.errors.isTermsAccepted]
  );

  const termsLink = useMemo(
    () =>
      country === 'KSA'
        ? '/downloads/cloud_services_master_agreement_ksa.pdf'
        : '/downloads/cloud_services_master_agreement_uae.pdf',
    [country]
  );

  const renderTermsText = () => {
    const text = activeLanguage === 'en' ? 'I have read and agree to ' : 'لقد قرأت ووافقت على ';

    return (
      <p className='font-size-14 m-0 text-center'>
        {text}
        <a href={termsLink} download target='_blank' rel='noreferrer'>
          {activeLanguage === 'en' ? 'Terms and Conditions' : 'الشروط والأحكام'}
        </a>
      </p>
    );
  };

  return (
    <div
      className={cx('d-flex align-items-center gap-2 px-5 py-2 full-border radius-8 mb-4', {
        [styles.errorBorder]: hasError,
      })}
    >
      <CheckBox
        shape='square'
        name='isTermsAccepted'
        checked={formik.values.isTermsAccepted}
        onChange={handleChange}
        error={hasError}
        onBlur={formik.handleBlur}
        id='isTermsAccepted'
      />
      {renderTermsText()}
    </div>
  );
};

export default GoLivePaymentTerms;
