import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';

import EngagementCenterApi from 'api/EngagementCenterApi';
import { AuthContext } from 'auth/AuthContext';
import { ThemeContext } from 'themes/ThemeContext';
import daysFromRange from 'utility/daysFromRange';
import formatRangesUTC from 'utility/formatRangesUTC';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'assets/sass/components/global/_range-calendar.scss';
import SkeletonCalendar from '../loaders/SkeletonCalendar';

const Calendar = ({
  setDates,
  minDate,
  maxDate,
  isLoading,
  disableDates,
  employee,
  className,
  showMonthArrow,
  weekdayDisplayFormat,
  canSelectDays,
  showMonthAndYearPickers,
  months,
  initialDate,
  requestType,
  handleCalendarDateChange,
}) => {
  const { authState } = useContext(AuthContext);
  const { themeState } = useContext(ThemeContext);
  const initialRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selected',
    color: themeState?.colors?.teal || '#16CA9C',
  };

  const initialYear = initialDate?.getFullYear().toString() || moment().format('YYYY');

  const [dateRangePicker, setDateRangePicker] = useState(initialRange);
  const [loading, setLoading] = useState(false);
  const [scheduledApproved, setScheduledApproved] = useState([]);
  const [scheduledPending, setScheduledPending] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [currentYear, setCurrentYear] = useState(initialYear);
  const [disabled, setDisabled] = useState([]);
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [isInit, setIsInit] = useState(false);
  const [visitedYears, setVisitedYears] = useState([initialYear]);

  useEffect(() => {
    if (selectedDate) {
      const range = {
        startDate: dateRangePicker.startDate,
        endDate: dateRangePicker.startDate,
        key: 'selected',
        color: themeState?.colors?.primary || '#16CA9C',
      };
      setDateRangePicker(range);
    } else {
      setDateRangePicker(initialRange);
    }
  }, [selectedDate]);

  const getCalendarData = (isScroll, year = currentYear) => {
    setLoading(true);
    setIsInit(true);
    if (!visitedYears.includes(year)) {
      setVisitedYears([...visitedYears, year]);
    }
    const hol = EngagementCenterApi.getHolidays(year, employee)
      .then((res) => {
        const holidayRanges = formatRangesUTC(res.data.items, themeState?.colors?.holiday || '#FF8BCD');
        if (isScroll) {
          setHolidays((state) => [...state, ...holidayRanges]);
        } else {
          setHolidays(holidayRanges);
        }
      })
      .catch(() => {});

    const req = EngagementCenterApi.getScheduled(employee, year, requestType === 'RemoteWorkRequest')
      .then((res) => {
        const data = res.data.items.reduce((acc, val) => {
          if (acc[val.status]) {
            acc[val.status].push(val);
          } else {
            acc[val.status] = [];
            acc[val.status].push(val);
          }
          return acc;
        }, {});

        const approved = formatRangesUTC(data.APPROVED, themeState?.colors['requests-approved'] || '#4EB1F9');
        const pending = formatRangesUTC(data.PENDING, themeState?.colors['requests-pending'] || '#ffa150');
        let disabledList = [];
        [...approved, ...pending].forEach((range) => {
          const days = [];
          daysFromRange(range.startDate, range.endDate).forEach((item) => {
            days.push(new Date(item));
          });
          disabledList = disabledList.concat(days);
        });
        if (isScroll) {
          setScheduledApproved((state) => [...state, ...approved]);
          setScheduledPending((state) => [...state, ...pending]);
          setDisabled((state) => [...state, ...disabledList]);
        } else {
          setScheduledApproved(approved);
          setScheduledPending(pending);
          setDisabled(disabledList);
        }
      })
      .catch(() => {});

    Promise.all([req, hol])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setVisitedYears([initialYear]);
    setCurrentYear(initialYear);
    getCalendarData(false, initialYear);
    setDateRangePicker(initialRange);
    setSelectedDate(initialDate || moment().toDate());

    return () => {
      setVisitedYears(undefined);
      setCurrentYear(undefined);
      setDateRangePicker(undefined);
      setSelectedDate(null);
    };
  }, [employee]);

  const handleRangeChange = (range) => {
    if (canSelectDays) {
      setDateRangePicker(range?.selected);
      setDates({
        startDate: range?.selected?.startDate,
        endDate: range?.selected?.endDate,
      });
    }
  };

  const onYearChange = (date) => {
    const year = moment(date).format('YYYY');
    if (!visitedYears.includes(year)) {
      setCurrentYear(year);
      setSelectedDate(date);
      getCalendarData(true, year);
      if (handleCalendarDateChange) {
        handleCalendarDateChange(date);
      }
    }
  };

  return (
    <div>
      {isLoading || loading || !isInit ? (
        <div style={{ height: '325px' }}>
          <SkeletonCalendar />
        </div>
      ) : (
        <DateRange
          minDate={minDate}
          maxDate={maxDate}
          onChange={handleRangeChange}
          className={cx(className, 'PreviewArea calendar-wrapper')}
          months={months}
          locale={locales[authState.user.language === 'ar' ? 'arSA' : 'en']}
          direction='horizontal'
          showMonthAndYearPickers={showMonthAndYearPickers}
          showDateDisplay={false}
          ranges={[
            dateRangePicker,
            ...holidays,
            ...scheduledApproved,
            ...scheduledPending,
            ...(disableDates?.ranges || []),
          ]}
          disabledDates={[...disabled, ...(disableDates?.days || [])]}
          onShownDateChange={onYearChange}
          shownDate={selectedDate}
          showMonthArrow={showMonthArrow}
          weekdayDisplayFormat={weekdayDisplayFormat}
          showPreview={canSelectDays}
          dragSelectionEnabled={canSelectDays}
          preventSnapRefocus
        />
      )}
    </div>
  );
};

Calendar.propTypes = {
  setDates: PropTypes.func.isRequired,
  showMonthArrow: PropTypes.bool,
  weekdayDisplayFormat: PropTypes.string,
  canSelectDays: PropTypes.bool,
  months: PropTypes.number,
  showMonthAndYearPickers: PropTypes.bool,
  initialDate: PropTypes.instanceOf(Date),
  requestType: PropTypes.string,
};

Calendar.defaultProps = {
  showMonthArrow: true,
  weekdayDisplayFormat: 'EEEEE',
  canSelectDays: true,
  months: 2,
  showMonthAndYearPickers: false,
  initialDate: null,
  requestType: '',
};

export default Calendar;
