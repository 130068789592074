import _ from "lodash";
import Location from "./Location";

export default class LocationCollection
{
    #collection

    constructor(items = [])
    {
        const collection = _.isObject(items)
            ? _.toArray(items)
            : items
        ;

        if(!_.isArray(collection)){
            throw new Error("Location collection items must be an array or object of objects.");
        }

        /**
         * @see Location.create
         * It will fail if item is not object,
         * this way we ensured that proper data is being passed
         */
        this.collection = collection.map((item) => Location.create(item));
    }

    static create(collection)
    {
        return new LocationCollection(collection instanceof LocationCollection
            ? collection.items
            : collection
        );
    }

    static createEmpty(){
        return new LocationCollection([]);
    }

    get items(){
        if(!this.collection) return [];
        return _.cloneDeep(this.collection);
    }

    get flatItems(){
        return this.items.map((item) => item.properties);
    }

    getItemsAsOptions(authState){
        return this.items.map((item) => item.getAsOption(authState));
    }

    get count(){
        return this.items.length;
    }

    get isEmpty(){
        return !this.count;
    }

    addItem(data)
    {
        this.items = [
            ...this.items,
            Location.create(data)
        ];
    }

}