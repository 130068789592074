import React, { useEffect, useState } from 'react';

import CheckBox from 'components/forms/components/CheckBox';
import t from 'utility/setTranslation';

import GoLiveAddonList from './GoLiveAddonList';
import { adaptAddonSubscriptionsForAPI } from '../../utility';

const GoLiveSubscriptionAddons = ({ formik, servicesAddOnsSubscriptions, productsAddOnsSubscriptions }) => {
  const [selectedProducts, setSelectedProducts] = useState(() =>
    productsAddOnsSubscriptions.filter((product) =>
      formik.values.subscription.addonSubscriptions.some((subscription) => subscription.plan === product.identifier)
    )
  );
  const [selectedServices, setSelectedServices] = useState(() =>
    servicesAddOnsSubscriptions.filter((service) =>
      formik.values.subscription.addonSubscriptions.some((subscription) => subscription.plan === service.identifier)
    )
  );
  const [isAllChecked, setIsAllChecked] = useState(false);

  const handleSelectProductAddon = (checked, name, value) => {
    if (checked) {
      setSelectedProducts([...selectedProducts, productsAddOnsSubscriptions.find((item) => item.id === value)]);
    } else {
      setSelectedProducts(selectedProducts.filter((item) => item.id !== value));
    }
  };

  const handleSelectServerAddon = (checked, name, value) => {
    if (checked) {
      setSelectedServices([...selectedServices, servicesAddOnsSubscriptions.find((item) => item.id === value)]);
    } else {
      setSelectedServices(selectedServices.filter((item) => item.id !== value));
    }
  };

  const checkAllAddons = (checked) => {
    setIsAllChecked(checked);
    if (checked) {
      setSelectedProducts([...productsAddOnsSubscriptions]);
      setSelectedServices([...servicesAddOnsSubscriptions]);
    } else {
      setSelectedProducts([]);
      setSelectedServices([]);
    }
  };

  useEffect(() => {
    formik.setFieldValue('subscription.addonSubscriptions', [
      ...adaptAddonSubscriptionsForAPI(selectedProducts, formik),
      ...adaptAddonSubscriptionsForAPI(selectedServices, formik),
    ]);
  }, [
    selectedProducts,
    selectedServices,
    formik.values.subscription.numberOfUsers,
    formik.values.subscription.periodUnit,
  ]);

  return (
    <>
      <CheckBox
        value='checkAll'
        checked={isAllChecked}
        onChange={(checked) => checkAllAddons(checked)}
        name='checkAll'
        label={t('GENERAL.ALL')}
        className='mb-3 ms-2'
      />
      <GoLiveAddonList
        type='primary'
        title={t('GENERAL.PRODUCT')}
        items={productsAddOnsSubscriptions}
        addonSelected={selectedProducts.map((item) => item.id)}
        onSelectAddon={handleSelectProductAddon}
      />
      <GoLiveAddonList
        type='secondary'
        title={t('GENERAL.SERVICE')}
        items={servicesAddOnsSubscriptions}
        addonSelected={selectedServices.map((item) => item.id)}
        onSelectAddon={handleSelectServerAddon}
      />
    </>
  );
};

export default GoLiveSubscriptionAddons;
