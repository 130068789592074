const create = (showResponseSuccessMessage, showResponseFailMessage) => {
  if (!showResponseSuccessMessage) {
    // eslint-disable-next-line no-param-reassign
    showResponseSuccessMessage = false;
  }

  if (!showResponseFailMessage) {
    // eslint-disable-next-line no-param-reassign
    showResponseFailMessage = false;
  }

  return {
    showResponseSuccessMessage,
    showResponseFailMessage,
  };
};

const shouldShowFailMessage = (interceptorConfig) => interceptorConfig.showResponseFailMessage === true;

export default {
  create,
  shouldShowFailMessage,
};
