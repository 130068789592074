import React, { useContext } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';

import { AuthContext } from 'auth/AuthContext';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

import styles from './ListReactions.module.scss';

const ListReactions = ({ data, title, position }) => {
  const { authState } = useContext(AuthContext);

  return (
    <>
      {data.length > 0 ? (
        <div
          style={{ top: position?.top, bottom: position?.bottom }}
          className={cx(styles.reactions, 'tooltip-content')}
        >
          <h6 className={cx(styles.title, 'px-2 pt-2')}>{t(title)}</h6>
          <Scrollbars
            className='scrollbarsWrapper'
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            autoHeight
            autoHeightMin={30}
            autoHeightMax={250}
          >
            <ul className='no-style px-2 mt-1'>
              {data.map((item) => (
                <li key={item.employee.id}>
                  {readName(authState.user.language, item.employee.fullName, item.employee.fullNameArabic)}
                </li>
              ))}
            </ul>
          </Scrollbars>
        </div>
      ) : null}
    </>
  );
};

ListReactions.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  title: PropTypes.string.isRequired,
  position: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default ListReactions;
