import { useGetWhoIsIn, useGetWhoNeedsToBeIn } from 'api/WhoIsIn/WhoIsInQueryApiUse';
import t from 'utility/setTranslation';

export const TYPE_IN = 'IN';
export const TYPE_OUT = 'OUT';

export const ALL = 'GENERAL.ALL';
export const PRESENT = 'GENERAL.PRESENT.ON_SITE';
export const RW = 'GENERAL.REMOTE_WORK';
export const BT = 'EC.REQUESTS.BUSINESS_TRIP';

export const api = (type) => {
  if (type === TYPE_OUT) return useGetWhoNeedsToBeIn;
  return useGetWhoIsIn;
};

export const options = (type) => {
  const items = [
    { value: ALL, label: t(ALL) },
    { value: PRESENT, label: t(PRESENT) },
    { value: RW, label: t(RW) },
  ];

  if (type === TYPE_IN) items.push({ value: BT, label: t(BT) });

  return items;
};

export const buildPayload = (limit, page, term, leaveType, type) => {
  let filters = {};

  if (leaveType === PRESENT) {
    filters = { isEmployeeOnRemote: false };
    if (type === TYPE_IN) filters.isEmployeeOnBusinessTrip = false;
  }

  if (leaveType === RW) filters = { isEmployeeOnRemote: true };
  if (leaveType === BT) filters = { isEmployeeOnBusinessTrip: true };

  return {
    limit,
    page,
    filters: JSON.stringify({ term, ...filters }),
  };
};
