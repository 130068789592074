import _ from 'lodash';

import resourcePath from 'shared/resourcePath';
import readName from 'utility/readName';

export default class Employee {
  #data;

  constructor(data = {}) {
    if (!_.isObject(data)) {
      throw new Error('Employee must be an object');
    }

    this.data = data;
  }

  static create(data) {
    return new Employee(data instanceof Employee ? data.getData() : data);
  }

  getData() {
    if (!this.data) return {};
    return _.cloneDeep(this.data);
  }

  getId() {
    return this.getData().id ?? '';
  }

  get fullName() {
    return this.getData().fullName ?? '';
  }

  get fullNameArabic() {
    return this.getData().fullNameArabic ?? '';
  }

  getLocalizedFullName(language) {
    return readName(language, this.fullName, this.fullNameArabic);
  }

  getUserLocalizedFullName(authState) {
    return this.getLocalizedFullName(authState?.user?.language);
  }

  get avatarThumb() {
    return this.getData().avatarThumb;
  }

  get relativeAvatarThumb() {
    return resourcePath(this.avatarThumb);
  }

  get avatarThumbPath() {
    return this.getData().avatarThumbPath;
  }

  get relativeAvatarThumbPath() {
    return resourcePath(this.avatarThumbPath);
  }

  getActiveContractLegalEntity() {
    return this.getData()?.contractedTo ?? {};
  }

  getActiveContractLegalEntityId() {
    return this.getActiveContractLegalEntity()?.id;
  }

  get activeJob() {
    const job = this.getData().activeJob ?? this.getData().job;
    return job ?? {};
  }

  getUserLocalizedActiveJob(authState) {
    return readName(authState?.user?.language, this.activeJob.name, this.activeJob.nameArabic);
  }

  get job() {
    return this.getData().job ?? {};
  }

  getUserLocalizedJob(authState) {
    return readName(authState?.user?.language, this.job.name, this.job.nameArabic);
  }

  get location() {
    return this.getData().activeLocation ?? {};
  }

  getUserLocalizedLocation(authState) {
    return readName(authState?.user?.language, this.location.name, this.location.nameArabic);
  }

  get employmentCode() {
    return this.getData().code;
  }

  get formattedEmploymentCode() {
    return this.employmentCode ?? '--';
  }

  get isOnFullRemoteDay() {
    return this.getData().onFullRemoteDay ?? false;
  }

  get isOnFullBusinessTripDay() {
    return this.getData().onFullDayBusinessTrip ?? false;
  }
}
