/* eslint-disable */

import PropTypes from 'prop-types';
import propTypesUtility from 'utility/propTypesUtility';
import colorUtility from 'utility/style/colorUtility';
import sizeUtility from 'utility/style/sizeUtility';
import cx from 'classnames';
import style from './style.module.scss';

const propTypes = () => {
  return {
    id: propTypesUtility.idPropType(),
    color: propTypesUtility.colorPropType(),
    size: propTypesUtility.sizePropType(),
    variant: PropTypes.oneOf(['solid', 'dotted', 'dashed']),
    className: propTypesUtility.classNamePropType()
  };
};

const defaultProps = () =>
{
  return {
    id: 'circular-loader',
    color: colorUtility.defaultColor(),
    size: sizeUtility.xsSize(),
    variant: 'solid',
    className: '',
  };
};

const classes = (className, color, size, variant) => {
  return cx(
    'd-flex',
    style.container,
    cx(style.variant, { [style[variant]]: true }),
    cx(style.colorBorder, { [style[color]]: true }),
    cx(style.size, { [style[size]]: true }),
      className
  );
};

export default {
  propTypes,
  defaultProps,
  classes,
};
