import React from 'react';

import cx from 'classnames';

import styles from './DaysHeader.module.scss';

const DaysHeader = () => {
  const daysName = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

  return (
    <div className='d-flex justify-content-between'>
      {daysName.map((name) => (
        <div key={name} className={cx('d-flex justify-content-center', styles.textContainer)}>
          <div className={cx('d-flex align-items-center', styles.text)}>
            <div className='w-100'>{name}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DaysHeader;
