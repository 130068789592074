import React from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import Personas from 'components/Personas';
import Tooltip from 'components/tooltip/Tooltip';
import useLanguage from 'shared/useLanguage';
import { getOrdinal } from 'utility/getOrdinal';
import renderPeriodKey from 'utility/renderPeriodKey';
import t from 'utility/setTranslation';

import styles from './CardAdvancedItem.module.scss';

const EmployeeItem = ({ data, type, date, icon, years, selectedDate }) => {
  const activeLang = useLanguage();

  const getYearsOfService = () => {
    if (data?.hireDate) {
      let currentDate = selectedDate ? moment(selectedDate) : moment();
      const hireDate = moment(data?.hireDate);
      if (hireDate.month() === 0 && !selectedDate) {
        currentDate = currentDate.add(1, 'month');
      }
      const yearsOfService = currentDate.year() - hireDate.year();
      return yearsOfService;
    }
    return 0;
  };

  return (
    <div className='w-100 d-flex justify-content-between align-items-center'>
      <div className='w-75 pe-1'>
        <Personas user={data} size={40} showDetails customClass='mb-2 mt-2' />
      </div>
      <div className='w-25 d-flex justify-content-end'>
        <div className='d-flex'>
          <Tooltip
            position='left'
            label={`${
              type === 'anniversary'
                ? `${t('EMPLOYEE.FLAG.ANNIVERSARY')} - ${
                    activeLang === 'ar'
                      ? `${getYearsOfService()} ${t(renderPeriodKey(getYearsOfService(), 'YEAR'))}`
                      : `${getOrdinal(getYearsOfService())} Year`
                  }`
                : t(`EMPLOYEE.FLAG.${type.toUpperCase()}`)
            } ${type !== 'birthday' && date ? years : ''}`}
          >
            <i
              className={cx(
                styles.contentIcon,
                icon,
                { [styles.birthday]: type === 'birthday' },
                { [styles.anniversary]: type === 'anniversary' }
              )}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

EmployeeItem.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

EmployeeItem.defaultProps = {
  data: null,
  type: '',
  date: '',
};

export default React.memo(EmployeeItem);
