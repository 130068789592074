import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';

import emptyImage from 'assets/img/request/congratulations.png';
import t from 'utility/setTranslation';

import styles from './CardAdvanced.module.scss';
import CardAdvancedItem from './CardAdvancedItem';
import SkeletonCardAdvanced from './SkeletonCardAdvanced';

const CardAdvanced = ({ title, todayData, upcomingData, loading, onClick }) => {
  const [formattedUpcomingData, setFormattedUpcomingData] = useState({});

  const formatDate = (d) => {
    const currMonth = moment(new Date()).month() + 1;
    const selectedMonth = moment(d, 'MM-DD').month() + 1;
    const currYear = moment(new Date()).year();

    let newData = '';
    if (currMonth === 12 && selectedMonth !== 12) {
      newData = `${currYear + 1}-${d}`;
    } else {
      newData = `${currYear}-${d}`;
    }

    return newData;
  };

  const counter = () => todayData.length + upcomingData.length;

  useEffect(() => {
    const newObj = upcomingData.map((item) => {
      const formattedDate = formatDate(moment(item.date).format('MM-DD'));

      Object.assign(item, { sortDate: moment(formattedDate).format('YYYY-MM-DD') });

      return item;
    });
    const sortedObj = _.sortBy(newObj, (elem) => moment(elem.sortDate, 'YYYY-MM-DD'));
    // grouped data by date
    const upcomingData1 = _.groupBy(sortedObj, 'sortDate');

    setFormattedUpcomingData(upcomingData1);
  }, [upcomingData]);
  return (
    <div className={cx(styles.cardWrapper)}>
      {loading ? (
        <SkeletonCardAdvanced num={3} />
      ) : (
        <>
          <div className={cx(styles.cardHeader, 'd-flex justify-content-between align-items-center')}>
            <div className={styles.title}>
              {title}
              <span className={styles.counter}> {`(${counter()})`}</span>
            </div>

            <div className={cx(styles.calendar, 'd-flex align-items-center')}>
              <i onClick={onClick} className='icon-calendar-dates' />
            </div>
          </div>

          {todayData && upcomingData && todayData.length === 0 && upcomingData.length === 0 ? (
            <div className={cx(styles.emptyState, 'm-auto')}>
              <div className={cx(styles.iconEmptyState, 'm-auto d-flex justify-content-center align-items-center')}>
                <img src={emptyImage} alt='empty-icon' />
              </div>

              <div className={styles.emptyStateText}>{t('GENERAL.NO_EVENTS_IN_NEXT_DAYS')}</div>
            </div>
          ) : (
            <Scrollbars className='scrollbarsWrapper' autoHeight autoHeightMin='150px' autoHeightMax='400px'>
              {todayData && todayData.length > 0 && (
                <>
                  <div className={cx(styles.label, 'my-3')}>{t('GENERAL.TODAY').toUpperCase()}</div>

                  {todayData.map((item) => (
                    <CardAdvancedItem
                      key={item?.id}
                      employee={{
                        avatarThumb: item?.avatarThumb,
                        avatarThumbPath: item?.avatarThumbPath,
                        fullName: item?.fullName,
                        fullNameArabic: item?.fullNameArabic,
                        id: item?.id,
                        job: item?.job,
                      }}
                      company={item}
                      type={item?.type}
                      date={item?.date}
                    />
                  ))}
                </>
              )}

              {upcomingData && upcomingData.length > 0 && (
                <>
                  <div className={cx(styles.label, 'my-3')}>{t('GENERAL.UPCOMING').toUpperCase()}</div>

                  {Object.keys(formattedUpcomingData).map((key) => (
                    <div key={key}>
                      <div className={cx(styles.date, 'w-100 my-2 pt-2')}>
                        {moment(key).format('ddd, D. MMM').toUpperCase()}
                      </div>

                      {formattedUpcomingData[key].map((item) => (
                        <CardAdvancedItem
                          key={item?.id}
                          employee={{
                            avatarThumb: item?.avatarThumb,
                            avatarThumbPath: item?.avatarThumbPath,
                            fullName: item?.fullName,
                            fullNameArabic: item?.fullNameArabic,
                            id: item?.id,
                            job: item?.job,
                            hireDate: item?.hireDate,
                          }}
                          company={item}
                          type={item?.type}
                          date={item?.date}
                          upcoming
                        />
                      ))}
                    </div>
                  ))}
                </>
              )}
            </Scrollbars>
          )}
        </>
      )}
    </div>
  );
};

CardAdvanced.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  todayData: PropTypes.array,
  upcomingData: PropTypes.array,
  onClick: PropTypes.func,
};

CardAdvanced.defaultProps = {
  title: '',
  loading: false,
  todayData: [],
  upcomingData: [],
  onClick: undefined,
};

export default CardAdvanced;
