import React, { useContext, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import cx from 'classnames';

import { AuthContext } from 'auth/AuthContext';
import IconButton from 'components/buttons/IconButton/IconButton';
import Modal from 'components/Modal';
import CanceledState from 'components/RenewalModal/CanceledState';
import ConfirmationState from 'components/RenewalModal/ConfirmationState';
import RenewalState from 'components/RenewalModal/RenewalState';
import AreaLoader from 'components/Spinner/AreaLoader';
import { queryKeys, useCancelSubscription, userRenewSubscription } from 'topNavigation/hooks/useAccount';

import styles from './RenewalModal.module.scss';

const RenewalModal = ({ isOpen, onRequestClose, onCreateTask }) => {
  const queryClient = useQueryClient();
  const { isLoading: isLoadingCancelSubscription, mutate } = useCancelSubscription();
  const { isLoading: isLoadingRenewSubscription, mutate: renewSubscription } = userRenewSubscription();
  const [isCanceled, setIsCanceled] = useState(false);
  const [isRenewed, setIsRenewed] = useState(false);
  const { dispatch } = useContext(AuthContext);

  const onConfirmRenewal = () => {
    renewSubscription(undefined, {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.subscriptionData);
        setIsRenewed(true);
      },
    });
  };

  const onCancelSubscription = () => {
    mutate(undefined, {
      onSuccess: () => {
        dispatch({ type: 'set', payload: { isRenewalPending: false } });
        queryClient.invalidateQueries(queryKeys.subscriptionData);
        setIsCanceled(true);
      },
    });
  };

  const renderContent = () => {
    if (isLoadingCancelSubscription || isLoadingRenewSubscription) {
      return <AreaLoader className='mb-15' />;
    }

    if (isRenewed) {
      return <RenewalState closeModal={onRequestClose} onCreateTask={onCreateTask} />;
    }

    if (isCanceled) {
      return <CanceledState />;
    }

    return (
      <ConfirmationState showImage onCancelSubscription={onCancelSubscription} onConfirmRenewal={onConfirmRenewal} />
    );
  };

  return (
    <Modal
      showHeader={false}
      isOpen={isOpen}
      styles={{ width: '672px', backgroundColor: 'rgba(0,0,0,0)', boxShadow: 'none', padding: 0 }}
      onRequestClose={onRequestClose}
    >
      <section className='bg-white position-relative mt-3 mx-3 radius-8 py-7 shadow-04'>
        <IconButton
          size='sm'
          icon='icon-cross-x'
          showBorder
          className={cx(styles.closeButton, 'bg-white z-1')}
          onClick={onRequestClose}
        />

        {renderContent()}
      </section>
    </Modal>
  );
};

export default RenewalModal;
