import http from 'api/Api';

export class MyProjectsAPI {
  static getMonthlyRequests(params) {
    return http.get(`/billable-hours/requests/monthly/me`, { params });
  }

  static getMonthlyRequestProjects(params) {
    return http.get(`/billable-hours/requests/projection/me`, { params });
  }

  static saveMonthlyRequestDraft({ requestId, request }) {
    if (requestId) {
      return http.put(`/billable-hours/request/save-as-draft/${requestId}`, request);
    }
    return http.put(`/billable-hours/request/save-as-draft`, request);
  }

  static submitMonthlyRequest({ requestId, request }) {
    if (requestId) {
      return http.put(`/billable-hours/request/submit/${requestId}`, request);
    }
    return http.put(`/billable-hours/request/submit`, request);
  }
}
