import http from './Api';

class ProfileNotificationSettings {
  static getProfileNotificationSettings(employeeId) {
    return http.get(`profile/${employeeId}/notification-settings`);
  }

  static updateProfileNotificationSettings(employeeId, data) {
    return http.put(`profile/${employeeId}/notification-settings`, data);
  }
}

export default ProfileNotificationSettings;
