import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './SkeletonProfile.module.scss';

const SkeletonProfile = ({ style, gradient }) => (
  <div className='d-flex align-items-center'>
    <div className={cx(styles.image, gradient)} style={{ ...style.image }} />
    <div>
      <div className={cx(styles.line1, gradient, 'mb-2')} style={{ ...style.line1 }} />
      <div className={cx(styles.line2, gradient)} style={{ ...style.line2 }} />
    </div>
  </div>
);

SkeletonProfile.propTypes = {
  gradient: PropTypes.string.isRequired,
  style: PropTypes.object,
};

SkeletonProfile.defaultProps = {
  style: {},
};

export default SkeletonProfile;
