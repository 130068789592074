import React from 'react';

import PropTypes from 'prop-types';
import CSSTransition from 'react-transition-group/CSSTransition';

import IconButton from 'components/buttons/IconButton/IconButton';
import MoreActions from 'components/menu/components/MoreActions';
import { useVisibility } from 'shared/useVisibility';

import styles from './Menu.module.scss';

const Menu = ({
  data,
  onAction,
  icon,
  iconSize,
  iconColor,
  className,
  style,
  showBorder,
  types,
  tooltipText,
  isDisabled,
  renderCustomButton,
  renderCustomItem,
  openMenuClassName,
  itemKey,
}) => {
  const { isOpen, close, open, toggle } = useVisibility();

  return (
    <div className={className}>
      {renderCustomButton ? (
        renderCustomButton({ isOpen, close, open, toggle })
      ) : (
        <IconButton
          icon={icon}
          onClick={open}
          size={iconSize}
          color={iconColor}
          showBorder={showBorder}
          tooltipText={tooltipText}
          isDisabled={isDisabled}
        />
      )}
      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={isOpen}
        timeout={100}
        classNames={{
          enter: styles.enter,
          enterActive: styles.enterActive,
          exit: styles.exit,
          exitActive: styles.exitActive,
        }}
      >
        <MoreActions
          className={openMenuClassName}
          renderCustomItem={renderCustomItem}
          style={style}
          itemKey={itemKey}
          data={data}
          onClose={close}
          onAction={onAction}
          types={types}
        />
      </CSSTransition>
    </div>
  );
};

Menu.propTypes = {
  style: PropTypes.object,
  onAction: PropTypes.func,
  data: PropTypes.array,
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  iconColor: PropTypes.string,
  itemKey: PropTypes.string,
  showBorder: PropTypes.bool,
  types: PropTypes.array,
  tooltipText: PropTypes.string,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  openMenuClassName: PropTypes.string,
  renderCustomButton: PropTypes.func,
  renderCustomItem: PropTypes.func,
};

Menu.defaultProps = {
  onAction: undefined,
  data: [],
  icon: 'icon-more-horizontal',
  iconSize: 'sm',
  iconColor: 'gray',
  style: {},
  className: '',
  itemKey: '',
  openMenuClassName: '',
  showBorder: false,
  isDisabled: false,
  renderCustomButton: null,
  renderCustomItem: null,
  types: [],
  tooltipText: '',
};

export default React.memo(Menu);
