import React from 'react';

import cx from 'classnames';
import moment from 'moment';

import renewalState from 'assets/img/Subscription_Renewal.svg';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import AreaLoader from 'components/Spinner/AreaLoader';
import { useSubscriptionDueDate } from 'topNavigation/hooks/useAccount';
import t from 'utility/setTranslation';

const ConfirmationState = ({ onCancelSubscription, onConfirmRenewal, showImage, className }) => {
  const { data, isLoading } = useSubscriptionDueDate();
  const dueDate = moment(data?.data?.dueDate).format('MMM DD, YYYY');

  return (
    <article className={cx('px-6 py-4 d-flex flex-column align-items-center', className)}>
      {isLoading ? (
        <AreaLoader className='mb-15' />
      ) : (
        <>
          {showImage && <img src={renewalState} width={220} alt='renwal needed' className='mt-7 mb-7' />}
          <section className='mb-5'>
            <h5 className='text-center font-weight-bold text-main font-size-20 mb-3'>
              {t('GENERAL.SUBSCRIPTION_RENEWAL')}
            </h5>
            <p className='text-center text-secondary font-size-16 multiline_2 text-secondary'>
              {t('GENERAL.RENEWAL_MESSAGE', { endDate: dueDate })}
            </p>
          </section>

          <footer className='d-flex gap-3 justify-content-center'>
            <RoundedButton
              onClick={onCancelSubscription}
              type='button'
              text='Cancel Subscription'
              btnStyle='outlined'
              color='primary'
              size='md'
            />
            <RoundedButton
              type='button'
              btnStyle='contained'
              color='primary'
              onClick={onConfirmRenewal}
              text='Confirm Renewal'
              size='md'
            />
          </footer>
        </>
      )}
    </article>
  );
};

export default ConfirmationState;
