import React from 'react';

import cx from 'classnames';

import Line from 'modules/EngagementCenter/shared/loaders/Line';

const LoadingList = ({ rowsCount, rowHeight, spaceBetween, className }) => (
  <div className={cx('d-flex flex-column', className)}>
    {[...Array(rowsCount).keys()].map((item) => (
      <Line
        key={item}
        style={{ height: rowHeight, width: '100%', marginBottom: spaceBetween }}
        gradient='loading-gradient'
        className='radius-4'
      />
    ))}
  </div>
);

export default LoadingList;
