import React, { useContext, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import { AuthContext } from 'auth/AuthContext';
import { getCookie, setCookie } from 'auth/Cookies';
import InitLoading from 'components/Spinner/InitLoading';
import { languageConstants } from 'constants/language';
import { isMobileFriendlyRoute } from 'shared/isMobileFriendlyRoute';
import { useCrisp } from 'shared/useCrisp';
import { useQueryTheme } from 'shared/useTheme';
import { loadLocales, queryKeys, useQueryTranslations } from 'shared/useTranslations';
import { getThemeFromLS, ThemeContext } from 'themes/ThemeContext';
import setTheme from 'utility/setTheme';

const SharedLogic = ({ children }) => {
  const queryClient = useQueryClient();
  const { dispatch } = useContext(AuthContext);
  const { dispatchTheme } = useContext(ThemeContext);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const paramLang = urlParams.get('lang');

  useCrisp();

  const { isLoading: isThemeLoading } = useQueryTheme({
    onSuccess: (res) => {
      dispatchTheme({ type: 'set', payload: res.data });
      setTheme(res.data.colors);
    },
    onError: () => {
      const theme = getThemeFromLS('theme');
      if (theme) {
        dispatchTheme({ type: 'set', payload: theme });
      }
    },
  });

  let browserLan = paramLang;

  if (browserLan) {
    setCookie('language', browserLan);
  }
  if (navigator.language && !browserLan) {
    browserLan = navigator.language.toLowerCase().includes('ar') ? 'ar' : 'en';
  }
  if (navigator.userLanguage) {
    browserLan = navigator.userLanguage.toLowerCase().includes('ar') ? 'ar' : 'en';
  }

  const localLanguage = getCookie('language');
  const language = localLanguage || browserLan || languageConstants.DEFAULT;

  const { isLoading: isTranslationsInitialLoading, data } = useQueryTranslations(language, {
    onSuccess: ({ data: translations }) => {
      loadLocales({ language, translations: data?.data });
      localStorage.setItem(`translations-${language}`, JSON.stringify(translations));
    },
    onError: () => {
      const storageLanguage = localStorage.getItem(`translations-${language}`);
      loadLocales({ language, translations: JSON.parse(storageLanguage) });
    },
    enabled: !queryClient.getQueryData(queryKeys.translations(language)),
  });

  useEffect(() => {
    const storageLanguage = localStorage.getItem(`translations-${language}`);
    loadLocales({ language, translations: data?.data || JSON.parse(storageLanguage) });
    dispatch({ type: 'set', payload: { translations: data?.data } });
  }, [JSON.stringify(data?.data)]);

  useEffect(() => {
    if (!isTranslationsInitialLoading && !isMobileFriendlyRoute(window.location.pathname)) {
      /** Checking if it is open on iOS  */
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        const newUrl = `palmhr://${window.location.href}`;
        window.location.href = newUrl;
      }
    }
  }, [isTranslationsInitialLoading]);

  return <>{isThemeLoading || isTranslationsInitialLoading ? <InitLoading /> : children}</>;
};

export default SharedLogic;
