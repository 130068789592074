import React from 'react';

import PropTypes from 'prop-types';

import ConfirmationBox from 'components/ConfirmationBox';
import Modal from 'components/Modal';

const ConfirmationModal = ({
  isOpen,
  onAction,
  onClose,
  responseStatus,
  isLoading,
  description,
  title,
  type,
  onRequestClose,
  actionText,
  styles,
  renderAdditionalContent,
  confirmationButtonId,
}) => (
  <Modal
    isOpen={isOpen}
    showHeader={false}
    styles={{ width: '360px', padding: '24px 48px 48px', ...styles }}
    onRequestClose={onRequestClose || onClose}
  >
    <ConfirmationBox
      renderAdditionalContent={renderAdditionalContent}
      responseStatus={responseStatus}
      sending={isLoading}
      type={type}
      leadText={title}
      actionText={actionText}
      action={onAction}
      text={description}
      close={onClose}
      confirmationButtonId={confirmationButtonId}
    />
  </Modal>
);

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  responseStatus: PropTypes.object,
  isLoading: PropTypes.bool,
  description: PropTypes.string,
  type: PropTypes.string,
  onRequestClose: PropTypes.func,
  actionText: PropTypes.string,
  styles: PropTypes.object,
  confirmationButtonId: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  isLoading: false,
  description: '',
  type: 'confirm',
  responseStatus: {},
  onRequestClose: null,
  actionText: 'Confirm',
  styles: {},
  confirmationButtonId: '',
};

export default ConfirmationModal;
