import React, { useContext, useEffect } from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';

import { AuthContext } from 'auth/AuthContext';
import formatKey from 'utility/formatKey';
import t from 'utility/setTranslation';

import styles from './DateInput.module.scss';
import FieldWrapper from './FieldWrapper';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const DateInput = ({
  name,
  value,
  label,
  isRequired,
  isDisabled,
  min,
  max,
  customClass,
  isInvalid,
  onChange,
  onBlur,
  error,
  isFormik,
  maxDetail,
  size,
}) => {
  const { authState } = useContext(AuthContext);

  const getNextSibling = (elem, selector) => {
    // Get the next sibling element
    let sibling = elem.nextElementSibling;

    // If there's no selector, return the first sibling
    if (!selector) return sibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) return sibling;
      sibling = sibling.nextElementSibling;
    }

    return null;
  };
  const removeComaFromPlaceholder = () => {
    setTimeout(() => {
      const select = document.getElementsByClassName('react-date-picker__inputGroup__month');

      if (maxDetail !== 'year') {
        Array.from(select).forEach((el) => {
          const day = getNextSibling(el, '.react-date-picker__inputGroup__day');
          if (el.children[0].innerHTML === 'Select') {
            day.nextElementSibling.innerHTML = '';
          } else {
            day.nextElementSibling.innerHTML = ', ';
          }
        });
      }
    }, 10);
  }; // Hack for removing , in palceholder

  const onChangeField = (value2) => {
    if (isFormik) {
      onChange(name)(value2 ? value2.toString() : '');
    } else {
      onChange(value2 ? value2.toString() : '');
    }
  };

  const onBlurField = () => {
    if (onBlur) {
      onBlur(name);
    }
  };
  const formatDate = () => {
    let format = 'MMM dd, yyyy';
    if (maxDetail === 'year') {
      format = 'MMMy';
    } else if (maxDetail !== 'year' && authState?.user?.language === 'ar') {
      format = 'MMM d, yyyy';
    }
    return format;
  };

  useEffect(() => {
    removeComaFromPlaceholder();
    if (value && moment(value, 'YYYY-MM-DD').isBefore(min, 'day')) {
      onChangeField('');
    }
  }, [min, value]);

  return (
    <FieldWrapper name={name} className={cx(styles.inputWrapper, customClass, 'position-relative')}>
      <div className='d-flex'>
        {label ? (
          <label className='label' htmlFor={name}>
            {formatKey('GENERAL', label)}
            {isRequired && <sup>*</sup>}
          </label>
        ) : null}
      </div>
      <DatePicker
        className={cx(`form-control form-control-${size}`, (error || isInvalid) && 'is-invalid')}
        value={typeof value === 'string' ? (value !== '' ? new Date(value) : '') : value}
        onChange={(val) => {
          onChangeField(val);
        }}
        onBlur={onBlurField}
        openCalendarOnFocus
        disabled={isDisabled}
        disableCalendar={isDisabled}
        name={name}
        locale={authState?.user?.language || 'en'}
        format={formatDate()}
        maxDate={typeof max === 'string' ? new Date(max) : max}
        maxDetail={maxDetail}
        minDate={typeof min === 'string' ? new Date(min) : min}
        required={isRequired}
        calendarIcon={<i className='icon-calendar-dates' />}
        clearIcon={<i className='icon-cross-x' />}
        yearPlaceholder=''
        monthPlaceholder={t('GENERAL.SELECT')}
        dayPlaceholder=''
        showLeadingZeros={false}
      />
      <div className='invalid-feedback mt-1 text-start'>
        <span>{error}</span>
      </div>
    </FieldWrapper>
  );
};

DateInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  customClass: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  error: PropTypes.string,
  isFormik: PropTypes.bool,
  size: PropTypes.string,
  maxDetail: PropTypes.string,
};

DateInput.defaultProps = {
  value: new Date(),
  label: '',
  onChange: undefined,
  onBlur: undefined,
  isRequired: false,
  isDisabled: false,
  customClass: '',
  min: new Date('1900-01-01'),
  max: new Date('3000-12-31'),
  isInvalid: false,
  error: '',
  isFormik: true,
  size: '',
  maxDetail: 'month',
};

export default DateInput;
