import moment from 'moment/moment';

const extractApplicationsByDate = (applicationsPerDay) => (dayDate) =>
  applicationsPerDay.find(({ createdAt }) => moment(createdAt).format('DD MMM YYYY') === dayDate.format('DD MMM YYYY'))
    ?.count;

const getAllDaysOfRange = (startDate, endDate, dataCallback) => {
  const datesArray = [];

  while (startDate.isSameOrBefore(endDate, 'day')) {
    datesArray.push([startDate.format('DD MMM YYYY'), dataCallback(startDate) || 0]);
    startDate.add(1, 'day');
  }

  return datesArray;
};

export { getAllDaysOfRange, extractApplicationsByDate };
