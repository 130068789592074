import PropTypes from "prop-types";
import _ from "lodash";
import Team from "services/Team/Team";

export const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    isNullable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    showLabel: PropTypes.bool,
    value: PropTypes.oneOf(PropTypes.instanceOf(Team), PropTypes.object),
    onChange: PropTypes.func
}

export const defaultProps = {
    name: 'team',
    label: 'team',
    placeholder: 'team',
    className: '',
    isNullable: true,
    isDisabled: false,
    isRequired: false,
    showLabel: true,
    value: null,
    onChange: null,
}

export const formatData = (data) => Team.create(data ?? {});

export const formatDisplay = (display) => display ? _.capitalize(display) : '';