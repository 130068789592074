import React, { useEffect } from 'react';

import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import pick from 'lodash/pick';
import { Scrollbars } from 'react-custom-scrollbars';

import ActionLoading from 'components/ActionLoading/ActionLoading';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import SpinnerRing from 'components/Spinner/SpinnerRing';
import {
  queryKeys,
  useMutationEmployeePrivacySettingsPerEmployee,
  useQueryEmployeeDetails,
  useQueryEmployeePrivacySettingsPerEmployee,
} from 'modules/CompanySettings/Team/hooks/useTeam';
import Card from 'modules/Employee/Organization/People/Card';
import PersonalSettingsDataSection from 'modules/Employee/shared/PersonalSettingsDataSection';
import useResolveHandler from 'shared/useResolveHandler';
import t from 'utility/setTranslation';

import styles from './SettingsPersonalData.module.scss';

const SettingsPersonalData = ({ employeeId, setIsFormDirty, onClose }) => {
  const { isLoading, data } = useQueryEmployeePrivacySettingsPerEmployee(employeeId);
  const { isLoading: isEmployeeDetailsLoading, data: employeeDetails } = useQueryEmployeeDetails(employeeId);
  const { isLoading: isMutateEmployeePrivacySettingsLoading, mutate } =
    useMutationEmployeePrivacySettingsPerEmployee(employeeId);

  const { responseStatus, setStatus } = useResolveHandler();
  const queryClient = useQueryClient();

  const employeePrivacySettings = queryClient.getQueryData(queryKeys.employeePrivacySettings);

  const {
    allowAvatarPrivacy,
    allowBirthDatePrivacy,
    allowLinkedInProfilePrivacy,
    allowPhoneNumberPrivacy,
    allowWorkEmailPrivacy,
    enableAvatarPrivacy,
    enableBirthDatePrivacy,
    enableLinkedInProfilePrivacy,
    enableWorkEmailPrivacy,
    enablePhoneNumberPrivacy,
  } = employeePrivacySettings?.data || {};

  const isFetching = Boolean(useIsFetching(queryKeys.employeePrivacySettingsPerEmployee(employeeId)));

  const apiValues = pick(data?.data || {}, [
    'enableAvatarPrivacy',
    'enableBirthDatePrivacy',
    'enableLinkedInProfilePrivacy',
    'enableWorkEmailPrivacy',
    'enablePhoneNumberPrivacy',
  ]);

  const formik = useFormik({
    initialValues: {
      enableAvatarPrivacy: allowAvatarPrivacy ? apiValues.enableAvatarPrivacy : enableAvatarPrivacy,
      enableBirthDatePrivacy: allowBirthDatePrivacy ? apiValues.enableBirthDatePrivacy : enableBirthDatePrivacy,
      enableLinkedInProfilePrivacy: allowLinkedInProfilePrivacy
        ? apiValues.enableLinkedInProfilePrivacy
        : enableLinkedInProfilePrivacy,
      enableWorkEmailPrivacy: allowPhoneNumberPrivacy ? apiValues.enableWorkEmailPrivacy : enableWorkEmailPrivacy,
      enablePhoneNumberPrivacy: enablePhoneNumberPrivacy
        ? apiValues.enablePhoneNumberPrivacy
        : enablePhoneNumberPrivacy,
    },
    onSubmit: (values) => {
      mutate(
        { employeeId, privacySettings: values },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries(queryKeys.employeePrivacySettingsPerEmployee(employeeId));
            setStatus(
              true,
              () => {
                onClose();
              },
              t('GENERAL.SAVED')
            );
          },
        }
      );
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    setIsFormDirty(formik.dirty);
  }, [formik.values]);

  if (isLoading || isEmployeeDetailsLoading) {
    return (
      <div style={{ height: '300px' }}>
        <SpinnerRing />
      </div>
    );
  }

  const aboutInputs = [
    {
      prop: 'enableAvatarPrivacy',
      name: t('EMPLOYEE.FIELDS.AVATAR'),
      value: apiValues.enableAvatarPrivacy || enableAvatarPrivacy,
      isEditAllowed: allowAvatarPrivacy,
    },
    {
      prop: 'enableBirthDatePrivacy',
      name: t('GENERAL.BIRTHDATE'),
      value: apiValues.enableBirthDatePrivacy || enableBirthDatePrivacy,
      isEditAllowed: allowBirthDatePrivacy,
    },
    {
      prop: 'enableLinkedInProfilePrivacy',
      name: t('GENERAL.LINKED_IN_PROFILE'),
      value: apiValues.enableLinkedInProfilePrivacy || enableLinkedInProfilePrivacy,
      isEditAllowed: allowLinkedInProfilePrivacy,
    },
  ];

  const contactInputs = [
    {
      prop: 'enableWorkEmailPrivacy',
      name: t('GENERAL.WORK_EMAIL'),
      value: apiValues.enableWorkEmailPrivacy || enableWorkEmailPrivacy,
      isEditAllowed: allowWorkEmailPrivacy,
    },
    {
      prop: 'enablePhoneNumberPrivacy',
      name: t('GENERAL.PHONE_NUMBER'),
      value: apiValues.enablePhoneNumberPrivacy || enablePhoneNumberPrivacy,
      isEditAllowed: allowPhoneNumberPrivacy,
    },
  ];

  const onChange = (key, newValue) => formik.setFieldValue(key, newValue);

  return (
    <>
      <ActionLoading isLoading={isMutateEmployeePrivacySettingsLoading || isFetching} responseStatus={responseStatus} />
      <form noValidate onSubmit={formik.handleSubmit} className='flex-1 d-flex flex-column'>
        <Scrollbars autoHide>
          <section className='full-border radius-8 p-4 mx-5 mb-4'>
            <h4 className='subtitle mb-0'>{t('GENERAL.HIDE_PERSONAL_DETAILS')}</h4>
            <p className='font-size-14 text-secondary'>{t('GENERAL.PERSONAL_PEF_EDIT_HINT')}</p>
            <h4 className='subtitle mt-4 mb-2'>{t('GENERAL.PERSONAL_INFO_DISPLAY')}</h4>
            <section className='d-flex justify-content-between'>
              <section>
                <PersonalSettingsDataSection
                  sectionTitle={t('GENERAL.ABOUT')}
                  inputs={aboutInputs}
                  onChange={onChange}
                  values={formik.values}
                />
                <PersonalSettingsDataSection
                  sectionTitle={t('GENERAL.CONTACT')}
                  inputs={contactInputs}
                  onChange={onChange}
                  values={formik.values}
                />
              </section>
              <section className='mt-3'>
                <Card
                  className={styles.cardWidth}
                  checked={[]}
                  enableAvatarPrivacy={formik.values.enableAvatarPrivacy}
                  enableBirthDatePrivacy={formik.values.enableBirthDatePrivacy}
                  enableLinkedInProfilePrivacy={formik.values.enableLinkedInProfilePrivacy}
                  enableWorkEmailPrivacy={formik.values.enableWorkEmailPrivacy}
                  enablePhoneNumberPrivacy={formik.values.enablePhoneNumberPrivacy}
                  canActOnEmployee={false}
                  canViewEmployeeDetails={false}
                  isPreviewMode
                  employee={employeeDetails?.data}
                />
              </section>
            </section>
          </section>
        </Scrollbars>

        <div className='d-flex justify-content-end pt-4 border-t px-5'>
          <RoundedButton type='submit' text='Save' btnStyle='contained' color='primary' size='md' />
        </div>
      </form>
    </>
  );
};

export default SettingsPersonalData;
