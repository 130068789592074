/* eslint no-console: ["error", { allow: ["warn", "error"] }] */

import NotificationsApi from 'api/NotificationsApi';
import { hasPushTokenItem, setPushTokenItem } from 'services/Store/StoreService';

import { getFirebaseToken, isFirebaseMessagingSupported } from './FirebaseService';

export const isNotificationPermissionGranted = async () => {
  try {
    const permission = await window.Notification.requestPermission();
    return permission === 'granted';
  } catch (error) {
    console.error('Error requesting notification permission:', error);
    return false;
  }
};

export const persistNotificationToken = async (token) => {
  if (!token) return;
  try {
    await NotificationsApi.putNotificationToken({ token });
  } catch (error) {
    console.error('Error persisting Firebase notification token:', error);
  }
};

export const getNotificationTokenVapid = async () => {
  try {
    const {
      data: { vapid },
    } = await NotificationsApi.getVapid();
    if (!vapid) console.error('Invalid VAPID key: ', vapid);
    return vapid;
  } catch (error) {
    console.error('Error retrieving VAPID key:', error);
    return null;
  }
};

export const getNotificationToken = async () => {
  const vapid = await getNotificationTokenVapid();
  return getFirebaseToken(vapid);
};

export const registerMessaging = () => {
  if (hasPushTokenItem()) return;

  isNotificationPermissionGranted().then((isGranted) => {
    if (!isGranted) return;
    isFirebaseMessagingSupported().then((isSupported) => {
      if (!isSupported) return;
      getNotificationToken().then((token) => {
        if (!token) return;
        persistNotificationToken(token).then(setPushTokenItem(token));
      });
    });
  });
};
