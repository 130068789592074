const PRIMARY = 'primary';
const SECONDARY = 'secondary';
const DEFAULT = 'default';
const INFO = 'info';
const SUCCESS = 'success';
const WARNING = 'warning';
const DANGER = 'danger';

// eslint-disable-next-line import/prefer-default-export
export const colorConstants = {
  PRIMARY,
  SECONDARY,
  DEFAULT,
  INFO,
  SUCCESS,
  WARNING,
  DANGER,
};
