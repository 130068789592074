/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { AuthContext } from 'auth/AuthContext';
import HeaderTitle from 'components/HeaderTitle/HeaderTitle';
import NavBar from 'components/Navigation/NavBar';
import { MODULES_CONFIGS } from 'constants/moduleConfigurations';
import AuthState from 'services/Auth/AuthState';
import { useModulePermissions } from 'shared/useModulePermissions';
import t from 'utility/setTranslation';

import { userNavigationItems } from './utility';
import styles from '../Header.module.scss';

const Header = ({ selectImage, isBillingModuleEnabled, isRecruitmentModuleEnabled }) => {
  const { authState } = useContext(AuthContext);
  const { isAdmin } = AuthState.create(authState);
  const [navigationItems, setNavigationItems] = useState(userNavigationItems(authState, isRecruitmentModuleEnabled));

  const location = useLocation();

  useEffect(() => {
    setNavigationItems(userNavigationItems(authState, isRecruitmentModuleEnabled, isBillingModuleEnabled));
  }, [authState]);

  return (
    <div className={cx(styles.content, 'position-relative')}>
      <div className='d-flex justify-content-between align-items-center pt-3'>
        <HeaderTitle className='mb-4 mt-1'>{t('GENERAL.COMPANY_SETTINGS')}</HeaderTitle>

        {location.pathname.includes('/company-settings/organization') && isAdmin && (
          <label htmlFor='background-image' className={cx(styles.uploadBtn, 'd-flex', 'px-2')}>
            <span>{t('SETTINGS.UPDATE_COVER')}</span>
            <i className={cx(styles.iconCamera, 'icon-camera', 'text-light', 'ms-2')} />
          </label>
        )}
      </div>

      <NavBar items={navigationItems} />

      <input
        type='file'
        accept='image/*'
        name='background'
        hidden
        id='background-image'
        onChange={selectImage}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
    </div>
  );
};

Header.propTypes = {
  selectImage: PropTypes.func,
};

Header.defaultProps = {
  selectImage: undefined,
};

export default Header;
