import React from 'react';

import CheckBox from 'components/forms/components/CheckBox';
import NumberInput from 'components/forms/components/NumberInput';
import t from 'utility/setTranslation';

import { PAYMENT_SCHEDULE_OPTIONS } from '../../constants';
import GoLiveOptionContainer from '../GoLiveOptionContainer/GoLiveOptionContainer';

const GoLiveSubscriptionDetails = ({ formik, title }) => (
  <section className='d-flex flex-column gap-3'>
    <h5 className='subtitle'>{t(title)}</h5>
    <section className='d-flex flex-column mb-3'>
      <section className='d-flex gap-4 mb-2'>
        {PAYMENT_SCHEDULE_OPTIONS.map((option) => (
          <GoLiveOptionContainer
            key={option.id}
            className='radius-16 flex-1'
            isChecked={formik.values.subscription.periodUnit === option.id}
          >
            <CheckBox
              name='subscription.periodUnit'
              value={option.id}
              id={option.id}
              label={t(option.nameKey)}
              checked={formik.values.subscription.periodUnit === option.id}
              error={formik.touched.subscription && formik.errors?.subscription?.periodUnit}
              onChange={(checked, name, value) => {
                formik.setFieldValue(name, value);
              }}
              onBlur={formik.handleBlur}
            />
          </GoLiveOptionContainer>
        ))}
      </section>
      {formik.touched.subscription && formik.errors.subscription?.periodUnit && (
        <div className='error'>{formik.errors.subscription?.periodUnit}</div>
      )}
    </section>
    <section>
      <NumberInput
        customClass='full-width'
        label='NUMBER_OF_USERS'
        name='subscription.numberOfUsers'
        step='1'
        isRequired
        value={formik.values.subscription.numberOfUsers}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors?.subscription?.numberOfUsers || ''}
      />
      {!formik.errors.subscription?.numberOfUsers ? (
        <p className='font-size-11 text-gray mt-0 pt-0' style={{ position: 'relative', top: '-11px' }}>
          {t('NAVBAR.NUMBER_OF_USERS_LENGTH')}
        </p>
      ) : null}
    </section>
  </section>
);

GoLiveSubscriptionDetails.defaultProps = {
  title: '',
};

export default GoLiveSubscriptionDetails;
