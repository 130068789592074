import React, { createContext, useReducer } from 'react';

const getThemeFromLS = (key) => {
  let data = window.localStorage.getItem('theme') || null;
  data = JSON.parse(data);

  if (!data) return null;
  return key in data ? data[key] : null;
};

const setThemeToLS = (key, value) => {
  let data = window.localStorage.getItem('theme') || null;
  data = JSON.parse(data) || {};

  const newValue = { ...value, timeStamp: new Date() };
  data[key] = newValue;

  window.localStorage.setItem('theme', JSON.stringify(data));
};

/* AUTH CONTEXT */
const ThemeContext = createContext();
const theme = getThemeFromLS('theme');

const initialState = { ...theme };

const themeReducer = (themeState, action) => {
  let data = {};
  switch (action.type) {
    case 'set':
      setThemeToLS('theme', {
        ...themeState,
        ...action.payload,
      });
      data = {
        ...themeState,
        ...action.payload,
      };
      break;
    case 'get':
      data = { ...themeState };
      break;
    case 'del':
      localStorage.removeItem('theme');
      break;
    default:
      break;
  }

  return data;
};

const ThemeContextProvider = (props) => {
  const themeProps = { ...props };
  const [themeState, dispatchTheme] = useReducer(themeReducer, initialState);
  const value = { themeState, dispatchTheme };
  return <ThemeContext.Provider value={value}>{themeProps ? themeProps.children : null}</ThemeContext.Provider>;
};

export { ThemeContext, ThemeContextProvider, getThemeFromLS, setThemeToLS };
