import React, { createContext, useReducer } from 'react';

const getPerformanceFromLS = (key) => {
  let data = window.localStorage.getItem('performance') || null;
  data = JSON.parse(data);
  if (!data) return null;
  return key in data ? data[key] : null;
};

const setPerformanceToLS = (key, value) => {
  let data = window.localStorage.getItem('performance') || null;
  data = JSON.parse(data) || {};
  data[key] = value;
  window.localStorage.setItem('performance', JSON.stringify(data));
};

const PerformanceContext = createContext();
const showPerformance = getPerformanceFromLS('performance');

const initialState = { ...showPerformance };

const PerformanceReducer = (performanceState, action) => {
  let data = {};
  switch (action.type) {
    case 'set':
      setPerformanceToLS('performance', {
        ...performanceState,
        ...action.payload,
      });
      data = {
        ...performanceState,
        ...action.payload,
      };
      break;
    case 'get':
      data = { ...performanceState };
      break;
    case 'del':
      localStorage.removeItem('performance');
      break;
    default:
      break;
  }

  return data;
};

const PerformanceContextProvider = (props) => {
  const performanceProps = { ...props };
  const [performanceState, dispatchPerformance] = useReducer(PerformanceReducer, initialState);

  const value = { performanceState, dispatchPerformance };

  return (
    <PerformanceContext.Provider value={value}>
      {performanceProps ? performanceProps.children : false}
    </PerformanceContext.Provider>
  );
};

export { PerformanceContext, PerformanceContextProvider, getPerformanceFromLS, setPerformanceToLS };
