import React from 'react';

import GoLiveEnvironmentSelector from '../GoLiveEnvironmentSelector/GoLiveEnvironmentSelector';
import GoLiveSubdomainAndEnvironmentTips from '../GoLiveSubdomainAndEnvironmentTips/GoLiveSubdomainAndEnvironmentTips';
import GoLiveSubdomainInput from '../GoLiveSubdomainInput/GoLiveSubdomainInput';

const GoLiveSubdomainAndEnvironmentSettings = (formik) => (
  <section className='d-flex justify-content-center mt-4 pb-4' style={{ maxWidth: '1370px' }}>
    <div className='d-flex flex-column' style={{ width: '57%' }}>
      <GoLiveSubdomainInput formik={formik} />
      <GoLiveEnvironmentSelector formik={formik} />
    </div>

    <div className='d-flex w-40 flex-column justify-content-center align-items-center '>
      <GoLiveSubdomainAndEnvironmentTips />
    </div>
  </section>
);

export default GoLiveSubdomainAndEnvironmentSettings;
