import React, { useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import OutsideClickHandler from 'hoc/OutsideClickHandler';
import t from 'utility/setTranslation';

import styles from './IconPicker.module.scss';

const IconPicker = ({ iconText, numberOfIcons, onSelectIcon, selectedIcon, icons }) => {
  const [pickerOpened, setPickerOpened] = useState(false);

  return (
    <div className='me-3 position-relative' onClick={() => setPickerOpened(true)}>
      <p className='label mb-1'>{t('GENERAL.ICON')}</p>
      <div className={cx(styles.iconBox, 'd-flex justify-content-center align-items-center')}>
        <i className={cx(selectedIcon, styles.icon)} />
      </div>
      {pickerOpened && (
        <OutsideClickHandler
          clickHandler={() => {
            setPickerOpened(false);
          }}
        >
          <div className={cx(styles.iconPicker, 'px-3', 'pt-4', 'pb-3', 'bg-white')}>
            <div className={cx(styles.icons, 'd-flex', 'flex-wrap')}>
              {numberOfIcons
                ? Array.from({ length: numberOfIcons }, (v, i) => {
                    const icon = `${iconText}-${i + 1}`;
                    return (
                      <div
                        key={icon}
                        className={cx(styles.iconItem, 'me-2', 'mb-2')}
                        onClick={(e) => {
                          e.stopPropagation();
                          onSelectIcon(icon);
                        }}
                      >
                        <i className={icon} />
                      </div>
                    );
                  })
                : icons.map((icon) => (
                    <div
                      key={icon}
                      className={cx(styles.iconItem, 'me-2', 'mb-2')}
                      onClick={(e) => {
                        e.stopPropagation();
                        onSelectIcon(icon);
                      }}
                    >
                      <i className={icon} />
                    </div>
                  ))}
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

IconPicker.propTypes = {
  iconText: PropTypes.string,
  numberOfIcons: PropTypes.number,
};

IconPicker.defaultProps = {
  iconText: undefined,
  numberOfIcons: undefined,
};

export default IconPicker;
