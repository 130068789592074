import React from 'react';

import cx from 'classnames';

import { getInitials } from 'utility/getInitials';

import styles from './PalmHrAIHumanMessage.module.scss';
import resourcePath from '../../../../shared/resourcePath';

const PalmHrAIHumanMessage = ({ sender, message, className }) => {
  const avatar = resourcePath(sender?.avatarThumb);

  return (
    <div className={cx(className, 'd-flex align-items-center full-border radius-8 py-4 px-5 mt-3')}>
      {avatar ? (
        <img src={avatar} alt='avatar' className={cx(styles.PalmHrAIHumanMessage__avatar, 'me-4')} />
      ) : (
        <div
          className={cx(
            styles.PalmHrAIHumanMessage__avatar,
            'd-flex align-items-center justify-content-center me-4 bg-primary'
          )}
        >
          <span className={cx('text-white font-size-13')}>{`${getInitials(sender.fullName)}`}</span>
        </div>
      )}
      <p style={{ color: '#284B64' }} className='font-size-13 multiline'>
        {message}
      </p>
    </div>
  );
};

export default PalmHrAIHumanMessage;
