/* eslint-disable no-cond-assign */

const findEmailInText = (text) => {
  const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gm;
  const matchResult = [];
  const links = [];
  let match;
  let newText = text;
  if (text && text.search(regex) !== -1) {
    newText = text.replace(/\n/g, ' \n');
  }
  let result = newText;

  while ((match = regex.exec(newText)) !== null) {
    if (match.index === regex.lastIndex) {
      regex.lastIndex += 1;
    }
    matchResult.push(match);
  }

  if (matchResult && matchResult.length > 0) {
    matchResult.forEach((el, index) => {
      if (el) {
        const url = el[0];
        const maper = `#${index}#`;
        links.push({ link: el[0], maper });
        result = result.replace(url, maper);
      }
    });
  }
  if (links.length > 0) {
    links.forEach((item) => {
      const a = `<a href=mailto:${item.link} target="_blank">${item.link}</a>`;
      result = result.replace(item.maper, a);
    });
  }

  return result;
};

export default findEmailInText;
