/* eslint-disable */

import React from 'react';
import utility from './utility';

const CircularLoader = ({ id, color, size, variant, className }) => {
  return <div id={id} className={utility.classes(className, color, size, variant)} />;
};

CircularLoader.propTypes = utility.propTypes();
CircularLoader.defaultProps = utility.defaultProps();

export default CircularLoader;
