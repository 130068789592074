import React, { Suspense } from 'react';

import { Navigate } from 'react-router-dom';

import AreaLoader from 'components/Spinner/AreaLoader';
import Recruitment from 'modules/Recruitment/Recruitment';
import Statistics from 'modules/Recruitment/Statistics/Statistics';
import CustomRoute from 'topNavigation/Router/CustomRoute';
import PrivateRoute from 'topNavigation/Router/PrivateRoute';
import { PrivateRoutes } from 'topNavigation/Router/routes';

const Candidates = React.lazy(() => import('modules/Recruitment/Candidates'));
const AllJobs = React.lazy(() => import('modules/Recruitment/Jobs/components/AllJobs/AllJobs'));
const JobPostDetails = React.lazy(() => import('modules/Recruitment/Jobs/components/JobPostDetails/JobPostDetails'));
const CandidatesJobApplications = React.lazy(() =>
  import('modules/Recruitment/Jobs/components/CandidatesJobApplications/CandidatesJobApplications')
);
const Jobs = React.lazy(() => import('modules/Recruitment/Jobs'));

const useRecruitmentRoutes = () =>
  // TODO: uncomment this lines when find an idea of how to call APIs outside the router without make the router screens flicker
  // const { isInitialLoading, isModuleEnabled, enabledFeatures } = useModulePermissions(MODULES_CONFIGS.RECRUITMENT.NAME);
  // const isCandidatesFeatureEnabled = enabledFeatures?.includes(MODULES_CONFIGS.RECRUITMENT.FEATURES.CANDIDATES);

  // if (isInitialLoading) {
  //   return [
  //     {
  //       path: `${PrivateRoutes.recruitment}/*`,
  //       element: <PrivateRoute path={PrivateRoutes.recruitment} component={InitLoading} />,
  //     },
  //   ];
  // }

  // if (isModuleEnabled) {
  [
    {
      path: PrivateRoutes.recruitment,
      element: <PrivateRoute path={PrivateRoutes.recruitment} component={Recruitment} />,
      children: [
        {
          path: PrivateRoutes.recruitmentJobs,
          element: (
            <Suspense fallback={<AreaLoader />}>
              <CustomRoute path={PrivateRoutes.recruitmentJobs} component={Jobs} />
            </Suspense>
          ),
          children: [
            {
              path: PrivateRoutes.jobDetails,
              element: (
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path={PrivateRoutes.jobDetails} component={JobPostDetails} />
                </Suspense>
              ),
              children: [
                {
                  path: PrivateRoutes.jobDetailsCandidates,
                  element: (
                    <Suspense fallback={<AreaLoader />}>
                      <CustomRoute path={PrivateRoutes.jobDetailsCandidates} component={CandidatesJobApplications} />
                    </Suspense>
                  ),
                },
                {
                  path: PrivateRoutes.jobDetailsStatistics,
                  element: <CustomRoute path={PrivateRoutes.jobDetailsStatistics} component={Statistics} />,
                },
                {
                  path: '',
                  element: <Navigate to='candidates' replace />,
                },
              ],
            },
            {
              path: '',
              element: (
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path={PrivateRoutes.recruitmentJobs} component={AllJobs} />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: PrivateRoutes.recruitmentCandidates,
          element: (
            <Suspense fallback={<AreaLoader />}>
              <CustomRoute path={PrivateRoutes.recruitmentCandidates} hidePage={false} component={Candidates} />
            </Suspense>
          ),
        },
        {
          path: '',
          element: <Navigate to={PrivateRoutes.recruitmentJobs} replace />,
        },
      ],
    },
  ];
// }

// return [
//   {
//     path: `${PrivateRoutes.recruitment}/*`,
//     element: <Navigate to={PublicRoutes.error403} replace />,
//   },
// ];
export default useRecruitmentRoutes;
