import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Line from './Line';
import styles from './SkeletonCalendar.module.scss';

const SkeletonCalendar = ({ gradient }) => (
  <div className={cx(styles.wrapper, 'p-4')}>
    <Line style={{ height: 12, width: 100 }} gradient={gradient} />
    <div className='pt-2 pb-4 border-btm mb-4'>
      <div className={cx(styles.box, gradient)} />
    </div>
    <Line style={{ height: 12, width: 96 }} gradient={gradient} />
    <Line style={{ height: 12, width: 100 }} gradient={gradient} />
    <Line style={{ height: 12, width: 100 }} gradient={gradient} />
  </div>
);

SkeletonCalendar.propTypes = {
  gradient: PropTypes.string,
};

SkeletonCalendar.defaultProps = {
  gradient: '',
};

export default SkeletonCalendar;
