import React from 'react';

import cx from 'classnames';

import IconButton from 'components/buttons/IconButton/IconButton';
import Search from 'components/forms/components/Search';
import Tooltip from 'components/tooltip/Tooltip';
import stringUtil from 'utility/stringUtil';

import helper from './helper';
import styles from './Table.module.scss';

const TableToolbar = React.forwardRef((props, ref) => {
  const {
    tableId,
    onSearch,
    onColumnsModalOpen,
    onRowDensityModalOpen,
    description,
    searchValue,
    searchable,
    controlColumns,
    sticky,
    title,
    loading,
    size,
    controlDensity,
    xScroll,
    isXScrollable,
    controlFilters,
    onFiltersModalOpen,
  } = props;

  const { tlbRef, tcRef } = helper.prepareRefs(ref).current;

  return (
    <div
      ref={tlbRef}
      id={helper.genId(tableId, helper.components().toolbar)}
      className={cx(styles.toolbar, sticky ? styles.sticky : '', `pb-3 pt-3`)}
    >
      {title && <div className={cx(styles.title, 'me-2')}>{stringUtil.capitalize(title)}</div>}

      {description && (
        <div className={cx(styles.description, 'me-2 d-flex align-items-center me-2')}>
          <Tooltip label={description} position='right' useTranslate className='text-start'>
            <i className='icon-info text-gray' />
          </Tooltip>
        </div>
      )}

      <div className={cx(styles.grow)} />

      <div className='d-flex align-items-center'>
        {searchable && (
          <Search
            onChange={(e) => helper.onTSearch({ id: tableId, value: e.target.value }, onSearch)}
            placeholder='Search people'
            value={searchValue}
            onClear={() => helper.onTSearch({ id: tableId, value: '' }, onSearch)}
            readOnly={loading}
            iconSize={size}
            autofocus
          />
        )}

        {controlFilters && (
          <IconButton
            className='me-2'
            icon='icon-filter'
            onClick={() => helper.onTFiltersModalOpen({ open: true, id: tableId }, onFiltersModalOpen)}
            size={size}
            isDisabled={loading}
          />
        )}

        {controlColumns && (
          <IconButton
            onClick={() => helper.onTColumnsModalOpen({ open: true, id: tableId }, onColumnsModalOpen)}
            icon='icon-grid--customize-columns'
            size={size}
            color='gray'
            className='me-2'
            isDisabled={loading}
          />
        )}

        {controlDensity && (
          <IconButton
            icon='icon-row-density'
            onClick={() => helper.onTRowDensityModalOpen({ open: true, id: tableId }, onRowDensityModalOpen)}
            size={size}
            isDisabled={loading}
            className='me-2'
          />
        )}

        {xScroll && (
          <>
            <IconButton
              icon='icon-chevron-left'
              size={size}
              className='me-2'
              isDisabled={loading || !isXScrollable}
              onClick={() => helper.xScroll(tcRef, 'right')}
            />

            <IconButton
              icon='icon-chevron-right'
              size={size}
              isDisabled={loading || !isXScrollable}
              onClick={() => helper.xScroll(tcRef, 'left')}
            />
          </>
        )}
      </div>
    </div>
  );
});

TableToolbar.defaultProps = {
  tableId: 'table-id',
  title: '',
  description: '',
  searchable: false,
  size: 'sm',
  loading: false,
  controlColumns: false,
  controlDensity: false,
  onSearch: null,
  onColumnsModalOpen: null,
  onRowDensityModalOpen: null,
  searchValue: '',
  sticky: false,
  xScroll: false,
  isXScrollable: false,
  controlFilters: false,
  onFiltersModalOpen: null,
};

export default TableToolbar;
