import React from 'react';

import PropTypes from 'prop-types';

import SkeletonProfile from '../../../../EngagementCenter/shared/loaders/SkeletonProfile';

const profileStyle = {
  image: {
    width: '32px',
    height: '32px',
  },
  line1: {
    width: 94,
    height: 10,
  },
  line2: {
    width: 63,
    height: 10,
  },
};

const ListSkeleton = ({ gradient, numOfItems }) => {
  const element = <SkeletonProfile gradient={gradient} style={profileStyle} />;
  const items = [];
  for (let i = 0; i < numOfItems; i += 1) {
    items.push(
      <li key={Math.random()} className='border-btm py-3 px-2a'>
        {element}
      </li>
    );
  }
  return <ul className='no-style'>{items}</ul>;
};

ListSkeleton.propTypes = {
  gradient: PropTypes.string.isRequired,
  numOfItems: PropTypes.number.isRequired,
};

export default ListSkeleton;
