import React, { useContext } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { AuthContext } from 'auth/AuthContext';
import HeaderTitle from 'components/HeaderTitle/HeaderTitle';
import NavBar from 'components/Navigation/NavBar';
import t from 'utility/setTranslation';

const Header = ({ onSelectView, canSeeTimesheets, canSeeApproval, shiftPermissions, canViewBillableTime }) => {
  const { authState } = useContext(AuthContext);

  const navItems = [
    { title: 'leave', href: '/time/leave' },
    { title: 'calendar', href: '/time/calendar' },
  ];

  if (canViewBillableTime) {
    navItems.push({ title: 'billable time', href: '/time/billable-time' });
  }

  if (canSeeTimesheets) {
    navItems.push({ title: 'timesheets', href: '/time/timesheets' });
  }

  if (canSeeApproval) {
    navItems.push({ title: 'approval', href: '/time/approval' });
  }

  if (shiftPermissions?.canAccessShiftManager) {
    navItems.push({ title: 'shift-schedule', href: '/time/shift-schedule' });
  }

  if (authState.user.roles.includes('ROLE_HR') || authState.user.roles.includes('ROLE_ADMIN')) {
    navItems.push({ title: 'insights', href: '/time/insights' });
  }

  return (
    <div className='pt-2'>
      <div className={cx('position-relative', 'pb-3')}>
        <div className='d-flex justify-content-between align-items-center pt-3'>
          <HeaderTitle className='mb-4 mt-1'>{t('GENERAL.TIME')}</HeaderTitle>
        </div>
        <NavBar items={navItems} onSelectView={onSelectView} type='link' />
      </div>
    </div>
  );
};

Header.propTypes = {
  onSelectView: PropTypes.func.isRequired,
  canSeeTimesheets: PropTypes.bool,
  canSeeApproval: PropTypes.bool,
  shiftPermissions: PropTypes.object,
};

Header.defaultProps = {
  canSeeTimesheets: false,
  canSeeApproval: false,
  shiftPermissions: null,
};

export default Header;
