/* global google */
import React, { useEffect, useState } from 'react';

const Marker = (options) => {
  const [marker, setMarker] = useState(null);
  const [infowindow, setInfowindow] = useState(null);
  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (!infowindow) {
      setInfowindow(
        new google.maps.InfoWindow({
          content: options.msgInfo,
        })
      );
    }
  }, [infowindow]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);

      ['click'].forEach((eventName) => google.maps.event.clearListeners(options.map, eventName));

      marker.addListener('click', () => {
        infowindow.open({
          anchor: marker,
          map: options.map,
          shouldFocus: false,
        });
      });

      if (options?.showInfoWindow) {
        google.maps.event.trigger(marker, 'click');
      }
    }
  }, [marker, options]);

  return null;
};

export default React.memo(Marker);
