function formatRangesUTC(ranges, color) {
  if (ranges && ranges.length) {
    return ranges.map((range) => {
      const startDate = new Date(range.startDate);
      const endDate = new Date(range.endDate);

      const normalizedStartDate = new Date(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate());
      const normalizedEndDate = new Date(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate());

      return {
        startDate: normalizedStartDate,
        endDate: normalizedEndDate,
        key: `selection${range.id}`,
        showDateDisplay: false,
        disabled: true,
        autoFocus: false,
        color,
        data: range,
      };
    });
  }
  return [];
}

export default formatRangesUTC;
