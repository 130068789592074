import React, { useEffect } from 'react';

import { PHRDataTable } from '@palmhr/palmhr-ui-lib';

import icon from 'assets/img/empty-dolar.png';
import TableHead from 'components/DataTable/TableHead';
import EmptyState from 'components/EmptyPages/EmptyState';

import InvoiceDetailContainer from './InvoiceDetail';
import InvoiceTableRow from './InvoiceTableRow';
import { useMutatePlgInvoicePreview } from '../../../../../../hooks/useAccount';
import {
  ENVIRONMENT_PLAN_VALUES,
  minAllowedNumberOfUsers,
  ORDER_SUMMARY_COLUMNS,
  orderSummaryInitialValues,
} from '../../constants';
import { adaptValuesForAPI, getOrderSummaryRowsData } from '../../utility';

const GoLiveOrderSummary = ({ formik }) => {
  const [invoicePreview, setInvoicePreview] = React.useState(orderSummaryInitialValues);

  const { mutate, isLoading } = useMutatePlgInvoicePreview();

  const invoiceCurrency = formik.values.subscription.currency;

  const adaptedInvoicePreview = adaptValuesForAPI(formik.values);
  useEffect(() => {
    if (
      formik.values.payment &&
      formik.values.subscription.numberOfUsers &&
      formik.values.subscription.numberOfUsers >= minAllowedNumberOfUsers &&
      formik.values.subscription.periodUnit
    ) {
      mutate(
        {
          ...adaptedInvoicePreview,
          continueWith: ENVIRONMENT_PLAN_VALUES.EXISTING_DATA,
        },
        {
          onSuccess: (dataInvoice) => {
            setInvoicePreview(dataInvoice.data);
          },
        }
      );
    }
  }, [formik.values.payment, formik.values.subscription, mutate]);

  const invoiceRowsData = getOrderSummaryRowsData(invoicePreview, invoiceCurrency);

  if (invoicePreview === orderSummaryInitialValues) {
    return (
      <EmptyState icon={icon} title='GENERAL.EMPTY_INVOCIE' subtitle='GENERAL.EMPTY_INVOCIE_DESC' showBtn={false} />
    );
  }

  return (
    <section>
      <PHRDataTable
        isLoading={isLoading}
        data={invoiceRowsData}
        TableHeader={TableHead}
        TableRowComponent={InvoiceTableRow}
        columns={ORDER_SUMMARY_COLUMNS}
        rowKey='id'
        rowAdditionalProps={{}}
        headerAdditionalProps={{
          className: 'mb-0',
          style: {
            borderBottom: '1px solid #E9EDEF',
          },
        }}
        isScroll={false}
      />
      <InvoiceDetailContainer invoicePreview={invoicePreview} invoiceCurrency={invoiceCurrency} />
    </section>
  );
};

export default GoLiveOrderSummary;
