import * as yup from 'yup';

import t from 'utility/setTranslation';

export const configFormValidationMessageLocalization = () => {
  yup.setLocale({
    string: {
      /* eslint-disable no-template-curly-in-string */
      max: t('GENERAL.MAX_VALUE_ERROR'),
    },
    number: {
      positive: t('GENERAL.AMOUNT_CANNOT_BE_NEGATIVE'),
    },
    mixed: {
      required: t('GENERAL.REQUIRED_FIELD'),
    },
  });
};
