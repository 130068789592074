import React, { useEffect, useContext } from 'react';

import { useLocation } from 'react-router-dom';

import welcomeImage from 'assets/img/login_bg.png';
import { AuthContext } from 'auth/AuthContext';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import Modal from 'components/Modal';
import { useVisibility } from 'shared/useVisibility';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

import { useAccountSubscriptionData } from '../../../../hooks/useAccount';

const QuickstartWelcomeMessage = ({ openResourceCenter, setShowQuickstartChecklist }) => {
  const { isOpen, open, close } = useVisibility();
  const { data: accountSubscriptionData } = useAccountSubscriptionData();
  const { authState } = useContext(AuthContext);

  const employeeName = readName(authState.user.language, authState.user?.fullName, authState.user?.fullNameArabic);
  const location = useLocation();
  const currentUrl = location.pathname;

  const isDashboard = currentUrl === '/dashboard';
  const isHR = authState.user.roles.includes('ROLE_HR');
  const isAdmin = authState.user.roles.includes('ROLE_ADMIN');
  const isFirstLogin = accountSubscriptionData?.data?.isFirstLogin ?? false;

  const handleCloseModal = () => {
    close();
    openResourceCenter();
    setShowQuickstartChecklist(true);
    localStorage.setItem('quickstartMessageShown', 'true');
  };

  useEffect(() => {
    const hasShownMessage = localStorage.getItem('quickstartMessageShown') === 'true';

    if ((isHR || isAdmin) && isFirstLogin && isDashboard && !hasShownMessage) {
      open();
    } else if (!isFirstLogin) {
      localStorage.setItem('quickstartMessageShown', 'false');
    }
  }, [isHR, isAdmin, isFirstLogin, isDashboard, open]);

  return (
    <Modal
      styles={{
        width: '640px',
        padding: '24px 48px',
        height: '640px',
      }}
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={false}
      showHeader={false}
    >
      <section className='d-flex flex-column align-items-center justify-content-center' style={{ height: '640px' }}>
        <div className='d-flex flex-column align-items-center'>
          <img src={welcomeImage} alt='Welcome' style={{ width: '320px', height: '320px' }} />
          <div className='d-flex flex-column align-items-center mb-8 mt-4'>
            <h5 className='subtitle font-size-20'>{t('GENERAL.WELCOME')}</h5>
            <p className='titleInfo text-gray-500 font-size-16 text-center' style={{ maxWidth: '65%' }}>
              {t('GENERAL.QUICKSTART_WELCOME_MESSAGE', { employeeName })}
            </p>
          </div>
          <RoundedButton text='Lets go' color='primary' onClick={handleCloseModal} />
        </div>
      </section>
    </Modal>
  );
};

export default QuickstartWelcomeMessage;
