import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Tooltip from 'components/tooltip/Tooltip';

import styles from './DayItem.module.scss';

const DayItem = ({ day, active }) => {
  let label = '';

  if (active) {
    label = active.isFlexible
      ? `Flexible: ${active.endTime} hrs`
      : `Fixed: ${active.startTime} - ${active.endTime} hrs`;
  }

  const content = (
    <div>
      <div className='m-auto d-flex justify-content-center align-items-center'>
        {active && active.startTime ? (
          <i
            className={cx(styles.iconChecked, { [styles.flexible]: active.isFlexible }, 'icon-checkmark', 'text-light')}
          />
        ) : (
          <i className='icon-circle-x' />
        )}
      </div>
      <p className={cx(styles.day, 'my-1', 'text-center', 'text-capitalize', 'text-gray')}>{day.charAt(0)}</p>
      <p className={cx(styles.hours, 'mb-1', 'text-center', 'text-gray')}>
        {active?.seconds ? `${active.seconds / 3600}h` : '0h'}
      </p>
    </div>
  );

  return (
    <div className={cx(styles.wrapper, 'me-2a')}>
      {active?.startTime ? (
        <Tooltip label={label} position='top'>
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </div>
  );
};

DayItem.propTypes = {
  day: PropTypes.string.isRequired,
  active: PropTypes.object,
};

DayItem.defaultProps = {
  active: undefined,
};

export default DayItem;
