import React, { useState, useEffect, useContext, Suspense, lazy } from 'react';

import cx from 'classnames';
import upperCase from 'lodash/upperCase';
import { matchPath, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import EngagementCenterApi from 'api/EngagementCenterApi';
import { AuthContext } from 'auth/AuthContext';
import AreaLoader from 'components/Spinner/AreaLoader';
import useDebounce from 'shared/useDebounce';
import queryParamsUtil from 'utility/queryParamsUtil';

import Header from './Header';
import styles from './Requests.module.scss';

const RequestsKind = lazy(() => import('./RequestsKind'));

let isInitRequest = true;
let isInitRequestAll = true;
const defaultPag = queryParamsUtil.defaultPagination();

const Requests = () => {
  const { authState } = useContext(AuthContext);
  const { checkPrivileges } = authState;
  const path = '/requests';
  const location = useLocation();

  const matchType = matchPath(
    {
      path: '/requests/:requestKind/:requestType',
      exact: true,
      strict: false,
    },
    location.pathname
  );
  const requestKind = matchType?.params?.requestKind || 'my_requests';
  const requestType = matchType?.params?.requestType || 'pending';
  const customRoutePath = path.replace(`${path}`, `/requests/${requestKind}`);

  const [activeFilter, setActiveFilter] = useState('all');
  const [searchSelectValue, setSearchSelectValue] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 350);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingType, setLoadingType] = useState(false);
  const [typeData, setTypeData] = useState({});
  const [loadingDuration, setLoadingDuration] = useState(false);
  const [durationData, setDurationData] = useState({});
  const [requests, setRequests] = useState({ items: [], pagination: defaultPag });
  const [requestsInit, setRequestsInit] = useState({
    items: [],
    pagination: defaultPag,
  });
  const [loadingWizard, setLoadingWizard] = useState(true);

  const getRequests = (filterStatus = '', sortParams = '', pagination) => {
    setLoadingData(true);
    const perPage = pagination?.perPage || defaultPag?.perPage || 10;
    const currentPage = pagination?.currentPage || defaultPag?.currentPage || 1;
    let params = `perPage=${perPage}${sortParams}`;

    const filters = [];
    if (requestType) {
      if (requestType === 'finished') {
        filters.push({
          field: 'r.status',
          type: 'hasOne',
          value: ['approved', 'rejected', 'canceled', 'completed'],
        });
      } else {
        filters.push({
          field: 'r.status',
          type: 'eq',
          value: upperCase(requestType),
        });
      }
    }

    if (requestKind === 'all_requests') {
      if (searchValue) {
        params += `&search=${encodeURIComponent(searchValue)}`;
      }
    } else if (searchSelectValue.length > 0) {
      const arr = [];
      searchSelectValue.map((el) => arr.push(el.value));

      filters.push({
        field: 'r.type',
        type: 'hasOne',
        value: arr,
      });
    }

    let sentFilter = '?filters=';
    if (filters.length > 0) {
      sentFilter += encodeURIComponent(JSON.stringify(filters));
    }

    if (filters.length > 0) {
      sentFilter += `&currentPage=${currentPage}&${params}`;
    } else {
      sentFilter = `?currentPage=${currentPage}&${params}`;
    }

    const type = filterStatus === 'all' ? '' : filterStatus === 'time' ? 'time-off' : filterStatus;

    if (requestKind === 'my_requests') {
      EngagementCenterApi.getMyRequests(sentFilter, type)
        .then((res) => {
          setRequests(res.data);
          setLoadingData(false);
          isInitRequest = false;
        })
        .catch(() => {
          setLoadingData(false);
          isInitRequest = false;
        });
    } else {
      EngagementCenterApi.getAllRequests(sentFilter, type)
        .then((res) => {
          setRequests(res.data);

          if (isInitRequestAll) {
            setRequestsInit(res.data);
          }

          setLoadingData(false);
          isInitRequest = false;
          isInitRequestAll = false;
        })
        .catch(() => {
          setLoadingData(false);
          isInitRequest = false;
        });
    }
  };

  const getAllRequestsByStatus = (status) => {
    setLoadingType(true);

    EngagementCenterApi.getRequestsGroupByStatus(status)
      .then((res) => {
        setTypeData(res.data);
        setLoadingType(false);
      })
      .catch(() => {
        setLoadingType(false);
      });
  };

  const getAllRequestsByDuration = (status) => {
    setLoadingDuration(true);
    EngagementCenterApi.getRequestsDurationByStatus(status)
      .then((res) => {
        setDurationData(res.data);
        setLoadingDuration(false);
      })
      .catch(() => {
        setLoadingDuration(false);
      });
  };

  const sortEmployees = (option, isAsc) => {
    let sort = `&direction=${isAsc ? 'asc' : 'desc'}`;

    if (option === 'created') {
      sort += '&sort=r.createdAt';
    }

    getRequests(activeFilter, sort);
  };

  const getChartData = () => {
    getAllRequestsByStatus(requestType !== 'pending' ? 'finished' : 'pending');

    getAllRequestsByDuration(requestType !== 'pending' ? 'finished' : 'pending');
  };

  useEffect(() => {
    setLoadingWizard(true);
    EngagementCenterApi.getSetupData()
      .then(() => {
        getRequests(activeFilter, '');

        if (requestKind?.includes('all')) {
          getChartData();
        }

        setLoadingWizard(false);
      })
      .catch(() => {
        setLoadingWizard(false);
      });

    const body = document.querySelector('body');
    body.classList.add('no-scroll');
    return () => {
      body.classList.remove('no-scroll');

      isInitRequest = true;
      isInitRequestAll = true;
    };
  }, []);

  useEffect(() => {
    if (!isInitRequest && requestKind && requestType) {
      getRequests(activeFilter, '');
    }
  }, [requestKind, requestType, debouncedSearchTerm, searchSelectValue]);

  useEffect(() => {
    isInitRequestAll = true;

    if (!isInitRequest && requestKind?.includes('all')) {
      getChartData();
    }
  }, [requestKind, requestType]);

  return (
    <div className={cx('pageContainer page-top-positioning position-relative px-1')}>
      <Header active={requestKind} />

      {loadingWizard ? (
        <div className={cx(styles.content, 'panel', 'position-relative')}>
          <AreaLoader />
        </div>
      ) : (
        <>
          <Routes>
            <Route
              path={':requestKind/*'}
              element={
                <Suspense fallback={<AreaLoader />}>
                  {checkPrivileges(customRoutePath) ? (
                    <RequestsKind
                      activeFilter={activeFilter}
                      setActiveFilter={setActiveFilter}
                      requests={requests}
                      getRequests={getRequests}
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                      searchSelectValue={searchSelectValue}
                      setSearchSelectValue={setSearchSelectValue}
                      sortEmployees={sortEmployees}
                      loadingData={loadingData}
                      isInit={isInitRequest}
                      requestType={requestType}
                      loadingType={loadingType}
                      typeData={typeData}
                      loadingDuration={loadingDuration}
                      durationData={durationData}
                      getChartData={getChartData}
                      requestsInit={requestsInit}
                    />
                  ) : (
                    <Navigate to='/403' />
                  )}
                </Suspense>
              }
            />

            <Route path='' element={<Navigate to='my_requests/pending' replace />} />
          </Routes>
        </>
      )}
    </div>
  );
};

export default Requests;
