import React from 'react';

import PropTypes from 'prop-types';

import png404 from 'assets/img/404.png';
import t from 'utility/setTranslation';

import RoundedButton from '../buttons/RoundedButton/RoundedButton';

const DeletedContent = ({ onClose }) => (
  <div className='d-flex justify-content-center align-items-center px-7 py-6' data-testid='deletedContent'>
    <div className='d-flex flex-column align-items-center'>
      <img src={png404} alt='404' style={{ width: 200, height: 'auto' }} />
      <p className='subtitle text-center fs-20 mt-5 mb-2'>{t('GENERAL.PAGE_NOT_FOUND')}</p>
      <p className='text-steal mb-5 text-center'>{t('GENERAL.CONTENT_NOT_FOUND_DESC')}</p>
      <RoundedButton onClick={onClose} color='primary' text='Got it' />
    </div>
  </div>
);

DeletedContent.propTypes = { onClose: PropTypes.func.isRequired };

export default DeletedContent;
