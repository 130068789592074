import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

const PageWrapper = ({ children }) => (
  <div className={cx('pageContainer page-top-positioning', 'position-relative')}>{children}</div>
);

PageWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default PageWrapper;
