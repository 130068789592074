import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import OutsideClickHandler from 'hoc/OutsideClickHandler';
import t from 'utility/setTranslation';

import styles from './MoreActions.module.scss';

const link = cx(styles.link, 'd-flex align-items-center px-3 py-2');

const MoreActions = ({ onClose, onAction, data, style, types, renderCustomItem, className, itemKey }) => {
  const onClickHandler = (e, action) => {
    e.preventDefault();
    e.stopPropagation();
    onAction(action);
    onClose();
  };

  // upload file
  const onAdd = (e, item) => {
    e.persist();
    if (e.target.files && e.target.files.length > 0) {
      onAction(item, e.target.files[0]);
      onClose();
    }
  };

  return (
    <OutsideClickHandler clickHandler={onClose}>
      <ul
        className={cx(styles.editMenu, className, 'radius-4 bg-white py-2 px-0 no-style overflow-auto')}
        style={style}
      >
        {data &&
          data.map((item, i) =>
            types && types[i] === 'upload-file' ? (
              <li key={item.action} className={styles.listItem}>
                <div className={link}>
                  <label id='file-label' htmlFor='upload-file' className='cursor-pointer w-100 mb-0'>
                    {data?.length > 0 && <i className={cx(item.icon, 'text-gray', 'me-2')} />}
                    <span> {t(item.title)}</span>
                  </label>
                  <input type='file' name='file' hidden id='upload-file' onChange={(e) => onAdd(e, item.action)} />
                </div>
              </li>
            ) : renderCustomItem ? (
              <li
                onClick={(e) => {
                  onClickHandler(e, item);
                }}
                key={item[itemKey] || item}
                className={styles.listItem}
              >
                {renderCustomItem(item, { itemDefaultClassName: link })}
              </li>
            ) : (
              <li
                key={item.action}
                className={styles.listItem}
                onClick={(e) => {
                  onClickHandler(e, item.action);
                }}
              >
                <div className={cx(link, item.action.toLowerCase().includes('delete') ? 'text-danger' : '')}>
                  {item?.icon && (
                    <i
                      className={cx(
                        item.icon,
                        item.action.toLowerCase().includes('delete') ? 'text-danger' : 'text-gray',
                        'me-2'
                      )}
                    />
                  )}
                  {t(item.title)}
                </div>
              </li>
            )
          )}
      </ul>
    </OutsideClickHandler>
  );
};

MoreActions.propTypes = {
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, nameArabic: PropTypes.string }),
    ])
  ),
  style: PropTypes.object,
  types: PropTypes.arrayOf(PropTypes.object),
  renderCustomItem: PropTypes.func,
};

MoreActions.defaultProps = {
  onClose: undefined,
  onAction: undefined,
  data: [],
  style: {},
  types: [],
  renderCustomItem: null,
};

export default React.memo(MoreActions);
