import React, { useEffect, useState } from 'react';

import GoLivePaymentTerms from './GoLivePaymentTerms/GoLivePaymentTerms';
import PaymentSuccess from './PaymentSuccess';
import PayWithStripe from './PayWithStripe';
import { useIsPaymentComplete } from '../../../../../../hooks/useAccount';
import { PAYMENT_TYPE_OPTIONS, PAYMENT_TYPE_VALUES } from '../../constants';
import OptionSelector from '../GoLiveOptionSelector/GoLiveOptionSelector';

const GoLivePayment = ({ formik }) => {
  const [selectedPaymentType, setSelectedPaymentType] = useState(formik.values.payment);
  const { data, isLoading } = useIsPaymentComplete();
  const paymentSuccess = data?.data?.success;
  const shouldShowPayWithStripe = !isLoading && !paymentSuccess;

  const handlePaymentType = (value) => {
    setSelectedPaymentType(value);
    formik.values.payment = value;
    formik.setFieldTouched('payment', true);
  };

  useEffect(() => {
    if (selectedPaymentType === PAYMENT_TYPE_VALUES.OFFLINE_PAYMENT) {
      formik.setFieldValue('isPaymentComplete', true);
      formik.setFieldTouched('isPaymentComplete', true);
    } else if (selectedPaymentType === PAYMENT_TYPE_VALUES.ONLINE_PAYMENT && paymentSuccess) {
      formik.setFieldValue('isPaymentComplete', true);
      formik.setFieldTouched('isPaymentComplete', true);
    } else {
      formik.setFieldValue('isPaymentComplete', false);
      formik.setFieldTouched('isPaymentComplete', false);
    }
  }, [selectedPaymentType, formik.values.payment, formik.values.isPaymentComplete, paymentSuccess]);

  return (
    <section className='d-flex flex-column gap-3'>
      <GoLivePaymentTerms formik={formik} />
      <div className='d-flex flex-1 gap-5 justify-content-between '>
        <OptionSelector
          options={PAYMENT_TYPE_OPTIONS}
          selectedOption={selectedPaymentType}
          handleOptionChange={handlePaymentType}
          error={formik.touched?.payment && formik.errors?.payment}
          onBlur={formik.handleBlur}
        />
      </div>
      {selectedPaymentType === PAYMENT_TYPE_VALUES.ONLINE_PAYMENT &&
        (shouldShowPayWithStripe ? <PayWithStripe formik={formik} /> : <PaymentSuccess />)}
    </section>
  );
};

export default GoLivePayment;
