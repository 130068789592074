import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { AuthContext } from 'auth/AuthContext';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

import styles from './StatusMenu.module.scss';

const StatusMenu = ({ className, statuses, onSelect }) => {
  const { authState } = useContext(AuthContext);
  const [statusGrouped, setStatusGrouped] = useState({ active: [], done: [] });

  useEffect(() => {
    const obj = { active: [], done: [] };

    statuses.forEach((el) => {
      if (el.type === 'ACTIVE') {
        obj.active.push(el);
      } else {
        obj.done.push(el);
      }
    });

    setStatusGrouped(obj);
  }, []);

  return (
    <div className={cx(styles.wrapper, className)}>
      <ul className='no-style py-2 mb-0'>
        <li className={styles.listLabel}>{t('GENERAL.ACTIVE')}</li>
        {statusGrouped.active.map((status) => (
          <li
            key={status.id}
            onClick={() => {
              onSelect(status.id);
            }}
            className={cx(styles.listItem, 'd-flex justify-content-start align-items-center')}
          >
            <i className={cx(styles.i, 'icon-circle-checked', 'me-2')} />
            <span>{readName(authState.user.language, status.name, status.nameArabic)}</span>
          </li>
        ))}

        <li className={styles.listLabel}>{t('GENERAL.DONE')}</li>
        {statusGrouped.done.map((status) => (
          <li
            key={status.id}
            onClick={() => {
              onSelect(status.id);
            }}
            className={cx(styles.listItem, 'd-flex justify-content-start align-items-center')}
          >
            <i className={cx(styles.i, 'icon-circle-checked', 'me-2')} />
            <span>{readName(authState.user.language, status.name, status.nameArabic)}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

StatusMenu.propTypes = {
  statuses: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
};

StatusMenu.defaultProps = {
  className: '',
};

export default StatusMenu;
