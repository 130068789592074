import React from 'react';

import utility from './utility';

const List = ({ id, raised, children, style, className }) => (
  <ul id={id} className={utility.classes(raised, className)} style={style}>
    {children}
  </ul>
);

List.propTypes = utility.propTypes();
List.defaultProps = utility.defaultProps();

export default List;
