import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import formatKey from 'utility/formatKey';

import styles from './NavTab.module.scss';

const NavTab = ({ text, onClick, active, showNumber, number, showError, type, href }) => {
  const navLinkClass = cx(styles.tab, 'btn rounded-pill', {
    [styles.error]: showError,
  });

  const elementMap = {
    button: (
      <button
        onClick={() => {
          onClick(text);
        }}
        type='button'
        className={cx(
          styles.tab,
          'btn rounded-pill',
          {
            [styles.active]: active,
          },
          {
            [styles.error]: showError,
          }
        )}
      >
        <div className='d-flex align-items-center'>
          <span>{formatKey('GENERAL', text)}</span>

          {showNumber && <span className={cx(styles.number, 'ms-1')}>{`(${number})`}</span>}

          {showError && <i className={cx(styles.showError, 'icon-alert-triangle ms-1')} />}
        </div>
      </button>
    ),
    link: (
      <NavLink to={href} className={({ isActive }) => (isActive ? cx(styles.active, navLinkClass) : navLinkClass)}>
        <div className='d-flex align-items-center'>
          <span>{formatKey('GENERAL', text)}</span>

          {showNumber && <span className={cx(styles.number, 'ms-1')}>{`(${number})`}</span>}

          {showError && <i className={cx(styles.showError, 'icon-alert-triangle ms-1')} />}
        </div>
      </NavLink>
    ),
  };

  return elementMap[type];
};

NavTab.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  showNumber: PropTypes.bool,
  number: PropTypes.number,
  showError: PropTypes.bool,
  type: PropTypes.string,
  href: PropTypes.string,
};

NavTab.defaultProps = {
  onClick: undefined,
  active: false,
  number: 0,
  showNumber: false,
  showError: false,
  type: 'button',
  href: '#',
};

export default NavTab;
