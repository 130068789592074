import { useMemo } from 'react';

import { PropTypes } from 'prop-types';

const usePagination = ({ perPage, pages, currentPage }) => {
  const rangeHandler = (start, end) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => i + start);
  };
  const firstPage = 1;
  const lastPage = pages;
  const siblings = 1;
  const range = 4;

  const paginationRange = useMemo(() => {
    const totalPageNumbers = siblings + range;

    if (totalPageNumbers >= lastPage) {
      return rangeHandler(1, lastPage);
    }

    const leftSiblingIndex = Math.max(currentPage - siblings, 1);
    const rightSiblingIndex = Math.min(currentPage + siblings, lastPage);

    const showLeftDots = leftSiblingIndex > 2;
    const showRightDots = rightSiblingIndex <= lastPage - 2;

    // Rights dots
    if (!showLeftDots && showRightDots) {
      const leftRange = rangeHandler(1, range);
      return [...leftRange, '...', lastPage];
    }

    // Left dots
    if (showLeftDots && !showRightDots) {
      const rightRange = rangeHandler(lastPage - range + 1, lastPage);
      return [firstPage, '...', ...rightRange];
    }

    // Both left and right
    if (showLeftDots && showRightDots) {
      const middleRange = rangeHandler(leftSiblingIndex, rightSiblingIndex);
      return [firstPage, '...', ...middleRange, '...', lastPage];
    }
    return null;
  }, [pages, perPage, currentPage]);

  return paginationRange;
};

usePagination.propTypes = {
  perPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
};

export default usePagination;
