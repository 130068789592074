import React, { useContext, useState } from 'react';

import cx from 'classnames';

import { useLocationFilters } from 'api/Filters/FiltersAPIUse';
import { AuthContext } from 'auth/AuthContext';
import { defaultProps, propTypes, formatDisplay } from 'components/Filters/Location/utility';
import MultipleSelectField from 'components/forms/components/MultipleSelectField';
import LocationCollection from 'services/Location/LocationCollection';

const LocationFilter = ({
  value,
  name,
  label,
  className,
  onChange,
  isDisabled,
  isRequired,
  showLabel,
  placeholder,
  isSearchDesign,
}) => {
  const { authState } = useContext(AuthContext);
  const [selectedLocations, setSelectedLocations] = useState(value);
  const { isFetching, data: responseData } = useLocationFilters();
  const locations = LocationCollection.create(responseData?.data?.items ?? []);

  const onValueChange = (newValue) => {
    setSelectedLocations(newValue);
    if (onChange) onChange(newValue);
  };

  return (
    <MultipleSelectField
      className={cx('min-h-unset', className)}
      label={formatDisplay(showLabel ? label : '')}
      name={formatDisplay(name)}
      placeholder={formatDisplay(placeholder)}
      options={locations.getItemsAsOptions(authState)}
      value={selectedLocations}
      onChange={(fieldName, locationData) => onValueChange(locationData)}
      isDisabled={isFetching || isDisabled}
      isRequired={isRequired}
      isFormik={false}
      isSearchDesign={isSearchDesign}
    />
  );
};

LocationFilter.propTypes = propTypes;
LocationFilter.defaultProps = defaultProps;

export default LocationFilter;
