export const useCTAOptions = (useOptions = {}) => ({
  enabled: false,
  staleTime: Infinity,
  ...useOptions,
});

export const usePaginatedOptions = (useOptions = {}, zeroIndex = true) => {
  const pagesToCompare = (number) => (zeroIndex ? number - 1 : number);

  return {
    getNextPageParam: ({ data: { pagination } }) => {
      const { currentPage: page, pages } = pagination;
      if (page < pagesToCompare(pages)) return page + 1;
      return undefined;
    },
    retry: 0,
    ...useOptions,
  };
};
