import { reportConstants } from 'constants/report';

const reportTransKey = (name) => `REPORT.NAME.${name.toUpperCase()}`;
const columnTransKey = (name) => `REPORT.COLUMN_NAME.${name.toUpperCase()}`;
const columnTotalTransKey = (name) => `REPORT.COLUMN_NAME.TOTAL_${name.toUpperCase()}`;
const columnInfoTransKey = (report, column) => `REPORT.${report.toUpperCase()}.${column.toUpperCase()}.INFO`;
const columnTotalInfoTransKey = (report, column) => `REPORT.${report.toUpperCase()}.TOTAL_${column.toUpperCase()}.INFO`;

const reports = () => {
  const items = {};
  reportConstants.REPORTS.forEach((report) => {
    items[report] = reportTransKey(report);
  });

  return items;
};

const columns = () => {
  const items = {};
  reportConstants.COLUMNS.forEach((column) => {
    items[column] = columnTransKey(column);
  });

  return items;
};

export default {
  reportTransKey,
  columnTransKey,
  reports,
  columns,
  columnInfoTransKey,
  columnTotalTransKey,
  columnTotalInfoTransKey,
};
