import http from './Api';

class CompanySettingsPerformanceApi {
  static getPermissions() {
    return http.get(`settings/performance/permissions`);
  }

  static addPermission(data) {
    return http.post(`settings/performance/permissions`, data);
  }

  static editPermission(id, data) {
    return http.put(`settings/performance/permissions/${id}`, data);
  }

  static deletePermission(id) {
    return http.delete(`settings/performance/permissions/${id}`);
  }

  static resetDefault(permissionGroup = '') {
    return http.put(`settings/performance/permissions/reset-default${permissionGroup}`);
  }

  static performanceAccess(filter = '') {
    return http.get(`performance/access${filter}`);
  }

  static getAvailableEmployees(type, filter = '') {
    return http.get(`performance/${type}/available-employees${filter}`);
  }

  static hasActiveEmployeesGoals(type) {
    return http.get(`performance/${type}/available-employees/check`);
  }

  static getPermissionsActivities() {
    return http.get(`settings/activity-group/performance-permissions/activities`);
  }

  static addStrategicAlignment(data) {
    return http.post(`settings/performance/strategic-alignment`, data);
  }

  static editStrategicAlignment(id, data) {
    return http.put(`settings/performance/strategic-alignment/${id}`, data);
  }

  static getStrategicAlignments(filters = '') {
    return http.get(`settings/performance/strategic-alignment${filters}`);
  }

  static deleteStrategicAlignment(id) {
    return http.delete(`settings/performance/strategic-alignment/${id}`);
  }
}

export default CompanySettingsPerformanceApi;
