import _ from 'lodash';

import readName from 'utility/readName';

import AuthState from '../Auth/AuthState';

export default class Department {
  #data;

  constructor(data = {}) {
    if (!_.isObject(data)) {
      throw new Error('Department must be an object');
    }

    this.data = data;
  }

  static create(data) {
    return new Department(data instanceof Department ? data.properties : data);
  }

  get properties() {
    if (!this.data) return {};
    return _.cloneDeep(this.data);
  }

  get id() {
    return this.properties.id ?? '';
  }

  get name() {
    return this.properties.name ?? '';
  }

  get nameArabic() {
    return this.properties.nameArabic ?? '';
  }

  getLocalizedName(language) {
    return readName(language, this.name, this.nameArabic);
  }

  getUserLocalizedName(authState) {
    const authStateDTO = AuthState.create(authState);
    return this.getLocalizedName(authStateDTO.getUserLanguage());
  }

  getAsOption(autState) {
    const { id } = this;
    const name = this.getUserLocalizedName(autState);

    return {
      id,
      name,
      value: id,
      option: id,
      label: name,
    };
  }
}
