import { useContext, useEffect } from 'react';

import { AuthContext } from 'auth/AuthContext';
import { IsLoggedInContext } from 'auth/IsLoggedInContext';
import { configureCrisp, destroyCrisp, initiateCrisp } from 'shared/crispUtilities';

import useCrispStyles from './useCrispStyles';

export const useCrisp = () => {
  const {
    logInState: { isLoggedIn },
  } = useContext(IsLoggedInContext);
  const { authState } = useContext(AuthContext);

  useEffect(() => {
    configureCrisp();
  }, []);

  useCrispStyles();

  useEffect(() => {
    const isAdmin = authState?.user?.roles?.includes('ROLE_ADMIN');
    const isHr = authState?.user?.roles?.includes('ROLE_HR');
    if (isLoggedIn && (isAdmin || isHr)) {
      const { identification, tenant, permissionGroup, username, fullName, firstName } = authState.user;
      initiateCrisp({
        id: identification,
        fullName: fullName || firstName,
        email: username,
        role: permissionGroup,
        tenant,
      });
    } else {
      destroyCrisp();
    }
  }, [isLoggedIn]);
};
