import React from 'react';

import cx from 'classnames';
import moment from 'moment/moment';

import { getThemeFromLS } from 'themes/ThemeContext';

const themeState = getThemeFromLS('theme');

const formatTime = (time) => moment(time, 'HH:mm:ss').format('HH:mm');

export const renderShiftType = (shiftType, startTime, endTime) => (
  <div className='d-flex flex-column'>
    <span className='font-size-14 text-tertiary'>{shiftType}</span>
    <span className='font-size-12 text-gray'>{`${formatTime(startTime)} - ${formatTime(endTime)}`}</span>
  </div>
);

export const renderLocationType = (locationType, locationName) => (
  <div className='d-flex gap-2 align-items-center '>
    <i
      className={cx('text-primary p-2 rounded-circle', locationType === 'remote' ? 'icon-request-remote' : 'icon-case')}
      style={{
        backgroundColor: `rgba(${themeState.colors['primary-rgb']}, 0.1)`,
      }}
    />
    <span className='font-size-14 text-tertiary'>{locationName}</span>
  </div>
);

export const renderActions = (navigate) => (
  <i
    className='icon-eye border rounded-circle font-size-32 cursor-pointer p-1'
    onClick={() => navigate('/time/shift-schedule')}
  />
);
