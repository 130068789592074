import http from './Api';

class ProfileTimesheetApi {
  static getTrackingMethods(employeeId, filters = '') {
    return http.get(`/team/employee/${employeeId}/time-tracking${filters}`);
  }

  static getCurrentTrackingMethod(employeeId) {
    return http.get(`/team/employee/${employeeId}/time-tracking/current`);
  }

  static addTrackingMethod(employeeId, data) {
    return http.post(`/team/employee/${employeeId}/time-tracking`, data);
  }

  static editTrackingMethod(employeeId, id, data) {
    return http.put(`/team/employee/${employeeId}/time-tracking/${id}`, data);
  }

  static deleteTrackingMethod(employeeId, id) {
    return http.delete(`/team/employee/${employeeId}/time-tracking/${id}`);
  }

  static getClockInStatus(employeeId) {
    return http.get(`/team/employee/${employeeId}/time-attendance/status`);
  }

  static clockIn(data) {
    return http.put(`/team/employee/time-attendance/clock-in`, data);
  }

  static clockOut(data) {
    return http.put(`/team/employee/time-attendance/clock-out`, data);
  }

  static getEmployeeTimesheets(employeeId, filters = '') {
    return http.get(`time/timesheets/employee/${employeeId}/timesheets${filters}`);
  }
}

export default ProfileTimesheetApi;
