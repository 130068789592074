const populateDataById = (arrayOfData = [], arrayOfIds = []) =>
  arrayOfIds.map((id) => arrayOfData?.find((data) => data.id === id));

const mapDataToField = (arrayOfData, field = 'id') => arrayOfData.map((data) => data[field]);

const flatMap = (data) => data?.reduce((acc, page) => acc.concat(page.data.items), []) || [];

const findItemInArrayByField = (dataSource, fieldValue, fieldName = 'id') =>
  dataSource?.find((data) => String(data[fieldName]) === String(fieldValue));

export { populateDataById, mapDataToField, flatMap, findItemInArrayByField };
