import React from 'react';

import cx from 'classnames';

import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import t from 'utility/setTranslation';

import styles from './WizardFooter.module.scss';

const WizardFooter = ({
  isFirstStep,
  isLastStep,
  handlePrev,
  stepsValidationState,
  handleNext,
  previousButtonText,
  nextButtonText,
  currentStepIndex,
  className,
}) => {
  const isOneStepWizard = stepsValidationState?.length === 1;

  return (
    <section className={cx('d-flex justify-content-between border-t py-3 px-4', className)}>
      <section className='d-flex justify-content-between align-items-center gap-2'>
        {!isOneStepWizard &&
          stepsValidationState.map((step, index) => {
            const stepNumber = index + 1;
            return (
              <div key={step.id} className='d-flex justify-content-between align-items-center gap-2'>
                <span
                  className={cx(
                    styles.stepNumber,
                    { 'bg-primary': step.isValid || index === currentStepIndex },
                    { 'bg-gray': !step.isValid && index !== currentStepIndex },
                    'd-flex align-items-center justify-content-center color-white font-size-11'
                  )}
                >
                  {stepNumber}
                </span>
                {stepNumber === stepsValidationState.length ? null : step?.isValid ? (
                  <div className={styles.lineSeparator} />
                ) : (
                  <i className='icon-arrow-right text-gray-500' />
                )}
              </div>
            );
          })}
      </section>

      <section className='d-flex gap-2'>
        {(isOneStepWizard || !isFirstStep) && (
          <RoundedButton type='button' onClick={handlePrev} btnStyle='outlined' text={previousButtonText} />
        )}
        <RoundedButton
          icon={isLastStep ? '' : 'icon-arrow-right'}
          color='primary'
          iconPosition='right'
          onClick={handleNext}
          type='button'
          btnStyle='contained'
          text={nextButtonText}
        />
      </section>
    </section>
  );
};

export default WizardFooter;
