import React, { useEffect } from 'react';
import {
  useAddStripePaymentMethod,
  useConfirmAddStripePaymentMethod,
} from '../../topNavigation/hooks/useAccount';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import t from '../../utility/setTranslation';
import Alert from '../alerts/Alert';
import LoadingList from '../LoadingList/LoadingList';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AddStripePaymentMethod = ({ onConfirmLoadingChange, onPaymentSuccessChange }) => {
  const { data, isLoading: isStripeLoading } = useAddStripePaymentMethod();
  const { mutate, isLoading: isConfirmLoading, data: confirmData } = useConfirmAddStripePaymentMethod();

  const clientSecret = data?.data?.clientSecret;
  const paymentSuccess = confirmData?.data?.success;

  useEffect(() => {
    if (onConfirmLoadingChange) {
      onConfirmLoadingChange(isConfirmLoading);
    }
  }, [isConfirmLoading, onConfirmLoadingChange]);

  useEffect(() => {
    if (onPaymentSuccessChange) {
      onPaymentSuccessChange(paymentSuccess);
    }
  }, [paymentSuccess, onPaymentSuccessChange]);

  const handleComplete = () => {
    mutate();
  };

  const options = { clientSecret, onComplete: handleComplete };

  if (isStripeLoading) {
    return (
      <LoadingList rowsCount={8} spaceBetween={2} rowHeight={100} className='justify-content-center gap-3 flex-1 p-4' />
    );
  }

  return (
    <section className='d-flex flex-column justify-content-between py-4'>
      {isConfirmLoading && (
        <div style={{ width: '460px' }}>
          <Alert hasIcon type='info' textMessage={t('PLG.ADD_STRIP_PAYMENT_NOTE')} className='mb-4 ms-2 me-2' />
        </div>
      )}
      <div id='checkout' style={{ width: '100%' }}>
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout className={'w-100'} />
        </EmbeddedCheckoutProvider>
      </div>
    </section>
  );
};

export default AddStripePaymentMethod;
