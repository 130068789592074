import React from 'react';

import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import useResolveHandler from 'shared/useResolveHandler';
import t from 'utility/setTranslation';
import { usePlgBookDemo } from '../../../../hooks/useAccount';


const BookDemoModal = ({ isOpen, onClose }) => {
  const { mutate, isLoading } = usePlgBookDemo();

  const { responseStatus, setStatus } = useResolveHandler();

  const handleBookDemo = () => {
    mutate(null, {
      onSuccess: () => {
        setStatus(true, onClose, t('NAVBAR.BOOK_DEMO_SUCCESS_MSG'));
      },
      onError: (error) => {
        setStatus(false, null, t(error.response.data.message));
      },
    });
  };

  return (
    <ConfirmationModal
      type='info'
      isLoading={isLoading}
      responseStatus={responseStatus}
      actionText='Book a demo'
      onAction={handleBookDemo}
      onClose={onClose}
      isOpen={isOpen}
      description='GENERAL.BOOK_A_DEMO_WARNING'
      styles={{ width: '560px' }}
      title='GENERAL.INFO'
      confirmationButtonId='book-a-demo'
    />
  );
};

export default BookDemoModal;
