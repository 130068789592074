import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';

import DashboardApi from 'api/DashboardApi';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import SelectField from 'components/forms/components/SelectField';
import MapComponent from 'components/map/MapComponent';
import SpinnerRing from 'components/Spinner/SpinnerRing';
import SuccessCheckmark from 'components/SuccessCheckmark/SuccessCheckmark';
import t from 'utility/setTranslation';

const selectStyles = {
  menuList: (provided) => ({
    ...provided,
    maxHeight: '130px',
  }),
};

const SelectLocationModal = ({
  locations,
  onClockInClockOut,
  statusType,
  sending,
  onClose,
  responseStatus,
  getCurrentPosition,
  errorHandler,
  waitingLocation,
  setWaitingLocation,
}) => {
  const initialState = {
    color: statusType === 'out' ? 'green' : 'warning',
    text: statusType === 'out' ? 'Check In' : 'Check Out',
    isDisabled: true,
  };
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isPositionValid, setIsPositionValid] = useState(true);
  const [btnProps, setBtnProps] = useState(initialState);
  const [currentCoordinates, setCurrentCoordinates] = useState({});
  const [locationError, setLocationError] = useState(false);

  const selectHandler = (name, opt) => {
    setSelectedLocation(opt);
  };

  const validateCurrentPosition = (location, coordinates) => {
    setLoading(true);
    DashboardApi.validateCurrentPosition(location, coordinates)
      .then((result) => {
        setIsPositionValid(result.data.isValid);
        setLocationError(false);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (!_.isEmpty(selectedLocation) && selectedLocation?.data?.geofencingMode) {
      setWaitingLocation(true);
      setLocationError(true);
      getCurrentPosition(
        (coordinates) => {
          setCurrentCoordinates(coordinates);
          setWaitingLocation(false);
          setLoading(true);
          validateCurrentPosition(selectedLocation.value, coordinates);
        },
        errorHandler,
        { enableHighAccuracy: true }
      );
    } else {
      setLocationError(false);
      setIsPositionValid(true);
    }
  }, [selectedLocation]);

  useEffect(() => {
    let state = {};
    if (selectedLocation?.value) {
      const enforce = selectedLocation?.data?.geofencing?.enforceLimitation;
      if (isPositionValid) {
        state = {
          color: statusType === 'out' ? 'green' : 'warning',
          text: statusType === 'out' ? 'Check In' : 'Check Out',
          isDisabled: false,
        };
      } else if (!isPositionValid && enforce) {
        state = {
          color: 'primary',
          text: 'Got it',
          isDisabled: false,
        };
      } else if (!isPositionValid && !enforce) {
        state = {
          color: 'warning',
          text: 'Yes, continue',
          isDisabled: false,
        };
      }
    } else {
      state = initialState;
    }
    setBtnProps(state);
  }, [isPositionValid, selectedLocation]);

  const onSubmit = () => {
    const enforce = selectedLocation?.data?.geofencing?.enforceLimitation;
    if (!isPositionValid && enforce) {
      onClose();
    } else if (selectedLocation?.data?.geofencingMode && ((!isPositionValid && !enforce) || isPositionValid)) {
      onClockInClockOut(currentCoordinates, selectedLocation.value);
    } else {
      onClockInClockOut(null, selectedLocation.value);
    }
  };

  return (
    <div className='mt-4'>
      {responseStatus.resolved && <SuccessCheckmark text={responseStatus.msg} isFailed={responseStatus.isFailed} />}
      {(loading || sending || waitingLocation) && <SpinnerRing />}
      <Scrollbars className='scrollbarsWrapper no-margin' autoHeight autoHeightMin={210} autoHeightMax={550}>
        <div className='px-5'>
          <SelectField
            styles={selectStyles}
            label='location'
            name='location'
            isRequired
            isFormik={false}
            options={locations}
            onChange={selectHandler}
            value={selectedLocation?.value || null}
          />
          {selectedLocation?.data?.geofencingMode && (
            <div className='mt-4'>
              {!isPositionValid && (
                <h6 className='mb-2 fw-bold'>
                  {selectedLocation?.data?.geofencing?.enforceLimitation
                    ? t('GENERAL.GEOFENCING_LIMITATION_WARNING_DESC')
                    : t('GENERAL.GEOFENCING_WARNING_DESC')}
                </h6>
              )}
              <div className='mb-7'>
                <div style={{ height: '280px' }} className='mt-5'>
                  <MapComponent
                    latitude={selectedLocation?.data?.geofencing?.latitude * 1 ?? 24.693529747911377}
                    longitude={selectedLocation?.data?.geofencing?.longitude * 1 ?? 46.676083121103154}
                    onUpdateLatLng={() => {}}
                    zoom={13}
                    radius={selectedLocation?.data?.geofencing?.radius * 1 ?? 150}
                    readMode
                    msgInfo={selectedLocation?.data?.name}
                    listOfMarkers={[
                      {
                        ...currentCoordinates,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Scrollbars>
      <div className='d-flex justify-content-end py-4 px-5 border-t'>
        <RoundedButton
          isDisabled={btnProps.isDisabled || waitingLocation || locationError}
          text={btnProps.text}
          color={btnProps.color}
          size='md'
          className='btn-lg'
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

SelectLocationModal.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  getCurrentPosition: PropTypes.func.isRequired,
  errorHandler: PropTypes.func.isRequired,
  waitingLocation: PropTypes.bool.isRequired,
  setWaitingLocation: PropTypes.func.isRequired,
  statusType: PropTypes.string,
  onClockInClockOut: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SelectLocationModal.defaultProps = {
  statusType: 'out',
};

export default SelectLocationModal;
