export default function resourcePath(relativePath) {
  if (!relativePath) return null;

  if (relativePath.includes('static') || relativePath.includes(window.location.origin)) return relativePath;

  const { origin } = new URL(`${process.env.REACT_APP_BASE_URL}api/v1/`);

  if (relativePath.includes(origin)) return relativePath;

  return `${origin}${relativePath}`;
}
