import React from 'react';

import { Scrollbars } from 'react-custom-scrollbars';

import HistoryItem from './HistoryItem';
import TableHead from '../../../Employee/shared/TableHead';

const headerCells = [
  { title: 'Date' },
  { title: 'Plan' },
  { title: 'Period' },
  { title: 'Status' },
  { title: 'Amount', colSpan: 2 },
];

const InvoiceHistoryTable = ({ invoices, onDownload }) => (
  <Scrollbars
    className='scrollbarsWrapper'
    autoHide
    autoHideTimeout={1000}
    autoHideDuration={200}
    autoHeight
    autoHeightMin={100}
    autoHeightMax={900}
  >
    <table className='w-100 bg-white mt-3a'>
      <TableHead headerCells={headerCells} />
      <tbody>
        {invoices.map((invoice) => (
          <HistoryItem
            key={invoice.id}
            data={invoice}
            onDownload={() => onDownload(invoice.id, 'download')}
            onPreview={() => onDownload(invoice.id, 'preview')}
          />
        ))}
      </tbody>
    </table>
  </Scrollbars>
);

export default InvoiceHistoryTable;
