import React from 'react';

import PalmHrAICard from 'modules/Dashboard/components/PalmHrAICard/PalmHrAICard';
import PalmHrAIModal from 'modules/Dashboard/components/PalmHrAIModal/PalmHrAIModal';
import { useVisibility } from 'shared/useVisibility';

const PalmHrAI = () => {
  const { open, close, isOpen } = useVisibility();

  return (
    <>
      <PalmHrAICard onClick={open} />
      {isOpen && <PalmHrAIModal isOpen={isOpen} onRequestClose={close} />}
    </>
  );
};

export default PalmHrAI;
