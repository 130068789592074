import React, { useContext, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { AuthContext } from 'auth/AuthContext';
import OutsideClickHandler from 'hoc/OutsideClickHandler';
import t from 'utility/setTranslation';

import styles from './TrialCounter.module.scss';
import TrialInfoBox from './TrialInfoBox';

const TrialCounter = ({ data }) => {
  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user.roles.includes('ROLE_ADMIN');
  const [openInfo, setOpenInfo] = useState(false);

  return (
    <div className={cx(styles.wrapper, 'd-flex align-items-center position-relative')}>
      {data.daysLeft > 0 && (
        <div
          className={cx(!isAdmin ? styles.disabled : '', 'd-flex align-items-center pb-1 px-1 position-relative')}
          onClick={() => {
            setOpenInfo(true);
          }}
        >
          <div className={styles.progressBox}>
            <CircularProgressbar
              value={data.percent}
              strokeWidth={10}
              styles={buildStyles({
                pathColor: `rgb(255, 161, 80)`,
              })}
            />

            <div className={styles.number}>
              <span>{data.daysLeft}</span>
            </div>
          </div>

          <div className={cx(styles.text, 'ps-2 me-2a mt-1')}>
            {t('SUBSCRIPTION.DAYS_LEFT', {
              type: t(`GENERAL.${data.type.toUpperCase()}`),
            })}
          </div>
        </div>
      )}

      {openInfo && (
        <OutsideClickHandler
          clickHandler={() => {
            setOpenInfo(false);
          }}
        >
          <TrialInfoBox
            type={data.type}
            date={data.type === 'demo' ? authState.companyData?.demoExpireAt : authState.companyData?.trialExpireAt}
          />
        </OutsideClickHandler>
      )}
    </div>
  );
};

TrialCounter.propTypes = { data: PropTypes.object.isRequired };

export default TrialCounter;
