import t from 'utility/setTranslation';

export const PERMISSION_GROUP_ADMIN = 'ADMIN';
export const PERMISSION_GROUP_SECURITY_ADMIN = 'SECURITY_ADMIN';
export const PERMISSION_GROUP_HR = 'HR';
export const PERMISSION_GROUP_ACCOUNTANT = 'ACCOUNTANT';
export const PERMISSION_GROUP_PUBLIC_RELATIONS = 'PUBLIC_RELATIONS';
export const PERMISSION_GROUP_EMPLOYEE = 'EMPLOYEE';

export const permissionGroups = [
  PERMISSION_GROUP_ADMIN,
  PERMISSION_GROUP_SECURITY_ADMIN,
  PERMISSION_GROUP_HR,
  PERMISSION_GROUP_ACCOUNTANT,
  PERMISSION_GROUP_PUBLIC_RELATIONS,
  PERMISSION_GROUP_EMPLOYEE,
];

export const isPermissionGroupAdmin = (toCompare) => toCompare === PERMISSION_GROUP_ADMIN;

export const isPermissionGroupSecurityAdmin = (toCompare) => toCompare === PERMISSION_GROUP_SECURITY_ADMIN;

export const isPermissionGroupHR = (toCompare) => toCompare === PERMISSION_GROUP_HR;

export const isPermissionGroupAccountant = (toCompare) => toCompare === PERMISSION_GROUP_ACCOUNTANT;

export const isPermissionGroupPublicRelations = (toCompare) => toCompare === PERMISSION_GROUP_PUBLIC_RELATIONS;

export const isPermissionGroupEmployee = (toCompare) => toCompare === PERMISSION_GROUP_EMPLOYEE;

export const isPermissionGroupEligibleForLegalEntityLimiting = (toCompare) =>
  isPermissionGroupAdmin(toCompare) || isPermissionGroupHR(toCompare);

export const isPermissionGroupEligibleForSettings = (toCompare) =>
  isPermissionGroupAdmin(toCompare) || isPermissionGroupHR(toCompare) || isPermissionGroupSecurityAdmin(toCompare);

export const permissionGroupTranslationKey = (permissionGroup) => {
  if (isPermissionGroupAdmin(permissionGroup)) return 'GENERAL.ADMINISTRATOR';
  if (isPermissionGroupSecurityAdmin(permissionGroup)) return 'GENERAL.SECURITY_ADMINISTRATOR';
  return `GENERAL.${permissionGroup}`;
};

export const permissionGroupTranslation = (permissionGroup) => t(permissionGroupTranslationKey(permissionGroup));

export const permissionGroupsAsOptions = permissionGroups.map((permissionGroup) => ({
  id: permissionGroup,
  value: permissionGroup,
  option: permissionGroup,
  name: permissionGroupTranslation(permissionGroup),
  label: permissionGroupTranslation(permissionGroup),
}));
