import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import icon from 'assets/img/svg-warning.svg';
import t from 'utility/setTranslation';

import RoundedButton from './buttons/RoundedButton/RoundedButton';
import styles from './DirtyForm.module.scss';

const wrapper = cx(styles.sendingOverlay, 'd-flex', 'justify-content-center', 'align-items-center');

const DirtyForm = ({ onClose, onConfirm, text, cancelText, confirmTex, hideCancelButton }) => (
  <div className={wrapper}>
    <div className={cx(styles.content, 'd-flex', 'flex-column', 'align-items-center')}>
      <img src={icon} alt='warning-icon' />
      <h5 className='text-center text-orange fw-bold mb-5'>{t('GENERAL.WARNING')}</h5>
      <p className='text-gray text-center mb-5 px-3'>{text ? t(text) : t('GENERAL.FORM_DIRTY_MSG')}</p>
      <div className='d-flex justify-content-end px-3'>
        {!hideCancelButton && (
          <RoundedButton
            onClick={onClose}
            text={cancelText || 'Cancel'}
            color='gray'
            btnStyle='outlined'
            size='md'
            className='me-3'
          />
        )}
        <RoundedButton onClick={onConfirm} text={confirmTex || 'OK'} btnStyle='contained' size='md' color='warning' />
      </div>
    </div>
  </div>
);

DirtyForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  text: PropTypes.string,
  hideCancelButton: PropTypes.bool,
};

DirtyForm.defaultProps = {
  text: '',
  hideCancelButton: false,
};

export default DirtyForm;
