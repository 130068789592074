function formatCurrency(num, min) {
  const val = parseFloat(num);
  if (Number.isNaN(val)) {
    return '0.00';
  }
  return val.toLocaleString(undefined, {
    minimumFractionDigits: min || min === 0 ? min : 2,
    maximumFractionDigits: 2,
  });
}

export default formatCurrency;
