import React, { useContext } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'auth/AuthContext';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { useVisibility } from 'shared/useVisibility';
import {
  queryKeys,
  useAccountSubscriptionData,
  useConfirmPayment,
  useSubscriptionDueDate,
} from 'topNavigation/hooks/useAccount';
import NotificationBanner from 'topNavigation/NavBar/NotificationBanner/NotificationBanner';
import t from 'utility/setTranslation';
import TrialPeriodBanner from './TrialPeriodBanner/TrialPeriodBanner';

const MainNavBarBanner = ({ closeRenewModal, openRenewalModal }) => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useSubscriptionDueDate();
  const { isLoading: isLoadingConfirmPayment, mutate } = useConfirmPayment();
  const { data: accountSubscriptionData } = useAccountSubscriptionData();

  const { authState, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const {
    isOpen: isCloseConfirmationModalOpen,
    open: openCloseConfirmationModal,
    close: closeCloseConfirmationModal,
  } = useVisibility();

  const { isUserRespondedToRenewal } = queryClient.getQueryState(queryKeys.subscriptionData)?.data?.data || {};

  if (isLoading) {
    return null;
  }

  const isPLGClient = accountSubscriptionData?.data.isPlg;

  const handleConfirmPayment = () => {
    mutate(undefined, {
      onSuccess: () => {
        closeCloseConfirmationModal();
        dispatch({ type: 'set', payload: { isInvoicePending: false } });
        dispatch({ type: 'set', payload: { isRenewalPending: false } });
      },
    });
  };

  const handleViewInvoice = () => {
    closeRenewModal();
    navigate('/company-settings/billing');
  };

  const { daysAfterOverdue, isInvoicePending, isRenewalPending } = authState;
  const subscriptionDueDate = moment(data?.data?.dueDate).format('MMM DD, YYYY');

  const isAdmin = authState?.user?.roles?.includes('ROLE_ADMIN');
  const isHr = authState?.user?.roles?.includes('ROLE_HR');

  const handleConfirmPaymentClick = () =>
    isUserRespondedToRenewal ? openCloseConfirmationModal() : openRenewalModal();

  const renderOverDueSubscriptionWarningActions = () => (
    <section className='d-inline-flex gap-2'>
      <RoundedButton
        size='sm'
        text='Confirm Payment'
        btnStyle='outlined'
        className='no-border'
        color='warning'
        onClick={handleConfirmPaymentClick}
      />
      <RoundedButton size='sm' text='View Invoice' btnStyle='text' color='white' onClick={handleViewInvoice} />
    </section>
  );

  const renderOverDueSubscriptionActions = () => {
    if (isAdmin || isHr) {
      return (
        <section className='d-inline-flex gap-2'>
          {!isPLGClient && (
            <RoundedButton
              size='sm'
              text='Confirm Payment'
              btnStyle='outlined'
              className='no-border'
              color='danger'
              onClick={openCloseConfirmationModal}
            />
          )}
          <RoundedButton size='sm' text='View Invoice' btnStyle='text' color='white' onClick={handleViewInvoice} />
        </section>
      );
    }

    return null;
  };

  return (
    <>
      <TrialPeriodBanner />
      <NotificationBanner
        type='warning'
        className='ps-5 py-3'
        isShow={(isAdmin || isHr) && isRenewalPending}
        message={t('GENERAL.DUE_DATE_SOON', {
          renewalDate: moment(accountSubscriptionData?.data?.currentTermEnd).format('MMM DD, YYYY'),
        })}
        renderActions={renderOverDueSubscriptionWarningActions}
      />

      <NotificationBanner
        type='danger'
        className='ps-5 py-3'
        isShow={isInvoicePending}
        message={
          daysAfterOverdue <= 0
            ? t('GENERAL.ACCOUNT_SUSPENDED_TODAY')
            : t('GENERAL.ACCOUNT_SUSPEND_SOON', {
                dayToSuspendCount: daysAfterOverdue,
              })
        }
        renderActions={renderOverDueSubscriptionActions}
      />

      <ConfirmationModal
        description='GENERAL.PAYMENT_CONFIRMATION_MESSAGE'
        type='warning'
        actionText='Ok'
        isLoading={isLoadingConfirmPayment}
        isOpen={isCloseConfirmationModalOpen}
        onClose={closeCloseConfirmationModal}
        onAction={handleConfirmPayment}
        responseStatus={{}}
        onRequestClose={closeCloseConfirmationModal}
      />
    </>
  );
};

export default MainNavBarBanner;
