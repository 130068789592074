import http from './Api';

class NotificationsApi {
  static putNotificationToken(data) {
    return http.put('/push-notification-token', data);
  }

  static getVapid() {
    return http.get('/push-notification-vapid');
  }
}

export default NotificationsApi;
