import React, { lazy, Suspense } from 'react';

import { Navigate } from 'react-router-dom';

import InitLoading from 'components/Spinner/InitLoading';
import useSharedRoutes from 'topNavigation/Router/hooks/useSharedRoutes';
import PublicRoute from 'topNavigation/Router/PublicRoute';
import { MobileFriendlyRoutes, PublicRoutes } from 'topNavigation/Router/routes';

const ResetPassword = lazy(() => import('modules/AuthPages/ResetPassword/ResetPassword'));
const ForgotPassword = lazy(() => import('modules/AuthPages/ForgotPassword/ForgotPassword'));
const Login = lazy(() => import('modules/AuthPages/Login/Login'));

const UsePublicRoutes = () => {
  const sharedRoutes = useSharedRoutes();

  return [
    {
      path: PublicRoutes.login,
      element: (
        <Suspense fallback={<InitLoading />}>
          <PublicRoute component={Login} />
        </Suspense>
      ),
    },
    {
      path: '/',
      element: (
        <Suspense fallback={<InitLoading />}>
          <PublicRoute component={Login} />
        </Suspense>
      ),
    },
    {
      path: MobileFriendlyRoutes.resetPassword,
      element: (
        <Suspense fallback={<InitLoading />}>
          <PublicRoute component={ResetPassword} />
        </Suspense>
      ),
    },
    {
      path: PublicRoutes.forgotPassword,
      element: (
        <Suspense fallback={<InitLoading />}>
          <PublicRoute component={ForgotPassword} />
        </Suspense>
      ),
    },
    ...sharedRoutes,
    {
      path: '*',
      element: <Navigate to={PublicRoutes.login} replace />,
    },
  ];
};

export default UsePublicRoutes;
