import React from 'react';

import cx from 'classnames';

import LoadingList from 'components/LoadingList/LoadingList';

import styles from './Card.module.scss';

const Card = ({ children, isLoading, className }) => (
  <section className={cx(styles.Card, className)}>
    {isLoading ? (
      <LoadingList rowsCount={3} spaceBetween={0} rowHeight={16} className='justify-content-center gap-3 flex-1' />
    ) : (
      children
    )}
  </section>
);

export default Card;
