import React, { useContext } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import cx from 'classnames';

import Suspended from 'assets/img/Account_Suspended.svg';
import info from 'assets/img/alert-img-info.png';
import { AuthContext } from 'auth/AuthContext';
import { IsLoggedInContext } from 'auth/IsLoggedInContext';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import Modal from 'components/Modal';
import AreaLoader from 'components/Spinner/AreaLoader';
import styles from 'modules/CompanySettings/Subscription/ExpiredPage.module.scss';
import { useVisibility } from 'shared/useVisibility';
import { useAccountSubscriptionData, useConfirmPayment, useLogout } from 'topNavigation/hooks/useAccount';
import t from 'utility/setTranslation';
import AddStripePaymentMethodModal from '../../../../components/AddStripePaymentMethod/AddStripePaymentMethodModal';

const SuspendedPage = () => {
  const {
    isOpen: isCloseConfirmationModalOpen,
    open: openCloseConfirmationModal,
    close: closeCloseConfirmationModal,
  } = useVisibility();
  const { isLoading: isLoadingConfirmPayment, mutate } = useConfirmPayment();
  const { isLoading: isLoadingLogout, mutate: mutateLogout } = useLogout();
  const { data: accountSubscriptionData } = useAccountSubscriptionData();
  const queryClient = useQueryClient();
  const { authState, dispatch } = useContext(AuthContext);
  const { dispatch: dispatchIsLoggedIn } = useContext(IsLoggedInContext);
  const { isOpen, open, close } = useVisibility();
  const { isOpen: isAddPaymentMethodModalOpen, close: closeAddPaymentMethodModal, open: openAddPaymentMethodModal } = useVisibility();

  const isPLGClient = accountSubscriptionData?.data.isPlg;

  const handleConfirmPayment = () => {
    mutate(undefined, {
      onSuccess: () => {
        closeCloseConfirmationModal();
        open();
      },
    });
  };

  const handleLogout = () => {
    mutateLogout(
      { username: authState.user.username },
      {
        onSuccess: () => {
          close();
          queryClient.clear();

          dispatch({
            type: 'del',
          });
          dispatchIsLoggedIn({
            type: 'set',
            payload: { isLoggedIn: false },
          });
        },
      }
    );
  };

  return (
    <>
      <section className='d-flex flex-column align-items-center'>
        <button
          className={cx(styles.link, 'nav-link', 'btn', 'text-start', 'pb-0')}
          type='button'
          onClick={handleLogout}
        >
          {t('GENERAL.LOG_OUT')}
        </button>
        <img className='mt-16' src={Suspended} width={480} alt='suspended' />
        <h6 className='subtitle font-size-20 mt-5 mb-3'>{t('GENERAL.ACCOUNT_SUSPENSION')}</h6>
        <p>{t('GENERAL.SUSPENDED_MESSAGE')}</p>
        {authState.isInvoicePending && !authState.forbidConfirmPaymentOnSuspendedPage && !isPLGClient && (
          <RoundedButton
            text='Confirm Payment'
            btnStyle='contained'
            className='no-border mt-5'
            color='primary'
            onClick={openCloseConfirmationModal}
          />
        )}

        {authState.isInvoicePending && !authState.forbidConfirmPaymentOnSuspendedPage && isPLGClient && (
          <RoundedButton
            text='Add Payment Method'
            btnStyle='contained'
            className='no-border mt-5'
            color='primary'
            onClick={openAddPaymentMethodModal}
          />
        )}
      </section>

      <Modal showHeader={false} isOpen={isOpen} styles={{ width: '480px' }} onRequestClose={close}>
        {isLoadingLogout ? (
          <AreaLoader className='mb-15' />
        ) : (
          <section className='d-flex flex-column align-items-center gap-4'>
            <img src={info} width={160} alt='info' />
            <p className='text-center text-main'>{t('GENERAL.ACTIVATE_SUBSCRIPTION')}</p>
            <RoundedButton
              text='Log out'
              btnStyle='outlined'
              className='no-border'
              color='primary'
              onClick={handleLogout}
            />
          </section>
        )}
      </Modal>

      <ConfirmationModal
        description='GENERAL.PAYMENT_CONFIRMATION_MESSAGE'
        type='warning'
        actionText='Ok'
        isLoading={isLoadingConfirmPayment}
        isOpen={isCloseConfirmationModalOpen}
        onClose={closeCloseConfirmationModal}
        onAction={handleConfirmPayment}
        responseStatus={{}}
        onRequestClose={closeCloseConfirmationModal}
      />
      {isAddPaymentMethodModalOpen && <AddStripePaymentMethodModal isOpen={isAddPaymentMethodModalOpen} onClose={closeAddPaymentMethodModal} />}
    </>
  );
};

export default SuspendedPage;
