const renderPeriodKey = (num, val) => {
  const keyMap = {
    YEAR: 'GENERAL.YR',
    MONTH: 'GENERAL.MTH',
    DAY: 'GENERAL.DAY',
  };
  let trKey = keyMap[val];
  if (num > 1) {
    trKey += 'S';
  }
  return trKey;
};

export default renderPeriodKey;
