/* eslint-disable no-case-declarations */
import React, { useContext, useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';

import { AuthContext } from 'auth/AuthContext';
import Personas from 'components/Personas';
import Tooltip from 'components/tooltip/Tooltip';
import WorkScheduleWeek from 'components/WorkSchedule/WorkScheduleWeek';
import OutsideClickHandler from 'hoc/OutsideClickHandler';
import numberUtil from 'utility/numberUtil';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

import styles from './Card.module.scss';
import CardMoreDetails from './CardMoreDetails';
import SkeletonCard from './SkeletonCard';
import OverlapItemDetails from '../../EngagementCenter/shared/TimeOverlap/OverlapItemDetails';

const Card = ({ className, title, link, contentIcon, contentData, hasLink, type, loading }) => {
  const { authState } = useContext(AuthContext);
  const [dropdownList, setDropdownList] = useState(false);

  const content = () => {
    switch (type) {
      case 'workschedule':
        return <WorkScheduleWeek workSchedule={contentData || {}} />;
      case 'vacation':
        return (
          <div className={styles.vacation}>{contentData.balance ? numberUtil.format(contentData.balance) : 0}</div>
        );
      default:
        const additionalUsers = contentData.length > 3 ? contentData.length - 3 : null;
        return contentData.length === 0 ? (
          <i>{t(`GENERAL.NO_${_.upperCase(type)}`)}</i>
        ) : (
          <>
            {contentData.map(
              (el, index) =>
                index < 3 && (
                  <div className='me-2' key={el.id}>
                    <Tooltip label={readName(authState.user.language, el.fullName, el.fullNameArabic)}>
                      <Personas user={el} size={24} />
                    </Tooltip>
                  </div>
                )
            )}

            {dropdownList && (type === 'out' || type === 'remote') && (
              <OutsideClickHandler clickHandler={() => setDropdownList(false)}>
                <div className={cx(styles.dropdown, 'bg-white radius-4')}>
                  <Scrollbars
                    className='scrollbarsWrapper'
                    autoHeight
                    autoHeightMin={100}
                    autoHeightMax={250}
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                  >
                    <ul className='no-style px-2 pt-3 d-flex flex-wrap mb-0'>
                      {contentData.map((item) => (
                        <OverlapItemDetails key={item.id} item={item} isDashboard />
                      ))}
                    </ul>
                  </Scrollbars>
                </div>
              </OutsideClickHandler>
            )}

            {dropdownList && (type === 'birthdays' || type === 'anniversaries') && (
              <OutsideClickHandler clickHandler={() => setDropdownList(false)}>
                <div className={cx(styles.dropdown, 'bg-white radius-4')}>
                  <Scrollbars
                    className='scrollbarsWrapper'
                    autoHeight
                    autoHeightMin={100}
                    autoHeightMax={250}
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                  >
                    <ul className='no-style px-2 pt-3 d-flex flex-wrap mb-0'>
                      {contentData.map((item) => (
                        <CardMoreDetails key={item.id} item={item} />
                      ))}
                    </ul>
                  </Scrollbars>
                </div>
              </OutsideClickHandler>
            )}

            {additionalUsers && additionalUsers > 0 && (
              <Tooltip
                label={t('GENERAL.NO_OF_MORE_USERS', {
                  number: additionalUsers,
                })}
              >
                <div className={cx(styles.moreUsers, 'd-flex', 'align-items-center', 'justify-content-center')}>
                  {`+${additionalUsers}`}
                </div>
              </Tooltip>
            )}
          </>
        );
    }
  };

  return (
    <div className={cx(styles.cardWrapper, className)}>
      {loading ? (
        <SkeletonCard type={type} className={`${type === 'vacation' ? 'mt-2' : ''}`} />
      ) : (
        <>
          <div className={cx(styles.cardHeader, 'd-flex', 'justify-content-between', 'align-items-center')}>
            <div className={styles.title}>{title}</div>

            {hasLink && (
              <Link to={link} className={cx(styles.link)}>
                <i className='icon-circle-arrow-right' />
              </Link>
            )}
          </div>

          {type === 'vacation' ? (
            <div className={cx(styles.cardContent, 'mt-2')}>
              <div className='d-flex justify-content-start align-items-center'>
                <i className={cx(styles.vacationIcon, contentIcon, 'me-3')} />
                {content()}
              </div>
              <label className='label p-0 m-0'>{t('GENERAL.DAYS_AVAILABLE')}</label>
            </div>
          ) : (
            <div
              className={cx(styles.cardContent, 'd-flex', 'justify-content-start', 'align-items-center')}
              onClick={() => setDropdownList(true)}
            >
              {type !== 'workschedule' && (
                <i
                  className={cx(
                    styles.contentIcon,
                    contentIcon,
                    { [styles.out]: type === 'out' },
                    { [styles.remote]: type === 'remote' },
                    { [styles.birthdays]: type === 'birthdays' },
                    { [styles.anniversaries]: type === 'anniversaries' },
                    'me-3'
                  )}
                />
              )}

              {content()}
            </div>
          )}
        </>
      )}
    </div>
  );
};

Card.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  contentIcon: PropTypes.string,
  hasLink: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

Card.defaultProps = {
  link: '',
  contentIcon: '',
  hasLink: false,
  className: '',
  loading: true,
};

export default Card;
