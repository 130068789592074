import React from 'react';

import cx from 'classnames';

import styles from './ItemPlaceholder.module.scss';

const ItemPlaceholder = () => (
  <div className='d-flex align-items-center'>
    <div className={cx(styles.square)} />
    <div className={cx(styles.line, 'ms-2 flex-1')} />
  </div>
);

export default ItemPlaceholder;
