import React from 'react';

import Circle from 'modules/EngagementCenter/shared/loaders/Circle';
import Line from 'modules/EngagementCenter/shared/loaders/Line';

import styles from './Skeleton.module.scss';

const SkeletonCard = ({ className, type }) => (
  <div className={className}>
    <div className='d-flex justify-content-between'>
      <Line style={{ height: 16, width: 100, borderRadius: 8 }} gradient={styles.gradient} />

      {type === 'vacation' && <Circle gradient={styles.gradient} size={24} />}
    </div>
    <div>
      {type === 'vacation' ? (
        <>
          <div className='d-flex align-items-center justify-content-start mt-2'>
            <Circle gradient={styles.gradient} className='me-3' size={38} />
          </div>
          <div className='d-flex align-items-center justify-content-start'>
            <Line style={{ height: 16, width: 100, borderRadius: 8 }} gradient={styles.gradient} />
          </div>
        </>
      ) : (
        <div className='d-flex align-items-center justify-content-start mt-2'>
          {type !== 'workschedule' && <Circle gradient={styles.gradient} className='me-3' size={32} />}

          <Line
            style={{
              height: 16,
              width: type === 'workschedule' ? '100%' : 100,
              borderRadius: 8,
            }}
            gradient={styles.gradient}
          />
        </div>
      )}
    </div>
  </div>
);

export default SkeletonCard;
