import React from 'react';

import GettingStartedWizardModal from 'components/GettingStartedWizardModal/GettingStartedWizardModal';

import { generateWizardImagesSteps } from './wizardconfig';
import { clientTypes } from '../../../constants/clientTypes';
import { getStartedGuidePages } from '../../Dashboard/Constants';

const PayGettingStarted = () => (
  <GettingStartedWizardModal
    generateWizardImagesSteps={() => generateWizardImagesSteps}
    accountTypes={[clientTypes.TRIAL, clientTypes.TEST_SITE]}
    trackedPages={getStartedGuidePages}
  />
);

export default PayGettingStarted;
