import React, { useContext, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import cx from 'classnames';
import moment from 'moment';

import AuthApi from 'api/AuthApi';
import image from 'assets/img/trial-expired.png';
import { AuthContext } from 'auth/AuthContext';
import { IsLoggedInContext } from 'auth/IsLoggedInContext';
import t from 'utility/setTranslation';

import styles from './ExpiredPage.module.scss';

const ExpiredPage = () => {
  const queryClient = useQueryClient();
  const { authState, dispatch } = useContext(AuthContext);
  const { dispatch: dispatchIsLoggedIn } = useContext(IsLoggedInContext);

  const [showContent, setShowContent] = useState(false);

  const isAdmin = authState?.user?.roles?.includes('ROLE_ADMIN');
  const date =
    authState.subscriptionStatus === 'cancelled' || authState.subscriptionStatus === 'paused'
      ? moment().add(7, 'days').format('MMM DD, YYYY')
      : moment(authState.companyData?.demoExpireAt).format('MMM DD, YYYY');

  const type =
    authState.subscriptionStatus === 'cancelled' || authState.subscriptionStatus === 'paused' ? 'SUBSCRIPTION' : 'DEMO';

  const status = type === 'DEMO' ? 'EXPIRED' : authState.subscriptionStatus === 'cancelled' ? 'CANCELLED' : 'PAUSED';

  const logout = () => {
    const data = { username: authState.user.username };
    AuthApi.logout(data)
      .then(() => {
        queryClient.clear();

        dispatch({
          type: 'del',
        });
        dispatchIsLoggedIn({
          type: 'set',
          payload: { isLoggedIn: false },
        });
      })
      .catch(() => {
        queryClient.clear();

        dispatch({
          type: 'del',
        });
        dispatchIsLoggedIn({
          type: 'set',
          payload: { isLoggedIn: false },
        });
      });
  };

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        setShowContent(true);
      }
    }, 2000);
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      {showContent && (
        <>
          <button className={cx(styles.link, 'nav-link', 'btn', 'text-start', 'pb-0')} type='button' onClick={logout}>
            {t('GENERAL.LOG_OUT')}
          </button>
          <div className={styles.content}>
            <img src={image} alt='trial-expired' className={styles.image} />
            <h5 className={styles.title}>
              {t('SUBSCRIPTION.TRIAL_EXPIRED.TITLE', {
                type: t(`GENERAL.${type}`),
                status: t(`GENERAL.${status}`),
              })}
            </h5>
            <p className={styles.text}>
              {t('SUBSCRIPTION.TRIAL_EXPIRED.TEXT', {
                type: t(`GENERAL.${type}`),
                status: t(`GENERAL.${status}`),
              })}
            </p>
            <p className={cx(styles.text, 'mb-5')}>
              {isAdmin && type === 'SUBSCRIPTION' && (
                <span>
                  <span>{t('SUBSCRIPTION.TRIAL_EXPIRED.TEXT_SUB_ADMIN_FIRST')}</span>
                  <span className='fw-bold ms-1'>{date}</span>
                  <span className='fw-bold me-1'>.</span>
                  <span className='fs-14'>
                    <span className='me-1'>{t('SUBSCRIPTION.TRIAL_EXPIRED.CONTACT_SUPPORT')}</span>
                    <a href='mailto:support@palmhr.net'>{t('GENERAL.PALMHR_SUPPORT')}</a>
                  </span>
                </span>
              )}
              {!isAdmin && type === 'SUBSCRIPTION' && t('SUBSCRIPTION.TRIAL_EXPIRED.TEXT_SUB')}
              {type === 'DEMO' && (
                <span>
                  <span>{t('SUBSCRIPTION.DEMO_EXPIRED.TEXT_SUB')}</span>
                  <span className='fw-bold ms-1'>{date}</span>
                  <span className='fw-bold me-1'>.</span>
                  <span className='fs-14'>
                    <span className='me-1'>{t('SUBSCRIPTION.DEMO_EXPIRED.CONTACT_SALES')}</span>
                    <a href='mailto:sales@palmhr.net'>{t('GENERAL.PALMHR_SALES')}</a>
                    <span className='fw-bold me-1'>.</span>
                  </span>
                </span>
              )}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default ExpiredPage;
