import React, { useState, useEffect, useContext } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import CSSTransition from 'react-transition-group/CSSTransition';

import SystemNotificationsApi from 'api/SystemNotificationsApi';
import { AuthContext } from 'auth/AuthContext';
import IconButton from 'components/buttons/IconButton/IconButton';
import Tooltip from 'components/tooltip/Tooltip';
import OutsideClickHandler from 'hoc/OutsideClickHandler';
import GeneralStore from 'stores/GeneralStore';
import t from 'utility/setTranslation';

import styles from './InboxNotifications.module.scss';
import NotificationItem from './NotificationItem';

const InboxNotifications = ({ onSelectNotification }) => {
  const [showMenu, setShowMenu] = useState(false);
  const { authState } = useContext(AuthContext);
  const generalStore = useContext(GeneralStore);
  const [notifications, setNotifications] = useState({
    items: [],
    pagination: {},
  });
  const [totalUnseen, setTotalUnseen] = useState(0);

  const closeMenu = () => {
    setShowMenu(false);
  };

  const openMenu = () => {
    setShowMenu(!showMenu);
  };

  const getNotificationsHandler = (isScroll = false) => {
    const params = '&direction=desc&sort=sn.receivedAt&perPage=20';
    let sentFilter = '';

    const state = { ...notifications };
    if (isScroll) {
      const nextPage = state.pagination.currentPage + 1;
      if (nextPage <= state.pagination.pages) {
        sentFilter = `?currentPage=${nextPage}&${params}`;
      } else {
        return;
      }
    } else {
      sentFilter = `?${params}`;
    }

    SystemNotificationsApi.getAllNotifications(authState.user.identification, sentFilter)
      .then((res) => {
        if (isScroll) {
          state.items = _.concat(state.items, res.data.items);
          state.pagination = res.data.pagination;

          setNotifications(state);
        } else {
          setNotifications(res.data);
        }
      })
      .catch(() => {});
  };

  const onMarkAllAsRead = () => {
    SystemNotificationsApi.seenAllNotifications(authState.user.identification).then(() => {
      getNotificationsHandler(false);
    });
  };

  const onSeenNotification = (id) => {
    const sentData = {
      notifications: [],
    };
    sentData.notifications.push(id);
    SystemNotificationsApi.setSeenedNotifications(authState.user.identification, sentData)
      .then((res) => {
        const data = { ...notifications };
        data.items.map((el) => {
          const el2 = el;
          if (el2.id === res.data.items[0].id) {
            el2.isSeen = true;
            el2.seenAt = res.data.items[0].seenAt;
          }
          return el2;
        });

        setNotifications(data);
      })
      .catch(() => {});
  };

  const handleScroll = (event) => {
    if (event.top >= 0.999) {
      getNotificationsHandler(true);
    }
  };

  useEffect(() => {
    getNotificationsHandler(false);
  }, []);

  useEffect(() => {
    const intervalNotifications = setInterval(() => {
      getNotificationsHandler(false);
    }, 180000);

    if (!generalStore.intervalChange) {
      clearInterval(intervalNotifications);
    }

    return () => {
      clearInterval(intervalNotifications);
    };
  }, [generalStore.intervalChange]);

  useEffect(() => {
    let total = 0;
    notifications.items.map((el) => {
      if (!el.isSeen) {
        total += 1;
      }

      return el;
    });

    setTotalUnseen(total > 9 ? '9+' : total);
  }, [notifications]);

  return (
    <div className='d-flex align-items-center position-relative px-2'>
      <OutsideClickHandler clickHandler={closeMenu}>
        <div className='d-flex align-items-center'>
          <div
            className={cx(styles.iconWrapper, 'cursor-pointer', 'me-2', showMenu ? styles.active : '')}
            onClick={openMenu}
            onKeyDown={openMenu}
          >
            <span className='text-white'>
              <i className={cx(styles.dropdown, 'icon-bell')} />
            </span>

            {totalUnseen ? <span className={cx(styles.notificationNo)}>{totalUnseen}</span> : ''}
          </div>

          <div className={styles.menuContainer}>
            <CSSTransition
              mountOnEnter
              unmountOnExit
              in={showMenu}
              timeout={400}
              classNames={{
                enter: styles.enter,
                enterActive: styles.enterActive,
                exit: styles.exit,
                exitActive: styles.exitActive,
              }}
            >
              <div className={cx(styles.menu, 'bg-white')}>
                <div className={cx('d-flex', 'align-items-center', 'justify-content-between', 'p-2', 'border-btm')}>
                  <span className={styles.heading}>{t('GENERAL.NOTIFICATIONS')}</span>

                  <Tooltip label={t('GENERAL.MARK_ALL_AS_READ')} position='left'>
                    <IconButton
                      icon='icon-comment-checked'
                      onClick={onMarkAllAsRead}
                      isDisabled={!totalUnseen}
                      size='md'
                      color='gray'
                    />
                  </Tooltip>
                </div>

                <div className={cx(styles.wrapper)}>
                  <Scrollbars
                    className='scrollbarsWrapper'
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                    onScrollFrame={(e) => handleScroll(e)}
                  >
                    {notifications.items.map((el) => (
                      <NotificationItem
                        key={el.id}
                        item={el}
                        onSeenNotification={onSeenNotification}
                        setShowMenu={setShowMenu}
                        onSelectNotification={onSelectNotification}
                      />
                    ))}
                  </Scrollbars>
                </div>
              </div>
            </CSSTransition>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default InboxNotifications;
