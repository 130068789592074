import React from 'react';

import t from 'utility/setTranslation';

import styles from './GoLiveOrderSummary.module.scss';
import { formatPrice } from '../../utility';

const InvoiceTableRow = ({ description, units, unitPrice, amount, billingPeriod, currency }) => (
  <tr className={styles.separator}>
    <td>
      <div className='d-flex flex-column ps-3 mb-2 py-2'>
        <h6 className='subtitle font-size-14 mb-0'>{t(description)}</h6>
        <p className='font-size-12 text-gray'>{billingPeriod}</p>
      </div>
    </td>
    <td className='font-size-14 text-gray'>{units}</td>
    <td className='font-size-14 text-gray'>{formatPrice(unitPrice, currency)}</td>
    <td className='font-size-14 font-weight-bold'>{formatPrice(amount, currency)}</td>
  </tr>
);

export default InvoiceTableRow;
