import { clientTypes } from 'constants/clientTypes';

import { quickStartGuidePages } from '../../../modules/Dashboard/Constants';

export const getResourceCenterItems = (setIsQuickstartChecklistVisible, isAdmin, isHR, accountType) => {
  const items = [
    {
      title: 'GENERAL.HELP_CENTER',
      subtitle: 'GENERAL.HELP_CENTER_SUB',
      icon: 'icon-request-suggestion',
      isActive: true,
      onClick: () => window.open('https://palmhr.crisp.help/en/', '_blank'),
      id: 'help-center',
    },
    {
      title: 'GENERAL.VIDEO_LIBRARY',
      subtitle: 'GENERAL.VIDEO_LIBRARY_SUB',
      icon: 'icon-video',
      isActive: false,
      onClick: () => {},
      id: 'video-library',
    },
    {
      title: 'GENERAL.PRODUCT_UPDATES',
      subtitle: 'GENERAL.PRODUCT_UPDATES_SUB',
      icon: 'icon-note-text',
      isActive: false,
      onClick: () => {},
      id: 'product-updates',
    },
  ];

  if ((isAdmin || isHR) && accountType === clientTypes.LIVE_INSTANCE) {
    items.splice(2, 0, {
      title: 'GENERAL.QUICKSTART_CHECKLIST',
      subtitle: 'GENERAL.QUICKSTART_CHECKLIST.SUB',
      icon: 'icon-check-list',
      isActive: true,
      onClick: () => setIsQuickstartChecklistVisible(true),
      id: 'quickstart-checklist',
    });
  }

  return items;
};

export const getQuickstartChecklistItems = (tasks, onOpenTask) =>
  tasks.map((task) => ({
    title: task.title,
    isCompleted: task.isCompleted,
    onClick: () => onOpenTask(task),
  }));

export const getNavigationPath = (taskKind) => {
  switch (taskKind) {
    case 'GO_LIVE_PAYROLL':
      return quickStartGuidePages.setupPayroll;
    case 'GO_LIVE_REQUESTS':
      return quickStartGuidePages.activeRequest;
    case 'GO_LIVE_WORKSCHEDULE':
      return quickStartGuidePages.workSchedule;
    case 'GO_LIVE_TIME':
      return quickStartGuidePages.addPolicies;
    case 'GO_LIVE_TEAM':
      return quickStartGuidePages.addTeam;
    default:
      return null;
  }
};
