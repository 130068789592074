import React, { useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import CardsSkeleton from 'components/skeletons/CardsSkeleton';
import CustomCalendarNav from 'modules/Dashboard/components/CustomCalendar/CustomCalendarNav';
import DaysHeader from 'modules/Time/shared/components/DaysHeader';
import t from 'utility/setTranslation';

import Day from './Day';
import styles from './Day.module.scss';

const CustomCalendar = ({ begining, onChangeMonthFn, data, calendatLoader, onSelectDayFn }) => {
  const [firstDayOfMonth, setFirstDayInMonth] = useState(moment(begining).startOf('month').toDate());
  const [lastDayOfMonth, setLastDayOfMonth] = useState(moment(begining).endOf('month').toDate());
  const [activeDay, setActiveDay] = useState(begining);

  const firstDayOfFirstWeek = moment(moment(firstDayOfMonth).startOf('month').toDate()).startOf('week').toDate();

  const onSelectDay = (e) => {
    setActiveDay(e);
    onSelectDayFn(e);
  };

  const renderCalendar = () => {
    const rows = [];
    let date = firstDayOfFirstWeek;
    for (let i = 0; i < 6; i += 1) {
      const days = [];
      for (let j = 0; j < 7; j += 1) {
        days.push(
          <Day
            key={moment(date).toString()}
            date={date}
            startDate={firstDayOfMonth}
            endDate={lastDayOfMonth}
            onSelectDay={onSelectDay}
            activeDay={activeDay}
            data={data}
          />
        );
        date = moment(date).add(1, 'days').toDate();
      }
      rows.push(
        <div className='d-flex justify-content-between' key={moment(date).isoWeek()}>
          {days}
        </div>
      );
    }
    return rows;
  };
  const onChangeMonth = (e) => {
    let firstDay;
    let lastDay;
    if (moment(firstDayOfMonth).format('YYYY-MM') !== moment(e).format('YYYY-MM')) {
      firstDay = moment(e).startOf('month').toDate();
      lastDay = moment(e).endOf('month').toDate();
    } else {
      firstDay = moment(firstDayOfMonth);
      lastDay = moment(firstDayOfMonth).endOf('month').toDate();
    }
    setFirstDayInMonth(firstDay);
    setLastDayOfMonth(lastDay);
    onChangeMonthFn(e);
  };

  return (
    <>
      <div>
        <CustomCalendarNav onChange={onChangeMonth} disableOnChange={calendatLoader} begining={begining} />
      </div>
      <div className='mt-4 mb-2'>
        <DaysHeader />
      </div>
      {calendatLoader ? <CardsSkeleton col={12} height='336px' num={1} /> : renderCalendar()}
      <div className='w-100 d-flex justify-content-start align-items-center mt-4'>
        <i className={cx(styles.bullet, styles.anniversarieAdvanced, 'icon-bullet-point')} />
        <div className={cx(styles.label, 'me-2')}>{t('EMPLOYEE.FLAG.ANNIVERSARY')}</div>
        <i className={cx(styles.bullet, styles.birthdayAdvanced, 'icon-bullet-point')} />
        <div className={cx(styles.label, 'me-2')}>{t('EMPLOYEE.FLAG.BIRTHDAY')}</div>
        <i className={cx(styles.bullet, styles.customEvent, 'icon-bullet-point')} />
        <div className={styles.label}>{t('GENERAL.CUSTOM_EVENTS')}</div>
      </div>
    </>
  );
};

CustomCalendar.propTypes = {
  onChangeMonthFn: PropTypes.func,
  begining: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.object]),
  data: PropTypes.object,
  calendatLoader: PropTypes.bool,
  onSelectDayFn: PropTypes.func,
};

CustomCalendar.defaultProps = {
  onChangeMonthFn: undefined,
  begining: new Date(),
  data: null,
  calendatLoader: false,
  onSelectDayFn: undefined,
};

export default CustomCalendar;
