import React, { useState, useEffect } from 'react';

import { Wrapper } from '@googlemaps/react-wrapper';
import { isLatLngLiteral } from '@googlemaps/typescript-guards';
import { createCustomEqual } from 'fast-equals';
import PropTypes from 'prop-types';

import MyMapComponent from 'components/map/MyMapComponent';
import SpinnerRing from 'components/Spinner/SpinnerRing';

const render = (status) => status === 'LOADING' && <SpinnerRing />;

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
  if (!window.google) return deepEqual(a, b);

  if (
    isLatLngLiteral(a) ||
    (window.google && a instanceof window.google.maps.LatLng) ||
    isLatLngLiteral(b) ||
    (window.google && b instanceof window.google.maps.LatLng)
  ) {
    try {
      return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
    } catch (e) {
      return deepEqual(a, b);
    }
  }

  return deepEqual(a, b);
});

function useDeepCompareMemoize(value) {
  const ref = React.useRef(null);

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

const MapComponent = ({
  additionalLocations,
  latitude,
  longitude,
  radius,
  onUpdateLatLng,
  listOfMarkers,
  readMode,
  zoom,
  msgInfo,
  fitBounds,
  mainLocation,
  zoomToBounds,
}) => {
  const [clicks, setClicks] = useState({ lat: latitude, lng: longitude });
  const [zoomProp, setZoomProp] = useState('');
  const [center, setCenter] = useState({ lat: latitude, lng: longitude });

  const onClick = (e) => {
    if (!readMode) {
      onUpdateLatLng(e.latLng.toJSON().lat, e.latLng.toJSON().lng);
      setClicks(e.latLng);
    }
  };

  const onIdle = (m) => {
    setZoomProp(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };

  const onChange = (lat, lng) => {
    onUpdateLatLng(lat, lng);
    setClicks({ lat, lng });
  };

  useEffect(() => {
    setClicks({ lat: latitude || 24.693529747911377, lng: longitude || 46.676083121103154 });
    setCenter({ lat: latitude || 24.693529747911377, lng: longitude || 46.676083121103154 });
  }, [latitude, longitude]);

  useEffect(() => {
    setZoomProp(zoom);
  }, [zoom]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Wrapper apiKey='AIzaSyB3JIhj-0MR211Nai91ENQulmCmiRwEQt0' libraries={['places']} render={render}>
        <MyMapComponent
          additionalLocations={additionalLocations}
          readMode={readMode}
          center={center}
          onChange={onChange}
          onClick={onClick}
          onIdle={onIdle}
          zoom={zoomProp}
          fullscreenControl={false}
          mapTypeControl={false}
          scaleControl={false}
          streetViewControl={false}
          rotateControl={false}
          clicks={clicks}
          radius={radius}
          msgInfo={msgInfo}
          listOfMarkers={listOfMarkers}
          useDeepCompareEffectForMaps={useDeepCompareEffectForMaps}
          fitBounds={fitBounds}
          mainLocation={mainLocation}
          zoomToBounds={zoomToBounds}
        />
      </Wrapper>
    </div>
  );
};

MapComponent.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  radius: PropTypes.number,
  onUpdateLatLng: PropTypes.func,
  listOfMarkers: PropTypes.array,
  readMode: PropTypes.bool,
  zoom: PropTypes.number,
  additionalLocations: PropTypes.arrayOf(PropTypes.object),
};

MapComponent.defaultProps = {
  latitude: 24.693529747911377,
  longitude: 46.676083121103154,
  radius: 10,
  onUpdateLatLng: undefined,
  listOfMarkers: [],
  readMode: false,
  zoom: 10,
  additionalLocations: [],
};

export default MapComponent;
