import React from 'react';

import GettingStartedWizardModal from 'components/GettingStartedWizardModal/GettingStartedWizardModal';
import { clientTypes } from 'constants/clientTypes';

import { generateWizardImagesSteps } from './wizardconfig';
import { getStartedGuidePages } from '../../Constants';

const DashboardGettingStarted = () => (
  <GettingStartedWizardModal
    generateWizardImagesSteps={() => generateWizardImagesSteps}
    accountTypes={[clientTypes.TRIAL, clientTypes.TEST_SITE]}
    trackedPages={getStartedGuidePages}
  />
);

export default DashboardGettingStarted;
