export const PAYMENT_TYPE_VALUES = {
  ONLINE_PAYMENT: 'ONLINE',
  OFFLINE_PAYMENT: 'OFFLINE',
};
export const ENVIRONMENT_PLAN_VALUES = {
  EXISTING_DATA: 'EXISTING_DATA',
  CLEAN_DATA: 'CLEAN_DATA',
};

export const PAYMENT_SCHEDULE_VALUES = {
  MONTH: 'MONTH',
  QUARTERLY: 'QUARTERLY',
  YEAR: 'YEAR',
  SEMI_ANNUAL: 'SEMI_ANNUAL',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};

export const SUBSCRIPTION_TYPE_VALUES = {
  ONE_TIME: 'ONE_TIME_CHARGE',
  SUBSCRIPTION: 'SUBSCRIPTION',
};

export const PAYMENT_TYPE_OPTIONS = [
  {
    id: PAYMENT_TYPE_VALUES.ONLINE_PAYMENT,
    nameKey: 'GENERAL.ONLINE_PAYMENT',
    description: 'NAVBAR.ONLINE_PAYMENT_DESCRIPTION',
  },
  {
    id: PAYMENT_TYPE_VALUES.OFFLINE_PAYMENT,
    nameKey: 'GENERAL.OFFLINE_PAYMENT',
    description: 'NAVBAR.OFFLINE_PAYMENT_DESCRIPTION',
  },
];

export const ORDER_SUMMARY_COLUMNS = [
  { title: 'Description', key: 'description', className: 'w-40' },
  { title: 'Units', key: 'units', className: 'w-20' },
  { title: 'Unit Price', key: 'unitPrice', className: 'w-20' },
  { title: 'Amount', key: 'amount', className: 'w-20' },
];

export const ENVIRONMENT_PLAN_OPTIONS = [
  {
    id: ENVIRONMENT_PLAN_VALUES.EXISTING_DATA,
    nameKey: 'NAVBAR.WITH_EXISTING_DATA',
    description: 'NAVBAR.WITH_EXISTING_DATA_DESCRIPTION',
  },
  {
    id: ENVIRONMENT_PLAN_VALUES.CLEAN_DATA,
    nameKey: 'NAVBAR.WITH_CLEAR_DATA',
    description: 'NAVBAR.WITH_CLEAR_DATA_DESCRIPTION',
  },
];

export const PAYMENT_SCHEDULE_OPTIONS = [
  {
    id: PAYMENT_SCHEDULE_VALUES.YEAR,
    nameKey: 'GENERAL.YEARLY',
  },
  {
    id: PAYMENT_SCHEDULE_VALUES.QUARTERLY,
    nameKey: 'GENERAL.QUARTERLY',
  },
  {
    id: PAYMENT_SCHEDULE_VALUES.MONTH,
    nameKey: 'GENERAL.MONTHLY',
  },
];

export const minAllowedNumberOfUsers = 10;

export const goLiveInitialValues = {
  payment: 'ONLINE',
  continueWith: '',
  subscription: {
    plan: '',
    numberOfUsers: null,
    unitPrice: 0,
    currency: '',
    discount: 0,
    type: SUBSCRIPTION_TYPE_VALUES.SUBSCRIPTION,
    addonSubscriptions: [],
    country: '',
    periodUnit: '',
    startDate: new Date().toISOString(),
  },
  subdomain: '',
  billingInfo: {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    phone: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    vatNumber: '',
  },
  isPaymentComplete: false,
  isTermsAccepted: false,
};

export const orderSummaryInitialValues = {
  items: [
    {
      description: '',
      billingPeriod: null,
      unitPrice: 0,
      units: 0,
      amount: 0,
    },
  ],
  subtotal: 0,
  tax: 0,
  total: 0,
  taxPercentage: 5,
};
