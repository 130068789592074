import React from 'react';

import InputsSectionContainer from 'components/InputsSectionContainer/InputsSectionContainer';
import t from 'utility/setTranslation';

import GoLiveBillingInformation from '../GoLiveBillingInformation/GoLiveBillingInformation';
import GoLivePayment from '../GoLivePayment/GoLivePayment';

const GoLivePaymentSettings = (formik) => (
  <section className='d-flex flex-column gap-4 mt-4 pb-4'>
    <InputsSectionContainer title={t('PLG.BILLING_INFORMATION')} subTitle={t('PLG.BILLING_INFORMATION_DESC')}>
      <GoLiveBillingInformation formik={formik} />
    </InputsSectionContainer>
    <InputsSectionContainer title={t('PLG.PAYMENT_TYPE')} subTitle={t('PLG.PAYMENT_TYPE_DESC')}>
      <GoLivePayment formik={formik} />
    </InputsSectionContainer>
  </section>
);

export default GoLivePaymentSettings;
