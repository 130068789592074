function arrayColumn(array, column) {
  return array.map((item) => item[column]);
}

function implode(values, glue = ',') {
  return values.join(glue);
}

function sortByProperty(array, direction, property) {
  return array.sort((a, b) => {
    const directions = ['asc', 'desc'];
    const dir = directions.includes(direction) ? direction : 'asc';
    const isDesc = dir === 'desc';
    const av = a[property] ? a[property] : '';
    const bv = b[property] ? b[property] : '';

    if (av > bv) {
      return isDesc ? -1 : 1;
    }

    if (av < bv) {
      return isDesc ? 1 : -1;
    }

    return 0;
  });
}

function removeProperties(array, properties) {
  const newA = {};
  Object.keys(array).forEach((key) => {
    if (properties.includes(key)) return;
    newA[key] = array[key];
  });
  return newA;
}

function removeBatchProperties(array, properties) {
  const newA = [];
  Object.keys(array).forEach((key) => {
    newA.push(removeProperties(array[key], properties));
  });
  return newA;
}

export default { arrayColumn, implode, sortByProperty, removeProperties, removeBatchProperties };
