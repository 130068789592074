import step1ImageEN from 'assets/img/getting-started/time/step_1_time_en.png';
import step1ImageAR from 'assets/img/getting-started/time/step_1_time_ar.png';
import step2ImageEN from 'assets/img/getting-started/time/step_2_time_en.png';
import step2ImageAR from 'assets/img/getting-started/time/step_2_time_ar.png';


export const generateWizardImagesSteps = [
  {
    id: 1,
    imageEN: step1ImageEN,
    imageAR: step1ImageAR,
  },
  {
    id: 2,
    imageEN: step2ImageEN,
    imageAR: step2ImageAR,
  },
];
