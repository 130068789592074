import React, { useContext, useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { ThemeContext } from 'themes/ThemeContext';

const BrandImage = ({ name, alt, customClass }) => {
  const { themeState } = useContext(ThemeContext);
  const enterpriseName = themeState?.companyName || 'palmhr';
  const [src, setSrc] = useState(null);
  const mounted = useRef(false);

  const loadImage = () => {
    const path = enterpriseName === 'palmhr' ? '' : 'enterprise/khadamati/';
    import(`assets/img/${path}${name}`)
      .then((image) => {
        if (mounted.current) setSrc(image.default || '');
      })
      .catch(() => {
        import(`assets/img/${path}logo_primary.png`).then((img) => {
          if (mounted.current) {
            setSrc(img.default || '');
          }
        });
      });
  };

  useEffect(() => {
    loadImage();
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return <img className={cx(customClass, 'w-100')} src={src} alt={alt} />;
};

BrandImage.propTypes = {
  name: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  customClass: PropTypes.string,
};

BrandImage.defaultProps = {
  customClass: '',
};

export default BrandImage;
