import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useAccountSubscriptionData,
  useMutateRecordPageVisit,
  useRecordingPageVisits,
} from '../topNavigation/hooks/useAccount';

const usePageVisitTracker = (trackedPages, accountTypes) => {
  const location = useLocation();
  const currentRoute = location.pathname;

  const { mutate } = useMutateRecordPageVisit();
  const { data: visitedPages, isLoading } = useRecordingPageVisits();
  const { data: accountSubscriptionData } = useAccountSubscriptionData();

  const clientType = accountSubscriptionData?.data.account.clientType;

  const isClientTypeAllowed = accountTypes.includes(clientType);

  const shouldTrackVisit = isClientTypeAllowed && Object.values(trackedPages).includes(currentRoute);
  const hasVisitedThisPage = visitedPages?.data.items.some((item) => item.page === currentRoute);

  useEffect(() => {
    if (shouldTrackVisit && !hasVisitedThisPage) {
      mutate({ page: currentRoute });
    }
  }, [currentRoute, mutate, hasVisitedThisPage, shouldTrackVisit]);

  return { hasVisitedThisPage, shouldTrackVisit, isLoading };
};

export default usePageVisitTracker;
