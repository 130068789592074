import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ProfileTwoFactorSettings from 'api/ProfileTwoFactorSettings';
import ConfirmationBox from 'components/ConfirmationBox';
import Modal from 'components/Modal';
import RecoveryCodes from 'modules/Employee/shared/RecoveryCodes';
import t from 'utility/setTranslation';

import styles from './SettingsAuth.module.scss';
import SettingsAuthEmail from './SettingsAuthEmail';
import SettingsAuthGoogle from './SettingsAuthGoogle';

const SettingsAuth = ({ setIsFormDirty, setSending, setSaved, employeeId, setAlertBoxMsg, configData }) => {
  const [savingSub, setSavingSub] = useState(false);
  const [savedSub, setSavedSub] = useState(false);
  const [modalOpened, setModalOpened] = useState('');
  const [currentSettings, setCurrentSettings] = useState({});
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [isRecoveryCodesOpen, setIsRecoveryCodesOpen] = useState(false);
  const [recoveryCodes, setRecoveryCodes] = useState([]);

  useEffect(() => {
    setSending(true);
    ProfileTwoFactorSettings.getCurrentSettings()
      .then((res) => {
        setCurrentSettings(res.data);
        setSending(false);
      })
      .catch(() => {
        setSending(false);
      });
  }, []);

  const enable2factor = (data) => {
    setSavingSub(true);
    ProfileTwoFactorSettings.enable2Factor(data)
      .then((res) => {
        setSavingSub(false);
        setSavedSub(true);
        setCurrentSettings(res.data);
        setIsFormDirty(false);

        if (modalOpened !== 'google') {
          setAlertBoxMsg(t('GENERAL.YOU_HAVE_TO_BE_LOGOUT'));
          setTimeout(() => {
            localStorage.removeItem('store');
            window.location.href = '/login';
            setAlertBoxMsg('');
          }, 2500);
        } else {
          setIsRecoveryCodesOpen(true);
        }
        setTimeout(() => {
          setSavedSub(true);
          setModalOpened('');
        }, 1000);
      })
      .catch(() => {
        setSavingSub(false);
      });
  };

  const disable2factor = () => {
    setIsConfirmModalOpened(false);
    setSending(true);
    ProfileTwoFactorSettings.disable2Factor(null)
      .then((res) => {
        setSending(false);
        setSaved(true);
        setCurrentSettings(res.data);
        setIsFormDirty(false);
      })
      .catch(() => {
        setSaved(true);
        setSending(false);
      });
  };
  const confirmMessage = t('GENERAL.CONFIRM_DEACTIVATE_2FA');

  return (
    <div className='pb-8'>
      <div className='d-flex justify-content-between mb-4'>
        <h5 className='fw-bold font-size-16 m-0'>{t('GENERAL.2_FACTOR_AUTH')}</h5>
      </div>

      {(configData.mode === 'GOOGLE' || configData.mode === 'ANY') && (
        <div
          className={cx(
            styles.authDiv,
            'd-flex justify-content-start align-items-start',
            currentSettings.mode === 'google_2fa' && styles.active
          )}
        >
          <div className='me-2'>
            <i className='icon-google' />
          </div>

          <div className={cx(styles.authWrapper)}>
            <h5>{t('GENERAL.GOOGLE_AUTH')}</h5>
            <p>{t('GENERAL.GOOGLE_AUTH_INFO')}</p>

            <div
              className={cx(
                styles.button,
                currentSettings.mode === 'google_2fa' && 'text-red',
                !(!currentSettings.enabled || (currentSettings.enabled && currentSettings.mode === 'google_2fa')) &&
                  'visibility-hidden'
              )}
              onClick={() => {
                if (currentSettings.mode === 'google_2fa') {
                  setIsConfirmModalOpened(true);
                } else {
                  setModalOpened('google');
                }
              }}
            >
              {currentSettings.mode === 'google_2fa' ? t('GENERAL.DEACTIVATE') : t('GENERAL.ACTIVATE')}
            </div>
          </div>
        </div>
      )}
      {(configData.mode === 'EMAIL' || configData.mode === 'ANY') && (
        <div
          className={cx(
            styles.authDiv,
            'd-flex justify-content-start align-items-start',
            currentSettings.mode === 'email_2fa' && styles.active
          )}
        >
          <div className='me-2'>
            <i className='icon-mail' />
          </div>

          <div className={styles.authWrapper}>
            <h5>{t('GENERAL.EMAIL')}</h5>
            <p>{t('GENERAL.EMAIL_AUTH_INFO')}</p>

            <div
              className={cx(
                styles.button,
                currentSettings.mode === 'email_2fa' && 'text-red',
                !(!currentSettings.enabled || (currentSettings.enabled && currentSettings.mode === 'email_2fa')) &&
                  'visibility-hidden'
              )}
              onClick={() => {
                if (currentSettings.mode === 'email_2fa') {
                  setIsConfirmModalOpened(true);
                } else {
                  setModalOpened('email');
                }
              }}
            >
              {currentSettings.mode === 'email_2fa' ? t('GENERAL.DEACTIVATE') : t('GENERAL.ACTIVATE')}
            </div>
          </div>
        </div>
      )}

      <Modal
        isOpen={!!modalOpened}
        styles={{ width: '440px', padding: '0', transform: 'none', top: '150px' }}
        onRequestClose={() => {
          setModalOpened(false);
        }}
        showHeader={false}
      >
        {modalOpened === 'google' && (
          <SettingsAuthGoogle
            close={() => {
              setModalOpened('');
            }}
            onVerify={enable2factor}
            saved={savedSub}
            sending={savingSub}
            setRecoveryCodes={setRecoveryCodes}
          />
        )}
        {modalOpened === 'email' && (
          <SettingsAuthEmail
            close={() => {
              setModalOpened('');
            }}
            onVerify={enable2factor}
            saved={savedSub}
            sending={savingSub}
            employeeId={employeeId}
          />
        )}
      </Modal>
      <Modal
        isOpen={isRecoveryCodesOpen}
        styles={{ width: '480px', height: '480px', padding: '0' }}
        onRequestClose={() => {
          setAlertBoxMsg(t('GENERAL.YOU_HAVE_TO_BE_LOGOUT'));
          setIsRecoveryCodesOpen(false);
          setTimeout(() => {
            localStorage.removeItem('store');
            window.location.href = '/login';
            setAlertBoxMsg('');
          }, 2500);
        }}
        showHeader={false}
        showDirtyForm
      >
        <RecoveryCodes data={recoveryCodes} />
      </Modal>
      <Modal
        isOpen={isConfirmModalOpened}
        styles={{ minHeight: '260px' }}
        onRequestClose={() => {
          setIsConfirmModalOpened(false);
        }}
        showHeader={false}
      >
        <ConfirmationBox
          close={() => {
            setIsConfirmModalOpened(false);
          }}
          action={() => {
            disable2factor();
          }}
          text={confirmMessage}
          title='GENERAL.DELETE'
          type='danger'
        />
      </Modal>
    </div>
  );
};

SettingsAuth.propTypes = {
  setIsFormDirty: PropTypes.func,
  setSending: PropTypes.func.isRequired,
  setSaved: PropTypes.func.isRequired,
  setAlertBoxMsg: PropTypes.func.isRequired,
  configData: PropTypes.object.isRequired,
};

SettingsAuth.defaultProps = {
  setIsFormDirty: undefined,
};

export default SettingsAuth;
