import React from 'react';

import cx from 'classnames';
import { useParams } from 'react-router';

import t from 'utility/setTranslation';

import CandidateApplicationsChartCard from './components/CandidateApplicationsChartCard/CandidateApplicationsChartCard';
import StatisticsDataCard from './components/StatisticsDataCard/StatisticsDataCard';
import { useQueryGeneralStatistics } from './hooks/useStatistics';
import styles from './Statistics.module.scss';

const Statistics = () => {
  const { id } = useParams();
  const { isLoading, data } = useQueryGeneralStatistics(id);
  const statisticsData = data?.data || {};

  return (
    <section className={cx(styles.Statistics, 'gap-4 mx-5 mt-4')}>
      <CandidateApplicationsChartCard
        jobPostPublishDate={statisticsData.publishedAt}
        jobPostArchiveDate={statisticsData.archivedAt}
        isLoading={isLoading}
        jobPostId={id}
      />
      <section className={cx(styles.Statistics__data, 'gap-2a')}>
        <StatisticsDataCard
          isLoading={isLoading}
          data={statisticsData.applicationCount}
          title={t('GENERAL.TOTAL_APPLICATIONS')}
          tooltipText={t('GENERAL.TOTAL_AMOUNT_OF_CANDIDATES')}
          unit={t('GENERAL.CANDIDATES')}
        />
        <StatisticsDataCard
          isLoading={isLoading}
          data={Number(statisticsData.applicationDailyAvgCount)?.toFixed(2)}
          title={t('GENERAL.AVERAGE_APPLICATIONS')}
          tooltipText={t('GENERAL.AVERAGE_NUMBER_OF_CANDIDATES')}
          unit={t('GENERAL.AVERAGE_CANDIDATES_PER_DAY')}
        />
      </section>
    </section>
  );
};

export default Statistics;
