import { useQuery, useMutation } from '@tanstack/react-query';

import { BillableHoursAccessAPI } from 'api/BillableHours/AccessApi';
import { ApprovalsAPI } from 'api/BillableHours/ApprovalsApi';
import { MyProjectsAPI } from 'api/BillableHours/MyProjects';

export const baseQueryKeys = {
  getRequestsMonthlyProjects: 'getRequestsMonthlyProjects',
  getRequestsPerEmployeesToBeReviewed: 'getRequestsPerEmployeesToBeReviewed',
};

export const queryKeys = {
  getMonthlyRequests: (params) => ['getRequestsMonthly', params],
  getBillableHoursAccess: (params) => ['getBillableHoursAccess', params],
  getMonthlyRequestsProjects: (params) => [
    baseQueryKeys.getRequestsMonthlyProjects,
    params.startDate,
    JSON.stringify(params),
  ],
  saveRequestDraft: (id) => ['saveRequestDraft', id],
  submitRequest: (id) => ['saveRequestDraft', id],
  approvalsMonths: (startDate) => ['approvalsMonths', startDate],
  employeesToBeReviewed: (params) => ['employeesToBeReviewed', JSON.stringify(params)],
  getRequestsPerEmployeesToBeReviewed: ({ employeeId, params }) => [
    baseQueryKeys.getRequestsPerEmployeesToBeReviewed,
    employeeId,
    JSON.stringify(params),
  ],
  approveRequest: ['approveRequest'],
  approveRequests: ['approveRequests'],
  rejectRequest: ['rejectRequest'],
  rejectRequests: ['rejectRequests'],
  exportRequest: (id) => ['rejectRequest', id],
  exportRequests: ['approveRequest'],
};

export const useQueryMonthlyRequests = (params) =>
  useQuery(queryKeys.getMonthlyRequests(params), () => MyProjectsAPI.getMonthlyRequests(params));

export const useQueryBillableHoursAccess = (params) =>
  useQuery(queryKeys.getBillableHoursAccess(params), () => BillableHoursAccessAPI.getBillableHoursAccess(params));

export const useQueryMonthlyRequestProjects = (params, options) =>
  useQuery(
    queryKeys.getMonthlyRequestsProjects(params),
    () => MyProjectsAPI.getMonthlyRequestProjects(params),
    options
  );

export const useQueryApprovalsMonths = (startDate) =>
  useQuery(queryKeys.approvalsMonths(startDate), () => ApprovalsAPI.getApprovalsMonths(startDate));

export const useQueryEmployeesToBeReviewed = (params) =>
  useQuery(queryKeys.employeesToBeReviewed(params), () => ApprovalsAPI.getEmployeesToBeReviewed(params));

export const useQueryExportRequest = ({ requestId, options = {} }) =>
  useQuery(queryKeys.exportRequest(requestId), () => ApprovalsAPI.getRequestReport(requestId), options);

export const useQueryExportRequests = ({ params, options = {} }) =>
  useQuery(queryKeys.exportRequests, () => ApprovalsAPI.exportRequests(params), options);

export const useQueryRequestsPerEmployeeToBeReviewed = ({ employeeId, params, options = {} }) =>
  useQuery(
    queryKeys.getRequestsPerEmployeesToBeReviewed({ employeeId, params }),
    () => ApprovalsAPI.getRequestsPerEmployeesToBeReviewed({ employeeId, params }),
    options
  );

export const useMutationSaveRequestDraft = (id) =>
  useMutation(queryKeys.saveRequestDraft(id), MyProjectsAPI.saveMonthlyRequestDraft);

export const useApproveRequest = () => useMutation(queryKeys.approveRequest, ApprovalsAPI.approveRequest);

export const useApproveRequests = () => useMutation(queryKeys.approveRequests, ApprovalsAPI.approveRequests);

export const useRejectRequest = () => useMutation(queryKeys.rejectRequest, ApprovalsAPI.rejectRequest);
export const useRejectRequests = () => useMutation(queryKeys.rejectRequests, ApprovalsAPI.rejectRequests);

export const useMutationSubmitRequest = (id) =>
  useMutation(queryKeys.submitRequest(id), MyProjectsAPI.submitMonthlyRequest);
