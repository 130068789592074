import React from 'react';

import styles from './Notification.module.scss';

const Notification = ({ data }) => (
  <div onClick={() => {}}>
    <h6 className={styles.title}>{data.data['firebase-messaging-msg-data'].notification.title}</h6>
    <p className={styles.content}>{data.data['firebase-messaging-msg-data'].notification.body}</p>
  </div>
);

Notification.propTypes = {};

Notification.defaultProps = {};

export default Notification;
