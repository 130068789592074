import http from './Api';

class CompanyLocationsApi {
  static getCompanyLocations(filters = '') {
    return http.get(`settings/account/company-locations${filters}`);
  }

  static assignLocations(data, filters = '') {
    return http.post(`profile/contract/locations/bulk-assign${filters}`, data);
  }

  static assignAdditionalLocations(data, filters = '') {
    return http.post(`profile/contract/locations/bulk-assign-additional${filters}`, data);
  }

  static addCompanyLocation(data) {
    return http.post(`settings/account/company-locations`, data);
  }

  static getCompanyLocationById(locationId) {
    return http.get(`settings/account/company-locations/${locationId}`);
  }

  static editCompanyLocation(locationId, data) {
    return http.put(`settings/account/company-locations/${locationId}`, data);
  }

  static deleteCompanyLocation(locationId) {
    return http.delete(`settings/account/company-locations/${locationId}`);
  }

  static getTimezones(filters = '') {
    return http.get(`settings/timezones${filters}`);
  }

  static showEmployeesAssignedToLocation(locationId, filters = '') {
    return http.get(`settings/account/company-locations/${locationId}/employees${filters}`);
  }
}
export default CompanyLocationsApi;
