import React, { lazy, Suspense, useContext, useState } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import cx from 'classnames';
import { useRoutes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import { AuthContextProvider } from 'auth/AuthContext';
import { IsLoggedInContext } from 'auth/IsLoggedInContext';
import { RowDensityContextProvider } from 'components/RowDensity/RowDensityContext';
import SmallScreenWarning from 'components/SmallScreenWarning';
import SharedLogic from 'hoc/SharedLogic';
import { PerformanceContextProvider } from 'modules/CompanySettings/Team/Performance/PerformanceContext';
import { isMobileFriendlyRoute } from 'shared/isMobileFriendlyRoute';
import { ThemeContextProvider } from 'themes/ThemeContext';
import MainNavBar from 'topNavigation/NavBar/MainNavBar';
import usePrivateRoutes from 'topNavigation/Router/hooks/usePrivateRoutes';
import usePublicRoutes from 'topNavigation/Router/hooks/usePublicRoutes';
import { PublicRoutes } from 'topNavigation/Router/routes';

const EngagementCenter = lazy(() => import('modules/EngagementCenter/EngagementCenter'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'offlineFirst',
      refetchOnWindowFocus: false,
    },
  },
});

const MainNavBarAndEngagement = ({ isLoggedIn, isErrorRoute, currentPath }) => {
  const [isCreateNewTask, setIsCreateNewTask] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);

  if (isLoggedIn && !isErrorRoute && !isMobileFriendlyRoute(currentPath)) {
    return (
      <>
        <Suspense
          fallback={
            <div
              className='height-100vh position-fixed'
              style={{ width: '57px', insetInlineStart: 0, backgroundColor: '#25455b' }}
            />
          }
        >
          <EngagementCenter
            notification={selectedNotification}
            setIsCreateNewTask={setIsCreateNewTask}
            isCreateNewTask={isCreateNewTask}
          />
        </Suspense>
        <MainNavBar onCreateNewTask={setIsCreateNewTask} onSelectNotification={setSelectedNotification} />
      </>
    );
  }

  return null;
};

const AppRouter = () => {
  const {
    logInState: { isLoggedIn },
  } = useContext(IsLoggedInContext);

  const nonAuthUserRoutes = usePublicRoutes();
  const authUserRoutes = usePrivateRoutes();

  const currentPath = window.location.pathname;
  const isErrorRoute = [PublicRoutes.error404, PublicRoutes.error403].includes(currentPath);

  const AppRoutes = () => useRoutes(isLoggedIn ? authUserRoutes : nonAuthUserRoutes);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeContextProvider>
        <AuthContextProvider>
          <BrowserRouter>
            <SharedLogic>
              <RowDensityContextProvider>
                <PerformanceContextProvider>
                  <>
                    <div className={cx(isMobileFriendlyRoute(currentPath) ? 'd-none' : 'smallScreen')}>
                      <SmallScreenWarning />
                    </div>
                    <div
                      className={cx(
                        isMobileFriendlyRoute
                          ? `d-flex flex-column height-100vh ${
                              isLoggedIn && !isMobileFriendlyRoute(currentPath) ? 'ms-8' : ''
                            }`
                          : 'bigScreen height-100vh'
                      )}
                    >
                      <MainNavBarAndEngagement
                        isLoggedIn={isLoggedIn}
                        isErrorRoute={isErrorRoute}
                        currentPath={currentPath}
                      />
                      <AppRoutes />
                    </div>
                  </>
                </PerformanceContextProvider>
              </RowDensityContextProvider>
            </SharedLogic>
          </BrowserRouter>
        </AuthContextProvider>
      </ThemeContextProvider>
    </QueryClientProvider>
  );
};

export default AppRouter;
