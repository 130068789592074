import React from 'react';

import cx from 'classnames';
import t from 'utility/setTranslation';

import styles from '../../ResourceCenter.module.scss';

const ResourceCenterItem = ({ item, activeLang }) => (
  <div
    className={cx(
      'd-flex justify-content-between border-btm py-3',
      item.isActive ? 'cursor-pointer' : '',
      activeLang === 'en' ? 'flex-row-reverse' : 'flex-row'
    )}
    onClick={item.onClick}
    id={item.id}
  >
    <div className={cx('d-flex gap-4', activeLang === 'en' ? 'flex-row-reverse' : 'flex-row')}>
      <i
        className={cx(
          item.icon,
          'd-flex justify-content-center align-items-center text-primary font-size-24',
          styles.mainListItemIcon
        )}
      />
      <div className='d-flex flex-column' style={{ width: '210px' }}>
        <h6 className={cx('subtitle m-0', activeLang === 'en' ? 'text-end' : 'text-start')}>{t(item.title)}</h6>
        <p className={cx('text-gray-500 font-size-14 m-0', activeLang === 'en' ? 'text-end' : 'text-start')}>
          {item.isActive ? t(item.subtitle) : t('GENERAL.COMMING_SOON')}
        </p>
      </div>
    </div>
    <span
      className={cx(
        'd-flex justify-content-center align-items-center text-center text-gray-500',
        activeLang === 'en' ? 'icon-chevron-left' : 'icon-chevron-right'
      )}
    />
  </div>
);

export default ResourceCenterItem;
