import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import PayApi from 'api/PayApi';
import HeaderTitle from 'components/HeaderTitle/HeaderTitle';
import NavBar from 'components/Navigation/NavBar';
import t from 'utility/setTranslation';

const Header = ({ onSelectView, active, canSeePayroll, payInsightsAccess }) => {
  const url = '/pay';
  const [progress, setProgress] = useState(0);
  const [navItems, setNavItems] = useState([{ title: 'compensation', href: `${url}/compensation` }]);

  useEffect(() => {
    PayApi.getSetupProgress()
      .then((res) => {
        setProgress(res.data.progress);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (canSeePayroll) {
      const items = [
        { title: 'compensation', href: `${url}/compensation` },
        { title: 'payroll', href: `${url}/payroll` },
      ];

      if (progress === 100 && payInsightsAccess) {
        items.push({ title: 'insights', href: `${url}/insights` });
      }

      setNavItems(items);
    }
  }, [canSeePayroll, progress, payInsightsAccess]);

  return (
    <div className='pt-2'>
      <div className={cx('position-relative', 'pb-3')}>
        <div className='d-flex justify-content-between align-items-center pt-3'>
          <HeaderTitle className='mb-4 mt-1'>{t('GENERAL.PAY')}</HeaderTitle>
        </div>
        <NavBar selectView={onSelectView} active={active} items={navItems} type='link' />
      </div>
    </div>
  );
};

Header.propTypes = {
  active: PropTypes.string.isRequired,
  onSelectView: PropTypes.func.isRequired,
};

export default Header;
