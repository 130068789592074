import { useRef } from 'react';

import _ from 'lodash';
// import { Exception } from 'node-sass';

import arrayUtil from 'utility/arrayUtil';
import eventUtil from 'utility/eventUtil';
import queryParams from 'utility/queryParamsUtil';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

const components = () => ({
  container: 'container',
  toolbar: 'toolbar',
  fchipscontainer: 'fchipscontainer',
  tcontainer: 'tcontainer',
  table: 'table',
  thead: 'thead',
  tbody: 'tbody',
  tfoot: 'tfoot',
  cfoot: 'cfoot',
});

const genId = (tableId = 'table-id', component = components().table) => {
  if (!component || component === components().table || !Object.keys(components()).includes(component)) return tableId;
  return `${tableId}-${component}`;
};

const prepareRefs = (ref) => {
  if (!ref || !ref.current) {
    return useRef({
      cRef: useRef(null),
      tlbRef: useRef(null),
      tcRef: useRef(null),
      tRef: useRef(null),
      thRef: useRef(null),
      tbRef: useRef(null),
      tfRef: useRef(null),
      cfRef: useRef(null),
    });
  }

  const { cRef, tlbRef, tcRef, tRef, thRef, tbRef, tfRef, cfRef } = ref.current;

  return useRef({
    cRef: cRef || useRef(null),
    tlbRef: tlbRef || useRef(null),
    tcRef: tcRef || useRef(null),
    tRef: tRef || useRef(null),
    thRef: thRef || useRef(null),
    tbRef: tbRef || useRef(null),
    tfRef: tfRef || useRef(null),
    cfRef: cfRef || useRef(null),
  });
};

const userFilters = (filterConfig) => {
  const filters = { options: [], values: {} };

  Object.keys(filterConfig).forEach((filter) => {
    const config = filterConfig[filter];

    if (!config.hasOwnProperty('title')) throw new Error(`User filters: config 'title' is required.`);
    if (!config.hasOwnProperty('filterData')) throw new Error(`User filters: config 'filterData' is required.`);
    if (!config.hasOwnProperty('dataValueIndex')) throw new Error(`User filters: config 'dataValueIndex' is required.`);
    if (!config.hasOwnProperty('dataLabelIndex')) throw new Error(`User filters: config 'dataLabelIndex' is required.`);

    const options = [];
    const values = [];
    const data = typeof config.filterData === 'function' ? config.filterData() : config.filterData;

    data.forEach((item) => {
      const value = item[config.dataValueIndex];
      const labelI = _.isArray(config.dataLabelIndex) ? config.dataLabelIndex : [config.dataLabelIndex];
      const labels = [];
      labelI.forEach((l) => {
        if (l === 'key') {
          labels.push(t(item.key));
        } else {
          const lang = l === 'name' ? 'en' : 'ar';
          labels.push(readName(lang, item.name, item.nameArabic));
        }
      });

      values.push(value);

      options.push({
        field: filter,
        id: value,
        label: arrayUtil.implode(labels, ' - '),
        selected: false,
      });
    });

    if (options.length === 0) return;

    filters.options.push({ title: config.title, colSize: config.colSize, options });
    filters.values[filter] = values;
  });

  return filters;
};
const filterChipData = (filters, activeFilters) => {
  const chipData = [];
  filters.forEach((filter) => {
    filter.options.forEach((option) => {
      let values = activeFilters[option.field] ? activeFilters[option.field] : [];
      values = _.isArray(values) ? values : [values];

      if (_.isEmpty(values) || !values.includes(option.id)) return;

      chipData.push({ ...option, groupTitle: filter.title });
    });
  });
  return chipData;
};

const onTFiltersModalOpen = (data, onFiltersModalOpen = null) => {
  if (onFiltersModalOpen && typeof onFiltersModalOpen === 'function') {
    onFiltersModalOpen(data);
    return;
  }
  eventUtil.dispatch('filters-modal-open', data);
};

const onTColumnsModalOpen = (data, onColumnsModalOpen = null) => {
  if (onColumnsModalOpen && typeof onColumnsModalOpen === 'function') {
    onColumnsModalOpen(data);
    return;
  }
  eventUtil.dispatch('columns-modal-open', data);
};

const onTRowDensityModalOpen = (data, onRowDensityModalOpen = null) => {
  if (onRowDensityModalOpen && typeof onRowDensityModalOpen === 'function') {
    onRowDensityModalOpen(data);
    return;
  }
  eventUtil.dispatch('row-density-modal-open', data);
};

const onTxScrollable = (data, onXScrollable = null) => {
  if (onXScrollable && typeof onXScrollable === 'function') {
    onXScrollable(data);
    return;
  }
  eventUtil.dispatch('x-scrollable', data);
};

const onTColumnsSaved = (data, onColumnsModalSave = null) => {
  if (onColumnsModalSave && typeof onColumnsModalSave === 'function') {
    onColumnsModalSave(data);
    return;
  }
  eventUtil.dispatch('columns-saved', data);
};

const onTSearch = (data, onSearch = null) => {
  if (onSearch && typeof onSearch === 'function') {
    onSearch(data);
    return;
  }
  eventUtil.dispatch('search', data);
};

const formatFilters = (filters) => {
  const selectedFilters = [];
  const activeFilters = {};

  filters.forEach((filter) => {
    const selectedValues = [];
    const selectedOptions = [];
    let selectedField = '';
    const { options } = filter;

    options.forEach((option) => {
      selectedField = option.field;
      if (Boolean(option.selected) === false) return;
      selectedValues.push(option.id);
      selectedOptions.push(option);
    });

    if (selectedValues.length === 0) return;

    activeFilters[selectedField] = selectedValues;
    selectedFilters.push({ ...filter, options: selectedOptions });
  });

  return { selectedFilters, activeFilters };
};

const onTFiltersSelect = (data, mFilters, onFiltersSelect) => {
  const filterIdentifier = { title: data.label };
  const filterOIdentifier = { label: data.valueLabel };

  const foundFilter = { ..._.find(mFilters, filterIdentifier) };
  if (!foundFilter) return;

  const foundOption = { ..._.find(foundFilter.options, filterOIdentifier) };
  if (!foundOption) return;

  const foundI = _.findIndex(mFilters, filterIdentifier);
  const foundOptionI = _.findIndex(foundFilter.options, filterOIdentifier);

  mFilters[foundI].options[foundOptionI].selected = !foundOption.selected;

  const params = { data, filters: mFilters, ...formatFilters(mFilters) };

  if (onFiltersSelect && typeof onFiltersSelect === 'function') {
    onFiltersSelect(params);
    return;
  }

  eventUtil.dispatch('filter-select', params);
};

const onTFiltersApply = (filters, onFiltersApply, onFiltersClear) => {
  const cleared = filters.length === 0;
  const params = formatFilters(filters);

  if (cleared && onFiltersClear && typeof onFiltersClear === 'function') {
    onFiltersClear(params);
    return;
  }

  if (!cleared && onFiltersApply && typeof onFiltersApply === 'function') {
    onFiltersApply(params);
    return;
  }

  eventUtil.dispatch(cleared ? 'filters-cleared' : 'filters-applied', params);
};

const xScroll = (ref, direction = 'left') => {
  if (!ref || !ref.current) return;
  ref.current.scroll({
    left:
      direction === 'left'
        ? ref.current.scrollLeft + ref.current.clientWidth - 410
        : ref.current.scrollLeft - ref.current.clientWidth + 410,
    behavior: 'smooth',
  });
};

function pagination(scrolled, current) {
  const config = queryParams.defaultPagination();

  Object.keys(current).forEach((key) => {
    const value = current[key];
    if (value) config[key] = value;
  });

  if (!scrolled) {
    config.currentPage = 1;
    return config;
  }

  const nextPage = current.currentPage + 1;
  if (nextPage <= current.pages) config.currentPage = nextPage;

  return config;
}

function filtersApplied(filters) {
  if (!filters || filters.length === 0) return false;

  let applied = false;
  Object.keys(filters).forEach((filter) => {
    if (applied) return;
    const values = filters[filter];
    if (!values || values.length === 0) return;
    applied = true;
  });

  return applied;
}

function pageDisplay(firstPage, page) {
  return Number(firstPage) === 0 ? page + 1 : page;
}

export default {
  onTRowDensityModalOpen,
  onTxScrollable,
  genId,
  components,
  onTColumnsModalOpen,
  onTColumnsSaved,
  prepareRefs,
  onTSearch,
  xScroll,
  pagination,
  onTFiltersModalOpen,
  onTFiltersSelect,
  onTFiltersApply,
  formatFilters,
  userFilters,
  filterChipData,
  filtersApplied,
  pageDisplay,
};
