import React, { useState, useContext, useEffect } from 'react';

import moment from 'moment';
import { Navigate, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import http from 'api/Api';
import 'react-toastify/dist/ReactToastify.css';
import CompanySettingsApi from 'api/CompanySettingsApi';
import { AuthContext } from 'auth/AuthContext';
import { IsLoggedInContext } from 'auth/IsLoggedInContext';
import AlertMessage from 'components/AlertMessage';
import DeletedContent from 'components/DeletedContent/DeletedContent';
import Modal from 'components/Modal';
import Notification from 'components/Notification';
import { clientTypes } from 'constants/clientTypes';
import ExpiredPage from 'modules/CompanySettings/Subscription/ExpiredPage';
import SuspendedPage from 'modules/CompanySettings/Subscription/SuspenededPage/SuspenededPage';
import ErrorBoundary from 'modules/Recruitment/components/ErrorBoundry/ErrorBoundry';
import GTMService from 'services/GTM/GTMService';
import { registerMessaging } from 'services/Messaging/NotificationRegisterService';
import ECStore from 'stores/ECStore';
import { useAccountSubscriptionData } from 'topNavigation/hooks/useAccount';
import PHRInterceptorConfig from 'utility/http/PHRInterceptorConfig';
import t from 'utility/setTranslation';

import ResourceCenter from './ResourceCenter/ResourceCenter';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const restParams = rest;
  const { authState, dispatch } = useContext(AuthContext);
  const { dispatch: dispatchLogin } = useContext(IsLoggedInContext);
  const ecStore = useContext(ECStore);
  const { checkPrivileges } = authState;
  const navigate = useNavigate();
  const [alertBoxMsg, setAlertBoxMsg] = useState('');
  const [noContentModal, setNoContentModal] = useState(false);
  const [isExpired, setIsExpired] = useState(true);

  const {
    refetch,
    isStale,
    data: accountSubscriptionData,
  } = useAccountSubscriptionData({
    onSuccess: ({ data }) => {
      const { isRenewalPending, isInvoicePending, daysAfterOverdue } = data;
      dispatch({ type: 'set', payload: { isRenewalPending, isInvoicePending, daysAfterOverdue } });
    },
  });

  http.interceptors.response.use(
    (response) => response,
    (error) => {
      // Disable error message
      if (error?.response?.status === 404 || error.message === 'cancelled') {
        return Promise.reject({ ...error });
      }

      if (error?.response?.status === 401 && error?.response?.data?.message.includes('Expired JWT Token')) {
        return Promise.reject({ ...error });
      }

      if (
        error.response?.data?.code === 1629712085 ||
        error.response?.data?.code === 1629711997 ||
        error.response?.data?.code === 1650032054
      ) {
        return Promise.reject({ ...error });
      }

      if (
        (error?.config?.method === 'get' &&
          (error?.config?.url.includes('/announcements') ||
            error?.config?.url.includes('/notifications-inbox') ||
            error?.config?.url.includes('/mudad/login') ||
            error?.config?.url.includes('/master-announcements/latest-unseen'))) ||
        (error?.config?.method === 'post' &&
          error?.config?.url.includes('/settings/time/time-tracking/biometrics/test'))
      ) {
        return Promise.reject({ ...error });
      }

      const interceptorConfig = error?.response?.config || {};
      // const interceptorConfig = error.response.config;
      const shouldShowFailMessage = PHRInterceptorConfig.shouldShowFailMessage(interceptorConfig);

      if (interceptorConfig.hasOwnProperty('showResponseFailMessage') && !shouldShowFailMessage) {
        return Promise.reject({ ...error });
      }

      if (error?.response?.status === 422) {
        if (
          error.response?.data?.code !== 1597316720 &&
          error.response?.data?.code !== 1620807133 &&
          error.response?.data?.code !== 1634191633 &&
          error.response?.data?.code !== 1649405339 &&
          error.response?.data?.code !== 1658392555 &&
          error?.response?.data?.code !== 1658616093
        ) {
          if (error.response?.data?.code === 1635339212) {
            setAlertBoxMsg(
              t(error.response.data.message, {
                date: moment(error.response.data.details.eligibilityDate).format('DD MMM, YYYY'),
              })
            );
          } else if (error.response.data.validationErrors) {
            if (Array.isArray(error.response.data.validationErrors)) {
              let errText = '';
              error.response.data.validationErrors.map((el) => {
                errText += `${t(el)}\n`;
                return el;
              });
              setAlertBoxMsg(errText);
            } else {
              const values = Object.values(error.response.data.validationErrors);
              let errText = '';
              values.map((el) => {
                errText += `${t(el)}\n`;
                return el;
              });
              setAlertBoxMsg(errText);
            }
          } else {
            setAlertBoxMsg(t(error.response.data.message));
          }
        }
      } else if (error?.response?.data?.code !== 1597920964 && error?.message !== 'cancelled') {
        if (!alertBoxMsg) {
          setAlertBoxMsg(t(error?.response?.data?.message) || t('GENERAL.GENERAL_ERROR_500'));
        }
      }
      if (error?.response?.data?.code === 1658616093 && window.location.hash === '#by-notification') {
        setNoContentModal(true);

        navigate('/dashboard', { replace: true });

        if (ecStore.showContent) {
          ecStore.setEcState(false, 'minimized');
        }
      }

      return Promise.reject({ ...error });
    }
  );

  const checkStatus = (expDate, clientType) => {
    if (clientType === clientTypes.DEMO && moment(expDate).isBefore(new Date())) {
      return setIsExpired(true);
    }
    return setIsExpired(false);
  };

  const isSuspended =
    authState.companyData?.clientType === clientTypes.LIVE_INSTANCE &&
    accountSubscriptionData?.data?.account?.status === 'SUSPENDED';

  const checkIsTokenValid = () => {
    if (authState) {
      if (!authState.user) {
        dispatchLogin({
          type: 'set',
          payload: { isLoggedIn: false },
        });
        dispatch({
          type: 'del',
        });
        return navigate('/login#tokenExpired', { replace: true });
      }

      if (authState.token) {
        const expRefreshToken = authState.refreshExpireAt;
        const currentTime = moment().unix();

        if (expRefreshToken <= currentTime) {
          dispatchLogin({
            type: 'set',
            payload: { isLoggedIn: false },
          });
          dispatch({
            type: 'del',
          });

          return navigate('/login#tokenExpired', { replace: true });
        }
      }
    }

    return false;
  };

  useEffect(() => {
    localStorage.setItem('currentPath', window.location.pathname);
    checkIsTokenValid();
    if (isStale) {
      refetch();
    }
  }, [window.location.pathname]);

  useEffect(() => {
    registerMessaging();

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', (message) => {
        const messageData = message?.data ?? {};
        const firebaseMessageData = messageData['firebase-messaging-msg-data'] ?? {};
        const firebaseMessageId = firebaseMessageData.fcmMessageId ?? null;

        if (!firebaseMessageId || (firebaseMessageId && !toast.isActive(firebaseMessageId))) {
          toast.dark(<Notification data={message} />, {
            position: 'bottom-right',
            autoClose: 30000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            toastId: firebaseMessageId,
          });
        }
      });
    }

    const expDate =
      authState.companyData?.clientType === clientTypes.LIVE_INSTANCE
        ? authState.companyData?.trialExpireAt
        : authState.companyData?.demoExpireAt;

    checkStatus(expDate, authState.companyData?.clientType, authState.subscriptionStatus);
  }, []);

  useEffect(() => {
    GTMService.dispatchGTMUserLoggedInEvent(authState);
  }, []);

  useEffect(
    () => () => {
      GTMService.dispatchGTMUserLoggedOutEvent(authState);
    },
    []
  );

  useEffect(() => {
    GTMService.dispatchGTMUserDataUpdatedEvent(authState);
  }, [JSON.stringify(authState)]);

  const onCloseToast = () => {
    setAlertBoxMsg('');
  };

  if (isSuspended) {
    return (
      <ErrorBoundary>
        <SuspendedPage />
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      {
        <>
          {alertBoxMsg && <AlertMessage text={alertBoxMsg} type='error' callback={onCloseToast} />}
          {isExpired ? (
            <ExpiredPage />
          ) : (
            <>
              {checkPrivileges(restParams.path) ? (
                <Component component={isExpired ? Component : <div />} />
              ) : (
                <Navigate to='/403' />
              )}
              <Modal
                showHeader={false}
                isOpen={noContentModal}
                styles={{ width: '360px', padding: '0' }}
                onRequestClose={() => setNoContentModal(false)}
              >
                <DeletedContent onClose={() => setNoContentModal(false)} />
              </Modal>
              <ResourceCenter />
            </>
          )}

          <ToastContainer />
        </>
      }
    </ErrorBoundary>
  );
};

export default PrivateRoute;
