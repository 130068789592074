import http from './Api';

class AuthApi {
  static login(data) {
    return http.post('login', data);
  }

  static login2fa(data) {
    return http.post('login/two-factor', data);
  }

  static logout(data) {
    return http.post('logout', data);
  }

  static resetPasword(data) {
    return http.post(`reset-password`, data);
  }

  static forceResetPasword(data) {
    return http.post('force-change-password', data);
  }

  static forgotPassword(data) {
    return http.post('forgot-password', data);
  }

  static getExTokenUserData(employee) {
    return http.get(`employee/${employee}/global-state`);
  }

  static getUserPasswordConfig(username) {
    return http.get(`user-password-config?username=${username}`);
  }

  static getPasswordConfig() {
    return http.get('password-config');
  }

  static changePasswordConfig(id, data) {
    return http.put(`password-config/${id}`, data);
  }

  static resendCode(data) {
    return http.post(`two-factor/resend-email`, data);
  }

  static checkIfTokenExpired(hash) {
    return http.get(`forgot-password/check-expiration?hash=${hash}`);
  }

  static forgotPasswordEmail(hash) {
    return http.get(`forgot-password?hash=${hash}`);
  }
}

export default AuthApi;
