import React from 'react';

import cx from 'classnames';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';

import darkLoader from 'assets/json/button_loader/dark.json';
import greyLoader from 'assets/json/button_loader/grey.json';
import primaryLoader from 'assets/json/button_loader/primary.json';
import whiteLoader from 'assets/json/button_loader/white.json';
import formatKey from 'utility/formatKey';

import styles from './RoundedButton.module.scss';

const RoundedButton = ({
  id,
  icon,
  innerRef,
  iconPosition,
  onClick,
  isDisabled,
  className,
  name,
  color,
  text,
  renderCustomContent,
  textPattern,
  type,
  href,
  loading,
  btnStyle,
  size,
  ariaLabel,
}) => {
  const loaderType = () => {
    if (btnStyle === 'contained' && color !== 'white') {
      return whiteLoader;
    }

    if (btnStyle === 'contained' && color === 'white') {
      return darkLoader;
    }

    if (btnStyle === 'outlined' && color === 'primary') {
      return primaryLoader;
    }

    if (btnStyle === 'text' && color === 'primary') {
      return primaryLoader;
    }

    return greyLoader;
  };

  const content = renderCustomContent ? renderCustomContent() : formatKey('GENERAL', text, textPattern);

  const elementMap = {
    button: (
      <button
        id={id}
        ref={innerRef}
        aria-label={ariaLabel}
        className={cx(
          styles.roundedButton,
          styles[btnStyle],
          styles[color],
          styles[size],
          loading && styles.loading,
          className
          // 'btn'
        )}
        type='button'
        onClick={onClick}
        disabled={isDisabled && 'disabled'}
      >
        {loading ? (
          <Lottie className={styles.lottieLoader} animationData={loaderType()} />
        ) : (
          <>
            {icon && iconPosition === 'left' && (
              <i className={cx(icon, styles.icon, size === 'sm' ? 'me-1' : 'me-2')} />
            )}

            {content}

            {icon && iconPosition === 'right' && (
              <i className={cx(icon, styles.icon, size === 'sm' ? 'ms-1' : 'ms-2')} />
            )}
          </>
        )}
      </button>
    ),
    submit: (
      <button
        id={id}
        className={cx(
          styles.roundedButton,
          styles[btnStyle],
          styles[color],
          styles[size],
          loading && styles.loading,
          className,
          'btn'
        )}
        type='submit'
        disabled={isDisabled && 'disabled'}
      >
        {loading ? (
          <Lottie className={styles.lottieLoader} animationData={loaderType()} />
        ) : (
          <>
            {icon && iconPosition === 'left' && (
              <i className={cx(icon, styles.icon, size === 'sm' ? 'me-1' : 'me-2')} />
            )}

            {content}

            {icon && iconPosition === 'right' && (
              <i className={cx(icon, styles.icon, size === 'sm' ? 'ms-1' : 'ms-2')} />
            )}
          </>
        )}
      </button>
    ),
    link: (
      <a
        id={id}
        href={href}
        role='button'
        className={cx(
          styles.roundedButton,
          styles[btnStyle],
          styles[color],
          styles[size],
          loading && styles.loading,
          className,
          'btn'
        )}
      >
        {loading ? (
          <Lottie className={styles.lottieLoader} animationData={loaderType()} />
        ) : (
          <>
            {icon && iconPosition === 'left' && (
              <i className={cx(icon, styles.icon, size === 'sm' ? 'me-1' : 'me-2')} />
            )}

            {content}

            {icon && iconPosition === 'right' && (
              <i className={cx(icon, styles.icon, size === 'sm' ? 'ms-1' : 'ms-2')} />
            )}
          </>
        )}
      </a>
    ),
    label: (
      <label
        id={id}
        htmlFor={name}
        className={cx(
          styles.roundedButton,
          styles[btnStyle],
          styles[color],
          styles[size],
          loading && styles.loading,
          className,
          'btn'
        )}
      >
        {loading ? (
          <Lottie className={styles.lottieLoader} animationData={loaderType()} />
        ) : (
          <>
            {icon && iconPosition === 'left' && (
              <i className={cx(icon, styles.icon, size === 'sm' ? 'me-1' : 'me-2')} />
            )}

            {content}

            {icon && iconPosition === 'right' && (
              <i className={cx(icon, styles.icon, size === 'sm' ? 'ms-1' : 'ms-2')} />
            )}
          </>
        )}
      </label>
    ),
  };

  return elementMap[type];
};

RoundedButton.propTypes = {
  text: PropTypes.string,
  textPattern: PropTypes.object,
  type: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.string,
  onClick: PropTypes.func,
  renderCustomContent: PropTypes.func,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  color: PropTypes.string,
  href: PropTypes.string,
  loading: PropTypes.bool,
  btnStyle: PropTypes.string,
  size: PropTypes.string,
};

RoundedButton.defaultProps = {
  onClick: undefined,
  icon: '',
  textPattern: {},
  iconPosition: 'left',
  isDisabled: false,
  color: 'gray',
  className: '',
  type: 'button',
  href: '',
  text: '',
  loading: false,
  renderCustomContent: undefined,
  btnStyle: 'contained',
  size: 'md',
};

export default RoundedButton;
