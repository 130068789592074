import React from 'react';

import PropTypes from 'prop-types';

import Line from 'modules/EngagementCenter/shared/loaders/Line';

import styles from './TableSkeleton.module.scss';

const TableSkeleton = ({ lineHight, mb, num, mt, testId }) => {
  const renderItems = () => {
    const items = [];
    for (let index = 0; index < num; index += 1) {
      items.push(
        <Line
          key={Math.random()}
          style={{ height: lineHight, width: '100%' }}
          gradient={styles.gradient}
          className={`radius-4 ${mb}`}
        />
      );
    }
    return items;
  };
  return (
    <section data-testid={testId}>
      <div className={mt}>{renderItems()}</div>
    </section>
  );
};

TableSkeleton.propTypes = {
  lineHight: PropTypes.number,
  mb: PropTypes.string,
  num: PropTypes.number,
  mt: PropTypes.string,
  testId: PropTypes.string,
};

TableSkeleton.defaultProps = {
  lineHight: 32,
  mb: 'mb-3',
  num: 3,
  mt: 'mt-3',
  testId: '',
};

export default TableSkeleton;
