import React from 'react';

import cx from 'classnames';

import palmVideo from 'assets/video/palmmp4.mp4';
import { PALM_HR_AI_LOADING_ID } from 'modules/Dashboard/Constants';
import t from 'utility/setTranslation';

import styles from './PalmHrAILoading.module.scss';

const PalmHrAILoading = ({ className, style }) => {
  const isWindowsOs = navigator.userAgent.includes('Windows');

  return (
    <div
      data-id={PALM_HR_AI_LOADING_ID}
      className={cx(
        className,
        styles.PalmHrAILoading,
        isWindowsOs ? 'bg-dark-blue-windows' : 'bg-dark-blue',
        'd-flex flex-column justify-content-center align-items-center w-100 radius-8'
      )}
      style={style}
    >
      <video autoPlay loop src={palmVideo} width={220} muted />
      <p className='text-gray-300 font-size-16'>{t('GENERAL.BE_PATIENT')}</p>
    </div>
  );
};

export default PalmHrAILoading;
