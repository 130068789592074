import t from './setTranslation';

function defaultFormat() {
  return { nullValue: '' };
}

function format(value = '', options = {}) {
  const defaultOpt = { ...defaultFormat() };

  Object.keys(defaultOpt).forEach((key) => {
    if (!options[key]) return;
    defaultOpt[key] = options[key];
  });

  return value || defaultOpt.nullValue;
}

function capitalize(sentence) {
  return sentence.replace(/\b\w/g, (l) => l.toUpperCase());
}

function hasWhiteSpace(string) {
  return /\s/.test(string);
}

function split(string, regex = /\s+/) {
  return string.split(regex);
}

function initials(string) {
  // eslint-disable-next-line no-param-reassign
  if (!Array.isArray(string)) string = split(string);
  if (string.length === 0) return '';
  if (string.length === 1) return this.capitalize(string[0])[0];

  // if string has more than 1 word, return capitals of first and last word
  return `${this.capitalize(string[0])[0]}${this.capitalize(string[string.length - 1])[0]}`;
}

function translation(str) {
  let string = '- -';
  if (str && str !== '--') {
    string = t(`GENERAL.${str}`);
  }
  return string;
}

export default { defaultFormat, format, capitalize, hasWhiteSpace, initials, split, translation };
