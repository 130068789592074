import React, { useState, useEffect, useContext } from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { useLocation } from 'react-router-dom';

import DashboardApi from 'api/DashboardApi';
import khadamatiEmptyImage from 'assets/img/enterprise/khadamati/congratulations.png';
import khadamatiImage from 'assets/img/enterprise/khadamati/who_s_out.png';
import palmhrImage from 'assets/img/onboarding-4.png';
import palmhrEmptyImage from 'assets/img/request/congratulations.png';
import { AuthContext } from 'auth/AuthContext';
import IconButton from 'components/buttons/IconButton/IconButton';
import ConfirmationBox from 'components/ConfirmationBox';
import EmptyState from 'components/EmptyPages/EmptyState';
import Modal from 'components/Modal';
import CardAdvancedItem from 'modules/Dashboard/components/CardAdvancedItem';
import CustomCalendar from 'modules/Dashboard/components/CustomCalendar/CustomCalendar';
import DashboardContext from 'modules/Dashboard/DashboardContext';
import AddEventModal from 'modules/Dashboard/Events/AddEventModal';
import getDateFromHash from 'modules/Employee/Profile/Performance/OneOnOne/functions/getDateFromHash';
import useResolveHandler from 'shared/useResolveHandler';
import { ThemeContext } from 'themes/ThemeContext';
import daysFromRange from 'utility/daysFromRange';
import t from 'utility/setTranslation';
import updateDataList from 'utility/updateDataList';

import styles from './EventCalendarModal.module.scss';
import SkeletonCardAdvanced from './SkeletonCardAdvanced';
import resourcePath from '../../../shared/resourcePath';

const EventCalendarModal = ({ onClose }) => {
  const [birthdays, setBirthdays] = useState([]);
  const [anniversary, setAnniversary] = useState([]);
  const location = useLocation();
  const [anniversarieAdvanced, setAnniversarieAdvanced] = useState([]);
  const [birthdayAdvanced, setBirthdayAdvanced] = useState([]);
  const [customEvents, setEvents] = useState([]);
  const [customEventsAdvanced, setCustomEventsAdvanced] = useState([]);
  const dateHash = getDateFromHash(location.hash);
  const [day, setDay] = useState(moment(dateHash).toDate());
  const [firstDayOfMonth, setFirstDayOfMonth] = useState(moment(dateHash).startOf('month').toDate());
  const [calendatLoader, setCalendatLoader] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [openAddEventModal, setIsAddEventModalOpened] = useState(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [selectedEvent, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const { responseStatus, setStatus } = useResolveHandler();
  const { themeState } = useContext(ThemeContext);
  const { setShouldUpdateEvents } = useContext(DashboardContext);
  const { authState } = useContext(AuthContext);

  const enterpriseName = themeState?.companyName || 'palmhr';
  const imageMap = {
    palmhr: {
      header: palmhrImage,
      empty: palmhrEmptyImage,
    },
    khadamati: {
      header: khadamatiImage,
      empty: khadamatiEmptyImage,
    },
  };
  const hasPermission = authState.user.roles.includes('ROLE_ADMIN') || authState.user.roles.includes('ROLE_HR');

  let eventsPromis = null;
  const getCompanyEvents = (dateSel3) => {
    eventsPromis = DashboardApi.getCompanyEvents(moment(dateSel3).format('YYYY-MM-DD'))
      .then((res) => {
        if (res?.data?.events.length > 0) {
          res?.data?.events.map((item) => Object.assign(item, { date: item?.startDate }));
        }
        setEvents(res?.data?.events);
      })
      .catch(() => {});
  };

  const getDateRange = (res) => {
    const newArr = [];
    if (res?.data?.events.length > 0) {
      res.data.events.forEach((item) => {
        daysFromRange(item.startDate, item.endDate).forEach((i) => {
          newArr.push(moment(i).format('MM-DD'));
        });
      });
    }
    setCustomEventsAdvanced(newArr);
  };

  let eventAdvancedPromis = null;
  const getEventsCompanyAdvanced = (dateSel4) => {
    eventAdvancedPromis = DashboardApi.getEventsAdvanced(moment(dateSel4).format('YYYY-MM-DD'))
      .then((res) => {
        getDateRange(res);
      })
      .catch(() => {});
  };

  let birthdaysPromis = null;
  const getBirthdays = (dateSel1) => {
    birthdaysPromis = DashboardApi.getBirthdays(moment(dateSel1).format('YYYY-MM-DD'))
      .then((res) => {
        if (res?.data?.birthdays.length > 0) {
          res?.data?.birthdays.map((item) => Object.assign(item, { date: item?.about?.birthdate }));
        }
        setBirthdays(res?.data?.birthdays);
      })
      .catch(() => {});
  };

  let anniversariesPromis = null;
  const getAnniversaries = (dateSel2) => {
    anniversariesPromis = DashboardApi.getAnniversaries(moment(dateSel2).format('YYYY-MM-DD'))
      .then((res) => {
        if (res?.data?.anniversaries.length > 0) {
          res?.data?.anniversaries.map((item) => Object.assign(item, { date: item?.employment?.hireDate }));
        }
        setAnniversary(res?.data?.anniversaries);
      })
      .catch(() => {});
  };

  const getEvents = (dateSel) => {
    getCompanyEvents(dateSel);
    getBirthdays(dateSel);
    getAnniversaries(dateSel);
    setLoadingEvents(true);
    Promise.all([eventsPromis, birthdaysPromis, anniversariesPromis])
      .then(() => {
        setLoadingEvents(false);
      })
      .catch(() => {
        setLoadingEvents(false);
      });
  };

  let birthdaysAdvancedPromis = null;
  const getBirthdaysAdvanced = (selectedDate1) => {
    birthdaysAdvancedPromis = DashboardApi.getBirthdaysAdvanced(moment(selectedDate1).format('YYYY-MM-DD'))
      .then((res) => {
        const newArr = [];
        if (res?.data?.birthdays.length > 0) {
          res?.data?.birthdays.forEach((item) => newArr.push(moment(item?.birthdateMasked).format('MM-DD')));
        }
        setBirthdayAdvanced(newArr);
      })
      .catch(() => {});
  };

  let anniversariesAdvancedPromis = null;
  const getAnniversariesAdvanced = (selectedDate2) => {
    anniversariesAdvancedPromis = DashboardApi.getAnniversariesAdvanced(moment(selectedDate2).format('YYYY-MM-DD'))
      .then((res) => {
        const newArrAn = [];
        if (res?.data?.anniversaries.length > 0) {
          res?.data?.anniversaries.map((item) => newArrAn.push(moment(item?.hireDate).format('MM-DD')));
        }
        setAnniversarieAdvanced(newArrAn);
      })
      .catch(() => {});
  };

  const getEventsAdvanced = (selectedDate) => {
    getEventsCompanyAdvanced(selectedDate);
    getBirthdaysAdvanced(selectedDate);
    getAnniversariesAdvanced(selectedDate);
    setCalendatLoader(true);
    Promise.all([birthdaysAdvancedPromis, anniversariesAdvancedPromis, eventAdvancedPromis])
      .then(() => {
        setCalendatLoader(false);
      })
      .catch(() => {
        setCalendatLoader(false);
      });
  };
  const onSelectDay = (e) => {
    getEvents(e);
    setDay(e);
  };

  const updateDashboardState = () => {
    setShouldUpdateEvents(true);
    onSelectDay(day);
  };

  const onOpenAddModal = () => {
    setSelected(null);
    setIsAddEventModalOpened(true);
  };

  const closeHandler = () => {
    setIsAddEventModalOpened(false);
  };

  const onEditHandler = (e) => {
    setSelected(e);
    setIsAddEventModalOpened(true);
  };

  const onDeleteHandler = (e) => {
    setSelected(e);
    setIsConfirmModalOpened(true);
  };

  const onChangeMonth = (e) => {
    const currentMonth = moment(moment(new Date(e)).startOf('month').toDate());
    setFirstDayOfMonth(currentMonth);
    getEventsAdvanced(currentMonth);
  };

  const addCustomEvent = (data, id) => {
    setLoading(true);
    if (selectedEvent) {
      DashboardApi.updateCustomEvent(data, id)
        .then((res) => {
          let state = { ...customEvents };
          state = updateDataList(res.data, 'edit', customEvents);
          setEvents(state);
          getEventsAdvanced(firstDayOfMonth);
          updateDashboardState();
          setLoading(false);
          setStatus(true, () => {
            setIsAddEventModalOpened(false);
          });
        })
        .catch((error) => {
          setLoading(false);
          setStatus(false, () => {}, error?.response?.data?.message);
        });
    } else {
      DashboardApi.addCustomEvent(data)
        .then((res) => {
          let state = [...customEvents];
          state = updateDataList(res.data, 'add', customEvents);
          setEvents(state);
          getEventsAdvanced(firstDayOfMonth);
          updateDashboardState();
          setLoading(false);
          setStatus(true, () => {
            setIsAddEventModalOpened(false);
          });
        })
        .catch((error) => {
          setLoading(false);
          setStatus(false, () => {}, error?.response?.data?.message);
        });
    }
  };

  const deleteCustomEvent = () => {
    DashboardApi.deleteCustomEvent(selectedEvent.id)
      .then(() => {
        let state = [...customEvents];
        state = updateDataList(selectedEvent, 'delete', customEvents);
        setEvents(state);
        getEventsAdvanced(firstDayOfMonth);
        updateDashboardState();
        setLoading(false);
        setStatus(true, () => {
          setIsConfirmModalOpened(false);
        });
      })
      .catch((error) => {
        setLoading(false);
        setStatus(false, () => {}, error?.response?.data?.message);
      });
  };

  const onAction = (action, item) => {
    switch (action) {
      case 'Delete':
        onDeleteHandler(item);
        break;
      case 'Edit':
        onEditHandler(item);
        break;
      default:
        break;
    }
    setSelected(item);
  };

  useEffect(() => {
    getEventsAdvanced(firstDayOfMonth);
    getEvents(moment(day));
  }, []);

  return (
    <div>
      <div className={cx(styles.header, 'w-100 d-flex justify-content-between')}>
        <div style={{ width: '255px', height: '255px', marginLeft: '48px' }}>
          <img style={{ width: '100%', height: '100%' }} src={imageMap[enterpriseName].header} alt='events' />
        </div>
        <div className='d-flex align-items-center pe-3'>
          <div className={styles.currentDay}>
            {moment(day).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')
              ? t('GENERAL.TODAY')
              : moment(day).format('ddd, D MMMM')}
          </div>
        </div>
        <div className={styles.closeBtn}>
          <IconButton
            icon='icon-cross-x'
            onClick={() => {
              onClose();
            }}
            size='md'
            color='gray'
          />
        </div>
      </div>
      <div className='d-flex flex-grow-1 pt-3'>
        <div className={cx('w-50 ps-3 pe-3')}>
          <CustomCalendar
            begining={moment(day).format('YYYY-MM-DD')}
            onChangeMonthFn={onChangeMonth}
            data={{
              anniversarieAdvanced: [...new Set(anniversarieAdvanced)] || [],
              birthdayAdvanced: [...new Set(birthdayAdvanced)] || [],
              customEvent: [...new Set(customEventsAdvanced)] || [],
            }}
            calendatLoader={calendatLoader}
            onSelectDayFn={onSelectDay}
          />
        </div>
        <div style={{ height: '100%', minHeight: '500px' }} className={cx('w-50 ps-3', !hasPermission && 'mt-8')}>
          <div className='d-flex align-items-cente justify-content-end mb-4 me-4'>
            {hasPermission && (
              <IconButton
                icon='icon-plus'
                onClick={() => {
                  onOpenAddModal();
                }}
                size='md'
                color='primary'
              />
            )}
          </div>

          {loadingEvents ? (
            <SkeletonCardAdvanced num={3} header={false} />
          ) : birthdays &&
            anniversary &&
            customEvents &&
            birthdays?.length === 0 &&
            anniversary?.length === 0 &&
            customEvents?.length === 0 ? (
            <div className='me-4'>
              <EmptyState
                brandImage='ws_empty.png'
                title='GENERAL.NO_EVENTS'
                onClick={onOpenAddModal}
                btnText='add event'
                btnIcon='icon-plus'
                showBtn={hasPermission}
              />
            </div>
          ) : (
            <Scrollbars className='scrollbarsWrapper' style={{ height: 430 }}>
              {birthdays && birthdays.length > 0 && (
                <>
                  <div className='pe-3 mb-4'>
                    <div className={cx(styles.label, 'mb-3')}>{t('GENERAL.BIRTHDAYS').toUpperCase()}</div>
                    {birthdays.map((item) => (
                      <CardAdvancedItem
                        key={item?.id}
                        employee={{
                          avatarThumb: resourcePath(item?.avatarThumb),
                          avatarThumbPath: item?.avatarThumbPath,
                          fullName: item?.fullName,
                          fullNameArabic: item?.fullNameArabic,
                          id: item?.id,
                          job: item?.job,
                        }}
                        type='birthday'
                        date={item?.date}
                      />
                    ))}
                  </div>
                </>
              )}
              {anniversary && anniversary.length > 0 && (
                <>
                  <div className='pe-3 mb-4'>
                    <div className={cx(styles.label, 'mb-3')}>{t('GENERAL.ANNIVERSARIES').toUpperCase()}</div>
                    {anniversary.map((item) => (
                      <CardAdvancedItem
                        key={item?.id}
                        employee={{
                          avatarThumb: resourcePath(item?.avatarThumb),
                          avatarThumbPath: item?.avatarThumbPath,
                          fullName: item?.fullName,
                          fullNameArabic: item?.fullNameArabic,
                          id: item?.id,
                          job: item?.job,
                          hireDate: item?.employment?.hireDate,
                        }}
                        selectedDate={moment(day).format('YYYY-MM-DD')}
                        type='anniversary'
                        date={item?.date}
                      />
                    ))}
                  </div>
                </>
              )}
              {customEvents && customEvents?.length > 0 && (
                <>
                  <div className='pe-3 mb-4'>
                    <div className={cx(styles.label, 'my-3')}>{t('GENERAL.CUSTOM_EVENTS').toUpperCase()}</div>
                    {customEvents?.map((item) => (
                      <CardAdvancedItem
                        onAction={onAction}
                        key={item?.id}
                        company={item}
                        date={item?.date}
                        event
                        type='customEvent'
                        hasPermission={hasPermission}
                      />
                    ))}
                  </div>
                </>
              )}
            </Scrollbars>
          )}
        </div>
        <Modal
          showDirtyForm
          title={selectedEvent ? t('GENERAL.EDIT_EVENT') : t('GENERAL.NEW_EVENT')}
          isOpen={openAddEventModal}
          styles={{ width: '640px', padding: '0' }}
          onRequestClose={closeHandler}
        >
          <AddEventModal
            selected={selectedEvent}
            onClose={closeHandler}
            onSave={addCustomEvent}
            saving={loading}
            responseStatus={responseStatus}
            day={day}
          />
        </Modal>
        <Modal
          showHeader={false}
          isOpen={isConfirmModalOpened}
          styles={{ width: '640px' }}
          onRequestClose={() => {
            setIsConfirmModalOpened(false);
          }}
        >
          <ConfirmationBox
            responseStatus={responseStatus}
            type='danger'
            sending={loading}
            action={deleteCustomEvent}
            title='GENERAL.DELETE'
            text='EVENT.DELETE_EVENT'
            close={() => {
              setIsConfirmModalOpened(false);
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

EventCalendarModal.propTypes = {
  onClose: PropTypes.func,
};

EventCalendarModal.defaultProps = {
  onClose: undefined,
};

export default EventCalendarModal;
