import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './HeaderTitle.module.scss';

const HeaderTitle = ({ className, children }) => (
  <h3 data-testid='headerTitle' className={cx(styles.headerTitle, className, 'text-light', 'mb-0')}>
    {children}
  </h3>
);

HeaderTitle.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
};

HeaderTitle.defaultProps = {
  children: '',
  className: '',
};

export default HeaderTitle;
