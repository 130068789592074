import React from 'react';

import { PHRLineLoader } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

const Line = ({ style, className }) => (
  <PHRLineLoader className={className} width={style.width} height={style.height} />
);

Line.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

Line.defaultProps = {
  className: '',
  style: {
    height: 8,
    width: '100%',
    borderRadius: 2,
    opacity: 0.7,
  },
};

export default React.memo(Line);
