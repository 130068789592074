const editModalStyle = {
  overlay: {
    backgroundColor: 'rgba(4, 51, 68, 0.2)',
    backdropFilter: 'blur(2px)',
    zIndex: '100',
    display: 'flex',
  },
  content: {
    width: '730px',
    height: 'auto',
    bottom: 'auto',
    margin: 'auto auto',
    padding: '24px 32px',
    border: 'none',
    boxShadow: `0 8px 10px 1px rgba(93,114,140,0.14),0 3px 14px 2px
        rgba(93,114,140,0.12),0 5px 5px -3px rgba(93,114,140,0.20)`,
  },
};

export default editModalStyle;
