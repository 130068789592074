import React from 'react';

import PropTypes from 'prop-types';

import NavItem from './NavItem';

const NavBar = ({ selectView, active, activeHOC, items, type }) => (
  <div className='d-flex align-items-center'>
    {items.map((item) => (
      <NavItem
        key={item.title}
        title={item.title}
        active={active === item.title}
        href={item.href}
        onClick={selectView}
        type={item.type || type}
        activeHOC={activeHOC}
      />
    ))}
  </div>
);

NavBar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectView: PropTypes.func,
  active: PropTypes.string,
  type: PropTypes.string,
  activeHOC: PropTypes.bool,
};

NavBar.defaultProps = {
  selectView: undefined,
  active: '',
  type: 'button',
  activeHOC: false,
};

NavBar.defaultProps = {};

export default NavBar;
