import http from 'api/Api';
import { convertObjToMultipart } from 'utility/convertObjToMultipart';

class RecruitmentJobsApi {
  static getRecruitmentJobs(params) {
    return http.get(`recruitment/job-posts`, { params });
  }

  static putArchiveJobPost(jobPostId) {
    return http.put(`recruitment/job-post/${jobPostId}/archive`);
  }

  static putPublishJobPost(jobPostId) {
    return http.put(`recruitment/job-post/${jobPostId}/publish`);
  }

  static putDraftJobPost(jobPostId) {
    return http.put(`recruitment/job-post/${jobPostId}/draft`);
  }

  static putUnArchiveJobPost(jobPostId) {
    return http.put(`recruitment/job-post/${jobPostId}/unarchive`);
  }

  static postDuplicateJobPost(jobPostId) {
    return http.post(`recruitment/job-post/${jobPostId}/clone`);
  }

  static postAddJobField(fieldName) {
    return (fieldValue) => http.post(`settings/team/profile-fields/${fieldName}`, fieldValue);
  }

  static postAddLocation(location) {
    return http.post(`settings/account/company-locations`, location);
  }

  static postAddJobPost(jobPost) {
    return http.post(`recruitment/job-post`, jobPost);
  }

  static putAddJobPost({ jobPostId, jobPost }) {
    return http.put(`recruitment/job-post/${jobPostId}`, jobPost);
  }

  static getRecruitmentActivity(group, filters) {
    return http.get(`recruitment/activity-group/${group}/activities${filters}`);
  }

  static getJobFieldsOptions() {
    return http.get(`recruitment/job-posts/form-field-options`);
  }

  static getJobHiringTeam(params) {
    return http.get(`team/people/quick-view`, { params });
  }

  static getCareers() {
    return http.get(`recruitment/job-posts/published`);
  }

  static getCandidateApplicationDetails(candidateApplicationId) {
    return http.get(`recruitment/candidate-application/${candidateApplicationId}`);
  }

  static getCandidateApplicationEmails(candidateApplicationId) {
    return http.get(`recruitment/candidate-application/chat/${candidateApplicationId}`);
  }

  static getCandidatesApplications({ jobId, params }) {
    return http.get(`recruitment/candidate-applications/${jobId}`, { params }).then((response) => {
      response.data.items = response.data.items.map(
        ({ application, isSeenByCurrentUser, isFavoriteByCurrentUser }) => ({
          ...application,
          isSeenByCurrentUser,
          isFavoriteByCurrentUser,
        })
      );
      return response;
    });
  }

  static postCandidate({ candidate, jobPostId }) {
    return http.post(`recruitment/candidate-application/apply/${jobPostId}`, convertObjToMultipart(candidate));
  }

  static markAsViewed(candidateId) {
    return http.put(`recruitment/candidate-application/seen/${candidateId}`);
  }

  static deleteCandidate(candidateId) {
    return http.delete(`recruitment/job-posts/candidates/${candidateId}`);
  }

  static hireCandidate({ candidateApplicationId }) {
    return http.put(`recruitment/candidate-application/hire/${candidateApplicationId}`);
  }

  static rejectCandidate(candidateApplicationId) {
    return http.put(`recruitment/candidate-application/reject/${candidateApplicationId}`);
  }

  static rejectBulkCandidates(candidatesApplicationsIds) {
    return http.put(`recruitment/candidate-applications/reject`, {
      applications: candidatesApplicationsIds,
    });
  }

  static revertCandidate({ candidateApplicationId }) {
    return http.put(`recruitment/candidate-application/revert/${candidateApplicationId}`);
  }

  static revertCandidateApplicationPhase({ candidateApplicationId }) {
    return http.put(`recruitment/candidate-application/phase/revert/${candidateApplicationId}`);
  }

  static changeCandidateApplicationPhase({ candidateApplicationId, hiringProcessPhaseId }) {
    return http.put(`recruitment/candidate-application/phase/${candidateApplicationId}/${hiringProcessPhaseId}`);
  }

  static updateCandidate({ candidateId, candidateData }) {
    return http.put(`recruitment/job-posts/candidates/${candidateId}`, candidateData);
  }

  static sendEmailToCandidate({ candidateApplicationId, emailData }) {
    return http.post(`recruitment/candidate-application/message/team-member/${candidateApplicationId}`, emailData);
  }

  static saveCandidateRating({ candidateApplicationId, ratingData }) {
    return http.post(`recruitment/candidate-application/feedback/${candidateApplicationId}`, ratingData);
  }

  static deleteCandidateRating(ratingId) {
    return http.delete(`recruitment/job-posts/candidates/feedback/${ratingId}`);
  }

  static editFeedback({ ratingId, ratingData }) {
    return http.put(`recruitment/job-posts/candidates/feedback/${ratingId}/edit`, ratingData);
  }

  static getFile(action, fileId) {
    return http.get(`file/export/${fileId}/${action}`, {
      responseType: 'arraybuffer',
    });
  }

  static applyActionToFile(action) {
    return (fileId) =>
      http.get(`file/export/${fileId}/${action}`, {
        responseType: 'arraybuffer',
      });
  }

  static copyCandidate({ candidateId, jobPostId }) {
    return http.put(`recruitment/candidate-application/copy/${candidateId}/${jobPostId}`);
  }

  static getCandidateApplicationLogs({ candidateId, params }) {
    return http.get(`/recruitment/candidate-application/${candidateId}/log`, { params });
  }

  static manualAddCandidate({ candidate, jobPostId }) {
    return http.post(`recruitment/candidate-application/manual-apply/${jobPostId}`, convertObjToMultipart(candidate));
  }

  static copyBulkCandidates({ candidateApplicationsIds, jobPostId }) {
    return http.put(`recruitment/candidate-applications/copy/${jobPostId}`, { applications: candidateApplicationsIds });
  }

  static getJobDetails(jobPostId) {
    return http.get(`recruitment/job-post/${jobPostId}`);
  }

  static getCandidates(params) {
    return http.get('recruitment/candidates', { params }).then((response) => {
      response.data.items = response.data.items.map(
        ({ candidate, isFavoriteByCurrentUser, isSeenByCurrentUser, defaultPhoto }) => ({
          ...candidate,
          isSeenByCurrentUser,
          isFavoriteByCurrentUser,
          defaultPhoto,
        })
      );

      return response;
    });
  }

  static getCandidateDetails(candidateId) {
    return http.get(`recruitment/candidate/${candidateId}`);
  }

  static getCandidateFiles(candidateId) {
    return http.get(`recruitment/candidate/${candidateId}/files`);
  }

  static getCandidateApplications(candidateId) {
    return http.get(`recruitment/candidate-applications/candidate/${candidateId}`).then((response) => ({
      ...response,
      data: {
        ...response?.data,
        items: response?.data?.items?.map(({ application }) => application),
      },
    }));
  }

  static putCandidateDetails({ candidateId, candidateDetails }) {
    return http.put(`recruitment/candidate/${candidateId}`, candidateDetails);
  }

  static postHealthBenefit(benefitData) {
    return http.post('settings/benefits/health-insurance', benefitData);
  }

  static postVisaBenefit(benefitData) {
    return http.post('settings/benefits/visa-fee', benefitData);
  }

  static postOtherBenefit(benefitData) {
    return http.post('settings/benefits/other', benefitData);
  }

  static markCandidateAsFavorite({ candidateId, isFavorite }) {
    return http.put(`recruitment/candidate/favorite/${candidateId}`, { isFavorite });
  }

  static getGeneralStatistics(jobPostId) {
    return http.get(`recruitment/job-post/${jobPostId}/general-statistics`);
  }

  static getApplicationsPerDay({ jobPostId, params }) {
    return http.get(`recruitment/job-post/${jobPostId}/graph-statistics`, { params });
  }
}

export default RecruitmentJobsApi;
