/* eslint-disable */

import propTypesUtility from 'utility/propTypesUtility';
import PropTypes from 'prop-types';
import cx from 'classnames';

const JUSTIFY_CONTENT_CENTER = 'center';
const JUSTIFY_CONTENT_AROUND = 'around';
const JUSTIFY_CONTENT_BETWEEN = 'between';
const JUSTIFY_CONTENT_START = 'start';
const JUSTIFY_CONTENT_END = 'end';

const justifyContentOptions = (atIndex) =>
{
  const options = {
    [JUSTIFY_CONTENT_BETWEEN]: 'justify-content-between',
    [JUSTIFY_CONTENT_CENTER]: 'justify-content-center',
    [JUSTIFY_CONTENT_AROUND]: 'justify-content-around',
    [JUSTIFY_CONTENT_START]: 'justify-content-start',
    [JUSTIFY_CONTENT_END]: 'justify-content-end',
  };

  if(!atIndex){
    return options;
  }

  return options[atIndex];
}

const propTypes = () =>
{
  return {
    id: propTypesUtility.idPropType(),
    justifyContent: PropTypes.oneOf(Object.keys(justifyContentOptions())),
    divider: PropTypes.bool,
    className: PropTypes.string,
    style: propTypesUtility.stylePropType(),
    fullWidth: PropTypes.bool,
    onClick: PropTypes.func,
  };
};

const defaultProps = () =>
{
  return {
    id: 'tool-bar',
    justifyContent: JUSTIFY_CONTENT_BETWEEN,
    divider: false,
    className: '',
    style: {},
    fullWidth: true,
  };
};

const containerClasses = (justifyContent, divider, className, fullWidth) =>
{
  const dividerClass = divider ? 'border-btm' : '';
  const fullWidthClass = fullWidth ? 'w-100' : 'w-fit-content';

  return cx(
      `
          d-flex align-items-center
          ${fullWidthClass} 
          ${dividerClass} 
          ${justifyContentOptions(justifyContent)} ${className}
      `
  );
};

const onContainerClick = (onClick) => (e) => {
  if(!onClick) return;
  onClick(e);
}

export default {
  propTypes,
  defaultProps,
  containerClasses,
  onContainerClick,
};
