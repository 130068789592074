/* eslint no-console: ["error", { allow: ["warn", "error"] }] */

import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported, getToken, deleteToken } from 'firebase/messaging';

export const getFirebaseApp = () => {
  const {
    REACT_APP_FB_API_KEY: apiKey,
    REACT_APP_FB_APP_ID: appId,
    REACT_APP_FB_AUTH_DOMAIN: authDomain,
    REACT_APP_FB_DB_URL: databaseURL,
    REACT_APP_FB_SENDER_ID: messagingSenderId,
    REACT_APP_FB_PROJECT_ID: projectId,
    REACT_APP_FB_STORAGE_BUCKET: storageBucket,
  } = process.env;

  return initializeApp({
    apiKey,
    appId,
    authDomain,
    databaseURL,
    messagingSenderId,
    projectId,
    storageBucket,
  });
};

export const getFirebaseMessaging = () => getMessaging(getFirebaseApp());

export const isFirebaseMessagingSupported = async () => {
  try {
    const supported = await isSupported();
    if (!supported) console.error('Browser does not support Firebase messaging.');
    return supported;
  } catch (error) {
    console.error('Error checking if browser supports Firebase messaging: ', error);
    return false;
  }
};

export const getFirebaseToken = async (vapidKey) => {
  try {
    const token = await getToken(getFirebaseMessaging(), { vapidKey });
    if (!token) console.error('Invalid Firebase token: ', token);
    return token;
  } catch (error) {
    console.error('Error getting Firebase token:', error);
    return null;
  }
};

export const unregisterFirebase = () => {
  const messaging = getFirebaseMessaging();
  if (!messaging) return;
  deleteToken(messaging).catch((error) => {
    console.error('Error unregistering Firebase: ', error);
  });
};
