import http from './Api';

class ReleaseApi {
  static getReleases(params = '') {
    return http.get(`/master-announcements${params}`);
  }

  static getReleaseById(id, params = '') {
    return http.get(`/master-announcements/${id}${params}`);
  }

  static getLastUnseed() {
    return http.get(`/master-announcements/latest-unseen`);
  }

  static releaseMarkAsSeen(id) {
    return http.post(`/master-announcements/${id}/mark-seen`);
  }
}

export default ReleaseApi;
