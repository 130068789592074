import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ProfileApi from 'api/ProfileApi';
import ProfileTwoFactorSettings from 'api/ProfileTwoFactorSettings';
import IconButton from 'components/buttons/IconButton/IconButton';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import Radio from 'components/forms/components/Radio';
import SpinnerRing from 'components/Spinner/SpinnerRing';
import SuccessCheckmark from 'components/SuccessCheckmark/SuccessCheckmark';
import t from 'utility/setTranslation';

import styles from './SettingsAuth.module.scss';

const SettingsAuthEmail = ({ close, responseStatus, sending, onVerify, employeeId }) => {
  const [selectedEmail, setSelectedEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [activeView, setActiveView] = useState('email');
  const [savingInit, setSavingInit] = useState(false);

  useEffect(() => {
    if (employeeId) {
      setSavingInit(true);
      ProfileApi.getProfilePersonal(employeeId)
        .then((res) => {
          const array = [];
          array.push(res.data.contact.email);

          if (res.data.contact.personalEmail) {
            array.push(res.data.contact.personalEmail);
          }

          setEmails(array);

          setSavingInit(false);
        })
        .catch(() => {
          setSavingInit(false);
        });
    }
  }, []);

  const onVerifyClick = () => {
    let code = '';

    const inputs = document.getElementsByClassName('code-input');
    for (let index = 0; index < inputs.length; index += 1) {
      const element = inputs[index];

      code += element.value;
    }

    onVerify({ mode: 'email_2fa', code });
  };

  const onInitEmail = () => {
    setSavingInit(true);
    const data = {
      email: selectedEmail,
    };
    ProfileTwoFactorSettings.initEmail(data)
      .then(() => {
        setSavingInit(false);

        setActiveView('verify');
      })
      .catch(() => {
        setSavingInit(false);
      });
  };

  return (
    <div>
      {responseStatus?.resolved && <SuccessCheckmark text={responseStatus.msg} isFailed={responseStatus.isFailed} />}
      {(sending || savingInit) && <SpinnerRing />}

      <div className={cx(styles.header, 'border-btm')}>
        <i className='icon-mail' />

        <IconButton
          className={styles.close}
          icon='icon-cross-x'
          onClick={() => {
            close();
          }}
          size='md'
          color='white'
        />
      </div>

      {activeView === 'email' && (
        <div className={cx(styles.content)}>
          <div className='text-start mb-7'>
            <h5>{t('GENERAL.ACTIVATE_EMAIL_AUTH')}</h5>
            <p>{t('GENERAL.CHOOSE_YOUR_EMAIL')}</p>
          </div>

          <div className={styles.emailDiv}>
            {emails.map((el) => (
              <Radio
                key={el}
                name='email'
                value={el}
                label={el}
                disableTranslation
                className={cx(styles.emailLabel, 'mb-4 w-100')}
                checked={selectedEmail === el}
                onChange={(event) => {
                  setSelectedEmail(event.target.value);
                }}
              />
            ))}
          </div>
        </div>
      )}

      {activeView === 'verify' && (
        <div className={cx(styles.content)}>
          <div className='text-start mb-7'>
            <h5>{t('GENERAL.ACTIVATE_EMAIL_AUTH')}</h5>
            <p>{t('GENERAL.ACTIVATE_EMAIL_AUTH_INFO', { email: selectedEmail })}</p>
          </div>

          <div className={styles.codeDiv}>
            <input type='text' className={cx(styles.emailCode, 'code-input')} />
          </div>
        </div>
      )}

      <div className={cx(styles.footer, 'mt-2 mb-2 d-flex justify-content-end align-items-center px-5')}>
        <div
          className={cx(styles.buttonBack, 'me-5')}
          onClick={() => {
            if (activeView === 'verify') {
              setActiveView('email');
            } else {
              close();
            }
          }}
        >
          {activeView === 'verify' ? t('GENERAL.BACK') : t('GENERAL.CANCEL')}
        </div>

        <RoundedButton
          type='button'
          text={activeView === 'verify' ? 'Verify' : 'Next'}
          btnStyle='contained'
          color='primary'
          size='md'
          isDisabled={!selectedEmail}
          onClick={() => {
            if (activeView === 'verify') {
              onVerifyClick();
            } else {
              onInitEmail();
            }
          }}
        />
      </div>
    </div>
  );
};

SettingsAuthEmail.propTypes = {
  close: PropTypes.func.isRequired,
};

export default SettingsAuthEmail;
