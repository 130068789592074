/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './Alert.module.scss';

const Alert = ({
  type,
  textMessage,
  title,
  hasIcon,
  dismiss,
  href,
  customText,
  className,
  customButton,
  onClick,
  hasImage,
  image,
}) => {
  const [showAlert, setShowAlert] = useState(true);

  let alertClass;
  let icon;
  switch (type) {
    case 'success':
      alertClass = 'alert-success';
      icon = 'icon-circle-checked-fill';
      break;
    case 'danger':
      alertClass = 'alert-danger';
      icon = 'icon-alert-triangle-fill';
      break;
    case 'warning':
      alertClass = 'alert-warning';
      icon = 'icon-notification-fill';
      break;
    case 'info':
      alertClass = 'alert-info';
      icon = 'icon-notification-fill';
      break;
    default:
      alertClass = '';
      icon = '';
  }
  return (
    <>
      {showAlert && (
        <div
          data-testid='alert'
          className={cx(className, alertClass, styles.alert, styles[type], 'alert d-flex align-items-center p3 w-100')}
        >
          <div className={cx(' d-flex align-items-start w-100 h-100')}>
            {hasIcon && (
              <div
                data-testid='alert-icon-container'
                className={cx(styles.icons, styles[type], title ? styles.lg : styles.md, 'd-flex align-items-center')}
              >
                <i data-testid='alert-icon' className={icon} />
              </div>
            )}
            {hasImage && (
              <div data-testid='alert-img' className={cx(styles.imageBox, 'd-flex align-items-center me-3')}>
                {image}
              </div>
            )}
            <div className='d-flex align-items-start flex-grow-1 w-100'>
              <div className='d-flex flex-column flex-grow-1 w-100'>
                {title && (
                  <div className={cx(styles.title, 'd-flex align-items-center')}>
                    {href ? (
                      <Link to={href} className={cx(styles.link)} target='_blank' rel='noreferrer'>
                        {title}
                      </Link>
                    ) : (
                      title
                    )}
                  </div>
                )}
                {textMessage && (
                  <div
                    data-testid='clickableDiv'
                    onClick={onClick}
                    className={cx(styles.textMessage, 'd-flex align-items-center w-100')}
                  >
                    {textMessage}
                  </div>
                )}
                {customText?.content && (
                  <div
                    className={cx(styles.textMessage, 'd-flex align-items-center')}
                    style={{ fontWeight: customText.fontWeight }}
                  >
                    <span
                      style={{ cursor: customText?.OnClick ? 'pointer' : 'auto' }}
                      onClick={
                        customText?.OnClick
                          ? () => {
                              customText.OnClick(true);
                            }
                          : () => false
                      }
                    >
                      {customText.content}
                    </span>
                  </div>
                )}
              </div>
              {dismiss && (
                <div
                  data-testid='clickableIconContainer'
                  className={cx(styles.dismiss, title ? styles.lg : styles.md, 'd-flex align-items-center')}
                >
                  <i
                    data-testid='clickableIcon'
                    className='icon-cross-x'
                    onClick={() => {
                      setShowAlert(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          {customButton && (
            <div data-testid='customButton' className='d-flex align-items-center'>
              {customButton}
            </div>
          )}
        </div>
      )}
    </>
  );
};

Alert.propTypes = {
  type: PropTypes.string.isRequired,
  textMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  title: PropTypes.string,
  hasIcon: PropTypes.bool,
  hasImage: PropTypes.bool,
  dismiss: PropTypes.bool,
  href: PropTypes.string,
  customText: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  customButton: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
};

Alert.defaultProps = {
  title: '',
  hasIcon: false,
  hasImage: false,
  dismiss: false,
  image: '',
  href: '',
  customText: {},
  className: '',
  customButton: null,
  onClick: undefined,
};

export default Alert;
