import React from 'react';

import cx from 'classnames';

import styles from './NotificationBanner.module.scss';

const NotificationBanner = ({ isShow, message, className, renderActions, type }) => {
  if (!isShow) {
    return null;
  }

  const icons = {
    warning: 'icon-info-fill',
    danger: 'icon-alert-triangle-fill',
    primary: 'icon-info-fill',
  };

  return (
    <div className={cx(className, styles[type], styles.NotificationBanner)}>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex align-items-start flex-1'>
          <i className={cx(icons[type], 'font-size-20 white')} />
          <span className='ms-2 font-size-14 font-weight-bold white'>{message}</span>
        </div>
        {renderActions && renderActions()}
      </div>
    </div>
  );
};

export default NotificationBanner;
