/* eslint-disable */

import propTypesUtility from 'utility/propTypesUtility';
import PropTypes from 'prop-types';
import cx from 'classnames';
import style from './style.module.scss';
import sizeUtility from 'utility/style/sizeUtility';
import colorUtility from 'utility/style/colorUtility';

const propTypes = () => {
  return {
    id: propTypesUtility.idPropType(),
    color: propTypesUtility.colorPropType(),
    size: propTypesUtility.sizePropType(),
    clickable: PropTypes.bool,
    hoverable: PropTypes.bool,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  };
};

const defaultProps = () => {
  return {
    id: 'list-item',
    clickable: true,
    hoverable: true,
    active: false,
    disabled: false,
    onClick: null,
    color: colorUtility.defaultColor(),
    size: sizeUtility.xsSize(),
  };
};

const containerClasses = (disabled, onClick, active, size, color, hoverable) => {
  return cx(
    cx(style.container, {
      [style.disabled]: disabled,
      [style.clickable]: !!onClick && !disabled,
      [style.active]: active,
    }),
    cx(style.sizeFont, { [style[size]]: true }),
    cx(style.color, { [style[color]]: true }),
    active && cx(style.colorBackground, { [style[color]]: true }),
    cx(style.colorBackgroundHover, { [style[color]]: hoverable })
  );
};

export default {
  propTypes,
  defaultProps,
  containerClasses,
};
