import http from './Api';

class TimesheetsApi {
  static getTimesheetsByPeriod(period, filters = '') {
    return http.get(`/time/timesheets/approval-period/${period}/timesheets${filters}`);
  }

  static getEmployeeTimesheetByPeriod(employeeId, periodId) {
    return http.get(`/time/timesheets/employee/${employeeId}/approval-period/${periodId}/timesheet`);
  }

  static getTimesheetsByDate(date, filters = '') {
    return http.get(`/time/timesheets/approval-period/date/${date}/timesheets${filters}`);
  }

  static getProgress(id) {
    return http.get(`/time/timesheets/approval-periods/${id}/progress`);
  }

  static getProgressTotal(id, type) {
    return http.get(`/time/timesheets/approval-periods/${id}/progress/${type}`);
  }

  static getApprovalsPeriods(approvalPeriod) {
    return http.get(`/time/timesheets/approval-periods/${approvalPeriod}/preview`);
  }

  static getPeriodsByYear(year) {
    return http.get(`/time/timesheets/approval-periods/year/${year}/monthly`);
  }

  static getAllPeriods() {
    return http.get(`/time/timesheets/approval-periods/months`);
  }

  static getAllPeriodsForEmployee(employeeId) {
    return http.get(`/time/timesheets/employee/${employeeId}/approval-periods/months`);
  }

  static approveTimesheet(id) {
    return http.put(`/time/timesheets/${id}/approve`);
  }

  static revertTimesheet(id) {
    return http.put(`/time/timesheets/${id}/revert`);
  }

  static importTimesheetFile(periodId, data) {
    return http.post(`/time/timesheets/approval-period/${periodId}/import`, data);
  }

  static submitTimesheetRecords(periodId, data) {
    return http.post(`/time/timesheets/approval-period/${periodId}/submit`, data);
  }

  static checkTimesheetAccess() {
    return http.get('/time/timesheets/access');
  }

  static getTimesheetSettingsInfo() {
    return http.get('/timesheet-settings/info');
  }

  static isInProcess() {
    return http.get('/time/timesheets/recalculation');
  }

  static multipleAproveTimesheets(data, params) {
    return http.post(`/time/timesheets/multiple-approve${params}`, data);
  }

  static multipleRevertlTimesheets(data, params) {
    return http.post(`/time/timesheets/multiple-revert${params}`, data);
  }

  static getTimesheetById(id) {
    return http.get(`/time/timesheets/employee-timesheets/${id}`);
  }

  static alignTimesheetAdditions(data, params) {
    return http.post(`/time/timesheets/schedule-additions${params}`, data);
  }

  static alignTimesheetDeductions(data, params) {
    return http.post(`/time/timesheets/schedule-deductions${params}`, data);
  }

  // Approval
  static getApprovalPeriods(year) {
    return http.get(`/time/approval/approval-periods/year/${year}/monthly`);
  }

  static getApprovalTimesheets(id, filters = '') {
    return http.get(`/time/approval/approval-periods/${id}/timesheets${filters}`);
  }

  static getApprovalTimesheetsByDate(date, filters = '') {
    return http.get(`/time/approval/approval-periods/date/${date}/timesheets${filters}`);
  }

  static offscheduleRecords(data, params) {
    return http.post(`/time/approval/approval-periods/offschedule-records${params}`, data);
  }

  static multipleReviewApprovalTimesheets(data, params) {
    return http.post(`/time/approval/timesheets/multiple-review${params}`, data);
  }

  static multipleRevertApprovalTimesheets(data, params) {
    return http.post(`/time/approval/timesheets/multiple-revert${params}`, data);
  }

  static alignApprovalTimesheetAdditions(data, params) {
    return http.post(`/time/approval/schedule-additions${params}`, data);
  }

  static alignApprovalTimesheetDeductions(data, params) {
    return http.post(`/time/approval/schedule-deductions${params}`, data);
  }

  // Statistic
  static getApprovalPeriodsStatistic(id) {
    return http.get(`/time/approval/approval-periods/${id}/statistics`);
  }

  static getApprovalPeriodsStatisticByDate(date) {
    return http.get(`/time/approval/approval-periods/date/${date}/statistics`);
  }

  static getTimesheetStatistic(id) {
    return http.get(`/time/timesheets/approval-periods/${id}/statistics`);
  }

  static getTimesheetStatisticByDate(date) {
    return http.get(`/time/timesheets/approval-periods/date/${date}/statistics`);
  }

  static revertApprovalTimesheet(id) {
    return http.put(`/time/approval/timesheets/${id}/revert`);
  }

  static reviewTimesheet(id) {
    return http.put(`/time/approval/timesheets/${id}/review`);
  }

  static completeTimesheet(id) {
    return http.put(`/time/timesheets/approval-periods/${id}/complete`);
  }

  static uncompleteTimesheet(id) {
    return http.put(`/time/timesheets/approval-periods/${id}/uncomplete`);
  }

  // Activities
  static getTimesheetActivities(timesheetId) {
    return http.get(`time/timesheets/approval-period/${timesheetId}/activities`);
  }

  static getEmployeeTimesheetActivities(timesheetId, filters = '') {
    return http.get(`time/timesheets/employee-timesheet/${timesheetId}/activities${filters}`);
  }

  static getEmployeeAttendanceActivities(attendanceId, filters = '') {
    return http.get(`time/timesheets/employee-attendance/${attendanceId}/activities${filters}`);
  }

  // Wizard
  static getWizardStatus() {
    return http.get(`time/timesheets/setup-progress`);
  }

  // Details

  static getTimesheetDetails(timesheetId) {
    return http.get(`time/timesheets/employee/${timesheetId}/details`);
  }

  // pdf, csv, xls
  static getTimesheetDetailsExport(id, type, filters = '') {
    return http.get(`time/timesheets/${id}/export/${type}${filters}`, {
      responseType: 'arraybuffer',
    });
  }

  static getMissingTimeStamps({ timesheetId, params }) {
    return http.get(`time/timesheets/${timesheetId}/unresolved-records`, {
      params,
    });
  }

  static deleteMissingTimeStamps({ timesheetId, data }) {
    return http.post(`time/timesheets/${timesheetId}/unresolved-records/bulk-delete`, data);
  }

  static updateMissingTimeStamps({ timesheetId, data }) {
    return http.post(`time/timesheets/${timesheetId}/unresolved-records/bulk-edit`, data);
  }

  static getMissingStampsCount({ timesheetId }) {
    return http.get(`time/timesheets/${timesheetId}/unresolved-records/total`);
  }
}

export default TimesheetsApi;
