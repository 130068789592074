import React from 'react';

import _ from 'lodash';

import Personas from 'components/Personas';
import Tooltip from 'components/tooltip/Tooltip';
import reportUtil from 'utility/report/reportUtil';

import arrayUtil from './arrayUtil';
import t from './setTranslation';
import styles from '../components/table/CellName.module.scss';
import { reportConstants } from '../constants/report';

function employeeDOM(employee = {}, authState = null, isLink = true, size = null) {
  // eslint-disable-next-line no-param-reassign
  isLink = !isLink
    ? false
    : !authState
    ? false
    : !authState.user.roles.includes('ROLE_EMPLOYEE') || authState.user.identification === employee.id;

  const dom = (
    <Personas
      user={employee || {}}
      size={size}
      showDetails
      customClass={isLink ? styles.personasLink : ''}
      iconStatus={
        employee.status === 2 ? (
          <div className='ps-2 d-flex align-items-center'>
            <Tooltip label={t('GENERAL.OFFBOARDED')} position='right'>
              <i className='icon-user-remove text-red' />
            </Tooltip>
          </div>
        ) : null
      }
    />
  );

  if (!isLink) return dom;

  return (
    <a
      className='align-items-center profile-link'
      onClick={(e) => e.stopPropagation()}
      href={`/profile/${employee?.id}`}
    >
      {dom}
    </a>
  );
}

function mergeColumns(defaultColumns, userColumns) {
  const cols = [];

  defaultColumns.forEach((col) => {
    const found = _.find(userColumns, { column: { name: col.name } });
    const c = { ...col };
    if (found) {
      c.visible = found.visible;
      c.columnOrder = found.columnOrder;
    }
    cols.push(c);
  });

  return cols;
}

function sortColumns(cols) {
  return arrayUtil.sortByProperty(cols, 'asc', 'columnOrder');
}

function columns(userColumns, config) {
  if (!userColumns || userColumns.length === 0) return config;

  const cols = [];
  const sortedCols = sortColumns(userColumns);

  // format user cols ie. sort
  Object.keys(sortedCols).forEach((key) => {
    const col = sortedCols[key];

    let column = { ..._.find(config, ['name', col.name]) };
    if (!column) column = { tdConfig: {}, thConfig: {} };
    if (!column.tdConfig) column.tdConfig = {};
    if (!column.thConfig) column.thConfig = {};
    if (!column.thConfig.title) column.thConfig.title = t(reportUtil.columnTransKey(col.name));

    cols.push({ ...column, ...col });
  });

  return cols;
}

const formatColumnValue = (value, columnConfig) => {
  const tdConfig = columnConfig?.tdConfig || {};
  const format = typeof tdConfig?.format === 'function' ? tdConfig.format : null;
  let formattedValue = null;

  if (_.isArray(value)) {
    formattedValue = value.map((item) => (format ? format(item) : item));
    formattedValue = arrayUtil.implode(formattedValue, ', ');
  }

  if (
    _.isObject(value) &&
    !React.isValidElement(value) &&
    columnConfig.name !== reportConstants.COLUMN_NAME_EMPLOYEE_NAME
  ) {
    formattedValue = [];

    Object.keys(value).forEach((batchValueName) => {
      const batchColumnValue = value[batchValueName];
      const formattedBatchColumnValue = format ? format(batchColumnValue) : batchColumnValue;
      formattedValue.push(`${batchValueName}: ${formattedBatchColumnValue}`);
    });

    formattedValue = _.isEmpty(formattedValue) ? (
      '--'
    ) : (
      <>
        {formattedValue.map((formattedValueChunk) => (
          <div key={formattedValueChunk}>{formattedValueChunk}</div>
        ))}
      </>
    );
  }

  if (_.isString(value) && format) {
    formattedValue = format(value);
  }

  if (!formattedValue) {
    formattedValue = format ? format(value) : value;
  }

  return {
    value: formattedValue,
    tdConfig,
  };
};

function rows(userColumns, records, config) {
  if (!userColumns || userColumns?.length === 0) return [];

  const cols = columns(userColumns, config);
  const rec = [];

  // format records based on formatted user cols
  records.forEach((item) => {
    const data = {};

    Object.keys(item).forEach((colName) => {
      const itemCol = { ..._.find(cols, ['name', colName]) };

      if (_.isEmpty(itemCol)) {
        return;
      }

      const propName = itemCol.readFrom ? itemCol.readFrom : colName;

      data[propName] = formatColumnValue(item[colName], itemCol);
    });

    if (_.isEmpty(data)) return;

    rec.push(data);
  });

  return rec;
}

function tableData(cols, records, header) {
  const table = { rows: [], columns: [] };
  if (!cols || cols.length === 0) return table;

  table.columns = columns(cols, header);
  table.rows = rows(cols, records, header);

  return table;
}

export default { tableData, mergeColumns, columns, sortColumns, rows, employeeDOM };
