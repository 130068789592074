import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { useResolvedPath } from 'react-router-dom';

import { AuthContext } from 'auth/AuthContext';
import HeaderTitle from 'components/HeaderTitle/HeaderTitle';
import NavBar from 'components/Navigation/NavBar';
import t from 'utility/setTranslation';

const FilesHeader = ({ active, onSelectView }) => {
  const { authState } = useContext(AuthContext);
  const hasPermission = authState.user.roles.includes('ROLE_ADMIN') || authState.user.roles.includes('ROLE_HR');
  const url = useResolvedPath('').pathname;

  const navItems = [
    { title: 'company-files', href: `${url}/company-files` },
    { title: hasPermission ? 'employee-documents' : 'my-documents', href: `${url}/employee-documents` },
  ];

  return (
    <>
      <div className='d-flex align-items-center justify-content-between pt-4 mb-4'>
        <HeaderTitle>{t('NAVBAR.FILES')}</HeaderTitle>
      </div>

      <NavBar selectView={onSelectView} active={active} items={navItems} type='link' />
    </>
  );
};

FilesHeader.propTypes = {
  active: PropTypes.string.isRequired,
  onSelectView: PropTypes.func.isRequired,
};

export default FilesHeader;
