import React, { useContext } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import khadamatiLogo from 'assets/img/enterprise/khadamati/logo_primary.png';
import palmhrLogo from 'assets/img/logo.svg';
import { AuthContext } from 'auth/AuthContext';
import { ThemeContext } from 'themes/ThemeContext';
import readName from 'utility/readName';

import styles from './Company.module.scss';

const Company = ({ company, size, customClass, icon }) => {
  const { authState } = useContext(AuthContext);
  const { themeState } = useContext(ThemeContext);
  const enterpriseName = themeState?.companyName || 'palmhr';

  const imageMap = {
    palmhr: palmhrLogo,
    khadamati: khadamatiLogo,
  };

  const eventName = readName(authState.user.language, company?.name, company?.nameArabic);

  const avatar = imageMap[enterpriseName];

  return (
    <div className={cx(customClass, 'd-flex align-items-center position-relative')}>
      <div
        className={cx(
          styles.profileImage,
          {
            [styles.primary]: !avatar,
          },
          'image-profle'
        )}
        style={{ width: `${size}px`, height: `${size}px`, minWidth: `${size}px`, minHeight: `${size}px` }}
      >
        {avatar ? (
          <img className={styles.img} src={avatar} alt={company?.name} />
        ) : (
          <span className={cx(styles.initials, 'text-white')} style={{ fontSize: `${Math.round(size * 0.4)}px` }}>
            NN
          </span>
        )}
      </div>

      <div className='ms-3' style={{ width: `calc(100% - 0.5rem - ${size}px)` }}>
        <div className='d-flex justify-content-start'>
          <p className={cx(styles.name, 'text-truncate mb-0')}>{eventName || '- -'}</p>
        </div>
        <p className={styles.desc}>{company.description || '- -'}</p>
      </div>

      <div>{icon}</div>
    </div>
  );
};

Company.propTypes = {
  company: PropTypes.object.isRequired,
  size: PropTypes.number,
  description: PropTypes.string,
};

Company.defaultProps = {
  size: 32,
  description: '',
};

export default Company;
