import ar from 'apexcharts/dist/locales/ar.json';
import en from 'apexcharts/dist/locales/en.json';
import moment from 'moment';

export const chartOptions = (maxDate) => ({
  id: 'area-datetime',
  grid: {
    borderColor: '#e9edef',
    strokeDashArray: 3,
    xaxis: {
      lines: {
        show: false,
      },
    },
    padding: {
      top: 30,
      right: 32,
      left: 12,
    },
    row: {
      colors: undefined,
      opacity: 1,
    },
  },
  chart: {
    id: 'CandidateApplicationsChart',
    locales: [en, ar],
    defaultLocale: 'en',
    type: 'area',
    foreColor: '#93a5b1',
    events: {
      zoomed(chartContext, { xaxis }) {
        const maxDateOnX = moment(xaxis.max);
        if (maxDateOnX.isAfter(maxDate)) {
          chartContext.resetSeries();
        }
      },
    },
    zoom: {
      autoScaleYaxis: true,
    },
    toolbar: {
      tools: {
        zoomin: '<i class="icon-circle-plus-manual-addition">',
        zoomout: '<i class="icon-circle-minus-manual-deduction">',
        pan: true,
        zoom: false,
        download: false,
        reset: false,
      },
      autoSelected: 'pan',
    },
  },
  colors: ['#1A938A'],
  stroke: {
    curve: 'straight',
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    style: 'hollow',
  },
  xaxis: {
    type: 'datetime',
    tickAmount: 12,
    axisBorder: {
      show: true,
      color: '#e9edef',
      height: 1,
    },
    axisTicks: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    labels: {
      show: true,
      trim: true,
      datetimeUTC: false,
      showDuplicates: false,
      minHeight: undefined,
      maxHeight: 120,
      style: {
        colors: ['#93a5b1'],
        fontSize: '12px',
      },
      datetimeFormatter: {
        month: 'MMM',
      },
    },
  },
  yaxis: {
    decimalsInFloat: 0,
    tooltip: {
      enabled: false,
      offsetX: 0,
    },
    labels: {
      show: true,
      align: 'left',
      offsetX: 0,
      formatter(val) {
        return val === Infinity ? '' : val;
      },
    },
    min: 0,
    forceNiceScale: true,
  },
  tooltip: {
    x: {
      format: 'dd MMM yyyy',
    },
    y: {
      title: {
        formatter: () => 'Applicants:',
      },
    },
    marker: {
      show: false,
    },
  },
  fill: {
    type: 'solid',
    opacity: 0,
  },
});
