import { useContext } from 'react';

import { AuthContext } from 'auth/AuthContext';

function useLanguage() {
  const userContext = useContext(AuthContext);

  return userContext?.authState?.user?.language || 'en';
}

export default useLanguage;
