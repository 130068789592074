import React, { useContext } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import palmhrIcon from 'assets/img/empty_list_icon.png';
import khadamatiIcon from 'assets/img/enterprise/khadamati/empty_list_icon.png';
import { ThemeContext } from 'themes/ThemeContext';
import t from 'utility/setTranslation';

import styles from './EmptyList.module.scss';

const EmptyList = ({ text, iconSize, paddingTop, pMarginTop, paragrafSize }) => {
  const { themeState } = useContext(ThemeContext);
  const enterpriseName = themeState?.companyName || 'palmhr';

  const imageMap = {
    palmhr: palmhrIcon,
    khadamati: khadamatiIcon,
  };
  return (
    <div className={cx('m-auto', paddingTop || 'pt-8 pb-5')}>
      <div className={cx(styles.emptyList, 'm-auto')}>
        <div
          className={cx(
            styles.iconHistory,
            styles[iconSize],
            'd-flex',
            'justify-content-center',
            'align-items-center',
            'm-auto'
          )}
        >
          <img src={imageMap[enterpriseName]} alt='empty-list' />
        </div>

        <p className={cx('text-center', styles.text, pMarginTop || 'mt-4', styles[paragrafSize])}>{t(text)}</p>
      </div>
    </div>
  );
};

EmptyList.propTypes = {
  iconSize: PropTypes.string,
  text: PropTypes.string.isRequired,
  paddingTop: PropTypes.string,
  pMarginTop: PropTypes.string,
  paragrafSize: PropTypes.string,
};

EmptyList.defaultProps = {
  iconSize: '',
  paddingTop: '',
  pMarginTop: '',
  paragrafSize: '',
};

export default EmptyList;
