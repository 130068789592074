import React, { useContext } from 'react';

import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import useResolveHandler from 'shared/useResolveHandler';
import t from 'utility/setTranslation';
import { usePlgClearData } from '../../../../hooks/useAccount';
import { AuthContext } from '../../../../../auth/AuthContext';
import { IsLoggedInContext } from '../../../../../auth/IsLoggedInContext';


const ClearDataModal = ({ isOpen, onClose }) => {
  const { mutate, isLoading } = usePlgClearData();
  const { responseStatus, setStatus } = useResolveHandler();

  const { dispatch } = useContext(AuthContext);
  const { dispatch: dispatchIsLoggedIn } = useContext(IsLoggedInContext);

  const handleClearData = () => {
    mutate(null, {
      onSuccess: () => {
        setStatus(true, t('NAVBAR.CLEAR_DATA_SUCCESS_MSG'));
        setTimeout(() => {
          dispatch({
            type: 'del',
          });
          dispatchIsLoggedIn({
            type: 'set',
            payload: { isLoggedIn: false },
          });
          onClose();
        }, 2000);
      },
      onError: (error) => {
        setStatus(false, null, t(error.response.data.message));
      },
    });
  };

  return (
    <ConfirmationModal
      type='warning'
      isLoading={isLoading}
      responseStatus={responseStatus}
      actionText='Clear Data'
      onAction={handleClearData}
      onClose={onClose}
      isOpen={isOpen}
      description='GENERAL.CLEAR_DATA_WARNING'
      styles={{ width: '560px' }}
    />
  );
};

export default ClearDataModal;
