import { useQuery } from '@tanstack/react-query';
import Localize from 'react-intl-universal';

import TranslationsApi from 'api/TranslationsApi';
import khdamatiFavicon from 'assets/img/enterprise/khadamati/favicon.ico';
import palmhrFavicon from 'assets/img/icon-square.svg';
import { getThemeFromLS } from 'themes/ThemeContext';
import { configFormValidationMessageLocalization } from 'utility/validation/yupConfig';

const themeMap = {
  palmhr: {
    favicon: palmhrFavicon,
    title: 'APPLICATION.TITLE',
  },
  khadamati: {
    favicon: khdamatiFavicon,
    title: 'AHAD.TITLE',
  },
};

export const loadLocales = ({ language, translations }) => {
  const themeState = getThemeFromLS('theme');
  const enterpriseName = themeState?.companyName || 'palmhr';

  Localize.init({
    currentLocale: language,
    locales: {
      [language]: translations,
    },
  }).then(() => {
    document.title = Localize.get(themeMap[enterpriseName].title);
    configFormValidationMessageLocalization();
    const favicon = document.getElementById('favicon');
    favicon.href = themeMap.palmhr.favicon || '';
  });
};

export const queryKeys = {
  translations: (lang) => ['translations', lang],
};

export const useQueryTranslations = (language, options = {}) => {
  const storageLanguage = localStorage.getItem(`translations-${language}`);
  const defaultQueryConfig = storageLanguage ? { placeholderData: { data: JSON.parse(storageLanguage) } } : {};

  return useQuery(queryKeys.translations(language), () => TranslationsApi.getTranslations(language), {
    ...defaultQueryConfig,
    ...options,
  });
};
