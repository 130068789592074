import React, { useMemo } from 'react';

import { PHRList } from '@palmhr/palmhr-ui-lib';
import cx from 'classnames';

import IconButton from 'components/buttons/IconButton/IconButton';
import ContentHeader from 'components/ContentHeader';
import t from 'utility/setTranslation';

import QuickstartChecklistItem from './QuickstartChecklistItem';
import LoadingList from '../../../../../components/LoadingList/LoadingList';
import TasksProgressBar from '../TasksProgressBar/TasksProgressBar';

const QuickstartChecklistList = ({ items, onBack, isLoading, activeLang }) => {
  const progress = useMemo(() => {
    const completedItems = items.filter((item) => item.isCompleted).length;
    return (completedItems / items.length) * 100;
  }, [items]);

  return (
    <>
      <ContentHeader
        className={cx(
          'd-flex align-items-center justify-start gap-2',
          activeLang === 'en' ? 'flex-row-reverse' : 'flex-row'
        )}
      >
        <IconButton icon={activeLang === 'en' ? 'icon-arrow-right' : 'icon-arrow-left'} size='md' onClick={onBack} />
        <h5 className='fw-bold m-0 text-main'>{t('GENERAL.QUICKSTART_CHECKLIST')}</h5>
      </ContentHeader>
      {isLoading && (
        <div className='d-flex gap-2 align-items-center' style={{ width: '328px' }}>
          <LoadingList
            rowsCount={6}
            spaceBetween={0}
            rowHeight={42}
            className='justify-content-center gap-4 flex-1 pt-4 px-4'
          />
        </div>
      )}
      {!isLoading && (
        <PHRList
          items={items}
          renderItem={(item) => (
            <QuickstartChecklistItem
              title={item.title}
              isCompleted={item.isCompleted}
              onClick={item.onClick}
              isLoading={isLoading}
              activeLang={activeLang}
            />
          )}
          className='list-unstyled bg-white pt-4 pb-2 px-4'
        />
      )}
      <div className='px-4 pb-5 pt-14'>
        <TasksProgressBar progress={progress} isLoading={isLoading} activeLang={activeLang} />
      </div>
    </>
  );
};

export default QuickstartChecklistList;
