/* eslint-disable react/no-danger */
import React, { useContext, useState, useEffect } from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Mention, MentionsInput } from 'react-mentions';

import CommentApi from 'api/CommentApi';
import TeamApi from 'api/TeamApi';
import { AuthContext } from 'auth/AuthContext';
import IconButton from 'components/buttons/IconButton/IconButton';
import Menu from 'components/menu/Menu';
import createSelectOptions from 'components/menu/utility/createSelectOptions';
import Personas from 'components/Personas';
import Spinner from 'components/Spinner/Spinner';
import Tooltip from 'components/tooltip/Tooltip';
import GeneralStore from 'stores/GeneralStore';
import findEmployeeInText from 'utility/findEmployeeInText';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

import styles from './CommentBox.module.scss';
import MentionInputStyle from '../../EngagementCenter/shared/Conversations/MentionInputStyle';
import MentionStyle from '../../EngagementCenter/shared/Conversations/MentionStyle';

const AnnouncementCommentItem = ({ data, onDelete, onEditUpdate, commentThread }) => {
  const { authState } = useContext(AuthContext);
  const generalStore = useContext(GeneralStore);
  const canDoActions =
    authState.user.roles.includes('ROLE_ADMIN') || data?.createdBy?.id === authState.user.identification;
  const [editCommentMode, setEditCommentMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(data.message);
  const { user } = authState;
  const [mentionPeople, setMentionPeople] = useState([]);
  let commentWrapperRef;

  const actions = ['Delete'];
  if (data?.createdBy?.id === authState.user.identification) {
    actions.unshift('Edit');
  }

  const onAction = (action) => {
    switch (action) {
      case 'Delete':
        onDelete(data);
        break;
      case 'Edit':
        setEditCommentMode(true);
        break;
      default:
        break;
    }
  };

  const onEdit = (commentId, editData) => {
    const sentData = {
      message: editData,
    };
    setLoading(true);
    CommentApi.editComment(commentThread.id, commentId, sentData)
      .then((res) => {
        setInputValue(res.data.message);
        onEditUpdate(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  // mention people
  const getAllActivePeople = () => {
    const params = `?sort=${authState.user.language === 'ar' ? 'e.fullNameArabic' : 'e.fullName'}&direction=asc`;

    TeamApi.getAllActiveEmployees(params).then((res) => {
      const activeEmployee = [];
      res.data.items.forEach((emp) => {
        activeEmployee.push({
          ...emp,
          display: readName(user.language, emp.fullName, emp.fullNameArabic),
        });
      });

      generalStore.setAllActiveUsers(activeEmployee);
      setMentionPeople(activeEmployee);
    });
  };

  useEffect(() => {
    if (generalStore.allActiveUsers.length === 0) {
      getAllActivePeople();
    } else {
      setMentionPeople(generalStore.allActiveUsers);
    }
  }, []);

  return (
    <div className={cx(styles.commentWrapper)}>
      <div className={cx(styles.imageDiv)}>
        <Personas user={data.createdBy || {}} size={32} />
      </div>

      <div
        className={cx(styles.textDiv, 'ms-7')}
        ref={(el) => {
          commentWrapperRef = el;
        }}
      >
        <div className={cx(styles.info, 'd-flex justify-content-between align-items-ceneter')}>
          <div className='d-flex justify-content-start align-items-ceneter'>
            <span className={cx(styles.autorName, 'me-2')}>
              {readName(authState.user.language, data.createdBy?.fullName, data.createdBy?.fullNameArabic)}
            </span>
            <span className={cx(styles.date)}>{moment(data?.createdAt).fromNow()}</span>
            {data.updatedAt && data.updatedBy && (
              <div className={cx(styles.edited, 'ms-2')}>
                <Tooltip
                  label={t('DASHBOARD.ANNOUNCEMENT.COMMENT_EDITED', {
                    user: readName(authState.user.language, data.updatedBy?.fullName, data.updatedBy?.fullNameArabic),
                    date: moment(data.updatedAt).format('DD MMM, YYYY HH:mm'),
                  })}
                  position='left'
                >
                  <i className='icon-bullet-point' />
                  <span>{t('GENERAL.EDITED')}</span>
                </Tooltip>
              </div>
            )}
          </div>

          <div>
            {canDoActions &&
              (editCommentMode ? (
                <div className='d-flex justify-content-end align-items-ceneter'>
                  <IconButton
                    color='green'
                    icon='icon-check-mark'
                    size='sm'
                    onClick={(e) => {
                      e.stopPropagation();
                      onEdit(data.id, inputValue);
                      setEditCommentMode(false);
                      setInputValue(data.message);
                    }}
                  />
                  <IconButton
                    className='ms-2'
                    color='danger'
                    icon='icon-cross-x'
                    size='sm'
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditCommentMode(false);
                      setInputValue(data.message);
                    }}
                  />
                </div>
              ) : (
                <div className='position-relative'>
                  <Menu
                    data={createSelectOptions(actions)}
                    onAction={onAction}
                    style={{ width: '150px' }}
                    iconSize='md'
                  />
                </div>
              ))}
          </div>
        </div>
        {editCommentMode ? (
          <MentionsInput
            className={cx(styles.textarea)}
            style={MentionInputStyle}
            value={inputValue}
            allowSpaceInQuery
            placeholder={t('GENERAL.WRITE_YOUR_COMMENT')}
            onChange={(e) => setInputValue(e.target.value)}
            suggestionsPortalHost={commentWrapperRef}
            allowSuggestionsAboveCursor
          >
            <Mention
              style={MentionStyle}
              trigger='@'
              data={mentionPeople}
              renderSuggestion={(entry, search, highlightedDisplay) => (
                <div className='d-flex justify-content-start align-items-center'>
                  <Personas user={mentionPeople.filter((el) => el.id === entry.id)[0]} size={16} customClass='me-2' />

                  <div>{highlightedDisplay}</div>
                </div>
              )}
              markup='@mention(__id__)[__display__]'
              appendSpaceOnAdd
              displayTransform={(id, display) => `@${display}`}
            />
          </MentionsInput>
        ) : loading ? (
          <Spinner />
        ) : (
          <p
            className={styles.comment}
            dangerouslySetInnerHTML={{
              __html: findEmployeeInText(inputValue),
            }}
          />
        )}
      </div>
    </div>
  );
};

AnnouncementCommentItem.propTypes = {
  data: PropTypes.object.isRequired,
  onEditUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  commentThread: PropTypes.object.isRequired,
};

export default AnnouncementCommentItem;
