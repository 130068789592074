import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import khadamatiLogo from 'assets/img/enterprise/khadamati/logo_primary.png';
import palmhrLogo from 'assets/img/logo.svg';
import { AuthContext } from 'auth/AuthContext';
import HeaderTitle from 'components/HeaderTitle/HeaderTitle';
import NavBar from 'components/Navigation/NavBar';
import { ThemeContext } from 'themes/ThemeContext';
import readName from 'utility/readName';

import styles from './Organization.module.scss';
import resourcePath from '../../../shared/resourcePath';

const Header = ({ formData, hasGosiIntegration }) => {
  const { authState } = useContext(AuthContext);
  const { themeState } = useContext(ThemeContext);
  const enterpriseName = themeState?.companyName || 'palmhr';
  const path = '/organization';

  const imageMap = {
    palmhr: palmhrLogo,
    khadamati: khadamatiLogo,
  };

  let navItems = [
    { title: 'people', href: `${path}/people` },
    { title: 'org_chart', href: `${path}/org_chart` },
  ];

  if (authState.user.roles.includes('ROLE_HR') || authState.user.roles.includes('ROLE_ADMIN')) {
    navItems = [
      { title: 'people', href: `${path}/people` },
      { title: 'org_chart', href: `${path}/org_chart` },
      { title: 'onboarding', href: `${path}/onboarding` },
      { title: 'offboarding', href: `${path}/offboarding` },
      { title: 'insights', href: `${path}/insights` },
    ];

    if (hasGosiIntegration) {
      navItems.push({ title: 'gosi', href: `${path}/gosi` });
    }
  }

  const renderLogo = () => {
    let src = imageMap[enterpriseName];
    if (formData?.companyLogo) {
      src = resourcePath(formData.companyLogo);
    }
    return src;
  };

  return (
    <div className='d-flex flex-column mb-3 pageContainerWidth mx-auto'>
      <div className='d-flex justify-content-between pt-4'>
        <div className='d-flex align-items-center mb-4'>
          <div className='d-flex me-2a'>
            <div className={styles.logoWrapper}>
              <img src={renderLogo()} alt='company-logo' className={styles.logo} />
            </div>
          </div>

          <HeaderTitle>{readName(authState.user.language, formData.fullName, formData.fullNameArabic)}</HeaderTitle>
        </div>
      </div>

      <NavBar type='link' items={navItems} />
    </div>
  );
};

Header.propTypes = {
  formData: PropTypes.object.isRequired,
  hasGosiIntegration: PropTypes.bool.isRequired,
};

export default Header;
