import React, { useState, useEffect, Suspense, lazy } from 'react';

import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import AreaLoader from 'components/Spinner/AreaLoader';
import CustomRoute from 'topNavigation/Router/CustomRoute';

import Header from './Header';
import PayGettingStarted from './PayGettingStarted/PayGettingStarted';
import PayrollAccessContext from './PayrollAccessContext';
import PayApi from '../../api/PayApi';

const Compensation = lazy(() => import('./Compensation/Compensation'));
const Insights = lazy(() => import('./Insights/Insights'));
const Payroll = lazy(() => import('./Payroll/Payroll'));

const Pay = () => {
  const [selectedView, setSelectedView] = useState('');
  const [payrollPermissions, setPayrollPermissions] = useState(false);
  const [payInsightsAccess, setPayInsightsAccess] = useState(null);
  const path = '/pay';
  const navigate = useNavigate();

  const getPayrollPermissions = () => {
    PayApi.checkPayrollAccess()
      .then((res) => {
        setPayrollPermissions(res?.data);
      })
      .catch(() => {});
  };

  const getPayInsightAccess = () => {
    PayApi.getPayrollAccessInsights()
      .then((res) => {
        setPayInsightsAccess(res?.data?.value);
        if (window.location.pathname === path || window.location.pathname === `${path}/`) {
          navigate(`${path}/compensation`);
        }
      })
      .catch(() => {
        setPayInsightsAccess(false);
      });
  };

  useEffect(() => {
    getPayrollPermissions();
    getPayInsightAccess();
  }, []);

  const onSelectView = (view) => {
    setSelectedView(view);
  };

  return (
    <div className={'pageContainer page-top-positioning px-1 position-relative'}>
      <Header
        active={selectedView}
        onSelectView={onSelectView}
        canSeePayroll={payrollPermissions.canView}
        payInsightsAccess={payInsightsAccess}
      />

      <PayrollAccessContext.Provider value={{ payrollPermissions, payInsightsAccess }}>
        <Routes>
          <Route
            path='compensation'
            element={
              <Suspense fallback={<AreaLoader />}>
                <CustomRoute path={`${path}/compensation`} component={Compensation} />
              </Suspense>
            }
          />
          <Route
            path='payroll/*'
            element={
              <Suspense fallback={<AreaLoader />}>
                <CustomRoute path={`${path}/payroll`} component={Payroll} />
              </Suspense>
            }
          />
          <Route
            path='insights/*'
            element={
              <Suspense fallback={<AreaLoader />}>
                <CustomRoute path={`${path}/insights`} component={Insights} />
              </Suspense>
            }
          />

          <Route path='' element={<Navigate to='compensation' replace />} />
        </Routes>
      </PayrollAccessContext.Provider>
      <PayGettingStarted />
    </div>
  );
};

export default Pay;
