import * as yup from 'yup';

import t from 'utility/setTranslation';

import { ENVIRONMENT_PLAN_VALUES } from '../constants';
import { getDomainAvailability, isDomainTested } from '../utility';

export const goLiveSubdomainAndEnvironmentSettingsValidationSchema = () =>
  yup.object().shape({
    subdomain: yup
      .string()
      .test('no-arabic-characters', t('GENERAL.ARABIC_NOT_ALLOWED'), (value) => {
        const arabicRegex = /[\u0600-\u06FF]/;
        return !arabicRegex.test(value);
      })
      .matches(/^[a-z0-9-]+$/, t('GENERAL.INVALID_SUBDOMAIN'))
      .min(1, t('GENERAL.REQUIRED_FIELD'))
      .max(30, t('GENERAL.SUBDOMAIN_TOO_LONG'))
      .required(t('GENERAL.REQUIRED_FIELD'))
      .test('domain-tested', t('GENERAL.DOMAIN_NOT_TESTED'), () => isDomainTested())
      .test('domain-availability', t('GENERA.INVALID_DOMAIN'), () => getDomainAvailability()),
    continueWith: yup.string().oneOf(Object.values(ENVIRONMENT_PLAN_VALUES)).required(t('GENERAL.REQUIRED_FIELD')),
  });
