import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import DayItem from './DayItem';

const WorkScheduleWeek = ({ workSchedule }) => {
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  return (
    <div data-testid='ws-week' className='d-flex align-items-center justify-content-center'>
      {days.map((day) => (
        <DayItem key={day} day={day} active={_.find(workSchedule?.days, ['day', day])} />
      ))}
    </div>
  );
};

WorkScheduleWeek.propTypes = {
  workSchedule: PropTypes.object,
};

WorkScheduleWeek.defaultProps = {
  workSchedule: {},
};

export default WorkScheduleWeek;
