import _ from 'lodash';

const NOTIFICATION_TYPE_FILE_EXPIRY = 'SYSTEM.NOTIFICATION.FILE.FILE.EXPIRED';
const FILE_TYPE_EMPLOYEE = 'EMPLOYEE';
const FILE_TYPE_COMPANY = 'COMPANY';

export default class NotificationMeta {
  #data;

  constructor(data = {}) {
    if (!_.isObject(data)) throw new Error('NotificationMeta must be an object');
    this.data = data;
  }

  static create(data) {
    return new NotificationMeta(data instanceof NotificationMeta ? data.properties : data);
  }

  static createFromNotification(notificationData) {
    const notificationMeta = notificationData?.data ?? {};
    return NotificationMeta.create({ ...notificationMeta, notificationType: notificationData?.notificationType });
  }

  get properties() {
    if (!this.data) return {};
    return _.cloneDeep(this.data);
  }

  get employeeId() {
    return this.properties?.employee;
  }

  get notificationType() {
    return this.properties?.notificationType;
  }

  get fileId() {
    return this.properties?.file;
  }

  get fileType() {
    return this.properties?.fileType;
  }

  get isCompanyFileType() {
    return this.fileType === FILE_TYPE_COMPANY;
  }

  get isEmployeeFileType() {
    return this.fileType === FILE_TYPE_EMPLOYEE;
  }

  get isFileExpiryNotificationType() {
    return this.notificationType === NOTIFICATION_TYPE_FILE_EXPIRY;
  }

  get navigateTo() {
    const { isFileExpiryNotificationType, isEmployeeFileType, employeeId } = this;
    if (!isFileExpiryNotificationType || !isEmployeeFileType || !employeeId) return null;
    return `/profile/${employeeId}/documents/my-documents`;
  }

  get isClickable() {
    const { navigateTo } = this;
    return !!navigateTo;
  }
}
