import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import formatKey from 'utility/formatKey';
import t from 'utility/setTranslation';

import FieldWrapper from './FieldWrapper';
import styles from './NumberInput.module.scss';

const NumberInput = ({
  name,
  value,
  label,
  isRequired,
  isDisabled,
  onChange,
  onBlur,
  step,
  error,
  min,
  max,
  pattern,
  size,
  customClass,
  placeholder,
  readOnly,
  hideLabel,
  direction,
  isCustom,
  sufix,
  prefix,
  iconPosition,
  icon,
}) => {
  let requiredAttr = false;

  if (isRequired) {
    requiredAttr = 'required';
  }

  const onBlurField = () => {
    if (onBlur) {
      onBlur(name);
    }
  };

  return (
    <FieldWrapper name={name} className={cx(styles.inputWrapper, customClass, 'position-relative w-100')}>
      {!hideLabel && (
        <div
          className={cx(styles.label, 'd-flex', direction === 'rtl' ? 'justify-content-end' : 'justify-content-start')}
        >
          {label ? (
            <label className='label' htmlFor={name}>
              {isCustom ? label : formatKey('GENERAL', label)}
              {isRequired && <sup>*</sup>}
            </label>
          ) : null}
        </div>
      )}

      <input
        className={cx(
          `form-control form-control-${size}`,
          error ? 'is-invalid' : 'valid',
          { [styles.inputSufix]: sufix || iconPosition === 'right' },
          { [styles.inputPrefix]: prefix || iconPosition === 'left' },
          { [styles.lg]: sufix.length > 3 }
        )}
        name={name}
        type='number'
        value={value}
        required={requiredAttr}
        onChange={onChange}
        onBlur={onBlurField}
        disabled={isDisabled}
        placeholder={placeholder ? t(placeholder) : ''}
        step={step}
        min={min}
        max={max}
        pattern={pattern}
        readOnly={readOnly}
      />
      {sufix ? (
        <span className={cx(styles.sufix, styles[size], 'label ps-2')}>
          {sufix.length > 5 ? sufix.slice(0, 5) : sufix}
        </span>
      ) : null}
      {prefix ? <span className={cx(styles.prefix, styles[size], 'label pe-2')}>{prefix}</span> : null}
      {icon ? <i className={cx(icon, styles.icon, styles[iconPosition], styles[size])} /> : null}
      <div className='invalid-feedback mt-0'>{error}</div>
    </FieldWrapper>
  );
};

NumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  step: PropTypes.string,
  error: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pattern: PropTypes.string,
  size: PropTypes.string,
  customClass: PropTypes.string,
  placeholder: PropTypes.string,
  sufix: PropTypes.string,
  prefix: PropTypes.string,
  iconPosition: PropTypes.string,
  icon: PropTypes.string,
  readOnly: PropTypes.bool,
  hideLabel: PropTypes.bool,
  direction: PropTypes.string,
  isCustom: PropTypes.bool,
};

NumberInput.defaultProps = {
  value: '',
  label: '',
  isRequired: false,
  isDisabled: false,
  onChange: undefined,
  onBlur: undefined,
  step: undefined,
  error: '',
  min: undefined,
  max: undefined,
  pattern: undefined,
  size: '',
  customClass: '',
  placeholder: '',
  sufix: '',
  prefix: '',
  iconPosition: '',
  icon: '',
  readOnly: false,
  hideLabel: false,
  direction: '',
  isCustom: false,
};

export default NumberInput;
