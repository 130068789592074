import React, { useContext } from 'react';

import Lottie from 'lottie-react';

import khadamatiMainLoader from 'assets/json/main_loader/khadamati_main_loader.json';
import mainLoader from 'assets/json/main_loader/main_loader.json';
import { ThemeContext } from 'themes/ThemeContext';

import styles from './InitLoading.module.scss';

const InitLoading = () => {
  const { themeState } = useContext(ThemeContext);
  const enterpriseName = themeState?.companyName || 'palmhr';

  const loaderMao = {
    palmhr: mainLoader,
    khadamati: khadamatiMainLoader,
  };

  return (
    <div data-testid='init-loader' className={styles.initLoading}>
      <Lottie className={styles.mainLoader} animationData={loaderMao[enterpriseName]} />
    </div>
  );
};

export default InitLoading;
