import React from 'react';

import cx from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import { CSSTransition } from 'react-transition-group';

import IconButton from 'components/buttons/IconButton/IconButton';
import useLanguage from 'shared/useLanguage';
import t from 'utility/setTranslation';

import styles from './TipsContainer.module.scss';

const TipsContainer = ({ isShowTips, renderTipsComponent, hideTips, tipsTitle, tipsWidth, className }) => {
  const language = useLanguage();
  const direction = language === 'ar' ? 'left' : 'right';

  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      in={isShowTips}
      classNames={{
        enter: styles[`slide-in-${direction}-enter`],
        enterActive: styles[`slide-in-${direction}-enter-active`],
        exit: styles[`slide-in-${direction}-exit`],
        exitActive: styles[`slide-in-${direction}-exit-active`],
      }}
      timeout={300}
    >
      <section
        className={cx(
          styles.TipsContainer,
          'bg-gray-50 d-flex flex-column radius-16 mt-4 py-4 box-sizing-border',
          className
        )}
        style={{ width: tipsWidth }}
      >
        <section className='d-flex justify-content-between mb-5 px-4'>
          <h4 className='subtitle font-size-20'>{t(tipsTitle)}</h4>
          <IconButton icon='icon-cross-x' size='md' onClick={hideTips} />
        </section>
        <section className='flex-1'>
          <Scrollbars autoHide>
            <section className='px-4'>{renderTipsComponent()}</section>
          </Scrollbars>
        </section>
      </section>
    </CSSTransition>
  );
};

export default TipsContainer;

TipsContainer.defaultProps = {
  tipsTitle: 'GENERAL.TIPS',
};
