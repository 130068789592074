import React from 'react';

import PHRFile from 'components/PHRUI/File/PHRFile/PHRFile';
import PHRFileDTO from 'services/File/PHRFile';

const AnnouncementFile = ({ announcement, enterpriseName, isPreview }) => {
  const { id, file, isDefault } = announcement;

  if (!file && !isDefault) return null;

  const fileDTO = isDefault ? PHRFileDTO.createDefaultAnnouncement(enterpriseName) : PHRFileDTO.create(file);
  const { isPdf } = fileDTO;

  const isThumbnail = isPreview ? false : isPdf;
  const isDownloadable = isPreview ? true : isPdf;

  return (
    <PHRFile id={`announcement-file-${id}`} file={fileDTO} isThumbnail={isThumbnail} isDownloadable={isDownloadable} />
  );
};

AnnouncementFile.propTypes = {};
AnnouncementFile.defaultProps = {};

export default AnnouncementFile;
