import { Crisp } from 'crisp-sdk-web';

export function initiateCrisp({ id, email, tenant, fullName, role }) {
  Crisp.setTokenId(email);
  Crisp.user.setEmail(email);
  Crisp.user.setNickname(fullName);
  Crisp.user.setCompany(tenant);
  Crisp.session.setData({
    user_id: id,
    tenant,
    email,
    full_name: fullName,
    role,
  });
  Crisp.session.reset();
  Crisp.chat.show();
}

export function configureCrisp() {
  Crisp.configure(process.env.REACT_APP_CRISP_WEBSITE_ID, {
    autoLoad: false,
  });
}

export function destroyCrisp() {
  Crisp.setTokenId();
  Crisp.session.reset();
  Crisp.chat.hide();
}
