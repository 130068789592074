import http from './Api';

class ShiftsApi {
  static getShifts(from, to, params = '') {
    return http.get(`/shift-management/planner/from/${from}/to/${to}${params}`);
  }

  static getShiftStatus(from, to) {
    return http.get(`/shift-management/planner/from/${from}/to/${to}/status`);
  }

  static getShiftsByEmployeeId(from, to, employee, params = '') {
    return http.get(`/shift-management/planner/employee/${employee}/from/${from}/to/${to}${params}`);
  }

  static assignShift(data, params) {
    return http.put(`/shift-management/planner${params}`, data);
  }

  static shiftPublish(from, to, data, params) {
    return http.put(`/shift-management/planner/from/${from}/to/${to}/publish${params}`, data);
  }

  static shiftCopyPrevious(from, to, data, params) {
    return http.put(`/shift-management/planner/from/${from}/to/${to}/copy-previous${params}`, data);
  }

  static shiftResolveOverlapping(from, to, data, params) {
    return http.put(`/shift-management/planner/from/${from}/to/${to}/resolve-overlapping${params}`, data);
  }

  static getFilters() {
    return http.get(`/shift-management/planner/filter-options`);
  }

  static editSingleShift(id, data, params = '') {
    return http.put(`/shift-management/shift/${id}${params}`, data);
  }

  static shiftsActivity(filters) {
    return http.get(`shift-management/planner/activity${filters}`);
  }

  static shiftsAccess() {
    return http.get(`shift-management/access`);
  }

  static downloadeProfileTabel(employee, from, to, type) {
    return http.get(`shift-management/planner/employee/${employee}/from/${from}/to/${to}/download/${type}`, {
      responseType: 'arraybuffer',
    });
  }

  static downloadePlannereTabel(from, to, type, params) {
    return http.get(`shift-management/planner/from/${from}/to/${to}/download/${type}${params}`, {
      responseType: 'arraybuffer',
    });
  }
}

export default ShiftsApi;
