import React from 'react';

import PropTypes from 'prop-types';

import HeaderTitle from 'components/HeaderTitle/HeaderTitle';
import NavBar from 'components/Navigation/NavBar';
import t from 'utility/setTranslation';

const PageHeader = ({ navItems, pageTitle, isBeta }) => (
  <div className='pt-1 pb-3'>
    <div className='d-flex align-items-center mt-4 mb-4 gap-3'>
      <HeaderTitle>{pageTitle}</HeaderTitle>
      {isBeta && (
        <span className='py-1 px-2 font-size-11 font-weight-bold radius-100 background-orange text-white text-uppercase'>
          {t('GENERAL.BETA')}
        </span>
      )}
    </div>

    <NavBar items={navItems} type='link' />
  </div>
);

PageHeader.propTypes = {
  navItems: PropTypes.array.isRequired,
  pageTitle: PropTypes.string.isRequired,
};

export default PageHeader;
