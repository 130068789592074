import jwtDecode from 'jwt-decode';

import { getLocalStorage } from 'auth/AuthContext';

import http, { httpPalmHrAPI } from './Api';

class DashboardApi {
  static getBirthdays(date = '') {
    return http.get(`/dashboard/birthdays?date=${date}`);
  }

  static getAnniversaries(date = '') {
    return http.get(`/dashboard/anniversaries?date=${date}`);
  }

  static getBirthdaysAdvanced(date = '') {
    return http.get(`/dashboard/birthdays/advance?date=${date}`);
  }

  static getAnniversariesAdvanced(date = '') {
    return http.get(`/dashboard/anniversaries/advance?date=${date}`);
  }

  static getMissedStampsTotal() {
    return http.get(`/dashboard/unresolved-records/total`);
  }

  static getMyWorkschedule(date = '') {
    return http.get(`/me/active-workschedule?date=${date}`);
  }

  /* Announcement */
  static getIsEmployeeAnnouncer() {
    return http.get(`/me/announcer`);
  }

  static getAnnouncements(filters = '') {
    return http.get(`/announcements${filters}`);
  }

  static getAnnouncement(announcementId) {
    return http.get(`/announcements/${announcementId}`);
  }

  static addAnnouncement(data) {
    return http.post(`/announcements`, data);
  }

  static editAnnouncement(announcementId, data, params) {
    return http.post(`/announcements/${announcementId}${params}`, data);
  }

  static deleteAnnouncement(announcementId) {
    return http.delete(`/announcements/${announcementId}`);
  }

  static addAnnouncementReaction(announcementId, reaction, data = {}) {
    return http.put(`/announcements/${announcementId}/reaction/${reaction}`, data);
  }

  static getAnnouncementsReactions() {
    return http.get(`/announcement-reactions`);
  }

  static attendanceHistory(employeeId) {
    return http.get(`/team/employee/${employeeId}/time-attendance/history`);
  }

  static getGetStarted() {
    return http.get(`settings/accounts/get-started`);
  }

  static validateCurrentPosition(location, data) {
    return http.post(`/geofencing/location/${location}/validate-position`, data);
  }

  static getEmployeeLocationsByDate(employee, date) {
    return http.get(`employee/${employee}/locations/date/${date}`);
  }

  static getWhoIsIn(data) {
    return http.get(`people/whos-in/count${data}`);
  }

  static getListEmployees(type, filters = '') {
    return http.get(`people/whos-in/${type}${filters}`);
  }

  static getStarted(data) {
    return http.put(`get-started`, data);
  }

  static getActiveShift() {
    return http.get(`me/active-shift`);
  }

  static getShiftEmployee(employee) {
    return http.get(`employee/${employee}/active-shift`);
  }

  /* Events */

  static getCompanyEvents(date = '') {
    return http.get(`company/events/date/${date}`);
  }

  static getEventsAdvanced(date = '') {
    return http.get(`company/events/advance/${date}`);
  }

  static addCustomEvent(data = '') {
    return http.post(`company/events`, data);
  }

  static updateCustomEvent(event = '', id) {
    return http.put(`company/events/${id}`, event);
  }

  static deleteCustomEvent(id) {
    return http.delete(`company/events/${id}`);
  }

  static getFeatureFlagConfigurations() {
    const userToken = getLocalStorage('auth')?.token;
    const decodedToken = jwtDecode(userToken);

    return httpPalmHrAPI.get(`/api/v1/feature-flag-configurations/${decodedToken?.tenant}`);
  }

  static getLanguages() {
    return http.get('languages');
  }

  static sendMessageToAI(messageBody) {
    return httpPalmHrAPI.post(`/api/v1/ai`, messageBody);
  }

  static getShifts(params) {
    return http.get('/dashboard/shifts', { params });
  }
}

export default DashboardApi;
