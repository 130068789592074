import React, { createContext, useReducer } from 'react';

const IsLoggedInContext = createContext();
const isLoggedInLocal = JSON.parse(localStorage.getItem('isLoggedIn'));

const initialState = {
  isLoggedIn: Boolean(isLoggedInLocal?.isLoggedIn),
};

const authReducer = (isLoggedIn, action) => {
  let data = {};
  switch (action.type) {
    case 'set':
      localStorage.setItem('isLoggedIn', JSON.stringify(action.payload));
      data = action.payload;
      break;
    case 'get':
      data = { isLoggedIn };
      break;
    case 'del':
      localStorage.removeItem('isLoggedIn');
      break;
    default:
      break;
  }

  return data;
};

const IsLoggedInContextProvider = (props) => {
  const [logInState, dispatch] = useReducer(authReducer, initialState);
  const value = { logInState, dispatch };

  return <IsLoggedInContext.Provider value={value}>{props.children}</IsLoggedInContext.Provider>;
};

export { IsLoggedInContext, IsLoggedInContextProvider };
