import React, { useContext, useState, useMemo } from 'react';

import cx from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';

import IconButton from 'components/buttons/IconButton/IconButton';
import { clientTypes } from 'constants/clientTypes';

import QuickstartChecklistList from './components/QuickstartChecklistList/QuickstartChecklistList';
import QuickstartWelcomeMessage from './components/QuickstartWelcomeMessage/QuickstartWelcomeMessage';
import ResourceCenterRenderList from './components/ResourceCenterRenderList/ResourceCenterRenderList';
import styles from './ResourceCenter.module.scss';
import { getQuickstartChecklistItems, getResourceCenterItems } from './utils';
import { AuthContext } from '../../../auth/AuthContext';
import useLanguage from '../../../shared/useLanguage';
import { useVisibility } from '../../../shared/useVisibility';
import ECStore from '../../../stores/ECStore';
import { useAccountSubscriptionData, useGetPlgTasks } from '../../hooks/useAccount';

const ResourceCenter = () => {
  const { isOpen, open, close, toggle } = useVisibility();
  const [isQuickstartChecklistVisible, setIsQuickstartChecklistVisible] = useState(false);

  const ecStore = useContext(ECStore);
  const { authState } = useContext(AuthContext);

  const activeLang = useLanguage();

  const { data, isLoading } = useGetPlgTasks();
  const { data: accountSubscriptionData } = useAccountSubscriptionData();

  const accountType = accountSubscriptionData?.data.account.clientType;

  const isHR = authState.user.roles.includes('ROLE_HR');
  const isAdmin = authState.user.roles.includes('ROLE_ADMIN');

  const handleCloseMenu = () => {
    close();
    setIsQuickstartChecklistVisible(false);
  };

  const onOpenTask = (task) => {
    ecStore.setEcState(true, '');
    ecStore.setCurrentView(`tasks`);
    ecStore.selectTask(task.id);
    close();
  };

  const resourceCenterItems = getResourceCenterItems(setIsQuickstartChecklistVisible, isAdmin, isHR, accountType);

  const quickstartChecklistItems = useMemo(
    () => getQuickstartChecklistItems(data?.data.items || [], onOpenTask),
    [data, onOpenTask]
  );

  return (
    <section className={activeLang === 'en' ? styles.resourceCenter : styles.resourceCenterAR}>
      {isOpen && <div className={styles.overlay} onClick={close} />}
      <div className='d-flex flex-column align-items-end justify-content-end' dir='rtl'>
        <CSSTransition
          mountOnEnter
          unmountOnExit
          in={isOpen}
          timeout={100}
          classNames={{
            enter: styles.enter,
            enterActive: styles.enterActive,
            exit: styles.exit,
            exitActive: styles.exitActive,
          }}
        >
          <div className={cx('bg-white px-5 rounded-3', styles.content)}>
            {isQuickstartChecklistVisible ? (
              <QuickstartChecklistList
                items={quickstartChecklistItems}
                onBack={() => setIsQuickstartChecklistVisible(false)}
                isLoading={isLoading}
                activeLang={activeLang}
              />
            ) : (
              <ResourceCenterRenderList items={resourceCenterItems} activeLang={activeLang} />
            )}
            <IconButton
              icon='icon-cross-x'
              className={cx('bg-white', activeLang === 'en' ? styles.closeButton : styles.closeButtonAR)}
              color='gray-500'
              size='sm'
              onClick={handleCloseMenu}
            />
          </div>
        </CSSTransition>
        <IconButton
          icon='icon-custom-7'
          className={cx('bg-primary', styles.iconButton)}
          color='white'
          size='md'
          onClick={toggle}
          isDisabled={isLoading}
        />
      </div>
      {(isHR || isAdmin) && accountType === clientTypes.LIVE_INSTANCE && (
        <QuickstartWelcomeMessage
          openResourceCenter={open}
          setShowQuickstartChecklist={setIsQuickstartChecklistVisible}
        />
      )}
    </section>
  );
};

export default ResourceCenter;
