import React from 'react';

import '@palmhr/palmhr-ui-lib/dist/style.css';
import moment from 'moment';
import { createRoot } from 'react-dom/client';

import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

moment.updateLocale('en', {
  relativeTime: {
    s: 'a few sec',
    ss: '%d sec',
    m: 'a min',
    mm: '%d min',
  },
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js')
    .then(() => {})
    .catch(() => {});
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
