import _ from 'lodash';

export const eosRoute = '/company-settings/pay/end-of-service';
export const eosTypeRoute = `${eosRoute}/types`;
export const eosPolicyRoute = `${eosRoute}/policies`;
export const eosPolicyByIdRoute = `${eosRoute}/policies/:policyId`;
export const eosPrivilegedRoles = ['admin'];

export const eosRoutes = {
  [eosRoute]: eosPrivilegedRoles,
  [eosTypeRoute]: eosPrivilegedRoles,
  [eosPolicyRoute]: eosPrivilegedRoles,
  [eosPolicyByIdRoute]: eosPrivilegedRoles,
};

const getRouteUrl = (route) => (_.isObject(route) ? route?.pathname : route);
export const isTypeRoute = (route) => getRouteUrl(route) === eosTypeRoute;
export const isPolicyRoute = (route) => getRouteUrl(route) === eosPolicyRoute;
