import React, { useContext, useState, useEffect } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import DashboardApi from 'api/DashboardApi';
import ProfileTimesheetApi from 'api/ProfileTimesheetApi';
import { AuthContext } from 'auth/AuthContext';
import AlertMessage from 'components/AlertMessage';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import Modal from 'components/Modal';
import Tooltip from 'components/tooltip/Tooltip';
import AttendanceDetails from 'modules/Dashboard/components/WhoIsIn/AttendanceDetails';
import DashboardContext from 'modules/Dashboard/DashboardContext';
import useResolveHandler from 'shared/useResolveHandler';
import t from 'utility/setTranslation';

import styles from './CardTimeTracking.module.scss';
import GeofencingWarningModal from './GeofencingWarningModal';
import SelectLocationModal from './SelectLocationModal';
import SkeletonCard from './SkeletonCard';

const CardTimeTracking = ({ ramadanWS, loadingWS }) => {
  const { authState } = useContext(AuthContext);
  const { shouldUpdateAttendance, setShouldUpdateAttendance } = useContext(DashboardContext);

  const [clock, setClock] = useState(moment().format('H:mm'));

  const [employeeLocations, setEmployeeLocations] = useState([]);

  const [trackingStatus, setTrackingStatus] = useState(null);
  const [waitingLocation, setWaitingLocation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertBoxMsg, setAlertBoxMsg] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [currentCoordinates, setCurrentCoordinates] = useState({});
  const [scheduled, setScheduled] = useState(null);
  const { responseStatus, setStatus } = useResolveHandler();

  const TimeTrackingDisabledTooltip = ({ children }) =>
    trackingStatus?.timeTrackingDisabled ? (
      <Tooltip label={t('TIME_TRACKING.SETTINGS.PREVENT_TIME_TRACKING_DURING_VACATION')} position='top'>
        {children}
      </Tooltip>
    ) : (
      children
    );

  const getShifts = () => {
    setLoading(true);
    DashboardApi.getActiveShift()
      .then((res) => {
        setLoading(false);
        setScheduled(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateClock = () => {
    setClock(moment().format('H:mm'));
  };

  const formatTitle = (data) => {
    let retVal = '';
    if (!scheduled.shifts.length > 0) {
      if (data?.isFlexible) {
        retVal = t('GENERAL.FLEXIBLE');
      } else {
        retVal = t('GENERAL.FIXED');
      }
    }
    return retVal;
  };

  const formatTime = (time) => {
    let timeReturnVal = time;
    const timeArr = timeReturnVal.split(':');
    if (timeArr.length > 2) {
      timeReturnVal = time.slice(0, -3);
    }
    return timeReturnVal;
  };

  const renderTodayShift = () => {
    const items = [];

    if (scheduled?.shifts?.length > 0) {
      scheduled.shifts.map((e) => {
        items.push(
          <p className={styles.hours} key={_.uniqueId()}>
            {`${(e?.startTime && formatTime(e?.startTime)) || '--'} : ${
              (e?.endTime && formatTime(e?.endTime)) || '--'
            }`}
          </p>
        );
        return e;
      });
    }
    if (scheduled?.overnightShift) {
      const textMessage = t(`SHIFT.ATTENDANCE_CARD`, {
        period: `${moment().subtract(1, 'day').format('MMM, DD. YYYY')}`,
        date: `${formatTime(scheduled?.overnightShift?.startTime)}`,
        period2: `${moment().format('MMM, DD. YYYY')}`,
        date2: `${formatTime(scheduled?.overnightShift?.endTime)}`,
      });
      items.unshift(
        <div className='d-flex'>
          <p className={cx(styles.hours, 'd-inline-block me-2a')} key={_.uniqueId()}>
            {`${formatTime(scheduled.overnightShift?.startTime) || '--'} : ${
              (scheduled.overnightShift?.endTime && formatTime(scheduled.overnightShift?.endTime)) || '--'
            }`}
          </p>
          <span className={cx(styles.tooltip, 'fs-11')}>
            <Tooltip label={textMessage} useTranslate position='left' className='text-start' size='md'>
              <i className='icon-info text-gray fs-11' />
            </Tooltip>
          </span>
        </div>
      );
    }
    if (scheduled?.workSchedule && !scheduled?.workSchedule?.isFlexible) {
      items.push(
        <p className={styles.hours} key={_.uniqueId()}>
          <span>{formatTitle(scheduled?.workSchedule)}</span>
          {` ${(scheduled?.workSchedule?.startTime && formatTime(scheduled?.workSchedule?.startTime)) || '--'} :
         
          ${(scheduled?.workSchedule?.endTime && formatTime(scheduled?.workSchedule?.endTime)) || '--'}`}
        </p>
      );
    }
    if (scheduled?.workSchedule && scheduled?.workSchedule?.isFlexible) {
      items.push(
        <p className={styles.hours} key={_.uniqueId()}>
          <span>{formatTitle(scheduled.workSchedule)}-</span>
          {`${scheduled?.workSchedule?.endTime && formatTime(scheduled?.workSchedule?.endTime)}h` || '--'}
        </p>
      );
    }
    if (scheduled?.workSchedule === null && scheduled?.shifts?.length === 0) {
      items.push(
        <p className={styles.hours} key={_.uniqueId()}>
          {t('GENERAL.DAY_OFF')}
        </p>
      );
    }

    return items;
  };

  /** *** CHECK IN / CHECK OUT ***** */

  const errorHandler = (err) => {
    setWaitingLocation(false);
    let message = 'GENERAL.ERROR_MESSAGE';
    switch (err.code) {
      case err.PERMISSION_DENIED:
        message = 'GEOLOCATION.PERMISSION_REQUIRED';
        break;
      case err.POSITION_UNAVAILABLE:
      case err.TIMEOUT:
        message = 'GEOLOCATION.CAN_NOT_GET_POSSITION';
        break;
      default:
        break;
    }
    setAlertBoxMsg(message);
  };

  const getClockInStatus = () => {
    ProfileTimesheetApi.getClockInStatus(authState.user.identification)
      .then((res) => {
        setTrackingStatus(res.data);
        setShouldUpdateAttendance(false);
      })
      .catch(() => {});
  };

  const onClockInClockOut = (coordinates, location) => {
    let data = {
      employee: authState.user.identification,
      location: location || null,
    };
    if (!_.isEmpty(coordinates)) {
      data = { ...coordinates, ...data };
    }
    setLoading(true);
    if (trackingStatus?.status === 'out') {
      ProfileTimesheetApi.clockIn(data)
        .then((res) => {
          getClockInStatus();
          setTrackingStatus({ ...trackingStatus, status: res.data[0].recordType });
          setLoading(false);
          setStatus(true, () => {
            setOpenLocationModal(false);
          });
        })
        .catch((error) => {
          setLoading(false);
          setStatus(false, () => {}, error?.response?.data?.message);
        });
    } else {
      ProfileTimesheetApi.clockOut(data)
        .then((res) => {
          getClockInStatus();
          setTrackingStatus({ ...trackingStatus, status: res.data[0].recordType });
          setLoading(false);
          setStatus(true, () => {
            setOpenLocationModal(false);
          });
        })
        .catch((error) => {
          setLoading(false);
          setStatus(false, () => {}, error?.response?.data?.message);
        });
    }
  };

  const validateCurrentPosition = (location, coordinates) => {
    DashboardApi.validateCurrentPosition(location.id, coordinates)
      .then((result) => {
        if (result.data.isValid) {
          onClockInClockOut(coordinates, location.id);
        } else {
          setOpenWarningModal(true);
          setCurrentCoordinates(coordinates);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getEmployeeLocationsByDate = () => {
    const date = moment().format('YYYY-MM-DD');
    DashboardApi.getEmployeeLocationsByDate(authState.user.identification, date)
      .then((result) => {
        setEmployeeLocations(result.data);
      })
      .catch(() => {});
  };

  const getCurrentPosition = (onSuccess, onError, options) => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const coordinates = {
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
          accuracy: pos.coords.accuracy,
        };
        onSuccess(coordinates);
      },
      onError,
      options
    );
  };

  const clockInOutWithGeofencingActivated = (location) => {
    if (location?.geofencingMode) {
      setWaitingLocation(true);
      getCurrentPosition(
        (coordinates) => {
          setWaitingLocation(false);
          setLoading(true);
          validateCurrentPosition(location, coordinates);
        },
        errorHandler,
        { enableHighAccuracy: true }
      );
    } else {
      onClockInClockOut(null, location.id);
    }
  };

  const onChangeStatus = () => {
    if (employeeLocations?.length > 1) {
      setOpenLocationModal(true);
    } else if (employeeLocations?.length === 1) {
      clockInOutWithGeofencingActivated(employeeLocations[0]);
    } else {
      onClockInClockOut(null);
    }
  };

  const onCloseToast = () => {
    setAlertBoxMsg('');
  };

  const onGeofencingWarningClickHandler = () => {
    onClockInClockOut(currentCoordinates, employeeLocations[0]?.id);
    setOpenWarningModal(false);

    setTimeout(() => {
      setCurrentCoordinates(null);
    }, 400);
  };

  useEffect(() => {
    if (authState.currentTimeTracking === 'APP_CHECKIN') {
      getClockInStatus();
    }
  }, [authState.currentTimeTracking]);

  useEffect(() => {
    // this is triggered from who is in modal if clock in/out is manually added
    if (shouldUpdateAttendance) {
      getClockInStatus();
    }
  }, [shouldUpdateAttendance]);

  /** *** CHECK IN / CHECK OUT END ***** */

  useEffect(() => {
    const timeout = setTimeout(() => {
      document.activeElement.blur();
    }, 300);
    return () => clearTimeout(timeout);
  }, [openModal]);

  useEffect(() => {
    getShifts();
    getEmployeeLocationsByDate();
    const timer = setInterval(() => updateClock(), 6000);
    return () => {
      clearInterval(timer);
      setClock(undefined);
      setScheduled(null);
      setTrackingStatus(null);
    };
  }, []);

  if (loadingWS) {
    return (
      <div className={cx(styles.cardWrapper, 'position-relative mb-3')}>
        <SkeletonCard />
      </div>
    );
  }

  return (
    <>
      {alertBoxMsg && <AlertMessage text={t(alertBoxMsg)} type='error' callback={onCloseToast} />}

      <div className={cx(styles.cardWrapper, 'position-relative mb-3')}>
        <div className={cx(styles.cardHeader, 'd-flex justify-content-between align-items-center')}>
          <div className={styles.title}>{t('GENERAL.ATTENDANCE')}</div>
        </div>

        <div className='d-flex'>
          <div className={cx(styles.clock, 'text-center')}>{clock}</div>

          <div className={cx(styles.scheduleBox)}>
            <p className={cx('label p-0 ms-2')}>
              {ramadanWS && <i className='icon-moon text-purple me-2 font-size-16' />}
              {scheduled?.shifts.length > 0 ? t('GENERAL.SHIFTS') : t('GENERAL.SCHEDULE')}
            </p>
            <div className='ms-2'>{renderTodayShift()}</div>
          </div>
        </div>

        {trackingStatus && (
          <div className={cx(styles.cardContent, 'row row-small mt-4')}>
            <div className='col-lg-6 col-md-12 d-flex justify-content-center mb-md-2 mb-lg-0'>
              <RoundedButton
                className={cx(styles.clockInBtn)}
                type='button'
                text='Details'
                color='gray'
                btnStyle='outlined'
                size='sm'
                onClick={() => {
                  setOpenModal(true);
                }}
                minWidth='auto'
              />
            </div>
            <div className='col-lg-6 col-md-12 d-flex justify-content-center'>
              <TimeTrackingDisabledTooltip>
                <RoundedButton
                  className={cx(styles.clockInBtn)}
                  type='button'
                  text={trackingStatus?.status === 'out' ? 'Check In' : 'Check Out'}
                  color={trackingStatus?.status === 'out' ? 'green' : 'warning'}
                  btnStyle='contained'
                  size='sm'
                  onClick={onChangeStatus}
                  minWidth='auto'
                  isDisabled={waitingLocation || trackingStatus?.timeTrackingDisabled}
                  loading={loading || waitingLocation}
                />
              </TimeTrackingDisabledTooltip>
            </div>
          </div>
        )}

        <Modal
          isOpen={openModal}
          onRequestClose={() => {
            setOpenModal(false);
          }}
          styles={{ width: '480px', padding: '0', overflow: 'hidden' }}
          showHeader={false}
        >
          <AttendanceDetails
            selected={authState.user.identification}
            onClose={() => {
              setOpenModal(false);
            }}
            trackingStatus={trackingStatus?.status || 'out'}
            updateCheckStatus={onChangeStatus}
            loadingCheck={loading}
            waitingLocation={waitingLocation}
            timeTrackingDisabled={trackingStatus?.timeTrackingDisabled}
            onGoBack={() => setOpenModal(false)}
          />
        </Modal>

        <Modal
          showHeader={false}
          isOpen={openWarningModal}
          styles={{ width: '480px', padding: '0', overflow: 'hidden' }}
          onRequestClose={() => setOpenWarningModal(false)}
        >
          <GeofencingWarningModal
            onClose={() => setOpenWarningModal(false)}
            clickHandler={onGeofencingWarningClickHandler}
            coordinates={currentCoordinates}
            location={employeeLocations.length ? employeeLocations[0] : {}}
          />
        </Modal>
        <Modal
          title={t(`GENERAL.CHOOSE_LOCATION`)}
          isOpen={openLocationModal}
          styles={{ width: '480px', padding: '0', overflow: 'hidden' }}
          onRequestClose={() => setOpenLocationModal(false)}
        >
          <SelectLocationModal
            getCurrentPosition={getCurrentPosition}
            errorHandler={errorHandler}
            waitingLocation={waitingLocation}
            setWaitingLocation={setWaitingLocation}
            currentCoordinates={currentCoordinates}
            locations={employeeLocations}
            onClockInClockOut={onClockInClockOut}
            statusType={trackingStatus?.status}
            sending={loading}
            responseStatus={responseStatus}
            onClose={() => setOpenLocationModal(false)}
          />
        </Modal>
      </div>
    </>
  );
};

CardTimeTracking.propTypes = {
  ramadanWS: PropTypes.object,
};

CardTimeTracking.defaultProps = {
  ramadanWS: null,
};

export default CardTimeTracking;
