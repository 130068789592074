import React, { Component } from 'react';

import cx from 'classnames';

import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import t from 'utility/setTranslation';

import styles from './errorBoundry.module.scss';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  handleReset = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className={cx(styles.container)}>
          <h1 className={cx(styles.title)}>{t('GOSI.SOMETHING_WENT_WRONG')}</h1>
          <p className={cx(styles.message)}>{t('MASDR.CMN_ERR_1000')}</p>
          <div className='mt-3'>
            <RoundedButton onClick={this.handleReset} text={'back'} />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
