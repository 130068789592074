import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import { matchPath, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import CSSTransition from 'react-transition-group/CSSTransition';

import { AuthContext } from 'auth/AuthContext';
import Modal from 'components/Modal';
import AreaLoader from 'components/Spinner/AreaLoader';

import BulkActionToolbar from './components/BulkActionToolbar';
import Header from './Header';
import styles from './Tasks.module.scss';
import CompanySettingsTasksApi from '../../api/CompanySettingsTasksApi';
import TasksApi from '../../api/TasksApi';
import ConfirmationBox from '../../components/ConfirmationBox';
import useDebounce from '../../shared/useDebounce';
import useResolveHandler from '../../shared/useResolveHandler';
import queryParamsUtil from '../../utility/queryParamsUtil';

const TasksKind = lazy(() => import('./TasksKind'));

let isInit = true;
const defaultPag = queryParamsUtil.defaultPagination();

const Tasks = () => {
  const { authState } = useContext(AuthContext);
  const { checkPrivileges } = authState;
  const location = useLocation();
  const path = '/tasks';
  const matchType = matchPath(
    {
      path: '/tasks/:taskKind/:taskType',
      exact: true,
      strict: false,
    },
    location.pathname
  );
  const taskKind = matchType?.params?.taskKind || 'my_tasks';
  const taskType = matchType?.params?.taskType || 'all';
  const customRoutePath = path.replace(`${path}`, `/tasks/${taskKind}`);
  const [isAsc, setIsAsc] = useState(false);
  const [confirmOpened, setConfirmOpened] = useState(false);
  const [saving, setSaving] = useState(false);
  const { responseStatus, setStatus } = useResolveHandler();
  const [bulkActionType, setBulkActionType] = useState({ type: '', subType: '' });
  const [loadingData, setLoadingData] = useState(false);
  const [tasks, setTasks] = useState({ items: [], pagination: {} });
  const [taskPriorities, setTaskPriorities] = useState({ items: [], pagination: {} });
  const [taskStatuses, setTasksStatuses] = useState({ items: [], pagination: {} });
  const [activeFilter, setActiveFilter] = useState({
    status: authState.user.language === 'ar' ? 'جميع العناصر' : 'All',
    id: null,
  });
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 350);
  const [checked, setChecked] = useState([]);
  const [allTasksChecked, setAllTasksChecked] = useState(false);
  const [exportFilters, setExportFilters] = useState('');

  const textMap = {
    delete: 'TASKS.DELETE_TASKS_TEXT',
    priority: 'GENERAL.DELETE_CONFIRM_MSG_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_?',
    status: 'GENERAL.DELETE_CONFIRM_MSG_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_?',
  };

  const titleMap = {
    delete: 'TASKS.DELETE_TASKS',
    priority: 'GENERAL.CHANGE_PRIORITY',
    status: 'GENERAL.CHANGE_STATUS',
  };

  const typeMap = {
    delete: 'danger',
    priority: 'confirm',
    status: 'confirm',
  };

  const prepareFilters = (filterStatus = null, sortParams = '', pagination = null, isExport = false) => {
    const myTasks = taskKind === 'my_tasks' && authState.user.roles.includes('ROLE_ADMIN') ? 1 : 0;
    let urlParams = `?${sortParams ?? 'sort=t.dueDate&direction=desc'}&myTasks=${myTasks}&search=${searchValue}`;

    if (isExport === false) {
      const perPage = pagination?.perPage || defaultPag?.perPage || 10;
      const currentPage = pagination?.currentPage || defaultPag?.currentPage || 1;
      urlParams += `&perPage=${perPage}&currentPage=${currentPage}`;
    }

    const filters = [];

    if (filterStatus && !!filterStatus.id) {
      filters.push({
        field: 'p.id',
        type: 'eq',
        value: filterStatus.id,
      });
    }

    if (taskType !== 'all') {
      filters.push({
        field: 's.type',
        type: 'eq',
        value: taskType?.toUpperCase(),
      });
    }

    if (filters.length === 0) return urlParams;

    return `${urlParams}&filters=${encodeURIComponent(JSON.stringify(filters))}`;
  };

  const getTasks = (filterStatus = null, sortParams = '', pagination) => {
    setLoadingData(true);
    setChecked([]);
    setAllTasksChecked(false);
    setExportFilters(prepareFilters(filterStatus, sortParams, pagination, true));

    TasksApi.getTasks(prepareFilters(filterStatus, sortParams, pagination))
      .then((res) => {
        setTasks(res.data);

        setLoadingData(false);
        isInit = false;
      })
      .catch(() => {
        setLoadingData(false);
        isInit = false;
      });
  };

  const getTasksPriorities = () => {
    CompanySettingsTasksApi.getTasksPriorities(`?perPage=100`).then((res) => {
      setTaskPriorities(res.data);
    });
  };

  const getTasksStatuses = () => {
    CompanySettingsTasksApi.getTasksStatuses(`?perPage=100`).then((res) => {
      setTasksStatuses(res.data);
    });
  };

  const sortTasks = (option) => {
    setIsAsc((prevState) => {
      let sort = `direction=${isAsc ? 'asc' : 'desc'}`;

      if (option === 'dueDate') {
        sort += '&sort=t.dueDate';
      }

      getTasks(activeFilter, sort);

      return !prevState;
    });
  };

  const checkAll = (val) => {
    let state = [...checked];
    const employeesState = { ...tasks };
    if (val) {
      setAllTasksChecked(true);
      employeesState.items.forEach((el) => {
        const newObj = { ...el };
        if (!state.includes(el.id)) {
          state.push(el.id);
        }
        return newObj;
      });
    } else {
      state = [];
      setAllTasksChecked(false);
      employeesState.items = employeesState.items.map((el) => {
        const newObj = { ...el };
        return newObj;
      });
    }
    setChecked(state);
    setTasks(employeesState);
  };

  const onCheck = (ch, el, val) => {
    const state = [...checked];
    const newValue = parseInt(val, 10);
    if (ch) {
      state.push(newValue);
    } else {
      const elIndex = checked.indexOf(newValue);
      state.splice(elIndex, 1);
    }
    setAllTasksChecked(false);
    setChecked(state);
  };

  const clearSelected = () => {
    setAllTasksChecked(false);
    setChecked([]);
  };

  const onBulkAction = () => {
    setSaving(true);
    const sentData = {
      tasks: [...checked],
    };
    switch (bulkActionType.type) {
      case 'delete':
        TasksApi.deleteMultipleTasks(sentData)
          .then(() => {
            setSaving(false);
            getTasks(activeFilter, '');

            setStatus(true, () => {
              setConfirmOpened(false);
            });
          })
          .catch((error) => {
            setSaving(false);
            setStatus(false, () => {}, error?.response?.data?.message);
          });
        break;
      case 'priority':
        sentData.priority = bulkActionType.subType;
        TasksApi.changeMultipleTasksPriority(sentData)
          .then(() => {
            setSaving(false);
            getTasks(activeFilter, '');

            setStatus(true, () => {
              setConfirmOpened(false);
            });
          })
          .catch((error) => {
            setSaving(false);
            setStatus(false, () => {}, error?.response?.data?.message);
          });
        break;
      case 'status':
        sentData.status = bulkActionType.subType;
        TasksApi.changeMultipleTasksStatus(sentData)
          .then(() => {
            setSaving(false);
            getTasks(activeFilter, '');

            setStatus(true, () => {
              setConfirmOpened(false);
            });
          })
          .catch((error) => {
            setSaving(false);
            setStatus(false, () => {}, error?.response?.data?.message);
          });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!isInit && taskKind && taskType) {
      getTasks(activeFilter, 'sort=t.dueDate&direction=desc');
    }
  }, [taskKind, taskType, debouncedSearchTerm]);

  useEffect(() => {
    if (taskType && taskKind) {
      getTasks(activeFilter, 'sort=t.dueDate&direction=desc');
      getTasksPriorities();
      getTasksStatuses();
    }

    const body = document.querySelector('body');
    body.classList.add('no-scroll');
    return () => {
      body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <>
      <div className={cx('pageContainer page-top-positioning position-relative')}>
        <Header active={taskKind} />

        <Routes>
          <Route
            path=':taskKind/*'
            element={
              <Suspense fallback={<AreaLoader />}>
                {checkPrivileges(customRoutePath) ? (
                  <TasksKind
                    exportFilters={exportFilters}
                    tasks={tasks}
                    getTasks={getTasks}
                    sortTasks={sortTasks}
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    loadingData={loadingData}
                    taskPriorities={taskPriorities}
                    onCheck={onCheck}
                    onCheckAll={checkAll}
                    checked={checked}
                    isAllChecked={allTasksChecked}
                  />
                ) : (
                  <Navigate to='/403' />
                )}
              </Suspense>
            }
          />

          <Route path='' element={<Navigate to='my_tasks/all' replace />} />
        </Routes>

        <div className={cx('d-flex justify-content-center', styles.bulkActionToolbarWrapper)}>
          <CSSTransition
            mountOnEnter
            unmountOnExit
            in={checked.length > 0}
            timeout={500}
            classNames={{
              enter: styles.enterTool,
              enterActive: styles.enterActiveTool,
              exit: styles.exitTool,
              exitActive: styles.exitActiveTool,
            }}
          >
            <BulkActionToolbar
              onClose={clearSelected}
              checkedNumber={checked.length || 0}
              onSelectAction={(type, subType) => {
                setConfirmOpened(true);
                setBulkActionType({
                  type,
                  subType,
                });
              }}
              taskPriorities={taskPriorities}
              taskStatuses={taskStatuses}
            />
          </CSSTransition>
        </div>
      </div>

      <Modal
        isOpen={confirmOpened}
        showHeader={false}
        styles={{ width: '720px' }}
        onRequestClose={() => {
          setConfirmOpened(false);
        }}
      >
        <ConfirmationBox
          close={() => {
            setConfirmOpened(false);
          }}
          action={onBulkAction}
          text={textMap[bulkActionType.type]}
          title={titleMap[bulkActionType.type]}
          type={typeMap[bulkActionType.type]}
          sending={saving}
          responseStatus={responseStatus}
        />
      </Modal>
    </>
  );
};

export default Tasks;
