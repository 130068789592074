import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import t from 'utility/setTranslation';

import styles from './ShowMore.module.scss';

const ShowMore = ({ showMoreHandler, showMoreBtn }) => (
  <div className={cx(styles.showMoreBtn, 'text-primary fw-bold pt-3')}>
    <span onClick={showMoreHandler}>
      <i className={showMoreBtn ? 'icon-arrow-top' : 'icon-arrow-bottom'} />
      <span className='ms-2'>{showMoreBtn ? t('GENERAL.SHOW_LESS') : t('GENERAL.SHOW_MORE')}</span>
    </span>
  </div>
);

ShowMore.propTypes = {
  showMoreHandler: PropTypes.func.isRequired,
  showMoreBtn: PropTypes.bool.isRequired,
};

export default ShowMore;
