import React from 'react';

import { useNavigate } from 'react-router-dom';

import renewalState from 'assets/img/Confrim_Renewal.svg';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import t from 'utility/setTranslation';

const RenewalState = ({ closeModal, onCreateTask }) => {
  const navigate = useNavigate();

  const handleGoToBilling = () => {
    navigate('/company-settings/billing');
    closeModal();
  };
  const handleCreateTask = () => {
    closeModal();
    onCreateTask(true);
  };

  return (
    <article className='px-6 py-4 d-flex flex-column align-items-center'>
      <img src={renewalState} width={220} alt='renwal needed' className='mt-4' />
      <section className='mb-5 mt-4'>
        <h5 className='text-center font-weight-bold text-main font-size-20 mb-3'>{t('GENERAL.CONFIRM_RENEWAL')}</h5>
        <p className='text-center text-secondary font-size-16 multiline_2 text-secondary'>
          {t('GENERAL.RENEWAL_CONFIRMATION_MESSAGE')}
        </p>
      </section>

      <footer className='d-flex gap-3 justify-content-center'>
        <RoundedButton
          onClick={handleGoToBilling}
          type='button'
          text='go to billing'
          btnStyle='outlined'
          color='primary'
          size='md'
        />
        <RoundedButton
          type='button'
          btnStyle='contained'
          color='primary'
          onClick={handleCreateTask}
          text='create task'
          size='md'
        />
      </footer>
    </article>
  );
};

export default RenewalState;
