import React from 'react';

import cancelState from 'assets/img/Subscription_Cancelled.svg';
import t from 'utility/setTranslation';

const CONTACT_EMAIL = 'happiness@palmhr.net';

const CanceledState = () => (
  <article className='px-6 py-4 d-flex flex-column align-items-center'>
    <img src={cancelState} alt='subscription cancelled' width={200} className='my-4' />
    <section className='d-flex flex-column align-items-center mb-5'>
      <h5 className='text-center font-weight-bold text-main font-size-20 mb-3'>
        {t('GENERAL.SUBSCRIPTION_CANCELLED')}
      </h5>
      <p className='text-center text-secondary font-size-16'>
        {t('GENERAL.CANCELLED_MESSAGE', { endDate: 'DEC 16, 2023' })}
      </p>
      <a className='font-weight-bold' href={`mailto:${CONTACT_EMAIL}`}>
        {CONTACT_EMAIL}
      </a>
    </section>
  </article>
);

export default CanceledState;
