import * as yup from 'yup';

import t from 'utility/setTranslation';

import { minAllowedNumberOfUsers, PAYMENT_SCHEDULE_OPTIONS } from '../constants';

export const goLiveSubscriptionSettingsValidationSchema = () =>
  yup.object().shape({
    subscription: yup.object().shape({
      periodUnit: yup
        .string()
        .oneOf(PAYMENT_SCHEDULE_OPTIONS.map((option) => option.id))
        .required(t('GENERAL.REQUIRED_FIELD')),
      numberOfUsers: yup
        .number()
        .min(minAllowedNumberOfUsers, t('NAVBAR.NUMBER_OF_USERS_LENGTH'))
        .required(t('NAVBAR.NUMBER_OF_USERS_LENGTH')),
    }),
    addOns: yup.array().optional(),
  });
