import React from 'react';

import { IsLoggedInContextProvider } from 'auth/IsLoggedInContext';
import AppRouter from 'topNavigation/Router/Router';

const App = () => (
  <IsLoggedInContextProvider>
    <AppRouter />
  </IsLoggedInContextProvider>
);
export default App;
