import React, { useState, useEffect } from 'react';

import InputsSectionContainer from 'components/InputsSectionContainer/InputsSectionContainer';
import LoadingList from 'components/LoadingList/LoadingList';
import t from 'utility/setTranslation';

import { useGetAccountPlgPlansAndSubscriptions } from '../../../../../../hooks/useAccount';
import {
  getPlgPlans,
  getPlgServicesSubscriptions,
  getPlgProductsSubscriptions,
  getSubscriptionValues,
} from '../../utility';
import GoLiveSubscriptionAddons from '../GoLiveSubscriptionAddons/GoLiveSubscriptionAddons';
import GoLiveSubscriptionDetails from '../GoLiveSubscriptionDetails/GoLiveSubscriptionDetails';

const GoLiveSubscriptionSettings = (formik) => {
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const { mutate, data, isLoading } = useGetAccountPlgPlansAndSubscriptions();

  const handleSwitch = (checked) => {
    setIsSwitchOn(checked);
  };

  const plgPricingPlan = data?.data?.items ? getPlgPlans(data.data.items) : {};
  const plgServicesSubscriptions = data?.data?.items ? getPlgServicesSubscriptions(data.data.items) : [];
  const plgProductsSubscriptions = data?.data?.items ? getPlgProductsSubscriptions(data.data.items) : [];

  useEffect(() => {
    mutate();
  }, []);

  useEffect(() => {
    getSubscriptionValues(formik, plgPricingPlan);
  }, [plgPricingPlan]);

  useEffect(() => {
    if (formik.values.subscription.addonSubscriptions.length > 0) {
      setIsSwitchOn(true);
    }
  }, [formik.values.subscription]);

  if (isLoading) {
    return (
      <LoadingList
        rowsCount={5}
        spaceBetween={0}
        rowHeight={140}
        className='justify-content-center gap-5 flex-1 mt-4'
      />
    );
  }

  return (
    <section className='d-flex flex-column gap-4 mt-4 pb-4'>
      <InputsSectionContainer
        title={t('NAVBAR.SUBSCRIPTION_DETAILS')}
        subTitle={t('NAVBAR.SELECT_SUBSCRIPTION_PACKAGE')}
      >
        <GoLiveSubscriptionDetails formik={formik} title={plgPricingPlan?.description} />
      </InputsSectionContainer>
      <InputsSectionContainer
        title={t('NAVBAR.SUBSCRIPTION_ADD-ONS')}
        subTitle={t('NAVBAR.SUBSCRIPTION_ADD-ONS_DESC')}
        hasSwitchButton
        onSwitch={handleSwitch}
        switchValue={isSwitchOn}
      >
        {isSwitchOn && (
          <GoLiveSubscriptionAddons
            formik={formik}
            productsAddOnsSubscriptions={plgProductsSubscriptions}
            servicesAddOnsSubscriptions={plgServicesSubscriptions}
          />
        )}
      </InputsSectionContainer>
    </section>
  );
};

export default GoLiveSubscriptionSettings;
