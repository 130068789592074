import { useQuery } from '@tanstack/react-query';

import RecruitmentJobsApi from 'api/RecruitmentJobsApi';

export const queryKeys = {
  generalStatistics: (jobPostId) => ['generalStatistics', jobPostId],
  applicationsPerDay: (jobPostId, params) => ['applicationsPerDay', jobPostId, JSON.stringify(params)],
};

export const useQueryGeneralStatistics = (jobPostId) =>
  useQuery(queryKeys.generalStatistics(jobPostId), () => RecruitmentJobsApi.getGeneralStatistics(jobPostId));

export const useQueryApplicationPerDay = ({ jobPostId, params }) =>
  useQuery(queryKeys.applicationsPerDay(jobPostId, params), () =>
    RecruitmentJobsApi.getApplicationsPerDay({ jobPostId, params })
  );
