import React from 'react';

import CheckBox from 'components/forms/components/CheckBox';

const PersonalSettingsDataSection = ({ sectionTitle, inputs, values, onChange }) => (
  <section className='d-flex flex-column'>
    <h6 className='my-3 text-gray-400 font-size-11 font-weight-bold'>{sectionTitle}</h6>
    <section className='d-flex flex-column gap-2'>
      {inputs.map(({ prop, name, isEditAllowed }) => (
        <CheckBox
          key={name}
          name={prop}
          value={prop}
          isDisabled={!isEditAllowed}
          id={prop}
          labelClass='text-main'
          label={name}
          checked={Boolean(values[prop])}
          onChange={(value) => onChange(prop, value)}
        />
      ))}
    </section>
  </section>
);

export default PersonalSettingsDataSection;
