import React, { useEffect } from 'react';

import usePageVisitTracker from '../../shared/usePageVisitTracker';
import { useVisibility } from '../../shared/useVisibility';
import { useAccountSubscriptionData } from '../../topNavigation/hooks/useAccount';
import Modal from '../Modal';
import PHRGettingStartedWizard from '../PHRGettingStartedWizard/PHRGettingStartedWizard';

const GettingStartedWizardModal = ({ generateWizardImagesSteps, accountTypes, trackedPages }) => {
  const { isOpen, open, close } = useVisibility();

  const { data: accountSubscriptionData } = useAccountSubscriptionData();
  const { hasVisitedThisPage, shouldTrackVisit, isLoading } = usePageVisitTracker(trackedPages, accountTypes);

  const clientType = accountSubscriptionData?.data.account.clientType;
  const isPLGClient = accountSubscriptionData?.data.isPlg;
  const showWizardModal = !hasVisitedThisPage && shouldTrackVisit && accountTypes.includes(clientType) && isPLGClient;

  useEffect(() => {
    if (showWizardModal && !isLoading) {
      open();
    }

    return () => {
      close();
    };
  }, [showWizardModal, isLoading]);

  return (
    <Modal
      styles={{
        width: '1024px',
        padding: 0,
        height: '800px',
      }}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      showHeader={false}
    >
      <PHRGettingStartedWizard generateWizardSteps={generateWizardImagesSteps} onClose={close} />
    </Modal>
  );
};

export default GettingStartedWizardModal;
