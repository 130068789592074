import http from './Api';

class SubscriptionApi {
  static getSubscriptionPlan() {
    return http.get(`settings/accounts/subscription/plan`);
  }

  static getSubscriptionPayment() {
    return http.get(`settings/accounts/subscription/payment`);
  }

  static getSubscriptionInvoices() {
    return http.get(`settings/accounts/subscription/invoices`);
  }

  static updateBillingInfo(data) {
    return http.put(`settings/accounts/billing-address`, data);
  }

  static addBillingInfo(data) {
    return http.post(`settings/accounts/billing-address`, data);
  }

  static cancelSubscription(data) {
    return http.post(`settings/accounts/subscription/cancel`, data);
  }

  static downloadInvoice(invoice, action = 'preview') {
    return http.post(`settings/accounts/subscription/invoices/${action}`, {
      invoice,
    });
  }

  static contactSales(data) {
    return http.post(`palm-hr/contact-sales`, data);
  }

  static upgradeSubscription(data) {
    return http.post('settings/accounts/subscription/upgrade', data);
  }

  static subscribe(data) {
    return http.post('accounts/subscribe', data);
  }
}

export default SubscriptionApi;
