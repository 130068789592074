import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import Slider from 'rc-slider/lib/Slider';
import 'rc-slider/assets/index.css';
import Cropper from 'react-easy-crop';

import IconButton from 'components/buttons/IconButton/IconButton';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import readFile from 'utility/readFile';
import t from 'utility/setTranslation';

import styles from './BackgroundCropper.module.scss';

const CONTAINER_WIDTH = Math.round(1920 / 2);
const CONTAINER_HEIGHT = Math.round(200 / 2);

const BackgroundCropper = ({ img, onSave, onClose, reverse, sendingImage, fieldValue }) => {
  const [error, setError] = useState(null);
  const [scale, setScale] = useState(1);
  const [image, setImage] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [minZoom, setMinZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const validateImageFormat = (file) => {
    const allowedExtension = ['jpeg', 'jpg', 'png'];
    return allowedExtension.some((el) => file.type.includes(el));
  };

  useEffect(() => {
    if (validateImageFormat(img)) {
      readFile(img).then((res) => {
        setImage(img);
        setImageSrc(res);
      });
    } else {
      setError('GENERAL.IMAGE_INVALID_FORMAT');
    }
  }, []);

  const handleScale = (val) => {
    setScale(val);
  };

  const handleNewImage = (e) => {
    e.persist();
    if (e.target.files && e.target.files.length > 0 && validateImageFormat(e.target.files[0])) {
      readFile(e.target.files[0]).then((res) => {
        setImage(e.target.files[0]);
        setImageSrc(res);
        setError('');
      });
    } else {
      setError('GENERAL.IMAGE_INVALID_FORMAT');
    }
  };

  const setZoom = (imageSize) => {
    const widthZoom = CONTAINER_WIDTH / imageSize.width;
    const heightZoom = CONTAINER_HEIGHT / imageSize.height;
    const initialZoom = widthZoom > heightZoom ? widthZoom.toFixed(2) : heightZoom.toFixed(2);
    setMinZoom(parseFloat(initialZoom));
    setScale(parseFloat(initialZoom));
  };

  const onCropChange = (values) => {
    setCrop(values);
  };

  const onCropComplete = (croppedArea, areaPixels) => {
    setCroppedAreaPixels(areaPixels);
  };

  const sliderStyles = {
    track: {
      backgroundColor: '#1A938A',
    },
    handle: {
      width: 15,
      height: 15,
      borderColor: '#1A938A',
      boxShadow: `0px 2px 2px 0px rgba(93, 114, 140, 0.14),
              0px 3px 1px -2px rgba(93, 114, 140, 0.12),
              0px 1px 5px 0px rgba(93, 114, 140, 0.2)`,
    },
  };

  const saveHandler = () => {
    if (image.size > 5242880) {
      setError('GENERAL.IMAGE_INVALID');
      return;
    }

    if (!validateImageFormat(image)) {
      setError('GENERAL.IMAGE_INVALID_FORMAT');
      return;
    }

    const data = new FormData();
    data.append(fieldValue, image);
    data.append('zoom', scale / minZoom);
    data.append('xCoordinate', croppedAreaPixels.x);
    data.append('yCoordinate', croppedAreaPixels.y);

    onSave(data);
  };

  return (
    <div className='cropper-wrapper'>
      <span className='d-flex justify-content-between border-btm pb-4 mb-3'>
        <h5 className='fw-bold m-0'>{t('GENERAL.UPLOAD_PHOTO')}</h5>
        <IconButton icon='icon-cross-x' onClick={onClose} size='md' color='gray' />
      </span>
      <div className='d-flex new align-items-center justify-content-between mb-4'>
        <p className='mb-0'>{t('GENERAL.DRAG_PHOTO')}</p>
      </div>
      <div className={styles.cropContainer}>
        {error ? (
          <div className={styles.invalidImage}>{t('GENERAL.IMAGE_INVALID_FORMAT')}</div>
        ) : (
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={scale}
            aspect={1920 / 200}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={handleScale}
            cropSize={{ width: CONTAINER_WIDTH, height: CONTAINER_HEIGHT }}
            onMediaLoaded={setZoom}
            showGrid={false}
            maxZoom={minZoom * 3}
            minZoom={minZoom}
          />
        )}
      </div>
      <div className='scale-box d-flex justify-content-center align-items-center mt-4'>
        <span className='text-gray'>{t('GENERAL.ZOOM_OUT')}</span>
        <span className='range-input-wrapper d-flex align-items-center mx-5'>
          <Slider
            handleStyle={sliderStyles.handle}
            trackStyle={sliderStyles.track}
            step={0.01}
            min={minZoom}
            max={minZoom * 3}
            value={scale}
            onChange={handleScale}
            reverse={reverse}
          />
        </span>
        <span className='text-gray'>{t('GENERAL.ZOOM_IN')}</span>
      </div>
      <div className='text-end d-flex align-items-center justify-content-between mt-5'>
        <div>
          <label className={styles.roundedButton} htmlFor='image-file'>
            {t('GENERAL.UPLOAD_NEW')}
          </label>
          <input name='newImage' type='file' accept='image/*' onChange={handleNewImage} hidden id='image-file' />
        </div>
        <div className='d-flex'>
          <RoundedButton
            onClick={saveHandler}
            btnStyle='contained'
            color='primary'
            size='md'
            isDisabled={!!error}
            text='Save Changes'
            loading={sendingImage}
          />
          <RoundedButton onClick={onClose} btnStyle='outlined' color='gray' size='md' text='Cancel' className='ms-3' />
        </div>
      </div>
    </div>
  );
};

BackgroundCropper.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  img: PropTypes.object,
  reverse: PropTypes.bool,
};

BackgroundCropper.defaultProps = {
  reverse: false,
  img: '',
};

export default BackgroundCropper;
