import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import t from 'utility/setTranslation';

import styles from './HistoryItem.module.scss';
import IconButton from '../../../../components/buttons/IconButton/IconButton';
import Cell from '../../../../components/table/Cell';
import formatCurrency from '../../../../utility/formatCurrency';

const HistoryItem = ({ data, onDownload, onPreview }) => (
  <tr className={cx(styles.row, 't-row border-btm light')} onClick={onPreview}>
    <Cell className='ps-3'>{data.created ? moment(data.created).format('MMM DD, YYYY') : '- -'}</Cell>
    <Cell>{t(data.plan)}</Cell>
    <Cell>{data.period}</Cell>
    <Cell>
      <div className={cx(styles.status, styles[_.camelCase(data.status)], 'text-center')}>
        {_.lowerCase(data.status)}
      </div>
    </Cell>
    <Cell>{data.amount ? `${data.currency} ${formatCurrency(data.amount)}` : '- -'}</Cell>
    <Cell>
      <div
        className='d-flex justify-content-end'
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <IconButton icon='icon-eye' onClick={onPreview} size='md' color='gray' />
        <IconButton icon='icon-download' onClick={onDownload} size='md' color='gray' />
      </div>
    </Cell>
  </tr>
);

HistoryItem.propTypes = {
  data: PropTypes.object.isRequired,
  onDownload: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
};

export default HistoryItem;
