import React, { lazy, Suspense } from 'react';

import InitLoading from 'components/Spinner/InitLoading';
import PrivateRoute from 'topNavigation/Router/PrivateRoute';
import PublicRoute from 'topNavigation/Router/PublicRoute';
import { MobileFriendlyRoutes, PrivateRoutes, PublicRoutes } from 'topNavigation/Router/routes';

const Careers = lazy(() => import('modules/Recruitment/Careers'));
const DigitalBusinessCard = lazy(() => import('modules/DigitalBusinessCard/DigitalBusinessCard'));
const RecruitmentLobby = lazy(() => import('modules/Recruitment/RecruitmentLobby/RecruitmentLobby'));
const Page403 = lazy(() => import('errors/403/Page403'));
const Page404 = lazy(() => import('errors/404/Page404'));
const ApiVersion = lazy(() => import('modules/ApiVersion/ApiVersion'));
const EmptyPage = lazy(() => import('modules/EngagementCenter/EmptyPage'));

const useSharedRoutes = () => [
  {
    path: MobileFriendlyRoutes.businessCard,
    element: (
      <Suspense fallback={<InitLoading />}>
        <PublicRoute component={DigitalBusinessCard} />
      </Suspense>
    ),
  },
  {
    path: MobileFriendlyRoutes.careersDetails,
    element: (
      <Suspense fallback={<InitLoading />}>
        <PublicRoute component={Careers} />
      </Suspense>
    ),
  },
  {
    path: MobileFriendlyRoutes.recruitmentLobbyDetails,
    element: (
      <Suspense fallback={<InitLoading />}>
        <PublicRoute component={RecruitmentLobby} />
      </Suspense>
    ),
  },
  {
    path: PrivateRoutes.expired,
    element: (
      <Suspense fallback={<InitLoading />}>
        <PrivateRoute path='/expired' component={EmptyPage} />
      </Suspense>
    ),
  },
  {
    path: PublicRoutes.error404,
    element: (
      <Suspense fallback={<InitLoading />}>
        <PublicRoute component={Page404} />
      </Suspense>
    ),
  },
  {
    path: PublicRoutes.error403,
    element: (
      <Suspense fallback={<InitLoading />}>
        <PublicRoute component={Page403} />
      </Suspense>
    ),
  },
  {
    path: PublicRoutes.apiVersion,
    element: (
      <Suspense fallback={<InitLoading />}>
        <PublicRoute component={ApiVersion} />
      </Suspense>
    ),
  },
];

export default useSharedRoutes;
