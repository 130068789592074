import { useQuery } from '@tanstack/react-query';

import FiltersAPI from './FiltersAPI';

export const queryKeys = {
  departmentFilters: (slug) => ['departmentFilters', slug],
  legalEntityFilters: (slug) => ['legalEntityFilters', slug],
  locationFilters: (slug) => ['locationFilters', slug],
  teamFilters: (slug) => ['teamFilters', slug],
  deductionTypeFilters: (slug) => ['deductionTypeFilters', slug],
  additionTypeFilters: (slug) => ['additionTypeFilters', slug],
};

export const useDepartmentFilters = (params = { perPage: 999 }, slug, useOptions = {}, apiConfig = {}) =>
  useQuery(queryKeys.departmentFilters(slug), () => FiltersAPI.departmentFilters(params, apiConfig), useOptions);

export const useLegalEntityFilters = (slug, useOptions = {}, apiConfig = {}) =>
  useQuery(queryKeys.legalEntityFilters(slug), () => FiltersAPI.legalEntityFilters(apiConfig), useOptions);

export const useLocationFilters = (params = { perPage: 999 }, slug, useOptions = {}, apiConfig = {}) =>
  useQuery(queryKeys.locationFilters(slug), () => FiltersAPI.locationFilters(params, apiConfig), useOptions);

export const useTeamFilters = (params = { perPage: 999 }, slug, useOptions = {}, apiConfig = {}) =>
  useQuery(queryKeys.teamFilters(slug), () => FiltersAPI.teamFilters(params, apiConfig), useOptions);

export const useDeductionTypeFilters = ({ params = {}, slug = null, useOptions = {}, apiConfig = {} } = {}) =>
  useQuery(queryKeys.deductionTypeFilters(slug), () => FiltersAPI.deductionTypeFilters(params, apiConfig), useOptions);

export const useAdditionTypeFilters = ({ params = {}, slug = null, useOptions = {}, apiConfig = {} } = {}) =>
  useQuery(queryKeys.additionTypeFilters(slug), () => FiltersAPI.additionTypeFilters(params, apiConfig), useOptions);
