import http, { httpPalmHrAPI } from 'api/Api';

class AccountApi {
  static disablePermissionsPerEntity() {
    return http.put(`/settings/accounts/disable-role-permissions-per-legal-entity`);
  }

  static enablePermissionsPerEnity() {
    return http.put(`/settings/accounts/enable-role-permissions-per-legal-entity`);
  }

  static getAccountSettings() {
    return http.get(`/settings/accounts`);
  }

  static getAccountSubscriptionDueDate() {
    return http.get('/settings/accounts/subscription/due-date');
  }

  static cancelSubscription() {
    return http.put(`/settings/cancel-renewal`);
  }

  static renewSubscription() {
    return http.put(`/settings/confirm-renewal`);
  }

  static confirmPayment() {
    return http.put(`/settings/confirm-payment`);
  }

  static checkIsDomainAvailable(domain) {
    return httpPalmHrAPI.get(`/api/v1/utility/domain-available?domain=${domain}`);
  }

  static getAccountPlansAndSubscriptions() {
    return http.put('/settings/plans');
  }

  static plgGoLive(payload) {
    return http.put(`/settings/go-live`, payload);
  }

  static getInvoicePreview(payload) {
    return http.put('settings/invoice-preview', payload);
  }

  static plgClearData() {
    return http.put('/settings/clear-data');
  }

  static plgBookDemo() {
    return http.put('/settings/demo-request');
  }

  static isPaymentComplete() {
    return http.put('/settings/payment-selected');
  }

  static recordPageVisit(payload) {
    return http.post('/settings/plg/page-visited', payload);
  }

  static getRecordingPageVisits() {
    return http.get('/settings/plg/page-visited');
  }

  static getPlgTasks() {
    return http.get('/settings/plg/go-live-tasks');
  }

  static getStripeClientSecret() {
    return http.put('/settings/stripe-checkout-session');
  }

  static addStripePaymentMethod() {
    return http.put('/settings/init-payment-method-change');
  }

  static confirmAddStripePaymentMethod() {
    return http.put('/settings/confirm-payment-method-change');
  }

  static getAccountListSubscriptions() {
    return http.get('/settings/list-subscriptions');
  }

  static updateAccountSubscription(payload) {
    return http.put(`/settings/list-subscriptions`, payload);
  }

  static getOnlinePaymentConfigured() {
    return http.get('/settings/online-payment-configured');
  }

  static getBillingInfo() {
    return http.get('/settings/billing-info');
  }

  static updateBillingInfo(payload) {
    return http.put('/settings/billing-info', payload);
  }

  static cancelAccountSubscription() {
    return http.put('/settings/cancel-subscriptions');
  }
}

export default AccountApi;
