import http from 'api/Api';

export default class WhoIsInQueryApi {
  static getWhoIsIn(params = {}, config = {}) {
    return http.get(`/employees/who-is-in`, { ...config, params });
  }

  static countWhoIsIn(params = {}, config = {}) {
    return http.get(`/employees/who-is-in/count`, { ...config, params });
  }

  static getWhoNeedsToBeIn(params = {}, config = {}) {
    return http.get(`/employees/who-needs-to-be-in`, { ...config, params });
  }

  static countWhoNeedsToBeIn(params = {}, config = {}) {
    return http.get(`/employees/who-needs-to-be-in/count`, { ...config, params });
  }

  static getWhoIsOut(params = {}, config = {}) {
    return http.get(`/employees/who-is-out`, { ...config, params });
  }

  static countWhoIsOut(params = {}, config = {}) {
    return http.get(`/employees/who-is-out/count`, { ...config, params });
  }
}
