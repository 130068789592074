import http from './Api';

class TasksApi {
  static addTask(data) {
    return http.post('/tasks', data);
  }

  static getTasks(filters = '') {
    return http.get(`/tasks${filters}`);
  }

  static getTasksExport(exportType, params = '', config = {}) {
    return http.get(`/tasks/export/${exportType}${params}`, { ...config, responseType: 'arraybuffer' });
  }

  static getTaskExport(taskId, config = {}) {
    return http.get(`/task/${taskId}/export`, { ...config, responseType: 'arraybuffer' });
  }

  static deleteTask(id) {
    return http.delete(`/tasks/${id}`);
  }

  static deleteMultipleTasks(data) {
    return http.post(`/tasks/delete/multiple`, data);
  }

  static editTask(id, data) {
    return http.put(`/tasks/${id}`, data);
  }

  static changeMultipleTasksStatus(data) {
    return http.put(`/tasks/change-status/multiple`, data);
  }

  static changeMultipleTasksPriority(data) {
    return http.put(`/tasks/change-priority/multiple`, data);
  }

  static getTaskById(id) {
    return http.get(`/tasks/${id}`);
  }

  static getTaskLog(id, filters = '') {
    return http.get(`/task/${id}/log${filters}`);
  }

  /* TASK ATTACHMENTS */
  static addTaskAttachment(id, data) {
    return http.post(`/tasks/${id}/attachments`, data);
  }

  static getTaskAttachments(id, filters = '') {
    return http.get(`/tasks/${id}/attachments${filters}`);
  }

  static deleteTaskAttachment(id) {
    return http.delete(`/tasks/attachments/${id}/delete`);
  }

  static updateTaskAttachment(id, attachment, data) {
    return http.put(`tasks/${id}/attachments/${attachment}/edit`, data);
  }

  static getTaskAttachment(id, action = 'preview') {
    const timestamp = new Date().getTime();
    return http.get(`/tasks/attachments/${id}/${action}?_=${timestamp}`, {
      responseType: 'arraybuffer',
    });
  }

  /* EXECUTE MUQEEM */
  static executeMuqeem(id, data) {
    return http.put(`/tasks/${id}/execute-muqeem`, data);
  }
}

export default TasksApi;
