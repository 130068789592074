import formatKey from 'utility/formatKey';

import actionIconMap from './actionIconMap';

const createSelectOptions = (actions, showIcon = true) => {
  const options = [];
  actions.forEach((item) => {
    options.push({
      action: item,
      title: formatKey('GENERAL', item),
      icon: showIcon && actionIconMap[item.toLowerCase()],
    });
  });
  return options;
};

export default createSelectOptions;
