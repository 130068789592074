import { useQuery } from '@tanstack/react-query';

import DashboardApi from 'api/DashboardApi';

export const queryBaseKeys = {
  getTodayShifts: 'getTodayShifts',
};

export const queryKeys = {
  getTodayShifts: (params) => [queryBaseKeys.getTodayShifts, params],
};

export const useQueryTodayShifts = (params = {}) =>
  useQuery(queryKeys.getTodayShifts(params), () => DashboardApi.getShifts(params));
