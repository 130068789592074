import axios from 'axios';
import moment from 'moment-timezone';

import { setLocalStorage, getLocalStorage } from 'auth/AuthContext';

import resourcePath from '../shared/resourcePath';

let data = {};

export const httpPalmHrAPI = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_PALMHR_API,
});

const http = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}api/v1/`,
});

const isHandlerEnabled = (config = {}) => !(config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled);

const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    data = getLocalStorage('auth');
    // Modify request here
    if (
      !(
        request.url === 'login' ||
        request.url === 'reset-password' ||
        request.url === 'forgot-password' ||
        request.url.includes('global-configuration') ||
        request.url === 'user-password-config'
      )
    ) {
      request.headers.Authorization = `Bearer ${data.token}`;
    }

    if (!request.url.includes('translation')) {
      request.headers['X-Client-Timezone'] = moment.tz.guess();
    }

    if (window.location.hash.includes('#by-notification')) {
      request.params = {};
      request.params['by-notification'] = 'true';
    }

    request.headers['X-Palmhr-Client'] = 'WEB';
    if (!request.url.includes('.json')) {
      // eslint-disable-next-line prefer-destructuring
      request.headers.tenant = new URL(window.location.href).hostname.split('.')[0];

      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line prefer-destructuring
        request.headers.tenant = process.env.REACT_APP_TENANT;
      }
    }
  }
  return request;
};

const errorHandler = async (error) => {
  if (
    error?.config &&
    !(
      error?.config.url === 'login' ||
      error?.config.url === 'reset-password' ||
      error?.config.url === 'forgot-password'
    ) &&
    isHandlerEnabled(error?.config)
  ) {
    /** Refresh Token Block * */

    if (error.response?.data?.code === 1597920964 || error.response?.data?.code === 1650032054) {
      localStorage.removeItem('store');
      localStorage.removeItem('isLoggedIn');
      window.location.href = '/login';
      return Promise.reject(error);
    }

    if (error.response?.data?.code === 1652960767) {
      localStorage.removeItem('store');
      localStorage.removeItem('isLoggedIn');
      window.location.href = 'maintenance.html';
      return Promise.reject(error);
    }

    if (error?.response?.status === 404) {
      // window.location.href = '/404';
      return Promise.reject(error);
    }

    const originalRequest = error?.config;

    if (error?.response?.status === 403 && !originalRequest.url.includes('token-refresh')) {
      window.location.href = '/403';
      return Promise.reject(error);
    }

    if (error?.response?.status === 401 && originalRequest.url.includes('token-refresh')) {
      localStorage.removeItem('store');
      localStorage.removeItem('isLoggedIn');
      window.location.href = '/login#tokenExpired';
      return Promise.reject(error);
    }

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const { refreshToken } = data;

      try {
        const res = await http.post('token-refresh', { refreshToken });
        const newData = res.data;
        newData.user = data.user;
        newData.translations = data.translations;
        newData.companyBackground = resourcePath(data.companyBackground);
        newData.init = data.init;
        newData.companyData = data.companyData;
        newData.subscriptionStatus = data.subscriptionStatus;
        newData.currentTimeTracking = data.currentTimeTracking;
        newData.isRenewalPending = data.isRenewalPending;
        newData.isInvoicePending = data.isInvoicePending;
        newData.daysAfterOverdue = data.daysAfterOverdue;
        newData.daysBeforeOverdue = data.daysBeforeOverdue;
        newData.forbidConfirmPaymentOnSuspendedPage = data.forbidConfirmPaymentOnSuspendedPage;

        setLocalStorage('auth', newData);

        data = getLocalStorage('auth');
        originalRequest.headers.Authorization = `Bearer ${data.token}`;
        return axios(originalRequest);
      } catch (e) {
        localStorage.removeItem('store');
        localStorage.removeItem('isLoggedIn');
        window.location.href = '/login#tokenExpired';
        return Promise.reject(error);
      }
    }
    /** ./ Refresh Token Block * */
  }
  return Promise.reject({ ...error });
};

const successHandler = (response) => response;

http.interceptors.request.use((request) => requestHandler(request));

http.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

httpPalmHrAPI.interceptors.request.use((request) => {
  const initialRequest = requestHandler(request);

  if (!request.url.includes('.json') && process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line prefer-destructuring
    initialRequest.headers.tenant = process.env.REACT_APP_TENANT;
  }

  return initialRequest;
});

httpPalmHrAPI.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export default http;
