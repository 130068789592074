import React from 'react';

import { PHRSearchInput } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

import useDirection from 'shared/useDirection';
import formatKey from 'utility/formatKey';

const Search = ({
  onChange,
  onClear,
  placeholder,
  className,
  value,
  minimized,
  autofocus,
  iconSize,
  iconColor,
  disabled,
  readOnly,
}) => {
  const direction = useDirection();

  return (
    <PHRSearchInput
      onChange={onChange}
      onClear={onClear}
      placeholder={formatKey('GENERAL', placeholder)}
      className={className}
      value={value}
      minimized={minimized}
      autofocus={autofocus}
      direction={direction}
      iconSize={iconSize}
      iconColor={iconColor}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
};

Search.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  autofocus: PropTypes.bool,
  iconSize: PropTypes.string,
  iconColor: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

Search.defaultProps = {
  placeholder: 'Type text...',
  className: '',
  value: '',
  autofocus: false,
  disabled: false,
  readOnly: false,
  iconSize: 'md',
  iconColor: 'gray',
};

export default Search;
