import React, { useContext, useState } from 'react';

import cx from 'classnames';

import { useTeamFilters } from 'api/Filters/FiltersAPIUse';
import { AuthContext } from 'auth/AuthContext';
import { defaultProps, propTypes, formatDisplay } from 'components/Filters/Team/utility';
import MultipleSelectField from 'components/forms/components/MultipleSelectField';
import TeamCollection from 'services/Team/TeamCollection';

const TeamFilter = ({
  value,
  name,
  label,
  className,
  onChange,
  isDisabled,
  isRequired,
  showLabel,
  placeholder,
  isSearchDesign,
}) => {
  const { authState } = useContext(AuthContext);
  const [selectedTeams, setSelectedTeams] = useState(value);
  const { isFetching, data: responseData } = useTeamFilters();
  const teams = TeamCollection.create(responseData?.data?.items ?? []);

  const onValueChange = (newValue) => {
    setSelectedTeams(newValue);
    if (onChange) onChange(newValue);
  };

  return (
    <MultipleSelectField
      className={cx('min-h-unset', className)}
      label={formatDisplay(showLabel ? label : '')}
      name={formatDisplay(name)}
      placeholder={formatDisplay(placeholder)}
      options={teams.getItemsAsOptions(authState)}
      value={selectedTeams}
      onChange={(fieldName, teamsData) => onValueChange(teamsData)}
      isDisabled={isFetching || isDisabled}
      isRequired={isRequired}
      isFormik={false}
      isSearchDesign={isSearchDesign}
    />
  );
};

TeamFilter.propTypes = propTypes;
TeamFilter.defaultProps = defaultProps;

export default TeamFilter;
