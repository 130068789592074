import React, { createContext, useReducer } from 'react';

import { unregisterMessaging } from 'services/Messaging/NotificationUnregisterService';
import { clearStore } from 'services/Store/StoreService';

import { checkPrivileges, roles } from './RolePrivileges';
import resourcePath from '../shared/resourcePath';

const getLocalStorage = (key) => {
  let data = window.localStorage.getItem('store') || null;
  data = JSON.parse(data);
  if (!data) return null;
  return key in data ? data[key] : null;
};

const setLocalStorage = (key, value) => {
  let data = window.localStorage.getItem('store') || null;
  data = JSON.parse(data) || {};
  data[key] = value;
  window.localStorage.setItem('store', JSON.stringify(data));
};

/* AUTH CONTEXT */
const AuthContext = createContext();
const auth = getLocalStorage('auth');

const initialState = {
  user: auth ? auth.user : null,
  token: auth ? auth.token : null,
  refreshToken: auth ? auth.refreshToken : null,
  translations: auth ? auth.translations : null,
  currentTimeTracking: auth ? auth.currentTimeTracking : null,
  refreshExpireAt: auth ? auth.refreshExpireAt : null,
  companyBackground: auth ? resourcePath(auth.companyBackground) : null,
  init: auth ? auth.init : null,
  companyData: auth ? auth.companyData : null,
  subscriptionStatus: auth ? auth.subscriptionStatus : null,
  isRenewalPending: auth?.isRenewalPending || false,
  isInvoicePending: auth?.isInvoicePending || false,
  daysAfterOverdue: auth?.daysAfterOverdue || 0,
  daysBeforeOverdue: auth?.daysBeforeOverdue || 0,
  forbidConfirmPaymentOnSuspendedPage: auth?.forbidConfirmPaymentOnSuspendedPage || false,
  checkPrivileges: (action) => checkPrivileges(action, auth.user?.roles, roles),
};

const authReducer = (authState, action) => {
  let data = {};
  switch (action.type) {
    case 'set':
      setLocalStorage('auth', {
        ...authState,
        ...action.payload,
      });
      data = {
        ...authState,
        ...action.payload,
      };
      break;
    case 'get':
      data = { ...authState };
      break;
    case 'del':
      unregisterMessaging();
      clearStore();
      break;
    default:
      break;
  }

  return data;
};

const AuthContextProvider = (props) => {
  const authProps = { ...props };
  const [authState, dispatch] = useReducer(authReducer, initialState);
  const value = { authState, dispatch };
  return <AuthContext.Provider value={value}>{authProps ? authProps.children : null}</AuthContext.Provider>;
};

export { AuthContext, AuthContextProvider, getLocalStorage, setLocalStorage };
