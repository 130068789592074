import http from 'api/Api';

export default class FiltersAPI {
  static profileFields(type, params, config = {}) {
    return http.get(`settings/team/profile-fields/${type}`, { ...config, params });
  }

  static groupFilters(group, config = {}) {
    return http.get(`filters?groups=${group}`, { ...config });
  }

  static departmentFilters(params, config = {}) {
    return FiltersAPI.profileFields('departments', params, config);
  }

  static teamFilters(params, config = {}) {
    return FiltersAPI.profileFields('teams', params, config);
  }

  static legalEntityFilters(config = {}) {
    return FiltersAPI.groupFilters(5, config).then((response) => ({
      ...response,
      data: response?.data?.legalEntities ?? [],
    }));
  }

  static locationFilters(params, config = {}) {
    return http.get(`settings/account/company-locations`, { params, ...config });
  }

  static deductionTypeFilters({ params = {}, config = {} } = {}) {
    return http.get(`settings/pay/compensation/payroll/deductions`, { params, ...config });
  }

  static additionTypeFilters({ params = {}, config = {} } = {}) {
    return http.get(`settings/pay/compensation/payroll/additions`, { params, ...config });
  }
}
