/* eslint-disable max-len */
import { getThemeFromLS } from '../../../../themes/ThemeContext';

const themeState = getThemeFromLS('theme');

export default {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
    height: '100%',
    wordBreak: 'break-all',
  },

  '&multiLine': {
    control: {
      fontFamily: 'inherit',
      minHeight: 64,
    },
    highlighter: {
      padding: 8,
      border: 'none',
      color: themeState && themeState?.colors['text-main'] ? themeState?.colors['text-main'] : '#043344',
      boxSizing: 'border-box',
      overflow: 'hidden',
      height: 88,
      wordBreak: 'break-all',
    },
    input: {
      padding: 8,
      border: 'none',
      color: themeState && themeState?.colors['text-main'] ? themeState?.colors['text-main'] : '#043344',
      overflow: 'auto',
      height: 88,
      wordBreak: 'break-all',
    },
  },

  suggestions: {
    zIndex: 99999,
    boxShadow:
      '0px 4px 5px 0px rgba(93, 114, 140, 0.1), 0px 1px 10px 0px rgba(93, 114, 140, 0.08), 0px 2px 4px -1px rgba(93, 114, 140, 0.16)',

    list: {
      backgroundColor: '#fff',
      color: themeState && themeState?.colors['text-main'] ? themeState?.colors['text-main'] : '#043344',
      fontSize: '13px',
      zIndex: 99999,
      maxHeight: '256px',
      overflow: 'auto',
    },
    item: {
      padding: '4px 8px',
      '&focused': {
        background: `rgba(
          ${themeState && themeState?.colors['green-rgb'] ? themeState?.colors['green-rgb'] : '#e9f9f1'},
          0.1
        )`,
      },
      color: themeState && themeState?.colors['text-main'] ? themeState?.colors['text-main'] : '#043344',
      zIndex: 99999,
    },
  },
};
