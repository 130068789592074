import React, { useContext, useMemo, useState } from 'react';

import cx from 'classnames';
import Scrollbars from 'react-custom-scrollbars';

import { useGetWhoIsOut, useGetWhoIsOutPaginated } from 'api/WhoIsIn/WhoIsInQueryApiUse';
import { AuthContext } from 'auth/AuthContext';
import Personas from 'components/Personas';
import Card from 'components/PHRUI/Card/Card';
import ToolBar from 'components/PHRUI/ToolBar/ToolBar';
import Tooltip from 'components/tooltip/Tooltip';
import OutsideClickHandler from 'hoc/OutsideClickHandler';
import SkeletonCard from 'modules/Dashboard/components/SkeletonCard';
import { flatMap } from 'utility/generalUtils';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

import WhoIsOutRecord from './List/WhoIsOutRecord';
import styles from './styles.module.scss';

const cardContent = (isFetching, items, authState, remainingTotal) => {
  if (isFetching) return <SkeletonCard />;

  const hasItems = !!items?.length;

  return (
    <>
      <span className={cx(styles.title)}>{t('GENERAL.WHO_IS_OUT')}</span>
      {!hasItems && <div className={'mt-3 mb-3'}>{t(`GENERAL.NO_OUT`)}</div>}
      {hasItems && (
        <ToolBar justifyContent={'start'} className={'mt-3 mb-3'}>
          <i className={cx(styles.icon, 'me-3 icon-time')} />
          {items.map((employee) => (
            <div className='me-2' key={employee.id}>
              <Tooltip label={readName(authState.user.language, employee.fullName, employee.fullNameArabic)}>
                <Personas user={employee} size={24} />
              </Tooltip>
            </div>
          ))}
          {Boolean(remainingTotal) && (
            <div className='me-2'>
              <Tooltip label={t('GENERAL.NO_OF_MORE_USERS', { number: remainingTotal })}>
                <div className={cx(styles.remainingTotal, 'd-flex', 'align-items-center', 'justify-content-center')}>
                  {`+${remainingTotal}`}
                </div>
              </Tooltip>
            </div>
          )}
        </ToolBar>
      )}
    </>
  );
};

const WhoIsOut = ({ className }) => {
  const { authState } = useContext(AuthContext);
  const [showList, setShowList] = useState(false);
  const { isFetching, data: response } = useGetWhoIsOut(0, { limit: 4, page: 0 });
  const { items, pagination } = response?.data ?? {};
  const { total } = pagination ?? {};
  const remainingTotal = (total ?? 0) - (items ?? []).length;
  const content = cardContent(isFetching, items, authState, remainingTotal);

  const {
    isFetching: isListing,
    data: listResponse,
    fetchNextPage,
    hasNextPage,
  } = useGetWhoIsOutPaginated({ limit: 10 }, { enabled: showList });

  const { items: listItems } = listResponse?.data ?? {};
  const records = useMemo(() => flatMap(listResponse?.pages), [listResponse?.pages?.length]);

  const onScroll = async (event) => {
    if (isListing || event.top < 0.999 || !hasNextPage) return;
    await fetchNextPage();
  };

  const onCardClick = () => {
    if (!total) return;
    setShowList(true);
  };

  return (
    <Card raised hoverable={false} className={cx(className, 'position-relative')} onClick={onCardClick}>
      {content}
      {showList && (
        <OutsideClickHandler clickHandler={() => setShowList(false)}>
          <div className={cx(styles.list, 'bg-white radius-4')}>
            <Scrollbars
              className='scrollbarsWrapper'
              autoHeight
              autoHeightMin={100}
              autoHeightMax={250}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              onScrollFrame={onScroll}
            >
              <ul className='no-style px-2 pt-3 d-flex flex-wrap mb-0'>
                {(records ?? []).map((item) => (
                  <li className='p-2 mb-3 w-100' key={item.id}>
                    <WhoIsOutRecord employee={item} />
                  </li>
                ))}
              </ul>
            </Scrollbars>
          </div>
        </OutsideClickHandler>
      )}
    </Card>
  );
};

export default WhoIsOut;
