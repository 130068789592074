import _ from 'lodash';
import moment from 'moment/moment';

import palmhrAnnouncementImg from 'assets/img/announcement-default.jpeg';
import khadamatiAnnouncementImg from 'assets/img/enterprise/khadamati/default_announcement.png';
import resourcePath from 'shared/resourcePath';
import numberUtil from 'utility/numberUtil';

import { mimeTypeIsImage, mimeTypeIsPdf, nameExtension } from './FileService';

export default class PHRFile {
  #data;

  constructor(data = {}) {
    if (!_.isObject(data)) {
      throw new Error('PHRFile: Constructor data must be an object');
    }

    this.data = data;
  }

  static create(data) {
    return new PHRFile(data instanceof PHRFile ? data.properties : data);
  }

  static createFromFile(file) {
    if (!(file instanceof File)) {
      throw new Error('PHRFile: Not a File');
    }

    const { type: mimeType, size, path: name } = file;

    const data = {
      path: URL.createObjectURL(file),
      mimeType,
      size,
      name,
      createdAt: moment().utc().format('YYYY-MM-DD'),
    };

    return new PHRFile(data);
  }

  static createDefaultAnnouncement(enterpriseName) {
    return PHRFile.create({
      path: enterpriseName === 'khadamati' ? khadamatiAnnouncementImg : palmhrAnnouncementImg,
      mimeType: enterpriseName === 'khadamati' ? 'image/png' : 'image/jpeg',
    });
  }

  get properties() {
    if (!this.data) return {};
    return _.cloneDeep(this.data);
  }

  get id() {
    return this.properties?.id ?? '';
  }

  get name() {
    return this.properties?.name ?? '';
  }

  get extension() {
    return nameExtension(this.name);
  }

  get relativePath() {
    return this.properties?.path ?? '';
  }

  get url() {
    return resourcePath(this.relativePath);
  }

  get sizeB() {
    return this.properties?.size ?? 0;
  }

  get sizeBFormatted() {
    return `${this.sizeB} B`;
  }

  get sizeKB() {
    return this.sizeB / 1024;
  }

  get sizeKBFormatted() {
    return `${numberUtil.format(this.sizeKB)} KB`;
  }

  get sizeMB() {
    return this.sizeKB / 1024;
  }

  get sizeMBFormatted() {
    return `${numberUtil.format(this.sizeMB)} MB`;
  }

  get mimeType() {
    return this.properties?.mimeType ?? '';
  }

  get isImage() {
    return mimeTypeIsImage(this.mimeType);
  }

  get isPdf() {
    return mimeTypeIsPdf(this.mimeType);
  }

  get description() {
    return this.properties?.description ?? '';
  }

  get createdAtUTCMoment() {
    const { createdAt } = this.properties;
    if (!createdAt) return null;
    return moment(createdAt).utc();
  }

  get createdAtLocalMoment() {
    return this.createdAtUTCMoment?.local();
  }

  get formattedLocalCreatedAt() {
    return this.createdAtLocalMoment?.format('YYYY-MM-DD') ?? '--';
  }

  get updatedAtUTCMoment() {
    const { updatedAt } = this.properties;
    if (!updatedAt) return null;
    return moment(updatedAt).utc();
  }

  get updatedAtLocalMoment() {
    return this.updatedAtUTCMoment?.local();
  }

  get formattedLocalUpdatedAt() {
    return this.updatedAtLocalMoment?.format('YYYY-MM-DD') ?? '--';
  }
}
