import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';

import DashboardApi from 'api/DashboardApi';
import EngagementCenterApi from 'api/EngagementCenterApi';
import ReleaseApi from 'api/ReleaseApi';
import TimeOffApi from 'api/TimeOffApi';
import TimesheetsApi from 'api/TimesheetsApi';
import { AuthContext } from 'auth/AuthContext';
import Modal from 'components/Modal';
import WhoIsOut from 'components/WhoIsOut/WhoIsOut';
import { MODULES_CONFIGS } from 'constants/moduleConfigurations';
import { useQueryEmployeePrivacySettings } from 'modules/CompanySettings/Team/hooks/useTeam';
import PalmHrAI from 'modules/Dashboard/components/PalmHrAI/PalmHrAI';
import editModalStyle from 'modules/Employee/Profile/Personal/editModals/editModalStyle';
import ReleaseModal from 'modules/EngagementCenter/Releases/ReleaseModal';
import { useModulePermissions } from 'shared/useModulePermissions';
import t from 'utility/setTranslation';

import Announcement from './Announcement/Announcement';
import Card from './components/Card';
import CardAdvanced from './components/CardAdvanced';
import CardCalendar from './components/CardCalendar';
import CardQuickAccess from './components/CardQuickAccess';
import CardTimeTracking from './components/CardTimeTracking';
import DashboardGettingStarted from './components/DashboardGettingStarted/DashboardGettingStarted';
import EventCalendarModal from './components/EventCalendarModal';
import WhoIsIn from './components/WhoIsIn/WhoIsIn';
import styles from './Dashboard.module.scss';
import DashboardContext from './DashboardContext';
import Header from './Header';
import ShiftWidget from './ShiftWidget/ShiftWidget';
import { useAccountSubscriptionData } from '../../topNavigation/hooks/useAccount';

const modalStyle = cloneDeep(editModalStyle);
modalStyle.content.width = '720px';

const Dashboard = () => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const isAdmin = authState.user.roles.includes('ROLE_ADMIN');

  const [shouldUpdateAttendance, setShouldUpdateAttendance] = useState(false);
  const [shouldUpdateEvents, setShouldUpdateEvents] = useState(false);
  const [workschedule, setWorkschedule] = useState({});
  const [ramadanWS, setRamadanWS] = useState(null);
  const [remoteWork, setRemoteWork] = useState([]);
  const [birthday, setBirthday] = useState([]);
  const [birthdayAdvanced, setBirthdayAdvanced] = useState([]);
  const [anniversarie, setAnniversarie] = useState([]);
  const [anniversarieAdvanced, setAnniversarieAdvanced] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventsAdvanced, setEventsAdvanced] = useState([]);
  const [vacation, setVacation] = useState({});
  const [loadingWS, setLoadingWS] = useState(true);
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [loadingVAC, setLoadingVAC] = useState(true);
  const [loadingRemoteWork, setLoadingRemoteWork] = useState(true);
  const [release, setRelease] = useState({});
  const [showReleaseModal, setShowReleaseModal] = useState(false);
  const [showWhoIsInModal, setShowWhoIsInModal] = useState(false);
  const [workType, setWorkType] = useState('');
  const [canSeeTimesheets, setCanSeeTimesheets] = useState(false);
  const [canSeeApproval, setCanSeeApproval] = useState(false);
  const [eventModal, setEventModal] = useState(false);
  const bodyElement = document.querySelector('body');

  const { isModuleEnabled } = useModulePermissions(MODULES_CONFIGS.AI.NAME, { isDataAlreadyCached: true });
  const { isLoading, data: employeePrivacySettings } = useQueryEmployeePrivacySettings();
  const { data: accountSubscriptionData } = useAccountSubscriptionData();

  const { enableWhoIsInPrivacy, enableWhoIsOutPrivacy, enableRemoteWorkAttendancePrivacy, enableEmployeePrivacy } =
    employeePrivacySettings?.data || {};

  const isFirstLogin = accountSubscriptionData?.data?.isFirstLogin ?? false;

  const getWorkschedule = () => {
    setLoadingWS(true);
    DashboardApi.getMyWorkschedule(moment(new Date()).format('YYYY-MM-DD'))
      .then((res) => {
        setWorkschedule(res.data);
        setRamadanWS(res.data.activeRamadanWorkSchedule);
        setLoadingWS(false);
      })
      .catch(() => {
        setLoadingWS(false);
      });
  };

  const getRemoteWork = (id) => {
    setLoadingRemoteWork(true);
    EngagementCenterApi.getRemoteWork(
      id,
      moment(new Date()).format('YYYY-MM-DD'),
      moment(new Date()).format('YYYY-MM-DD')
    )
      .then((res) => {
        setRemoteWork(res.data);
        setLoadingRemoteWork(false);
      })
      .catch(() => {
        setLoadingRemoteWork(false);
      });
  };

  let birthdaysPromis = null;
  const getBirthdays = () => {
    birthdaysPromis = DashboardApi.getBirthdays(moment(new Date()).format('YYYY-MM-DD'))
      .then((res) => {
        if (res?.data?.birthdays.length > 0) {
          res?.data?.birthdays.map((item) =>
            Object.assign(item, { type: 'birthday' }, { date: item?.about?.birthdate })
          );
        }
        setBirthday(res?.data?.birthdays);
      })
      .catch(() => {});
  };

  let anniversariesPromis = null;
  const getAnniversaries = () => {
    anniversariesPromis = DashboardApi.getAnniversaries(moment(new Date()).format('YYYY-MM-DD'))
      .then((res) => {
        if (res?.data?.anniversaries.length > 0) {
          res?.data?.anniversaries.map((item) =>
            Object.assign(item, { type: 'anniversary' }, { date: item?.employment?.hireDate })
          );
        }
        setAnniversarie(res?.data?.anniversaries);
      })
      .catch(() => {});
  };

  let eventsPromis = null;
  const getCompanyEvents = () => {
    eventsPromis = DashboardApi.getCompanyEvents(moment(new Date()).format('YYYY-MM-DD'))
      .then((res) => {
        if (res?.data?.events.length > 0) {
          res?.data?.events.map((item) => Object.assign(item, { type: 'customEvent' }, { date: item?.startDate }));
        }
        setEvents(res.data.events);
      })
      .catch(() => {});
  };

  let eventAdvancedPromis = null;
  const getEventsAdvanced = () => {
    eventAdvancedPromis = DashboardApi.getEventsAdvanced(moment(new Date()).add(1, 'days').format('YYYY-MM-DD'))
      .then((res) => {
        if (res?.data?.events.length > 0) {
          res?.data?.events.map((item) => Object.assign(item, { type: 'customEvent' }, { date: item?.startDate }));
        }
        setEventsAdvanced(res.data.events);
      })
      .catch(() => {});
  };

  let birthdaysAdvancedPromis = null;
  const getBirthdaysAdvanced = () => {
    birthdaysAdvancedPromis = DashboardApi.getBirthdaysAdvanced(moment(new Date()).add(1, 'days').format('YYYY-MM-DD'))
      .then((res) => {
        if (res?.data?.birthdays.length > 0) {
          res?.data?.birthdays.map((item) =>
            Object.assign(item, { type: 'birthday' }, { date: item?.birthdateMasked })
          );
        }

        setBirthdayAdvanced(res?.data?.birthdays);
      })
      .catch(() => {});
  };

  let anniversariesAdvancedPromis = null;
  const getAnniversariesAdvanced = () => {
    anniversariesAdvancedPromis = DashboardApi.getAnniversariesAdvanced(
      moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
    )
      .then((res) => {
        if (res?.data?.anniversaries.length > 0) {
          res?.data?.anniversaries.map((item) =>
            Object.assign(item, { type: 'anniversary' }, { date: item?.hireDate })
          );
        }
        setAnniversarieAdvanced(res?.data?.anniversaries);
      })
      .catch(() => {});
  };

  const getEvents = () => {
    getCompanyEvents();
    getEventsAdvanced();
    getBirthdays();
    getAnniversaries();
    getBirthdaysAdvanced();
    getAnniversariesAdvanced();
    setLoadingEvents(true);
    Promise.all([
      eventAdvancedPromis,
      eventsPromis,
      birthdaysPromis,
      anniversariesPromis,
      birthdaysAdvancedPromis,
      anniversariesAdvancedPromis,
    ])
      .then(() => {
        setLoadingEvents(false);
      })
      .catch(() => {
        setLoadingEvents(false);
      });
  };

  const getVacation = (id) => {
    setLoadingVAC(true);
    TimeOffApi.getVacationBalance(
      id,
      moment().startOf('year').format('YYYY-MM-DD'),
      moment().endOf('year').format('YYYY-MM-DD'),
      'vacation'
    )
      .then((res) => {
        setVacation(res.data.balance[moment().format('YYYY-MM-DD')]);
        setLoadingVAC(false);
      })
      .catch(() => {
        setLoadingVAC(false);
      });
  };

  const getLatestUnseenRelease = () => {
    ReleaseApi.getLastUnseed()
      .then((res) => {
        if (res.data && res.data.id) {
          setRelease(res.data);
          setShowReleaseModal(!(isFirstLogin ?? false));
        } else {
          setRelease({});
          setShowReleaseModal(false);
        }
      })
      .catch(() => {
        setRelease({});
        setShowReleaseModal(false);
      });
  };

  const markReleaseAsSeen = (id) => {
    ReleaseApi.releaseMarkAsSeen(id)
      .then(() => {
        setRelease({});
        setShowReleaseModal(false);
      })
      .catch(() => {
        setRelease({});
        setShowReleaseModal(false);
      });
  };

  const todayEvents = () => {
    const newArr = [...birthday, ...anniversarie, ...events];
    return sortBy(newArr, ['fullName']);
  };

  const upcomingEvents = () => {
    const newArr = [...birthdayAdvanced, ...anniversarieAdvanced, ...eventsAdvanced];

    return sortBy(newArr, [
      // eslint-disable-next-line func-names
      function (o) {
        return [moment(o.date).format('MM/DD'), o.fullName];
      },
    ]);
  };

  const onGetStarted = () => {
    const id = authState.user.identification;
    getWorkschedule();
    getVacation(id);
    getRemoteWork(id);
    getEvents();
  };

  useEffect(() => {
    TimesheetsApi.checkTimesheetAccess()
      .then((res) => {
        setCanSeeTimesheets(res.data.canAccess.timesheets);
        setCanSeeApproval(res.data.canAccess.approval);
      })
      .catch(() => {});
    onGetStarted();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    bodyElement.classList.add('no-scroll');
    return () => {
      bodyElement.classList.remove('no-scroll');
      setRamadanWS(null);
      setWorkschedule({});
    };
  }, []);

  useEffect(() => {
    getLatestUnseenRelease();
  }, [isFirstLogin]);

  const onOpenCalendar = () => {
    setEventModal(true);
  };

  const onSetQuickAccessType = (type) => {
    setShowWhoIsInModal(true);
    setWorkType(type);
  };

  const onCloseQuickAccessModal = () => {
    setShowWhoIsInModal(false);
    setWorkType('');
  };

  const onCloseEventModal = () => {
    setEventModal(false);
  };

  const onCloseReleaseModal = () => {
    setShowReleaseModal(false);
    if (release?.id) {
      markReleaseAsSeen(release.id);
    }
  };

  useEffect(() => {
    if (shouldUpdateEvents) {
      getEvents();
      setShouldUpdateEvents(false);
    }
  }, [shouldUpdateEvents]);

  const isShowWhoIsOut = !isLoading && enableEmployeePrivacy ? isAdmin || (!isLoading && enableWhoIsOutPrivacy) : true;
  const isShowWhoIsIn = !isLoading && enableEmployeePrivacy ? isAdmin || (!isLoading && enableWhoIsInPrivacy) : true;
  const isShowRemoteWork =
    !isLoading && enableEmployeePrivacy ? isAdmin || (!isLoading && enableRemoteWorkAttendancePrivacy) : true;

  return (
    <DashboardContext.Provider
      value={{
        setShouldUpdateAttendance,
        shouldUpdateAttendance,
        onOpenCalendar,
        shouldUpdateEvents,
        setShouldUpdateEvents,
      }}
    >
      <div data-testid='dashboard-content' className={cx(styles.wrapper, 'flex-1 pageContainer')}>
        <Header />
        <div className='d-flex w-100 gap-2 mt-2 flex-1'>
          <div className='flex-1'>
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              hideTracksWhenNotNeeded
              style={{ height: '100%' }}
              className='scrollbarsWrapper'
            >
              <div className='pt-2 px-2a pb-3'>
                <CardQuickAccess
                  className='mb-3'
                  onClick={(type) => {
                    onSetQuickAccessType(type);
                  }}
                  isShowWhoIsIn={isShowWhoIsIn}
                  isShown={remoteWork?.isRemoteWorkActive}
                  canSeeApproval={canSeeApproval}
                  canSeeTimesheets={canSeeTimesheets}
                />

                <>
                  {isShowWhoIsOut && <WhoIsOut className={'bg-white mb-3'} />}

                  {remoteWork?.isRemoteWorkActive && isShowRemoteWork && (
                    <Card
                      type='remote'
                      className='mb-3'
                      title={t('GENERAL.REMOTE_WORK')}
                      contentIcon='icon-request-remote'
                      hasLink={false}
                      contentData={remoteWork?.employees || []}
                      loading={loadingRemoteWork}
                    />
                  )}

                  <CardAdvanced
                    title={t('GENERAL.EVENTS')}
                    todayData={todayEvents()}
                    upcomingData={upcomingEvents()}
                    type='events'
                    loading={loadingEvents}
                    onClick={onOpenCalendar}
                  />
                </>
              </div>
            </Scrollbars>
          </div>

          <div className='w-50'>
            <Announcement />
          </div>

          <div className='flex-1'>
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              hideTracksWhenNotNeeded
              style={{ height: '100%' }}
              className='scrollbarsWrapper'
            >
              <div className='pt-2 px-2a pb-3'>
                {isModuleEnabled && <PalmHrAI />}
                <CardTimeTracking loadingWS={loadingWS} workschedule={workschedule} ramadanWS={ramadanWS} />

                <ShiftWidget />

                <Card
                  type='vacation'
                  className='pt-2 mb-3'
                  title={t('EC.REQUESTS.VACATION')}
                  contentIcon='icon-request-vacation'
                  link='/time/leave/vacation'
                  hasLink
                  contentData={vacation || {}}
                  loading={loadingVAC}
                />
                <CardCalendar employeeData={user} />
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>

      <Modal
        styles={{ width: '720px' }}
        isOpen={showReleaseModal}
        showHeader={false}
        onRequestClose={onCloseReleaseModal}
      >
        <ReleaseModal close={onCloseReleaseModal} release={release} />
      </Modal>

      <Modal
        onRequestClose={() => {
          setShowWhoIsInModal(false);
        }}
        isOpen={showWhoIsInModal}
        showHeader={false}
        styles={{ width: '480px', padding: '0', overflow: 'none' }}
      >
        <WhoIsIn
          onClose={() => {
            onCloseQuickAccessModal();
          }}
          workType={workType === 'remoteWork'}
        />
      </Modal>

      <Modal
        onRequestClose={onCloseEventModal}
        // isOpen={location.pathname === `${path}/event-calendar`}
        isOpen={eventModal}
        showHeader={false}
        styles={{ width: '720px', height: '670px', padding: '0', overflow: 'none' }}
      >
        <EventCalendarModal onClose={onCloseEventModal} />
      </Modal>
      <DashboardGettingStarted />
    </DashboardContext.Provider>
  );
};

export default Dashboard;
