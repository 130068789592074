const OFFSET = 14;
const RIGHT_POSITIONS = ['right', 'rightTop', 'rightBottom'];
const LEFT_POSITIONS = ['left', 'leftTop', 'leftBottom'];
const TOP_POSITIONS = ['top', 'topLeft', 'topRight'];
const BOTTOM_POSITIONS = ['bottom', 'bottomLeft', 'bottomRight'];

// eslint-disable-next-line import/prefer-default-export
export const tooltipPositions = {
  OFFSET,
  RIGHT_POSITIONS,
  LEFT_POSITIONS,
  TOP_POSITIONS,
  BOTTOM_POSITIONS,
};
