import React, { useContext } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { AuthContext } from 'auth/AuthContext';
import HeaderTitle from 'components/HeaderTitle/HeaderTitle';
import NavBar from 'components/Navigation/NavBar';
import t from 'utility/setTranslation';

const Header = ({ active }) => {
  const { authState } = useContext(AuthContext);

  const navItems = [{ title: 'my_tasks', href: '/tasks/my_tasks' }];
  if (authState.user.roles.includes('ROLE_ADMIN')) {
    navItems.push({
      title: 'all_tasks',
      href: '/tasks/all_tasks',
    });
  }

  return (
    <div className='pt-2'>
      <div className={cx('position-relative', 'pb-3')}>
        <div className='d-flex justify-content-between align-items-center pt-3'>
          <HeaderTitle className='mb-4 mt-1'>{t('GENERAL.TASKS')}</HeaderTitle>
        </div>
        <NavBar active={active} activeHOC items={navItems} type='link' />
      </div>
    </div>
  );
};

Header.propTypes = {
  active: PropTypes.string.isRequired,
};

export default Header;
