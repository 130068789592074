import React from 'react';

import { PHRList } from '@palmhr/palmhr-ui-lib';
import cx from 'classnames';

import ResourceCenterItem from './ResourceCenterItem';
import ContentHeader from '../../../../../components/ContentHeader';
import t from '../../../../../utility/setTranslation';

const ResourceCenterRenderList = ({ items, activeLang }) => (
  <>
    <ContentHeader>
      <h5 className={cx('fw-bold m-0 text-main', activeLang === 'en' ? 'text-end' : 'text-start')}>
        {t('GENERAL.RESOURCE_CENTER')}
      </h5>
    </ContentHeader>
    <PHRList
      items={items}
      renderItem={(item) => <ResourceCenterItem item={item} activeLang={activeLang} />}
      className='list-unstyled bg-white pt-4 pb-2'
    />
  </>
);

export default ResourceCenterRenderList;
