import PropTypes from "prop-types";
import Department from "services/Department/Department";
import _ from "lodash";

export const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    isNullable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    showLabel: PropTypes.bool,
    value: PropTypes.oneOf(PropTypes.instanceOf(Department), PropTypes.object),
    onChange: PropTypes.func
}

export const defaultProps = {
    name: 'department',
    label: 'department',
    placeholder: 'department',
    className: '',
    isNullable: true,
    isDisabled: false,
    isRequired: false,
    showLabel: true,
    value: null,
    onChange: null,
}

export const formatData = (data) => Department.create(data ?? {});

export const formatDisplay = (display) => display ? _.capitalize(display) : '';