import http from './Api';

class CompanySettingsTasksApi {
  static getTasksPriorities(filters = '') {
    return http.get(`/settings/tasks/fields/priorities${filters}`);
  }

  static addTaskPriority(data) {
    return http.post('/settings/tasks/fields/priorities', data);
  }

  static deleteTaskPriority(id) {
    return http.delete(`/settings/tasks/fields/priorities/${id}`);
  }

  static editTaskPriority(id, data) {
    return http.put(`/settings/tasks/fields/priorities/${id}`, data);
  }

  static getTasksPriorityById(id) {
    return http.get(`/settings/tasks/fields/priorities/${id}`);
  }

  /* STATUS */
  static getTasksStatuses(filters = '') {
    return http.get(`/task/settings/statuses${filters}`);
  }

  static addTaskStatus(data) {
    return http.post('/task/settings/statuses', data);
  }

  static deleteTaskStatus(id) {
    return http.delete(`/task/settings/statuses/${id}`);
  }

  static editTaskStatus(id, data) {
    return http.put(`/task/settings/statuses/${id}`, data);
  }

  static getTasksStatusById(id) {
    return http.get(`/task/settings/statuses/${id}`);
  }

  /* Configuration */
  static getTasksSettings(params) {
    return http.get(`/settings/tasks/settings${params}`);
  }

  static editTaskSettings(data) {
    return http.put('/settings/tasks/settings', data);
  }
}

export default CompanySettingsTasksApi;
