import React from 'react';

import cx from 'classnames';

import styles from '../../ResourceCenter.module.scss';

const QuickstartChecklistItem = ({ title, isCompleted, onClick, activeLang }) => (
  <div
    className={cx(
      'd-flex justify-content-between border-btm py-3 cursor-pointer',
      activeLang === 'en' ? 'flex-row-reverse' : 'flex-row'
    )}
    onClick={onClick}
  >
    <div
      className={cx('d-flex gap-2 align-items-center', activeLang === 'en' ? 'flex-row-reverse' : 'flex-row')}
      style={{ width: '265px' }}
    >
      <i
        className={cx(
          'font-size-24',
          isCompleted ? 'icon-circle-checked-fill' : 'icon-tasks-2',
          isCompleted ? 'text-primary' : styles.taskIcon
        )}
      />
      <h6 className='font-size-14 m-0 text-secondary'>{title}</h6>
    </div>
    <span
      className={cx(
        'd-flex justify-content-center align-items-center text-center text-gray-500',
        activeLang === 'en' ? 'icon-arrow-left' : 'icon-arrow-right'
      )}
    />
  </div>
);

export default QuickstartChecklistItem;
