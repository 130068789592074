import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Tooltip from 'components/tooltip/Tooltip';

import styles from './SwitchInputExtended.module.scss';

const SwitchInputExtended = ({
  className,
  name,
  checked,
  title,
  label,
  onChange,
  onBlur,
  labelBullet,
  size,
  disabled,
  requiredNoteText,
  isShowRequiredNote,
}) => {
  const onBlurField = () => {
    if (onBlur) {
      onBlur(name);
    }
  };
  return (
    <div className={cx(className, styles.wrapper, 'd-flex justify-content-between align-items-center w-100')}>
      <div className='flex-1'>
        {title && <h5 className={cx(styles.title, { [styles.checked]: checked }, `me-3 mb-1`)}>{title}</h5>}
        {label && (
          <p className={cx(styles.labelBox, styles.label, { [styles.checked]: checked }, `me-3 mb-1`)}>{label}</p>
        )}

        {labelBullet && labelBullet.length > 0 && (
          <ul className={cx(styles.labelBullet, styles.label, { [styles.checked]: checked }, `mx-3 mb-1 no-style`)}>
            {labelBullet.map((el) => (
              <li key={el}>{el}</li>
            ))}
          </ul>
        )}
      </div>

      {isShowRequiredNote ? (
        <Tooltip position='top' label={requiredNoteText}>
          <section
            className={cx(
              styles.requiredNote,
              'rounded-circle full-border d-flex justify-content-center align-items-center box-content p-1 cursor-default'
            )}
          >
            <i className='icon-info text-primary' />
          </section>
        </Tooltip>
      ) : (
        <label className={cx(styles.switch, styles[size])}>
          <input
            disabled={disabled}
            name={name}
            type='checkbox'
            value='1'
            checked={checked ? 'checked' : ''}
            onChange={(event) => {
              onChange(event.target.checked);
            }}
            onBlur={onBlurField}
          />
          <span className={cx(styles.slider, styles.round)} />
        </label>
      )}
    </div>
  );
};

SwitchInputExtended.propTypes = {
  checked: PropTypes.bool,
  title: PropTypes.string,
  label: PropTypes.string,
  labelBullet: PropTypes.array,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
};

SwitchInputExtended.defaultProps = {
  checked: false,
  title: '',
  label: '',
  labelBullet: [],
  onChange: undefined,
  onBlur: undefined,
  className: '',
  size: 'md',
  disabled: false,
};

export default SwitchInputExtended;
