import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import t from 'utility/setTranslation';

import FieldWrapper from './FieldWrapper';
import styles from './Radio.module.scss';

const Radio = ({
  name,
  value,
  label,
  checked,
  onChange,
  className,
  onBlur,
  disableTranslation,
  isDisabled,
  error,
  id,
  ariaLabel,
  disableAutoScroll,
}) => {
  const onBlurField = () => {
    if (onBlur) {
      onBlur(name);
    }
  };
  return (
    <FieldWrapper name={name} className={className}>
      <input
        className={cx(styles.inputField, { [styles.disableAutoScroll]: disableAutoScroll })}
        id={id || value}
        type='radio'
        name={name}
        aria-label={ariaLabel}
        value={value}
        checked={checked}
        onChange={onChange}
        onBlur={onBlurField}
        disabled={isDisabled}
      />
      <label
        className={cx(styles.label, { [styles.error]: !!error }, 'd-flex', 'align-items-center')}
        htmlFor={id || value}
      >
        {disableTranslation ? label : t(label)}
      </label>
    </FieldWrapper>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  disableTranslation: PropTypes.bool,
  isDisabled: PropTypes.bool,
  id: PropTypes.string,
  error: PropTypes.string,
  ariaLabel: PropTypes.string,
  disableAutoScroll: PropTypes.bool,
};

Radio.defaultProps = {
  checked: undefined,
  onChange: undefined,
  onBlur: undefined,
  className: '',
  disableTranslation: false,
  label: '',
  isDisabled: false,
  disableAutoScroll: false,
  id: '',
  error: '',
  ariaLabel: '',
};

export default Radio;
