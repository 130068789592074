import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import useLanguage from 'shared/useLanguage';

import WizardFooter from './WizardFooter';
import t from '../../utility/setTranslation';
import ContentHeader from '../ContentHeader';

const PHRGettingStartedWizard = ({ generateWizardSteps, onNext, onClose, className }) => {
  const language = useLanguage();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState('');
  const [loading, setLoading] = useState(true);

  const steps = generateWizardSteps();
  const { imageEN, imageAR } = steps[currentStepIndex];
  const image = language === 'ar' ? imageAR : imageEN;

  const isFirstStep = currentStepIndex === 0;
  const isLastStep = currentStepIndex === steps.length - 1;

  useEffect(() => {
    const fetchImage = (src) => {
      const loadingImage = new Image();
      loadingImage.src = src;
      loadingImage.onload = () => {
        setCurrentImage(src);
        setLoading(false);
      };
    };

    fetchImage(image);
  }, [image]);

  const handleNext = () => {
    setLoading(true);
    if (currentStepIndex < steps.length - 1) {
      setCurrentStepIndex((prevIndex) => prevIndex + 1);
    } else {
      if (isLastStep) {
        onClose();
      }
      if (onNext) {
        onNext();
      }
    }
  };

  const handlePrev = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <>
      <ContentHeader className={'mx-5'}>
        <div className='d-flex justify-content-between'>
          <h5 className='fw-bold m-0 text-main'>{t('GENERAL.GET_STARTED')}</h5>
        </div>
      </ContentHeader>
      <div className={cx('d-flex flex-column align-items-center justify-content-center', className)}>
        <section
          className='d-flex align-items-center justify-content-center my-4'
          style={{ width: '960px', height: '600px', backgroundColor: '#FFFFFF', position: 'relative' }}
        >
          <img
            src={currentImage}
            alt={`Step ${currentStepIndex + 1}`}
            style={{
              width: '960px',
              maxHeight: '600px',
              filter: `${loading ? 'blur(10px)' : ''}`,
              transition: '1s filter ease-in-out',
            }}
            loading='lazy'
            onLoad={() => setLoading(false)}
          />
        </section>
        <WizardFooter
          className='mt-auto'
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          handlePrev={handlePrev}
          currentStepIndex={currentStepIndex}
          stepsState={steps}
          handleNext={handleNext}
          nextButtonText={isLastStep ? 'FINISH' : 'Next'}
          disableNext={loading}
        />
      </div>
    </>
  );
};

export default PHRGettingStartedWizard;
