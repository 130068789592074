import http from './Api';

class CommentApi {
  static addComment(thread, data) {
    return http.post(`/threads/${thread}/comments`, data);
  }

  static editComment(thread, comment, data) {
    return http.put(`/threads/${thread}/comments/${comment}`, data);
  }

  static deleteComment(thread, comment) {
    return http.delete(`/threads/${thread}/comments/${comment}`);
  }

  static getComments(thread, filters) {
    return http.get(`/threads/${thread}/comments${filters}`);
  }

  static getWatchers(id, type, filters = '') {
    return http.get(`/participants/${type}/${id}${filters}`);
  }
}

export default CommentApi;
