import React, { useState, useEffect, Suspense, lazy } from 'react';

import cx from 'classnames';
import { Navigate, Route, Routes } from 'react-router-dom';

import CompanySettingsApi from 'api/CompanySettingsApi';
import ShiftsApi from 'api/ShiftsApi';
import AreaLoader from 'components/Spinner/AreaLoader';
import { MODULES_CONFIGS } from 'constants/moduleConfigurations';
import { useQueryBillableHoursAccess } from 'modules/Time/BillableTime/hooks/useBillableTime';
import TimeContext from 'modules/Time/TimeContext';
import { useModulePermissions } from 'shared/useModulePermissions';

import Header from './Header';
import styles from './Time.module.scss';
import TimeGettingStarted from './TimeGettingStarted/TimeGettingStarted';
import TimesheetsApi from '../../api/TimesheetsApi';
import CustomRoute from '../../topNavigation/Router/CustomRoute';

const Leave = lazy(() => import('./Leave/Leave'));
const Shift = lazy(() => import('modules/Time/Shift/Shift'));
const Calendar = lazy(() => import('./Calendar/Calendar'));
const BillableTime = lazy(() => import('./BillableTime/BillableTime'));
const Insights = lazy(() => import('./Insights/Insights'));
const Timesheets = lazy(() => import('./Timesheets/Timesheets'));

const Time = () => {
  const { isModuleEnabled } = useModulePermissions(MODULES_CONFIGS.BILLABLE_HOURS.NAME);
  const [canSeeTimesheets, setCanSeeTimesheets] = useState(false);
  const [canSeeApproval, setCanSeeApproval] = useState(false);
  const [shiftPermissions, setShiftPermissions] = useState(null);
  const [weekStart, setWeekStart] = useState('sunday');
  const path = '/time';

  const { data } = useQueryBillableHoursAccess({ requestsManage: true, approvalsManage: true });
  const { canManageRequests, canManageApprovals } = data?.data || {};
  const canViewBillableTime = isModuleEnabled && (canManageRequests || canManageApprovals);

  useEffect(() => {
    TimesheetsApi.checkTimesheetAccess()
      .then((res) => {
        setCanSeeTimesheets(res.data.canAccess.timesheets);
        setCanSeeApproval(res.data.canAccess.approval);
      })
      .catch(() => {});

    ShiftsApi.shiftsAccess()
      .then((res) => {
        setShiftPermissions(res?.data?.access);
      })
      .catch(() => {});

    CompanySettingsApi.getStartDayOfWeek()
      .then((res) => {
        setWeekStart(res?.data?.weekStart);
      })
      .catch(() => {});
  }, []);

  const onSelectView = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <TimeContext.Provider
      value={{
        shiftPermissions,
        weekStart,
      }}
    >
      <div className={cx(styles.wrapper, 'pageContainer px-1 position-relative')}>
        <Header
          canViewBillableTime={canViewBillableTime}
          canSeeTimesheets={canSeeTimesheets}
          canSeeApproval={canSeeApproval}
          onSelectView={onSelectView}
          shiftPermissions={shiftPermissions}
        />
        <div className='page-content d-flex flex-column flex-1'>
          <Routes>
            <Route
              path='leave/*'
              element={
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path={`${path}/leave`} component={Leave} canSeeDetails canOffboard />
                </Suspense>
              }
            />

            {canViewBillableTime && (
              <Route
                path='billable-time/*'
                element={
                  <Suspense fallback={<AreaLoader />}>
                    <CustomRoute path={`${path}/billable-time`} component={BillableTime} />
                  </Suspense>
                }
              />
            )}

            <Route
              path='calendar'
              element={
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path={`${path}/calendar`} component={Calendar} />
                </Suspense>
              }
            />

            <Route
              path='timesheets/*'
              element={
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path={`${path}/timesheets`} component={Timesheets} />
                </Suspense>
              }
            />

            <Route
              path='approval/*'
              element={
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path={`${path}/approval`} component={Timesheets} isApproval />
                </Suspense>
              }
            />

            <Route
              path='shift-schedule'
              element={
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path={`${path}/shift-schedule`} component={Shift} />
                </Suspense>
              }
            />

            <Route
              path='insights/*'
              element={
                <Suspense fallback={<AreaLoader />}>
                  <CustomRoute path={`${path}/insights`} component={Insights} />
                </Suspense>
              }
            />

            <Route path='' element={<Navigate to='leave' replace />} />
          </Routes>
        </div>
        <TimeGettingStarted />
      </div>
    </TimeContext.Provider>
  );
};

export default Time;
