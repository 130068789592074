import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import CompanyAccessApi from 'api/CompanyAccessApi';
import AlertMessage from 'components/AlertMessage';
import IconButton from 'components/buttons/IconButton/IconButton';
import ContentHeader from 'components/ContentHeader';
import NavTab from 'components/Navigation/NavTab';
import SpinnerRing from 'components/Spinner/SpinnerRing';
import SuccessCheckmark from 'components/SuccessCheckmark/SuccessCheckmark';
import { useQueryEmployeePrivacySettings } from 'modules/CompanySettings/Team/hooks/useTeam';
import SettingsPersonalData from 'modules/Employee/shared/SettingsPersonalData';
import useResolveHandler from 'shared/useResolveHandler';
import t from 'utility/setTranslation';

import ChangePassword from './ChangePasword';
import SettingsAuth from './SettingsAuth';
import SettingsNotifications from './SettingsNotifications';

const SettingsModal = ({ onRequestClose, employeeId, setIsFormDirty, discardChanges, onClose }) => {
  const { isLoading, data: employeePrivacySettings } = useQueryEmployeePrivacySettings();
  const [activeView, setActiveView] = useState('password');
  const [alertBoxMsg, setAlertBoxMsg] = useState('');
  const [sending, setSending] = useState(false);
  const { responseStatus, setStatus } = useResolveHandler();
  const [twoFactorConfig, setTwoFactorConfig] = useState({});

  const { enableEmployeePrivacy } = employeePrivacySettings?.data || {};

  const selectHandler = (view) => {
    setActiveView(view);
  };

  useEffect(() => {
    setSending(true);
    CompanyAccessApi.getTwoFactorConfig()
      .then((res) => {
        setTwoFactorConfig(res.data);
        setSending(false);
      })
      .catch(() => {
        setSending(false);
      });
  }, []);

  const onCloseToast = () => {
    setAlertBoxMsg('');
  };

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
      className='d-flex flex-column'
      style={{ height: activeView === 'personal data' ? 'calc(100vh - 4rem)' : 'auto', maxHeight: '800px' }}
    >
      {alertBoxMsg && <AlertMessage text={alertBoxMsg} type='info' callback={onCloseToast} />}
      {responseStatus?.resolved && <SuccessCheckmark text={responseStatus.msg} isFailed={responseStatus.isFailed} />}
      {(sending || isLoading) && <SpinnerRing />}
      <ContentHeader>
        <div className='d-flex mx-5 justify-content-between align-items-center'>
          <h5 className='fw-bold m-0'>{t('GENERAL.SETTINGS')}</h5>

          <IconButton icon='icon-cross-x' onClick={onRequestClose} size='md' color='gray' />
        </div>
      </ContentHeader>

      <div className='flex-1 d-flex flex-column pb-4'>
        <div className='mb-5 mt-4 mx-5'>
          <NavTab text='password' onClick={selectHandler} active={activeView === 'password'} />
          {twoFactorConfig?.enableTwoFactor && (
            <NavTab text='authentication' onClick={selectHandler} active={activeView === 'authentication'} />
          )}
          <NavTab text='notifications' onClick={selectHandler} active={activeView === 'notifications'} />
          {enableEmployeePrivacy && (
            <NavTab text='personal data' onClick={selectHandler} active={activeView === 'personal data'} />
          )}
        </div>

        {activeView === 'password' && (
          <ChangePassword
            setIsFormDirty={setIsFormDirty}
            setSending={(data) => {
              setSending(data);
            }}
            setSaved={(data) => {
              setStatus(data);
            }}
            setAlertBoxMsg={(text) => {
              setAlertBoxMsg(text);
            }}
          />
        )}

        {activeView === 'authentication' && (
          <SettingsAuth
            configData={twoFactorConfig}
            setIsFormDirty={setIsFormDirty}
            setSending={(data) => {
              setSending(data);
            }}
            setSaved={(data) => {
              setStatus(data);
            }}
            setAlertBoxMsg={(text) => {
              setAlertBoxMsg(text);
            }}
            employeeId={employeeId}
          />
        )}

        {activeView === 'notifications' && (
          <SettingsNotifications
            setIsFormDirty={setIsFormDirty}
            setSending={(data) => {
              setSending(data);
            }}
            setSaved={setStatus}
            employeeId={employeeId}
            close={discardChanges}
          />
        )}

        {activeView === 'personal data' && enableEmployeePrivacy && (
          <SettingsPersonalData setIsFormDirty={setIsFormDirty} employeeId={employeeId} onClose={onClose} />
        )}
      </div>
    </div>
  );
};

SettingsModal.propTypes = {
  onRequestClose: PropTypes.func,
};

SettingsModal.defaultProps = { onRequestClose: undefined };

export default SettingsModal;
