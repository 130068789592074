import React from 'react';

import cx from 'classnames';

import Circle from 'modules/EngagementCenter/shared/loaders/Circle';
import Line from 'modules/EngagementCenter/shared/loaders/Line';

import styles from './Skeleton.module.scss';

const SkeletonAnnouncement = ({ className }) => (
  <div className={cx(styles.cardWrapper, className)}>
    <div className='d-flex justify-content-between align-items-center'>
      <div className='d-flex justify-content-start align-items-center'>
        <Circle gradient={styles.gradient} size={32} className='me-2' />

        <Line style={{ height: 32, width: 100, borderRadius: 8 }} gradient={styles.gradient} />
      </div>

      <Circle gradient={styles.gradient} size={16} />
    </div>
    <div className='py-3'>
      <div className=''>
        <Line
          style={{
            height: 16,
            width: 250,
            borderRadius: 8,
          }}
          gradient={styles.gradient}
          className='mb-3'
        />

        <Line
          style={{
            height: 84,
            width: '100%',
            borderRadius: 8,
          }}
          gradient={styles.gradient}
          className='mb-4'
        />

        <Line
          style={{
            paddingTop: '56.25%',
            width: '100%',
            borderRadius: 8,
          }}
          gradient={styles.gradient}
        />
      </div>
    </div>
  </div>
);

export default SkeletonAnnouncement;
