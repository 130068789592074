/* eslint-disable no-cond-assign */
import findEmailInText from './findEmailInText';
import findLinkInText from './findLinkInText';

const findEmployeeInText = (text) => {
  const regex = /@mention\((\d+)\)\[.+?\]/gm;
  const regexName = /\[(.*?)\]/;
  let result = findLinkInText(text);
  result = findEmailInText(result);
  const fr = [];
  let m;

  while ((m = regex.exec(text)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex += 1;
    }

    fr.push(m);
  }

  if (fr && fr.length > 0) {
    fr.forEach((el) => {
      if (el) {
        const obj = el[0];
        const id = el[1];

        const name = regexName.exec(obj)[1];

        const a = `<a href=/profile/${id}/personal>${name}</a>`;

        result = result.replace(obj, a);
      }
    });
  }

  return result;
};

export default findEmployeeInText;
