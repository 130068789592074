import React, { useState, useEffect, Suspense, lazy } from 'react';

import cx from 'classnames';
import { Navigate, Route, Routes } from 'react-router-dom';

import GosiApi from 'api/GosiApi';
import AreaLoader from 'components/Spinner/AreaLoader';
import CustomRoute from 'topNavigation/Router/CustomRoute';

import Header from './Header';
import styles from './Organization.module.scss';
import CompanySettingsApi from '../../../api/CompanySettingsApi';
import TeamGettingStarted from '../TeamGettingStarted/TeamGettingStarted';

const People = lazy(() => import('./People/People'));
const OrgChart = lazy(() => import('./OrgChart/OrgChart'));
const Hiring = lazy(() => import('./Hiring/Hiring'));
const Insights = lazy(() => import('./Insights/Insights'));
const Offboarding = lazy(() => import('./Offboarding/Offboarding'));
const GosiOverview = lazy(() => import('modules/Employee/Organization/Gosi/GosiOverview'));

const Organization = () => {
  const [formData, setFormData] = useState({});
  const [totalUsers, setTotalUsers] = useState({
    counter: { 0: {}, 1: {}, 2: {} },
  });
  const [hasGosiIntegration, setHasGosiIntegration] = useState(false);
  const bodyElement = document.querySelector('body');
  const path = '/organization';

  useEffect(() => {
    CompanySettingsApi.getCompanySettingsData()
      .then((res) => {
        setFormData(res.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    CompanySettingsApi.getTotalUsers()
      .then((res) => {
        setTotalUsers(res.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    GosiApi.gosiIntegrationHasActive()
      .then((res) => {
        setHasGosiIntegration(res.data.hasActiveIntegrations);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    bodyElement.classList.add('no-scroll');
    return () => {
      bodyElement.classList.remove('no-scroll');
    };
  }, []);

  return (
    <div className={cx(styles.wrapper, 'd-flex flex-column flex-1')}>
      <Header hasGosiIntegration={hasGosiIntegration} formData={formData} />

      <Routes>
        <Route
          path='people'
          element={
            <Suspense fallback={<AreaLoader />}>
              <CustomRoute path={`${path}/people`} component={People} canSeeDetails canOffboard />
            </Suspense>
          }
        />
        <Route
          path='org_chart'
          element={
            <Suspense fallback={<AreaLoader />}>
              <CustomRoute
                path={`${path}/org_chart`}
                component={OrgChart}
                companyData={formData}
                totalUsers={totalUsers}
              />
            </Suspense>
          }
        />
        <Route
          path='onboarding'
          element={
            <Suspense fallback={<AreaLoader />}>
              <CustomRoute path={`${path}/onboarding`} component={Hiring} totalUsers={totalUsers} />
            </Suspense>
          }
        />
        <Route
          path='offboarding'
          element={
            <Suspense fallback={<AreaLoader />}>
              <CustomRoute path={`${path}/offboarding`} component={Offboarding} />
            </Suspense>
          }
        />
        <Route
          path='insights/*'
          element={
            <Suspense fallback={<AreaLoader />}>
              <CustomRoute path={`${path}/insights`} component={Insights} canSeeInsights />
            </Suspense>
          }
        />
        <Route
          path='gosi'
          element={
            <Suspense fallback={<AreaLoader />}>
              <CustomRoute path={`${path}/gosi`} component={GosiOverview} />
            </Suspense>
          }
        />

        <Route path='' element={<Navigate to='people' replace />} />
      </Routes>
      <TeamGettingStarted />
    </div>
  );
};

export default Organization;
