import React, { useContext } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { AuthContext } from 'auth/AuthContext';
import IconButton from 'components/buttons/IconButton/IconButton';
import Personas from 'components/Personas';
import ToolBar from 'components/PHRUI/ToolBar/ToolBar';
import AuthState from 'services/Auth/AuthState';
import Employee from 'services/Employee/Employee';
import t from 'utility/setTranslation';

import style from './style.module.scss';

const LeaveType = ({ employee }) => {
  const { isOnFullRemoteDay, isOnFullBusinessTripDay } = employee;

  let text = t('GENERAL.PRESENT.ON_SITE');
  let icon = 'icon-case';

  if (isOnFullRemoteDay) {
    text = t('GENERAL.REMOTE_WORK');
    icon = 'icon-request-remote';
  }

  if (isOnFullBusinessTripDay) {
    text = t('EC.REQUESTS.BUSINESS_TRIP');
    icon = 'icon-request-business';
  }

  return (
    <ToolBar fullWidth={false}>
      <div className={style.icon}>
        <i className={`${icon} color-time-request`} />
      </div>
      <span className={'ms-2'}>{text}</span>
    </ToolBar>
  );
};

const WhoIsInTableRow = ({ onClick, type, ...rest }) => {
  const { authState } = useContext(AuthContext);
  const authDTO = AuthState.create(authState);
  const employeeDTO = Employee.create(rest);
  const id = employeeDTO.getId();

  const onRowClick = () => {
    if (!onClick) return;
    onClick(id);
  };

  return (
    <tr className={cx(style.row, 'border-btm')} key={id}>
      <td className={'p-3'}>
        <Personas size={32} user={employeeDTO.getData()} showDetails />
      </td>
      <td className={'p-3 d-flex align-items-center justify-content-between'}>
        <LeaveType employee={employeeDTO} />
        {authDTO.hasAccessToEmployeeAttendance(employeeDTO) && (
          <IconButton
            icon='icon-eye'
            type='button'
            color='secondary'
            btnStyle='outlined'
            size='md'
            showBorder
            onClick={onRowClick}
            lassName={style.action}
          />
        )}
      </td>
    </tr>
  );
};

WhoIsInTableRow.propTypes = {
  onClick: PropTypes.func,
};

WhoIsInTableRow.defaultProps = {
  onClick: null,
};

export default WhoIsInTableRow;
