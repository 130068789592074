import React, { useState, useEffect } from 'react';

import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import TextInput from 'components/forms/components/TextInput';
import t from 'utility/setTranslation';

import styles from './GoLiveSubdomainInput.module.scss';
import { useCheckIsDomainAvailable } from '../../../../../../hooks/useAccount';
import { setDomainTested, updateDomainAvailability } from '../../utility';

const GoLiveSubdomainInput = ({ formik }) => {
  const [subdomain, setSubdomain] = useState('');
  const [availabilityMessage, setAvailabilityMessage] = useState('');

  const { mutate: checkDomain, isLoading } = useCheckIsDomainAvailable();

  useEffect(() => {
    setDomainTested(false);
    updateDomainAvailability(false);
    setAvailabilityMessage('');
  }, [subdomain]);

  const handleSubdomainChange = (e) => {
    const formattedValue = e.target.value.toLowerCase().replace(/\s+/g, '-');

    setSubdomain(formattedValue);
    formik.setFieldValue('subdomain', formattedValue);
    formik.setFieldError('subdomain', '');
  };

  const handleCheckDomain = () => {
    const arabicRegex = /[\u0600-\u06FF]/;

    if (arabicRegex.test(subdomain)) {
      setAvailabilityMessage('');
      return;
    }

    checkDomain(subdomain, {
      onSuccess: (response) => {
        const isAvailable = !response?.data?.inUse;
        setDomainTested(true);
        updateDomainAvailability(isAvailable);

        if (isAvailable && formik.errors.subdomain !== t('GENERAL.INVALID_SUBDOMAIN')) {
          formik.setFieldValue('subdomain', subdomain);
          formik.setFieldError('subdomain', '');
          setAvailabilityMessage(t('GENERAL.SUBDOMAIN_AVAILABLE', { subdomain }));
        } else if (isAvailable && formik.errors.subdomain === t('GENERAL.INVALID_SUBDOMAIN')) {
          formik.setFieldValue('subdomain', subdomain);
          formik.setFieldError('subdomain', '');
          setAvailabilityMessage('');
        } else {
          formik.setFieldError('subdomain', t('GENERAL.SUBDOMAIN_UNAVAILABLE', { subdomain }));
        }
      },
      onError: () => {
        setDomainTested(true);
        updateDomainAvailability(false);
        formik.setFieldError('subdomain', t('GENERAL.INVALID_DOMAIN'));
        setAvailabilityMessage(t('GENERAL.DOMAIN_CHECK_ERROR'));
      },
    });

    formik.validateField('subdomain');
    formik.setTouched({ subdomain: true });
  };

  return (
    <>
      <h5 className='subtitle'>{t('NAVBAR.CHOOSE_SUBDOMAIN')}</h5>
      <div className='d-flex gap-2'>
        <i className='icon-info font-size-20 text-gray' />
        <p className='font-size-13 text-gray' style={{ maxWidth: '350px' }}>
          {t('NAVBAR.CHOOSE_SUBDOMAIN_DESC')}
        </p>
      </div>
      <div className='d-flex justify-content-center align-content-center gap-5 mt-4'>
        <TextInput
          name='subdomain'
          placeholder='type your subdomain'
          value={subdomain}
          onChange={handleSubdomainChange}
          onBlur={formik.handleBlur}
          isRequired
          error={formik.touched.subdomain && formik.errors.subdomain}
        />
        <RoundedButton
          className={styles.customButton}
          text='Test'
          btnStyle='primary'
          loading={isLoading}
          onClick={handleCheckDomain}
        />
      </div>
      {availabilityMessage && subdomain && (
        <div className={styles.availabilityMessage}>
          <span className='text-success font-size-12'>{availabilityMessage}</span>
        </div>
      )}
    </>
  );
};

export default GoLiveSubdomainInput;
