const requestMap = {
  'HR requests': [
    {
      type: 'GeneralRequest',
      title: 'General Request',
      icon: 'icon-request-general color-hr-request',
      apiParam: 'general',
    },
    {
      type: 'DocumentRequest',
      title: 'Document Request',
      icon: 'icon-request-doc color-hr-request',
      apiParam: 'document',
    },
    {
      type: 'FlightTicketRequest',
      title: 'Flight Ticket',
      icon: 'icon-request-ticket color-hr-request',
      apiParam: 'flight-ticket',
    },
    {
      type: 'VisaRequests',
      title: 'Visa',
      icon: 'icon-request-visa color-hr-request',
      apiParam: 'visa',
    },
    {
      type: 'TransportationRequest',
      title: 'Transportation Request',
      icon: 'icon-request-transport color-hr-request',
      apiParam: 'transportation',
    },
    {
      type: 'AccommodationRequest',
      title: 'Accommodation Request',
      icon: 'icon-request-accomodation color-hr-request',
      apiParam: 'accommodation',
    },
    {
      type: 'AssetRequest',
      title: 'Asset Request',
      icon: 'icon-request-asset color-hr-request',
      apiParam: 'asset',
    },
    {
      type: 'MuqeemRequest',
      title: 'Muqeem',
      icon: 'icon-muqeem color-hr-request',
      apiParam: 'muqeem',
    },
    {
      type: 'SuggestionBoxRequest',
      title: 'Suggestion Box',
      icon: 'icon-request-suggestion color-hr-request',
      apiParam: 'suggestion-box',
    },
    {
      type: 'ResignationRequest',
      title: 'Resignation Request',
      icon: 'icon-resignation color-hr-request',
      apiParam: 'resignation',
    },
    {
      type: 'ResumeWorkRequest',
      title: 'Resume Work',
      icon: 'icon-resume-work color-hr-request',
      apiParam: 'resume-work',
    },
  ],
  'time requests': [
    {
      type: 'VacationRequest',
      title: 'Vacation',
      iconProfile: 'icon-request-vacation',
      profileBg: 'bg-blue',
      iconTeam: 'icon-request-vacation text-light',
      keyTeam: 'TEAM.FLAG.ON_VACATION',
      icon: 'icon-request-vacation color-time-request',
      apiParam: 'vacation',
    },
    {
      type: 'SickLeaveRequest',
      title: 'Sick Leave',
      iconTeam: 'icon-request-sick text-light',
      iconProfile: 'icon-request-sick',
      profileBg: 'bg-blue',
      keyTeam: 'EC.REQUESTS.SICK_LEAVE',
      icon: 'icon-request-sick color-time-request',
      apiParam: 'sick-leave',
    },
    {
      type: 'UnpaidLeaveRequest',
      title: 'Unpaid Leave',
      iconTeam: 'icon-request-unpaid text-light',
      iconProfile: 'icon-request-unpaid',
      profileBg: 'bg-blue',
      keyTeam: 'EC.REQUESTS.UNPAID_LEAVE',
      icon: 'icon-request-unpaid color-time-request',
      apiParam: 'unpaid-leave',
    },
    {
      type: 'BusinessTripRequest',
      title: 'Business Trip',
      iconTeam: 'icon-request-business text-light',
      iconProfile: 'icon-request-business',
      profileBg: 'bg-green',
      keyTeam: 'EC.REQUESTS.BUSINESS_TRIP',
      icon: 'icon-request-business color-time-request',
      apiParam: 'business-trip',
    },
    {
      type: 'RemoteWorkRequest',
      title: 'Remote Work',
      iconTeam: 'icon-request-remote text-light',
      iconProfile: 'icon-request-remote',
      profileBg: 'bg-green',
      keyTeam: 'EC.REQUESTS.REMOTE_WORK',
      icon: 'icon-request-remote color-time-request',
      apiParam: 'remote-work',
    },
    {
      type: 'SpecialLeaveRequest',
      title: 'Special Leave',
      iconTeam: 'icon-request-special text-light',
      iconProfile: 'icon-request-special',
      profileBg: 'bg-blue',
      keyTeam: 'EC.REQUESTS.SPECIAL_LEAVE',
      icon: 'icon-request-special color-time-request',
      apiParam: 'special-leave',
    },
    {
      type: 'ExcuseRequest',
      title: 'Excuse',
      iconTeam: 'icon-request-excuse text-light',
      iconProfile: 'icon-request-excuse',
      profileBg: 'bg-green',
      keyTeam: 'EC.REQUESTS.EXCUSE_REQUEST',
      icon: 'icon-request-excuse color-time-request',
      apiParam: 'excuse',
    },
    {
      type: 'OvertimeRequest',
      title: 'Overtime',
      iconTeam: 'icon-request-overtime text-light',
      iconProfile: 'icon-request-overtime',
      profileBg: 'bg-green',
      keyTeam: 'EC.REQUESTS.OVERTIME_REQUEST',
      icon: 'icon-request-pay color-time-request',
      apiParam: 'overtime',
    },
  ],
  'pay requests': [
    {
      type: 'LoanRequest',
      title: 'Loan',
      icon: 'icon-loan color-pay-request',
      apiParam: 'loan',
    },
    {
      type: 'VacationAdvanceRequest',
      title: 'Vacation Advance',
      icon: 'icon-vacation-salary color-pay-request',
      apiParam: 'vacation-advance',
    },
    {
      type: 'SalaryAdvanceRequest',
      title: 'Salary Advance',
      icon: 'icon-wallet color-pay-request',
      apiParam: 'salary-advance',
    },
    {
      type: 'ExpenseClaimRequest',
      title: 'Expense Claim',
      icon: 'icon-finance color-pay-request',
      apiParam: 'expense-claim',
    },
  ],
};

const typeMap = {
  general: 'GeneralRequest',
  document: 'DocumentRequest',
  'flight-ticket': 'FlightTicketRequest',
  visa: 'VisaRequests',
  transportation: 'TransportationRequest',
  accommodation: 'AccommodationRequest',
  asset: 'AssetRequest',
  'suggestion-box': 'SuggestionBoxRequest',
  vacation: 'VacationRequest',
  'sick-leave': 'SickLeaveRequest',
  'unpaid-leave': 'UnpaidLeaveRequest',
  'business-trip': 'BusinessTripRequest',
  'remote-work': 'RemoteWorkRequest',
  'special-leave': 'SpecialLeaveRequest',
  excuse: 'ExcuseRequest',
  loan: 'LoanRequest',
  'vacation-advance': 'VacationAdvanceRequest',
  'salary-advance': 'SalaryAdvanceRequest',
  'expense-claim': 'ExpenseClaimRequest',
};

const requestGroupList = {
  'time requests': [
    'vacation',
    'sick leave',
    'unpaid leave',
    'special leave',
    'business trip',
    'remote work',
    'excuse',
  ],
  'pay requests': ['loan', 'vacation advance', 'salary advance', 'expense claim'],
  'hr requests': [
    'general',
    'document',
    'flight ticket',
    'visa',
    'transportation',
    'accommodation',
    'asset',
    'suggestion box',
  ],
};

export { requestMap, requestGroupList, typeMap };
