import http from './Api';

class GosiApi {
  static getGosiData(employee) {
    return http.get(`employee/${employee}/gosi-snapshot`);
  }

  static getNonSaudiGosiData(employee) {
    return http.get(`employee/${employee}/non-saudi-gosi-snapshot`);
  }

  static getMasdrGosiData(group, params) {
    return http.get(`gosi/masdr/${group}${params}`);
  }

  static editGosiData(employee, data) {
    return http.put(`employee/${employee}/gosi-snapshot`, data);
  }

  static editGosiNonSaudiContributorData(employee, data) {
    return http.put(`employee/${employee}/non-saudi-gosi-snapshot`, data);
  }

  static getEmployeeWithGosi(filters = '') {
    return http.get(`gosi-snapshots${filters}`);
  }

  static getGosiDraftContent(id) {
    return http.get(`employee-drafts/${id}/gosi-draft-content`);
  }

  static gosiSync(employee) {
    return http.get(`employee/${employee}/gosi-snapshot/sync`);
  }

  static gosiApproveNonSaudiContributor(employee) {
    return http.get(`employee/${employee}/non-saudi-gosi-snapshot/approve`);
  }

  static checkGosiAvailability(legalEntity) {
    return http.get(`legal-entity/${legalEntity}/check-availability`);
  }

  static gosiIntegrationHasActive() {
    return http.get('gosi/gosi-integration/has-active');
  }
}

export default GosiApi;
