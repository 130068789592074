import React, { lazy, Suspense, useEffect, useState } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import AreaLoader from 'components/Spinner/AreaLoader';
import CustomRoute from 'topNavigation/Router/CustomRoute';

import FilesHeader from './FilesHeader';
import CompanySettingsDocumentsApi from '../../api/CompanySettingsDocumentsApi';

const CompanyFiles = lazy(() => import('./CompanyFiles/CompanyFiles'));
const EmployeeDocuments = lazy(() => import('./EmployeeDocuments/EmployeeDocuments'));

const Files = () => {
  const [access, setAccess] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeView, setActiveView] = useState('company-files');
  const path = '/files';

  const getFileSettingsHandler = () => {
    setLoading(true);
    CompanySettingsDocumentsApi.getFileSettings()
      .then((res) => {
        setAccess(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const body = document.querySelector('body');
    body.classList.add('no-scroll');

    getFileSettingsHandler();

    return () => {
      body.classList.remove('no-scroll');
    };
  }, []);

  if (loading) {
    return <div />;
  }

  return (
    <div className='position-relative pageContainer page-top-positioning'>
      <FilesHeader onSelectView={setActiveView} active={activeView} />

      <Routes>
        <Route
          path='company-files/*'
          element={
            <Suspense fallback={<AreaLoader />}>
              <CustomRoute
                path={`${path}/company-files`}
                component={CompanyFiles}
                allowLinkAsFile={access.allowLinkAsFile}
              />
            </Suspense>
          }
        />

        <Route
          path='employee-documents'
          element={
            <Suspense fallback={<AreaLoader />}>
              <CustomRoute
                path={`${path}/employee-documents`}
                component={EmployeeDocuments}
                allowLinkAsFile={access.allowLinkAsFile}
              />
            </Suspense>
          }
        />

        <Route path='' element={<Navigate to='company-files' replace />} />
      </Routes>
    </div>
  );
};

export default Files;
