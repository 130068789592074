import React from 'react';

import cx from 'classnames';

import IconButton from 'components/buttons/IconButton/IconButton';
import SwitchInputExtended from 'components/forms/components/SwitchInputExtended';
import styles from 'components/InputsSectionContainer/InputsSectionContainer.module.scss';

const InputsSectionContainer = ({
  className,
  children,
  title,
  subTitle,
  hasSwitchButton,
  hasAddButton,
  onSwitch,
  switchValue,
  onClickAdd,
  isLimitedWidth,
  hideBorder,
}) => (
  <section
    className={cx(className, styles.InputsSectionContainer, ' full-border radius-16', {
      'no-border': hideBorder,
      'px-5 pt-5': !hideBorder,
    })}
  >
    <section className='d-flex justify-content-between  align-items-center'>
      <section className='flex-1'>
        <section className='d-flex justify-content-between mb-2 w-100'>
          <h6 className='subtitle font-size-16 mb-0'>{title}</h6>
          {onClickAdd && <IconButton size='md' icon='icon-plus' color='primary' onClick={onClickAdd} />}
        </section>
        {subTitle && (
          <p
            className={cx('mb-4 color-secondary font-size-14 multiline', {
              [styles.description]: isLimitedWidth,
            })}
          >
            {subTitle}
          </p>
        )}
      </section>

      <section className='d-flex items-center justify-center gap-3'>
        {hasSwitchButton && (
          <section>
            <SwitchInputExtended name='isEligibilityEnabled' onChange={onSwitch} checked={switchValue} />
          </section>
        )}
        {hasAddButton && (
          <section>
            <IconButton onClick={onClickAdd} size='md' icon='icon-plus' color='primary' />
          </section>
        )}
      </section>
    </section>
    {children}
  </section>
);

export default InputsSectionContainer;
