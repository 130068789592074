import { useContext } from 'react';

import { AuthContext } from 'auth/AuthContext';
import t from 'utility/setTranslation';

const generateActions = (employee, content, showLinkToProfile = true, showGosi = false, component = false) => {
  const { authState } = useContext(AuthContext);

  const actions = [];

  const isActive = employee.status === 1;

  const showViewProfile =
    !authState.user.roles.includes('ROLE_EMPLOYEE') || authState.user.identification === employee.id;
  const isAdmin = authState.user.roles.includes('ROLE_ADMIN');

  const canOffboard =
    (authState.user.roles.includes('ROLE_ADMIN') || authState.user.roles.includes('ROLE_HR')) &&
    !employee.toBeOffBoarded;

  const canCancelOffboard =
    (authState.user.roles.includes('ROLE_ADMIN') || authState.user.roles.includes('ROLE_HR')) &&
    employee.toBeOffBoarded &&
    !employee.offBoarded;

  const seeChangePassword =
    employee.status === 1 && (authState.user.roles.includes('ROLE_ADMIN') || authState.user.roles.includes('ROLE_HR'));

  if (showViewProfile && showLinkToProfile) {
    actions.push({ action: 'view profile', title: t('PEOPLE.VIEW_PROFILE'), icon: 'icon-user' });
  }

  if (seeChangePassword && employee?.id !== authState.user.identification) {
    actions.push(
      { action: 'invite', title: t('PEOPLE.SEND_INVITATION'), icon: 'icon-mail' },
      { action: content, title: t('GENERAL.GENERATE_PASSWORD'), icon: 'icon-key' }
    );
  }

  if (seeChangePassword && employee?.id === authState.user.identification) {
    actions.push({ action: content, title: t('PEOPLE.CHANGE_PASSWORD'), icon: 'icon-key' });
  }

  if (isAdmin) {
    actions.push(
      { action: 'permission', title: t('PEOPLE.CHANGE_PERMISSION_GROUP'), icon: 'icon-shield-ok' },
      {
        action: isActive ? 'deactivate' : 'activate',
        title: isActive ? t('GENERAL.REMOVE_ACCESS') : t('GENERAL.ALLOW_ACCESS'),
        icon: isActive ? 'icon-circle-x' : 'icon-verified',
      }
    );
  }

  if (canOffboard) {
    actions.push({ action: 'offboarding', title: t('PEOPLE.OFFBOARD'), icon: 'icon-user-remove' });
  }

  if (canCancelOffboard) {
    actions.push({ action: 'cancelOffboarding', title: t('PROFILE.CANCEL_OFFBOARDING'), icon: 'icon-user' });
  }

  if ((isAdmin || authState.user.roles.includes('ROLE_HR')) && component === 'orgchart') {
    actions.push({ action: 'line_manager', title: t('GENERAL.SET_LINE_MANAGER'), icon: 'icon-users' });
  }

  if (showGosi && isAdmin && employee.gosiSyncAllowed) {
    actions.push({ action: 'gosi', title: t('GENERAL.SYNC_WIDTH_GOSI'), icon: 'icon-refresh-cw' });
  }
  return actions;
};

export default generateActions;
