import { useQueryFeatureFlagConfigurations } from 'modules/Dashboard/hooks/useDashboard';

import { isDevelopment } from '../services/Env/EnvService';

export const useModulePermissions = (moduleName, config = { isDataAlreadyCached: false }) => {
  const { data, isLoading, isInitialLoading } = useQueryFeatureFlagConfigurations({
    enabled: !config.isDataAlreadyCached,
  });

  const moduleData = data?.data?.modules?.find((module) => module.name.toLowerCase() === moduleName?.toLowerCase());

  const isModuleEnabled = isDevelopment() ? true : Boolean(moduleData?.enabled);

  const enabledFeatures =
    moduleData?.features?.filter(({ enabled }) => enabled)?.map(({ name }) => name.toLowerCase()) || [];

  return { isLoading, isInitialLoading, isModuleEnabled, enabledFeatures };
};
