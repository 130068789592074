import React, { useState, useEffect, useContext } from 'react';

import cx from 'classnames';
import copy from 'copy-to-clipboard';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import ProfileApi from 'api/ProfileApi';
import { AuthContext } from 'auth/AuthContext';
import IconButton from 'components/buttons/IconButton/IconButton';
import ConfirmationBox from 'components/ConfirmationBox';
import CheckBox from 'components/forms/components/CheckBox';
import Menu from 'components/menu/Menu';
import Modal from 'components/Modal';
import Personas from 'components/Personas';
import Tooltip from 'components/tooltip/Tooltip';
import EmployeeOnProbation from 'modules/Employee/shared/EmployeeOnProbation';
import { requestMap } from 'modules/EngagementCenter/requestMap';
import useResolveHandler from 'shared/useResolveHandler';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

import styles from './Card.module.scss';
import flagMap from './flagMap';
import generateActions from './function/generateActions';

const Card = ({
  employee,
  onUpdateEmployee,
  onCheck,
  checked,
  canViewEmployeeDetails,
  canActOnEmployee,
  isPreviewMode,
  setModalContent,
  setModalOpened,
  enableAvatarPrivacy,
  enableBirthDatePrivacy,
  enableLinkedInProfilePrivacy,
  enableWorkEmailPrivacy,
  enablePhoneNumberPrivacy,
  className,
}) => {
  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();
  const [content, setContent] = useState('reset');

  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { responseStatus, setStatus } = useResolveHandler();

  const isInactive = employee.status === 2;
  const actions = generateActions(employee, content, true, true, false);

  const onAction = (type) => {
    if (type === 'view profile') {
      navigate(`/profile/${employee.id}`);
    } else if (type === 'offboarding') {
      navigate(`/offboarding/${employee?.id}`);
    } else if (type === 'cancelOffboarding') {
      setIsConfirmModalOpened(true);
    } else {
      setModalContent(type);
      setModalOpened(true);
    }
  };

  useEffect(() => {
    if (employee?.contact?.email === authState.user.username) {
      setContent('change');
    }
  }, []);

  const onConfirmAction = () => {
    setDeleting(true);
    ProfileApi.cancelProfileOffboard(employee.id)
      .then(() => {
        setDeleting(false);
        onUpdateEmployee('offboarding', {
          id: employee.id,
          toBeOffBoarded: false,
        });

        setStatus(true, () => {
          setIsConfirmModalOpened(false);
        });
      })
      .catch((error) => {
        setDeleting(false);
        setStatus(false, () => {}, error?.response?.data?.message);
      });
  };

  const renderLocation = () => {
    let el = null;
    if (isInactive) {
      el = (
        <div className='d-flex align-items-center align-items-center bg-gray rounded-circle'>
          <Tooltip label={t('REPORT.FLUCTUATION.OFF_BOARDED')} position='bottom' size='md'>
            <i className='icon-user-remove text-light' />
          </Tooltip>
        </div>
      );
    } else if (employee.flags.location?.description === 'EMPLOYEE.FLAG.OFFICE') {
      el = (
        <div className='d-flex justify-content-center align-items-center bg-green rounded-circle'>
          <Tooltip label={t('EMPLOYEE.FLAG.OFFICE')} position='bottom' size='md'>
            <i className='icon-case text-light ' />
          </Tooltip>
        </div>
      );
    } else {
      const req = _.find(requestMap['time requests'], ['type', employee.flags.location?.type]);
      el = (
        <div
          className={cx(
            req.profileBg,
            'd-flex align-items-center align-items-center justify-content-center rounded-circle'
          )}
        >
          <Tooltip label={t(req.keyTeam)} position='bottom' size='md'>
            <i className={cx(req.iconTeam, 'text-light')} />
          </Tooltip>
        </div>
      );
    }
    return el;
  };

  const cardItem = (
    <div className={cx(styles.content, 'position-relative', { [styles.visible]: checked.includes(employee.id) })}>
      <div className={cx(styles.headerWraper, 'w-100')}>
        <div className='d-flex align-items-center justify-content-between p-3' onClick={(e) => e.stopPropagation()}>
          <div className='d-flex align-items-center'>
            {canActOnEmployee && !isPreviewMode && (
              <div className={cx(styles.checkBox)}>
                {!isPreviewMode && (
                  <CheckBox
                    name='employee'
                    value={employee.id}
                    onChange={onCheck}
                    checked={checked.includes(employee.id)}
                  />
                )}
              </div>
            )}

            {employee.flags.contract.length > 0 &&
              !isPreviewMode &&
              employee.flags.contract.map((flag) => (
                <Tooltip
                  key={flag}
                  label={
                    flag === 'EMPLOYEE.FLAG.ON_PROBATION' ? (
                      <EmployeeOnProbation
                        remainingProbationDays={employee?.remainingProbationDays}
                        probationPeriodEnd={employee?.probationPeriodEnd}
                      />
                    ) : (
                      t(flag)
                    )
                  }
                >
                  <i className={flagMap[flag]} />
                </Tooltip>
              ))}
          </div>

          <div className='d-flex'>
            {employee.toBeOffBoarded && !isPreviewMode && (
              <div className='me-2'>
                <Tooltip label={t('GENERAL.OFFBOARDING_IN_PROCESS')}>
                  <i className='icon-exit text-danger' />
                </Tooltip>
              </div>
            )}

            {employee.flags.celebration.length > 0 &&
              !isPreviewMode &&
              employee.flags.celebration.map((flag) => (
                <div className='me-2' key={flag}>
                  <Tooltip label={t(flag)}>
                    <i className={flagMap[flag]} />
                  </Tooltip>
                </div>
              ))}
          </div>
          {canActOnEmployee && !isInactive && !isPreviewMode && (
            <div className='position-relative'>
              <Menu
                data={actions}
                onAction={onAction}
                iconColor='gray'
                iconSize='md'
                openMenuClassName={styles.editMenu}
              />
            </div>
          )}
        </div>

        <div className={styles.imgWrapper}>
          <div className='position-relative'>
            <Personas user={employee} size={80} isHideAvatar={!enableAvatarPrivacy} />
            <div className={styles.position}>{renderLocation()}</div>
          </div>
        </div>
      </div>

      <div className='px-3 pt-7 pb-3 '>
        <div className='text-center'>
          <div className='d-flex flex-column align-items-center border-btm mb-2'>
            <p className={cx(styles.code, 'pt-1')}>{`# ${employee.employment.code}`}</p>

            <p className='text-dark fw-bold mt-2 text-truncate mb-0 w-100'>
              {readName(authState.user.language, employee.fullName, employee.fullNameArabic) || '- -'}
            </p>

            <p className={cx(styles.job, 'text-truncate', 'w-100', 'mb-3')}>
              {readName(authState.user.language, employee?.job?.name, employee?.job?.nameArabic) || '- -'}
            </p>

            <section className='d-flex mb-3 gap-3'>
              <div
                className='radius-100 p-1 full-border d-flex justify-content-center box-content align-items-center'
                style={{ width: 24, height: 24 }}
              >
                <Tooltip
                  label={
                    <div>
                      <p className='font-weight-bold'>{t('GENERAL.BIRTHDATE')}</p>
                      {enableBirthDatePrivacy ? (
                        <p>{moment(employee?.about?.birthdate).format('MMM DD, YYYY')}</p>
                      ) : (
                        <p>{t('GENERAL.HIDDEN_BY_EMPLOYEE')}</p>
                      )}
                    </div>
                  }
                  position='bottom'
                >
                  <i
                    className={`icon-gift ${enableBirthDatePrivacy ? 'text-indigo' : 'text-gray-500 cursor-default'}`}
                  />
                </Tooltip>
              </div>

              <div
                className='radius-100 p-1 full-border d-flex justify-content-center box-content align-items-center'
                style={{ width: 24, height: 24 }}
                onClick={(e) => e.stopPropagation()}
              >
                {employee?.about?.linkedInProfile ? (
                  <Tooltip
                    label={
                      <div>
                        <p className='font-weight-bold'>{t('GENERAL.LINKED_IN_PROFILE')}</p>
                        {enableLinkedInProfilePrivacy ? (
                          <p>{employee?.about?.linkedInProfile}</p>
                        ) : (
                          <p>{t('GENERAL.HIDDEN_BY_EMPLOYEE')}</p>
                        )}
                      </div>
                    }
                    position='bottom'
                  >
                    <a
                      target='_blank'
                      href={`${
                        employee?.about?.linkedInProfile.includes('https')
                          ? employee?.about?.linkedInProfile
                          : `https://${employee?.about?.linkedInProfile}`
                      }`}
                      rel='noreferrer'
                    >
                      <i style={{ color: '#0077B5' }} color='#0077B5' className={`icon-Icon_LinkedIn `} />
                    </a>
                  </Tooltip>
                ) : (
                  <i className={`icon-Icon_LinkedIn text-gray-500  cursor-default`} />
                )}
              </div>
            </section>
          </div>

          <div className='text-center'>
            <div
              style={{ height: '20px' }}
              className={cx(styles.data, 'text-truncate', 'mt-2 d-flex align-items-center justify-content-center')}
            >
              {employee.contact?.email && !isPreviewMode && (
                <div className={cx(styles.verified, 'me-1')}>
                  <Tooltip label={employee.status === 1 ? t('GENERAL.ACTIVE') : t('GENERAL.INACTIVE')}>
                    <i className={employee.status === 1 ? 'icon-verified1-fill text-green' : 'icon-not-verified'} />
                  </Tooltip>
                </div>
              )}
              <span className={cx('text-truncate d-inline-block')} style={{ maxWidth: '200px' }}>
                <span className={cx({ [styles.blur]: !enableWorkEmailPrivacy })}>
                  {enableWorkEmailPrivacy ? employee.contact?.email || '- -' : 'noEmailHere@email'}
                </span>
              </span>
              {enableWorkEmailPrivacy && employee.contact?.email && (
                <IconButton
                  className={cx(styles.copyButton, 'font-size-11')}
                  icon='icon-copy'
                  size='sm'
                  onClick={() => copy(employee.contact?.email)}
                />
              )}
            </div>

            <div style={{ height: '20px' }} className='d-flex justify-content-center align-items-center'>
              <p className={cx(styles.data, 'text-truncate', 'mt-1')}>
                <i className='icon-call-phone me-1 font-size-16' />
                <span
                  className={cx({
                    [styles.blur]: !enablePhoneNumberPrivacy,
                  })}
                >
                  {enablePhoneNumberPrivacy ? employee.contact?.mobileNumber || '- -' : '000-0000-00'}
                </span>
              </p>
              {enablePhoneNumberPrivacy && employee.contact?.mobileNumber && (
                <IconButton
                  className={styles.copyButton}
                  icon='icon-copy'
                  size='sm'
                  onClick={() => copy(employee.contact?.mobileNumber)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={cx({ [styles.inactive]: isInactive }, 'col-3 pb-3 px-2', className)}>
      {canViewEmployeeDetails && !isPreviewMode ? (
        <div
          onClick={() => {
            navigate(`/profile/${employee.id}/personal`);
          }}
          className='cursor-pointer'
        >
          {cardItem}
        </div>
      ) : (
        cardItem
      )}

      <Modal
        isOpen={isConfirmModalOpened}
        styles={{ width: '720px' }}
        onRequestClose={() => {
          setIsConfirmModalOpened(false);
        }}
        showHeader={false}
      >
        <ConfirmationBox
          close={() => {
            setIsConfirmModalOpened(false);
          }}
          action={onConfirmAction}
          text={t('PROFILE.CANCEL_OFFBOARDING_DESC')}
          title='GENERAL.DEACTIVATE'
          type='cancel'
          sending={deleting}
          responseStatus={responseStatus}
        />
      </Modal>
    </div>
  );
};

Card.propTypes = {
  employee: PropTypes.object.isRequired,
  onUpdateEmployee: PropTypes.func,
  canViewEmployeeDetails: PropTypes.bool.isRequired,
  canActOnEmployee: PropTypes.bool.isRequired,
  isPreviewMode: PropTypes.bool,
};

Card.defaultProps = {
  onUpdateEmployee: undefined,
  isPreviewMode: false,
};

export default Card;
