/* eslint-disable */

function defaultFormat() {
  return {
    locale: undefined,
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
  };
}

function format(value = 0, options = {}) {
  const defaultOpt = { ...defaultFormat() };

  Object.keys(defaultOpt).forEach((key) => {
    if (!options[key]) return;
    defaultOpt[key] = options[key];
  });

  delete defaultOpt.locale;

  // Remove commas before converting to a number
  let numberValue = value;
  if (typeof value === 'string') {
    numberValue = Number(value.replace(/,/g, ''));
  }

  return numberValue.toLocaleString(options.locale, defaultOpt);
}

function decimalPortion(number) {
  return number % 1;
}

function integerPortion(number) {
  // to work with negative numbers as well
  return Math.trunc(number);
}

function roundNumber(number) {
  return Math.round(number);
}

const isNumeric = (value) => {
  return !isNaN(parseFloat(value)) && isFinite(value);
};

const parseToDecimal = (value, decimals = 2, nanAsZero = false) => {
  if (!isNumeric(value) && !nanAsZero) {
    return '';
  }

  if (!isNumeric(value) && nanAsZero) {
    value = 0;
  }

  return parseFloat(value).toFixed(decimals);
};

export const floorNumber = (number) => {
  return Math.floor(parseToDecimal(number, 2, true));
};

export default {
  defaultFormat,
  format,
  decimalPortion,
  integerPortion,
  roundNumber,
  parseToDecimal,
};
