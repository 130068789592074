import React from 'react';

import { Scrollbars } from 'react-custom-scrollbars';

import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import Modal from 'components/Modal';
import t from 'utility/setTranslation';

import TodayShiftsTable from '../TodayShiftsTable/TodayShiftsTable';

const TodayShiftModal = ({ isOpen, onClose }) => (
  <Modal
    title={t('GENERAL.TODAY_SHIFTS')}
    isOpen={isOpen}
    styles={{ width: '800px', height: '720px', padding: '24px 32px' }}
    onRequestClose={onClose}
  >
    <Scrollbars className='scrollbarsWrapper h-100' autoHide autoHideTimeout={1000} autoHideDuration={200}>
      <TodayShiftsTable />
    </Scrollbars>
    <footer className='d-flex gap-3 justify-content-end border-t pt-4'>
      <RoundedButton onClick={onClose} type='button' text='close' btnStyle='outlined' color='secondary' size='md' />
    </footer>
  </Modal>
);

export default TodayShiftModal;
