import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import danger from 'assets/img/alert-danger.png';
import info from 'assets/img/alert-img-info.png';
import success from 'assets/img/success.svg';
import warning from 'assets/img/warning-image.png';
import SuccessCheckmark from 'components/SuccessCheckmark/SuccessCheckmark';
import t from 'utility/setTranslation';

import RoundedButton from './buttons/RoundedButton/RoundedButton';
import styles from './ConfirmationBox.module.scss';
import SpinnerRing from './Spinner/SpinnerRing';

const ConfirmationBox = ({
  action,
  close,
  text,
  sending,
  responseStatus,
  type,
  actionText,
  leadText,
  onlyButtonSpiner,
  renderAdditionalContent,
  confirmationButtonId,
}) => {
  const iconMap = {
    confirm: success,
    edit: warning,
    warning,
    danger,
    cancel: info,
    info,
    reject: danger,
  };

  const buttonsLabelMap = {
    confirm: ['Cancel', 'Confirm'],
    edit: ['Cancel', 'Change'],
    warning: ['Cancel', 'Yes, Disable'],
    danger: ['Cancel', 'Yes, Delete'],
    cancel: ['Go Back', 'Yes, Cancel'],
    reject: ['Cancel', 'Yes, Reject'],
    info: ['Cancel', 'Yes'],
  };

  const btnColors = {
    confirm: 'primary',
    edit: 'warning',
    warning: 'warning',
    danger: 'danger',
    cancel: 'primary',
    reject: 'danger',
    info: 'info',
  };

  const btnStyles = {
    confirm: 'contained',
    edit: 'contained',
    warning: 'contained',
    danger: 'contained',
    cancel: 'contained',
    reject: 'contained',
  };

  return (
    <div>
      {responseStatus.resolved && <SuccessCheckmark text={responseStatus.msg} isFailed={responseStatus.isFailed} />}
      {sending && !onlyButtonSpiner && <SpinnerRing />}

      <div className='mb-5 d-flex flex-column justify-content-center align-items-center'>
        <img className={cx('mb-4', styles.icon)} src={iconMap[type]} alt={type} />
        <h6 className='font-size-20 mb-2 font-weight-bold text-main'>{t(leadText) || `${t('GENERAL.WARNING')}!`}</h6>
        <p className='font-size-16 text-center color-secondary'>{t(text)}</p>
      </div>

      {renderAdditionalContent && renderAdditionalContent()}

      <div className='d-flex justify-content-center'>
        <RoundedButton
          text={buttonsLabelMap[type][0]}
          onClick={close}
          btnStyle='outlined'
          color='gray'
          size='md'
          className='me-3'
        />

        <RoundedButton
          text={actionText || buttonsLabelMap[type][1]}
          onClick={action}
          btnStyle={btnStyles[type]}
          color={btnColors[type]}
          size='md'
          loading={sending}
          id={confirmationButtonId}
        />
      </div>
    </div>
  );
};

ConfirmationBox.propTypes = {
  action: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  text: PropTypes.string,
  sending: PropTypes.bool,
  responseStatus: PropTypes.object,
  type: PropTypes.string,
  leadText: PropTypes.string,
  onlyButtonSpiner: PropTypes.bool,
};

ConfirmationBox.defaultProps = {
  text: '',
  leadText: '',
  sending: false,
  responseStatus: {},
  type: 'confirm',
  onlyButtonSpiner: false,
};

export default ConfirmationBox;
