import React, { useContext } from 'react';

import cx from 'classnames';

import appstore from 'assets/img/enterprise/khadamati/app-store.svg';
import playstore from 'assets/img/enterprise/khadamati/play-store.svg';
import { AuthContext } from 'auth/AuthContext';
import { ThemeContext } from 'themes/ThemeContext';
import t from 'utility/setTranslation';

import BrandImage from './BrandImage';
import RoundedButton from './buttons/RoundedButton/RoundedButton';
import styles from './SmallScreenWarning.module.scss';

const SmallScreenWarning = () => {
  useContext(AuthContext);
  const { themeState } = useContext(ThemeContext);
  const enterpriseName = themeState?.companyName || 'palmhr';

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <BrandImage name='1024_screen.png' alt='screen-1024' customClass={styles.img} />
        {enterpriseName === 'khadamati' ? (
          <h1 className='fw-bold text-center mt-6 mb-3'>{t('AHAD.TITLE')}</h1>
        ) : (
          <h5 className={styles.title}>{t('GENERAL.SMALL_SCREEN_TITLE')}</h5>
        )}

        <p className={styles.text}>{t('GENERAL.SMALL_SCREEN_DESC')}</p>

        <div className={styles.buttonDiv}>
          {enterpriseName === 'khadamati' ? (
            <div className='row mt-11'>
              <div className='col-sm-6 col-xs-12'>
                <div className={cx(styles.linkBtn, 'mb-5')}>
                  <a
                    className='d-flex align-items-center justify-content-center'
                    href='https://m.thiqah.sa/khadamati'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img className={cx(styles.storeImg, 'me-3')} src={playstore} alt='appstore' />
                    <span>{t('GENERAL.ANDROID_APP')}</span>
                  </a>
                </div>
              </div>
              <div className='col-sm-6 col-xs-12'>
                <div className={cx(styles.linkBtn)}>
                  <a
                    className='d-flex align-items-center justify-content-center'
                    href='https://m.thiqah.sa/khadamati'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img className={cx(styles.storeImg, 'me-3')} src={appstore} alt='appstore' />
                    <span>{t('GENERAL.IOS_APP')}</span>
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <>
              <RoundedButton
                icon='icon-appstore'
                iconPosition='left'
                btnStyle='contained'
                color='primary'
                size='md'
                text='App Store'
                className='m-auto'
                onClick={() => {
                  window.open('https://apps.apple.com/app/palmhr/id1517422977', '_blank');
                }}
              />
              <RoundedButton
                icon='icon-googleplay'
                iconPosition='left'
                btnStyle='contained'
                color='primary'
                size='md'
                text='Google Play'
                className='m-auto'
                onClick={() => {
                  window.open('https://play.google.com/store/apps/details?id=com.palmhr', '_blank');
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SmallScreenWarning;
