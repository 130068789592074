import http from './Api';

class ProfileApi {
  static me() {
    return http.get('/me');
  }

  static getProfilePersonal(id) {
    return http.get(`/profile/${id}/personal`);
  }

  static employeeAbout(id) {
    return http.get(`/profile/${id}/personal/about`);
  }

  static profileFieldsForAsset(filters = '') {
    return http.get(`/settings/team/profile-fields/assets/types${filters}`);
  }

  static createNotes(data) {
    return http.post(`/notes`, data);
  }

  static uploadPhoto(employeeId, data) {
    return http.post(`/profile/${employeeId}/photo`, data);
  }

  static uploadAvatar(employee, avatarThumb) {
    return http.post(`profile/${employee}/avatar`, avatarThumb);
  }

  static uploadBackground(employeeId, data) {
    return http.post(`/profile/${employeeId}/background`, data);
  }

  static removePhoto(employeeId) {
    return http.delete(`/profile/${employeeId}/photo`);
  }

  static checkNameOptions() {
    return http.get(`/settings/team/profile-settings`);
  }

  // Assets
  static getAssets(filters = '') {
    return http.get(`/profile/assets${filters}`);
  }

  static getAssigneAssets(employee) {
    return http.get(`/assets/${employee}/offboarding`);
  }

  static getAsset(id) {
    return http.get(`/profile/assets/${id}`);
  }

  static getEmployeeAssets(employeeID, filters = '') {
    return http.get(`/profile/${employeeID}/assets${filters}`);
  }

  static addAsset(data) {
    return http.post('/profile/assets', data);
  }

  static editAsset(id, data) {
    return http.put(`/profile/assets/${id}`, data);
  }

  static addAssetAttachments(id, data) {
    return http.post(`/profile/assets/${id}/attachments`, data);
  }

  static deleteAsset(id) {
    return http.delete(`/profile/assets/${id}`);
  }

  // Notes
  static getNotes() {
    return http.get(`/team/notes`);
  }

  static getNotesForEmployee(employeeID, filters = '') {
    return http.get(`/team/notes/employee/${employeeID}${filters}`);
  }

  static getNote(id) {
    return http.get(`/team/notes/employee/${id}`);
  }

  static createNote(data) {
    return http.post(`/team/notes`, data);
  }

  static editNote(id, data) {
    return http.put(`/team/notes/${id}`, data);
  }

  static deleteNote(id) {
    return http.delete(`/team/notes/${id}`);
  }

  // Activity
  static getActivityByGroup(employeeId, group, filters = '') {
    return http.get(`/employee/${employeeId}/activity-group/${group}/activities${filters}`);
  }

  // Benefits
  static getEmployeeBenefits(employee, filters = '') {
    return http.get(`/profile/employee/${employee}/benefits${filters}`);
  }

  static getEmployeeBenefit(employee, employeeBenefit) {
    return http.get(`/profile/employee/${employee}/benefits/${employeeBenefit}`);
  }

  static addEmployeeBenefit(employee, data) {
    return http.post(`/profile/employee/${employee}/benefits`, data);
  }

  static editEmployeeBenefit(employee, employeeBenefit, data) {
    return http.put(`/profile/employee/${employee}/benefits/${employeeBenefit}`, data);
  }

  static deleteEmployeeBenefit(employee, employeeBenefit) {
    return http.delete(`/profile/employee/${employee}/benefits/${employeeBenefit}`);
  }

  // Dependants
  static getDependents(employeeId, filters = '') {
    return http.get(`/profile/${employeeId}/dependants${filters}`);
  }

  static addDependent(data) {
    return http.post(`/profile/dependants`, data);
  }

  static editDependent(id, data) {
    return http.put(`/profile/dependants/${id}`, data);
  }

  static deleteDependent(id) {
    return http.delete(`/profile/dependants/${id}`);
  }

  // Offboard
  static getProfileOffobarding(employeeId) {
    return http.get(`profile/${employeeId}/offboarding-info`);
  }

  static updateProfileOffobarding(employeeId, data) {
    return http.put(`profile/${employeeId}/offboarding`, data);
  }

  static getMinPossibleTerminationDate(employeeId) {
    return http.get(`team/employee/${employeeId}/off-boarding/minimal-date`);
  }

  static cancelProfileOffboard(employeeId) {
    return http.post(`team/employee/${employeeId}/off-boarding/undo`);
  }

  static getProfilePaySlipsForPeriod(employeeId, filter = '') {
    return http.get(`profile/${employeeId}/pay-slips${filter}`);
  }

  static getOffboardPayment(employee) {
    return http.get(`/team/employee/${employee}/off-boarding/payment`);
  }

  // ActiveEmployeePolicy
  static getActiveEmployeePolicy(employeeId, date) {
    return http.get(`profile/employee/${employeeId}/vacation-policies/${date}`);
  }

  static shiftAccess(employee) {
    return http.get(`profile/${employee}/shifts-visible`);
  }
}

export default ProfileApi;
