import PropTypes from 'prop-types';

import PHRFile from 'services/File/PHRFile';
import propTypesUtility from 'utility/propTypesUtility';

export const icon = (file) => {
  const { isImage, isPdf } = PHRFile.create(file);

  if (isPdf) return 'icon-file-pdf';
  if (isImage) return 'icon-file-jpg';

  return 'icon-file-doc';
};

export const color = (file) => {
  const { isImage, isPdf } = PHRFile.create(file);

  if (isPdf) return 'danger';
  if (isImage) return 'info';

  return 'success';
};

export const propTypes = {
  id: propTypesUtility.idPropType(),
  file: PropTypes.oneOf([PropTypes.instanceOf(PHRFile), PropTypes.object]),
  isDownloadable: propTypesUtility.boolPropType(),
};

export const defaultProps = {
  id: 'phr-file-thumbnail',
  isDownloadable: false,
};
