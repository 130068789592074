import React from 'react';

import cx from 'classnames';

import styles from './GoLiveOptionContainer.module.scss';

const GoLiveOptionContainer = ({ children, isChecked, error, className }) => (
  <section
    className={cx('py-4 px-4 d-flex flex-column flex-1 full-border', className, {
      [styles.GoLiveOptionContainer__container]: isChecked,
      [styles.GoLiveOptionContainerError__container]: error && !isChecked,
    })}
  >
    {children}
  </section>
);

export default GoLiveOptionContainer;
