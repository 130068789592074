import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './BillingSubsectionContainer.module.scss';

const BillingSubsectionContainer = ({ title, description, containerClassName, icon, onClick, children }) => (
  <section className={cx(styles.container, containerClassName)}>
    <div className='d-flex justify-content-between w-100'>
      <div className='d-flex flex-column'>
        <h6 className='fw-bold m-0'>{title}</h6>
        <p className='mt-1 text-secondary font-size-14'>{description}</p>
      </div>
      <i className={cx(icon, 'text-gray font-size-20 cursor-pointer')} onClick={onClick} />
    </div>
    {children}
  </section>
);

export default BillingSubsectionContainer;

BillingSubsectionContainer.propTypes = {
  title: PropTypes.string,
  containerClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
};

BillingSubsectionContainer.defaultProps = {
  title: '',
  containerClassName: '',
};
