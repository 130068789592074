/* eslint-disable */

import React from 'react';
import utility from './utility';

const Card = ({ id, children, className, style, raised, border, hoverable, onClick, isBackgroundHover }) =>
{
    return (
        <div
            id={id} style={style}
            className={utility.containerClasses(className, raised, border, hoverable, onClick, isBackgroundHover)}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

Card.propTypes = utility.propTypes();
Card.defaultProps = utility.defaultProps();

export default Card;