import readName from './readName';
import t from './setTranslation';

function formatOptions(options, lang, customField, useTranslate) {
  const result = [];
  const getName = (k) => {
    let val = k.fullName;
    if (k.name && useTranslate) {
      val = t(k.name);
    } else if (k.name) {
      val = `${readName(lang, k.name, k.nameArabic)}`;
    }

    if (customField) {
      val = k[customField];
    }

    if (k.fullName) {
      val = `${readName(lang, k.fullName, k.fullNameArabic)}`;
    }

    return val;
  };

  options.forEach((item) => {
    result.push({
      value: `${item.id}`,
      label: getName(item),
      isDisabled: item.isDisabled || false,
      data: item,
    });
  });

  return result;
}

export default formatOptions;
