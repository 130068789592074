import { getLocalItem, setLocalItem, removeLocalItem } from './StorageService';

const STORE = 'store';
const PUSH_TOKEN = 'push-token';

// get
export const storeDataParse = (data) => {
  if (!data) return null;

  try {
    return JSON.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Store parse failed:', error);
    return null;
  }
};

export const getStore = () => {
  const data = getLocalItem(STORE) ?? null;
  return storeDataParse(data);
};

export const getStoreItem = (key) => {
  const store = getStore() ?? {};
  return store[key] ?? null;
};

export const getPushTokenItem = () => getStoreItem(PUSH_TOKEN);

// has
export const hasStoreItem = (key) => !!getStoreItem(key);

export const hasPushTokenItem = () => hasStoreItem(PUSH_TOKEN);

// set
export const setStoreItem = (key, value) => {
  const store = getStore() ?? {};
  store[key] = value;
  return storeDataParse(setLocalItem(STORE, JSON.stringify(store)));
};

export const setPushTokenItem = (value) => setStoreItem(PUSH_TOKEN, value);

// remove
export const removeStoreItem = (key) => {
  const store = getStore() ?? {};
  const { [key]: removed, ...newStore } = store;
  return storeDataParse(setLocalItem(STORE, JSON.stringify(newStore)));
};

export const removePushTokenItem = () => removeStoreItem(PUSH_TOKEN);

export const clearStore = () => {
  removeLocalItem(STORE);
};
