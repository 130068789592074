import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import IconButton from 'components/buttons/IconButton/IconButton';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import ContentHeader from 'components/ContentHeader';
import MapComponent from 'components/map/MapComponent';
import t from 'utility/setTranslation';

import styles from './Attendance.module.scss';

const GeofencingWarningModal = ({ onClose, clickHandler, coordinates, location }) => (
  <div>
    <ContentHeader className='mx-5 mb-4'>
      <div className='d-flex justify-content-between'>
        <h5 className='d-flex align-items-center fw-bold m-0'>
          <i className={cx('icon-alert-triangle me-2a text-orange')} />
          <span>{t('GENERAL.GEOFENCING_WARNING')}</span>
        </h5>
        <IconButton icon='icon-cross-x' onClick={onClose} size='lg' color='gray' />
      </div>
    </ContentHeader>

    <div className='px-5'>
      <h6 className='mb-2 fw-bold'>
        {location?.geofencing?.enforceLimitation
          ? t('GENERAL.GEOFENCING_LIMITATION_WARNING_DESC')
          : t('GENERAL.GEOFENCING_WARNING_DESC')}
      </h6>
    </div>

    {!_.isEmpty(location) && (
      <div className='mb-7'>
        <div style={{ height: '280px' }} className={styles.mapDiv}>
          <MapComponent
            msgInfo={location.name}
            latitude={location.geofencing?.latitude * 1 ?? 24.693529747911377}
            longitude={location.geofencing?.longitude * 1 ?? 46.676083121103154}
            onUpdateLatLng={() => {}}
            zoom={13}
            radius={location.geofencing?.radius * 1 ?? 150}
            readMode
            listOfMarkers={[
              {
                ...coordinates,
              },
            ]}
          />
        </div>
      </div>
    )}

    <div className='d-flex justify-content-end border-t px-5 py-4'>
      {location.geofencing?.enforceLimitation ? (
        <RoundedButton
          text='got it'
          color='primary'
          btnStyle='contained'
          size='md'
          className='btn-lg'
          onClick={onClose}
        />
      ) : (
        <>
          <RoundedButton
            text='Cancel'
            color='gray'
            btnStyle='outlined'
            size='md'
            className='btn-lg me-3'
            onClick={onClose}
          />

          <RoundedButton text='Yes, Continue' color='warning' btnStyle='contained' size='md' onClick={clickHandler} />
        </>
      )}
    </div>
  </div>
);

GeofencingWarningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  clickHandler: PropTypes.func.isRequired,
  coordinates: PropTypes.object,
  location: PropTypes.object,
};

GeofencingWarningModal.defaultProps = {
  coordinates: {},
  location: {},
};

export default GeofencingWarningModal;
