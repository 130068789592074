/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import DirtyForm from 'components/DirtyForm';

import IconButton from './buttons/IconButton/IconButton';
import ContentHeader from './ContentHeader';
import modalStyle from './modalStyle';

const Modal = ({
  children,
  styles,
  onRequestClose,
  isOpen,
  title,
  showHeader,
  showDirtyForm,
  contentLabel,
  shouldCloseOnOverlayClick,
}) => {
  const delaySeconds = 300;
  const [formDirtyModal, setFormDirtyModal] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const modalRef = useRef(null);

  const closeHandler = () => {
    if (modalRef !== null) {
      // Prevent close handler if form is submitting and prevent dirty form trigger after form submit
      const checkmark = modalRef.current.querySelector('#checkmark-overlay');
      const spinner = modalRef.current.querySelector('#spinner-ring');
      if (checkmark) {
        setFormSubmitted(true);
        setIsFormDirty(false);
      }

      if (checkmark || spinner) return;
      if (formSubmitted) {
        setTimeout(() => {
          setFormSubmitted(false);
        }, 200);
        return;
      }
    }

    if (isFormDirty) {
      setFormDirtyModal(true);
    } else {
      // eslint-disable-next-line no-unused-expressions
      onRequestClose && onRequestClose(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setFormSubmitted(false);
    }
  }, [isOpen]);

  const discardChanges = () => {
    setIsFormDirty(false);
    setFormDirtyModal(false);
    onRequestClose(false);
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { discardChanges, onRequestClose: closeHandler, setIsFormDirty });
    }
    return child;
  });

  return (
    <ReactModal
      onClick={(event) => {
        event.stopPropagation();
      }}
      isOpen={isOpen}
      onRequestClose={closeHandler}
      contentLabel={contentLabel}
      closeTimeoutMS={delaySeconds}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={{
        ...modalStyle,
        content: { ...modalStyle.content, ...styles },
      }}
    >
      {formDirtyModal && (
        <DirtyForm
          onClose={() => {
            setFormDirtyModal(false);
          }}
          onConfirm={() => {
            discardChanges();
          }}
        />
      )}

      <div className='w-100 h-100 d-flex flex-column' ref={modalRef}>
        {showHeader && (
          <ContentHeader className={styles.padding == '0' ? 'mx-5' : ''}>
            <div className='d-flex justify-content-between'>
              <h5 className='fw-bold m-0 text-main'>{title}</h5>
              <IconButton icon='icon-cross-x' color='gray' size='lg' onClick={closeHandler} />
            </div>
          </ContentHeader>
        )}
        {showDirtyForm ? childrenWithProps : children}
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.elementType]).isRequired,
  styles: PropTypes.object,
  onRequestClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  showHeader: PropTypes.bool,
  showDirtyForm: PropTypes.bool,
  contentLabel: PropTypes.string,
  shouldCloseOnOverlayClick: PropTypes.bool,
};

Modal.defaultProps = {
  styles: {},
  showHeader: true,
  title: '',
  showDirtyForm: false,
  onRequestClose: undefined,
  contentLabel: 'Modal',
  shouldCloseOnOverlayClick: true,
};

export default Modal;
