import React from 'react';

import cx from 'classnames';
import { PropTypes } from 'prop-types';

import t from 'utility/setTranslation';

import styles from './PasswordConfig.module.scss';

const PasswordConfig = ({ passwordConfig, passwordStrength }) => (
  <>
    <div
      className={cx(
        styles.passwordStrength,
        passwordStrength.passwordMinimumLength && styles.active,
        'd-flex justify-content-start align-items-center'
      )}
    >
      <i className='icon-check-mark me-2' />
      <span>{t('PASSWORD.AT_LEAST_NO_CHARACTERS', { number: passwordConfig?.passwordMinimumLength || '8' })}</span>
    </div>

    {passwordConfig?.requireLowercase && (
      <div
        className={cx(
          styles.passwordStrength,
          passwordStrength.requireLowercase && styles.active,
          'd-flex justify-content-start align-items-center'
        )}
      >
        <i className='icon-check-mark me-2' />
        <span>{t('PASSWORD.AT_LEAST_LOWER_CHARACTERS')}</span>
      </div>
    )}

    {passwordConfig?.requireUppercase && (
      <div
        className={cx(
          styles.passwordStrength,
          passwordStrength.requireUppercase && styles.active,
          'd-flex justify-content-start align-items-center'
        )}
      >
        <i className='icon-check-mark me-2' />
        <span>{t('PASSWORD.AT_LEAST_UPPER_CHARACTERS')}</span>
      </div>
    )}

    {passwordConfig?.requireNumber && (
      <div
        className={cx(
          styles.passwordStrength,
          passwordStrength.requireNumber && styles.active,
          'd-flex justify-content-start align-items-center'
        )}
      >
        <i className='icon-check-mark me-2' />
        <span>{t('PASSWORD.AT_LEAST_NUMBER_CHARACTERS')}</span>
      </div>
    )}

    {passwordConfig?.requireSpecialChar && (
      <div
        className={cx(
          styles.passwordStrength,
          passwordStrength.requireSpecialChar && styles.active,
          'd-flex justify-content-start align-items-center'
        )}
      >
        <i className='icon-check-mark me-2' />
        <span>{t('PASSWORD.AT_LEAST_SPECIAL_CHARACTERS')}</span>
      </div>
    )}
  </>
);

PasswordConfig.propTypes = {
  passwordConfig: PropTypes.shape({
    passwordMinimumLength: PropTypes.number,
    requireLowercase: PropTypes.bool,
    requireNumber: PropTypes.bool,
    requireSpecialChar: PropTypes.bool,
    requireUppercase: PropTypes.bool,
  }).isRequired,
};

PasswordConfig.defaultProps = {};

export default PasswordConfig;
