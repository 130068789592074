import React from 'react';

import cx from 'classnames';

import t from 'utility/setTranslation';

import styles from './TasksProgressBar.module.scss';
import Line from '../../../../../modules/EngagementCenter/shared/loaders/Line';

const TasksProgressBar = ({ progress, isLoading, activeLang }) => (
  <>
    {isLoading && <Line style={{ height: 30, width: '100%' }} gradient='loading-gradient' />}
    {!isLoading && (
      <>
        <div className={cx('d-flex justify-content-between', activeLang === 'en' ? 'flex-row-reverse' : 'flex-row')}>
          <h6 className='text-secondary font-size-14'>{t('GENERAL.COMPLETED')}</h6>
          <h6 className='text-secondary font-size-14'>{progress}%</h6>
        </div>
        <div className={cx(styles.progressBarContainer, 'w-100 bg-light')}>
          <div className={cx(styles.progressBar, 'bg-primary')} style={{ width: `${progress}%` }} />
        </div>
      </>
    )}
  </>
);

export default TasksProgressBar;
