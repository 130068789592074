import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';

import { AuthContext } from 'auth/AuthContext';
import IconButton from 'components/buttons/IconButton/IconButton';
import Tooltip from 'components/tooltip/Tooltip';
import AttendanceSelect from 'modules/Time/shared/components/AttendanceSelect';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

const AttendanceRecordRow = ({
  data,
  onSaveAttRecord,
  onDeleteAttRecordRow,
  onRemoveNewRow,
  showEdit,
  selectedDate,
  updateBy,
  showDelete,
  setCoordinates,
}) => {
  const [element, setElement] = useState(_.cloneDeep(data));
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState({ in: '', out: '' });
  const { authState } = useContext(AuthContext);
  const updatedTime = moment(updateBy?.in?.updatedAt).format('hh:mm A');

  const onAction = (dataAction) => {
    const sentDataIn = {
      recordMoment: dataAction.in.value
        ? moment(`${moment(selectedDate).format('YYYY-MM-DD')} ${dataAction.in.value}:00`).utc()
        : null,
      recordType: 'in',
      id: dataAction.in.id,
    };

    const sentDataOut = {
      recordMoment: dataAction.out.value
        ? moment(`${moment(selectedDate).format('YYYY-MM-DD')} ${dataAction.out.value}:00`).utc()
        : null,
      recordType: 'out',
      id: dataAction.out.id,
    };

    onSaveAttRecord(sentDataIn, sentDataOut);

    setEditMode(false);
  };

  useEffect(() => {
    const err = {};
    if (element.in.value) {
      const timeValues = element.in.value.split(':');
      const date = moment().hour(timeValues[0]).minute(timeValues[1]);
      err.in = moment().isBefore(date) ? t('GENERAL.ERROR.TIME_IN_FUTURE') : '';
    }
    if (element.out.value) {
      const timeValues = element.out.value.split(':');
      const date = moment().hour(timeValues[0]).minute(timeValues[1]);
      err.out = moment().isBefore(date) ? t('GENERAL.ERROR.TIME_IN_FUTURE') : '';
    }
    setError(err);
  }, [element]);

  useEffect(() => {
    setElement(data);
  }, [data]);

  return (
    <div className='row align-items-start mb-3'>
      <div className='col-4'>
        <div className=' d-flex align-items-center'>
          <AttendanceSelect
            name='in'
            onChange={(name, value) => {
              const obj = { ...element };
              obj[name].value = value;
              setElement(obj);
            }}
            data={element.in}
            isDisabled={element.new ? false : !editMode}
            isInvalid={!!error.in}
          />
          {element?.in?.latitude && element?.in?.longitude && (
            <i
              onClick={() => setCoordinates(element?.in?.latitude, element?.in?.longitude)}
              className='icon-pin cursor-pointer text-gray-500 ms-2'
            />
          )}
          {updateBy?.in?.updatedBy && (
            <Tooltip
              label={`${t('WARNING.UPDATEBY')} ${readName(
                authState.user.language,
                updateBy?.in?.updatedBy?.fullName || updateBy?.in?.updatedBy?.fullNameArabic
              )}: ${updatedTime}`}
            >
              <i className='icon-alert-triangle text-orange ms-2' />
            </Tooltip>
          )}
        </div>
        <div className='error mt-1'>{editMode || element.new ? error.in : ''}</div>
      </div>

      <div className='col-4'>
        <div className=' d-flex align-items-center'>
          <AttendanceSelect
            name='out'
            onChange={(name, value) => {
              const obj = { ...element };
              obj[name].value = value;
              setElement(obj);
            }}
            data={element.out}
            isDisabled={element.new ? false : !editMode}
            isInvalid={!!error.out}
          />
          {element?.out?.latitude && element?.out?.longitude && (
            <i
              onClick={() => setCoordinates(element?.out?.latitude, element?.out?.longitude)}
              className='icon-pin cursor-pointer text-gray-500 ms-2'
            />
          )}
          {updateBy?.out?.updatedBy && (
            <Tooltip
              position='right'
              label={`${t('WARNING.UPDATEBY')} ${readName(
                authState.user.language,
                updateBy?.out?.updatedBy?.fullName || updateBy?.out?.updatedBy?.fullNameArabic
              )} ${updatedTime}`}
            >
              <i className='icon-alert-triangle text-orange ms-2' />
            </Tooltip>
          )}
        </div>
        <div className='error mt-1'>{editMode || element.new ? error.out : ''}</div>
      </div>
      <div className='col-4'>
        <div className='d-flex justify-content-end mt-2'>
          {editMode ? (
            <>
              <IconButton
                className='me-3'
                icon='icon-check-mark'
                color='success'
                size='sm'
                isDisabled={!element.in.value || !!error.in || !!error.out}
                onClick={() => {
                  onAction(element);
                }}
              />

              <IconButton
                icon='icon-cross-x'
                color='danger'
                size='sm'
                onClick={() => {
                  setElement(_.cloneDeep(data));
                  setEditMode(false);
                }}
              />
            </>
          ) : (
            <>
              {element.new ? (
                <>
                  <IconButton
                    className='me-3'
                    icon='icon-check-mark'
                    color='success'
                    size='sm'
                    isDisabled={!element.in.value || !!error.in || !!error.out}
                    onClick={() => {
                      onAction(element);
                    }}
                  />

                  <IconButton
                    icon='icon-cross-x'
                    color='danger'
                    size='sm'
                    onClick={() => {
                      onRemoveNewRow(element.id);
                    }}
                  />
                </>
              ) : (
                <>
                  {showEdit && (
                    <IconButton
                      icon='icon-pencil-create'
                      size='sm'
                      color='gray'
                      onClick={() => {
                        setEditMode(true);
                      }}
                    />
                  )}

                  {showDelete && (
                    <IconButton
                      className='ms-3'
                      icon='icon-trash'
                      color='danger'
                      size='sm'
                      onClick={() => {
                        onDeleteAttRecordRow(element.in.id, element.out.id);
                      }}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AttendanceRecordRow;
