import React, { useContext } from 'react';

import icon from 'assets/img/success_state.svg';
import { AuthContext } from 'auth/AuthContext';
import { IsLoggedInContext } from 'auth/IsLoggedInContext';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import t from 'utility/setTranslation';

import { useLogout } from '../../../../../../hooks/useAccount';

const GoLiveSuccessState = () => {
  const { mutate: mutateLogout, isLoading } = useLogout();

  const { authState, dispatch } = useContext(AuthContext);
  const { dispatch: dispatchIsLoggedIn } = useContext(IsLoggedInContext);

  const handleLogout = () => {
    mutateLogout(
      { username: authState.user.username },
      {
        onSuccess: () => {
          dispatch({
            type: 'del',
          });
          dispatchIsLoggedIn({
            type: 'set',
            payload: { isLoggedIn: false },
          });
          window.location.href = 'https://palmhr.net/preparing-go-live/';
        },
      }
    );
  };
  return (
    <div
      className='d-flex flex-column align-items-center justify-content-center radius-8 bg-white'
      style={{ width: '100%', height: '100%' }}
    >
      <div style={{ width: '400px', height: 'auto' }}>
        <img className='w-100' src={icon} alt='search-icon' />
      </div>

      <h5 className='fw-bold mx-2 text-center text-main'>{t('GENERAL.SUCCESS')}</h5>

      <p style={{ maxWidth: '370px' }} className='text-gray fs-16 text-center mx-auto mt-1'>
        {t('NAVBAR.GO_LIVE_SUCCESS_MSG')}
      </p>

      <div className='d-flex justify-content-center mt-5'>
        <RoundedButton text='got it' color='primary' onClick={handleLogout} loading={isLoading} />
      </div>
    </div>
  );
};

export default GoLiveSuccessState;
