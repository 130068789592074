/* eslint-disable */

import PropTypes from 'prop-types';
import colorUtility from './style/colorUtility';
import sizeUtility from './style/sizeUtility';

const isRequired = (propType, required = false) => {
  if (required === true) {
    propType = propType.isRequired;
  }

  return propType;
};

const numericPropType = (required = false) => {
  return isRequired(PropTypes.oneOfType([PropTypes.string, PropTypes.number]), required);
};

const idPropType = (required = false) => {
  return numericPropType(required);
};

const promisePropType = (required = false) => {
  return isRequired(
    PropTypes.shape({
      then: PropTypes.func.isRequired,
      catch: PropTypes.func.isRequired,
    }),
    required
  );
};

const filePropType = (required = false) => {
  return isRequired(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      mimeType: PropTypes.string.isRequired,
      binary: PropTypes.object.isRequired,
    }),
    required
  );
};

const stylePropType = (required = false) => {
  return isRequired(PropTypes.object, required);
};

const boolPropType = (required = false) => {
  return isRequired(PropTypes.bool, required);
};

const colorPropType = (required = false) => {
  return isRequired(PropTypes.oneOf(colorUtility.colors()), required);
};

const sizePropType = (required = false) => {
  return isRequired(PropTypes.oneOf(sizeUtility.sizes()), required);
};

const classNamePropType = (required = false) => {
  return isRequired(PropTypes.string, required);
};

const datePropType = (required = false) => {
  return isRequired(PropTypes.oneOf([PropTypes.object, PropTypes.instanceOf(Date)]), required);
};

export default {
  numericPropType,
  idPropType,
  promisePropType,
  stylePropType,
  filePropType,
  boolPropType,
  colorPropType,
  sizePropType,
  classNamePropType,
  datePropType,
};
