import React from 'react';

import cx from 'classnames';

import PalmHrAIHumanMessage from 'modules/Dashboard/components/PalmHrAIHumanMessage/PalmHrAIHumanMessage';
import PalmHrAILoading from 'modules/Dashboard/components/PalmHrAILoading/PalmHrAILoading';
import PalmHrAIMessage from 'modules/Dashboard/components/PalmHrAIMessage/PalmHrAIMessage';
import { AI } from 'modules/Dashboard/Constants';

import styles from './PalmHrAIMessages.module.scss';

const PalmHrAIMessages = ({ messages, isLoading, showRegenerate }) => (
  <div className={cx(styles.PalmHrAIMessages, 'mx-5')}>
    {messages.map(({ id, sender, message }) =>
      sender === AI ? (
        <PalmHrAIMessage
          showRegenerate={showRegenerate}
          className={styles.PalmHrAIMessages__message}
          key={id}
          message={message}
        />
      ) : (
        <PalmHrAIHumanMessage className={styles.PalmHrAIMessages__message} key={id} sender={sender} message={message} />
      )
    )}
    {isLoading && <PalmHrAILoading className='mt-3' />}
  </div>
);

export default PalmHrAIMessages;
