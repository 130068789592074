import React from 'react';

import TableSkeleton from 'components/skeletons/TableSkeleton';

const TableLoading = ({ num = 7 }) => (
  <tr>
    <td colSpan='8'>
      <div>
        <TableSkeleton lineHight={56} mb='mb-2' num={num} />
      </div>
    </td>
  </tr>
);

export default TableLoading;
