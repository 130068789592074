import React, { useState, useContext, useEffect } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

import CompanySettingsTimesheetsApi from 'api/CompanySettingsTimesheetsApi';
import DashboardApi from 'api/DashboardApi';
import WhoIsInApi from 'api/WhoIsInApi';
import { AuthContext } from 'auth/AuthContext';
import Alert from 'components/alerts/Alert';
import IconButton from 'components/buttons/IconButton/IconButton';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import CircleBadge from 'components/CircleBadge';
import ContentHeader from 'components/ContentHeader';
import MapComponent from 'components/map/MapComponent';
import SpinnerRing from 'components/Spinner/SpinnerRing';
import SuccessCheckmark from 'components/SuccessCheckmark/SuccessCheckmark';
import Tooltip from 'components/tooltip/Tooltip';
import DashboardContext from 'modules/Dashboard/DashboardContext';
import useResolveHandler from 'shared/useResolveHandler';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

import styles from './AttendanceDetails.module.scss';
import AttendanceRecordRow from './AttendanceRecordRow';

const AttendanceDetails = ({
  onGoBack,
  onClose,
  trackingStatus,
  updateCheckStatus,
  loadingCheck,
  waitingLocation,
  selected,
  timeTrackingDisabled,
}) => {
  const { authState } = useContext(AuthContext);
  const { setShouldUpdateAttendance } = useContext(DashboardContext);
  const [attendanceData, setAttendanceData] = useState({});
  const [attendanceRecords, setAttendanceRecords] = useState([]);
  const [loading, setLoading] = useState({});
  const [ramadanWS, setRamadanWS] = useState(null);
  const [canAddEdit, setCanAddEdit] = useState(false);
  const [loadingSettings, setLoadingSettings] = useState(false);
  const { responseStatus, setStatus } = useResolveHandler();
  const [actualWorked, setActualWorked] = useState(0);
  const [disabledButton, setButtonState] = useState(false);
  const [scheduled, setScheduled] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    latitude: undefined,
    longitude: undefined,
  });

  const [activeGeofencingLocations, setActiveGeofencingLocations] = useState([]);
  const [mainLocation, ...otherLocations] = activeGeofencingLocations;

  const updateDashboardState = () => {
    if (+selected === +authState.user.identification) {
      setShouldUpdateAttendance(true);
    }
  };

  const getShifts = () => {
    setLoading(true);
    DashboardApi.getShiftEmployee(selected)
      .then((res) => {
        setLoading(false);
        setScheduled(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const formatTitle = (data) => {
    let retVal = '';
    if (!scheduled.shifts.length > 0) {
      if (data?.isFlexible) {
        retVal = t('GENERAL.FLEXIBLE');
      } else {
        retVal = t('GENERAL.FIXED');
      }
    }
    return retVal;
  };

  const formatTime = (time) => {
    let timeReturnVal = time;
    const timeArr = timeReturnVal.split(':');
    if (timeArr.length > 2) {
      timeReturnVal = time.slice(0, -3);
    }
    return timeReturnVal;
  };

  const renderTodayShift = () => {
    const items = [];
    if (scheduled?.shifts?.length > 0) {
      scheduled.shifts.map((e) => {
        items.push(
          <p className={styles.hours} key={_.uniqueId()}>
            {`${(e?.startTime && formatTime(e?.startTime)) || '--'} : ${
              (e?.endTime && formatTime(e?.endTime)) || '--'
            }`}
          </p>
        );
        return e;
      });
    }
    if (scheduled?.overnightShift) {
      const textMessage = t(`SHIFT.ATTENDANCE_CARD`, {
        period: `${moment().subtract(1, 'day').format('MMM, DD. YYYY')}`,
        date: `${formatTime(scheduled?.overnightShift?.startTime)}`,
        period2: `${moment().format('MMM, DD. YYYY')}`,
        date2: `${formatTime(scheduled?.overnightShift?.endTime)}`,
      });
      items.unshift(
        <div className='ms-3a'>
          <p className={cx(styles.hours, 'd-inline-block me-2')} key={_.uniqueId()}>
            {`${formatTime(scheduled.overnightShift?.startTime) || '--'} : ${
              (scheduled.overnightShift?.endTime && formatTime(scheduled.overnightShift?.endTime)) || '--'
            }`}
          </p>
          <span>
            <Tooltip label={textMessage} useTranslate position='top' className='text-start' size='md'>
              <i className='icon-info text-gray fs-14' />{' '}
            </Tooltip>
          </span>
        </div>
      );
    }
    if (scheduled?.workSchedule && !scheduled?.workSchedule.isFlexible) {
      items.push(
        <p className={styles.hours} key={_.uniqueId()}>
          <span>{formatTitle(scheduled?.workSchedule)}</span>
          {` ${(scheduled?.workSchedule?.startTime && formatTime(scheduled?.workSchedule?.startTime)) || '--'} :
         
          ${(scheduled?.workSchedule?.endTime && formatTime(scheduled?.workSchedule?.endTime)) || '--'}`}
        </p>
      );
    }
    if (scheduled?.workSchedule && scheduled.workSchedule.isFlexible) {
      items.push(
        <p className={styles.hours} key={_.uniqueId()}>
          <span>{formatTitle(scheduled.workSchedule)}-</span>
          {`${scheduled?.workSchedule?.endTime && formatTime(scheduled?.workSchedule?.endTime)}h` || '--'}
        </p>
      );
    }
    if (scheduled?.workSchedule === null && scheduled?.shifts.length === 0) {
      items.push(
        <p className={styles.hours} key={_.uniqueId()}>
          {t('GENERAL.DAY_OFF')}
        </p>
      );
    }

    return items;
  };

  const loadAttRecords = (timeAttendanceRecords) => {
    const array = [];
    let obj = {
      in: {
        id: null,
        value: '',
        recordMoment: '',
        validLocation: undefined,
        latitude: undefined,
        longitude: undefined,
      },
      out: {
        id: null,
        value: '',
        recordMoment: '',
        validLocation: undefined,
        latitude: undefined,
        longitude: undefined,
      },
      id: 0,
    };
    timeAttendanceRecords.map((el, index) => {
      const i = index + 1;

      obj.id = i;

      if (el?.recordType === 'in') {
        obj.in = {
          id: el.id,
          value: moment(el.recordMoment).format('HH:mm'),
          recordMoment: el.recordMoment,
          validLocation: el.validLocation,
          latitude: el.latitude,
          longitude: el.longitude,
        };

        const obj2 = { ...obj };
        array.push(obj2);
      } else {
        obj.out = {
          id: el.id,
          value: moment(el.recordMoment).format('HH:mm'),
          recordMoment: el.recordMoment,
          validLocation: el.validLocation,
          latitude: el.latitude,
          longitude: el.longitude,
        };

        array[array.length - 1] = { ...obj };

        obj = {
          in: {
            id: null,
            value: '',
            recordMoment: '',
            validLocation: undefined,
            latitude: undefined,
            longitude: undefined,
          },
          out: {
            id: null,
            value: '',
            recordMoment: '',
            validLocation: undefined,
            latitude: undefined,
            longitude: undefined,
          },
          id: i,
        };
      }

      return el;
    });

    setAttendanceRecords(array);
  };

  const calculateNewHoursWorked = (dataIn, dataOut, editData = null) => {
    const seconds = moment(dataOut).diff(moment(dataIn)) / 1000;
    let totalWorked = actualWorked + seconds;

    if (editData) {
      const checkIn = editData.in
        ? attendanceRecords.filter((el) => el.in.id === editData.in)[0].in.recordMoment
        : null;
      const checkOut = editData.out
        ? attendanceRecords.filter((el) => el.out.id === editData.out)[0].out.recordMoment
        : null;

      const removedSecond = moment(checkOut).diff(moment(checkIn)) / 1000;
      totalWorked -= removedSecond;
    }

    setActualWorked(totalWorked);
  };

  const onSaveAttRecord = (dataIn, dataOut) => {
    setLoading(true);

    const sentDataIn = { ...dataIn };
    delete sentDataIn.id;

    const sentDataOut = { ...dataOut };
    delete sentDataOut.id;

    if (dataIn.recordMoment) {
      if (dataIn.id) {
        WhoIsInApi.updateAttendanceRecord(attendanceData.id, dataIn.id, sentDataIn)
          .then((res) => {
            if (dataOut.recordMoment) {
              if (dataOut.id) {
                WhoIsInApi.updateAttendanceRecord(attendanceData.id, dataOut.id, sentDataOut)
                  .then((result) => {
                    setLoading(false);
                    const state = { ...attendanceData };
                    const objIndex = _.findIndex(state.timeAttendanceRecords, ['id', dataOut.id]);
                    state.timeAttendanceRecords[objIndex] = { ...result.data };
                    setAttendanceData(state);
                    loadAttRecords(state.timeAttendanceRecords);

                    setStatus(true, () => {
                      updateDashboardState();
                      calculateNewHoursWorked(result.data.recordMoment, result.data.recordMoment, {
                        in: dataIn.id,
                        out: dataOut.id,
                      });
                    });
                  })
                  .catch((error) => {
                    setLoading(false);
                    setStatus(false, () => {}, error?.response?.data?.message);
                  });
              } else {
                WhoIsInApi.addAttendanceRecords(attendanceData.id, sentDataOut)
                  .then((response) => {
                    setLoading(false);
                    const state = { ...attendanceData };
                    state.timeAttendanceRecords.push(response.data);
                    setAttendanceData(state);
                    loadAttRecords(state.timeAttendanceRecords);
                    calculateNewHoursWorked(res.data.recordMoment, response.data.recordMoment, null);
                    setStatus(true, () => {
                      updateDashboardState();
                    });
                  })
                  .catch((error) => {
                    setLoading(false);
                    setStatus(false, () => {}, error?.response?.data?.message);
                  });
              }
            }

            const state = { ...attendanceData };
            const objIndex = _.findIndex(state.timeAttendanceRecords, ['id', dataIn.id]);
            state.timeAttendanceRecords[objIndex] = { ...res.data };
            setAttendanceData(state);
            loadAttRecords(state.timeAttendanceRecords);

            if (!dataOut.recordMoment) {
              setLoading(false);
              if (!dataOut.id) {
                setStatus(true, () => {
                  updateDashboardState();
                  calculateNewHoursWorked(res.data.recordMoment, res.data.recordMoment, {
                    in: dataIn.id,
                    out: dataOut.id,
                  });
                });
              }
            }
          })
          .catch((error) => {
            setLoading(false);
            setStatus(false, () => {}, error?.response?.data?.message);
          });
      } else {
        WhoIsInApi.addAttendanceRecords(attendanceData.id, sentDataIn)
          .then((res) => {
            if (sentDataOut.recordMoment) {
              WhoIsInApi.addAttendanceRecords(attendanceData.id, sentDataOut)
                .then((response) => {
                  setLoading(false);
                  const state = { ...attendanceData };
                  state.timeAttendanceRecords.push(response.data);
                  setAttendanceData(state);
                  loadAttRecords(state.timeAttendanceRecords);
                  calculateNewHoursWorked(res.data.recordMoment, response.data.recordMoment, null);
                  setStatus(true, () => {
                    updateDashboardState();
                  });
                })
                .catch((error) => {
                  setLoading(false);
                  setStatus(false, () => {}, error?.response?.data?.message);
                });
            } else {
              setLoading(false);
              updateDashboardState();
            }

            const state = { ...attendanceData };
            state.timeAttendanceRecords.push(res.data);
            setAttendanceData(state);
            loadAttRecords(state.timeAttendanceRecords);
            calculateNewHoursWorked(res.data.recordMoment, res.data.recordMoment, {
              in: dataIn.id,
              out: dataOut.id,
            });
          })
          .catch((error) => {
            setLoading(false);
            setStatus(false, () => {}, error?.response?.data?.message);
          });
      }
    }
  };

  const deleteAttRecordRow = (inId, outId) => {
    if (outId) {
      WhoIsInApi.deleteAttendanceRecord(attendanceData.id, outId).then(() => {
        if (inId) {
          WhoIsInApi.deleteAttendanceRecord(attendanceData.id, inId)
            .then(() => {
              setLoading(false);
              const state = { ...attendanceData };
              state.timeAttendanceRecords = state.timeAttendanceRecords.filter(
                (el) => el.id !== inId && el.id !== outId
              );
              setAttendanceData(state);
              loadAttRecords(state.timeAttendanceRecords);
              setStatus(true, () => {
                updateDashboardState();
              });
            })
            .catch((error) => {
              setLoading(false);
              setStatus(false, () => {}, error?.response?.data?.message);
            });
        }
      });
    }

    if (!outId && inId) {
      WhoIsInApi.deleteAttendanceRecord(attendanceData.id, inId)
        .then(() => {
          setLoading(false);
          const state = { ...attendanceData };
          state.timeAttendanceRecords = state.timeAttendanceRecords.filter((el) => el.id !== inId);
          setAttendanceData(state);
          loadAttRecords(state.timeAttendanceRecords);
          setStatus(true, () => {
            updateDashboardState();
          });
        })
        .catch((error) => {
          setLoading(false);
          setStatus(false, () => {}, error?.response?.data?.message);
        });
    }

    setLoading(true);
  };

  const getInfo = (attendanceRecord, inId, outId) => {
    const inObj = _.find(attendanceRecord, ['id', inId]);
    const outObj = _.find(attendanceRecord, ['id', outId]);
    return {
      in: inObj,
      out: outObj,
    };
  };

  const getEmployeeLocationsByDate = () => {
    const date = moment().format('YYYY-MM-DD');
    DashboardApi.getEmployeeLocationsByDate(selected, date).then((result) => {
      const filterActive = _.filter(result.data, ['geofencingMode', true]);
      setActiveGeofencingLocations(filterActive);
    });
  };

  const attendanceHistoryHandler = () => {
    setLoading(true);
    DashboardApi.attendanceHistory(selected)
      .then((res) => {
        setAttendanceData(res.data);
        setRamadanWS(res.data.employee?.activeWorkschedule?.activeRamadanWorkSchedule);
        loadAttRecords(res.data.timeAttendanceRecords);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getTimeTrackingSettings = () => {
    setLoadingSettings(true);
    CompanySettingsTimesheetsApi.timeTrackingSettings()
      .then((res) => {
        let havePermission = res.data.allowTimestampEdit;
        if (authState.user.roles.includes('ROLE_ADMIN')) havePermission = true;
        setCanAddEdit(havePermission);

        setLoadingSettings(false);
      })
      .catch(() => {
        setLoadingSettings(false);
      });
  };

  const recenterMap = (latitude, longitude) => {
    setMapCenter({ latitude, longitude });
  };

  useEffect(() => {
    let val = false;
    if (attendanceRecords.length === 0) {
      val = false;
    } else {
      const inObj = attendanceRecords[attendanceRecords.length - 1];
      val = !inObj?.in?.value || !inObj?.out?.value;
    }
    setButtonState(val);
  }, [attendanceRecords]);

  useEffect(() => {
    getTimeTrackingSettings();
  }, []);

  useEffect(() => {
    attendanceHistoryHandler();
    getShifts();
    getEmployeeLocationsByDate();
  }, [selected, trackingStatus]);

  return (
    <div className='hidden-x'>
      {responseStatus.resolved && <SuccessCheckmark text={responseStatus.msg} isFailed={responseStatus.isFailed} />}
      {(loading || loadingSettings) && <SpinnerRing />}
      <ContentHeader className='mx-5'>
        <div className='d-flex justify-content-between align-items-center'>
          <h5>
            {trackingStatus
              ? t('GENERAL.ATTENDANCE')
              : readName(
                  authState.user.language,
                  attendanceData?.employee?.fullName,
                  attendanceData?.employee?.fullNameArabic
                )}
          </h5>
          <IconButton icon='icon-cross-x' onClick={onClose} size='lg' color='gray' />
        </div>
      </ContentHeader>
      <Scrollbars
        className='scrollbarsWrapper hideScroll'
        autoHeight
        autoHeightMin={200}
        autoHeightMax='calc(100vh - 304px)'
      >
        <div className={cx(styles.header, 'position-relative')}>
          <div className={styles.badge}>
            <CircleBadge icon='icon-alarm-checked' size={64} className={cx(styles.icon, 'bg-white')} iconSize={32} />
          </div>
        </div>

        <div className='my-5 text-center'>
          {ramadanWS && (
            <div className='d-inline-block'>
              <Tooltip label={t('SYSTEM.OBJECTS.RAMADAN_WORK_SCHEDULE')} position='top'>
                <i className='icon-moon text-purple mb-2 fs-24' />{' '}
              </Tooltip>
            </div>
          )}
          {renderTodayShift()}
          <p className='label p-0'>{scheduled?.shifts.length > 0 ? t('GENERAL.SHIFTS') : t('GENERAL.SCHEDULE')}</p>
        </div>
        <div className='mx-5 pb-4'>
          {timeTrackingDisabled && (
            <Alert
              type='warning'
              textMessage={t('TIME_TRACKING.SETTINGS.PREVENT_TIME_TRACKING_DURING_VACATION')}
              hasIcon
            />
          )}
          <div className='row align-items-center mb-3'>
            <div className='col-4'>
              <label className='label px-2a'>{t('GENERAL.CHECK_IN')}</label>
            </div>

            <div className='col-4'>
              <label className='label px-2a'>{t('GENERAL.CHECK_OUT')}</label>
            </div>

            <div className='col-4'>
              <div className='d-flex justify-content-end'>
                {!disabledButton && canAddEdit && (
                  <IconButton
                    icon='icon-plus'
                    size='md'
                    color='gray'
                    isDisabled={timeTrackingDisabled}
                    onClick={() => {
                      const array = [...attendanceRecords];
                      array.push({
                        in: {
                          id: null,
                          value: '',
                          recordMoment: '',
                          validLocation: undefined,
                        },
                        out: {
                          id: null,
                          value: '',
                          recordMoment: '',
                          validLocation: undefined,
                        },
                        id: array.length > 0 ? array[array.length - 1].id + 1 : 1,
                        new: true,
                      });
                      setAttendanceRecords(array);
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {attendanceRecords.map((el, index) => (
            <AttendanceRecordRow
              key={el.id}
              data={el}
              onDeleteAttRecordRow={deleteAttRecordRow}
              onRemoveNewRow={(rowId) => {
                let array = [...attendanceRecords];
                array = array.filter((l) => l.id !== rowId);
                setAttendanceRecords(array);
              }}
              onSaveAttRecord={onSaveAttRecord}
              showDelete={
                !timeTrackingDisabled &&
                canAddEdit &&
                attendanceRecords?.length === index + 1 &&
                attendanceData?.payrollCompensations?.length === 0
              }
              showEdit={!timeTrackingDisabled && canAddEdit && attendanceData?.payrollCompensations?.length === 0}
              selectedDate={{}}
              updateBy={getInfo(attendanceData.timeAttendanceRecords, el.in.id, el.out.id)}
              setCoordinates={recenterMap}
            />
          ))}
        </div>

        {activeGeofencingLocations.length > 0 && (
          <div style={{ height: '280px' }} className={styles.mapDiv}>
            <MapComponent
              additionalLocations={otherLocations}
              onUpdateLatLng={() => {}}
              radius={mainLocation.geofencing.radius * 1}
              msgInfo={mainLocation.name}
              listOfMarkers={attendanceData.timeAttendanceRecords || []}
              readMode
              fitBounds
              mainLocation={mainLocation}
              zoomToBounds={mapCenter}
            />
          </div>
        )}
      </Scrollbars>

      {!timeTrackingDisabled && trackingStatus ? (
        <div className='d-flex py-4 border-t px-5 '>
          <RoundedButton
            text={trackingStatus === 'out' ? 'Check In' : 'Check Out'}
            color={trackingStatus === 'out' ? 'green' : 'warning'}
            btnStyle='contained'
            size='md'
            className='w-100'
            onClick={() => {
              updateCheckStatus();
            }}
            loading={loadingCheck || waitingLocation}
          />
        </div>
      ) : (
        <div className='d-flex justify-content-start py-4 border-t'>
          <RoundedButton
            className='ms-5'
            type='button'
            text='Go back'
            btnStyle='outlined'
            color='secondary'
            size='md'
            onClick={onGoBack}
          />
        </div>
      )}
    </div>
  );
};

AttendanceDetails.propTypes = {
  onGoBack: PropTypes.func,
  onClose: PropTypes.func,
  trackingStatus: PropTypes.string,
  loadingCheck: PropTypes.bool,
  waitingLocation: PropTypes.bool,
  updateCheckStatus: PropTypes.func,
  timeTrackingDisabled: PropTypes.bool,
};

AttendanceDetails.defaultProps = {
  onGoBack: undefined,
  onClose: undefined,
  trackingStatus: '',
  loadingCheck: false,
  waitingLocation: false,
  updateCheckStatus: undefined,
  timeTrackingDisabled: false,
};

export default AttendanceDetails;
