import React from 'react';

import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';

import TableLoading from 'components/DataTable/TableLoading';
import PaginationWrapper from 'components/Pagination/PaginationWrapper';

import styles from './DataTable.module.scss';

function getNestedValue(obj, key) {
  return key.split('.').reduce((result, prop) => result[prop], obj);
}

const DataTable = ({
  isLoading,
  data,
  TableHeader,
  TableRowComponent,
  EmptySearchComponent,
  rowKey = 'id',
  onCheck,
  checkedRows,
  fetchNextPage,
  hasNextPage,
  columns,
  zeroIndexingPagination,
  rowAdditionalProps = {},
  headerAdditionalProps = {},
  isScroll,
  isPagination,
  pagination,
  onPaginationAction,
  tableHeight,
  renderLastRow,
}) => {
  const handleScroll = async (event) => {
    if (event.top >= 0.999 && hasNextPage) {
      await fetchNextPage();
    }
  };

  if (isLoading || data?.length) {
    const renderTable = () => (
      <table className='w-100'>
        <TableHeader headerCells={columns} {...headerAdditionalProps} className={styles.fixedHeader} />
        <tbody>
          {isLoading ? (
            <TableLoading />
          ) : (
            <>
              {data?.map((rowData, index) => (
                <TableRowComponent
                  onCheck={onCheck}
                  isChecked={checkedRows.includes(rowData[rowKey])}
                  key={getNestedValue(rowData, rowKey)}
                  index={index}
                  {...rowData}
                  {...rowAdditionalProps}
                />
              ))}
              {renderLastRow && renderLastRow()}
            </>
          )}
        </tbody>
      </table>
    );

    return isScroll ? (
      <Scrollbars
        className='scrollbarsWrapper'
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        onScrollFrame={(e) => handleScroll && handleScroll(e)}
        style={{ height: tableHeight || 'calc(100vh - 335px)' }}
      >
        <div className='mx-5 d-flex flex-column h-100'>
          <PaginationWrapper
            zeroIndexing={zeroIndexingPagination}
            onPaginationAction={onPaginationAction}
            pagination={pagination}
            showPagination={isPagination}
            loading={isLoading}
            paginationDivCss='mt-auto'
          >
            {renderTable()}
          </PaginationWrapper>
        </div>
      </Scrollbars>
    ) : (
      renderTable()
    );
  }

  return <EmptySearchComponent />;
};

DataTable.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.array.isRequired,
  TableHeader: PropTypes.elementType.isRequired,
  TableRowComponent: PropTypes.elementType.isRequired,
  columns: PropTypes.array.isRequired,
  EmptySearchComponent: PropTypes.func,
  rowKey: PropTypes.string,
  onCheck: PropTypes.func,
  checkedRows: PropTypes.array,
  fetchNextPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  rowAdditionalProps: PropTypes.object,
  headerAdditionalProps: PropTypes.object,
  isScroll: PropTypes.bool,
};

DataTable.defaultProps = {
  isLoading: false,
  EmptySearchComponent: () => null,
  rowKey: 'id',
  onCheck: () => {},
  checkedRows: [],
  fetchNextPage: () => {},
  hasNextPage: false,
  rowAdditionalProps: {},
  headerAdditionalProps: {},
  isScroll: false,
};

export default DataTable;
