import React, { lazy, Suspense, useContext, useEffect } from 'react';

import cx from 'classnames';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AuthContext } from 'auth/AuthContext';
import AreaLoader from 'components/Spinner/AreaLoader';
import Header from 'modules/CompanyAssets/Header';
import CustomRoute from 'topNavigation/Router/CustomRoute';

const MyAssets = lazy(() => import('modules/CompanyAssets/MyAssets'));
const Management = lazy(() => import('./Management'));
const Insights = lazy(() => import('./Insights/Insights'));

const Assets = () => {
  const { authState } = useContext(AuthContext);
  const path = '/company-assets';
  const employee = authState?.user;

  const onSelectView = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const body = document.querySelector('body');
    body.classList.add('no-scroll');
    return () => {
      body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <div className={cx('position-relative pageContainer page-top-positioning')}>
      <Header onSelectView={onSelectView} />
      <Routes>
        <Route
          path='my-assets/:id'
          element={
            <Suspense fallback={<AreaLoader />}>
              <CustomRoute path={`${path}/my-assets/:id`} component={MyAssets} employee={employee} />
            </Suspense>
          }
        />
        <Route
          path='management/*'
          element={
            <Suspense fallback={<AreaLoader />}>
              <CustomRoute path={`${path}/management`} component={Management} />
            </Suspense>
          }
        />

        <Route
          path='insights/*'
          element={
            <Suspense fallback={<AreaLoader />}>
              <CustomRoute path={`${path}/insights`} component={Insights} />
            </Suspense>
          }
        />
        <Route
          path=''
          element={<Navigate to={`my-assets/${authState?.user?.identification || authState?.user?.id}`} replace />}
        />
      </Routes>
    </div>
  );
};

export default Assets;
