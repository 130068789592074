import cx from 'classnames';
import PropTypes from 'prop-types';

import propTypesUtility from 'utility/propTypesUtility';

import style from './style.module.scss';

const propTypes = () => ({
  id: propTypesUtility.idPropType(),
  raised: PropTypes.bool,
  style: propTypesUtility.stylePropType(),
  className: propTypesUtility.classNamePropType(),
});

const defaultProps = () => ({
  id: 'list',
  raised: false,
  style: {},
});

const classes = (raised, className) =>
  cx('pt-1 pb-1 bg-white', cx(style.container, { [style.raised]: raised }), className);

export default {
  propTypes,
  defaultProps,
  classes,
};
