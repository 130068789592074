import React, { useContext } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { AuthContext } from 'auth/AuthContext';
import Tooltip from 'components/tooltip/Tooltip';
import { useMissingTimeStampsCount } from 'modules/Dashboard/hooks/useDashboard';
import t from 'utility/setTranslation';

import styles from './Card.module.scss';
import WhoIsInTrigger from './WhoIsIn/Modal/WhoIsInTrigger';

const CardQuickAccess = ({ className, onClick, isShown, canSeeApproval, canSeeTimesheets, isShowWhoIsIn }) => {
  const { authState } = useContext(AuthContext);
  const { data } = useMissingTimeStampsCount();

  const formatRoute = () => {
    let retVal = '/time/timesheets/monthly';
    if (canSeeApproval && !canSeeTimesheets) {
      retVal = '/time/approval/monthly';
    }
    return retVal;
  };

  return (
    <div className={cx(styles.cardWrapper, className, 'position-relative')}>
      <div className={cx(styles.cardHeader, 'd-flex', 'justify-content-between', 'align-items-center')}>
        <div className={styles.title}>{t('GENERAL.QUICK_ACCESS')}</div>
      </div>

      <div className={cx(styles.cardContent, 'd-flex', 'justify-content-start', 'align-items-center')}>
        <div className='me-sm-2 me-lg-3'>
          <Link to={`/profile/${authState.user.identification}/personal`}>
            <Tooltip label={t('TEAM.PROFILE.PERSONAL')} position='top'>
              <i className={cx(styles.quicIcon, styles.personal, 'icon-user')} />
            </Tooltip>
          </Link>
        </div>

        <div className='me-sm-2 me-lg-3'>
          <Link to={`/profile/${authState.user.identification}/documents`}>
            <Tooltip label={t('TEAM.PROFILE.DOCUMENTS')} position='top'>
              <i className={cx(styles.quicIcon, styles.files, 'icon-folder-opened')} />
            </Tooltip>
          </Link>
        </div>

        <div className=''>
          <Link to={`/profile/${authState.user.identification}/contracts`}>
            <Tooltip label={t('TEAM.PROFILE.CONTRACT')} position='top'>
              <i className={cx(styles.quicIcon, styles.wallet, 'icon-contract')} />
            </Tooltip>
          </Link>
        </div>

        {isShowWhoIsIn && <WhoIsInTrigger />}

        {Boolean(data?.data.total) && (canSeeApproval || canSeeTimesheets) && (
          <div className='ms-sm-2 ms-lg-3 position-relative'>
            <i className={cx(styles.timesheetIconRedDot, 'icon-bullet-point text-danger position-absolute')} />

            <Link className={cx(styles.timesheetLink)} to={formatRoute()}>
              <Tooltip label={t('GENERAL.MISSING_STAMPS')} position='top'>
                <i className={cx(styles.quicIcon, styles.timesheet, 'icon-calendar-checked')} />
              </Tooltip>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

CardQuickAccess.propTypes = {
  className: PropTypes.string,
  canSeeApproval: PropTypes.bool,
  canSeeTimesheets: PropTypes.bool,
};

CardQuickAccess.defaultProps = {
  className: '',
  canSeeApproval: false,
  canSeeTimesheets: false,
};

export default CardQuickAccess;
