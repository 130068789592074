import React from 'react';

import { PHRList } from '@palmhr/palmhr-ui-lib';

import CheckBox from 'components/forms/components/CheckBox';
import t from 'utility/setTranslation';

import { SUBSCRIPTION_STATUS } from '../../../../../../../modules/CompanySettings/Subscription/constants';
import { getSubscriptionPricingModels } from '../../utility';

const GoLiveAddonList = ({ type, title, items, addonSelected, onSelectAddon }) => {
  const renderItem = (item) => {
    const isChecked = addonSelected.includes(item.id) || (
      item?.isSingleCharge
        ? item?.status === undefined
          ? addonSelected.includes(item.id)
          : item?.status !== SUBSCRIPTION_STATUS.OPT_OUT
        : addonSelected.includes(item.id)
    );

    const isDisabled =
      item?.isSingleCharge && item?.status !== undefined && item?.status !== SUBSCRIPTION_STATUS.OPT_OUT;

    return (
      <div className='d-flex justify-content-between px-2' key={item.id}>
        <div className='d-flex mb-3 gap-2' style={{ width: '100%' }}>
          <CheckBox
            value={`${item.name}-${item.id}`}
            checked={isChecked}
            onChange={(checked) => onSelectAddon(checked, item.name, item.id)}
            name={`${item.name}-${item.id}`}
            isDisabled={isDisabled}
          />
          <div className='d-flex flex-column'>
            <h4 className={`font-size-14 ${isChecked ? 'text-main' : 'text-gray'}`}>{t(item.name)}</h4>
            <p className={`font-size-12 ${isChecked ? 'text-main' : 'text-gray'}`}>{t(item.description)}</p>
          </div>
        </div>
        <div className='d-flex flex-column justify-content-start align-items-end' style={{ width: '20%' }}>
          <h5 className='subtitle font-size-14 mb-0'>{`${item.defaultUnitPrice} ${item.currency}`}</h5>
          <h6 className='font-size-12 text-gray mb-0 text-end'>
            {getSubscriptionPricingModels(
              item.isSingleCharge,
              item.pricingUnit,
              item.pricingModel,
              item.defaultBillingPeriod
            )}
          </h6>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='px-4 py-2 mb-3' style={{ backgroundColor: type === 'primary' ? '#1a938a26' : '#D2A15326' }}>
        <h5
          className='mb-0'
          style={{ fontSize: '14px', fontWeight: 'bold', color: type === 'primary' ? '#1A938A' : '#D2A153' }}
        >
          {title}
        </h5>
      </div>
      <PHRList items={items} renderItem={renderItem} className='list-unstyled' />
    </div>
  );
};

export default GoLiveAddonList;
