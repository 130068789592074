import React from 'react';

import { PHRPageHeader } from '@palmhr/palmhr-ui-lib';
import cx from 'classnames';
import PropTypes from 'prop-types';

const SubPageHeader = ({
  title,
  isLoading,
  renderSideActionComponent,
  renderTitleActionComponent,
  showBackButton,
  onClickGoBack,
  navTabs,
  className,
}) => (
  <PHRPageHeader
    title={title}
    isLoading={isLoading}
    renderSideActionComponent={renderSideActionComponent}
    renderTitleActionComponent={renderTitleActionComponent}
    isShowBackButton={showBackButton}
    navTabs={navTabs || []}
    onClickGoBack={onClickGoBack}
    className={cx(className, 'py-2a')}
  />
);

SubPageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  renderSideActionComponent: PropTypes.func,
  renderTitleActionComponent: PropTypes.func,
  navTabs: PropTypes.array,
};

SubPageHeader.defaultProps = {
  renderSideActionComponent: () => {},
  renderTitleActionComponent: () => {},
  className: '',
  navTabs: [],
  isLoading: false,
};

export default SubPageHeader;
