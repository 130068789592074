import React, { useState, useEffect, useContext } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import cx from 'classnames';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import CompanySettingsApi from 'api/CompanySettingsApi';
import CompanySettingsPerformanceApi from 'api/CompanySettingsPerformanceApi';
import { AuthContext } from 'auth/AuthContext';
import Modal from 'components/Modal';
import RenewalModal from 'components/RenewalModal/RenewalModal';
import { MODULES_CONFIGS } from 'constants/moduleConfigurations';
import TrialCounter from 'modules/CompanySettings/Subscription/Counter/TrialCounter';
import { PerformanceContext } from 'modules/CompanySettings/Team/Performance/PerformanceContext';
import { useModulePermissions } from 'shared/useModulePermissions';
import { useVisibility } from 'shared/useVisibility';
import { queryKeys } from 'topNavigation/hooks/useAccount';
import MainNavBarBanner from 'topNavigation/NavBar/MainNavBarBanner/MainNavBarBanner';
import countPercent from 'utility/countPercent';
import t from 'utility/setTranslation';

import InboxNotifications from './InboxNotifications';
import styles from './MainNavBar.module.scss';
import MainNavItem from './MainNavItem';
import ProfileNav from './ProfileNav';
import SuspendedDetails from './SuspendedDetails';
import { clientTypes } from '../../constants/clientTypes';

const MainNavBar = ({ onSelectNotification, onCreateNewTask }) => {
  const queryClient = useQueryClient();
  const { isModuleEnabled: isRecruitmentEnabled } = useModulePermissions(MODULES_CONFIGS.RECRUITMENT.NAME);
  const { authState } = useContext(AuthContext);
  const { performanceState, dispatchPerformance } = useContext(PerformanceContext);
  const [backgroundImg, setBackgroundImg] = useState('');
  const [showCounter, setShowCounter] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();
  const { isUserRespondedToRenewal, account, isRenewalPending } =
    queryClient.getQueryState(queryKeys.subscriptionData)?.data?.data || {};

  const [counterData, setCounterData] = useState({
    type: '',
    percent: 0,
    daysLeft: 0,
  });

  const isAdmin = authState?.user?.roles?.includes('ROLE_ADMIN');
  const isHr = authState?.user?.roles?.includes('ROLE_HR');
  const body = document.querySelector('body');

  const showPerformance =
    performanceState.goalsEnabled || performanceState.oneOnOneEnabled || performanceState.reviewsEnabled;

  const getCounterData = (isCustomer, expireDate, createdAt) => {
    const data = {};
    data.type = isCustomer ? 'trial' : 'demo';
    if (expireDate && moment().isSameOrBefore(expireDate, 'day')) {
      const today = moment().format('YYYY-MM-DD');
      const formatExpDate = moment(expireDate).format('YYYY-MM-DD');
      const formatCreatedDate = moment(createdAt).format('YYYY-MM-DD');
      const daysDiff = moment(formatExpDate).diff(today, 'days');
      const total = moment(formatExpDate).diff(formatCreatedDate, 'days');

      data.daysLeft = daysDiff && daysDiff > 0 ? daysDiff : 0;
      data.percent = isCustomer ? countPercent(7 - daysDiff, 7) : countPercent(total - daysDiff, total);
      setShowCounter(true);
    }
    setCounterData(data);
  };

  useEffect(() => {
    if (!authState.companyData?.paymentDetails) {
      const isCustomer = authState.companyData?.clientType === clientTypes.LIVE_INSTANCE;
      const expDate = isCustomer ? authState.companyData?.trialExpireAt : authState.companyData?.demoExpireAt;
      getCounterData(isCustomer, expDate, authState.companyData?.createdAt);
    } else {
      setShowCounter(false);
    }

    if (authState?.user?.userBackground && location?.pathname !== '/company-settings') {
      setBackgroundImg(authState.user.userBackground);
    } else {
      setBackgroundImg(authState.companyBackground);
    }

    if (authState?.user?.language === 'ar') {
      body.classList.add('lang-ar');
    } else {
      body.classList.remove('lang-ar');
    }
  }, [authState]);

  useEffect(() => {
    CompanySettingsPerformanceApi.performanceAccess()
      .then((res) => {
        dispatchPerformance({
          type: 'set',
          payload: {
            modulePermission:
              (res.data.access.canViewGoals && performanceState.goalsEnabled) ||
              (res.data.access.canViewOneOnOnes && performanceState.oneOnOneEnabled),
            permissionsModule: res.data.access,
          },
        });
      })
      .catch(() => {});
    CompanySettingsApi.getPerformanceConfig()
      .then((res) => {
        dispatchPerformance({ type: 'set', payload: res.data });
      })
      .catch(() => {});
  }, [authState, showPerformance]);

  const { isOpen, open, close: onRenewalModalClose } = useVisibility();

  useEffect(() => {
    if (!isUserRespondedToRenewal && isRenewalPending && (isAdmin || isHr)) {
      open();
    }
  }, [isUserRespondedToRenewal]);

  const isSuspended = account?.status === 'SUSPENDED';

  if (isSuspended) {
    return null;
  }

  return (
    <>
      <MainNavBarBanner closeRenewModal={onRenewalModalClose} openRenewalModal={open} />
      <header
        className={cx(styles.headerWrapper, { [styles.small]: location.pathname.includes('dashboard') })}
        style={{
          backgroundImage:
            !!backgroundImg && `linear-gradient(rgba(4, 20, 28, 0.55), rgba(4, 20, 28, 0.55)), url(${backgroundImg})`,
        }}
      >
        <nav className='navbar navbar-expand-md navbar-dark'>
          <div className='pageContainer'>
            <div className='d-flex justify-content-between w-100'>
              <div>
                <ul className='no-style navbar-nav mt-2 mt-lg-0'>
                  <MainNavItem title='Home' href='/dashboard' id='home' />
                  <MainNavItem title='Team' href='/organization' id='team' />
                  <MainNavItem title='Time' href='/time' id='time' />
                  <MainNavItem title='Pay' href='/pay' id='pay' />
                  <MainNavItem title='Requests' href='/requests' id='requests' />
                  <MainNavItem title='Tasks' href='/tasks' id='tasks' />
                  <MainNavItem title='Files' href='/files' id='files' />
                  <MainNavItem title='Assets' href='/company-assets' id='assets' />
                  {showPerformance && performanceState.modulePermission && (
                    <MainNavItem title='Performance' href='/performance' id='performance' />
                  )}
                  {isRecruitmentEnabled && <MainNavItem title='Recruitment' href='/recruitment' id='recruitment' />}
                </ul>
              </div>
            </div>
          </div>

          <div className={cx(styles.topRightNav, 'd-flex mt-2 align-items-center')}>
            {showCounter && <TrialCounter data={counterData} />}
            <InboxNotifications onSelectNotification={onSelectNotification} />
            <ProfileNav />
          </div>
        </nav>
      </header>

      <Modal
        isOpen={openModal}
        styles={{ width: '640px', padding: '0', borderRadius: '8px' }}
        onRequestClose={() => {
          setOpenModal(false);
        }}
        title={t('GENERAL.INVOICE')}
      >
        <SuspendedDetails
          onClose={() => {
            setOpenModal(false);
          }}
        />
      </Modal>

      <RenewalModal isOpen={isOpen} onRequestClose={onRenewalModalClose} onCreateTask={onCreateNewTask} />
    </>
  );
};

MainNavBar.propTypes = {};

MainNavBar.defaultProps = {};

export default MainNavBar;
