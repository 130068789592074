import React, { useMemo } from 'react';

import moment from 'moment';
import ReactApexChart from 'react-apexcharts';

import './CandidateApplicationsChart.css';
import Line from 'modules/EngagementCenter/shared/loaders/Line';
import { extractApplicationsByDate, getAllDaysOfRange } from 'modules/Recruitment/Statistics/helpers';
import { useQueryApplicationPerDay } from 'modules/Recruitment/Statistics/hooks/useStatistics';

import { chartOptions } from './chartOptions';

const CandidateApplicationsChart = ({ jobPostId, selectedYear, jobPostPublishDate, jobPostArchiveDate, isLoading }) => {
  const todayYear = new Date().getFullYear();
  const jobPostPublishYear = moment(jobPostPublishDate).year();

  const startDate =
    jobPostPublishYear === selectedYear ? moment(jobPostPublishDate) : moment(new Date(`${selectedYear}-01-01`));
  const endDate = todayYear === selectedYear ? moment(new Date()) : moment(jobPostArchiveDate);

  const { isLoading: isGraphDataLoading, data } = useQueryApplicationPerDay({
    jobPostId,
    params: {
      filters: JSON.stringify({
        startDate: `${selectedYear}-01-01`,
        endDate: `${selectedYear}-12-31`,
      }),
    },
  });

  const applicationsPerDay = data?.data?.items;

  const series = useMemo(
    () => [
      {
        data: applicationsPerDay
          ? getAllDaysOfRange(startDate, endDate, extractApplicationsByDate(applicationsPerDay))
          : [],
      },
    ],
    [selectedYear, JSON.stringify(startDate), JSON.stringify(endDate)]
  );

  if (isGraphDataLoading || isLoading) {
    return <Line style={{ height: 232, width: '100%' }} gradient='loading-gradient' className='radius-4' />;
  }

  return (
    <section className='CandidateApplicationsChart'>
      <ReactApexChart id='area-datetime' options={chartOptions(endDate)} series={series} type='area' height={232} />
    </section>
  );
};

export default CandidateApplicationsChart;
