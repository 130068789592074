import React, { useState } from 'react';

import Modal from 'components/Modal';

import AddStripePaymentMethod from './AddStripePaymentMethod';
import RoundedButton from '../buttons/RoundedButton/RoundedButton';

const AddStripePaymentMethodModal = ({ isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const handleConfirmLoadingChange = (loading) => {
    setIsLoading(loading);
  };

  const handlePaymentSuccessChange = (success) => {
    setPaymentSuccess(success);
    setIsConfirmLoading(false);
  };

  return (
    <Modal
      styles={{
        width: '100%',
        maxWidth: '480px',
        padding: 0,
        height: '90%',
        maxHeight: '910px',
      }}
      isOpen={isOpen}
      onRequestClose={isLoading || isConfirmLoading || paymentSuccess ? () => {} : onClose}
      shouldCloseOnOverlayClick={false}
      showHeader
      title='Enter Payment Details'
    >
      <div style={{ height: '710px', overflow: 'hidden', overflowY: 'scroll' }}>
        <AddStripePaymentMethod
          onConfirmLoadingChange={handleConfirmLoadingChange}
          onPaymentSuccessChange={handlePaymentSuccessChange}
        />
      </div>
      <div className='mt-5 d-flex align-items-center justify-content-end border-t py-4 gap-2'>
        <RoundedButton
          onClick={onClose}
          btnStyle='outlined'
          size='sm'
          color='gray'
          text='Cancel'
          isDisabled={isLoading || isConfirmLoading || paymentSuccess}
        />
        <RoundedButton
          onClick={() => window.location.reload()}
          btnStyle='contained'
          color='primary'
          size='sm'
          text='Save'
          className='me-3'
          isDisabled={isLoading || isConfirmLoading || !paymentSuccess}
        />
      </div>
    </Modal>
  );
};

export default AddStripePaymentMethodModal;
