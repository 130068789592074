import React, { useContext } from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import ReactPlayer from 'react-player';

import image from 'assets/img/teampalm.jpg';
import { AuthContext } from 'auth/AuthContext';
import IconButton from 'components/buttons/IconButton/IconButton';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

import styles from './ReleaseDetails.module.scss';

const ReleaseModal = ({ release, close }) => {
  const { authState } = useContext(AuthContext);

  return (
    <div className={cx(styles.wrapper, 'position-relative', 'bg-white', 'w-100', 'h-auto', 'p-0')}>
      <IconButton
        className={styles.closeModalBtn}
        icon='icon-cross-x'
        onClick={() => {
          close();
        }}
        size='lg'
        color='dark'
      />

      <div className={cx(styles.header, styles.modalVideo, 'mb-5 position-relative')}>
        {release?.videoUrl ? (
          <ReactPlayer width='100%' height='346px' url={release.videoUrl} controls />
        ) : (
          <img src={image} alt='video-placeholder' />
        )}
      </div>

      <div className={styles.subHeader}>
        <div className='d-flex justify-content-between align-items-center'>
          <div className={cx(styles.title, 'd-flex justify-content-between align-items-center')}>
            <i className='icon-rocket me-3' />

            <span>{readName(authState.user.language, release.title, release.titleArabic)}</span>
          </div>

          <div className={styles.releaseDate}>
            <div>{moment(release?.releaseDate).format('MMM DD, YYYY')}</div>

            <p>{t('GENERAL.RELEASE_DATE')}</p>
          </div>
        </div>
      </div>

      <div className=''>
        <Scrollbars
          className='scrollbarsWrapper'
          autoHide
          autoHeight
          autoHeightMax='calc(97vh - 598px)'
          renderView={({ style, ...props }) => <div {...props} style={{ ...style, overflowX: 'hidden' }} />}
        >
          <div className='mt-6 pe-5 mb-5 h-100'>
            {release?.featureItems && release.featureItems.length > 0 && (
              <div className={cx(styles.infoSection, 'ps-7')}>
                <i className={cx(styles.icon, 'icon-star')} />

                <h5 className={styles.sectionTitle}>{t('GENERAL.WHAT_IS_NEW')}</h5>
                <ul className={styles.ul}>
                  {release.featureItems.map((el) => (
                    <li key={el.id} className='ps-4 d-flex align-items-start gap-1'>
                      <span className={cx(styles.itemText, 'me-auto')}>
                        {readName(authState.user.language, el.title, el.titleArabic)}
                      </span>
                      {el.videoUrl && (
                        <IconButton
                          icon='icon-video text-gray'
                          onClick={() => {
                            window.open(el.videoUrl, '_target');
                          }}
                        />
                      )}
                      {el.documentUrl && (
                        <IconButton
                          icon='icon-google-docs text-gray'
                          onClick={() => {
                            window.open(el.documentUrl, '_target');
                          }}
                        />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {release?.improvementItems && release.improvementItems.length > 0 && (
              <div className={cx(styles.infoSection, 'ps-7 h-100')}>
                <i className={cx(styles.icon, 'icon-list')} />

                <h5 className={styles.sectionTitle}>{t('GENERAL.WHAT_IS_IMPROVED')}</h5>
                <ul className={styles.ul}>
                  {release.improvementItems.map((el) => (
                    <li key={el.id} className='ps-4 d-flex align-items-start gap-1'>
                      <span className={cx(styles.itemText, 'me-auto')}>
                        {readName(authState.user.language, el.title, el.titleArabic)}
                      </span>
                      {el.videoUrl && (
                        <IconButton
                          icon='icon-video text-gray'
                          onClick={() => {
                            window.open(el.videoUrl, '_target');
                          }}
                        />
                      )}
                      {el.documentUrl && (
                        <IconButton
                          icon='icon-google-docs text-gray'
                          onClick={() => {
                            window.open(el.documentUrl, '_target');
                          }}
                        />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Scrollbars>
      </div>

      <div className='mt-3 mb-2 d-flex align-items-center border-t justify-content-end pt-4 btn-modal-box'>
        <RoundedButton
          type='button'
          text='ok'
          btnStyle='contained'
          color='primary'
          size='md'
          onClick={() => {
            close();
          }}
        />
      </div>
    </div>
  );
};

ReleaseModal.propTypes = {
  close: PropTypes.func,
  release: PropTypes.object,
};

ReleaseModal.defaultProps = {
  close: undefined,
  release: {},
};

export default ReleaseModal;
