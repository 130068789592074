import React, { useContext } from 'react';

import { Outlet } from 'react-router';

import { AuthContext } from 'auth/AuthContext';
import PageHeader from 'components/PHRPageHeader/PageHeader';
import PageWrapper from 'components/PHRPageWrapper/PageWrapper';
import SubPageWrapper from 'components/PHRSubPageWrapper/SubPageWrapper';
import { MODULES_CONFIGS } from 'constants/moduleConfigurations';
import { useModulePermissions } from 'shared/useModulePermissions';
import { PrivateRoutes } from 'topNavigation/Router/routes';
import t from 'utility/setTranslation';

const Recruitment = () => {
  const { enabledFeatures } = useModulePermissions(MODULES_CONFIGS.RECRUITMENT.NAME);
  const isCandidatesFeatureEnabled = enabledFeatures.includes(
    MODULES_CONFIGS.RECRUITMENT.FEATURES.CANDIDATES.toLowerCase()
  );
  const { authState } = useContext(AuthContext);

  const navItems = [{ title: 'jobs', href: PrivateRoutes.recruitmentJobs }];

  const isHr = authState.user.roles.includes('ROLE_ADMIN');
  const isAdmin = authState.user.roles.includes('ROLE_HR');

  if (isCandidatesFeatureEnabled && (isHr || isAdmin)) {
    navItems.push({ title: 'candidates', href: PrivateRoutes.recruitmentCandidates });
  }

  return (
    <PageWrapper>
      <PageHeader isBeta navItems={navItems} pageTitle={t('NAVBAR.RECRUITMENT')} />
      <SubPageWrapper className='mb-0'>
        <Outlet />
      </SubPageWrapper>
    </PageWrapper>
  );
};

export default Recruitment;
