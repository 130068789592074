/* eslint-disable */

import React from 'react';
import utility from './utility';

const ListItem = ({ id, children, disabled, onClick, hoverable, color, size, active }) => {
  return (
    <li
      id={id}
      className={utility.containerClasses(disabled, onClick, active, size, color, hoverable)}
      onClick={onClick}
    >
      {children}
    </li>
  );
};

ListItem.propTypes = utility.propTypes();
ListItem.defaultProps = utility.defaultProps();

export default ListItem;
