import http, { httpPalmHrAPI } from './Api';

class PayApi {
  static checkPayrollAccess() {
    return http.get(`pay/access`);
  }

  static getSetupProgress() {
    return http.get('/pay/payrolls/setup-progress');
  }

  static getStartingPeriod() {
    return http.get('/settings/pay/payroll-start');
  }

  static editStartingPeriod(data) {
    return http.put('/settings/pay/payroll-start', data);
  }

  static disableProjection() {
    return http.put('/settings/pay/payroll-start/disable-projections');
  }

  static enableProjection() {
    return http.put('/settings/pay/payroll-start/enable-projections');
  }

  // new api block
  static getPayrollMonthsByRange(start, end) {
    return http.get(`pay/payroll-months/year-month-overview/from/${start}/to/${end}`);
  }

  static getPayrollListByMonth(year, month) {
    return http.get(`pay/payroll-months/list/year/${year}/month/${month}`);
  }

  static disablePayrollMonth(payrollMonth) {
    return http.delete(`pay/payroll-months/${payrollMonth}/disable`);
  }

  static enablePayrollMonth(le, data) {
    return http.post(`pay/legal-entity/${le}/enable-payroll-month`, data);
  }

  static rebuildPayrollMonth(payrollMonth, data) {
    return http.put(`pay/payroll-months/${payrollMonth}/rebuild-by-employees`, data);
  }

  static getPayrollDetails(id) {
    return http.get(`pay/payroll-months/${id}/overview`);
  }

  static getPayrollDetailsByLE(legalEntity, year, month) {
    return http.get(`pay/payroll-months/legal-entity/${legalEntity}/year/${year}/month/${month}/overview`);
  }

  static getMissingDetails(id) {
    return http.get(`pay/payroll-months/${id}/missing-details`);
  }

  static getActionsCount(id) {
    return http.get(`pay/payroll-months/${id}/payslip-items-count`);
  }

  static getApproversListByStep(id, step) {
    return http.get(`pay/payroll-months/${id}/step/${step}`);
  }

  static getPayrollPayslips(id, filters = '') {
    return http.get(`pay/payslips/payroll-month/${id}${filters}`);
  }

  static deletePayslips(id, filters = '', data) {
    return http.put(`pay/payroll-months/${id}/delete-payslips${filters}`, data);
  }

  static changePayslipsStep(id, step, filters = '', data) {
    if (step === 'payment') {
      return http.post(`pay/payroll-months/${id}/payrun${filters}`, data);
    }
    return http.put(`pay/payroll-months/${id}/change-payslips-step/${step}${filters}`, data);
  }

  static movePayslip(id, data) {
    return http.put(`pay/payslips/${id}/change-step`, data);
  }

  static deletePayslip(id) {
    return http.delete(`pay/payslips/${id}`);
  }

  static rebuildPayslip(id) {
    return http.put(`pay/payslips/${id}/rebuild`);
  }

  static getFilterOptions() {
    return http.get(`pay/payroll-months/filter-options`);
  }

  static getACFilterOptions() {
    return http.get(`pay/action-centar/filter-options`);
  }

  static getActionItems(filters = '') {
    return http.get(`pay/action-centar${filters}`);
  }

  static getPayslipItem(id) {
    return http.get(`pay/payslip-item/${id}`);
  }

  static editPayslipItem(id) {
    return http.put(`pay/payslip-item/${id}`);
  }

  static addToPayslip(id, data) {
    return http.put(`pay/payslip-item/${id}/add-to-payslip${data.payslip ? '?resolve=1658392555' : ''}`, data);
  }

  static createPayslipItem(data) {
    return http.post(`pay/payslip-items/create`, data);
  }

  static rejectPayslipItem(id) {
    return http.put(`pay/payslip-item/${id}/reject`);
  }

  static movePayslipItemToPending(id) {
    return http.put(`pay/payslip-item/${id}/move-to-pending`);
  }

  static movePayslipItemToMonth(id, data) {
    return http.put(`pay/payslip-item/${id}/move-payment`, data);
  }

  static deletePayslipItem(id) {
    return http.delete(`pay/payslip-item/${id}/delete`);
  }

  static markPayslipAsPaid(id, data) {
    return http.put(`pay/payslips/${id}/mark-as-paid`, data);
  }

  static markPayslipAsPaidBulk(payroll, payrun, data, filters = '') {
    return http.put(`pay/payroll-months/${payroll}/payrun/${payrun}/mark-payslips-paid${filters}`, data);
  }

  static downloadPayslips(payroll, payrun, data, filters = '') {
    return http.put(`pay/payroll-months/${payroll}/payrun/${payrun}/download`, data, {
      responseType: 'arraybuffer',
    });
  }

  static markItemAsPaid(id, data) {
    return http.put(`pay/payslip-item/${id}/mark-as-paid`, data);
  }

  static actionItemsBulk(type, filters = '', data) {
    if (type === 'delete') {
      return http.delete(`pay/payslip-items/delete${filters}`, data);
    }
    return http.put(`pay/payslip-items/${type}${filters}`, data);
  }

  static getPayslipsByPayrun(payrollId, payrunId, filters = '') {
    return http.get(`pay/payslips/payroll-month/${payrollId}/payrun/${payrunId}${filters}`);
  }

  static getPayrunOverview(payrollId, id) {
    return http.get(`pay/payroll-month/${payrollId}/payrun/${id}/overview`);
  }

  static deleteFromPayrun(payrollMonth, payrun, payslip) {
    return http.put(`pay/payroll-months/${payrollMonth}/payrun/${payrun}/payslips/${payslip}/remove`);
  }

  static deletePayrun(payrollMonth, payrun) {
    return http.delete(`pay/payroll-months/${payrollMonth}/payrun/${payrun}`);
  }

  static rejectPayrun(payrollMonth, payrun) {
    return http.put(`pay/payroll-months/${payrollMonth}/payrun/${payrun}/mark-rejected`);
  }

  static repostPayrun(payrollMonth, payrun) {
    return http.put(`pay/payroll-months/${payrollMonth}/payrun/${payrun}/repost-accounting`);
  }

  static getPayslipDetailsById(payslipId) {
    return http.get(`pay/payslips/${payslipId}`);
  }

  static dowloadPayrun(id, type, format, query) {
    const timestamp = new Date().getTime();
    return http.get(`pay/payslips/payrun/${id}/${type}/${format}?${query}_=${timestamp}`);
  }

  static markCommentAsSeen(payslip) {
    return http.put(`/pay/payslips/${payslip}/mark-comments-seen`);
  }

  static addAdditionOrDeduction(payrollMonth, data) {
    return http.post(`/pay/action-centar/payroll-month/${payrollMonth}/create-items`, data);
  }

  static getRebuildInfoDetails(payrollMonth) {
    return http.get(`/pay/payroll-months/${payrollMonth}/rebuild-details`);
  }

  static payslipActivity(id, filters = '') {
    return http.get(`pay/activities/payslip/${id}${filters}`);
  }

  static payslipItemActivity(payslipItem, filters = '') {
    return http.get(`pay/activities/item/${payslipItem}${filters}`);
  }

  static actionCenterActivity(filters = '') {
    return http.get(`pay/activities/action-center${filters}`);
  }

  static changePaymentDate(payrollMonth, payrun, data) {
    return http.put(`/pay/payroll-months/${payrollMonth}/payrun/${payrun}/set-payment-date`, data);
  }
  // end new block

  static bankRequest(data) {
    return http.post('/settings/pay/wps/bank-accounts/request-bank', data);
  }

  static archivePayroll(legalEntityId, year, month) {
    return http.put(`/pay/payrolls/legal-entity/${legalEntityId}/year/${year}/month/${month}/archive`);
  }

  static finalizePayroll(id, data) {
    return http.put(`/pay/payrolls/${id}/finalize`, data);
  }

  static repeatPaymentPayroll(id, data) {
    return http.put(`/pay/payrolls/${id}/repeat-online-payment`, data);
  }

  static changePayDate(id, data) {
    return http.put(`/pay/payrolls/${id}/set-payment-date`, data);
  }

  static completePayslip(id, data) {
    return http.put(`/pay/payrolls/${id}/pay-slips/mark-complete`, data);
  }

  static uncompletePayslip(id, data) {
    return http.put(`/pay/payrolls/${id}/pay-slips/mark-draft`, data);
  }

  static rejectPayslip(id, data) {
    return http.put(`/pay/payrolls/${id}/pay-slips/mark-rejected`, data);
  }

  static markCommentSeen(payroll, payslip) {
    return http.put(`/pay/payrolls/${payroll}/pay-slips/${payslip}/mark-comments-seen`);
  }

  static createPayroll(data) {
    return http.post('/pay/payrolls', data);
  }

  static getAvailableEmployees(legalEntityId, year, month, kind = 'wps') {
    return http.get(
      `pay/payrolls/legal-entity/${legalEntityId}/year/${year}/month/${month}/available-employees/${kind}`
    );
  }

  static getPayrollsByYear(legalEntityId, year) {
    return http.get(`pay/payrolls/legal-entity/${legalEntityId}/year/${year}/monthly`);
  }

  static getPayrollById(id) {
    return http.get(`/pay/payrolls/${id}`);
  }

  static getProgress(id) {
    return http.get(`/pay/payrolls/${id}/progress`);
  }

  static getWorkflow(date) {
    return http.get(`/settings/payroll-workflows?date=${date}`);
  }

  static removeFromPayrun(payroll, payslip) {
    return http.delete(`/pay/payrolls/${payroll}/pay-slips/${payslip}`);
  }

  static getPayslipsById(id, filter = '') {
    return http.get(`/pay/payrolls/${id}/pay-slips${filter}`);
  }

  static getPayslipsDetails(payroll, id) {
    return http.get(`/pay/payrolls/${payroll}/pay-slips/${id}`);
  }

  static getPayslipPayments(em, le, currency, year, month, kind) {
    return http.get(
      `employee/${em}/legal-entity/${le}/currency/${currency}/year/${year}/month/${month}/kind/${kind}/payment-methods`
    );
  }

  static addModifier(payroll, id, type, data) {
    return http.post(`/pay/payrolls/${payroll}/pay-slip/${id}/items/${type}`, data);
  }

  static addMultipleModifier(payroll, type, data) {
    return http.post(`pay/payrolls/${payroll}/pay-slip/items/multiple-${type}`, data);
  }

  static editModifier(payroll, payslip, id, data) {
    return http.put(`/pay/payrolls/${payroll}/pay-slip/${payslip}/items/${id}`, data);
  }

  static deleteModifier(payroll, payslip, id) {
    return http.delete(`/pay/payrolls/${payroll}/pay-slip/${payslip}/items/${id}`);
  }

  static dowloadWpsPayrun(id, type) {
    const timestamp = new Date().getTime();
    return http.get(`/pay/payrolls/${id}/wps-export/${type}?_=${timestamp}`);
  }

  static dowloadNonWpsPayrun(id, type) {
    const timestamp = new Date().getTime();
    return http.get(`/pay/payrolls/${id}/non-wps-export/${type}?_=${timestamp}`);
  }

  /* ACTIVITY LOG */
  static getPayrollActivities(payrollId, filters = '') {
    return http.get(`/pay/payrolls/${payrollId}/activities${filters}`);
  }

  static getPayrollPayslipActivities(payrollId, payslipId, filters = '') {
    return http.get(`/pay/payrolls/${payrollId}/pay-slips/${payslipId}/activities${filters}`);
  }

  static getPayrollPayslipItemActivities(payrollId, payslipId, payslipItemId, filters = '') {
    return http.get(`/pay/payrolls/${payrollId}/pay-slips/${payslipId}/items/${payslipItemId}/activities${filters}`);
  }

  static getContract(id, date = '') {
    return http.get(`/contract/employee/${id}?date=${date}`);
  }

  static getContractInMonth(employeeId, year, month, kind) {
    return http.get(`/contract/employee/${employeeId}/year/${year}/month/${month}/${kind}`);
  }

  // Finance alignments
  static getFinanceAlignments(payrollId, payslipId, filters = '') {
    return http.get(`/payrolls/${payrollId}/pay-slip/${payslipId}/finance-alignments${filters}`);
  }

  static actionFinanceAlignment(id, action, data, params = '') {
    return http.put(`payrolls/finance-alignments/${id}/${action}${params}`, data);
  }

  static dowloadXmlPayrun(id) {
    const timestamp = new Date().getTime();
    return http.get(`/pay/payrolls/${id}/xms-export?_=${timestamp}`);
  }

  static getPayrollAccessInsights() {
    return http.get('pay/payrolls/access-insights');
  }

  static getActiveIntegrations() {
    return httpPalmHrAPI.get('api/v1/integrations/accounting/available-integration');
  }

  static postActiveIntegrations({ integration, payrun }) {
    return http.post(`integrations/accounting/integrations/${integration}/payrun/${payrun}`);
  }

  static getPayrunAccountingStatus(payrun) {
    return http.get(`integrations/accounting/integrations/payrun/${payrun}/status`);
  }

  static getAvailablePaymentMethods(payslip) {
    return http.get(`payslips/${payslip}/avaliable-payment-methods`);
  }
}

export default PayApi;
