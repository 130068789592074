import React, { useState } from 'react';

import t from 'utility/setTranslation';

import { ENVIRONMENT_PLAN_OPTIONS } from '../../constants';
import OptionSelector from '../GoLiveOptionSelector/GoLiveOptionSelector';

const GoLiveEnvironmentSelector = ({ formik }) => {
  const [selectedEnvironment, setSelectedEnvironment] = useState(formik.values.continueWith);

  const handleSelectingEnvironment = (value) => {
    setSelectedEnvironment(value);
    formik.setFieldTouched('continueWith', true);
    formik.setFieldValue('continueWith', value);
  };
  return (
    <div className='d-flex flex-column'>
      <h6 className='subtitle py-2'>{t('NAVBAR.SELECT_ENVIRONMENT_PLAN')}</h6>
      <OptionSelector
        options={ENVIRONMENT_PLAN_OPTIONS}
        selectedOption={selectedEnvironment}
        handleOptionChange={handleSelectingEnvironment}
        error={formik.touched?.continueWith && formik.errors?.continueWith}
        onBlur={formik.handleBlur}
      />
    </div>
  );
};

export default GoLiveEnvironmentSelector;
