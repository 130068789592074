import React, { useContext, useEffect, useState } from 'react';

import { useFormik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';

import CompanyLocationsApi from 'api/CompanyLocationsApi';
import CompanySettingsApi from 'api/CompanySettingsApi';
import { AuthContext } from 'auth/AuthContext';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import DateInput from 'components/forms/components/DateInput';
import FormContext from 'components/forms/components/FormContext';
import MultipleSelectField from 'components/forms/components/MultipleSelectField';
import SelectField from 'components/forms/components/SelectField';
import Textarea from 'components/forms/components/Textarea';
import TextInput from 'components/forms/components/TextInput';
import IconPicker from 'components/IconPicker';
import SpinnerRing from 'components/Spinner/SpinnerRing';
import SuccessCheckmark from 'components/SuccessCheckmark/SuccessCheckmark';
import useCustomSelectData from 'shared/useCustomSelectData';
import useDebounce from 'shared/useDebounce';
import formatOptions from 'utility/formatOptions';
import t from 'utility/setTranslation';

const selectStyles = {
  menuList: (provided) => ({
    ...provided,
    maxHeight: '200px',
  }),
};

const AddEventModal = ({ selected, onSave, setIsFormDirty, responseStatus, saving, day }) => {
  const { authState } = useContext(AuthContext);

  const calendarPickerIcons = [
    'icon-users',
    'icon-calendar-dates',
    'icon-alarm',
    'icon-building',
    'icon-case',
    'icon-contract',
    'icon-pin',
    'icon-star',
    'icon-heart',
    'icon-sunbed',
    'icon-sun',
    'icon-megaphone',
    'icon-rocket',
    'icon-flag',
  ];

  const audience = [
    {
      value: 'EVERYONE',
      name: t('GENERAL.EVERYONE'),
      id: 'EVERYONE',
      option: 'EVERYONE',
    },
    {
      value: 'DEPARTMENT',
      name: t('GENERAL.DEPARTMENT'),
      id: 'DEPARTMENT',
      option: 'DEPARTMENT',
    },
    {
      value: 'TEAM',
      name: t('GENERAL.TEAM'),
      id: 'TEAM',
      option: 'TEAM',
    },
    {
      value: 'LOCATION',
      name: t('GENERAL.LOCATION'),
      id: 'LOCATION',
      option: 'LOCATION',
    },
  ];

  /* LOCATIONS */
  const [searchLocationValue, setSearchLocationValue] = useState('');
  const debouncedLocationTerm = useDebounce(searchLocationValue, 350);

  const {
    data: locationOptions,
    getData: getLocations,
    loading: loadingLocData,
  } = useCustomSelectData({
    apiGet: CompanyLocationsApi.getCompanyLocations,
    searchValue: searchLocationValue,
    searchField: authState.user.language === 'ar' ? 'cl.nameArabic' : 'cl.name',
  });

  useEffect(() => {
    getLocations();
  }, [debouncedLocationTerm]);

  /* DEPARTMENTS */
  const [searchDepartmentValue, setSearchDepartmentValue] = useState('');
  const debouncedDepartmentTerm = useDebounce(searchDepartmentValue, 350);

  const {
    data: departmentOptions,
    getData: getDepartments,
    loading: loadingDepData,
  } = useCustomSelectData({
    apiGet: CompanySettingsApi.getProfileFields,
    apiSubcategory: 'departments',
    searchValue: searchDepartmentValue,
    searchField: authState.user.language === 'ar' ? 'd.nameArabic' : 'd.name',
  });

  useEffect(() => {
    getDepartments();
  }, [debouncedDepartmentTerm]);

  /* TEAM */
  const [searchTeamValue, setSearchTeamValue] = useState('');
  const debouncedTeamTerm = useDebounce(searchTeamValue, 350);

  const {
    data: teamOptions,
    getData: getTeams,
    loading: loadingTeaData,
  } = useCustomSelectData({
    apiGet: CompanySettingsApi.getProfileFields,
    apiSubcategory: 'teams',
    searchValue: searchTeamValue,
    searchField: authState.user.language === 'ar' ? 't.nameArabic' : 't.name',
  });

  useEffect(() => {
    getTeams();
  }, [debouncedTeamTerm]);

  const searchHandler = (e, type) => {
    switch (type) {
      case 'team':
        setSearchTeamValue(e);
        break;
      case 'department':
        setSearchDepartmentValue(e);
        break;
      case 'location':
        setSearchLocationValue(e);
        break;
      default:
        break;
    }
  };

  const validate = (values) => {
    const errors = {};
    const reDot = /^[^.]*$/;

    if (!values.name) {
      errors.name = t('GENERAL.NAME_REQUIRED');
    } else if (!reDot.test(values.name)) {
      errors.name = t('GENERAL.CONTAINS_FORBIDDEN_CHARACTER');
    }

    if (!!values.nameArabic && !reDot.test(values.nameArabic)) {
      errors.nameArabic = t('GENERAL.CONTAINS_FORBIDDEN_CHARACTER');
    }
    if (!values.isVisibleToEveryone) {
      errors.isVisibleToEveryone = t('GENERAL.FIELD_REQUIRED');
    }
    if (!values.startDate) {
      errors.startDate = t('GENERAL.START_DATE_REQUIRED');
    }

    if (
      values.isVisibleToEveryone === 'LOCATION' &&
      values.locations?.length === 0 &&
      locationOptions?.items?.length > 0
    ) {
      errors.locations = t('GENERAL.FIELD_REQUIRED');
    }

    if (
      values.isVisibleToEveryone === 'DEPARTMENT' &&
      values.departments?.length === 0 &&
      departmentOptions?.items?.length > 0
    ) {
      errors.departments = t('GENERAL.FIELD_REQUIRED');
    }
    if (values.isVisibleToEveryone === 'TEAM' && values.teams?.length === 0 && teamOptions?.items?.length > 0) {
      errors.teams = t('GENERAL.FIELD_REQUIRED');
    }

    return errors;
  };

  const getAudienceValue = (s) => {
    let audienceValue = '';
    if (s?.teams.length > 0) {
      audienceValue = 'TEAM';
    } else if (s?.departments.length > 0) {
      audienceValue = 'DEPARTMENT';
    } else if (s?.locations.length > 0) {
      audienceValue = 'LOCATION';
    } else {
      audienceValue = 'EVERYONE';
    }

    return audienceValue;
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selected?.name || '',
      nameArabic: selected?.nameArabic || '',
      icon: selected?.icon || 'icon-circle-plus',
      startDate: selected ? selected.startDate : day || null,
      endDate: selected?.endDate || null,
      isVisibleToEveryone: selected ? getAudienceValue(selected) : '',
      departments: selected?.departments ? formatOptions(selected.departments) : [],
      locations: selected?.locations ? formatOptions(selected.locations) : [],
      teams: selected?.teams ? formatOptions(selected.teams) : [],
      description: selected?.description || '',
    },
    validate,
    onSubmit: (values) => {
      const formatData = {
        ...values,
        startDate: moment(values.startDate).format('YYYY-MM-DD'),
        endDate: values?.endDate ? moment(values.endDate).format('YYYY-MM-DD') : null,
        locations: values?.locations ? values?.locations.map((l) => l.value) : [],
        departments: values?.departments ? values?.departments.map((l) => l.value) : [],
        teams: values.teams ? values?.teams.map((l) => l.value) : [],
      };

      if (formatData.isVisibleToEveryone !== 'LOCATION') {
        formatData.locations = [];
      }
      if (formatData.isVisibleToEveryone !== 'DEPARTMENT') {
        formatData.departments = [];
      }
      if (formatData.isVisibleToEveryone !== 'TEAM') {
        formatData.teams = [];
      }

      delete formatData.isVisibleToEveryone;

      onSave(formatData, selected?.id);
    },
  });

  const onSelectIcon = (val) => {
    formik.setFieldValue('icon', val);
  };

  useEffect(() => {
    setIsFormDirty(formik.dirty);
  }, [formik.dirty]);

  return (
    <div className='h-100 d-flex flex-column'>
      {responseStatus?.resolved && <SuccessCheckmark text={responseStatus.msg} isFailed={responseStatus.isFailed} />}
      {saving && <SpinnerRing />}
      {(loadingLocData || loadingTeaData || loadingDepData) && <SpinnerRing />}
      <FormContext.Provider value={{ formik }}>
        <form className='d-flex flex-column flex-1 mt-4' noValidate onSubmit={formik.handleSubmit}>
          <Scrollbars className='scrollbarsWrapper' autoHeight autoHeightMin={200} autoHeightMax='calc(100vh - 334px)'>
            <div className='px-5 flex-1'>
              <div className='row '>
                <div className='col-12'>
                  <div className='d-flex justify-content-between'>
                    <IconPicker
                      icons={calendarPickerIcons}
                      selectedIcon={formik.values.icon}
                      onSelectIcon={onSelectIcon}
                    />
                    <TextInput
                      name='name'
                      label='name'
                      error={formik.touched.name && formik.errors.name}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isRequired
                    />
                  </div>
                </div>
                <div className='col-12 mb-4'>
                  <TextInput
                    name='nameArabic'
                    sufix='ar'
                    value={formik.values.nameArabic}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.nameArabic && formik.errors.nameArabic}
                    direction='rtl'
                  />
                </div>
                <div className='col-6 '>
                  <DateInput
                    name='startDate'
                    label='start date'
                    isRequired
                    maxDetail='month'
                    value={formik.values.startDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.startDate && formik.errors.startDate}
                    max={moment().add(1, 'year').format('YYYY-MM-DD')}
                    min={moment().subtract(1, 'year').format('YYYY-MM-DD')}
                  />
                </div>
                <div className='col-6'>
                  <DateInput
                    name='endDate'
                    label='end date'
                    maxDetail='month'
                    value={formik.values.endDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    min={formik.values.startDate}
                    max={moment().add(1, 'year').format('YYYY-MM-DD')}
                  />
                </div>
                <div className='col-12 mt-4'>
                  <SelectField
                    styles={selectStyles}
                    label='choose audience'
                    name='isVisibleToEveryone'
                    options={audience}
                    value={formik.values.isVisibleToEveryone}
                    error={formik.touched.isVisibleToEveryone && formik.errors.isVisibleToEveryone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isRequired
                  />
                </div>

                {formik.values.isVisibleToEveryone === 'LOCATION' && (
                  <div className='col-12'>
                    <MultipleSelectField
                      isFormik={false}
                      name='locations'
                      label='location'
                      selected
                      options={locationOptions.items}
                      value={formik.values.locations}
                      onChange={formik.setFieldValue}
                      onBlur={formik.handleBlur}
                      onInputChange={(e) => {
                        searchHandler(e, 'locations');
                      }}
                      scrollMore={() => {
                        getLocations(true);
                      }}
                      error={formik.touched.locations && formik.errors.locations}
                      isRequired
                    />
                  </div>
                )}
                {formik.values.isVisibleToEveryone === 'DEPARTMENT' && (
                  <div className='col-12'>
                    <MultipleSelectField
                      isFormik={false}
                      name='departments'
                      label='department'
                      options={departmentOptions.items}
                      value={formik.values.departments}
                      onChange={formik.setFieldValue}
                      onBlur={formik.handleBlur}
                      onInputChange={(e) => {
                        searchHandler(e, 'departments');
                      }}
                      scrollMore={() => {
                        getDepartments(true);
                      }}
                      error={formik.touched.departments && formik.errors.departments}
                      isRequired
                    />
                  </div>
                )}
                {formik.values.isVisibleToEveryone === 'TEAM' && (
                  <div className='col-12'>
                    <MultipleSelectField
                      isFormik={false}
                      name='teams'
                      label='team'
                      options={teamOptions.items}
                      value={formik.values.teams}
                      onChange={formik.setFieldValue}
                      onBlur={formik.handleBlur}
                      onInputChange={(e) => {
                        searchHandler(e, 'teams');
                      }}
                      scrollMore={() => {
                        getTeams(true);
                      }}
                      error={formik.touched.teams && formik.errors.teams}
                      isRequired
                    />
                  </div>
                )}

                <div className='col-12 mt-4 mb-8'>
                  <Textarea
                    label='description'
                    name='description'
                    rows='3'
                    maxLength={250}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                  />
                </div>
              </div>
            </div>
          </Scrollbars>
          <div className='d-flex justify-content-end py-4 px-5 border-t'>
            <RoundedButton
              type='submit'
              text={selected ? 'Save' : 'Add'}
              btnStyle='contained'
              color='primary'
              size='md'
            />
          </div>
        </form>
      </FormContext.Provider>
    </div>
  );
};

AddEventModal.propTypes = {
  selected: PropTypes.object,
  responseStatus: PropTypes.object,
  saving: PropTypes.bool,
  onSave: PropTypes.func,
  setIsFormDirty: PropTypes.func,
};

AddEventModal.defaultProps = {
  selected: null,
  responseStatus: null,
  saving: false,
  onSave: undefined,
  setIsFormDirty: undefined,
};

export default AddEventModal;
