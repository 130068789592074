import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

const SubPageWrapper = ({ children, className }) => (
  <div className={cx('d-flex flex-column p-0 panel w-100 flex-1 shadow-04', className)}>{children}</div>
);

SubPageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

SubPageWrapper.defaultProps = {
  className: '',
};

export default SubPageWrapper;
