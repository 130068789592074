import React from 'react';

import cx from 'classnames';

import IconButton from 'components/buttons/IconButton/IconButton';
import Tooltip from 'components/tooltip/Tooltip';
import eventUtil from 'utility/eventUtil';

import styles from './Table.module.scss';

const THCell = ({
  name,
  tableId,
  title,
  info,
  colSpan,
  sortable,
  sortValue,
  sortIsAsc,
  onSort,
  size,
  width,
  loading,
  style,
  className,
  sticky,
  sorted,
}) => {
  const onTSort = () => {
    const isAsc = sortIsAsc === null ? true : !sortIsAsc;
    const direction = isAsc ? 'asc' : 'desc';
    const sort = { sort: sortValue, direction };

    const detail = { name, tableId, sort };

    if (onSort && typeof onSort === 'function') {
      onSort(detail);
      return;
    }

    eventUtil.dispatch('sort', detail);
  };

  const renderSort = () => {
    if (!sortable) return null;
    const icon = !sorted ? 'icon-chevron-sort' : sortIsAsc === true ? 'icon-chevron-top' : 'icon-chevron-bottom';
    return <IconButton onClick={onTSort} icon={icon} color='gray' className='ms-1' isDisabled={loading} />;
  };

  return (
    <th
      colSpan={colSpan}
      className={cx(styles[size], { [styles.sticky]: sticky }, `${className}`)}
      width={width}
      style={style}
    >
      <div className='d-flex align-items-center'>
        {info && (
          <div className='me-2 d-flex align-items-center'>
            <Tooltip label={info} position='bottom' useTranslate className='text-start'>
              <i className='icon-info text-gray fs-14' />
            </Tooltip>
          </div>
        )}

        {title}

        {renderSort()}
      </div>
    </th>
  );
};

THCell.defaultProps = {
  name: 'key',
  tableId: 'table-id',
  title: '',
  info: '',
  sortable: false,
  sortIsAsc: null,
  sortValue: null,
  sorted: false,
  onSort: null,
  colSpan: 1,
  size: 'sm',
  width: null,
  loading: false,
  style: {},
  className: '',
  sticky: false,
};

export default THCell;
