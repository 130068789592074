import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';

import IconButton from 'components/buttons/IconButton/IconButton';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import CheckBox from 'components/forms/components/CheckBox';
import SpinnerRing from 'components/Spinner/SpinnerRing';
import SuccessCheckmark from 'components/SuccessCheckmark/SuccessCheckmark';
import useResolveHandler from 'shared/useResolveHandler';

import helper from './helper';

const UserReportColumnsModal = ({ onSave, columns, reportId }) => {
  const [minColOrder, setMinColOrder] = useState(0);
  const [maxColOrder, setMaxColOrder] = useState(0);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [saving, setSaving] = useState(false);
  const [allSelected, setAllSelected] = useState(helper.allSelected([...columns]));
  const { responseStatus, setStatus } = useResolveHandler();

  const formik = useFormik({
    initialValues: {
      columns: helper.sortedColumns([...columns]),
    },
    onSubmit: (values) => {
      const callbacks = {
        before: () => {
          setSaving(true);
        },
        success: () => {
          setStatus(true, () => {
            setSaving(false);
            onSave(values.columns);
          });
        },
        fail: (error) => {
          setStatus(
            false,
            () => {
              setSaving(false);
            },
            error?.response?.data?.message
          );
        },
      };

      helper.save(reportId, { columns: helper.payload(values.columns) }, callbacks);
    },
  });

  const onCheck = (c, n) => {
    formik.setFieldValue('columns', helper.optionChecked(formik.values.columns, n, c));
  };

  const onCheckAll = (c) => {
    formik.setFieldValue('columns', helper.checkedAll(formik.values.columns, c));
  };

  const onSort = (index, item, direction = 'up') => {
    formik.setFieldValue('columns', helper.optionSorted(formik.values.columns, index, item, direction));
  };

  // init columns changed:
  // set minimal order whenever init columns change
  // set form columns
  useEffect(() => {
    setMinColOrder(helper.minOrder([...columns]));
    setMaxColOrder(helper.maxOrder([...columns]));
    formik.setFieldValue('columns', helper.sortedColumns([...columns]));
  }, [JSON.stringify(columns)]);

  // form columns changed:
  // enable save if there are differences
  // check if all selected
  useEffect(() => {
    setSaveEnabled(helper.enableSave([...columns], formik.values.columns));
    setAllSelected(helper.allSelected(formik.values.columns));
  }, [JSON.stringify(formik.values.columns)]);

  const renderCheckboxes = () => {
    const first = [];
    const second = [];

    formik.values.columns.forEach((column, i, arr) => {
      const visible = Boolean(column.visible) === true;
      const sortableUp =
        visible && !helper.excludedCols().includes(column.name) && Number(column.columnOrder) !== minColOrder;
      const sortableDown =
        visible && !helper.excludedCols().includes(column.name) && Number(column.columnOrder) !== maxColOrder;

      const el = (
        <div
          key={column.id}
          className='py-3'
          style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
        >
          <CheckBox
            label=''
            value={column.id}
            checked={visible}
            name={column.id}
            onChange={onCheck}
            isDisabled={helper.excludedCols().includes(column.name)}
          />

          <span style={{ flexGrow: 1, marginLeft: '8px' }}>{`${i + 1}. ${column.thConfig.title}`}</span>

          <div>
            <IconButton
              isDisabled={!sortableUp}
              icon='icon-chevron-top'
              size='xs'
              color='gray'
              onClick={() => {
                onSort(i, column);
              }}
            />

            <IconButton
              isDisabled={!sortableDown}
              icon='icon-chevron-bottom'
              size='xs'
              color='gray'
              onClick={() => {
                onSort(i, column, 'down');
              }}
            />
          </div>
        </div>
      );

      if (i < arr.length / 2) {
        first.push(el);
        return;
      }

      second.push(el);
    });

    return (
      <div className='row mt-4'>
        <div className='col-6'>{first}</div>
        <div className='col-6'>{second}</div>
      </div>
    );
  };

  return (
    <div>
      <form noValidate onSubmit={formik.handleSubmit}>
        {saving && <SpinnerRing />}
        {responseStatus.resolved && <SuccessCheckmark text={responseStatus.msg} isFailed={responseStatus.isFailed} />}

        <Scrollbars autoHeight autoHeightMin={200} autoHeightMax='calc(100vh - 350px)'>
          <div className='px-5 pt-6'>
            <CheckBox
              name='allSelected'
              value='all'
              label='GENERAL.SELECT_ALL'
              checked={allSelected}
              onChange={onCheckAll}
            />
            <div>{renderCheckboxes()}</div>
          </div>
        </Scrollbars>

        <div className='d-flex justify-content-end border-t btn-modal-box px-5 py-4'>
          <RoundedButton
            type='submit'
            text='Save'
            btnStyle='contained'
            color='primary'
            size='md'
            className='me-5'
            isDisabled={!saveEnabled}
          />
        </div>
      </form>
    </div>
  );
};

UserReportColumnsModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  reportId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

UserReportColumnsModal.defaultProps = {
  reportId: null,
};

export default UserReportColumnsModal;
