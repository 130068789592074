// eslint-disable-next-line import/prefer-default-export
export const MODULES_CONFIGS = {
  RECRUITMENT: {
    NAME: 'ats',
    FEATURES: {
      CANDIDATES: 'candidates::view',
    },
  },
  AI: {
    NAME: 'ai',
  },
  BILLABLE_HOURS: {
    NAME: 'billable_hours',
  },
  BILLING_SECTION: {
    NAME: 'billing_section',
  },
  CUSTOM_REPORTS: {
    NAME: 'custom_reports',
  },
};
