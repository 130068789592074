import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';

const AlertMessage = ({ type, text, duration, callback }) => {
  const [init, setInit] = useState(false);
  if (!init) {
    toast[type](text, {
      position: 'bottom-right',
      autoClose: duration,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      toastId: text,
      onClose: () => {
        if (callback) {
          callback();
        }
        toast.dismiss(text);
        setInit(false);
      },
      onOpen: () => {
        setInit(true);
      },
    });
  }
  return <ToastContainer />;
};

AlertMessage.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  callback: PropTypes.func,
  duration: PropTypes.number,
};

AlertMessage.defaultProps = {
  text: '',
  type: '',
  callback: undefined,
  duration: 5000,
};

export default AlertMessage;
