import _ from 'lodash';
import moment from 'moment';

import queryParamsUtil from 'utility/queryParamsUtil';

import http from './Api';

class CompanySettingsApi {
  /** *** ACCOUNT ***** */
  static getSettingsActivity(group, filters) {
    return http.get(`settings/activity-group/${group}/activities${filters}`);
  }

  static getCompanySettingsData() {
    return http.get(`settings/organization/details`);
  }

  static getCompanyDataWithSubscription() {
    return http.get(`settings/account-subscription`);
  }

  static putCompanySettingsData(data) {
    return http.put(`settings/organization/details`, data);
  }

  static getCompanyRepresentative() {
    return http.get(`settings/accounts/company-representative`);
  }

  static updateCompanyRepresentative(data) {
    return http.put(`settings/accounts/company-representative`, data);
  }

  static cleanupCompanySettingsData() {
    return http.put(`settings/accounts/clear-defaults`);
  }

  static getTotalUsers() {
    return http.get(`settings/accounts/total-users`);
  }

  static addCompanyLogo(data) {
    return http.post(`settings/organization/logo`, data);
  }

  static removeCompanyLogo() {
    return http.delete(`settings/organization/logo`);
  }

  static uploadBackground(data) {
    return http.post(`settings/organization/background`, data);
  }
  /** *** ACCOUNT ***** */

  /** *** OWNERS ***** */
  static getOwners(legalEntity, filters = '') {
    return http.get(`settings/legal-entity/${legalEntity}/owners${filters}`);
  }

  static addOwner(legalEntity, data) {
    return http.post(`settings/legal-entity/${legalEntity}/owners`, data);
  }

  static editOwner(legalEntity, id, data) {
    return http.put(`settings/legal-entity/${legalEntity}/owners/${id}`, data);
  }

  static deleteOwner(legalEntity, id) {
    return http.delete(`settings/legal-entity/${legalEntity}/owners/${id}`);
  }
  /** *** ./OWNERS ***** */

  /** *** COMPANIES ***** */
  static getCompanies(filters = '') {
    return http.get(`settings/legal-entity${filters}`);
  }

  static getEntityStats(id) {
    return http.get(`settings/legal-entity/${id}/stats`);
  }

  static getEntityData(id) {
    return http.get(`settings/legal-entity/${id}`);
  }

  static addNewCompany(data) {
    return http.post(`settings/legal-entity`, data);
  }

  static deleteCompany(id) {
    return http.delete(`settings/legal-entity/${id}`);
  }

  static editCompany(id, data) {
    return http.put(`settings/legal-entity/${id}`, data);
  }

  static getEntityPeople(id, filters = '') {
    return http.get(`settings/legal-entity/${id}/people${filters}`);
  }

  static addEntityLogo(id, data) {
    return http.post(`settings/legal-entity/${id}/logo`, data);
  }

  static removeEntityLogo(id) {
    return http.delete(`settings/legal-entity/${id}/logo`);
  }
  /** *** ./COMPANIES ***** */

  static getBankAccounts(filters = '') {
    return http.get(`/settings/pay/wps/bank-accounts${filters}`);
  }

  static saveBankAccount(data) {
    return http.post(`/settings/pay/wps/bank-accounts`, data);
  }

  static editBankAccount(id, data) {
    return http.put(`/settings/pay/wps/bank-accounts/${id}`, data);
  }

  static deleteBankAccount(id) {
    return http.delete(`/settings/pay/wps/bank-accounts/${id}`);
  }

  static getBankOptions(filters = '') {
    return http.get(`/settings/pay/wps/bank-accounts/supported-banks${filters}`);
  }

  /** *** TEAM ***** */
  static getCountries(filters = '') {
    return http.get(`settings/team/profile-fields/countries${filters}`);
  }

  static getEmployees(filters = '') {
    return http.get(`settings/team/employees${filters}`);
  }

  static getPermissionGroups() {
    return http.get('settings/team/permission-groups');
  }

  static exportPermissionGroupToPDF(filters = '') {
    return http.get(`settings/team/employees/pdf${filters}`, {
      responseType: 'arraybuffer',
    });
  }

  static changePermissionGroup(id, data) {
    return http.put(`settings/team/employees/${id}`, data);
  }
  /** ***  ./ TEAM ***** */

  /** ***  PROFILE FIELDS ***** */
  static getProfileFields(type, filters = '') {
    return http.get(`settings/team/profile-fields/${type}${filters}`);
  }

  static addProfileFields(type, data) {
    return http.post(`settings/team/profile-fields/${type}`, data);
  }

  static delProfileFields(type, id) {
    return http.delete(`settings/team/profile-fields/${type}/${id}`);
  }

  static updateProfileFields(type, id, data) {
    return http.put(`settings/team/profile-fields/${type}/${id}`, data);
  }

  static addCustomField(data) {
    return http.post(`settings/team/custom-fields`, data);
  }

  static getCustomFields(filters = '') {
    return http.get(`settings/team/custom-fields${filters}`);
  }

  static editCustomField(id, data) {
    return http.put(`settings/team/custom-fields/${id}`, data);
  }

  static deleteCustomField(id) {
    return http.delete(`settings/team/custom-fields/${id}`);
  }

  static getCustomValues(id, filters = '') {
    return http.get(`settings/team/custom-fields/${id}/custom-field-values${filters}`);
  }

  static addCustomOption(data) {
    return http.post(`settings/team/custom-field-values`, data);
  }

  static editCustomOption(id, data) {
    return http.put(`settings/team/custom-field-values/${id}`, data);
  }

  static deleteCustomOption(id) {
    return http.delete(`settings/team/custom-field-values/${id}`);
  }

  static getProfileFieldsActivity(group, filters) {
    return http.get(`settings/team/profile-fields/group/${group}/activities${filters}`);
  }

  static getPerformanceConfig() {
    return http.get(`settings/performance/config`);
  }

  static editPerformanceConfig(data) {
    return http.put(`settings/performance/config`, data);
  }
  /** ***  ./ PROFILE FIELDS ***** */

  /** *** TIME ***** */
  static getEmployeesOverview(filters = '') {
    return http.get(`team/people/overview${filters}`);
  }

  static getLoanPolicy() {
    return http.get('settings/policy/loan');
  }

  static assignPolicies(data, filters = '') {
    return http.post(`settings/time/policies/bulk-assign${filters}`, data);
  }

  static getTimePolicies(filters = '') {
    return http.get(`settings/time/policies${filters}`);
  }

  static getVacationPolicies(params) {
    return http.get(`leave/vacation/policies`, { params });
  }

  static getVacationPolicyAssignments(id, params, apiConfig) {
    return http.get(`vacation/policy/${id}/assignments`, { params, ...apiConfig });
  }

  static getActiveVacationPolicyAssignments(id, params, apiConfig) {
    const filters = {
      ...(params?.filters ?? {}),
      date: moment().utc().format('YYYY-MM-DD'),
    };

    return CompanySettingsApi.getVacationPolicyAssignments(id, { ...params, filters }, apiConfig);
  }

  static getVacationPolicyEligibleAssignees(id, params, apiConfig) {
    return http.get(`team/people/overview`, {
      params: {
        ...params,
        date: params?.date ?? moment().utc().format('YYYY-MM-DD'),
        'exclude-vacation-policies': id,
      },
      ...apiConfig,
    });
  }

  static getSpecialLeavePolicyEligibleAssignees(id, params, apiConfig) {
    return http.get(`team/people/overview`, {
      params: {
        ...params,
        date: params?.date ?? moment().utc().format('YYYY-MM-DD'),
        'exclude-special-leave-policies': id,
      },
      ...apiConfig,
    });
  }

  static bulkAssignSpecialLeavePolicy({ policyId, data, params }) {
    return http.post(`settings/special-leave-policies/${policyId}/assign`, data, { params });
  }

  static getHolidayPolicyEligibleAssignees(id, params, apiConfig) {
    return http.get(`team/people/overview`, {
      params: {
        ...params,
        date: params?.date ?? moment().utc().format('YYYY-MM-DD'),
        'exclude-holiday-policies': id,
      },
      ...apiConfig,
    });
  }

  static getTimePolicy(id) {
    return http.get(`settings/time/policies/${id}`);
  }

  static getTimePolicieAssigned(id, filters = '') {
    return http.get(`settings/time/policies/${id}/assigned${filters}`);
  }

  static addTimePolicies(data) {
    return http.post('settings/time/policies', data);
  }

  static updateTimePolicy({ id, data }) {
    return http.put(`settings/time/policies/${id}`, data);
  }

  static bulkAssignVacationPolicy({ postData = {}, queryParams = {}, config = {} }) {
    const queryParamsFormatted = _.isObject(queryParams) ? queryParamsUtil.stringifyParams(queryParams) : '';
    return http.post(`settings/time/policies/bulk-assign?${queryParamsFormatted}`, { ...postData }, config);
  }

  static bulkAssignUnpaidLeavePolicy({ id, postData = {} }) {
    return http.post(`settings/unpaid-leave-policies/${id}/assign`, postData);
  }

  static updateTimePolicie(id, data) {
    return http.put(`settings/time/policies/${id}`, data);
  }

  static delTimePolicies(id) {
    return http.delete(`settings/time/policies/${id}`);
  }

  // Unpaid Leave policies
  static getAllUnpaidLeavePolicies(params) {
    return http.get(`leave/unpaid/policies`, { params });
  }

  static getAllCompensationFields(type, params) {
    return http.get(`settings/pay/compensation/${type}`, { params });
  }

  static getUnpaidLeavePolicies(filters = '') {
    return http.get(`settings/unpaid-leave-policies${filters}`);
  }

  static getUnpaidLeavePolicy(id) {
    return http.get(`leave/unpaid/policy/${id}`);
  }

  static getUnpaidLeavePolicyAssignments(id, params, apiConfig) {
    return http.get(`leave/unpaid/policy/${id}/assignments`, { params, ...apiConfig });
  }

  static getActiveUnpaidLeavePolicyAssignments(id, params, apiConfig) {
    const filters = {
      ...(params?.filters ?? {}),
      date: moment().utc().format('YYYY-MM-DD'),
    };

    return CompanySettingsApi.getUnpaidLeavePolicyAssignments(id, { ...params, filters }, apiConfig);
  }

  static getUnpaidPolicyEligibleAssignees(id, params, apiConfig) {
    return http.get(`team/people/overview`, {
      params: {
        ...params,
        date: params?.date ?? moment().utc().format('YYYY-MM-DD'),
        'exclude-unpaid-leave-policies': id,
      },
      ...apiConfig,
    });
  }

  static assignUnpaidLeavePolicies(id, data, filters = '') {
    return http.post(`settings/unpaid-leave-policies/${id}/assign${filters}`, data);
  }

  static getUnpaidLeavePolicieAssignes(id, filters = '') {
    return http.get(`settings/unpaid-leave-policies/${id}/assignees${filters}`);
  }

  static getUnpaidLeavePolicieUnassignes(id, filters = '') {
    return http.get(`settings/unpaid-leave-policies/${id}/unassigned${filters}`);
  }

  static addUnpaidLeavePolicy(data) {
    return http.post('settings/unpaid-leave-policies', data);
  }

  static updateUnpaidLeavePolicy({ id, data }) {
    return http.put(`settings/unpaid-leave-policies/${id}`, data);
  }

  static deleteUnpaidLeavePolicy(id) {
    return http.delete(`settings/unpaid-leave-policies/${id}`);
  }

  // ./Unpaid Leave policies

  // ./ Policies end

  static getSpecialLeaves(filters = '') {
    return http.get(`settings/time/policies/special-leaves${filters}`);
  }

  static getSpecialLeave(id) {
    return http.get(`settings/time/policies/special-leaves/${id}`);
  }

  static addSpecialLeave(data) {
    return http.post('settings/time/policies/special-leaves', data);
  }

  static editSpecialLeave(id, data) {
    return http.put(`settings/time/policies/special-leaves/${id}`, data);
  }

  static delSpecialLeave(id) {
    return http.delete(`settings/time/policies/special-leaves/${id}`);
  }

  // new api SPECIAL LEAVE POLICY
  static getSpecialLeavePolicies(params) {
    return http.get('leave/special/policies', { params });
  }

  static addSpecialLeavePolicy(data) {
    return http.post(`settings/special-leave-policies`, data);
  }

  static editSpecialLeavePolicy({ id, data }) {
    return http.put(`settings/special-leave-policies/${id}`, data);
  }

  static deleteSpecialLeavePolicy(id) {
    return http.delete(`settings/special-leave-policies/${id}`);
  }

  static getSpecialLeavePolicy(id) {
    return http.get(`settings/special-leave-policies/${id}`);
  }

  static assignEmployeeSpecialLeavePolicy(specialLeavePolicy, data, filters = '') {
    return http.post(`settings/special-leave-policies/${specialLeavePolicy}/assign${filters}`, data);
  }

  static getSpecialLeavePolicyAssignees(specialLeavePolicyId, params, config = {}) {
    return http.get(`settings/special-leave-policies/${specialLeavePolicyId}/assignees`, { ...config, params });
  }

  static getSpecialLeavePolicyAssignes(specialLeavePolicy, filters = '') {
    return http.get(`settings/special-leave-policies/${specialLeavePolicy}/assignees${filters}`);
  }

  // wizard Special Leave types
  static getSpecialLeavePolicyLeaveTypes(specialLeavePolicy, specialLeavePolicyLeaveType) {
    return http.get(`settings/special-leave-policies/${specialLeavePolicy}/leave-types/${specialLeavePolicyLeaveType}`);
  }

  static addSpecialLeavePolicyLeaveType(specialLeavePolicy, data) {
    return http.post(`settings/special-leave-policies/${specialLeavePolicy}/leave-types`, data);
  }

  static editSpecialLeavePolicyLeaveType(specialLeavePolicy, specialLeavePolicyLeaveType, data) {
    return http.put(
      `settings/special-leave-policies/${specialLeavePolicy}/leave-types/${specialLeavePolicyLeaveType}`,
      data
    );
  }

  static deleteSpecialLeavePolicyLeaveType(specialLeavePolicy, specialLeavePolicyLeaveType) {
    return http.delete(
      `settings/special-leave-policies/${specialLeavePolicy}/leave-types/${specialLeavePolicyLeaveType}`
    );
  }

  // ./ Special leaves

  static getTimeHolidays(year = '') {
    return http.get(`settings/time/holidays${year}`);
  }

  static getTimeHoliday(id) {
    return http.get(`/settings/time/holidays/${id}`);
  }

  static addTimeHoliday(data) {
    return http.post('settings/time/holidays', data);
  }

  static editTimeHoliday(id, data) {
    return http.put(`/settings/time/holidays/${id}`, data);
  }

  static delTimeHoliday(id) {
    return http.delete(`settings/time/holidays/${id}`);
  }

  // ./ Holidays end

  static getWorkSchedules(filters = '') {
    return http.get(`settings/time/workschedules${filters}`);
  }

  static assignWorkschedule(data, filters = '') {
    return http.post(`settings/time/workschedules/bulk-assign${filters}`, data);
  }

  static getWorkSchedule(id) {
    return http.get(`settings/time/workschedules/${id}`);
  }

  static addWorkSchedule(data) {
    return http.post('settings/time/workschedules', data);
  }

  static editWorkSchedule(id, data) {
    return http.put(`settings/time/workschedules/${id}`, data);
  }

  static deleteWorkSchedule(id) {
    return http.delete(`settings/time/workschedules/${id}`);
  }

  static getAssignWorkSchedule(id, filters = '') {
    return http.get(`settings/time/workschedules/${id}/assigned${filters}`);
  }

  /* Ramadan */
  static getTimeRamadans(filters = '') {
    return http.get(`settings/time/ramadans${filters}`);
  }

  static updateTimeRamadan(id, data) {
    return http.put(`settings/time/ramadans/${id}`, data);
  }

  static getRamadanAvailableWorkSchedules(ramadan) {
    return http.get(`settings/time/ramadan/${ramadan}/available-workschedules`);
  }

  static getRamadanWorkSchedules(ramadan) {
    return http.get(`settings/time/ramadan/${ramadan}/workschedules`);
  }

  static addRamadanWorkSchedule(ramadan, data) {
    return http.post(`settings/time/ramadan/${ramadan}/workschedules`, data);
  }

  static editRamadanWorkSchedule(ramadan, data) {
    return http.put(`settings/time/ramadan/${ramadan}/workschedules`, data);
  }

  static deleteRamadanWorkSchedule(ramadan, ramadanWorkschedule) {
    return http.delete(`settings/time/ramadan/${ramadan}/workschedules/${ramadanWorkschedule}`);
  }
  /* Default Shifts */

  static getShifts(params = '') {
    return http.get(`settings/time/shift-management/default-shift${params}`);
  }

  static getShift(id) {
    return http.get(`settings/time/shift-management/default-shift/${id}`);
  }

  static addShif(data) {
    return http.post(`settings/time/shift-management/default-shift`, data);
  }

  static updateShif(id, data) {
    return http.put(`settings/time/shift-management/default-shift/${id}`, data);
  }

  static deleteShif(id) {
    return http.delete(`settings/time/shift-management/default-shift/${id}`);
  }

  /* Shifts Template */

  static getShiftTemplates(params = '') {
    return http.get(`settings/time/shift-management/shift-templates${params}`);
  }

  static getShiftTemplate(id) {
    return http.get(`settings/time/shift-management/shift-templates/${id}`);
  }

  static addShiftTemplate(data) {
    return http.post(`settings/time/shift-management/shift-templates`, data);
  }

  static updateShiftTemplate(id, data) {
    return http.put(`settings/time/shift-management/shift-templates/${id}`, data);
  }

  static deleteShiftTemplate(id) {
    return http.delete(`settings/time/shift-management/shift-templates/${id}`);
  }

  /* Shifts Permissions */

  static getPermissions(params = '') {
    return http.get(`settings/shift-management/permissions${params}`);
  }

  static addPermission(data) {
    return http.post(`settings/shift-management/permissions`, data);
  }

  static editPermission(id, data) {
    return http.put(`settings/shift-management/permissions/${id}`, data);
  }

  static deletePermission(id) {
    return http.delete(`settings/shift-management/permissions/${id}`);
  }

  static resetShiftsPermissions(permissionGroup) {
    return http.put(`settings/shift-management/permissions/reset-default${permissionGroup}`);
  }

  /* Shifts Config */

  static getStartDayOfWeek() {
    return http.get(`settings/shift-management/config`);
  }

  static setStartDayOfWeek(data) {
    return http.put(`settings/shift-management/config`, data);
  }

  /** *** ./ TIME ***** */

  /** *** PAY COMPENSATION FIELDS ***** */
  static getCompensationFields(type = '', params = '') {
    return http.get(`settings/pay/compensation/${type}${params}`);
  }

  static addCompensationFields(type, data) {
    return http.post(`settings/pay/compensation/${type}`, data);
  }

  static delCompensationFields(type, id) {
    return http.delete(`settings/pay/compensation/${type}/${id}`);
  }

  static updateCompensationFields(type, id, data) {
    return http.put(`settings/pay/compensation/${type}/${id}`, data);
  }
  /** ***  ./ PAY COMPENSATION FIELDS ***** */

  /** *** PAY COMPANY PACKAGE ***** */
  static getCountryCompensationPackages(params = '') {
    return http.get(`settings/pay/compensation/salary/country-compensation-package${params}`);
  }

  static getCountryCompensationPackage(id) {
    return http.get(`settings/pay/compensation/salary/country-compensation-package/${id}`);
  }

  static updateCountryCompensationPackage(id, data) {
    return http.put(`settings/pay/compensation/salary/country-compensation-package/${id}`, data);
  }

  static getCompensationPackagesByCountry(country) {
    return http.get(`settings/pay/compensation/salary/country-compensation-package/country/${country}`);
  }

  static getCompensationPackagesByLegalEntity(legalEntity) {
    return http.get(`settings/pay/compensation/salary/country-compensation-package/legal-entity/${legalEntity}`);
  }

  /** ***  ./ PAY COMPANY PACKAGE ***** */

  /** *** PAY PAYROLL ***** */
  static getApprovalFlows(filters = '') {
    return http.get(`settings/pay/workflows${filters}`);
  }

  static addApprovalFlow(data) {
    return http.post(`settings/pay/workflows`, data);
  }

  static editApprovalFlowPay(id, data) {
    return http.put(`settings/pay/workflows/${id}`, data);
  }

  static deletePayApprovalFlow(id) {
    return http.delete(`settings/pay/workflows/${id}`);
  }

  static getApprovalFlowDetails(id) {
    return http.get(`settings/pay/workflows/${id}`);
  }

  static addEmployeesToStage(stage, data) {
    return http.post(`settings/pay/payroll-workflows-stage/${stage}/add`, data);
  }

  static removeEmployeesFromStage(stage, data) {
    return http.post(`settings/pay/payroll-workflows-stage/${stage}/remove`, data);
  }

  static delEmployeFromPayroll(stageId, employeeId) {
    return http.delete(`settings/pay/payroll/stages/${stageId}/remove-employee/${employeeId}`);
  }

  static getAllOvertimeSettings(filters = '') {
    return http.get(`settings/pay/overtime${filters}`);
  }

  static getCountryOvertimeSettings(id) {
    return http.get(`settings/pay/overtime/${id}`);
  }

  static getHourlyRates(id, filters = '') {
    return http.get(`settings/pay/overtime/${id}/hourly-rates${filters}`);
  }

  static addHourlyRate(data) {
    return http.post(`settings/pay/overtime/hourly-rates`, data);
  }

  static editHourlyRate(id, data) {
    return http.put(`settings/pay/overtime/hourly-rates/${id}`, data);
  }

  static deleteHourlyRate(id) {
    return http.delete(`settings/pay/overtime/hourly-rates/${id}`);
  }

  static getAllUndertimeSettings(filters = '') {
    return http.get(`settings/pay/undertime${filters}`);
  }

  static getUndertimeHourlyRates(id, filters = '') {
    return http.get(`settings/pay/undertime/${id}/hourly-rates${filters}`);
  }

  static addUndertimeHourlyRate(data) {
    return http.post(`settings/pay/undertime/hourly-rates`, data);
  }

  static editUndertimeHourlyRate(id, data) {
    return http.put(`settings/pay/undertime/hourly-rates/${id}`, data);
  }

  static deleteUndertimeHourlyRate(id) {
    return http.delete(`settings/pay/undertime/hourly-rates/${id}`);
  }

  static getOvertimeMarkups(id, filters = '') {
    return http.get(`settings/pay/overtime/${id}/markups${filters}`);
  }

  static addOvertimeMarkup(data) {
    return http.post(`settings/pay/overtime/markups`, data);
  }

  static editOvertimeMarkup(id, data) {
    return http.put(`settings/pay/overtime/markups/${id}`, data);
  }

  static deleteOvertimeMarkup(id) {
    return http.delete(`settings/pay/overtime/markups/${id}`);
  }

  static getGosiConfig() {
    return http.get(`settings/pay/gosi/config`);
  }

  static updateGosiConfig(data) {
    return http.put(`settings/pay/gosi/config`, data);
  }
  /** *** ./ PAY PAYROLL ***** */

  /** *** REQUESTS ***** */
  static getRequestSettings() {
    return http.get(`request-settings-details`);
  }

  static getApprovalFlow(type, value, params) {
    return http.get(`request-settings/${type}/${value}`, { params });
  }

  static saveApprovalFlow(type, value, data) {
    return http.post(`request-settings/${type}/${value}`, data);
  }

  static deleteApprovalFlow(id) {
    return http.delete(`request-settings/${id}`);
  }

  static addSpecialLeaveType(typeData) {
    return http.post(`settings/requests/time-requests/leave-types`, typeData);
  }

  static editSpecialLeaveType({ id, data }) {
    return http.put(`settings/requests/time-requests/leave-types/${id}`, data);
  }

  static deleteSpecialLeaveType(id) {
    return http.delete(`settings/requests/time-requests/leave-types/${id}`);
  }

  static getSpecialLeaveTypes(params) {
    return http.get(`settings/requests/time-requests/leave-types`, { params });
  }

  static getRequestsFields(type, params = '') {
    return http.get(`settings/requests/${type}${params}`);
  }

  static addRequestsFields(type, data) {
    return http.post(`settings/requests/${type}`, data);
  }

  static delRequestsFields(type, id) {
    return http.delete(`settings/requests/${type}/${id}`);
  }

  static updateRequestsFields(type, id, data) {
    return http.put(`settings/requests/${type}/${id}`, data);
  }

  static getApprovalFlowById(id) {
    return http.get(`request-settings/${id}`);
  }

  static getApprovalFlowEmployees(id, params = '') {
    return http.get(`request-settings/${id}/employees${params}`);
  }

  static disableRequest(id, data) {
    return http.put(`request-settings-details/${id}`, data);
  }

  static getEmployeesOnEdit(id) {
    return http.get(`request-settings/${id}/unassigned-employees`);
  }

  static getAllEmployeesApprovalFlow() {
    return http.get(`request-settings/employees`);
  }

  static deleteEmployeeFromApprovalFlow(requestSettings, employee) {
    return http.delete(`request-settings/${requestSettings}/employee/${employee}`);
  }

  static getTaskAssignees(requestSettings, filters = '') {
    return http.get(`request-settings/${requestSettings}/task-assignees${filters}`);
  }

  static deleteTaskAssignee(requestSettings, employee) {
    return http.delete(`request-settings/${requestSettings}/task-assignees/${employee}`);
  }

  static getBusinessTripLocations(filters = '') {
    return http.get(`settings/business-trip-locations${filters}`);
  }

  static addBusinessTripLocations(data) {
    return http.post(`settings/business-trip-locations`, data);
  }

  static editBusinessTripLocations(id, data) {
    return http.put(`settings/business-trip-locations/${id}`, data);
  }

  static deleteBusinessTripLocations(id) {
    return http.delete(`settings/business-trip-locations/${id}`);
  }

  static getGeneralRequestTypes(filters = '') {
    return http.get(`settings/requests/general-request/types${filters}`);
  }

  static addGeneralRequestType(data) {
    return http.post(`settings/requests/general-request/types`, data);
  }

  static editGeneralRequestType(id, data) {
    return http.put(`settings/requests/general-request/types/${id}`, data);
  }

  static deleteGeneralRequestType(id) {
    return http.delete(`settings/requests/general-request/types/${id}`);
  }

  static getValueConfigurator() {
    return http.get(`settings/requests/general-request/configurator`);
  }

  static updateValueConfigurator(data) {
    return http.put(`settings/requests/general-request/configurator`, data);
  }

  // Expense Claim Categories
  static getExpenseClaimCategories(requestType) {
    return http.get(`request-settings/${requestType}/category`);
  }

  static addExpenseClaimCategory({ data, requestType }) {
    return http.post(`request-settings/${requestType}/category`, data);
  }

  static editExpenseClaimCategory({ id, data, requestType }) {
    return http.put(`request-settings/${requestType}/category/${id}`, data);
  }

  static deleteExpenseClaimCategory(id) {
    return http.delete(`/request-settings/category/${id}`);
  }

  // Expense Claim Approval flow
  static addExpenseClaimApprovalFlow(data) {
    return http.post(`/request-settings/pay/expense-claim`, data);
  }
  /** *** ./ REQUESTS ***** */

  /** *** BENEFITS ***** */
  static getAllBenefits(filters = '') {
    return http.get(`settings/benefits${filters}`);
  }

  static getBenefitsPerType(type, filters = '') {
    return http.get(`settings/benefits/${type}${filters}`);
  }

  static postBenefit(type, data) {
    return http.post(`settings/benefits/${type}`, data);
  }

  static deleteBenefit(id) {
    return http.delete(`settings/benefits/${id}`);
  }

  static editBenefit(type, id, data) {
    return http.put(`settings/benefits/${type}/${id}`, data);
  }

  static employeesPerBenefit(id) {
    return http.get(`settings/benefits/${id}/count`);
  }

  static employeesOnBenefit(id, filters = '') {
    return http.get(`settings/benefits/${id}/employees${filters}`);
  }

  static getBenefitsIcons() {
    return http.get('settings/benefits/colors-and-icons');
  }
  /** *** ./ BENEFITS ***** */

  // Employee code id
  static getEmployeeCode() {
    return http.get(`/settings/employee-code`);
  }

  static updateEmployeeCode(data) {
    return http.put(`/settings/employee-code`, data);
  }

  // Contract Extension
  static getContractExtension() {
    return http.get(`/settings/team/contracts/extension`);
  }

  static updateContractExtension(data) {
    return http.put(`/settings/team/contracts/extension`, data);
  }

  static getLegalEntities(filters = '', params = '') {
    return http.get(`/settings/legal-entity${filters}${params}`);
  }

  static getLegalEntitiesWithoutEosPolicy(params = {}, config = {}) {
    return http.get(`/settings/legal-entities/no-eos-policy`, { params, ...config });
  }

  static getApplicationFormTemplate(id) {
    return http.get(`/recruitment/settings/application-form/${id}`);
  }

  static getHiringPhases(params) {
    return http.get('/recruitment/settings/hiring-phases', { params: params || { perPage: 9999 } });
  }

  static postHiringPhase(hiringPhase) {
    return http.post('/recruitment/settings/hiring-phase', hiringPhase);
  }

  static updateHiringPhase({ hiringPhase, id }) {
    return http.put(`/recruitment/settings/hiring-phase/${id}`, hiringPhase);
  }

  static getApplicationFormTemplateLogs({ id, params }) {
    return http.get(`/recruitment/settings/application-form/${id}/log`, { params });
  }

  static getHiringProcessTemplateLogs({ id, params }) {
    return http.get(`/recruitment/settings/hiring-process/${id}/log`, { params });
  }

  static getJobPostsLogs(params) {
    return http.get(`/recruitment/job-posts/log`, { params });
  }

  static getHiringPhasesLogs({ params }) {
    return http.get(`/recruitment/settings/hiring-phases/log`, { params });
  }

  static getHiringProcessTemplates() {
    return http.get('/recruitment/settings/hiring-processes', { params: { perPage: 9999 } });
  }

  static getBenefits() {
    return http.get('/settings/benefits', { params: { perPage: 9999 } });
  }

  static getHiringProcessTemplateDetails(id) {
    return http.get(`/recruitment/settings/hiring-process/${id}`);
  }

  static postHiringProcess(hiringProcess) {
    return http.post('/recruitment/settings/hiring-process', hiringProcess);
  }

  static putHiringProcess({ hiringProcess, id }) {
    return http.put(`/recruitment/settings/hiring-process/${id}`, hiringProcess);
  }

  static getContractNotification() {
    return http.get(`/settings/contract/config/notification/expiry`);
  }

  static setContractNotification(contractNotificationConfig) {
    return http.put(`/settings/contract/config/notification/expiry`, contractNotificationConfig);
  }

  static getEmployeePrivacySettings() {
    return http.get(`/settings/team/privacy`);
  }

  static updateEmployeePrivacySettings(privacySettings) {
    return http.put(`/settings/team/privacy`, privacySettings);
  }

  static getEmployeePrivacySettingsPerEmployee(employeeId) {
    return http.get(`/profile/${employeeId}/privacy-preferences`);
  }

  static getEmployeeDetails(employeeId) {
    return http.get(`/profile/${employeeId}/personal`);
  }

  static updateEmployeePrivacySettingsPerEmployee({ employeeId, privacySettings }) {
    return http.put(`/profile/${employeeId}/privacy-preferences`, privacySettings);
  }
}

export default CompanySettingsApi;
