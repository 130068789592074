import React from 'react';

import cx from 'classnames';

import t from 'utility/setTranslation';

import styles from './GoLiveOrderSummary.module.scss';
import { formatPrice } from '../../utility';

const InvoiceDetail = ({ label, value, currency, bold = false }) => (
  <div className={cx('d-flex justify-content-end align-items-center pe-3 mt-3 gap-8', styles.separator)}>
    <h6 className={`font-size-14 mb-0 ${bold ? 'font-weight-bold' : ''}`}>{label}</h6>
    <p className={`font-size-14 mb-0 ${bold ? 'font-weight-bold' : ''} ${bold ? 'text-end' : ''}`}>
      {formatPrice(value, currency)}
    </p>
  </div>
);

const InvoiceDetailContainer = ({ invoicePreview, invoiceCurrency }) => (
  <>
    <InvoiceDetail label={t('GENERAL.SUB_TOTAL')} value={invoicePreview.subtotal} currency={invoiceCurrency} />
    <InvoiceDetail
      label={t('GENERAL.TAX_RATE', { taxRate: invoicePreview.taxPercentage })}
      value={invoicePreview.tax}
      currency={invoiceCurrency}
    />
    <InvoiceDetail label={t('GENERAL.TOTAL')} value={invoicePreview.total} currency={invoiceCurrency} bold />
    <InvoiceDetail label={t('GENERAL.AMOUNT_DUE')} value={invoicePreview.total} currency={invoiceCurrency} bold />
  </>
);

export default InvoiceDetailContainer;
