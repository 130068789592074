import React from 'react';

import cx from 'classnames';

import LinearProgress from 'components/PHRLinearProgress/LinearProgress';

import helper from './helper';
import styles from './Table.module.scss';
import THCell from './THCell';

const TableHead = React.forwardRef((props, ref) => {
  const { sticky, columns, tableId, size, onSort, loading, sort } = props;
  const cols = columns.length;
  const loader = loading && cols > 0;

  return (
    <thead id={helper.genId(tableId, helper.components().thead)} ref={ref}>
      <tr>
        {columns.map((cell) => {
          if (cell.visible === false) return;
          const conf = cell.thConfig ?? {};

          // eslint-disable-next-line consistent-return
          return (
            <THCell
              key={`${tableId}-${cell.name}`}
              loading={loading}
              name={cell.name}
              tableId={tableId}
              size={size}
              onSort={onSort}
              sticky={sticky}
              sortable={conf.sortable}
              sortIsAsc={conf.sortable && sort.direction === 'asc'}
              sorted={conf.sortable && sort.sort === conf.sortValue}
              {...conf}
            />
          );
        })}
      </tr>

      <tr className={cx(styles.progress, { [styles.sticky]: sticky })}>
        <td colSpan={cols}>{loader && <LinearProgress />}</td>
      </tr>
    </thead>
  );
});

TableHead.defaultProps = {
  tableId: 'table-id',
  columns: [],
  size: '',
  sticky: false,
  onSort: false,
  loading: false,
  sort: null,
};

export default TableHead;
