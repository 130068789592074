import React, { useEffect } from 'react';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import ProfileNotificationSettings from 'api/ProfileNotificationSettings';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import SwitchInputExtended from 'components/forms/components/SwitchInputExtended';
import t from 'utility/setTranslation';

const SettingsNotifications = ({ setIsFormDirty, setSending, setSaved, employeeId, close }) => {
  const validate = () => {
    const errors = {};

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      enableEmail: false,
      enableWeb: false,
      enableMobile: false,
    },
    validate,
    onSubmit: (values) => {
      setSending(true);

      ProfileNotificationSettings.updateProfileNotificationSettings(employeeId, values).then(
        () => {
          setSending(false);
          setSaved(true, () => {
            close();
          });
        },
        (error) => {
          setSending(false);
          setSaved(false, () => {}, error?.response?.data?.message);
        }
      );
    },
  });

  useEffect(() => {
    setIsFormDirty(formik.dirty);
  }, [formik.values]);

  const changeStatus = (e, name) => {
    formik.setFieldValue(name, e);
  };

  useEffect(() => {
    if (employeeId) {
      ProfileNotificationSettings.getProfileNotificationSettings(employeeId).then((res) => {
        formik.setFieldValue('enableEmail', res.data.enableEmail);
        formik.setFieldValue('enableWeb', res.data.enableWeb);
        formik.setFieldValue('enableMobile', res.data.enableMobile);
      });
    }
  }, []);

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <div className='row mx-5'>
        <div className='col-12 px-2a mb-8'>
          <SwitchInputExtended
            name='enableEmail'
            checked={!!formik.values.enableEmail}
            label={t('GENERAL.EMAIL_NOTIFICATIONS_INFO')}
            title={t('GENERAL.EMAIL_NOTIFICATIONS')}
            onChange={(e) => {
              changeStatus(e, 'enableEmail');
            }}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className='col-12 px-2a mb-8'>
          <SwitchInputExtended
            name='enableWeb'
            checked={!!formik.values.enableWeb}
            label={t('GENERAL.WEB_NOTIFICATIONS_INFO')}
            title={t('GENERAL.WEB_NOTIFICATIONS')}
            onChange={(e) => {
              changeStatus(e, 'enableWeb');
            }}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className='col-12 px-2a mb-9'>
          <SwitchInputExtended
            name='enableMobile'
            checked={!!formik.values.enableMobile}
            label={t('GENERAL.MOBILE_NOTIFICATIONS_INFO')}
            title={t('GENERAL.MOBILE_NOTIFICATIONS')}
            onChange={(e) => {
              changeStatus(e, 'enableMobile');
            }}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>
      <div className='d-flex justify-content-end pt-4 border-t px-5'>
        <RoundedButton type='submit' text='Save' btnStyle='contained' color='primary' size='md' />
      </div>
    </form>
  );
};

SettingsNotifications.propTypes = {
  setIsFormDirty: PropTypes.func,
  setSending: PropTypes.func.isRequired,
  setSaved: PropTypes.func.isRequired,
};

SettingsNotifications.defaultProps = {
  setIsFormDirty: undefined,
};

export default SettingsNotifications;
