import { goLivePaymentSettingsValidationSchema } from '../../validations/GoLivePaymentSettingsValidationSchema';
import { goLiveSubdomainAndEnvironmentSettingsValidationSchema } from '../../validations/goLiveSubdomainAndEnvironmentSettingsValidationSchema';
import { goLiveSubscriptionSettingsValidationSchema } from '../../validations/GoLiveSubscriptionSettingsValidationSchema';
import GoLiveOrderSummary from '../GoLiveOrderSummary/GoLiveOrderSummary';
import GoLivePaymentSettings from '../GoLivePaymentSettings/GoLivePaymentSettings';
import GoLiveSubdomainAndEnvironmentSettings from '../GoLiveSubdomainAndEnvironmentSettings/GoLiveSubdomainAndEnvironmentSettings';
import GoLiveSubscriptionSettings from '../GoLiveSubscriptionSettings/GoLiveSubscriptionSettings';

export const generateWizardStepsWithValidation = () => [
  {
    id: 1,
    component: GoLiveSubscriptionSettings,
    validationSchema: goLiveSubscriptionSettingsValidationSchema(),
    tipsComponent: GoLiveOrderSummary,
  },
  {
    id: 2,
    component: GoLivePaymentSettings,
    validationSchema: goLivePaymentSettingsValidationSchema(),
    tipsComponent: GoLiveOrderSummary,
  },
  {
    id: 3,
    component: GoLiveSubdomainAndEnvironmentSettings,
    validationSchema: goLiveSubdomainAndEnvironmentSettingsValidationSchema(),
  },
];
