import React, { useContext } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { AuthContext } from 'auth/AuthContext';
import HeaderTitle from 'components/HeaderTitle/HeaderTitle';
import NavBar from 'components/Navigation/NavBar';
import t from 'utility/setTranslation';

const Header = ({ onSelectView }) => {
  const { authState } = useContext(AuthContext);
  const navItems = [
    { title: 'my assets', href: `/company-assets/my-assets/${authState?.user?.identification || authState?.user?.id}` },
  ];
  if (authState.user.roles.includes('ROLE_ADMIN') || authState.user.roles.includes('ROLE_HR')) {
    navItems.push(
      {
        title: 'asset management',
        href: '/company-assets/management',
      },
      {
        title: 'insights',
        href: '/company-assets/insights',
      }
    );
  }

  return (
    <div className='pt-2'>
      <div className={cx('position-relative', 'pb-3')}>
        <div className='d-flex justify-content-between align-items-center pt-3'>
          <HeaderTitle className='mb-4 mt-1'>{t('GENERAL.ASSETS')}</HeaderTitle>
        </div>
        <NavBar items={navItems} onSelectView={onSelectView} type='link' />
      </div>
    </div>
  );
};

Header.propTypes = {
  onSelectView: PropTypes.func.isRequired,
};

export default Header;
