import React, { useContext } from 'react';

import cx from 'classnames';
import moment from 'moment/moment';
import PropTypes from 'prop-types';

import { AuthContext } from 'auth/AuthContext';
import Personas from 'components/Personas';
import Tooltip from 'components/tooltip/Tooltip';
import styles from 'modules/EngagementCenter/shared/TimeOverlap/OverlapItemDetails.module.scss';
import Employee from 'services/Employee/Employee';
import renderPeriodKey from 'utility/renderPeriodKey';
import t from 'utility/setTranslation';

const leaveData = (employee) => {
  const {
    onHoliday,
    holidayStartDate,
    holidayEndDate,
    onVacation,
    onFullVacation,
    vacationStartDate,
    vacationEndDate,
    sick,
    sickWholeDay,
    sickStartDate,
    sickEndDate,
    onSpecialLeave,
    onFullSpecialLeaveDay,
    specialLeaveStartDate,
    specialLeaveEndDate,
    onUnpaidLeave,
    onFullUnpaidLeave,
    unpaidLeaveStartDate,
    unpaidLeaveEndDate,
  } = employee;

  const getPeriod = (startDate, endDate) =>
    `${moment(startDate).format('MMM DD')} - ${moment(endDate).format('MMM DD')}`;

  const getDuration = (startDate, endDate) => {
    const periodDuration = moment(endDate).diff(moment(startDate), 'days') + 1;
    return `${periodDuration} ${t(renderPeriodKey(periodDuration, 'DAY'))}`;
  };

  if (onHoliday) {
    return {
      text: t('GENERAL.HOLIDAY'),
      duration: getDuration(holidayStartDate, holidayEndDate),
      period: getPeriod(holidayStartDate, holidayEndDate),
      isPartialLeave: false,
    };
  }

  if (onVacation) {
    return {
      text: t('EC.REQUESTS.VACATION'),
      duration: getDuration(vacationStartDate, vacationEndDate),
      period: getPeriod(vacationStartDate, vacationEndDate),
      isPartialLeave: !onFullVacation,
    };
  }

  if (sick) {
    return {
      text: t('EC.REQUESTS.SICK_LEAVE'),
      duration: getDuration(sickStartDate, sickEndDate),
      period: getPeriod(sickStartDate, sickEndDate),
      isPartialLeave: !sickWholeDay,
    };
  }

  if (onSpecialLeave) {
    return {
      text: t('EC.REQUESTS.SPECIAL_LEAVE'),
      duration: getDuration(specialLeaveStartDate, unpaidLeaveEndDate),
      period: getPeriod(specialLeaveStartDate, unpaidLeaveEndDate),
      isPartialLeave: !onFullUnpaidLeave,
    };
  }

  if (onUnpaidLeave) {
    return {
      text: t('EC.REQUESTS.UNPAID_LEAVE'),
      duration: getDuration(unpaidLeaveStartDate, specialLeaveEndDate),
      period: getPeriod(unpaidLeaveStartDate, specialLeaveEndDate),
      isPartialLeave: !onFullSpecialLeaveDay,
    };
  }

  const none = t('GENERAL.NONE');

  return { text: none, duration: none, period: none, isPartialLeave: false };
};

const WhoIsOutRecord = ({ employee }) => {
  const { authState } = useContext(AuthContext);
  const employeeDTO = Employee.create(employee);
  const { text, duration, period, isPartialLeave } = leaveData(employee);

  const content = (
    <div className='d-flex align-items-center justify-content-between'>
      <div className='d-flex align-items-start'>
        <Personas user={employee} size={20} />

        <div className='ms-2'>
          <p className={cx(styles.name, 'fw-bold', 'text-steal')}>{employeeDTO.getUserLocalizedFullName(authState)}</p>

          <p className={styles.date}>
            <span className='me-1'>{period}</span>
          </p>

          <p className={cx(styles.date, 'mt-1')}>
            <span className='me-1 fw-bold'>{text}</span>
          </p>
        </div>
      </div>

      <div className={cx(styles.date, 'text-gray')}>
        <span>{duration}</span>
      </div>
    </div>
  );

  if (!isPartialLeave) return content;

  return (
    <Tooltip label={t('GENERAL.PARTIAL_LEAVE')} size='md' position='right'>
      {content}
    </Tooltip>
  );
};

WhoIsOutRecord.propTypes = {
  employee: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(Employee)]).isRequired,
};

export default WhoIsOutRecord;
