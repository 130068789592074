import { useEffect, useRef, useState } from 'react';

import _ from 'lodash';

import queryParams from 'utility/queryParamsUtil';

const INITIAL_VALUES = {
  searchValue: '',
  searchField: '',
  perPage: 20,
  filters: [],
  direction: 'asc',
  sort: '',
  preselectedOption: undefined,
};

const useCustomSelectData = (options) => {
  const [data, _setData] = useState({ items: [], pagination: {} });
  const [loading, setLoading] = useState(false);
  const dataRef = useRef(data);
  const apiOptions = useRef({});
  apiOptions.current = { ...INITIAL_VALUES, ...options };

  const setData = (newState) => {
    dataRef.current = newState;
    _setData(newState);
  };

  const onSuccess = (res, prevState) => {
    const state = { ...prevState };
    state.items = [...prevState.items, ...res.data.items];
    state.items = _.uniqBy(state.items, 'id');
    state.pagination = res.data.pagination;
    setData(state);
    setLoading(false);
  };

  const onError = () => {
    setLoading(false);
  };

  const formatParams = (nextPage, paramValues) => {
    const filters = [];

    if (paramValues.searchValue) {
      filters.push({
        field: `${paramValues.searchField}`,
        type: `like`,
        value: paramValues.searchValue,
      });
    }

    if (paramValues.filters?.length > 0) {
      filters.push(...paramValues.filters);
    }

    const paramsObject = {
      perPage: paramValues.perPage,
      sort: paramValues.sort || paramValues.searchField,
      direction: paramValues.direction,
      currentPage: nextPage,
      filters: filters.length ? filters : '',
      ...paramValues.customParams,
    };

    const params = `?${queryParams.stringifyParams(_.pickBy(paramsObject))}`;
    return params;
  };

  const getData = (isScroll = false, resetData = false) => {
    const { apiGet, apiSubcategory } = apiOptions.current;
    if (!apiGet) return;
    if (!data.items.length) {
      setLoading(true);
    }
    if (resetData) {
      setData({ items: [], pagination: {} });
    }

    const state = { ...dataRef.current };
    let pageParam = '';

    if (isScroll) {
      const nextPage = state?.pagination?.currentPage + 1;
      if (nextPage <= state?.pagination?.pages) {
        pageParam = nextPage;
      } else {
        return;
      }
    }

    const params = formatParams(pageParam, apiOptions.current);

    if (apiSubcategory) {
      apiGet(apiSubcategory, params)
        .then((res) => {
          onSuccess(res, state);
        })
        .catch(onError);
    } else {
      apiGet(params)
        .then((res) => {
          onSuccess(res, state);
        })
        .catch(onError);
    }
  };

  useEffect(() => {
    const state = { ...data };
    const { preselectedOption } = apiOptions.current;
    if (preselectedOption?.id) {
      const indexSelected = _.findIndex(state.items, ['id', preselectedOption.id]);
      if (indexSelected === -1) {
        state.items.unshift(preselectedOption);
        setData(state);
      }
    }
  }, [apiOptions.current.preselectedOption]);

  return {
    data,
    setData,
    getData,
    loading,
    setLoading,
  };
};

export default useCustomSelectData;
