import http from './Api';

class EmployeeApi {
  static meChangePassword(data) {
    return http.post('me/change-password', data);
  }

  static changeEmployeePassord(data) {
    return http.post('change-employee-password', data);
  }

  static getEmployeeOptions() {
    return http.get('/me/people');
  }

  static getEmploymentDates(id) {
    return http.get(`/employee/${id}/employment-dates`);
  }

  static getEmployeeFilter(filters) {
    return http.get(`/filters${filters}`);
  }
}

export default EmployeeApi;
