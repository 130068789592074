import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import formatKey from 'utility/formatKey';

import styles from './CalendarLegend.module.scss';

const CalendarLegend = ({ labels, className }) => (
  <ul className={cx(styles.legend, className || 'pt-4')}>
    {labels.map((label) => (
      <li className={cx(styles[_.head(_.words(label)).toLowerCase()], 'mb-1 pe-5')} key={label}>
        {formatKey('EC.REQUESTS.TIME', label)}
      </li>
    ))}
  </ul>
);

CalendarLegend.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CalendarLegend;
