import http from './Api';

class ProfileTwoFactorSettings {
  static getCurrentSettings() {
    return http.get(`profile/two-factor-settings`);
  }

  static enable2Factor(data) {
    return http.put(`profile/two-factor-settings/enable`, data);
  }

  static disable2Factor(data) {
    return http.put(`profile/two-factor-settings/disable`, data);
  }

  static initGoogle(data) {
    return http.put(`profile/two-factor-settings/initialize-google`, data);
  }

  static initEmail(data) {
    return http.put(`profile/two-factor-settings/initialize-email`, data);
  }
}

export default ProfileTwoFactorSettings;
