import React, { useContext } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { RowDensityContext } from 'components/RowDensity/RowDensityContext';

import styles from './Cell.module.scss';

const Cell = ({ children, className, zIndex, colspan, applyDensity, ariaLabel, onClick }) => {
  const { density } = useContext(RowDensityContext);
  return (
    <td
      onClick={onClick}
      style={{ zIndex: zIndex || '' }}
      className={cx(styles.data, className, { [styles[density]]: applyDensity })}
      colSpan={colspan}
      aria-label={ariaLabel}
    >
      {children}
    </td>
  );
};

Cell.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element, PropTypes.elementType]),
  className: PropTypes.string,
  zIndex: PropTypes.number,
  colspan: PropTypes.string,
  ariaLabel: PropTypes.string,
  applyDensity: PropTypes.bool,
};

Cell.defaultProps = {
  className: '',
  children: '',
  zIndex: 0,
  colspan: '',
  ariaLabel: '',
  applyDensity: false,
};

export default Cell;
