import { createContext } from 'react';

import { observable, action, makeObservable } from 'mobx';

class GeneralStore {
  intervalChange = true;

  allActiveUsers = [];

  constructor() {
    makeObservable(this, {
      intervalChange: observable,
      setIntervalChange: action,
      allActiveUsers: observable,
      setAllActiveUsers: action,
    });
  }

  setIntervalChange(data) {
    this.intervalChange = data;
  }

  setAllActiveUsers(data) {
    this.allActiveUsers = data;
  }
}

export default createContext(new GeneralStore());
