import React from 'react';

import cx from 'classnames';
import Lottie from 'lottie-react';

import spinner from 'assets/json/response_loaders/loop/data.json';

const AreaLoader = ({ className }) => (
  <section className={cx('d-flex justify-content-center mt-15', className)} style={{ height: '200px', width: '100%' }}>
    <Lottie animationData={spinner} />
  </section>
);

export default AreaLoader;
