import _ from 'lodash';

const updateDataList = (item, type, list) => {
  let state = [...list];
  const objIndex = _.findIndex(state, ['id', +item.id]);
  switch (type) {
    case 'edit':
      state[objIndex] = { ...item };
      break;
    case 'delete':
      state = _.filter(state, (x) => x.id !== +item.id);
      break;
    default:
      state.unshift(item);
      break;
  }
  return state;
};

export default updateDataList;
