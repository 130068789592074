import React from 'react';

import Lottie from 'lottie-react';

import success from 'assets/json/response_loaders/success/data.json';
import t from 'utility/setTranslation';

const PaymentSuccess = () => (
  <div className='d-flex flex-column justify-content-center align-items-center mt-6'>
    <h2 className='text-success font-size-14'>{t('GENERAL.PAYMENT_SUCCESS')}</h2>
    <Lottie id='lottie-success-error-animation' animationData={success} loop={false} style={{ width: '60px' }} />
  </div>
);

export default PaymentSuccess;
