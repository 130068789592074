import React, { useState } from 'react';

import ActionLoading from 'components/ActionLoading/ActionLoading';
import IconButton from 'components/buttons/IconButton/IconButton';
import Modal from 'components/Modal';
import SubPageHeader from 'components/PHRSubPageHeader/SubPageHeader';
import PhrWizardWithoutSideMenu from 'components/PHRWizardWithoutSideMenu/PHRWizardWithoutSideMenu';
import useResolveHandler from 'shared/useResolveHandler';
import { useMutatePlgGoLive } from 'topNavigation/hooks/useAccount';
import t from 'utility/setTranslation';

import { generateWizardStepsWithValidation } from './wizardConfig';
import { goLiveInitialValues } from '../../constants';
import { adaptValuesForAPI } from '../../utility';
import GoLiveSuccessState from '../GoLiveSuccessState/GoLiveSuccessState';

const GoLiveModal = ({ isOpen, onClose }) => {
  const [showSuccessState, setShowSuccessState] = useState(false);

  const { mutate, isLoading } = useMutatePlgGoLive();
  const { responseStatus, setStatus } = useResolveHandler();

  const onSubmit = (values) => {
    const adaptedGoLiveValues = adaptValuesForAPI(values);

    mutate(adaptedGoLiveValues, {
      onSuccess: () => {
        setStatus(true);
        setShowSuccessState(true);
      },
      onError: (error) => {
        setStatus(false, null, t(error.response.data.message));
      },
    });
  };

  const renderHeaderActions = ({ isTipsExist, setIsShowTips, isShowTips }) => (
    <section className='d-flex gap-3'>
      {isTipsExist && <IconButton icon='icon-Icon_Lightbulb' size='md' onClick={() => setIsShowTips(!isShowTips)} />}
      <IconButton icon='icon-cross-x' size='md' onClick={onClose} />
    </section>
  );

  return (
    <Modal
      styles={{
        width: '100%',
        maxWidth: '1370px',
        padding: 0,
        height: '800px',
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={false}
      showHeader={false}
    >
      <ActionLoading isLoading={isLoading} responseStatus={responseStatus} />

      {!showSuccessState && (
        <section className='d-flex flex-column h-100'>
          <section className='flex-1'>
            <PhrWizardWithoutSideMenu
              renderHeaderComponent={({ isTipsExist, setIsShowTips, isShowTips }) => (
                <SubPageHeader
                  title={t('GENERAL.GO_LIVE')}
                  renderSideActionComponent={() => renderHeaderActions({ isTipsExist, setIsShowTips, isShowTips })}
                />
              )}
              className='h-100'
              initialIsShowTipsState
              initialValues={goLiveInitialValues}
              onSubmit={onSubmit}
              generateWizardSteps={generateWizardStepsWithValidation}
              submitButtonText='Complete'
              tipsTitle='GENERAL.ORDER_SUMMARY'
              tipsWidth='475px'
            />
          </section>
        </section>
      )}

      {showSuccessState && <GoLiveSuccessState />}
    </Modal>
  );
};

export default GoLiveModal;
