import moment from 'moment';

function daysFromRange(from, to) {
  const dates = [];
  const endDate = moment(to);
  let startDate = moment(from);

  while (startDate <= endDate) {
    dates.push(startDate.format('YYYY-MM-DD'));
    startDate = startDate.clone().add(1, 'd');
  }

  return dates;
}

export default daysFromRange;
