/* eslint-disable max-len */
import http from './Api';

class EngagementCenterApi {
  static isInApprovalFlow(type, id) {
    return http.get(`request-settings/${type}/employees/${id}/status`);
  }

  static getSetupData() {
    return http.get('/requests/request-wizard');
  }

  static requestWizardUpdate(data) {
    return http.put('/requests/request-wizard', data);
  }

  static createRequest(group, type, data, param = '') {
    return http.post(`/requests/${group}/${type}${param}`, data);
  }

  static overTimeRequestPreview(data) {
    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      if (item === 'attachments') {
        data.attachments.forEach((at) => {
          formData.append('attachments[]', at);
        });
      } else {
        formData.append(item, data[item]);
      }
    });

    return http.post(`/requests/time-off/overtime/preview`, formData);
  }

  static getLeaveTypes(filters = '') {
    return http.get(`/settings/requests/time-requests/leave-types${filters}`);
  }

  static getDocumentTypes(filters = '') {
    return http.get(`/settings/requests/hr-requests/document-types${filters}`);
  }

  static getActiveRequests(group = '') {
    if (group) {
      return http.get(`/request-settings/${group}/active`);
    }
    return http.get(`/request-settings`);
  }

  static getAllRequestsAF(group = '') {
    if (group) {
      return http.get(`/request-settings/${group}`);
    }
    return http.get(`/request-settings`);
  }

  static getActiveRequestsGrouped() {
    return http.get(`/request-settings/grouped`);
  }

  static getTasks(filters = '') {
    return http.get(`/requests/tasks${filters}`);
  }

  static getApprovals(status = 'all', filters = '') {
    return http.get(`/requests/approval/${status}${filters}`);
  }

  static getFutureTasks(id) {
    return http.get(`/requests/future-tasks/${id}`);
  }

  static getMyRequests(filters = '', type) {
    if (type) {
      return http.get(`/me/requests/${type}${filters}`);
    }
    return http.get(`/me/requests${filters}`);
  }

  static getAllRequests(filters = '', type) {
    if (type) {
      return http.get(`/requests/${type}${filters}`);
    }
    return http.get(`/requests${filters}`);
  }

  static getRequestsById(id) {
    return http.get(`/requests/${id}`);
  }

  static getAttachment(id, action = 'preview') {
    const timestamp = new Date().getTime();
    return http.get(`request-attachment/${id}/${action}?_=${timestamp}`, {
      responseType: 'arraybuffer',
    });
  }

  static requestHandler(id, action, filters = '') {
    return http.put(`/requests/${id}/${action}${filters}`);
  }

  static deleteRequest(id) {
    return http.delete(`/requests/${id}`);
  }

  static attachFiles(id, data) {
    return http.post(`/requests/${id}/attachments`, data);
  }

  static deleteRequestAttachment(id) {
    return http.delete(`/requests/attachments/${id}/delete`);
  }

  static updateRequestAttachment(id, attachment, data) {
    return http.put(`/requests/${id}/attachments/${attachment}/edit`, data);
  }

  /** ***  TIME  ***** */

  static getHolidays(year, employee) {
    return http.get(`/holidays/time-off/date-ranges?year=${year}&employee=${employee}`);
  }

  static getScheduled(id, year, includeRemoteWork) {
    return http.get(
      `/requests/${id}/time-off/date-ranges?year=${year}&perPage=366&include-remote-work=${includeRemoteWork}`
    );
  }

  static getOverlap(id, from, to) {
    return http.get(`/requests/time-off/employee/${id}/who-is-out?startDate=${from}&endDate=${to}`);
  }

  static getRemoteWork(id, from, to) {
    return http.get(`/requests/time-off/employee/${id}/remote-work?startDate=${from}&endDate=${to}`);
  }

  static getBalances({ id, from, to, type, requestDays }) {
    return http.get(`/requests/time-off/${type}/employee/${id}/future-balance`, {
      params: {
        from,
        to,
        requestDays,
      },
    });
  }

  static getRequestBalances(id, type) {
    return http.get(`/requests/time-off/${type}/request/${id}/request-balance`);
  }

  static getApprovedRequestsForEmployee(employee, filters = '') {
    return http.get(`requests/time-off/vacation/${employee}/approved${filters}`);
  }

  static getActiveUnpaidPolicyForEmployee(id, from, to) {
    return http.get(`/unpaid-leave-policy/employee/${id}/policies/from/${from}/to/${to}`);
  }

  /** *** Sick Leave Policies ***** */

  static getSickLEaveDaysConsumedInfo(employee, from, to) {
    return http.get(`/requests/time-off/sick-leave/employee/${employee}/days-consumed/from/${from}/to/${to}`);
  }

  static getFutureBalanceSpecialLeavePolicy(employee, leaveType, from, to, requestDates) {
    return http.get(
      `requests/time-off/special-leave/employee/${employee}/leave-type/${leaveType}/future-balance?from=${from}&to=${to}&requestDays=${requestDates}`
    );
  }

  static getActiveSpecialLeavePolicy(id, from, to) {
    return http.get(`/special-leave-policy/employee/${id}/policies/from/${from}/to/${to}`);
  }

  /** *** Time / Excuse request */
  static getExcuseType() {
    return http.get(`/requests/time-off/excuse/reasons`);
  }

  /** ***  ./ TIME  ***** */

  /** ***  SALARY ADVANCE  ***** */

  static getSalaryAdvanceProjection(data) {
    return http.post(`requests/pay/salary-advance/projection`, data);
  }

  static getSalaryAdvanceProjectionByID(id) {
    return http.get(`requests/pay/salary-advance/${id}/projection`);
  }

  /** ***  ./ SALARY ADVANCE  ***** */

  /** ***  VACATION ADVANCE  ***** */

  // static getAmountVacationAdvance(legalEntityId, request, month, year, currency) {
  //   return http.get(
  //     `requests/pay/legal-entity/${legalEntityId}/vacation-advance/${request}/month/${month}/year/${year}/${currency}/amount`
  //   );
  // }

  // static getVacationAdvanceDuration(request, month, year) {
  //   return http.get(`requests/pay/vacation-advance/${request}/month/${month}/year/${year}/duration`);
  // }

  static getVacationAdvanceProjection(data) {
    return http.post(`requests/pay/vacation-advance/projection`, data);
  }

  static getVacationAdvanceProjectionById(id) {
    return http.get(`requests/pay/vacation-advance/${id}/projection`);
  }

  static getVacationRequests(employee) {
    return http.get(`requests/pay/vacation-advance/employee/${employee}/available-vacation-requests`);
  }

  /** ***  ./ VACATION ADVANCE  ***** */

  /** ***  LOAN  ***** */
  static getMaxAmount(employee, date) {
    return http.get(`requests/pay/loan/${employee}/date/${date}/max-amount`);
  }

  static getLoanProjection(data, cancelToken) {
    return http.post(`requests/pay/loan-projection`, data, cancelToken);
  }

  static getLoanProjectionById(id) {
    return http.get(`requests/pay/loan-request/${id}/projection`);
  }
  /** ***  ./ LOAN  ***** */

  /** ***  RESUME REQUEST  ***** */
  static getVacationResume(employee, fromDate, toDate) {
    return http.get(
      `requests/hr/resume-work/employee/${employee}/from-date/${fromDate}/to-date/${toDate}/balance-days`
    );
  }
  /** ***  ./ RESUME REQUEST ***** */

  /** ***  EXPENSE CLAIM  ***** */
  static getAvailableLegalEntities(employee, from, to) {
    return http.get(`employee/${employee}/monthly-payslip-options/from/${from}/to/${to}`);
  }

  static moveExpenseClaim(employee, from, to) {
    return http.get(`employee/${employee}/available-legal-entities/from/${from}/to/${to}`);
  }
  /** ***  ./ EXPENSE CLAIM  ***** */

  /* REQUESTS CHART */

  static getRequestsGroupByStatus(status, filters = '') {
    return http.get(`requests/groups/${status}${filters}`);
  }

  static getRequestsDurationByStatus(status, filters = '') {
    return http.get(`requests/approval-duration/${status}${filters}`);
  }

  static upadateDocumentSnaphots(id, data) {
    return http.put(`/requests/hr/document/${id}/snapshots`, data);
  }

  static templatePreview(id, mode) {
    const timestamp = new Date().getTime();
    return http.get(`requests/hr/document/${id}/pdf/${mode}?_=${timestamp}`, {
      responseType: 'arraybuffer',
    });
  }

  static documentPreviewContent(id) {
    return http.get(`requests/hr/document/${id}/preview`);
  }

  static templatePreviewContent(documentTemplate, employee, params) {
    return http.get(`requests/hr/document/template/${documentTemplate}/employee/${employee}/preview${params}`, {
      responseType: 'arraybuffer',
    });
  }

  /* LegalEntity option for Pay requests  */
  static getAvailableWpsNonwpsOptions(type, employee, date) {
    return http.get(`/requests/pay/${type}/employee/${employee}/date/${date}/available-options`);
  }

  static getAvailableWpsNonwpsOptionsExpenseClaim(employee) {
    return http.get(`/requests/pay/expense-claim/employee/${employee}/available-options`);
  }
  //   expense claim categories

  static listExpenseClaimCategories() {
    return http.get(`/request-settings/expense-claim/category`);
  }
}

export default EngagementCenterApi;
