import http from './Api';

class TeamApi
{
  static getCurrentlyActiveTeam(filters = '') {
    return http.get(`team/people/active-list${filters}`);
  }

  static getTeamPeoplePublic(filters = '') {
    return http.get(`team/people/public${filters}`);
  }

  static sendInvitation(id) {
    return http.put(`/profile/${id}/invite`);
  }

  static changeEmployeeRole(id, data) {
    return http.put(`/team/employee/${id}/role`, data);
  }

  static makeEmployeeActive(id, data) {
    return http.put(`/team/employee/${id}/activate`, data);
  }

  static makeEmployeeInactive(id, data = {}) {
    return http.put(`/team/employee/${id}/deactivate`, data);
  }

  static getActiveEmployeesShort(params = '') {
    return http.get(`team/people/quick-view${params}`);
  }

  /** ***  ./ PEOPLE ***** */

  /** *** ORG CHART ***** */
  static getOrgChartHeads(filters = '') {
    return http.get(`team/orgchart/heads${filters}`);
  }

  static getOrgChartEmployee(id, filters = '') {
    return http.get(`team/orgchart/${id}/heads${filters}`);
  }
  /** ***  ./ ORG CHART ***** */

  /** *** EMPLOYEE SELECT ***** */
  static getActiveEmployees(filters = '') {
    return http.get(`active-employees${filters}`);
  }
  /** *** ./ EMPLOYEE SELECT ***** */

  /** *** GET ALL ACTIVE EMPLOYEES ***** */
  static getAllActiveEmployees(filters = '') {
    return http.get(`employee/active${filters}`);
  }

  // bulk
  static sendInvitationMultipl(employees) {
    return http.put(`profile/invite/multiple`, { employees });
  }

  static sendInvitationMultiplAll() {
    return http.put(`profile/invite/all`);
  }

  static changeEmployeesRole(employees, value) {
    return http.put(`settings/team/employees/permission-group/multiple`, {
      employees,
      permissionGroup: value.permissionGroup,
      // notice: value.notice,
    });
  }

  static changeEmployeeRoleAll(value) {
    return http.put(`settings/team/employees/permission-group/all`, {
      permissionGroup: value.permissionGroup,
      // notice: value.notice,
    });
  }

  static makeEmployeesActivateMultipl(employees, value) {
    return http.put(`/team/employee/activate/multiple`, { employees, sendNotice: value.sendInvite });
  }

  static makeEmployeesActivateAll(value) {
    return http.put(`/team/employee/activate/all`, { sendNotice: value.sendInvite });
  }

  static makeEmployeesInactiveMultipl(employees) {
    return http.put(`/team/employee/deactivate/multiple`, { employees });
  }

  static makeEmployeesInactiveAll() {
    return http.put(`/team/employee/deactivate/all`);
  }

  static getAllReportersOfLineManager(employee, filters = '') {
    return http.get(`team/employee/${employee}/subordinates${filters}`);
  }

  static assigneLineManager(data, param) {
    return http.post(`line-managers-bulk-assign${param}`, data);
  }

  static enableAutomaticContractExtension(data, filters = '') {
    return http.put(`team/contracts/bulk-enable-extension${filters}`, data);
  }
}

export default TeamApi;
