import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './AttendanceSelect.module.scss';

const AttendanceSelect = ({ onChange, name, data, isDisabled, isInvalid }) => (
  <input
    className={cx(
      'form-control',
      isInvalid ? 'is-invalid' : 'valid',
      styles.inputTime,
      isDisabled && styles.disabled,
      data.validLocation === false && 'text-red'
    )}
    type='time'
    name={name}
    min='00:00'
    max='23:59'
    value={data?.value || ''}
    onChange={(event) => onChange(name, event.target.value)}
    disabled={isDisabled}
  />
);

AttendanceSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool,
};

AttendanceSelect.defaultProps = {
  isInvalid: false,
};

export default AttendanceSelect;
