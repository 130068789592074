import React from 'react';

import cx from 'classnames';

import palmVideo from 'assets/video/palmmp4.mp4';
import t from 'utility/setTranslation';

import styles from './PalmHrAiWelcomeScreen.module.scss';

const PalmHrAIWelcomeScreen = () => {
  const isWindowsOs = navigator.userAgent.includes('Windows');

  return (
    <div className='mx-5 pb-4'>
      <div
        className={cx(
          styles.PalmHrAiWelcomeScreen__intro,
          isWindowsOs ? 'bg-dark-blue-windows' : 'bg-dark-blue',
          'd-flex flex-column align-items-center justify-content-center radius-8'
        )}
      >
        <video autoPlay loop src={palmVideo} width={75} muted />
        <p className='text-gray-300 font-size-16'>{t('GENERAL.PALMHR_WELCOME_MESSAGE')}</p>
      </div>
      <p className='text-center mt-2 text-dark-blue fs-12'>{t('GENERAL.PALMHR_DISCLAIMER')}</p>
    </div>
  );
};

export default PalmHrAIWelcomeScreen;
