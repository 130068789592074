import React from 'react';

import PropTypes from 'prop-types';

import Alert from 'components/alerts/Alert';
import IconButton from 'components/buttons/IconButton/IconButton';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import ContentHeader from 'components/ContentHeader';
import t from 'utility/setTranslation';

const RecoveryCodes = ({ data, onRequestClose }) => (
  <div className='h-100 d-flex flex-column'>
    <ContentHeader className='mx-5 mb-4'>
      <div className='d-flex justify-content-between align-items-center'>
        <h5 className='text-capitalize'>{t('GENERAL.TWO_FACTOR_AUTH_ENABLED')}</h5>
        <IconButton icon='icon-cross-x' onClick={() => onRequestClose()} size='md' />
      </div>
    </ContentHeader>
    <div className='d-flex flex-column flex-1'>
      <div className='px-5 d-flex flex-1 flex-column'>
        <div>
          <Alert type='info' textMessage={t('GENERAL.TWO_FACTOR_AUTH_INFO')} hasIcon />
        </div>
        <div className='w-100 h-100'>
          {data.length > 0 &&
            data.map((item) => (
              <div
                style={{ lineHeight: '2' }}
                key={item}
                className='d-flex justify-content-start align-items-center fs-14'
              >
                <i className='icon-bullet-point text-gray me-2' />
                <div>{item}</div>
              </div>
            ))}
        </div>
      </div>
      <div className='d-flex justify-content-end py-4 px-5 border-t'>
        <RoundedButton
          type='button'
          text='Close'
          btnStyle='contained'
          color='primary'
          size='md'
          onClick={() => onRequestClose()}
        />
      </div>
    </div>
  </div>
);

RecoveryCodes.propTypes = {
  data: PropTypes.array,
  onRequestClose: PropTypes.func,
};

RecoveryCodes.defaultProps = {
  data: [],
  onRequestClose: undefined,
};

export default React.memo(RecoveryCodes);
