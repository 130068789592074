import { useMutation, useQuery } from '@tanstack/react-query';

import AccountApi from 'api/AccountApi';
import AuthApi from 'api/AuthApi';
import CompanySettingsApi from 'api/CompanySettingsApi';

export const queryKeys = {
  subscriptionDueDate: ['accountDueDate'],
  cancelSubscription: ['cancelSubscription'],
  renewSubscription: ['renewSubscription'],
  confirmSubscription: ['confirmSubscription'],
  confirmPayment: ['confirmPayment'],
  subscriptionData: ['subscriptionData'],
  logout: ['logout'],
  checkIsDomainAvailable: ['checkIsDomainAvailable'],
  plgPlansAndSubscriptions: ['plgPlansAndSubscriptions'],
  plgClearData: ['plgClearData'],
  plgBookDemo: ['plgBookDemo'],
  invoicePreview: ['invoicePreview'],
  plgGoLive: ['plgGoLive'],
  isPaymentComplete: ['isPaymentComplete'],
  recordPageVisit: ['recordPageVisit'],
  recordingPageVisits: ['recordingPageVisits'],
  plgTasks: ['plgTasks'],
  stripeClientSecret: ['stripeClientSecret'],
  addStripePaymentMethod: ['addStripePaymentMethod'],
  confirmAddStripePaymentMethod: ['confirmAddStripePaymentMethod'],
  listAccountSubscriptions: ['listAccountSubscriptions'],
  updateAccountSubscription: ['updateAccountSubscription'],
  onlinePaymentConfigured: ['onlinePaymentConfigured'],
  subscriptionsList: ['subscriptionsList'],
  getBillingInfo: ['getBillingInfo'],
  updateBillingInfo: ['updateBillingInfo'],
  cancelAccountSubscription: ['cancelAccountSubscription'],
};

export const useSubscriptionDueDate = () =>
  useQuery(queryKeys.subscriptionDueDate, AccountApi.getAccountSubscriptionDueDate);

export const useCancelSubscription = () => useMutation(queryKeys.cancelSubscription, AccountApi.cancelSubscription);

export const userRenewSubscription = () => useMutation(queryKeys.renewSubscription, AccountApi.renewSubscription);

export const useConfirmPayment = () => useMutation(queryKeys.confirmSubscription, AccountApi.confirmPayment);

export const useAccountSubscriptionData = (options) =>
  useQuery(queryKeys.subscriptionData, CompanySettingsApi.getCompanyDataWithSubscription, {
    staleTime: 60 * 60 * 1000,
    ...options,
  });

export const useLogout = () => useMutation(queryKeys.logout, AuthApi.logout);

export const useGetAccountPlgPlansAndSubscriptions = () =>
  useMutation(queryKeys.plgPlansAndSubscriptions, AccountApi.getAccountPlansAndSubscriptions);

export const useCheckIsDomainAvailable = () =>
  useMutation(queryKeys.checkIsDomainAvailable, AccountApi.checkIsDomainAvailable);

export const usePlgClearData = () => useMutation(queryKeys.plgClearData, AccountApi.plgClearData);

export const usePlgBookDemo = () => useMutation(queryKeys.plgBookDemo, AccountApi.plgBookDemo);

export const useMutatePlgInvoicePreview = () => useMutation(queryKeys.invoicePreview, AccountApi.getInvoicePreview);

export const useMutatePlgGoLive = () => useMutation(queryKeys.plgGoLive, AccountApi.plgGoLive);

export const useIsPaymentComplete = () => useQuery(queryKeys.isPaymentComplete, AccountApi.isPaymentComplete);

export const useMutateRecordPageVisit = () => useMutation(queryKeys.recordPageVisit, AccountApi.recordPageVisit);

export const useRecordingPageVisits = () => useQuery(queryKeys.recordingPageVisits, AccountApi.getRecordingPageVisits);

export const useGetPlgTasks = () => useQuery(queryKeys.plgTasks, AccountApi.getPlgTasks);

export const useStripeClientSecret = () => useQuery(queryKeys.stripeClientSecret, AccountApi.getStripeClientSecret);

export const useListAccountSubscriptions = () =>
  useQuery(queryKeys.listAccountSubscriptions, AccountApi.getAccountListSubscriptions);

export const useMutateUpdateAccountSubscription = () =>
  useMutation(queryKeys.updateAccountSubscription, AccountApi.updateAccountSubscription);

export const useAddStripePaymentMethod = () =>
  useQuery(queryKeys.addStripePaymentMethod, AccountApi.addStripePaymentMethod);

export const useConfirmAddStripePaymentMethod = () =>
  useMutation(queryKeys.confirmAddStripePaymentMethod, AccountApi.confirmAddStripePaymentMethod);

export const useGetOnlinePaymentConfigured = () =>
  useQuery(queryKeys.onlinePaymentConfigured, AccountApi.getOnlinePaymentConfigured);

export const useGetBillingInfo = () =>
  useQuery(queryKeys.getBillingInfo, AccountApi.getBillingInfo);

export const useMutateUpdateBillingInfo = () =>
  useMutation(queryKeys.updateBillingInfo, AccountApi.updateBillingInfo);

export const useMutateCancelAccountSubscription = () =>
  useMutation(queryKeys.cancelAccountSubscription, AccountApi.cancelAccountSubscription);
