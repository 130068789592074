import React, { useContext } from 'react';

import cx from 'classnames';

import calenderImage from 'assets/img/ws_empty.png';
import { AuthContext } from 'auth/AuthContext';
import EmptyState from 'components/EmptyPages/EmptyState';
import Card from 'components/PHRUI/Card/Card';
import { useVisibility } from 'shared/useVisibility';
import t from 'utility/setTranslation';

import ShiftItem from './components/ShiftItem/ShiftItem';
import TodayShiftModal from './components/TodayShiftModal/TodayShiftModal';
import { useQueryTodayShifts } from './hooks/useTodayShifts';
import styles from './styles.module.scss';
import { processShifts, prepareShiftItems } from './utillity';
import SkeletonCardAdvanced from '../components/SkeletonCardAdvanced';

const ShiftWidget = () => {
  const { authState } = useContext(AuthContext);
  const { isOpen, open, close } = useVisibility();

  const { isLoading, data: todayShiftsResponse } = useQueryTodayShifts();
  const { items: shiftsItems = [] } = todayShiftsResponse?.data ?? [];

  const userLanguage = authState.user.language;

  const groupedShifts = processShifts(shiftsItems, userLanguage);
  const items = prepareShiftItems(groupedShifts);

  return (
    <>
      <Card raised hoverable={false} className={cx('bg-white mb-3 position-relative')}>
        <div className='d-flex justify-content-between align-items-center'>
          <span className={cx(styles.title)}>
            {t('GENERAL.TODAY_SHIFTS')} <span className='text-gray'>({items.length})</span>
          </span>
          <i className={cx('icon-circle-arrow-right', styles.arrowIcon)} onClick={open} />
        </div>
        {isLoading && <SkeletonCardAdvanced num={3} />}
        {items.length === 0 && !isLoading && (
          <EmptyState title={t('GENERAL.NO_TODAY_SHIFTS')} showBtn={false} className='bg-white' icon={calenderImage} />
        )}
        {items.map((shifts) => (
          <ShiftItem
            key={shifts.title}
            employees={shifts.employees}
            userLanguage={userLanguage}
            info={shifts.info}
            title={shifts.title}
          />
        ))}
      </Card>
      <TodayShiftModal isOpen={isOpen} onClose={close} />
    </>
  );
};

export default ShiftWidget;
