import React, { useEffect } from 'react';

import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Alert from 'components/alerts/Alert';
import t from 'utility/setTranslation';

import { useIsPaymentComplete, useStripeClientSecret } from '../../../../../../hooks/useAccount';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PayWithStripe = ({ formik }) => {
  const { data: isPaymentCompleteData, refetch } = useIsPaymentComplete();
  const paymentSuccess = isPaymentCompleteData?.data?.success;

  const { data } = useStripeClientSecret();

  const clientSecret = data?.data?.clientSecret;

  const handleComplete = () => {
    formik.setFieldValue('isPaymentComplete', true);
    formik.setFieldTouched('isPaymentComplete', true);
    formik.setFieldError('isPaymentComplete', null);
    refetch();
  };

  useEffect(() => {
    handleComplete();
  }, [formik.values.isPaymentComplete, paymentSuccess]);

  const options = { clientSecret, onComplete: handleComplete };

  return (
    <section className='d-flex flex-column justify-content-between py-4'>
      <h5 className='subtitle pb-2'>{t('GENERAL.ENTER_PAYMENT_DETAILS')}</h5>
      <Alert hasIcon type='info' textMessage={t('PLG.STRIP_PAYMENT_INFO')} className='mb-4' />
      {formik.errors.isPaymentComplete && formik.touched.isPaymentComplete && (
        <div className='error mb-4'>{formik.errors.isPaymentComplete}</div>
      )}
      <div id='checkout' style={{ width: '100%' }}>
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout className={'w-100'} />
        </EmbeddedCheckoutProvider>
      </div>
    </section>
  );
};

export default PayWithStripe;
