import _ from 'lodash';
import PropTypes from 'prop-types';

import propTypesUtility from 'utility/propTypesUtility';

export const propTypes = () => ({
  id: propTypesUtility.idPropType(),
  value: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  className: propTypesUtility.classNamePropType(),
  style: propTypesUtility.stylePropType(),
  onSelect: PropTypes.func,
});

export const defaultProps = () => ({
  id: 'phr-select',
  value: null,
  label: null,
  className: '',
  style: {},
  onSelect: null,
});

export const findLabelByValue = (value, options) => _.find(options, ['value', value])?.label;
