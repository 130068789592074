import React, { useContext, useEffect, useState } from 'react';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import Radio from 'components/forms/components/Radio';
import SuccessCheckmark from 'components/SuccessCheckmark/SuccessCheckmark';
import t from 'utility/setTranslation';

import DensityPlaceholder from './DensityPlaceholder';
import { RowDensityContext } from './RowDensityContext';

const RowDensityModal = ({ onClose, setIsFormDirty }) => {
  const [saved, setSaved] = useState(false);
  const { density, onSetRowDensity } = useContext(RowDensityContext);

  const formik = useFormik({
    initialValues: {
      density: density || 'default',
    },
    onSubmit: (values) => {
      setSaved(true);
      onSetRowDensity(values.density);
      setTimeout(onClose, 1000);
    },
  });
  useEffect(() => {
    setIsFormDirty(formik.dirty);
  }, [formik.dirty]);

  return (
    <div className='pt-4'>
      {saved && <SuccessCheckmark />}
      <form noValidate onSubmit={formik.handleSubmit} className='h-100'>
        <p className='subtitle mb-4'>{t('INSIGHTS.DENSITY_MODAL_SUBTITLE')}</p>
        <div>
          <div className='border-btm border-t p-3 d-flex justify-content-between align-items-center'>
            <Radio
              name='density'
              value='default'
              label='GENERAL.DEFAULT'
              checked={formik.values.density === 'default'}
              onChange={formik.handleChange}
            />
            <DensityPlaceholder />
          </div>
          <div className='border-btm p-3 d-flex justify-content-between align-items-center'>
            <Radio
              name='density'
              value='comfortable'
              label='GENERAL.COMFORTABLE'
              checked={formik.values.density === 'comfortable'}
              onChange={formik.handleChange}
            />
            <DensityPlaceholder num={2} />
          </div>
          <div className='border-btm p-3 d-flex justify-content-between align-items-center'>
            <Radio
              name='density'
              value='compact'
              label='GENERAL.COMPACT'
              checked={formik.values.density === 'compact'}
              onChange={formik.handleChange}
            />
            <DensityPlaceholder num={3} />
          </div>
        </div>
        <div className='d-flex justify-content-end mt-11 btn-modal-box border-t'>
          <RoundedButton text='Save' color='primary' size='md' type='submit' />
        </div>
      </form>
    </div>
  );
};

RowDensityModal.propTypes = { onClose: PropTypes.func.isRequired, setIsFormDirty: PropTypes.func };
RowDensityModal.defaultProps = { setIsFormDirty: undefined };

export default RowDensityModal;
