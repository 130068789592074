import React from 'react';

import Personas from 'components/Personas';
import Tooltip from 'components/tooltip/Tooltip';
import readName from 'utility/readName';

const ShiftItem = ({ title, info, employees, userLanguage }) => (
  <div className='mt-3'>
    <div className='d-flex align-items-center gap-2'>
      <h4 className='m-0 font-size-14 text-gray'>{title}</h4>
      {info && (
        <Tooltip label={info} position='topRight'>
          <i className='icon-info font-size-14 text-gray' />
        </Tooltip>
      )}
    </div>
    <div className='d-flex align-items-center gap-2 mt-1 flex-wrap'>
      {employees.map((employee) => (
        <div key={employee.id}>
          <Tooltip label={readName(userLanguage, employee.fullName, employee.fullNameArabic)}>
            <Personas user={employee} size={32} />
          </Tooltip>
        </div>
      ))}
    </div>
  </div>
);

export default ShiftItem;
