import React, { useEffect } from 'react';

import NumberInput from 'components/forms/components/NumberInput';
import TextInput from 'components/forms/components/TextInput';
import LoadingList from 'components/LoadingList/LoadingList';

import { useCompanySettingsData } from '../../../../../../../modules/CompanySettings/hooks/useCompanySettings';
import { useAccountSubscriptionData } from '../../../../../../hooks/useAccount';

const GoLiveBillingInformation = ({ formik }) => {
  const { data, isLoading } = useCompanySettingsData();
  const { data: subscriptionData } = useAccountSubscriptionData();

  const firstName = data?.data?.representativeFirstName;
  const lastName = data?.data?.representativeLastName;
  const email = data?.data?.representativeContactEmail;
  const company = data?.data?.fullName;
  const phone = data?.data?.representativeContactPhone;
  const country = subscriptionData?.data.account.customerCountry;

  useEffect(() => {
    if (!data || !subscriptionData || formik.values.billingInfo.firstName) {
      return;
    }
    formik.setFieldValue('billingInfo', {
      firstName,
      lastName,
      email,
      company,
      phone,
      country,
      addressLine2: formik.values.billingInfo.addressLine2 ?? '',
      vatNumber: formik.values.billingInfo.vatNumber ?? '',
      state: formik.values.billingInfo.state ?? '',
    });
  }, [isLoading, data?.data, subscriptionData?.data]);

  if (isLoading || !data || !subscriptionData) {
    return (
      <LoadingList
        rowsCount={5}
        spaceBetween={0}
        rowHeight={140}
        className='justify-content-center gap-5 flex-1 mt-4'
      />
    );
  }

  return (
    <div className='col-12'>
      <div className='row row-small'>
        <div className='col-12 px-2a mb-3'>
          <TextInput
            name='billingInfo.vatNumber'
            label='vatNumber'
            value={formik.values.billingInfo.vatNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.billingInfo && formik.errors.billingInfo?.vatNumber}
          />
        </div>
        <div className='col-12 px-2a mb-3'>
          <TextInput
            name='billingInfo.addressLine1'
            label='address'
            value={formik.values.billingInfo.addressLine1}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.billingInfo && formik.errors.billingInfo?.addressLine1}
            isRequired
          />
        </div>
        <div className='col-12 px-2a mb-3'>
          <TextInput
            name='billingInfo.addressLine2'
            label='address'
            value={formik.values.billingInfo.addressLine2}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.billingInfo && formik.errors.billingInfo?.addressLine2}
          />
        </div>
        <div className='col-6 px-2a mb-3'>
          <TextInput
            name='billingInfo.country'
            label='country'
            value={formik.values.billingInfo.country}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.billingInfo && formik.errors.billingInfo?.country}
            isRequired
            isDisabled
          />
        </div>
        <div className='col-6 px-2a mb-3'>
          <TextInput
            name='billingInfo.city'
            label='city'
            value={formik.values.billingInfo.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.billingInfo && formik.errors.billingInfo?.city}
            isRequired
          />
        </div>
        <div className='col-6 px-2a mb-3'>
          <NumberInput
            name='billingInfo.zip'
            label='postal code'
            value={formik.values.billingInfo.zip}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.billingInfo && formik.errors.billingInfo?.zip}
            isRequired
          />
        </div>
        <div className='col-6 px-2a mb-3'>
          <TextInput
            name='billingInfo.state'
            label='state'
            value={formik.values.billingInfo.state}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.billingInfo && formik.errors.billingInfo?.state}
          />
        </div>
      </div>
    </div>
  );
};

export default GoLiveBillingInformation;
