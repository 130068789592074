import React, { useContext, useState, useRef, useEffect } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { AuthContext } from 'auth/AuthContext';
import Portal from 'components/Portal/Portal';
import t from 'utility/setTranslation';

import styles from './Tooltip.module.scss';
import { tooltipPositions } from './tooltipPositions';

const Tooltip = ({ label, children, type, size, useTranslate, position, className }) => {
  const {
    authState: {
      user: { language },
    },
  } = useContext(AuthContext);

  const [style, setStyle] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);
  const wrapperRef = useRef(null);
  const elRef = useRef(null);

  const mouseEnter = () => {
    setShowTooltip(true);
  };

  const mouseLeave = () => {
    setShowTooltip(false);
  };

  useEffect(() => {
    const stylesState = {};
    const pos = wrapperRef.current.getBoundingClientRect();

    if (showTooltip) {
      const element = elRef.current.getBoundingClientRect();

      // left right positions
      if (position === 'left' || position === 'right') {
        stylesState.top = pos.y + pos.height / 2 - element.height / 2;
      }
      if (position.includes('Top')) {
        stylesState.top = pos.y - 6;
      }
      if (position.includes('Bottom')) {
        stylesState.top = pos.y + 4 + pos.height - element.height;
      }
      if (
        (tooltipPositions.LEFT_POSITIONS.includes(position) && language === 'ar') ||
        (tooltipPositions.RIGHT_POSITIONS.includes(position) && language === 'en')
      ) {
        stylesState.left = pos.x + pos.width + 10;
      }
      if (
        (tooltipPositions.LEFT_POSITIONS.includes(position) && language === 'en') ||
        (tooltipPositions.RIGHT_POSITIONS.includes(position) && language === 'ar')
      ) {
        stylesState.left = pos.x - 10 - element.width;
      }

      // top bottom positions
      if (position === 'top' || position === 'bottom') {
        stylesState.left = pos.x + pos.width / 2 - element.width / 2;
      }
      if (tooltipPositions.TOP_POSITIONS.includes(position)) {
        stylesState.top = pos.y - tooltipPositions.OFFSET - element.height;
      }
      if (tooltipPositions.BOTTOM_POSITIONS.includes(position)) {
        stylesState.top = pos.y + pos.height + tooltipPositions.OFFSET;
      }
      if ((position.includes('Left') && language === 'ar') || (position.includes('Right') && language === 'en')) {
        stylesState.left = pos.x + pos.width / 2 - tooltipPositions.OFFSET;
      }
      if ((position.includes('Left') && language === 'en') || (position.includes('Right') && language === 'ar')) {
        stylesState.left = pos.x + pos.width / 2 - element.width + tooltipPositions.OFFSET;
      }
    }
    setStyle(stylesState);
  }, [showTooltip, language]);

  return (
    <>
      <div
        data-testid='tooltip-wrapper'
        className={styles.tooltipBox}
        ref={wrapperRef}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        onScroll={mouseLeave}
      >
        {children}
      </div>
      {showTooltip && (
        <Portal>
          <div
            data-testid='tooltip'
            ref={elRef}
            style={style}
            className={cx(styles[position], styles.tooltip, className, styles[type], styles[size])}
          >
            {useTranslate ? t(label) : label}
          </div>
        </Portal>
      )}
    </>
  );
};

Tooltip.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  position: PropTypes.oneOf([
    'right',
    'rightTop',
    'rightBottom',
    'top',
    'topLeft',
    'topRight',
    'left',
    'leftTop',
    'leftBottom',
    'bottom',
    'bottomLeft',
    'bottomRight',
  ]),
  useTranslate: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.oneOf(['md', 'lg', '']),
};

Tooltip.defaultProps = {
  label: '',
  className: '',
  position: 'right',
  useTranslate: false,
  type: 'dark',
  size: '',
};

export default Tooltip;
