import { useMemo } from 'react';

import * as yup from 'yup';

import t from 'utility/setTranslation';

const EN = 'en';
const AR = 'ar';
const DEFAULT = EN;

// eslint-disable-next-line import/prefer-default-export
export const languageConstants = {
  DEFAULT,
  EN,
  AR,
  LANGUAGES: { [EN]: 'English', [AR]: 'Arabic' },
  LANGUAGES_DATA_SUFFIX: { [EN]: '', [AR]: 'Arabic' },
};

export const TRANSLATIONS_TYPES = {
  name: 'NAME',
  content: 'CONTENT',
};

export const languageValidationRule = (requiredLanguageCodes) =>
  yup.array().of(
    yup.object().shape({
      value: yup.string().when(['code'], {
        is: (value) => requiredLanguageCodes.includes(value),
        then: (schema) => schema.required(t('GENERAL.REQUIRED_FIELD')),
        otherwise: (schema) => schema,
      }),
    })
  );

export const useRequiredTranslationCode = (translations = [], fieldType) => {
  const requiredTranslations = useMemo(
    () =>
      translations.reduce(
        (acc, { value, code, type }) =>
          fieldType ? (value && type === fieldType ? acc.concat(code) : acc) : value ? acc.concat(code) : acc,
        []
      ),
    []
  );

  return requiredTranslations.length ? requiredTranslations : [DEFAULT];
};
