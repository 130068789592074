import React, { useEffect, useState } from 'react';

import Localize from 'react-intl-universal';

import http from 'api/Api';
import AlertMessage from 'components/AlertMessage';
import ErrorBoundary from 'modules/Recruitment/components/ErrorBoundry/ErrorBoundry';
import t from 'utility/setTranslation';

const PublicRoute = ({ component: Component }) => {
  const [alertBoxMsg, setAlertBoxMsg] = useState('');

  const body = document.querySelector('body');

  http.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 422) {
        let errText = '';
        error?.response?.data?.validationErrors?.map((el) => {
          errText += `${t(el)}\n`;
          return el;
        });
        setAlertBoxMsg(errText);
      } else if (error?.response?.status !== 404) {
        setAlertBoxMsg(t(error?.response?.data?.message));
      }
      return Promise.reject({ ...error });
    }
  );

  useEffect(() => {
    if (Localize && Localize.options) {
      if (Localize.options?.currentLocale === 'ar') {
        body.classList.add('lang-ar');
      } else {
        body.classList.remove('lang-ar');
      }
    }
  }, []);

  const onCloseToast = () => {
    setAlertBoxMsg('');
  };

  const isExpiredToken = window.location.hash.includes('tokenExpired');

  return (
    <ErrorBoundary>
      {!isExpiredToken && alertBoxMsg && <AlertMessage text={alertBoxMsg} type='error' callback={onCloseToast} />}
      <Component />
    </ErrorBoundary>
  );
};

export default PublicRoute;
