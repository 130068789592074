import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import { AuthContext } from 'auth/AuthContext';
import formatKey from 'utility/formatKey';
import readName from 'utility/readName';

import styles from './TableHead.module.scss';
import TableHeadCell from './TableHeadCell';

const TableHead = ({ onSort, headerCells, className, style }) => {
  const { authState } = useContext(AuthContext);
  return (
    <thead className={className}>
      <tr style={style} className={styles.tr}>
        {headerCells.map((cell) => {
          let title = '';
          if (cell.custom) {
            title = readName(authState.user.language, cell.title, cell.titleArabic);
          } else if (cell.translate) {
            title = cell.title;
          } else {
            title = cell.title ? formatKey('GENERAL', cell.title) : '';
          }
          return (
            <TableHeadCell
              key={title}
              title={title}
              tooltip={cell.tooltip}
              textRight={cell.textRight}
              textCenter={cell.textCenter || false}
              onSort={onSort}
              sortable={cell.sortable}
              colSpan={cell.colSpan}
              sortValue={cell.sortValue}
              className={cell.className}
              customButton={cell.customButton}
            />
          );
        })}
      </tr>
    </thead>
  );
};

TableHead.propTypes = {
  headerCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSort: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};

TableHead.defaultProps = {
  onSort: undefined,
  className: '',
  style: null,
};

export default React.memo(TableHead);
