import includes from 'lodash/includes';
import reduce from 'lodash/reduce';

import { reportConstants } from 'constants/report';
import { eosRoutes } from 'services/EndOfService/EOSRouterService';
import { PrivateRoutes } from 'topNavigation/Router/routes';

const customAttendanceReport = `/time/insights/${reportConstants.REPORT_CUSTOM_ATTENDANCE}`;

export const roles = {
  '/dashboard': ['admin', 'user'],
  '/dashboard/event-calendar': ['admin', 'user', 'hr'],

  '/profile': ['admin', 'user'],
  '/profile/:id': ['admin', 'user'],
  '/profile/:id/contracts': ['admin', 'user'],
  '/profile/:id/contracts/:contractId': ['admin', 'user'],
  '/profile/:id/financial': ['admin', 'user'],
  '/profile/:id/financial/bank-accounts': ['admin', 'user'],
  '/profile/:id/financial/pay-info': ['admin', 'user', 'hr'],
  '/profile/:id/financial/accruals': ['admin', 'user'],
  '/profile/:id/documents': ['admin', 'user'],
  '/profile/:id/documents/my-documents': ['admin', 'user'],
  '/profile/:id/documents/my-documents/:folderId': ['admin', 'user'],
  '/profile/:id/documents/company-files': ['admin', 'user'],
  '/profile/:id/documents/company-files/:folderId': ['admin', 'user'],
  '/profile/:id/timesheets': ['admin', 'user'],
  '/profile/:id/timesheets/attendance': ['admin', 'user'],
  '/profile/:id/timesheets/time-tracking': ['admin', 'user'],
  '/profile/:id/timesheets/holidays': ['admin', 'user'],
  '/profile/:id/leave': ['admin', 'user'],
  '/profile/:id/leave/:type': ['admin', 'user'],
  '/profile/:id/leave/:type/details': ['admin', 'user'],
  '/profile/:id/leave/:type/policies': ['admin', 'user'],
  '/profile/:id/leave/details/remote-work-leave': ['admin', 'user'],
  '/profile/:id/assets': ['admin', 'user', 'hr'],
  '/profile/:id/assets/:type': ['admin', 'user', 'hr'],
  '/profile/:id/shifts': ['admin', 'user', 'hr'],
  '/profile/:id/goals/:goalId': ['admin', 'user', 'hr'],
  '/profile/:id/performance/goals': ['admin', 'user', 'hr'],
  '/profile/:id/performance/goals/:goalId': ['admin', 'user', 'hr'],
  '/profile/:id/performance/goals/:goalId/target/:kpiId': ['admin', 'user', 'hr'],
  '/profile/:id/performance/one-on-one': ['admin', 'user', 'hr'],

  '/expired': ['admin', 'user'],

  '/organization': ['admin', 'user'],
  '/organization/gosi': ['admin', 'hr'],
  '/organization/people': ['admin', 'hr', 'user'],
  '/organization/org_chart': ['admin', 'hr', 'user'],
  '/organization/onboarding': ['admin', 'hr'],
  '/onboarding': ['admin', 'hr'],
  '/organization/offboarding': ['admin', 'hr'],
  '/offboarding/:id': ['admin', 'hr'],
  '/organization/insights': ['admin', 'hr'],
  '/organization/insights/team_details': ['admin', 'hr'],
  '/organization/insights/headcount': ['admin', 'hr'],
  '/organization/insights/fluctuation': ['admin', 'hr'],
  '/organization/insights/demographic': ['admin', 'hr'],
  '/organization/insights/organization': ['admin', 'hr'],
  '/organization/insights/contract_details': ['admin', 'hr'],
  '/organization/insights/length_of_service': ['admin', 'hr'],
  '/organization/insights/probation_periods': ['admin', 'hr'],
  '/organization/insights/dependants': ['admin', 'hr'],
  '/organization/insights/objectives': ['admin', 'hr'],

  '/requests': ['admin', 'user'],
  '/requests/my_requests': ['admin', 'user'],
  '/requests/my_requests/:requestType': ['admin', 'user'],
  '/requests/all_requests': ['admin', 'hr'],
  '/requests/all_requests/:requestType': ['admin', 'hr'],

  '/time': ['admin', 'user'],

  '/time/leave': ['admin', 'user'],
  '/time/leave/:type': ['admin', 'user'],
  '/time/leave/remote-work-leave': ['admin', 'user'],

  '/time/calendar': ['admin', 'user'],

  '/time/timesheets': ['admin', 'user'],
  '/time/timesheets/wizard': ['admin'],
  '/time/timesheets/monthly': ['admin', 'user'],
  '/time/timesheets/:timesheetId': ['admin', 'user'],
  '/time/timesheets/:timesheetId/:status': ['admin', 'user'],
  '/time/timesheets/:timesheetId/:status/:view': ['admin', 'user'],
  '/time/approval': ['admin', 'user'],
  '/time/approval/wizard': ['admin'],
  '/time/approval/monthly': ['admin', 'user'],
  '/time/approval/:timesheetId': ['admin', 'user'],
  '/time/approval/:timesheetId/:status': ['admin', 'user'],
  '/time/approval/:timesheetId/:status/:view': ['admin', 'user'],
  '/time/shift-schedule': ['admin', 'hr', 'user'],
  '/time/billable-time': ['user', 'admin', 'hr'],
  '/time/billable-time/my-projects': ['user', 'admin', 'hr'],
  '/time/billable-time/approvals': ['user', 'admin', 'hr'],

  '/time/insights': ['admin', 'hr'],
  '/time/insights/vacation_balance': ['admin', 'hr'],
  '/time/insights/vacation_policies': ['admin', 'hr'],
  '/time/insights/work_schedules': ['admin', 'hr'],
  '/time/insights/attendance': ['admin', 'hr'],
  [customAttendanceReport]: ['admin', 'hr'],
  '/time/insights/leave': ['admin', 'hr'],
  '/time/insights/overtime': ['admin', 'hr'],
  '/time/insights/shifts': ['admin', 'hr'],
  '/time/insights/billable_hours_insight': ['admin', 'hr'],
  '/time/insights/remote_work_insight': ['admin', 'hr'],

  '/pay': ['admin', 'user'],
  '/pay/compensation': ['admin', 'user'],
  '/pay/payroll': ['admin', 'user'],
  '/pay/payroll/list': ['admin', 'user'],
  '/pay/payroll/:leID': ['admin', 'user'],
  '/pay/payroll/wizard': ['admin'],
  '/pay/payroll/wizard/legal-entity/:legalEntityId': ['admin'],
  '/pay/payroll/wizard/legal-entity/:legalEntityId/approval-flow/:approvalFlowId': ['admin'],
  '/pay/insights': ['admin', 'user'],
  '/pay/insights/payroll_history': ['admin', 'hr', 'accountant', 'user'],
  '/pay/insights/eos_accrual': ['admin', 'hr', 'accountant', 'user'],
  '/pay/insights/eos_monthly': ['admin', 'hr', 'accountant', 'user'],
  '/pay/insights/vacation_accrual': ['admin', 'hr', 'accountant', 'user'],
  '/pay/insights/loan': ['admin', 'hr', 'accountant', 'user'],
  '/pay/insights/gosi': ['admin', 'hr', 'accountant', 'user'],
  '/pay/insights/expense_claim': ['admin', 'hr'],
  '/pay/insights/payroll_report': ['admin', 'hr', 'accountant'],
  '/pay/insights/payroll_summary_report': ['admin', 'hr', 'accountant'],
  '/pay/insights/variance_summary_report': ['admin', 'hr', 'accountant'],
  '/pay/insights/leave_salary_&_gratuity': ['admin', 'hr', 'accountant'],

  '/tasks': ['admin', 'user'],
  '/tasks/my_tasks': ['admin', 'user'],
  '/tasks/my_tasks/:taskType': ['admin', 'user'],
  '/tasks/all_tasks': ['admin', 'hr'],
  '/tasks/all_tasks/:taskType': ['admin', 'hr'],

  '/files': ['admin', 'hr', 'user'],
  '/files/company-files': ['admin', 'hr', 'user'],
  '/files/company-files/:folderId': ['admin', 'hr', 'user'],
  '/files/employee-documents': ['admin', 'hr', 'user'],

  '/company-assets': ['admin', 'user', 'hr'],
  '/company-assets/management': ['admin', 'hr'],
  '/company-assets/management/:type': ['admin', 'hr'],
  '/company-assets/my-assets': ['admin', 'user', 'hr'],
  '/company-assets/my-assets/:id': ['admin', 'user', 'hr'],
  '/company-assets/my-assets/:id/:type': ['admin', 'user', 'hr'],
  '/company-assets/insights': ['admin', 'hr'],
  '/company-assets/insights/assets': ['admin', 'hr'],

  '/assets': ['admin'],
  '/assets/management': ['admin'],
  '/assets/management/:type': ['admin'],

  '/performance': ['admin', 'user', 'hr'],
  '/performance/goals': ['admin', 'user', 'hr'],
  '/performance/goals/:goalId': ['admin', 'user', 'hr'],
  '/performance/goals/:goalId/target/:kpiId': ['admin', 'user', 'hr'],
  '/performance/goals/employee/:id': ['admin', 'user', 'hr'],
  '/performance/goals/:id/goal-details/:goalId': ['admin', 'user', 'hr'],
  '/performance/goals/employee/:id/goal-details/:goalId': ['admin', 'user', 'hr'],
  '/performance/goals/employee/:id/goal-details/:goalId/target/:kpiId': ['admin', 'user', 'hr'],
  '/performance/1-on-1': ['admin', 'user', 'hr'],
  '/performance/1-on-1/:id': ['admin', 'user', 'hr'],
  '/performance/approvals': ['admin', 'user', 'hr'],
  '/performance/approvals/target/:kpiId': ['admin', 'user', 'hr'],

  '/company-settings': ['admin', 'hr', 'security_admin'],
  '/company-settings/organization': ['admin'],
  '/company-settings/organization/details': ['admin'],
  '/company-settings/organization/permissions': ['admin'],
  '/company-settings/organization/companies': ['admin'],
  '/company-settings/organization/company/:id': ['admin'],
  '/company-settings/organization/company/:id/details': ['admin'],
  '/company-settings/organization/company/:id/locations': ['admin'],
  '/company-settings/organization/company/:id/banks': ['admin'],

  '/company-settings/team': ['admin', 'hr'],
  '/company-settings/team/profile-fields': ['admin', 'hr'],
  '/company-settings/team/benefits': ['admin', 'hr'],
  '/company-settings/team/benefits/:type': ['admin', 'hr'],
  '/company-settings/team/posts': ['admin', 'hr'],
  '/company-settings/team/posts/announcements': ['admin', 'hr'],
  '/company-settings/team/posts/events': ['admin', 'hr'],
  '/company-settings/team/automation': ['admin', 'hr'],
  '/company-settings/team/performance': ['admin', 'hr'],
  '/company-settings/team/performance/goals': ['admin', 'hr'],
  '/company-settings/team/performance/permissions': ['admin', 'hr'],
  '/company-settings/team/performance/one-on-one': ['admin', 'hr'],
  '/company-settings/team/performance/reviews': ['admin', 'hr'],
  '/company-settings/team/public-profile': ['admin', 'hr'],
  '/company-settings/team/employee-privacy': ['admin', 'hr'],

  '/company-settings/time': ['admin', 'hr'],
  '/company-settings/time/policies': ['admin', 'hr'],
  '/company-settings/time/policies/vacation': ['admin', 'hr'],
  '/company-settings/time/policies/vacation/:policyId': ['admin', 'hr'],
  '/company-settings/time/policies/special-leave': ['admin', 'hr'],
  '/company-settings/time/policies/special-leave/policy': ['admin', 'hr'],
  '/company-settings/time/policies/special-leave-details/:policyId': ['admin', 'hr'],
  '/company-settings/time/policies/special-leave/types': ['admin', 'hr'],
  '/company-settings/time/policies/sick-leave': ['admin', 'hr'],
  '/company-settings/time/policies/sick-leave/:policyId': ['admin', 'hr'],
  '/company-settings/time/policies/unpaid-leave': ['admin', 'hr'],
  '/company-settings/time/policies/unpaid-leave/:policyId': ['admin', 'hr'],
  '/company-settings/time/policies/holidays': ['admin', 'hr'],
  '/company-settings/time/policies/remote-work': ['admin', 'hr'],
  '/company-settings/time/policies/remote-work/:policyId': ['admin', 'hr'],
  '/company-settings/time/policies/holidays/:policyId': ['admin', 'hr'],
  '/company-settings/time/work-schedules': ['admin', 'hr'],
  '/company-settings/time/work-schedules/:wsId': ['admin', 'hr'],
  '/company-settings/time/ramadan': ['admin', 'hr'],
  '/company-settings/time/ramadan/:year': ['admin', 'hr'],

  '/company-settings/time/timesheets': ['admin'],
  '/company-settings/time/timesheets/approval': ['admin'],
  '/company-settings/time/timesheets/grace-periods': ['admin'],
  '/company-settings/time/time-tracking': ['admin'],
  '/company-settings/time/time-tracking/app-checkin': ['admin'],
  '/company-settings/time/time-tracking/biometrics': ['admin'],
  '/company-settings/time/time-tracking/biometrics/zkteco': ['admin'],
  '/company-settings/time/time-tracking/biometrics/cams': ['admin'],
  '/company-settings/time/time-tracking/import': ['admin'],
  '/company-settings/time/time-tracking/settings': ['admin'],
  '/company-settings/time/shifts': ['admin'],
  '/company-settings/time/billable-hours': ['admin', 'hr'],
  '/company-settings/time/billable-hours/projects': ['admin', 'hr'],
  '/company-settings/time/billable-hours/projects/:id': ['admin', 'hr'],
  '/company-settings/time/billable-hours/preferences': ['admin', 'hr'],
  '/company-settings/time/shifts/:category': ['admin'],

  '/company-settings/pay': ['admin', 'hr'],
  '/company-settings/pay/rounding': ['user'],
  '/company-settings/pay/bank-details': ['admin', 'hr'],
  '/company-settings/pay/salary-basis': ['admin', 'hr'],
  '/company-settings/pay/package-components': ['admin', 'hr'],
  '/company-settings/pay/package-components/items': ['admin', 'hr'],
  '/company-settings/pay/package-components/additions': ['admin', 'hr'],
  '/company-settings/pay/package-components/deductions': ['admin', 'hr'],
  '/company-settings/pay/package-components/country-package': ['admin', 'hr'],
  '/company-settings/pay/approval-flow': ['admin'],
  '/company-settings/pay/approval-flow/legal-entity/:legalEntityId': ['admin'],
  '/company-settings/pay/approval-flow/legal-entity/:legalEntityId/approval-flow-id/:approvalFlowId': ['admin'],
  '/company-settings/pay/overtime': ['admin'],
  '/company-settings/pay/overtime/:id': ['admin'],
  '/company-settings/pay/undertime': ['admin'],
  '/company-settings/pay/undertime/:id': ['admin'],

  ...eosRoutes,

  '/company-settings/pay/taxes-and-contributions': ['admin'],
  '/company-settings/pay/loan': ['admin'],
  '/company-settings/pay/preferences': ['admin'],

  '/company-settings/documents': ['admin', 'hr'],
  '/company-settings/documents/templates': ['admin', 'hr'],
  '/company-settings/documents/employee-documents': ['admin', 'hr'],
  '/company-settings/documents/employee-documents/:folderId': ['admin', 'hr'],
  '/company-settings/documents/configuration': ['admin', 'hr'],

  '/company-settings/integrations': ['admin', 'hr'],
  '/company-settings/integrations/gosi': ['admin', 'hr'],
  '/company-settings/integrations/gosi/details': ['admin', 'hr'],
  '/company-settings/integrations/gosi/companies': ['admin', 'hr'],
  '/company-settings/integrations/gosi/companies/:legalEntityId': ['admin', 'hr'],
  '/company-settings/integrations/mudad': ['admin', 'hr'],
  '/company-settings/integrations/mudad/details': ['admin', 'hr'],
  '/company-settings/integrations/mudad/companies': ['admin', 'hr'],
  '/company-settings/integrations/mudad/transactions': ['admin', 'hr'],
  '/company-settings/integrations/zapier': ['admin', 'hr'],
  '/company-settings/integrations/accounting': ['admin', 'hr'],
  '/company-settings/integrations/accounting/:accountingId': ['admin', 'hr'],
  '/company-settings/requests': ['admin'],
  '/company-settings/permissions': ['admin', 'hr'],
  '/company-settings/billing': ['admin'],
  '/company-settings/security': ['admin', 'security_admin'],
  '/company-settings/recruitment': ['admin', 'hr'],
  '/company-settings/requests/:group/:request': ['admin'],
  '/company-settings/requests/:group/:request/locations': ['admin'],
  '/company-settings/requests/:group/:request/types': ['admin'],
  '/company-settings/requests/:group/:request/flows': ['admin'],
  '/company-settings/requests/:group/:request/flows/:id': ['admin'],
  '/company-settings/tasks': ['admin'],
  '/company-settings/tasks/priority': ['admin', 'hr'],
  '/company-settings/tasks/status': ['admin', 'hr'],
  '/company-settings/tasks/configuration': ['admin', 'hr'],
  '/company-settings/assets': ['admin'],
  '/company-settings/assets/types': ['admin', 'hr'],
  '/company-settings/assets/status': ['admin', 'hr'],
  '/company-settings/assets/configurator': ['admin', 'hr'],
  '/company-settings/recruitment/applicationForm': ['admin', 'hr'],
  '/company-settings/recruitment/applicationForm/:id': ['admin', 'hr'],
  '/company-settings/recruitment/templates': ['admin', 'hr'],
  '/company-settings/recruitment/hiringProcess': ['admin', 'hr'],
  [PrivateRoutes.recruitment]: ['admin', 'hr', 'user'],
  [PrivateRoutes.recruitmentJobs]: ['admin', 'hr', 'user'],
  [PrivateRoutes.recruitmentCandidates]: ['admin', 'hr'],
  [PrivateRoutes.jobDetails]: ['admin', 'hr', 'user'],
  [PrivateRoutes.jobDetailsCandidates]: ['admin', 'hr', 'user'],
  [PrivateRoutes.jobDetailsStatistics]: ['admin', 'hr', 'user'],
  logout: ['admin', 'user'],
};

export const checkPrivileges = (action, userRoles, globalRoles) =>
  reduce(
    userRoles,
    (acc, val) => {
      let account = acc;
      const userRole = val.slice(5).toLowerCase();
      if (includes(globalRoles[action], userRole)) account = true;
      return account;
    },
    false
  );
