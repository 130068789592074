/* global google */
import React, { Fragment, useEffect, useRef, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import iconCheckOutRed from 'assets/img/icons/pin-end-red.png';
import iconLocationOrange from 'assets/img/icons/pin-orange.png';
import iconCheckInGreen from 'assets/img/icons/pin-start-green.png';
import iconLocation from 'assets/img/icons/pin.png';
import Circle from 'components/map/Circle';
import Marker from 'components/map/Marker';
import useLanguage from 'shared/useLanguage';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

const optionsSearcsh = {
  fields: ['*'],
  strictBounds: false,
  types: ['establishment', 'business', '(regions)', '(cities)'],
};

const MyMapComponent = ({
  useDeepCompareEffectForMaps,
  clicks,
  radius,
  listOfMarkers,
  readMode,
  onChange,
  onClick,
  onIdle,
  msgInfo,
  additionalLocations,
  mainLocation,
  fitBounds,
  zoomToBounds,
  ...options
}) => {
  const language = useLanguage();
  const ref = useRef(null);
  const refSearch = useRef(null);
  const [map, setMap] = useState();
  const [autocomplete, setAutocomplete] = useState();
  const [placesService, setPlacesService] = useState();
  const [showLocationError, setShowLocationError] = useState(false);
  const officeLatitude = mainLocation?.geofencing?.latitude * 1;
  const officeLongitude = mainLocation?.geofencing?.longitude * 1;

  const zoomToBoundsSet = typeof zoomToBounds?.latitude === 'number' && typeof zoomToBounds?.longitude === 'number';

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useEffect(() => {
    if (map && !placesService) {
      setPlacesService(new google.maps.places.PlacesService(map));
    }
  }, [map]);

  // Center and zoom map based on pins locations to capture all
  useEffect(() => {
    if (zoomToBoundsSet) {
      return;
    }

    if (fitBounds && map) {
      const circle = new google.maps.Circle({
        center: { lat: officeLatitude, lng: officeLongitude },
        radius: mainLocation?.geofencing?.radius,
      });
      const bounds = circle.getBounds();

      listOfMarkers.forEach(({ latitude, longitude }) => {
        if (typeof latitude === 'number' && typeof longitude === 'number') {
          bounds.extend(new google.maps.LatLng({ lat: latitude, lng: longitude }));
        }
      });

      additionalLocations.forEach(({ geofencing: { latitude, longitude } = {} }) => {
        if (latitude && longitude) {
          bounds.extend(new google.maps.LatLng({ lat: latitude * 1, lng: longitude * 1 }));
        }
      });

      bounds.extend(new google.maps.LatLng({ lat: officeLatitude, lng: officeLongitude }));

      map.fitBounds(bounds);
    }
  }, [additionalLocations, listOfMarkers, mainLocation, map]);

  useEffect(() => {
    if (map && zoomToBoundsSet) {
      const circle = new google.maps.Circle({
        center: { lat: zoomToBounds.latitude, lng: zoomToBounds.longitude },
        radius: 100,
      });

      map.fitBounds(circle.getBounds());
    }
  }, [zoomToBounds, map]);

  useEffect(() => {
    if (refSearch.current && !autocomplete && !readMode) {
      setAutocomplete(new google.maps.places.SearchBox(refSearch.current, optionsSearcsh));
    }
  }, [refSearch, autocomplete]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
    if (autocomplete && !readMode) {
      autocomplete.setOptions(optionsSearcsh);
    }
  }, [map, options]);

  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) => google.maps.event.clearListeners(map, eventName));
      if (onClick) {
        map.addListener('click', onClick);
      }
      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  useEffect(() => {
    if (autocomplete && !readMode) {
      ['places_changed'].forEach((eventName) => google.maps.event.clearListeners(autocomplete, eventName));

      autocomplete.addListener('places_changed', () => {
        const places = autocomplete.getPlaces();
        if (!places || places.length === 0) {
          console.warn('No places found');
          setShowLocationError(false);
          if (refSearch.current) {
            refSearch.current.value = '';
          }
          return;
        }

        const place = places[0];

        // Get fresh place details to avoid stale Place IDs
        if (placesService && place.place_id) {
          placesService.getDetails(
            {
              placeId: place.place_id,
              fields: ['geometry', 'formatted_address', 'name'],
            },
            (freshPlace, status) => {
              if (status === google.maps.places.PlacesServiceStatus.OK && freshPlace.geometry?.location) {
                onChange(freshPlace.geometry.location.lat(), freshPlace.geometry.location.lng());
                setShowLocationError(false);

                if (refSearch.current) {
                  refSearch.current.value = freshPlace.formatted_address || freshPlace.name || '';
                }
              } else {
                console.warn('Could not get fresh place details:', status);
                setShowLocationError(true);
                // Add a small delay to ensure the input is cleared after Google's autocomplete
                setTimeout(() => {
                  if (refSearch.current) {
                    refSearch.current.value = '';
                  }
                }, 100);
              }
            }
          );
        } else {
          console.warn('Places service not available');
          setShowLocationError(true);
          if (refSearch.current) {
            refSearch.current.value = '';
          }
        }
      });
    }
  }, [autocomplete, onChange, placesService]);

  const time = (val) => moment(val).utc().set({ year: moment().year(), month: moment().month() }).toString();

  const renderIcon = (marker) => {
    switch (marker.recordType) {
      case 'in':
        return iconCheckInGreen;
      case 'out':
        return iconCheckOutRed;
      default:
        return iconLocationOrange;
    }
  };

  const renderMsg = (marker) => {
    let msg = '';
    const isValid = marker.validLocation;
    const name = readName(language, marker?.companyLocation?.name, marker?.companyLocation?.nameArabic);
    if (marker.recordMoment) {
      msg = `<div style= "color: ${isValid ? '' : 'red'}">
      ${marker.recordType === 'in' ? t('GENERAL.CHECK_IN') : t('GENERAL.CHECK_OUT')}
      ${moment(time(marker.recordMoment)).format('HH:mm')}
    </div>
    <div>
      ${!isValid ? t('GENERAL.LOCATION') : ''}${!isValid ? ':' : ''}${!isValid ? name : ''}
    </div>
    <div>
      ${t('GENERAL.ACCURACY')}: ${marker.accuracy}m
    </div>`;
    } else {
      msg = t('GENERAL.CURRENT_LOCATION');
    }
    return msg;
  };

  return (
    <>
      {!readMode && (
        <>
          <input
            ref={refSearch}
            className='form-control'
            type='text'
            placeholder={t('GENERAL.SEARCH')}
            onKeyUp={(e) => {
              setShowLocationError(false);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
          />
          {showLocationError && (
            <small className='error'>{t('GENERAL.SELECTED_LOCATION_DOESNOT_HAVE_COORDINATES')}</small>
          )}
        </>
      )}
      <div style={{ width: '100%', height: readMode ? '100%' : 'calc(100% - 56px)', marginTop: '16px' }} ref={ref} />

      <Marker
        icon={iconLocation}
        map={map}
        position={{ lat: officeLatitude, lng: officeLongitude }}
        msgInfo={msgInfo || t('SYSTEM.OBJECTS.COMPANY_LOCATION')}
      />
      <Circle map={map} radius={radius} center={{ lat: officeLatitude, lng: officeLongitude }} />
      {!_.isEmpty(additionalLocations) &&
        additionalLocations.map(
          (location) =>
            location.geofencing.latitude &&
            location.geofencing.longitude && (
              <Fragment key={location.id}>
                <Marker
                  key={location.id || location.geofencing.latitude}
                  map={map}
                  icon={iconLocation}
                  position={{ lat: location.geofencing.latitude * 1, lng: location.geofencing.longitude * 1 }}
                  msgInfo={location.name}
                />
                <Circle
                  map={map}
                  radius={location.geofencing.radius * 1}
                  center={{ lat: location.geofencing.latitude * 1, lng: location.geofencing.longitude * 1 }}
                />
              </Fragment>
            )
        )}
      {listOfMarkers.map(
        (marker) =>
          marker.latitude &&
          marker.longitude && (
            <Marker
              key={marker.id || marker.latitude}
              map={map}
              icon={renderIcon(marker)}
              position={{ lat: marker.latitude, lng: marker.longitude }}
              msgInfo={renderMsg(marker)}
              showInfoWindow={
                zoomToBoundsSet &&
                marker.latitude === zoomToBounds?.latitude &&
                marker.longitude === zoomToBounds?.longitude
              }
            />
          )
      )}
    </>
  );
};

MyMapComponent.propTypes = {
  useDeepCompareEffectForMaps: PropTypes.func,
  clicks: PropTypes.object,
  radius: PropTypes.number,
  onUpdateLatLng: PropTypes.func,
  listOfMarkers: PropTypes.array,
  readMode: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onIdle: PropTypes.func,
  additionalLocations: PropTypes.arrayOf(PropTypes.object),
};

MyMapComponent.defaultProps = {
  useDeepCompareEffectForMaps: undefined,
  clicks: null,
  radius: 10,
  onUpdateLatLng: undefined,
  listOfMarkers: [],
  readMode: false,
  onChange: undefined,
  onClick: undefined,
  onIdle: undefined,
  additionalLocations: [],
};

export default React.memo(MyMapComponent);
