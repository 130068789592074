import React from 'react';

import cx from 'classnames';

import TableSkeleton from 'components/skeletons/TableSkeleton';
import t from 'utility/setTranslation';

import styles from './BillingHistory.module.scss';
import InvoiceHistoryTable from './InvoiceHistoryTable';
import { useDownloadSubscriptionInvoice, UseListSubscriptionInvoices } from '../../hooks/useCompanySettings';
import BillingSubsectionContainer from '../components/BillingSubsectionContainer/BillingSubsectionContainer';

const BillingHistory = () => {
  const { data: invoices, isLoading: isInvoicesHistoryLoading } = UseListSubscriptionInvoices();
  const { mutate: downloadInvoice } = useDownloadSubscriptionInvoice();

  const invoicesHistory = invoices?.data?.items || [];

  if (isInvoicesHistoryLoading) {
    return (
      <div className={cx(styles.content, 'mt-6 p-4')}>
        <h6 className='fw-bold mb-3a'>{t('SUBSCRIPTION.BILLING_HISTORY')}</h6>
        <TableSkeleton num={4} lineHight={40} mb='mb-2' />
      </div>
    );
  }

  const handleInvoiceDownload = (invoice, action) => {
    downloadInvoice(
      { invoice, action },
      {
        onSuccess: (response, { action: actionType }) => {
          if (actionType === 'preview') {
            window.open(response.data.resourceUrl);
          } else {
            const link = document.createElement('a');
            link.href = response.data.resourceUrl;
            link.setAttribute('download', 'invoice.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        },
      }
    );
  };

  return (
    <BillingSubsectionContainer title={t('SUBSCRIPTION.BILLING_HISTORY')} containerClassName={''}>
      {invoicesHistory.length > 0 ? (
        <InvoiceHistoryTable invoices={invoicesHistory} onDownload={handleInvoiceDownload} />
      ) : (
        <div className={styles.empty}>{t('SUBSCRIPTION.EMPTY_HISTORY')}</div>
      )}
    </BillingSubsectionContainer>
  );
};

export default BillingHistory;
