/* eslint-disable */

import React from 'react';
import utility from './utility';

const ToolBar = ({ id, children, justifyContent, divider, className, style, fullWidth, onClick }) => {
  return (
    <div
      id={id} style={style}
      className={utility.containerClasses(justifyContent, divider, className, fullWidth)}
      onClick={utility.onContainerClick(onClick)}
    >
      {children}
    </div>
  );
};

ToolBar.propTypes = utility.propTypes();
ToolBar.defaultProps = utility.defaultProps();

export default ToolBar;
