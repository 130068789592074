import step1ImageEN from 'assets/img/getting-started/team/step_1_team_en.png';
import step1ImageAR from 'assets/img/getting-started/team/step_1_team_ar.png';
import step2ImageEN from 'assets/img/getting-started/team/step_2_team_en.png';
import step2ImageAR from 'assets/img/getting-started/team/step_2_team_ar.png';

export const generateWizardImagesSteps = [
  {
    id: 1,
    imageEN: step1ImageEN,
    imageAR: step1ImageAR,
  },
  {
    id: 2,
    imageEN: step2ImageEN,
    imageAR: step2ImageAR,
  },
];
