import React from 'react';

import PropTypes from 'prop-types';

import CheckBox from 'components/forms/components/CheckBox';

import styles from './SearchFilterMenu.module.scss';

const FilterMenuGroup = React.memo(({ group, onCheckboxClick }) => {
  const onChangeCheckbox = (val, name, field, label) => {
    onCheckboxClick(val, name, field, group.title, label);
  };

  const addressDuplicateLabels = (opt) => {
    const occurrence = {};

    opt.forEach((o) => {
      if (!occurrence[o.label]) {
        occurrence[o.label] = 1;
        return;
      }

      const newOcc = occurrence[o.label] + 1;
      occurrence[o.label] = newOcc;

      o.label = `${o.label} (${newOcc})`;
    });

    return opt;
  };

  return (
    <div className={styles.menuDiv}>
      <h5>{group.title}</h5>

      <ul className='no-style'>
        {addressDuplicateLabels(group.options).map((option) => (
          <li key={`${option.label}-${option.id}`} className={styles.listItem}>
            <CheckBox
              id={`${option.field}-${option.label}-${option.id}`}
              name={option.field}
              value={option.id}
              label={option.label}
              checked={option.selected}
              onChange={(val, name, field) => {
                onChangeCheckbox(val, name, field, option.label);
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
});

FilterMenuGroup.propTypes = {
  group: PropTypes.object.isRequired,
  onCheckboxClick: PropTypes.func.isRequired,
};

export default FilterMenuGroup;
