import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import styles from './Day.module.scss';

const Day = ({ date, startDate, endDate, onSelectDay, activeDay, data }) => {
  let className = 'currentMonth';
  let canClick = true;
  if (startDate && endDate) {
    if (!moment(date).isBetween(moment(startDate), moment(endDate), 'day', '[]')) {
      canClick = false;
      className = 'notCurrentMonth';
    }
  }
  const selectDay = () => {
    if (canClick) {
      onSelectDay(date);
    }
  };
  return (
    <div className={cx('d-flex justify-content-center align-items-center', styles.numberContainer)}>
      <div className='d-flex flex-column'>
        <div
          className={cx(
            'd-flex justify-content-center align-items-center',
            styles.number,
            styles[className],
            moment(activeDay).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD') && styles.active,
            canClick ? 'cursor-pointer' : 'cursor-default'
          )}
          onClick={selectDay}
        >
          <div>{moment(date).format('DD')}</div>
        </div>
        <div style={{ height: '16px' }} className='d-flex justify-content-center align-items-center'>
          {!_.isEmpty(data) &&
            canClick &&
            Object.keys(data).map(
              (key) =>
                data[key].includes(moment(date).format('MM-DD')) && (
                  <i key={key} className={cx(styles.bullet, styles[key], 'icon-bullet-point')} />
                )
            )}
        </div>
      </div>
    </div>
  );
};
Day.propTypes = {
  onSelectDay: PropTypes.func,
  activeDay: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.object]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  data: PropTypes.object,
};

Day.defaultProps = {
  onSelectDay: undefined,
  activeDay: '',
  startDate: '',
  endDate: '',
  date: '',
  data: null,
};
export default Day;
