import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import { AuthContext } from 'auth/AuthContext';
import Tooltip from 'components/tooltip/Tooltip';
import formatKey from 'utility/formatKey';
import formatOptions from 'utility/formatOptions';
import t from 'utility/setTranslation';

import FieldWrapper from './FieldWrapper';
import classes from './SelectField.module.scss';

const SelectField = ({
  refSelect,
  isCustom,
  customClass,
  name,
  label,
  options,
  autoFocus,
  size,
  isDisabled,
  isRequired,
  styles,
  onChange,
  onBlur,
  onAddNew,
  showAddBtn,
  value,
  isFormik = true,
  onInputChange,
  error,
  hideClear,
  scrollMore,
  placeholder,
  isGroupedOption,
  hideGroupedIcon,
  customOptionField,
  useTranslate,
  showError,
  optionUppercase,
  tooltip,
  menuPlacement,
  haveCustomBtn,
  renderedBtn,
}) => {
  const { authState } = useContext(AuthContext);
  const [isShown, setIsShown] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const lang = authState?.user?.language || 'en';

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDisabled ? '#F4F6F7' : 'white',
      minHeight: size === 'sm' ? '32px' : '40px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#a7b5bf',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? '#A7B5BF' : '#043344',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      visibility: 'hidden',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#1A938A' : 'white',
      color: state.isSelected ? 'white' : '#043344',
      opacity: state.isDisabled ? 0.5 : 1,
      '&:hover': {
        backgroundColor: state.isSelected ? '#1A938A' : '#e9edef',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: size === 'sm' ? '0 8px' : '2px 8px',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const formatOptionsList = (result) => {
    const newArray = [];
    result.forEach((item) => {
      item.options.forEach((optionItem) => {
        newArray.push(optionItem);
      });
    });
    return newArray;
  };

  useEffect(() => {
    const result = isGroupedOption ? options : formatOptions(options, lang, customOptionField, useTranslate);
    setSelectOptions(result);
  }, [JSON.stringify(options)]);

  const changeHandler = (val) => {
    const fieldValue = val || { value: '', label: '' };

    if (isFormik) {
      if (onChange) {
        onChange(name)(fieldValue.value);
      }
    } else {
      onChange(name, fieldValue);
    }
  };

  const clearSerachHadler = () => {
    if (onInputChange) {
      /* This is added to remove text from searchValue on select */
      onInputChange('');
    }
  };

  /* Styling clear and arrow icon in select */
  const controlStyles = {
    cursor: 'pointer',
    color: '#bec9d0',
    padding: size === 'sm' ? '4px 8px' : '8px',
  };

  const DropdownIndicator = (props) => {
    const {
      children = <i className='icon-arrow-dropdown' />,
      innerProps: { ref, ...restInnerProps },
    } = props;

    return (
      <div {...restInnerProps} ref={ref} style={controlStyles}>
        <div className='text-gray p-0 d-flex align-items-center'>{children}</div>
      </div>
    );
  };

  const ClearIndicator = (props) => {
    const {
      children = <i className='icon-cross-x' />,
      innerProps: { ref, ...restInnerProps },
    } = props;

    return (
      <div {...restInnerProps} ref={ref} style={controlStyles}>
        <div className='p-0 d-flex align-items-center'>{children}</div>
      </div>
    );
  };

  const CustomMenuList = (props) => (
    <components.MenuList {...props}>
      {props.children}
      {haveCustomBtn && renderedBtn()}
    </components.MenuList>
  );

  // Grouped Styling
  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '11px',
    fontWeight: 'bold',
    color: '#93A5B1',
    padding: '12px 0',
    margin: 0,
  };

  const groupBadgeStyles = {
    borderRadius: '100px',
    width: '16px',
    height: '16px',
  };

  const onBlurField = () => {
    if (onBlur) {
      onBlur(name);
    }
  };

  const onSearch = (v, e) => {
    // set search value only on input change, to prevent options api request on select value,
    // 'cause select value clears search
    if (onInputChange && e.action === 'input-change') {
      onInputChange(v);
    }
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      {!hideGroupedIcon && data.img && (
        <img src={data.img} style={groupBadgeStyles} className='me-2' alt='group icon' />
      )}
      {!hideGroupedIcon && data.icon && <i style={groupBadgeStyles} className={cx('me-2 font-size-16', data.icon)} />}

      <span>{data.label}</span>
    </div>
  );
  const selectedOptionsList = isGroupedOption ? formatOptionsList(selectOptions) : selectOptions;

  return (
    <FieldWrapper
      data-testid={name}
      name={name}
      className={cx(classes.inputWrapper, customClass, error ? 'is-invalid' : '')}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <div
        className={cx(
          'd-flex',
          { [classes.labelContainer]: tooltip },
          'align-items-center',
          !label ? 'justify-content-end' : 'justify-content-between'
        )}
      >
        {label ? (
          <>
            <label className='label pb-1' htmlFor={name}>
              {isCustom ? label : formatKey('GENERAL', label)}
              {isRequired && <sup>*</sup>}
            </label>
            {tooltip && (
              <Tooltip label={tooltip.text} size='md' position={tooltip.position || 'topRight'} className='text-start'>
                <i className={cx(tooltip.icon, classes.tooltip)} />
              </Tooltip>
            )}
          </>
        ) : null}

        {showAddBtn ? <i className={cx(classes.addNewButton, 'icon-circle-plus1')} onClick={onAddNew} /> : null}
      </div>
      <>
        <Select
          ref={refSelect}
          components={{ ClearIndicator, DropdownIndicator, MenuList: CustomMenuList }}
          styles={{
            ...selectStyles,
            ...styles,
          }}
          name={name}
          inputId={name}
          className={`styled-select ${optionUppercase ? 'ucfirst' : ''}`}
          classNamePrefix='react-select'
          placeholder={placeholder ? t(placeholder) : t('GENERAL.SELECT')}
          options={selectOptions}
          value={_.find(selectedOptionsList, ['value', value]) || null}
          formatGroupLabel={formatGroupLabel}
          isDisabled={isDisabled}
          isClearable={isShown && !hideClear}
          backspaceRemoves
          maxMenuHeight={250}
          minMenuHeight={56}
          menuPortalTarget={document.body}
          menuShouldScrollIntoView={false}
          menuPosition='fixed'
          menuShouldBlockScroll
          deleteRemoves
          autoFocus={autoFocus}
          onChange={(val) => {
            changeHandler(val);
          }}
          menuPlacement={menuPlacement}
          onBlur={onBlurField}
          onInputChange={onSearch}
          onMenuScrollToBottom={scrollMore}
          onMenuOpen={clearSerachHadler}
          isOptionDisabled={(option) => option.isDisabled}
        />
      </>
      {showError && <div className='error'>{error}</div>}
    </FieldWrapper>
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onAddNew: PropTypes.func,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  customClass: PropTypes.string,
  styles: PropTypes.object,
  showAddBtn: PropTypes.bool,
  isFormik: PropTypes.bool,
  hideClear: PropTypes.bool,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string,
  isGroupedOption: PropTypes.bool,
  hideGroupedIcon: PropTypes.bool,
  useTranslate: PropTypes.bool,
  autoFocus: PropTypes.bool,
  showError: PropTypes.bool,
  size: PropTypes.string,
  error: PropTypes.string,
  scrollMore: PropTypes.func,
  customOptionField: PropTypes.string,
  isCustom: PropTypes.bool,
  tooltip: PropTypes.object,
  optionUppercase: PropTypes.bool,
  haveCustomBtn: PropTypes.bool,
};

SelectField.defaultProps = {
  value: '',
  label: '',
  onChange: undefined,
  onBlur: undefined,
  isDisabled: false,
  isRequired: false,
  customClass: '',
  styles: undefined,
  options: [],
  onAddNew: undefined,
  showAddBtn: false,
  isFormik: true,
  onInputChange: undefined,
  hideClear: false,
  placeholder: '',
  isGroupedOption: false,
  autoFocus: false,
  hideGroupedIcon: false,
  useTranslate: false,
  showError: true,
  size: '',
  error: '',
  scrollMore: undefined,
  customOptionField: '',
  isCustom: false,
  tooltip: undefined,
  optionUppercase: false,
  haveCustomBtn: false,
};

export default SelectField;
