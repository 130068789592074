import React from 'react';

import cx from 'classnames';

import styles from './WizardFooter.module.scss';
import t from '../../utility/setTranslation';
import RoundedButton from '../buttons/RoundedButton/RoundedButton';

const WizardFooter = ({
  isFirstStep,
  isLastStep,
  handlePrev,
  stepsState,
  handleNext,
  nextButtonText,
  currentStepIndex,
  className,
  disableNext,
}) => (
  <section className={cx('d-flex justify-content-between border-t py-3 px-4 w-full', className, styles.footer)}>
    <section className='d-flex justify-content-between align-items-center gap-2'>
      {stepsState.map((step, index) => {
        const stepNumber = index + 1;
        return (
          <div key={step.id} className='d-flex justify-content-between align-items-center gap-2'>
            <span
              className={cx(
                styles.stepNumber,
                { 'bg-primary': step.isValid || index <= currentStepIndex },
                { 'bg-gray': !step.isValid && index > currentStepIndex },
                'd-flex align-items-center justify-content-center color-white font-size-11'
              )}
            >
              {stepNumber}
            </span>
            {stepNumber === stepsState.length ? null : step?.isValid ? (
              <div className={styles.lineSeparator} />
            ) : (
              <i
                className={cx(
                  { 'icon-minus text-primary font-size-20': step.isValid || index < currentStepIndex },
                  { 'icon-arrow-right text-gray-500 font-size-20': !step.isValid && index >= currentStepIndex }
                )}
              />
            )}
          </div>
        );
      })}
    </section>

    <section className='d-flex gap-2'>
      {!isFirstStep && <RoundedButton type='button' onClick={handlePrev} btnStyle='outlined' text={t('go back')} />}
      <RoundedButton
        icon={isLastStep ? '' : 'icon-arrow-right'}
        color='primary'
        iconPosition='right'
        onClick={handleNext}
        type='button'
        btnStyle='contained'
        text={nextButtonText}
        disabled={disableNext}
      />
    </section>
  </section>
);

export default WizardFooter;
