import React from 'react';

import SpinnerRing from 'components/Spinner/SpinnerRing';
import SuccessCheckmark from 'components/SuccessCheckmark/SuccessCheckmark';

const ActionLoading = ({ responseStatus, isLoading, positionFixed, loadingClassName }) => (
  <>
    {responseStatus?.resolved && (
      <SuccessCheckmark text={responseStatus.msg} isFailed={responseStatus.isFailed} hideCloseButton />
    )}
    {isLoading && <SpinnerRing positionFixed={positionFixed} className={loadingClassName} />}
  </>
);

export default ActionLoading;
