import React from 'react';

import cx from 'classnames';
import copy from 'copy-to-clipboard';

import palmLogo from 'assets/img/palm-ai-icon.svg';
import IconButton from 'components/buttons/IconButton/IconButton';
import WritingAnimation from 'components/WritingAnimation/WritingAnimation';

import styles from './PalmHrAIMessage.module.scss';

const PalmHrAIMessage = ({ message, className, showRegenerate }) => {
  const copyMessage = () => copy(message);

  return (
    <div className={cx(className, 'd-flex mt-3 bg-dark-blue py-4 px-5 radius-8')}>
      <img className={cx(styles.PalmHrAIMessage__logo, 'me-2')} alt='palm logo' src={palmLogo} />
      <WritingAnimation
        onWritingEnd={showRegenerate}
        className={cx(styles.PalmHrAIMessage__message, 'text-gray-300 mx-3 font-size-13 multiline')}
        text={message}
        speed={15}
      />
      <IconButton icon='icon-copy' className='text-gray-300' onClick={copyMessage} />
    </div>
  );
};

export default PalmHrAIMessage;
