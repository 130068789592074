import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Menu from 'components/menu/Menu';
import createSelectOptions from 'components/menu/utility/createSelectOptions';
import Tooltip from 'components/tooltip/Tooltip';
import t from 'utility/setTranslation';

import styles from './CardAdvancedItem.module.scss';
import Company from './Company';

const CompanyItem = ({ data, icon, event, onAction, hasPermission }) => (
  <div className='w-100 d-flex justify-content-between align-items-center'>
    <div className='w-75 pr-1'>
      <Company company={data} size={40} customClass='my-2' />
    </div>
    <div className='w-25 d-flex justify-content-end'>
      {event ? (
        <div className='position-relative'>
          {hasPermission && (
            <Menu
              data={createSelectOptions(['Edit', 'Delete'])}
              onAction={(a) => {
                onAction(a, data);
              }}
              style={{ width: '150px' }}
            />
          )}
        </div>
      ) : (
        <div className='d-flex'>
          <Tooltip position='left' label={t('GENERAL.CUSTOM_EVENT')}>
            <i className={cx(styles.contentIcon, styles.color, icon)} />
          </Tooltip>
        </div>
      )}
    </div>
  </div>
);

CompanyItem.propTypes = {
  data: PropTypes.object,
  icon: PropTypes.string,
  event: PropTypes.bool,
  hasPermission: PropTypes.bool,
};

CompanyItem.defaultProps = {
  data: null,
  icon: '',
  event: false,
  hasPermission: false,
};

export default React.memo(CompanyItem);
