/* eslint-disable react/no-danger */
import React, { useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';

import ShowMore from 'modules/Employee/Onboarding/ShowMore';

import styles from './ShowMoreAnnouncement.module.scss';

const ShowMoreAnnouncement = ({ text, showMoreButton }) => {
  const [showMore, setShowMore] = useState(false);

  const showMoreHandler = (e) => {
    e.stopPropagation();
    setShowMore(!showMore);
  };

  return (
    <>
      {showMoreButton ? (
        <div className='position-relative overflow-hidden'>
          <div
            className={cx(showMore ? styles.showMore : styles.basic, styles.openTextDiv)}
            dangerouslySetInnerHTML={{ __html: text }}
          />
          {text.length > 240 && (
            <div>
              <ShowMore showMoreHandler={showMoreHandler} showMoreBtn={showMore} />
            </div>
          )}
        </div>
      ) : (
        <Scrollbars
          className='scrollbarsWrapper'
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          hideTracksWhenNotNeeded
          renderView={({ style, ...props }) => <div {...props} style={{ ...style, overflowX: 'hidden' }} />}
        >
          <div className='position-relative overflow-hidden'>
            <div
              className={styles.openTextDiv}
              style={{ marginBottom: '1rem' }}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </div>
        </Scrollbars>
      )}
    </>
  );
};

ShowMoreAnnouncement.propTypes = {
  text: PropTypes.string.isRequired,
  showMoreButton: PropTypes.bool,
};

ShowMoreAnnouncement.defaultProps = {
  showMoreButton: false,
};

export default ShowMoreAnnouncement;
