import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import IconButton from 'components/buttons/IconButton/IconButton';
import RoundedButton from 'components/buttons/RoundedButton/RoundedButton';
import Tooltip from 'components/tooltip/Tooltip';
import t from 'utility/setTranslation';

import styles from './SearchFilterMenu.module.scss';

const FilterTabsGroup = React.memo(({ id, selectedFilterTabs, onRemoveItemFromTabs, filterTabsRef, disabled }) => (
  <div id={id} ref={filterTabsRef} className='d-flex align-items-start justify-content-between'>
    <div className='d-flex flex-wrap align-items-center justify-content-start'>
      {selectedFilterTabs.map((el) => (
        <div
          key={`${el.label}-${el.id}`}
          className={cx(styles.tab, 'd-flex align-items-start justify-content-start me-3')}
        >
          <div className='me-1'>
            <Tooltip label={el.groupTitle} position='bottom'>
              <i className='icon-info' />
            </Tooltip>
          </div>

          {el.label && (
            <div>
              {el.label.length > 14 ? (
                <Tooltip label={el.label} position='rightTop'>
                  <span>{el.label}</span>
                </Tooltip>
              ) : (
                <span>{el.label}</span>
              )}
            </div>
          )}

          <IconButton
            className='ms-1'
            icon='icon-cross-x'
            onClick={() => onRemoveItemFromTabs(el)}
            size='sm'
            color='dark'
            isDisabled={disabled}
          />
        </div>
      ))}

      {selectedFilterTabs.length === 0 && <div>{t('GENERAL.FILTER_IS_EMPTY')}</div>}
    </div>

    <div className='d-flex align-items-center justify-content-start'>
      <RoundedButton
        type='button'
        text='Clear all'
        btnStyle='text'
        color='primary'
        size='md'
        isDisabled={selectedFilterTabs.length === 0 || disabled}
        onClick={() => {
          onRemoveItemFromTabs(null);
        }}
      />
    </div>
  </div>
));

FilterTabsGroup.propTypes = {
  id: PropTypes.string,
  selectedFilterTabs: PropTypes.array.isRequired,
  onRemoveItemFromTabs: PropTypes.func.isRequired,
  filterTabsRef: PropTypes.any,
  disabled: PropTypes.bool,
};

FilterTabsGroup.defaultProps = {
  id: null,
  disabled: false,
  filterTabsRef: null,
};

export default FilterTabsGroup;
