/* eslint-disable react/jsx-curly-newline */
import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import formatKey from 'utility/formatKey';

import styles from './NavItem.module.scss';

const NavItem = ({ title, onClick, active, type, href }) => {
  const navLinkClass = cx(styles.link, 'd-flex align-items-center py-1 px-2a me-3');
  const elementMap = {
    button: (
      <button
        className={cx(styles.link, { [styles.active]: active }, 'd-flex', 'align-items-center', 'py-1', 'px-3', 'me-3')}
        onClick={() => {
          onClick(title.toLowerCase());
        }}
        type='button'
      >
        <p className={styles.title}>{formatKey('GENERAL', title)}</p>
      </button>
    ),
    link: (
      <NavLink to={href} className={({ isActive }) => (isActive ? cx(styles.active, navLinkClass) : navLinkClass)}>
        <p className={styles.title}>{formatKey('GENERAL', title)}</p>
      </NavLink>
    ),
  };
  return elementMap[type];
};
NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  type: PropTypes.string,
  href: PropTypes.string,
};

NavItem.defaultProps = {
  onClick: undefined,
  active: false,
  type: 'button',
  href: '#',
};
export default NavItem;
