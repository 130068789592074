import React from 'react';

import moment from 'moment';
import t from 'utility/setTranslation';

const EmployeeOnProbation = ({ probationPeriodEnd, remainingProbationDays }) => (
  <div className='text-start'>
    <p>{t(`TEAM_PEOPLE_PROBATION`)}</p>
    <p className='fs-11'>
      {probationPeriodEnd ? moment(probationPeriodEnd).format('MMM DD, YYYY') : '- -'}
      <span className='text-orange '> {t('TEAM_DAY_LEFT', { day: `${remainingProbationDays}` })}</span>
    </p>
  </div>
);

export default EmployeeOnProbation;
