class QueryAPI
{
    static formatListResponse(listResponse)
    {
        return listResponse.then((response) =>
        {
            const {data} = response ?? {};
            const {items, pagination} = data ?? {};
            const {perPage, currentPage} = pagination ?? {};

            response.data.hasItems = !!items?.length;
            response.data.pagination.limit = perPage;
            response.data.pagination.page = currentPage;

            return response;
        });
    }
}

export default QueryAPI;