import _ from 'lodash';

import Employee from '../Employee/Employee';
import {
  isPermissionGroupAdmin,
  isPermissionGroupEligibleForSettings,
  isPermissionGroupHR,
  isPermissionGroupSecurityAdmin,
} from '../PermissionGroup/PermissionGroupService';

export default class AuthState {
  #data;

  constructor(data = {}) {
    if (!_.isObject(data)) {
      throw new Error('AuthState must be an object');
    }

    this.data = data;
  }

  static create(data) {
    return new AuthState(data instanceof AuthState ? data.getData() : data);
  }

  getData() {
    return _.cloneDeep(this.data) ?? {};
  }

  getUser() {
    return this.getData()?.user ?? {};
  }

  getUserId() {
    return this.getUser()?.id;
  }

  getUserLanguage() {
    return this.getUser()?.language;
  }

  get employeeId() {
    return this.getUser()?.identification;
  }

  get permissionGroup() {
    return this.getUser()?.permissionGroup;
  }

  get isPermissionGroupLimitedToLegalEntity() {
    return this.getUser()?.limitRolePerLegalEntity ?? false;
  }

  get permissionGroupAllowedLegalEntityIds() {
    return this.getUser()?.legalEntityPermissions ?? [];
  }

  get roles() {
    return this.getUser()?.roles ?? [];
  }

  get isEosVisible() {
    const { roles } = this;
    return roles.includes('ROLE_ADMIN') || roles.includes('ROLE_HR');
  }

  get isAdmin() {
    const { permissionGroup } = this;
    return isPermissionGroupAdmin(permissionGroup);
  }

  get isHr() {
    const { permissionGroup } = this;
    return isPermissionGroupHR(permissionGroup);
  }

  get isAdminOrHr() {
    const { isAdmin, isHr } = this;
    return isAdmin || isHr;
  }

  get isSecurityAdmin() {
    const { permissionGroup } = this;
    return isPermissionGroupSecurityAdmin(permissionGroup);
  }

  get hasAccessToSettings() {
    const { permissionGroup } = this;
    return isPermissionGroupEligibleForSettings(permissionGroup);
  }

  hasAccessToEmployeeAttendance(employee) {
    const { isAdminOrHr, employeeId } = this;

    if (isAdminOrHr) return true;

    return Employee.create(employee).getId() === employeeId;
  }

  get companyData() {
    return this.getData().companyData ?? {};
  }

  get isEnterprise() {
    return this.companyData.isEnterprise ?? false;
  }

  get isEnglish() {
    return this.getUserLanguage() === 'en';
  }

  get isArabic() {
    return this.getUserLanguage() === 'ar';
  }
}
