import step1ImageEN from 'assets/img/getting-started/dashboard/step_1_dashboard_en.png';
import step1ImageAR from 'assets/img/getting-started/dashboard/step_1_dashboard_ar.png';
import step2ImageEN from 'assets/img/getting-started/dashboard/step_2_dashboard_en.png';
import step2ImageAR from 'assets/img/getting-started/dashboard/step_2_dashboard_ar.png';
import step3ImageEN from 'assets/img/getting-started/dashboard/step_3_dashboard_en.png';
import step3ImageAR from 'assets/img/getting-started/dashboard/step_3_dashboard_ar.png';
import step4ImageEN from 'assets/img/getting-started/dashboard/step_4_dashboard_en.png';
import step4ImageAR from 'assets/img/getting-started/dashboard/step_4_dashboard_ar.png';
import step5ImageEN from 'assets/img/getting-started/dashboard/step_5_dashboard_en.png';
import step5ImageAR from 'assets/img/getting-started/dashboard/step_5_dashboard_ar.png';

export const generateWizardImagesSteps = [
  {
    id: 1,
    imageEN: step1ImageEN,
    imageAR: step1ImageAR,
  },
  {
    id: 2,
    imageEN: step2ImageEN,
    imageAR: step2ImageAR,
  },
  {
    id: 3,
    imageEN: step3ImageEN,
    imageAR: step3ImageAR,
  },
  {
    id: 4,
    imageEN: step4ImageEN,
    imageAR: step4ImageAR,
  },
  {
    id: 5,
    imageEN: step5ImageEN,
    imageAR: step5ImageAR,
  },
];
