import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './DensityPlaceholder.module.scss';
import ItemPlaceholder from './ItemPlaceholder';

const DensityPlaceholder = ({ num }) => {
  const renderContent = () => {
    let el = null;
    if (num === 1) {
      el = (
        <div className='p-2'>
          <ItemPlaceholder />
          <div className={cx(styles.singleBox, 'mt-1 ms-3')} />
        </div>
      );
    } else {
      const list = [];
      for (let index = 0; index < num; index += 1) {
        list.push(
          <div key={Math.random()} className={cx(styles.row, num > 2 ? 'py-1' : 'py-2', 'px-2')}>
            <ItemPlaceholder />
          </div>
        );
      }
      el = <div>{list}</div>;
    }
    return el;
  };

  return <div className={styles.wrapper}>{renderContent()}</div>;
};

DensityPlaceholder.propTypes = { num: PropTypes.number };
DensityPlaceholder.defaultProps = { num: 1 };

export default DensityPlaceholder;
