/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import formatKey from 'utility/formatKey';
import t from 'utility/setTranslation';

import FieldWrapper from './FieldWrapper';

const Textarea = ({
  showCounter,
  name,
  placeholder,
  value,
  label,
  lang,
  isRequired,
  onChange,
  onClick,
  onBlur,
  autofocus,
  rows,
  error,
  containerClassName,
  customClass,
  maxLength,
  showImportant,
  direction,
  id,
  onKeyPress,
  isDisabled,
  isTranslated,
}) => {
  let requiredAttr = false;

  if (isRequired) {
    requiredAttr = 'required';
  }

  const onBlurField = () => {
    if (onBlur) {
      onBlur(name);
    }
  };

  return (
    <FieldWrapper name={name} className={containerClassName}>
      <div className='d-flex justify-content-between'>
        {label ? (
          <label className='label mb-1 w-auto' htmlFor={name}>
            {showImportant && <b className='text-secondary me-1'>{t('GENERAL.IMPORTANT')}: </b>}
            {isTranslated ? label : formatKey('GENERAL', label)}
            {isRequired && <sup>*</sup>}
          </label>
        ) : null}

        {lang ? <span className='label text-end mb-1 w-auto'>{`(${formatKey('GENERAL', lang)})`}</span> : null}
      </div>
      <textarea
        onClick={onClick}
        id={id}
        className={cx(`form-control ${error ? 'is-invalid' : 'valid'}`, customClass)}
        style={{ direction }}
        name={name}
        onKeyPress={onKeyPress}
        value={value}
        placeholder={placeholder && formatKey('GENERAL', placeholder)}
        cols='30'
        rows={rows}
        required={requiredAttr}
        onChange={onChange}
        onBlur={onBlurField}
        autoFocus={autofocus}
        maxLength={maxLength}
        dir={direction}
        disabled={isDisabled}
      />
      <div className='d-flex justify-content-between'>
        <div>
          <div className='error'>{error}</div>
        </div>
        {showCounter && (
          <div className='d-flex justfy-content-end'>
            <p className={cx(`text-end fs-11 mt-1 ${value.length < maxLength ? 'text-gray' : 'text-danger'}`)}>
              {`${value.length} / ${maxLength}`}
            </p>
          </div>
        )}
      </div>
    </FieldWrapper>
  );
};

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  lang: PropTypes.string,
  isRequired: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  autofocus: PropTypes.bool,
  rows: PropTypes.string,
  error: PropTypes.string,
  customClass: PropTypes.string,
  containerClassName: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showImportant: PropTypes.bool,
  direction: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  isTranslated: PropTypes.bool,
};

Textarea.defaultProps = {
  value: '',
  placeholder: '',
  label: '',
  lang: '',
  isRequired: false,
  isTranslated: false,
  onChange: undefined,
  onClick: () => {},
  autofocus: undefined,
  onBlur: undefined,
  rows: '10',
  error: undefined,
  customClass: '',
  containerClassName: '',
  maxLength: '',
  showImportant: false,
  direction: 'auto',
  id: '',
};

export default Textarea;
