import { getThemeFromLS } from '../../../../themes/ThemeContext';

const themeState = getThemeFromLS('theme');

export default {
  background: `rgba(
    ${themeState && themeState?.colors['green-rgb'] ? themeState?.colors['green-rgb'] : '#e9f9f1'},
    0.1
  )`,
};
