import React, { useContext, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'auth/AuthContext';
import Search from 'components/forms/components/Search';
import { PHRTable } from 'components/PHRTable';
import useDebounce from 'shared/useDebounce';

import { useQueryTodayShifts } from '../../hooks/useTodayShifts';
import { buildQueryPayload, tableData } from '../../utillity';
import DepartmentFilter from '../Filters/Department/DepartmentFilter';
import LocationFilter from '../Filters/Location/LocationFilter';
import TeamFilter from '../Filters/Team/TeamFilter';

const TodayShiftsTable = () => {
  const { authState } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState('');
  const debounceSearchTerm = useDebounce(searchTerm, 400);
  const [selectedLocationIds, setSelectedLocationIds] = useState([]);
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([]);
  const [selectedTeamIds, setSelectedTeamIds] = useState([]);

  const navigate = useNavigate();

  const { isLoading: isTodayShiftsLoading, data: todayShiftsResponse } = useQueryTodayShifts(
    buildQueryPayload(debounceSearchTerm, selectedDepartmentIds, selectedLocationIds, selectedTeamIds)
  );

  const { data } = todayShiftsResponse ?? {};
  const { items, pagination } = data ?? {};
  const { pages, total } = pagination ?? {};
  const table = tableData(items ?? [], authState, navigate);

  const onSearch = (e) => {
    setSearchTerm(e.target.value.replace(/^\s+/g, ''));
  };
  const clearSearch = () => {
    setSearchTerm('');
  };

  const changeLocation = (locations) => {
    const newIds = locations.map((location) => location.data?.id).filter(Boolean);
    setSelectedLocationIds(newIds);
  };

  const changeDepartment = (departments) => {
    const newIds = departments.map((department) => department.data?.id).filter(Boolean);
    setSelectedDepartmentIds(newIds);
  };

  const changeTeam = (teams) => {
    const newIds = teams.map((team) => team.data?.id).filter(Boolean);
    setSelectedTeamIds(newIds);
  };

  return (
    <div className='mt-4'>
      <div className='row w-100 mb-3'>
        <LocationFilter className='col-4' showLabel={false} isDisabled={false} onChange={changeLocation} />
        <DepartmentFilter className='col-4' showLabel={false} isDisabled={false} onChange={changeDepartment} />
        <TeamFilter className='col-4' showLabel={false} isDisabled={false} onChange={changeTeam} />
      </div>
      <Search onChange={onSearch} placeholder='Search People' value={searchTerm} onClear={clearSearch} iconSize='lg' />
      <PHRTable
        rowIdentifier='todayShiftId'
        id='today-shift-report'
        total={total}
        pages={pages}
        searchValue={searchTerm}
        onSearch={onSearch}
        columns={table.columns}
        rows={table.rows}
        size='sm'
        loading={isTodayShiftsLoading}
        stickyHead
      />
    </div>
  );
};

export default TodayShiftsTable;
