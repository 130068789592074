const getGTMDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer;
};

const dispatchGTMEvent = (eventName, payload) => {
  const GTMPayload = payload ?? {};
  GTMPayload.event = eventName;
  getGTMDataLayer().push(GTMPayload);
};

const createGTMPayloadFromAuthState = (authState) => {
  const { subscriptionPlan } = authState || {};
  const { fullName, fullNameArabic, tenant, username, identification } = authState?.user || {};

  return {
    name: fullName,
    email: username,
    company_name: tenant,
    user_id: `${tenant}_${identification}`,
    permission_group: authState?.user?.permissionGroup,
    name_arabic: fullNameArabic,
    subscription_plan: subscriptionPlan,
  };
};

const dispatchGTMUserLoggedInEvent = (authState) => {
  dispatchGTMEvent('user_logged_in', createGTMPayloadFromAuthState(authState));
};

const dispatchGTMUserLoggedOutEvent = (authState) => {
  dispatchGTMEvent('user_logged_out', createGTMPayloadFromAuthState(authState));
};

const dispatchGTMUserDataUpdatedEvent = (authState) => {
  dispatchGTMEvent('user_data_updated', createGTMPayloadFromAuthState(authState));
};

export default {
  dispatchGTMUserLoggedInEvent,
  dispatchGTMUserLoggedOutEvent,
  dispatchGTMUserDataUpdatedEvent,
};
