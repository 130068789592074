import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Spinner.module.scss';

const Spinner = ({ color, absolute, className }) => (
  <div
    data-testid='spinner'
    className={cx(styles.spinnerWrapper, className, 'd-flex', 'justify-content-center', 'align-items-center', {
      [styles.absolute]: absolute,
    })}
  >
    <div className={styles.spinner}>
      <div data-testid='spinner-first-bounce' className={styles.doubleBounce1} style={{ backgroundColor: color }} />
      <div data-testid='spinner-second-bounce' className={styles.doubleBounce2} style={{ backgroundColor: color }} />
    </div>
  </div>
);

Spinner.propTypes = {
  color: PropTypes.string,
  absolute: PropTypes.bool,
  className: PropTypes.string,
};

Spinner.defaultProps = {
  color: '#d4dbe0',
  absolute: false,
  className: '',
};

export default Spinner;
