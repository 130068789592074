import React from 'react';

import Circle from 'modules/EngagementCenter/shared/loaders/Circle';
import Line from 'modules/EngagementCenter/shared/loaders/Line';

import styles from './Skeleton.module.scss';

const SkeletonCard = ({ num, header = true }) => {
  const renderItems = () => {
    const items = [];
    for (let index = 0; index < num; index += 1) {
      items.push(
        <div key={index} className='d-flex align-items-center justify-content-start mt-2'>
          <Circle gradient={styles.gradient} className='me-3' size={38} />
          <Line style={{ height: 16, width: 100, borderRadius: 8 }} gradient={styles.gradient} />
        </div>
      );
    }
    return items;
  };
  return (
    <div>
      {header && (
        <div style={{ height: '48px' }} className='d-flex justify-content-between align-items-center pe-2 pt-2 w-100'>
          <Line style={{ height: 16, width: 100, borderRadius: 8 }} gradient={styles.gradient} />
          <Circle gradient={styles.gradient} size={24} />
        </div>
      )}
      <div>{renderItems()}</div>
    </div>
  );
};
export default SkeletonCard;
