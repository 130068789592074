import React, { lazy, Suspense, useContext, useEffect } from 'react';

import cx from 'classnames';
import { Navigate, Route, Routes } from 'react-router-dom';

import CompanySettingsPerformanceApi from 'api/CompanySettingsPerformanceApi';
import AreaLoader from 'components/Spinner/AreaLoader';
import { PerformanceContext } from 'modules/CompanySettings/Team/Performance/PerformanceContext';
import CustomRoute from 'topNavigation/Router/CustomRoute';

import Header from './Header';

const OneToOne = lazy(() => import('./1to1/OneToOne'));
const Approvals = lazy(() => import('./Approvals/Approvals'));
const Goals = lazy(() => import('./Goals/Goals'));

const Performance = () => {
  const path = '/performance';
  const { performanceState, dispatchPerformance } = useContext(PerformanceContext);

  const onSelectView = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const formatRedirect = () => {
    let route = '/dashboard';

    if (performanceState.goalsEnabled && performanceState?.permissionsModule?.canViewGoals) {
      route = `${path}/goals`;
    } else if (performanceState.oneOnOneEnabled && performanceState?.permissionsModule?.canViewOneOnOnes) {
      route = `${path}/1-on-1`;
    }

    return route;
  };

  useEffect(() => {
    const body = document.querySelector('body');
    body.classList.add('no-scroll');
    return () => {
      body.classList.remove('no-scroll');
    };
  }, []);

  useEffect(() => {
    if (path === '/performance') {
      CompanySettingsPerformanceApi.performanceAccess()
        .then((res) => {
          dispatchPerformance({ type: 'set', payload: { permissions: res.data.access } });
        })
        .catch(() => {});
    }
  }, [path]);

  const isPerformanceLoaded = Boolean(Object.keys(performanceState)?.length);

  return (
    <div className={cx('pageContainer page-top-positioning position-relative')}>
      <Header onSelectView={onSelectView} url={path} />

      <Routes>
        {(performanceState.goalsEnabled || performanceState?.permissionsModule?.canViewGoals) && (
          <Route
            path='goals/*'
            element={
              <Suspense fallback={<AreaLoader />}>
                <CustomRoute
                  path={`${path}/goals`}
                  component={Goals}
                  hidePage={!performanceState.goalsEnabled || !performanceState?.permissionsModule?.canViewGoals}
                />
              </Suspense>
            }
          />
        )}
        {(performanceState.oneOnOneEnabled || performanceState?.permissionsModule?.canViewOneOnOnes) && (
          <Route
            path='1-on-1/*'
            element={
              <Suspense fallback={<AreaLoader />}>
                <CustomRoute
                  path={`${path}/1-on-1`}
                  component={OneToOne}
                  hidePage={!performanceState.oneOnOneEnabled || !performanceState?.permissionsModule?.canViewOneOnOnes}
                />
              </Suspense>
            }
          />
        )}
        {(performanceState?.kpiApprovalFlowEnabled ||
          performanceState.goalsEnabled ||
          performanceState?.permissionsModule?.canApproveTarget) && (
          <Route
            path='approvals/*'
            element={
              <Suspense fallback={<AreaLoader />}>
                <CustomRoute
                  path={`${path}/approvals`}
                  component={Approvals}
                  hidePage={
                    !performanceState?.kpiApprovalFlowEnabled ||
                    !performanceState.goalsEnabled ||
                    !performanceState?.permissionsModule?.canApproveTarget
                  }
                />
              </Suspense>
            }
          />
        )}

        {isPerformanceLoaded && <Route path='' element={<Navigate to={formatRedirect()} replace />} />}
      </Routes>
    </div>
  );
};

export default Performance;
