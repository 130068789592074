import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import IconButton from 'components/buttons/IconButton/IconButton';

import styles from './CustomCalendarNav.module.scss';

const CustomCalendarNav = ({ begining, onChange }) => {
  const [currentMonth, setCurrentMonth] = useState(moment(begining).format('YYYY-MM-DD'));
  const startDate = moment(moment(begining).subtract(1, 'year')).format('YYYY-MM');
  const endDate = moment(moment(begining).add(1, 'year')).format('YYYY-MM');

  const changeMonth = (type) => {
    if (type === 'prev') {
      if (moment(currentMonth).subtract(1, 'month').isSameOrAfter(startDate, 'month')) {
        setCurrentMonth(moment(currentMonth).subtract(1, 'month'));
      }
    } else if (type === 'next') {
      if (moment(currentMonth).add(1, 'month').isSameOrBefore(endDate, 'month')) {
        setCurrentMonth(moment(currentMonth).add(1, 'month'));
      }
    }
  };

  useEffect(() => {
    onChange(currentMonth);
  }, [currentMonth]);

  return (
    <div className={cx(styles.monthSwitch, 'd-flex justify-content-between align-items-center w-100')}>
      <>
        <div>
          <IconButton
            icon='icon-chevron-left'
            onClick={() => {
              changeMonth('prev');
            }}
            isDisabled={startDate === currentMonth}
            size='md'
            color='gray'
          />
        </div>

        <div>{moment(currentMonth).format('MMM YYYY')}</div>

        <div>
          <IconButton
            icon='icon-chevron-right'
            isDisabled={endDate === currentMonth}
            onClick={() => {
              changeMonth('next');
            }}
            size='md'
            color='gray'
          />
        </div>
      </>
    </div>
  );
};
CustomCalendarNav.propTypes = {
  begining: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
};

CustomCalendarNav.defaultProps = {
  begining: new Date(),
  onChange: undefined,
};
export default CustomCalendarNav;
