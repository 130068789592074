const PublicRoutes = {
  login: '/login',
  forgotPassword: '/forgot-password',
  error404: '/404',
  error403: '/403',
  apiVersion: '/api-version',
};

const MobileFriendlyRoutes = {
  resetPassword: '/reset-password',
  // next line route is coupled with mobile app, don't change it without align with mobile team
  publicProfile: '/public/profile',
  get businessCard() {
    return `${this.publicProfile}/:id`;
  },
  careers: '/careers',
  get careersDetails() {
    return `${this.careers}/:id`;
  },
  recruitmentLobby: '/recruitment-lobby',
  get recruitmentLobbyDetails() {
    return `${this.recruitmentLobby}/:jobPostId`;
  },
};

const PrivateRoutes = {
  dashboard: 'dashboard',
  clientOnboarding: 'client-onboarding',
  companySettings: 'company-settings/*',
  organization: 'organization/*',
  onboarding: 'onboarding',
  profileId: 'profile/:id/*',
  offboardingId: 'offboarding/:id',
  requests: 'requests/*',
  time: 'time/*',
  pay: 'pay/*',
  tasks: 'tasks/*',
  files: 'files/*',
  companyAssets: 'company-assets/*',
  performance: 'performance/*',
  expired: 'expired',
  recruitment: '/recruitment',
  payment: '/payment',
  get recruitmentJobs() {
    return `${this.recruitment}/jobs`;
  },
  get recruitmentCandidates() {
    return `${this.recruitment}/candidates`;
  },
  get jobDetails() {
    return `${this.recruitmentJobs}/:id`;
  },
  get jobDetailsCandidates() {
    return `${this.jobDetails}/candidates`;
  },
  get jobDetailsStatistics() {
    return `${this.jobDetails}/statistics`;
  },
};

export { PublicRoutes, MobileFriendlyRoutes, PrivateRoutes };
