import React, { useContext } from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import { AuthContext } from 'auth/AuthContext';
import Personas from 'components/Personas';
import Tooltip from 'components/tooltip/Tooltip';
import readName from 'utility/readName';
import renderPeriodKey from 'utility/renderPeriodKey';
import t from 'utility/setTranslation';

import styles from './OverlapItemDetails.module.scss';

const OverlapItemDetails = ({ item, isDashboard }) => {
  const { authState } = useContext(AuthContext);
  const { partialLeaveFirstDay, partialLeaveLastDay, startDate, endDate } = item.requests[0];
  const isPartialLeave = partialLeaveFirstDay && partialLeaveLastDay;
  const isPartialLeaveForSingleDay = partialLeaveFirstDay === partialLeaveLastDay;

  const partialLeaveText = { first_half: 'GENERAL.FIRST_HALF_OF_DAY', second_half: 'GENERAL.SECOND_HALF_OF_DAY' };

  const partialLeaveTooltipText = () => (
    <div>
      <p className={cx('me-0', styles.tooltipTitle)}>{t('GENERAL.HALF_DAY')}</p>

      <p>
        {moment(startDate).format('MMM DD')} - {t(partialLeaveText[partialLeaveFirstDay])}
      </p>

      {!isPartialLeaveForSingleDay && (
        <p>
          {moment(endDate).format('MMM DD')} - {t(partialLeaveText[partialLeaveLastDay])}
        </p>
      )}
    </div>
  );

  const content = (
    <div className='d-flex align-items-center justify-content-between'>
      <div className='d-flex align-items-start'>
        <Personas user={item} size={20} />

        <div className='ms-2'>
          <p className={cx(styles.name, 'fw-bold', 'text-steal')}>
            {readName(authState.user.language, item?.fullName, item?.fullNameArabic)}
          </p>

          <p className={styles.date}>
            <span className='me-1'>{moment(item.requests[0].startDate).format('MMM DD')}</span>-
            <span className='ms-1'>{moment(item.requests[0].endDate).format('MMM DD')}</span>
          </p>

          {isDashboard && (
            <p className={cx(styles.date, 'mt-1')}>
              <span className='me-1 fw-bold'>
                {item.requests[0].type
                  .replace(/([A-Z])/g, ' $1')
                  .replace('Request', '')
                  .trim()}
              </span>
            </p>
          )}
        </div>
      </div>

      <div className={cx(styles.date, 'text-gray')}>
        {isDashboard ? (
          <>
            <span className='me-1'>
              {moment(item.requests[0].endDate).diff(moment(item.requests[0].startDate), 'days') + 1}
            </span>

            <span>
              {t(
                renderPeriodKey(
                  moment(item.requests[0].endDate).diff(moment(item.requests[0].startDate), 'days') + 1,
                  'DAY'
                )
              )}
            </span>
          </>
        ) : (
          <>
            <span className='me-1'>{item.overlappedDays}</span>
            <span>{t(renderPeriodKey(item.overlappedDays, 'DAY'))}</span>
          </>
        )}
      </div>
    </div>
  );

  return (
    <li className='p-2 mb-3 w-100'>
      {isPartialLeave ? (
        <Tooltip label={partialLeaveTooltipText()} size='md' position='right'>
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </li>
  );
};

OverlapItemDetails.propTypes = {
  item: PropTypes.object.isRequired,
  isDashboard: PropTypes.bool,
};

OverlapItemDetails.defaultProps = {
  isDashboard: false,
};

export default OverlapItemDetails;
