import React, { useEffect, useState } from 'react';

import { useCountWhoNeedsToBeIn, useCountWhoIsIn } from 'api/WhoIsIn/WhoIsInQueryApiUse';
import NavTab from 'components/Navigation/NavTab';
import ToolBar from 'components/PHRUI/ToolBar/ToolBar';
import { useSlug } from 'shared/useSlug';

import WhoIsInTable from '../Table/WhoIsInTable';

const IN = 'checked in';
const TO_BE_IN = 'needs to check in';

const WhoIsInTabs = () => {
  const { slug, toggleSlug } = useSlug(0);
  const [inCount, setInCount] = useState(0);
  const [toBeInCount, setToBeInCount] = useState(0);
  const [activeView, setActiveView] = useState(IN);
  const { isFetching: isCountingIn, data: inResponse } = useCountWhoIsIn(slug);
  const { isFetching: isCountingToBeIn, data: toBeInResponse } = useCountWhoNeedsToBeIn(slug);

  useEffect(() => {
    setInCount(inResponse?.data?.count ?? 0);
  }, [inResponse]);

  useEffect(() => {
    setToBeInCount(toBeInResponse?.data?.count ?? 0);
  }, [toBeInResponse]);

  return (
    <div>
      <ToolBar fullWidth={false} className={'px-5 py-3'}>
        <NavTab text={IN} onClick={setActiveView} active={activeView === IN} number={inCount} showNumber />
        <NavTab
          text={TO_BE_IN}
          onClick={setActiveView}
          active={activeView === TO_BE_IN}
          number={toBeInCount}
          showNumber
        />
      </ToolBar>

      {activeView === IN && <WhoIsInTable type={'IN'} />}
      {activeView === TO_BE_IN && <WhoIsInTable type={'OUT'} />}
    </div>
  );
};

export default WhoIsInTabs;
