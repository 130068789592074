/* eslint-disable */

import { colorConstants } from 'constants/color';

const colors = () => {
  return Object.values(colorConstants);
};

const defaultColor = () => {
  return colorConstants.DEFAULT;
};

const primaryColor = () => {
  return colorConstants.PRIMARY;
};

const secondaryColor = () => {
  return colorConstants.SECONDARY;
};

const infoColor = () => {
  return colorConstants.INFO;
};

const successColor = () => {
  return colorConstants.SUCCESS;
};

const warningColor = () => {
  return colorConstants.WARNING;
};

const dangerColor = () => {
  return colorConstants.DANGER;
};

export default {
  colors,
  defaultColor,
  primaryColor,
  secondaryColor,
  infoColor,
  warningColor,
  dangerColor,
  successColor,
};
