import React, { useState, useContext, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import cx from 'classnames';
import moment from 'moment';
import { NavLink, useNavigate } from 'react-router-dom';
import CSSTransition from 'react-transition-group/CSSTransition';

import AuthApi from 'api/AuthApi';
import TranslationsApi from 'api/TranslationsApi';
import { AuthContext } from 'auth/AuthContext';
import { setCookie } from 'auth/Cookies';
import { IsLoggedInContext } from 'auth/IsLoggedInContext';
import Modal from 'components/Modal';
import Personas from 'components/Personas';
import InitLoading from 'components/Spinner/InitLoading';
import OutsideClickHandler from 'hoc/OutsideClickHandler';
import { PerformanceContext } from 'modules/CompanySettings/Team/Performance/PerformanceContext';
import SettingsModal from 'modules/Employee/shared/SettingsModal';
import AuthState from 'services/Auth/AuthState';
import { loadLocales, useQueryTranslations } from 'shared/useTranslations';
import Language from 'topNavigation/Language/Language';
import readName from 'utility/readName';
import t from 'utility/setTranslation';

import styles from './ProfileNav.module.scss';

moment.defineLocale('ar-palm', {
  parentLocale: 'ar-sa',
  preparse: (string) => string,
  postformat: (string) => string,
});

const ProfileNav = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [sending, setSending] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const { authState, dispatch } = useContext(AuthContext);
  const { user } = authState;
  const { dispatch: dispatchIsLoggedIn } = useContext(IsLoggedInContext);
  const { dispatchPerformance } = useContext(PerformanceContext);
  const [selectedLanguage, setSelectedLanguage] = useState(authState?.user?.language);
  const queryClient = useQueryClient();
  const authStateDTO = AuthState.create(authState);

  const {
    isInitialLoading,
    refetch,
    data: translationsData,
  } = useQueryTranslations(authState?.user?.language === 'ar' ? 'en' : 'ar', {
    onSuccess: ({ data: translations }) => {
      localStorage.setItem(
        `translations-${authState?.user?.language === 'ar' ? 'en' : 'ar'}`,
        JSON.stringify(translations)
      );
    },
    enabled: false,
  });

  useEffect(() => {
    if (authState && authState?.user) {
      setSelectedLanguage(authState?.user?.language);
      moment.locale(authState?.user?.language === 'ar' ? 'ar-palm' : 'en');
    }
  }, [authState]);

  const closeMenu = () => {
    setShowMenu(false);
  };

  const openMenu = () => {
    setShowMenu(!showMenu);
  };

  const onChangePassword = () => {
    setModalOpened(true);
    setShowMenu(false);
  };
  const navigate = useNavigate();

  const logout = () => {
    setShowMenu(false);
    if (sending) return;
    setSending(true);
    const data = { username: authState.user.username };

    AuthApi.logout(data).then(async () => {
      queryClient.clear();
      await setSending(false);
      document.cookie = `sso_session=; Max-Age=0; path=/; domain=.${new URL(window.location.href).hostname
        .split('.')
        .slice(1)
        .join('.')}`;

      await dispatch({
        type: 'del',
      });
      await dispatchIsLoggedIn({
        type: 'set',
        payload: { isLoggedIn: false },
      });
      dispatchPerformance({ type: 'del' });

      navigate('/login', { replace: true });
    });
  };

  const onModalPasswordClose = () => {
    setModalOpened(false);
  };

  function setAppLanguage(lang, translations) {
    setCookie('language', lang, 30);
    loadLocales({ language: lang, translations: translations.data });
    document.title = t('APPLICATION.TITLE');
    dispatch({
      type: 'set',
      payload: {
        token: authState.token,
        refreshToken: authState.refreshToken,
        refreshExpireAt: authState.refreshExpireAt,
        translations: translations.data,
        currentTimeTracking: authState.currentTimeTracking,
        user: { ...authState.user, language: lang },
      },
    });
    setSelectedLanguage(lang);
    moment.locale(lang === 'ar' ? 'ar-palm' : 'en');
  }

  const onChangeLang = (lang) => {
    closeMenu();
    if (translationsData) {
      setAppLanguage(lang, translationsData);
    } else {
      refetch().then((res) => {
        setAppLanguage(lang, res.data);
      });
    }

    const data = { language: lang };
    TranslationsApi.setLanguage(data).then(() => {
      if (lang === 'en') {
        const url = new URL(window.location);
        url.searchParams.delete('lang');
        window.history.pushState({}, '', url);
      }
    });
  };

  const handleOnClickSettings = async () => {
    await setShowMenu(false);

    const { isAdmin, isHr, isSecurityAdmin } = authStateDTO;

    let route = null;

    if (isAdmin) route = '/company-settings/organization/details';
    if (isHr) route = '/company-settings/team';
    if (isSecurityAdmin) route = '/company-settings/security';

    if (!route) return;

    navigate(route);
  };

  return (
    <div className={cx(styles.wrapper, 'd-flex', 'align-items-center', showMenu ? styles.active : '')}>
      <OutsideClickHandler clickHandler={closeMenu}>
        <div className='d-flex align-items-center'>
          <div
            data-testid='profile-menu-button'
            className='d-flex align-items-center cursor-pointer'
            onClick={openMenu}
            onKeyDown={openMenu}
          >
            <Personas user={user} customClass={styles.profileImg} size={24} />
            <span className='text-white ms-2'>
              <i className={cx(styles.dropdown, 'icon-chevron-bottom')} />
            </span>
          </div>
          <div className={styles.menuContainer}>
            <CSSTransition
              mountOnEnter
              unmountOnExit
              in={showMenu}
              timeout={400}
              classNames={{
                enter: styles.enter,
                enterActive: styles.enterActive,
                exit: styles.exit,
                exitActive: styles.exitActive,
              }}
            >
              <div className={cx(styles.menu, 'bg-white')}>
                <p className='mt-3 mx-3 m-0'>
                  {readName(authState?.user?.language, user?.fullName, user?.fullNameArabic)}
                </p>

                <p className={cx(styles.mail, 'mx-3', 'mb-0', 'pb-1', 'word-break')}>
                  <small>{user?.username}</small>
                </p>
                <ul className='no-style nav flex-column m-3'>
                  <li className='nav-item' onClick={() => setShowMenu(false)}>
                    <NavLink
                      to={`/profile/${user?.identification}/personal`}
                      className={cx(styles.link, 'nav-link', 'text-start')}
                    >
                      {t('GENERAL.MY_PROFILE')}
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <button
                      className={cx(styles.link, 'nav-link', 'btn', 'text-start')}
                      type='button'
                      onClick={onChangePassword}
                    >
                      {t('GENERAL.SETTINGS')}
                    </button>
                  </li>
                  <li className='nav-item border-btm mb-3'>
                    <div className={cx(styles.link, 'nav-link', 'btn', 'text-start')}>
                      <Language onChangeLang={onChangeLang} selectedLanguage={selectedLanguage} />
                    </div>
                  </li>
                  {authStateDTO.hasAccessToSettings && (
                    <li className='nav-item cursor-pointer' onClick={handleOnClickSettings}>
                      <p className={cx(styles.link, 'nav-link', 'text-start')}>{t('GENERAL.COMPANY_SETTINGS')}</p>
                    </li>
                  )}
                  <li className='nav-item'>
                    <button
                      className={cx(styles.link, 'nav-link btn text-start text-red pb-0', sending ? 'no-events' : '')}
                      type='button'
                      onClick={logout}
                    >
                      {t('GENERAL.SIGN_OUT')}
                    </button>
                  </li>
                </ul>
              </div>
            </CSSTransition>
          </div>
        </div>
      </OutsideClickHandler>

      <Modal
        showDirtyForm
        isOpen={modalOpened}
        showHeader={false}
        styles={{ width: '720px', padding: '0' }}
        onRequestClose={() => {
          onModalPasswordClose(false);
        }}
      >
        <SettingsModal
          employeeId={user?.identification}
          onClose={() => {
            onModalPasswordClose(false);
          }}
        />
      </Modal>

      {isInitialLoading && (
        <div className={styles.loadingDiv}>
          <InitLoading />
        </div>
      )}
    </div>
  );
};

export default ProfileNav;
