import React, { useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './TableHead.module.scss';
import IconButton from '../../../components/buttons/IconButton/IconButton';
import formatKey from '../../../utility/formatKey';

const TableHeadCell = ({
  onSort,
  title,
  colSpan,
  sortable,
  sortValue,
  textRight,
  className,
  textCenter,
  tooltip,
  customButton,
}) => {
  const [isAsc, setIsAsc] = useState(false);

  const onClick = () => {
    onSort(sortValue, isAsc);
    setIsAsc(!isAsc);
  };

  return (
    <th className={cx(styles.th, className, 'py-2')} colSpan={colSpan}>
      <div
        className={cx(
          'd-flex align-items-center',
          textRight || customButton ? 'justify-content-between' : '',
          textCenter ? 'justify-content-center' : ''
        )}
      >
        <div className='d-flex align-items-center'>
          {tooltip || null}
          {title}
          {sortable && (
            <IconButton icon='icon-chevron-sort' onClick={onClick} size='sm' color='gray' className='ms-1' />
          )}
        </div>
        {textRight && <div>{formatKey('GENERAL', textRight)}</div>}
        {customButton}
      </div>
    </th>
  );
};

TableHeadCell.propTypes = {
  title: PropTypes.string.isRequired,
  onSort: PropTypes.func,
  colSpan: PropTypes.number,
  sortable: PropTypes.bool,
  sortValue: PropTypes.string,
  textRight: PropTypes.string,
  className: PropTypes.string,
  textCenter: PropTypes.bool,
  tooltip: PropTypes.element,
  customButton: PropTypes.element,
};

TableHeadCell.defaultProps = {
  onSort: undefined,
  colSpan: 1,
  sortable: false,
  sortValue: '',
  textRight: '',
  className: '',
  textCenter: '',
  tooltip: null,
  customButton: null,
};

export default React.memo(TableHeadCell);
