import Localize from 'react-intl-universal';

const t = (translationKey, pattern) => {
  let result = '';
  if (translationKey) {
    result = Localize.get(translationKey, pattern) ? Localize.get(translationKey, pattern) : `${translationKey}`;
  }
  return result;
};

export default t;
