const emailRegex = /^.{1,}[@].{1,}[.].{1,3}$/;
const mobileRegex = /^\d{5,30}$/;
const phoneRegex = /^\d{5,30}$/;
const documentRegex = /^1[0-9]{9}$/;
const basicWageRegex = /^\d*(\.\d{0,2})?$/;
const housingRegex = /^\d*(\.\d{0,2})?$/;
const arabicAlphabetDigits = /^[\p{Script=Arabic}\s]+$/u;
const specialCharacterRegex = /[~`!#@$%^&*+=\-[\]\\';,./{}|\\":<>?]/;
const lowercaseRegex = /[a-z]/;
const uppercaseRegex = /[A-Z]/;
const numberRegex = /^[0-9]+$/;
const hasNumberRegex = /\d/;
const zktecoHostRegex = /^https?:\/\/.+\..{2,}\/?$/;
const alphabetAndDigits = /^[A-Za-z0-9\s]+$/;
const reDot = /^[^.]*$/;
const angleBracketsRegex = /[<>]/;
const ibanRegex = /^(?=.*\d)[a-zA-Z0-9]{1,34}$/;

const validURL = (str) => {
  let isValid = false;
  const pattern = new RegExp(
    '^(https?\\:\\/\\/)' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%:_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  isValid = pattern.test(str);
  return isValid;
};

export {
  emailRegex,
  mobileRegex,
  phoneRegex,
  documentRegex,
  basicWageRegex,
  housingRegex,
  arabicAlphabetDigits,
  specialCharacterRegex,
  lowercaseRegex,
  uppercaseRegex,
  numberRegex,
  hasNumberRegex,
  validURL,
  zktecoHostRegex,
  alphabetAndDigits,
  reDot,
  angleBracketsRegex,
  ibanRegex,
};
