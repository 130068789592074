// eslint-disable-next-line import/prefer-default-export
export const convertObjToMultipart = (data) => {
  const formData = new FormData(); // create a new FormData object

  Object.keys(data).forEach((key) => {
    const value = data[key];

    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        Object.keys(item).forEach((field) => {
          formData.append(`${key}[${index}][${field}]`, item[field]);
        });
      });
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};
