import { useMutation, useQuery } from '@tanstack/react-query';

import CompanySettingsApi from 'api/CompanySettingsApi';

export const queryKeys = {
  contractNotification: ['contractNotification'],
  saveContractRenewalNotificationConfig: ['saveContractRenewalNotificationConfig'],
  employeePrivacySettings: ['queryEmployeePrivacySettings'],
  mutateEmployeePrivacySettings: ['mutateEmployeePrivacySettings'],
  employeePrivacySettingsPerEmployee: (id) => ['employeePrivacySettingsPerEmployee', id],
  mutateEmployeePrivacySettingsPerEmployee: (id) => ['mutateEmployeePrivacySettingsPerEmployee', id],
  employeeDetails: (id) => ['employeeDetails', id],
};

export const useQueryContractNotificationConfig = () =>
  useQuery(queryKeys.contractNotification, CompanySettingsApi.getContractNotification);

export const useSaveContractRenewalNotificationConfig = () =>
  useMutation(queryKeys.saveContractRenewalNotificationConfig, CompanySettingsApi.setContractNotification);

export const useQueryEmployeePrivacySettings = () =>
  useQuery(queryKeys.employeePrivacySettings, CompanySettingsApi.getEmployeePrivacySettings);

export const useMutationEmployeePrivacySettings = () =>
  useMutation(queryKeys.mutateEmployeePrivacySettings, CompanySettingsApi.updateEmployeePrivacySettings);

export const useQueryEmployeePrivacySettingsPerEmployee = (employeeId) =>
  useQuery(queryKeys.employeePrivacySettingsPerEmployee(employeeId), () =>
    CompanySettingsApi.getEmployeePrivacySettingsPerEmployee(employeeId)
  );

export const useMutationEmployeePrivacySettingsPerEmployee = (employeeId) =>
  useMutation(
    queryKeys.mutateEmployeePrivacySettingsPerEmployee(employeeId),
    CompanySettingsApi.updateEmployeePrivacySettingsPerEmployee
  );

export const useQueryEmployeeDetails = (employeeId) =>
  useQuery(queryKeys.employeeDetails(employeeId), () => CompanySettingsApi.getEmployeeDetails(employeeId));
