import _ from 'lodash';

function initQuery(query = '') {
  let q = '?';
  if (query) q = `${query}&`;
  return q;
}

function defaultSort() {
  return {
    direction: 'asc',
    sort: '',
  };
}

function defaultPagination() {
  return {
    currentPage: 1,
    perPage: 10,
  };
}

function stringifyParams(params) {
  return Object.keys(params)
    .map((key) => {
      let value = params[key];
      if (_.isObject(value)) {
        value = JSON.stringify(value);
      }
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join('&');
}

function addParams(params = {}, query = '') {
  const q = initQuery(query);
  const sParams = stringifyParams(params);

  return `${q}${sParams}`;
}

export default {
  defaultSort,
  defaultPagination,
  stringifyParams,
  addParams,
};
