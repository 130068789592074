import React, { useEffect, useRef, useState } from 'react';

const WritingAnimation = ({ text, speed, className, onWritingEnd }) => {
  const [displayText, setDisplayText] = useState('');
  const currentIndex = useRef(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex.current < text.length) {
        setDisplayText((prevText) => {
          // eslint-disable-next-line no-plusplus
          currentIndex.current++;
          return prevText + text[currentIndex.current - 1];
        });
      } else {
        onWritingEnd();
        clearInterval(intervalId);
      }
    }, speed);

    return () => clearInterval(intervalId);
  }, [displayText]);

  return <p className={className}>{displayText}</p>;
};

export default WritingAnimation;
