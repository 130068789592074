import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import Line from 'modules/EngagementCenter/shared/loaders/Line';
import t from 'utility/setTranslation';

import styles from './MainNavItem.module.scss';

const MainNavItem = ({ title, href, isLoading, id }) => {
  const navLinkClass = cx('nav-link px-0');
  return (
    <li className={cx(styles.link, 'nav-item px-lg-5 px-md-3a')}>
      {isLoading ? (
        <Line key={title} style={{ height: 20, width: '100%' }} gradient={styles.gradient} className='radius-4' />
      ) : (
        <NavLink
          exact='false'
          replace
          to={href}
          className={({ isActive }) => (isActive ? cx('active', navLinkClass) : navLinkClass)}
          id={id}
        >
          {t(`NAVBAR.${title.toUpperCase()}`)}
        </NavLink>
      )}
    </li>
  );
};

MainNavItem.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

MainNavItem.defaultProps = {
  isLoading: false,
};
export default MainNavItem;
