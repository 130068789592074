import { useQuery } from '@tanstack/react-query';

import { useCTAOptions } from 'services/UseQuery/UseQueryService';

import queryAPI from './FileQueryAPI';

export const queryKeys = {
  downloadFileFromUrl: (url) => ['downloadFileFromUrl', url],
};

export const useDownloadFileFromUrl = (url, useOptions = {}, apiConfig = {}) =>
  useQuery(
    queryKeys.downloadFileFromUrl(url),
    () => queryAPI.downloadFileFromUrl(url, apiConfig),
    useCTAOptions(useOptions)
  );
