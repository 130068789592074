import cx from 'classnames';
import PropTypes from 'prop-types';

import propTypesUtility from 'utility/propTypesUtility';

import style from './style.module.scss';

const propTypes = () => ({
  id: propTypesUtility.idPropType(),
  raised: PropTypes.bool,
  border: PropTypes.bool,
  hoverable: PropTypes.bool,
  isBackgroundHover: PropTypes.bool,
  className: propTypesUtility.classNamePropType(),
  style: propTypesUtility.stylePropType(),
  onClick: PropTypes.func,
});

const defaultProps = () => ({
  id: 'card',
  raised: true,
  border: false,
  hoverable: false,
  isBackgroundHover: true,
  className: '',
  style: {},
  onClick: null,
});

const containerClasses = (className, raised, border, hoverable, onClick, isBackgroundHover) => {
  const raisedClass = raised ? 'shadow-04' : '';
  const borderClass = border ? 'full-border' : '';
  const hoverRaised = !isBackgroundHover;
  const hoverBG = !!isBackgroundHover;

  return cx(
    cx(style.container, {
      [style.hoverable]: hoverable && hoverBG,
      [style.hoverableRaised]: hoverable && hoverRaised,
      [style.clickable]: !!onClick,
    }),
    `px-3 py-2a radius-8 ${borderClass} ${raisedClass} ${className}`
  );
};

export default {
  propTypes,
  defaultProps,
  containerClasses,
};
